import React, { createContext, useState, useContext } from "react"

const RecognitionContext = createContext()

export default function RecognitionProvider({ children }) {
    const [state, setState] = useState({ checked: false, bounding_boxes: [] })

    const setDataState = (data) => {
        setState(prev => ({ ...prev, ...data }))
    }

    const resetData = () => {
        setState({ checked: false, bounding_boxes: [] })
    }

    return (
        <RecognitionContext.Provider value={{ state, setDataState, resetData }}>
            {children}
        </RecognitionContext.Provider>
    )
}

export function useDataRecognition() {
    const { state, setDataState, resetData } = useContext(RecognitionContext)
    return { state, setDataState, resetData }
}
