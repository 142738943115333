import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios'
import _ from 'lodash'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    Menu,
    MenuItem
} from '@material-ui/core'


import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'

import { makeStyles } from "@material-ui/core/styles"
import { useDataRequestTools } from "./context"

export const useStyles = makeStyles((theme) => ({
    icon: {
        display: 'flex',
        marginLeft: 5,
        cursor: 'pointer',
        '& svg': {
            padding: 2
        },
        '& :hover': {
            backgroundColor: '#eee',
            borderRadius: 4
        }
    }
}))

export default function StatusFilter({ type }) {
    const [anchorEl, setAnchorEl] = React.useState(false)
    const [options, setOptions] = useState({items: [], loading:  true})
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)

    const classes = useStyles()
    const { t } = useTranslation()
    const { setStatus, hasStatus, status } = useDataRequestTools()

    const getData = useCallback(async () => {
        let resultData = {}

        try {
            const resp = await axios.get(`${pathServer}/tool/${type}/status`, requestHeaders)
            resultData.items = _.get(resp, 'data.items', [])
            resultData.loading = false
        } catch (err) {
            resultData.items = []
            resultData.loading = false
            console.log('get data attachments: ', err)
        }
        setOptions(resultData)

    }, [pathServer, type, requestHeaders])

    useEffect(() => {
        let isMounted = true

        if (isMounted) getData()
        return () => {
            isMounted = false
        }
    }, [getData])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSubmit = (evt, option = '') => {
        evt.preventDefault()
        setAnchorEl(null)
        setStatus(option)
    }

    return (
        <div>
            <div onClick={handleClick} className={classes.icon}>
                {hasStatus ? <FilterListRoundedIcon color='primary' /> : <FilterListRoundedIcon />}
            </div>

            {
                anchorEl && (
                    <Menu
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        keepMounted
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            onClick={handleSubmit}
                            disabled={!Boolean(status)}
                        >
                            {t('common:Todas')}
                        </MenuItem>
                        {
                            !options.loading && options.items.map(item => (
                                <MenuItem
                                    key={`${item}`}
                                    onClick={evt => handleSubmit(evt, item)}
                                    disabled={status === item}
                                >
                                    {item}
                                </MenuItem>
                            ))
                        }                       
                    </Menu>
                )
            }

        </div>
    );
}