import React, { createContext, useState, useContext, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSnackbar } from "notistack"
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import _ from 'lodash'
import axios from 'axios'

import { Creators as ItemsActions } from "flux/ducks/Items"
import AppActions from "flux/actions/AppActions"
import Utils from 'utils/Utils'
import useWindowSize from "components/Layout/useWindowSize"
import useUtils from '../hook/useUtils'

const ItemsContext = createContext()

const getLimit = (size, isListMode) => {
  if (isListMode) {
    return 30
  }
  const cardSize = 250
  const cardMargin = 8
  const card = cardSize + cardMargin * 2 // -- esquerda e direita
  const limit = Math.trunc(size.width / card) * 3
  return limit >= 3 ? limit : 30
}

export default function ItemsProvider({ children, draft = false, isShortcut, showSelected = false, pitContext = null }) {
  const {
    showPublished,
    sorting,
    search,
    filtersOptions,
    page,
    visualization_mode,
    pit
  } = useSelector((state) => state.itemsReducer)
  const {
    requestHeaders,
    pathServer,
    markedItems
  } = useSelector((state) => state.appReducer)
  const isListMode = visualization_mode === 'list-mode'
  const size = useWindowSize()
  const limit = getLimit(size, isListMode)
  const [state, setState] = useState({
    checkedData: false,
    hasData: false,
    loading: false,
    loadingMoreData: false,
    userData: {},
    pageCurrent: 1,
    next: null,
    totalItems: { total: 0, to: 0, lengthCurrentPage: 0 },
    dataItensIds: [],
    dataItems: [],
    permission: { msg: '', status: true },
    parameters: null,
    limit,
  })
  const {
    deletePitContext,
    getOnlyParams
  } = useUtils()

  const dataParams = useParams()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const handleSetData = useCallback((field, value, callback) => {
    setState(prev => ({ ...prev, [field]: value }), callback)
  }, [])

  const getParamsToItemsWithoutMarked = useCallback(() => {
    const only = getOnlyParams()
    let parameters = {
      only,
      limit: state.limit,
      showPublished: showPublished,
      pit: pit,
      ...(Boolean(sorting) && { order: sorting }),
      ...(Boolean(search) && { search }),
      ...(_.get(dataParams, 'category', false) && { [`category.slug`]: dataParams.category }),
      ...(_.get(dataParams, 'segment', false) && { [`segment.slug`]: dataParams.segment }),
      ...(_.get(dataParams, 'division', false) && { [`division.slug`]: dataParams.division }),
      ...(_.get(dataParams, 'searchText', false) && { search: dataParams.searchText }),
      ...(draft && { draft: true }),
      ...(filtersOptions.length > 0 && { filtersOptions: filtersOptions })
    }

    return parameters
  }, [
    dataParams,
    draft,
    state.limit,
    showPublished,
    sorting,
    search,
    filtersOptions,
    getOnlyParams,
    pit
  ])

  const getParamsToItems = useCallback(() => {
    const parameters = getParamsToItemsWithoutMarked()
    return parameters
  }, [getParamsToItemsWithoutMarked])

  const getUrl = useCallback((isFirstSearch = false) => {
    if (isShortcut && isFirstSearch) {
      return `${pathServer}/item/shortcut/${match.params.shortcut_slug}`
    } else {
      return `${pathServer}/item/find`
    }
  }, [match.params.shortcut_slug, pathServer, isShortcut])

  const getPage = useCallback(pPage => {
    if (pPage > 0) {
      return pPage;
    }
    if (page > 0 && isListMode) {
      return page;
    }
    return 1;
  }, [isListMode, page])

  const parametersWithPaging = useCallback((parameters, page, next = null) => {
    if (isListMode) {
      parameters['page'] = page;
    } else if (next) {
      parameters['search_after'] = next
    }
    return parameters;
  }, [isListMode])

  const postReturn = useCallback((data, initialParams) => {
    if (_.get(data, "params", false)) {
      const DATA = {
        ...(data.params.filtersOptions && {
          filters: data.params.filtersOptions.map(i => ({
            ...i,
            disabled: true,
            isInitial: true
          }))
        }),
        ...(data.params.sorting && { order: data.params.sorting }),
        ...(data.params.showPublished && { showPublished: data.params.showPublished }),
        ...(data.params.search && { search: data.params.search }),
        ...(Boolean(_.get(data, "params.pit", "")) && { pit: _.get(data, "params.pit", '') }),
      }

      dispatch(ItemsActions.setSession({
        name: "filtersShortcut",
        data: DATA,
      }))
    }

    const items = _.get(data, "items", [])
    const checkNext = _.get(data, "last", [])
    const next = Array.isArray(checkNext) && checkNext.length > 0 ? checkNext : null

    const callback = () => {
      if (Boolean(data.pit)) {
        dispatch(ItemsActions.setSession({
          name: "pit",
          data: data.pit,
        }))
      }
    }
    setState(prev => {
      const dataItems = isListMode ? items : [...prev.dataItems, ...items];
      return {
        ...prev,
        checkedData: true,
        loadingMoreData: false,
        loading: false,
        totalItems: {
          total: _.get(data, 'total', 0),
          to: _.get(data, 'to', 0),
          rowsPerPage: _.get(data, 'rowsPerPage', 0),
          current: _.get(data, 'page', 1),
          exceeded_limit: _.get(data, 'exceeded_limit', false),
          lengthCurrentPage: items.length
        },
        pageCurrent: !isListMode ? null : _.get(data, 'page', 1),
        next: isListMode ? null : next,
        userData: {
          canManage: _.get(data, 'canManage', false),
          userCanSeePublishedFilter: _.get(data, 'canSeePublicationFilter', false),
        },
        hasData: dataItems.length > 0,
        dataItems,
        ...(initialParams && { parameters: initialParams }),
      }
    }, callback())

  }, [dispatch, isListMode])

  const postInvalid = useCallback(err => {
    if (err.response.status === 401) {
      setState(prev => ({ ...prev, hasData: false, loading: false, loadingMoreData: false, checkedData: true }),
        dispatch(AppActions.openDialog("expiredToken"))
      )
      return;
    }

    if (err.response.status === 410) {
      deletePitContext(() => history.go(0))
      return
    }
    const message = Utils.ajaxErrorGetMessage(err, t)
    setState(prev => ({
      ...prev, hasData: false, loading: false, loadingMoreData: false, checkedData: true, permission: {
        status: false,
        msg: message,
      }
    }))
  }, [dispatch, t, history, deletePitContext])

  const postError = useCallback((err) => {
    const message = Utils.ajaxErrorGetMessage(err, t)
    console.error('Erro ao buscar lista de itens', err)
    setState(prev => ({ ...prev, hasData: false, loading: false, loadingMoreData: false }),
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      })
    )
  }, [enqueueSnackbar, t])

  const doPost = useCallback(() => {
    let params = state.parameters
    if (showSelected) {
      let isDraft = markedItems.some(i => i.draft)
      params = {
        only: getOnlyParams(),
        id: markedItems.map(i => i.id),
        limit: markedItems.length,
        showPublished: "all",
        ...(isDraft && { draft: true }),
      }
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = { ...requestHeaders, cancelToken: source.token }

    axios.post(getUrl(params.isFirstSearch), params, options)
      .then(({ data }) => postReturn(data, params), (err) => postInvalid(err))
      .catch((err) => postError(err))
  }, [
    requestHeaders,
    state.parameters,
    getUrl,
    postInvalid,
    postError,
    postReturn,
    showSelected,
    markedItems,
    getOnlyParams
  ])

  const fixedParameters = useCallback((parameters, page, next) => {
    const isFirstSearch = isListMode ? page <= 0 : !next
    if (isFirstSearch) {
      parameters['isFirstSearch'] = true
      parameters["pit"] = pitContext
    }
    return parametersWithPaging(parameters, page, next)
  }, [isListMode, parametersWithPaging, pitContext])

  const getData = useCallback((parameters = {}, pPage = 0) => {
    const page = getPage(pPage)
    setState(prev => ({
      ...prev,
      loading: true,
      pageCurrent: page,
      parameters: fixedParameters(parameters, page, state.next)
    }))
  }, [fixedParameters, getPage, state.next])

  const removedDuplicates = useCallback((data_, markedData) => {
    let newData = []

    for (let item of data_) {
      if (!markedData.some(i => i.id === item.id)) {
        newData.push(item)
      }
    }
    return newData
  }, [])

  const getRenewedData = useCallback((parameters = {}) => {
    setState(
      prev => ({
        ...prev,
        checkedData: false,
        hasData: false,
        loading: true,
        loadingMoreData: false,
        pageCurrent: 1,
        next: null,
        totalItems: { total: 0, to: 0 },
        dataItensIds: [],
        dataItems: [],
        parameters: fixedParameters(parameters, 1, null)
      })
    )
  }, [fixedParameters]);

  const updateRecord = useCallback(rec => {
    const dataItems = state.dataItems;
    const index = dataItems.findIndex(r => r.id === rec.id)
    for (const prop in rec) {
      dataItems[index][prop] = rec[prop]
    };
    setState(prev => ({ ...prev, dataItems }))
  }, [state.dataItems])

  const hasDataAndNotLoading = useCallback(
    () => state.hasData && !state.loading && !state.loadingMoreData,
    [state.hasData, state.loading, state.loadingMoreData])

  const hasMoreThanOnePage = useCallback(
    () => state.totalItems.total > state.totalItems.rowsPerPage,
    [state.totalItems])

  const hasNext = useCallback(
    () => state.next
      && hasMoreThanOnePage()
      && state.totalItems.rowsPerPage === state.totalItems.lengthCurrentPage ? true : false,
    [state.next, state.totalItems.rowsPerPage, state.totalItems.lengthCurrentPage, hasMoreThanOnePage])

  const hasMoreOnPaging = useCallback(
    () => isListMode && !hasMoreThanOnePage(),
    [hasMoreThanOnePage, isListMode])

  const handleLoadMore = useCallback(() => {
    const parameters = getParamsToItems()
    const shouldLoadMore
      = hasDataAndNotLoading() && (hasMoreOnPaging() || hasNext())
    if (shouldLoadMore) {
      handleSetData('loadingMoreData', true, getData(parameters))
    }
  }, [
    handleSetData,
    hasDataAndNotLoading,
    hasMoreOnPaging,
    hasNext,
    getData,
    getParamsToItems
  ])

  useEffect(() => {
    let isMounted = true
    const limit = getLimit(size, isListMode)
    if (isMounted) setState(prev => ({ ...prev, limit }))
    return () => isMounted = false
  }, [size, isListMode])

  useEffect(() => {
    let isMounted = true
    if (isMounted && state.pageCurrent) {
      dispatch(ItemsActions.setSession({
        name: "page",
        data: state.pageCurrent
      }))
    }
    return () => isMounted = false
  }, [state.pageCurrent, dispatch])

  useEffect(() => {
    let isMounted = true
    if (isMounted && state.loading && Boolean(pitContext)) {
      doPost()
    }
    return () => isMounted = false
  }, [state.loading, doPost, pitContext])

  useEffect(() => {
    let isMounted = true
    if (isMounted && visualization_mode) {
      handleSetData('checkedData', false)
    }
    return () => isMounted = false
  }, [visualization_mode, handleSetData])

  return (
    <ItemsContext.Provider
      value={{
        dataParams,
        handleLoadMore,
        getData,
        getRenewedData,
        getParamsToItems,
        getParamsToItemsWithoutMarked,
        getUrl,
        removedDuplicates,
        isShortcut,
        state,
        handleSetData,
        hasNext,
        hasMoreThanOnePage,
        updateRecord,
        showItemsSelected: showSelected,
      }}
    >
      {children}
    </ItemsContext.Provider>
  )
}

export function useDataItems() {
  const {
    dataParams,
    handleLoadMore,
    getData,
    getRenewedData,
    getParamsToItems,
    getParamsToItemsWithoutMarked,
    getUrl,
    removedDuplicates,
    isShortcut,
    state,
    handleSetData,
    hasNext,
    hasMoreThanOnePage,
    updateRecord,
    showItemsSelected,
  } = useContext(ItemsContext)

  const {
    hasData,
    loading,
    loadingMoreData,
    userData,
    pageCurrent,
    totalItems,
    dataItems,
    permission,
    checkedData,
  } = state

  return {
    loading,
    loadingMoreData,
    hasData,
    dataItems,
    dataParams,
    totalItems,
    handleLoadMore,
    userData,
    getData,
    getRenewedData,
    permission,
    pageCurrent,
    getParamsToItems,
    getParamsToItemsWithoutMarked,
    getUrl,
    removedDuplicates,
    isShortcut,
    checkedData,
    handleSetData,
    hasNext,
    hasMoreThanOnePage,
    updateRecord,
    showItemsSelected,
  }
}
