import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperWidthLg': {
      padding: '20px',
    },
    "& .react-transform-wrapper": {
      maxHeight: "calc(100vh - 341px)"
    }
  },
  title: {
    padding: '0 0 16px 0',
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div.info': {
        display: "flex",
        alignItems: 'center',
      },
      "& * svg": {
        width: "40px",
        height: "40px",
        backgroundColor: '#F4511E',
        borderRadius: "50%",
        padding: "4px",
        marginRight: "10px",
        color: "#fff",
      },
    },
  },
  titleZoom: {
    padding: '0 0 16px 0',
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div.info': {
        display: "flex",
        alignItems: 'center',
      },
      "& * svg": {
        width: "40px",
        height: "40px",
        backgroundColor: '#fff',
        padding: "4px",
        marginRight: "10px",
        color: "#666666",
      },
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: '0',
    marginTop: '20px',
    "& button": {
      padding: "10px 16px",
      minWidth: "140px",
      height: "40px",
      maxHeight: "40px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        maxHeight: "200px",
      },
    },
    "& button.delete": {
      "& svg": {
        color: "#757575",
      },
    },
    "& button.active": {
      backgroundColor: "#F4511E",
      color: "#fff",
      "& svg": {
        color: "#fff !important",
      },
      "&:hover": {
        backgroundColor: darken(0.08, "#F4511E"),
      },
    },
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column'
    },
  },
  zoomDiv: {
    width: '100%',
    height: '100%',
    cursor: 'grab',
    '& .zoomImg': {
      width: '100%',
      height: 'auto'
    }
  },
  buttonActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonZoom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: 64
    },
  },
  gridContainer: {
    marginTop: 16,
  }
}));
