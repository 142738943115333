import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack"
import axios from 'axios'

import {
    makeStyles,
    Divider,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';

import RecipesDownloadsBtns from 'components/RecipesDownloadsBtns'
import DownloadStepsWrapper from './Steps'

import _ from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'
import Utils from "utils/Utils"

const useStyles = makeStyles((theme) => ({
    root: {
        '& .title': {
            padding: '20px 20px 0 20px'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiGrid-container': {
            flexDirection: 'column',
            overflow: 'inherit',
            flexWrap: 'nowrap',
            width: '100%',
            margin: 0,
            '& .MuiGrid-root': {
                maxWidth: '100%'
            }
        }
    },
    btns_root: {
        overflow: 'auto',
        [theme.breakpoints.down("sm")]: {
            maxHeight: '100%'
        },
        '& div.MuiGrid-container': {
            maxHeight: '100%'
        }
    }
}))

const DownloadAsset = ({
    id_asset,
    id_item,
    version_item,
    hasHighLow,
    dates,
    category_id,
}) => {
    const [state, setState] = useState({
        open: false,
        alternativeName: '',
        loading: false,
        hasDataAlternativeName: false,
        dataAlternativeNames: [],
    })

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation();
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
    const classes = useStyles()

    const getData = useCallback((id) => {
        axios
            .get(`${pathServer}/alternative-name/listByCategory/${id}`, requestHeaders)
            .then((result) => {
                const data = _.get(result, 'data.items', [])
                setState(prev => ({
                    ...prev,
                    dataAlternativeNames: data,
                    loading: false
                }))
            })
            .catch((err) => {
                const message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
                setState(prev => ({ ...prev, loading: false }), enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                }))

            })
    }, [pathServer, requestHeaders, enqueueSnackbar, t])

    useEffect(() => {
        let isMounted = true
        if (Boolean(category_id) && Boolean(id_asset)) {
            if (isMounted) setState(prev => ({ ...prev, loading: true }), getData(category_id))
        } else {
            setState(prev => ({ ...prev, loading: false }))
        }
        return () => { isMounted = false }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, getData, category_id, id_asset])

    const handleNameSelect = (evt) => {
        setState(prev => ({ ...prev, alternativeName: _.get(evt, 'target.value', '') }))
    }

    return (
        <>
            {
                state.loading ? (
                    <>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton variant="rect" width={288} height={40} />
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton variant="rect" width={288} height={40} />
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton variant="rect" width={288} height={40} />
                        </div>
                        <Divider />
                    </>
                ) : (
                    <DownloadStepsWrapper
                        componentStep1={
                            <FormControl component="fieldset" style={{ overflow: 'auto' }}>
                                <RadioGroup aria-label="gender" name="gender1" value={state.alternativeName} onChange={handleNameSelect}>
                                    <Divider />
                                    <FormControlLabel value="" control={<Radio color='primary' />} label={t('manager:Taxonomia do item')} style={{ margin: 0 }} />
                                    <Divider />
                                    {state.dataAlternativeNames.map((i) =>
                                        <div key={i.id}>
                                            <FormControlLabel value={i.id} control={<Radio color='primary' />} label={i.title} style={{ margin: 0 }} />
                                            <Divider />
                                        </div>
                                    )}
                                </RadioGroup>
                            </FormControl>
                        }
                        componentStep2={
                            <div className={classes.btns_root} id='btn_content'>
                                <RecipesDownloadsBtns
                                    path={`item/${id_item}/listrecipes`}
                                    data={{
                                        item_id: id_item,
                                        versionIndex: version_item,
                                        date_end: dates.end,
                                        alternativename_id: state.alternativeName,
                                        assets_ids: [id_asset],
                                        hasHighLow: hasHighLow,
                                        options: [
                                            {
                                                id: 1,
                                                title: 'Ativo original',
                                                params: {
                                                    items: [{ id: id_item, version: version_item, assets: [id_asset] }],
                                                    type: 'download',
                                                    synchronous: true,
                                                },
                                                can_use: true,
                                                hasHighLow: true,
                                            },
                                            {
                                                id: 2, title: 'Imagem web em alta', params: {
                                                    items: [{ id: id_item, version: version_item, assets: [id_asset] }],
                                                    type: 'path_clone',
                                                    synchronous: true,
                                                },
                                                can_use: true,
                                                hasHighLow: hasHighLow,
                                            },
                                            {
                                                id: 3, title: 'Imagem web em baixa', params: {
                                                    items: [{ id: id_item, version: version_item, assets: [id_asset] }],
                                                    type: 'path_low',
                                                    synchronous: true,
                                                },
                                                can_use: true,
                                                hasHighLow: hasHighLow,
                                            }
                                        ]
                                    }}
                                />
                            </div>
                        }
                    />
                )
            }
        </>
    );
}

DownloadAsset.defaultProps = {
    id_asset: '',
    id_item: '',
    version_item: 0,
    hasHighLow: false,
    dates: { end: '' },
    category_id: '',
}

export default DownloadAsset