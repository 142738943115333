import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import _ from 'lodash'

import {
    Button,
    TextField,
    Divider,
    Grid,
    Typography
} from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';

import { useSnackbar } from 'notistack'
import Settings from 'utils/Settings'
import { genereteHash, getRequiredLngCombo } from 'utils/Helpers'

const PlatformConfig = () => {
    const [platformName, setPlatformName] = useState('')
    const [platformData, setPlatformData] = useState({})
    const [loading, setLoading] = useState(true)
    const { requestHeaders, pathServer } = useSelector(
        (state) => state.appReducer
    )
    const ID_PLATFORM = useSelector(state =>
        _.get(state, 'appReducer.data.client_id', '')
    )
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const getData = useCallback(() => {
        axios.get(pathServer + "/client/names", requestHeaders)
            .then(function (resp) {
                const { name, platform_name, description } = resp.data;

                setPlatformName(name)
                let dataAPI = {}

                if (typeof platform_name === 'string' &&
                    typeof description === 'string') {
                    dataAPI['pt'] = {
                        platform_name: {
                            value: platform_name,
                            hash: genereteHash(platform_name),
                            hasChanged: false
                        },
                        description: {
                            value: description,
                            hash: genereteHash(description),
                            hasChanged: false
                        }
                    }

                } else {
                    Object.entries(platform_name).forEach(([lng, value]) => {
                        dataAPI[lng] = {
                            platform_name: {
                                value: value,
                                hash: genereteHash(value),
                                hasChanged: false
                            }
                        }
                    })
                    Object.entries(description).forEach(([lng, value]) => {
                        dataAPI[lng] = {
                            ...dataAPI[lng],
                            description: {
                                value: value,
                                hash: genereteHash(value),
                                hasChanged: false
                            }
                        }
                    })
                }

                let data = {}

                Settings.DATA_SUPPORTED_LNG.forEach((item) => {
                    data = {
                        ...data,
                        [item.lng]: {
                            platform_name: {
                                value: '',
                                hash: genereteHash(''),
                                hasChanged: false
                            },
                            description: {
                                value: '',
                                hash: genereteHash(''),
                                hasChanged: false
                            }
                        }
                    }
                })

                setPlatformData({ ...data, ...dataAPI })

                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            });
    }, [pathServer, requestHeaders, enqueueSnackbar, t])

    useEffect(() => {
        getData()
        return () => { }
    }, [getData])


    const handleChange = (evt, lng, fieldName) => {
        const newData = { ...platformData }

        const fieldHash = genereteHash(evt.target.value)

        if (newData[lng][fieldName].hash !== fieldHash) {
            newData[lng][fieldName] = { ...newData[lng][fieldName], hasChanged: true };
        } else {
            newData[lng][fieldName] = { ...newData[lng][fieldName], hasChanged: false };
        }

        newData[lng][fieldName] = { ...newData[lng][fieldName], value: evt.target.value };

        setPlatformData(newData)
    }

    const validateFormEmpty = () => {
        const newData = { ...platformData }
        newData['pt']['error_platform_name'] = `* ${t("common:Este campo é obrigatório")}`
        setPlatformData(newData)
    }

    const validateFields = () => {
        const newData = { ...platformData }

        Object.entries(newData).forEach(([lng, item]) => {            
            if (_.get(item, 'platform_name.value', false) ||
                _.get(item, 'description.value', false)
            ) {
                Object.entries(item).forEach(([field, data]) => {
                    switch (field) {
                        case 'platform_name':
                            if (_.get(data, 'value', '').trim().length === 0) {
                                newData[lng]['error_platform_name'] = `* ${t("common:Este campo é obrigatório")}`
                            } else {
                                delete newData[lng]['error_platform_name']
                            }
                            break;
                        case 'description':
                            if (_.get(data, 'value', '').trim().length === 0) {
                                newData[lng]['error_description'] = `* ${t("common:Este campo é obrigatório")}`
                            } else {
                                delete newData[lng]['error_description']
                            }
                            break;
                        default:
                            break;
                    }
                })
            }
        })

        let invalidForm = false
        Object.entries(newData).forEach(([lng, item]) => {
            if (item.error_description || item.error_platform_name) {
                invalidForm = true
            }
        })

        setPlatformData(newData)
        return invalidForm
    }

    const getParameters = () => {
        let params = {}

        Object.entries(platformData).forEach(([lng, item]) => {
            if (_.get(item, 'platform_name.value', false) &&
                _.get(item, 'description.value', false)
            ) {
                params = {
                    platform_name: {
                        ...params.platform_name,
                        [lng]: _.get(item, 'platform_name.value', '')
                    },
                    description: {
                        ...params.description,
                        [lng]: _.get(item, 'description.value', '')
                    }
                }
            }
        })

        return params
    }

    const submitForm = (e) => {
        e.preventDefault();

        if (validateFields()) {
            return
        }
        
        const parameters = getParameters()

        if(_.isEmpty(parameters)) {
            validateFormEmpty()
            return
        }

        setLoading(true)
        axios.put(`${pathServer}/client/${ID_PLATFORM}`, parameters, requestHeaders)
            .then((resp) => {
                setLoading(false)
                enqueueSnackbar(resp.headers['x-message'], {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                })
            })
            .catch((err) => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            });
    }

    const content = (dataLng) => {
        return (
            <Grid container spacing={3} key={dataLng.lng}>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Divider />
                </Grid>

                <Grid item xs={12} style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <TranslateIcon fontSize='small' style={{ color: '#666' }} />
                    <Typography variant="body1" style={{ color: '#666', marginLeft: 10, fontWeight: 500 }}>
                        {t(`manager:${_.get(dataLng, 'short_name', '')}`)}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        name="platform_name"
                        label={dataLng.lng !== 'pt' ? t("manager:Nome da plataforma") : `${t("manager:Nome da plataforma")}*`}
                        value={_.get(platformData, `${dataLng.lng}.platform_name.value`, '')}
                        error={Boolean(_.get(platformData, `${dataLng.lng}.error_platform_name`, false))}
                        helperText={_.get(platformData, `${dataLng.lng}.error_platform_name`, '')}
                        onChange={(event) => handleChange(event, dataLng.lng, "platform_name")}
                        style={{ width: '100%' }}
                        disabled={loading}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        name="description"
                        label={dataLng.lng !== 'pt' ? t("common:Descrição") : `${t("common:Descrição")}*`}
                        value={_.get(platformData, `${dataLng.lng}.description.value`, '')}
                        error={Boolean(_.get(platformData, `${dataLng.lng}.error_description`, false))}
                        helperText={_.get(platformData, `${dataLng.lng}.error_description`, '')}
                        multiline
                        rows={4}
                        onChange={(event) => handleChange(event, dataLng.lng, "description")}
                        style={{ width: '100%' }}
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        )
    }

    const disabledSubmit = () => {
        let changed = false

        Object.entries(platformData).forEach(([key, item]) => {
            if (!changed) {
                changed = _.get(item, 'platform_name.hasChanged', false) ||
                    _.get(item, 'description.hasChanged', false)
            }
        })

        return changed
    }


    const DISABLED_SUBMIT = disabledSubmit()
    
    return (
        <div>
            <Grid item xs={12} style={{ margin: '30px 0' }}>
                <Typography variant="body1">
                    {t('manager:A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma principal preenchido as informações serão utilizadas como padrão em todos os idiomas.')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    id="name"
                    label={t("common:Nome da empresa")}
                    value={platformName}
                    disabled
                    style={{ width: '100%', margin: 0 }}
                />
            </Grid>            

            {getRequiredLngCombo().map(item => content(item))}           

            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: 20
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={evt => submitForm(evt)}
                    style={{ height: 40 }}
                    disabled={!DISABLED_SUBMIT || loading}
                >
                    {t("common:Salvar")}
                </Button>
            </div>

        </div>

    )
}

export default PlatformConfig