import React, { Fragment, useState } from "react";
import _ from "lodash"
import { withTranslation } from "react-i18next";

import { Paper, Typography, TextField, Button, Fade } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStyles } from "./styles";
import Settings from 'utils/Settings'

const ForgotPassword = ({
  t,
  handleHistory,
  handleSubmit,
  responseStatus,
  loading,
}) => {
  const [state, setState] = useState({
    email: "",
    emailError: false,
    disabled: true
  })
  const classes = useStyles()

  const validation = (evt) => {
    evt.preventDefault();
    const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i

    if (!regex.test(state.email)) {
      setState(prev => ({ ...prev, emailError: true }))
      return
    }

    if (!state.emailError) {
      handleSubmit(evt, { email: state.email })
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter" && !state.emailError)
      validation(evt)
  };

  const handleSetEmail = (value) => {
    setState(prev => ({ ...prev, email: value, emailError: false }))
  }

  return (
    <Fragment>
      <Paper elevation={2} className={classes.content} onKeyDown={(evt) => handleKeyDown(evt)}>
        <div className="logoImageLogin" />

        {responseStatus !== Settings.RESPONSE_STATUS.SUCCEEDED && (
          <Fragment>
            <div className="containerText">
              <Typography>
                {t(
                  "login:Insira abaixo o endereço de e-mail associado à sua conta e, em seguida, enviaremos um e-mail com o link de redefinição de senha"
                )}
              </Typography>
            </div>
            <form className="formLogin">
              <div
                style={{ width: "100%", marginBottom: 20 }}
              >
                <TextField
                  error={state.emailError}
                  helperText={state.emailError ? t("common:E-mail inválido") : null}
                  variant="outlined"
                  id="email"
                  label="E-mail"
                  name="email"
                  value={state.email}
                  autoComplete="email"
                  onChange={(evt) => handleSetEmail(_.get(evt, 'target.value', ""))}
                />
              </div>
              <Button
                disabled={state.email === "" || loading || state.emailError}
                type="submit"
                variant="contained"
                color="primary"
                onClick={validation}
                style={{ marginBottom: 20 }}
              >
                {loading ? (
                  <Fade
                    in={loading}
                    style={{
                      transitionDelay: loading ? "800ms" : "0ms",
                    }}
                    unmountOnExit
                  >
                    <CircularProgress color="inherit" />
                  </Fade>
                ) : (
                  t("common:Enviar")
                )}
              </Button>
            </form>
          </Fragment>
        )}

        {responseStatus === Settings.RESPONSE_STATUS.SUCCEEDED && (
          <Fragment>
            <div className="containerText">
              <Typography>
                {`${t("Solicitação enviada com sucesso")} ${t("Confira a caixa de entrada e spam de ")}`}<br />
                <b style={{ fontWeight: "500" }}>{`${state.email}`}</b>
              </Typography>

              <CheckCircleRoundedIcon color="primary" />
            </div>
          </Fragment>
        )}

        <button
          className="btnLink"
          onClick={() => handleHistory("/login")}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',
          }}
        >
          {t("login:Ir para o login")}
        </button>
      </Paper>
    </Fragment>
  );
};

export default withTranslation(["common", "login"])(ForgotPassword);
