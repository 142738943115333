import React, { useState, useEffect, useCallback } from "react"
import CategoryAttributes, { createForm, validation } from 'components/CategoryAttributes'
import MUIRichTextEditor from "mui-rte"
import axios from "axios"
import Skeleton from "@material-ui/lab/Skeleton"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import SelectUI from '../../../../components/ComboBox/SelectUI'
import PaperTitle from "../PaperTitle"
import _ from "lodash"
import { useStyles } from "./styles"
import Utils from "../../../../utils/Utils"
import { useSnackbar } from "notistack"
import { Chip, Avatar, Button, CircularProgress, Fade, FormHelperText, Grid } from "@material-ui/core"

import { EditorState, convertToRaw } from "draft-js"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"

import CheckSVG from "../../../../imagens/ui/fact_check-24px.svg"
import Settings from 'utils/Settings'
import { withStyles } from "@material-ui/core/styles"

const ContentSVG = withStyles({
  svg: {
    marginRight: 20,
    filter:
      "invert(39%) sepia(5%) saturate(2%) hue-rotate(8deg) brightness(99%) contrast(89%)",
  },
})(({ classes, thumbnail, title }) => (
  <img
    src={thumbnail}
    alt={title}
    width="48"
    height="48"
    className={classes.svg}
  />
))

const RequestTool = ({ idItem, componentSlug }) => {
  const [dataTools, setDataTools] = useState({
    data: {},
    loading: true,
    loadingSubmit: false,
    messageUser: '',
    defaultMessageUser: '',
    attributes: [],
    dataFields: {},
    errorFields: {},
    recipientsError: '',
    messageError: ''
  })

  const { t } = useTranslation()
  const { palette, pathServer, requestHeaders } = useSelector(
    (state) => state.appReducer
  )
  const classes = useStyles({ palette })
  const { enqueueSnackbar } = useSnackbar()

  const createTheme = createMuiTheme()
  const defaultTheme = Object.assign(createTheme, {
    overrides: {
      MUIRichTextEditor: {
        container: {
          margin: 0,
        },
        toolbar: {
          borderBottom: "1px solid #eee",
          padding: "2px",
          "& svg": {
            fontSize: "1.2rem",
          },
        },
        placeHolder: {
          margin: 0,
          padding: "19px 16px",
          fontSize: "0.875rem",
        },
        editorContainer: {
          margin: 0,
          padding: "19px 16px",
        },
      },
    },
  })

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      setDataTools(prev => ({ ...prev, data: {}, messageUser: '', loading: true }))
      await axios
        .get(`${pathServer}/tool/slug/${componentSlug}`, requestHeaders)
        .then((result) => {
          if (isMounted) {
            const DATA = {
              ...result.data,
              recipients: _.get(result, 'data.recipients', []).map((i) => ({
                label: i.name,
                value: i.id,
              })),
            }
            const dataFields = createForm(_.get(result, 'data.attributes', []))

            setDataTools(prev => ({
              ...prev,
              data: DATA,
              loading: false,
              ...(result.data.hasOwnProperty('attributes') && { 
                attributes: result.data.attributes,
                dataFields
               }),
            }))
          }
        })
        .catch((err) => {
          if (isMounted) {
            const message = err.response
              ? err.response.data.message || err.response.headers["x-message"]
              : t("manager:Erro inesperado. Contate o suporte")
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })
            setDataTools(prev => ({ ...prev, loading: false }))
          }
        })
    }
    getData()
    return () => {
      isMounted = false
    }
  }, [pathServer, requestHeaders, componentSlug, enqueueSnackbar, t])
 

  const handleChangeSelect = (recipient) => {
    setDataTools(prev => ({
      ...prev,
      data: {
        ...prev.data, recipients: prev.data.recipients.map((i) => {
          if (i.value === recipient.value) {
            i.disabled = true
            i.isSelected = true
          }
          return i
        })
      }
    }))
  }

  const handleRemoveRecipients = (item) => {
    setDataTools(prev => ({
      ...prev,
      data: {
        ...prev.data, recipients: prev.data.recipients.map((i) => {
          if (i.value === item) {
            i.disabled = false
            i.isSelected = false
          }
          return i
        })
      }
    }))
  }

  const formError = useCallback(() => {
    let formValid = true
    let newstate = {}
    
    if (_.get(dataTools, 'messageUser.text', '') === '') {
      newstate.messageError = 'Este campo é obrigatório'
      formValid = false
    } else {
      newstate.messageError = ''
    }

    if (_.get(dataTools, 'data.recipients', []).filter((i) => i.isSelected).length === 0) {
      newstate.recipientsError = 'Este campo é obrigatório'
      formValid = false
    } else {
      newstate.recipientsError = ''
    }
    
    const errorFields = validation(dataTools.dataFields, dataTools.attributes)
    
    if (!_.isEmpty(errorFields)) {
      formValid = false
    }

    setDataTools(prev => ({
      ...prev,      
      errorFields,
      ...newstate
    }))
    return formValid
  }, [dataTools])

  const formattedAttributes = () => {
    let formattedAttributes = []
    Object.entries(dataTools.dataFields).forEach(([key, value]) => {
      const find = dataTools.attributes.find(i => i.id === key)
      if (Boolean(find) && Boolean(value)) {
        formattedAttributes.push({
          "value": Array.isArray(value) ? value.map(i => i.value) : _.get(value, 'value', value),
          "id": key         
        });
      }
    })
  
    return formattedAttributes
  }

  const handleSubmit = () => {
    if (!formError()) {
      return
    }

    setDataTools(prev => ({
      ...prev,
      loadingSubmit: true
    }))

    const payload = {
      recipients: dataTools.data.recipients
        .filter((i) => i.isSelected)
        .map((i) => i.value),
      item_id: idItem,
      attributes: formattedAttributes()
    }

    if (dataTools.messageUser.text && dataTools.messageUser.formatter) {
      payload["message"] = dataTools.messageUser
    }

    axios
      .post(
        `${pathServer}/tool/${componentSlug}/send`, payload, requestHeaders)
      .then((result) => {         
          setDataTools(prev => ({
            ...prev,
            loadingSubmit: false,
            defaultMessageUser: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
            messageUser: '',
            data: {
              ...prev.data, recipients: prev.data.recipients.map((i) => {
                i.disabled = false
                i.isSelected = false
                return i
              })
            },
            dataFields: createForm(prev.attributes)
          }),
          enqueueSnackbar("Email enviado.", {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          }))
      })
      .catch((err) => {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("manager:Erro inesperado. Contate o suporte")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
        setDataTools(prev => ({
          ...prev,
          loadingSubmit: false
        }))
      })
  }

  const handleChangeAttributes = (id, value) => {
    const newAttributes = { ...dataTools.dataFields }
    Object.entries(newAttributes).forEach(([key, item]) => {
      if (key === id) {
        newAttributes[key] = value
      }
    })
    setDataTools(prev => {
      let errorFields = prev.errorFields
      delete errorFields[id]
      return {
        ...prev,
        dataFields: newAttributes,
        errorFields: errorFields
      }
    })    
  } 

  const handleChangeMessageUser = (editorState) => {
    const editorConverted = convertToRaw(editorState.getCurrentContent())

    const textPerLine = editorConverted.blocks.map((el) => el.text)

    const msg = {
      text: textPerLine.join(" "),
      formatter: {
        name: "MUI-RTE/DraftJs",
        version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
        format: JSON.stringify(editorConverted),
      },
    }
    setDataTools(prev => ({
      ...prev,
      messageUser: msg
    }))
  }

  const dataIsSelecteds = dataTools.data.recipients
    ? dataTools.data.recipients.filter((i) => i.isSelected)
    : []

  return (
    <>
      {dataTools.loading ? (
        <>
          <PaperTitle />
          <div className={classes.paperWrapper}>
            <div className={classes.rowWrapper}>
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
            </div>
            <div className={classes.rowWrapper}>
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
            </div>
            <div className={classes.rowWrapper}>
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
            </div>
          </div>
        </>
      ) : (
        <>
          <PaperTitle
            title={dataTools.data.title}
            iconSVG_IMG={
              <ContentSVG
                thumbnail={dataTools.data.datathumbnail ? dataTools.data.thumbnail : CheckSVG}
                title={dataTools.data.title ? dataTools.data.title : t("common:Solicitações")}
              />
            }
          />

          <div className={classes.paperWrapper}>
            {_.get(dataTools, "data.support_text", false) && (
              <div style={{ marginBottom: 20 }}>
                <MUIRichTextEditor
                  defaultValue={Utils.getFormattedDescription(
                    dataTools.data.support_text,
                    t
                  )}
                  className={classes.message}
                  inlineToolbar={false}
                  toolbar={false}
                  readOnly={true}
                  style={{ fontSize: 12 }}
                />
              </div>
            )}

            {_.get(dataTools, "data.recipients", false) && (
              <div>
                <SelectUI
                  title={t("common:Selecione pelo nome")}
                  error={dataTools.recipientsError}
                  name="recipients"
                  handleChangeValue={(value) => handleChangeSelect(value)}
                  selectValues={dataTools.data.recipients}
                />
              </div>
            )}
            {dataIsSelecteds.length > 0 && (
              <div
                style={{
                  border: "1px solid #C4C4C4",
                  borderRadius: 4,
                  padding: 10,
                  margin: "10px 0",
                }}
              >
                {dataIsSelecteds.map((item, key) => (
                  <Chip
                    disabled={dataTools.loadingSubmit}
                    key={key}
                    label={item.label}
                    onDelete={() => handleRemoveRecipients(item.value)}
                    avatar={<Avatar>{item.label[0]}</Avatar>}
                    style={{ margin: 5, maxWidth: "100%" }}
                  />
                ))}
              </div>
            )}
          
            {
              dataTools.attributes.length > 0 &&
              <Grid container spacing={2} className={classes.categoryAttributes} style={{marginTop: 8}}>                
                <CategoryAttributes                      
                      isColumnChip
                      attributes={dataTools.attributes}
                      dataFields={dataTools.dataFields}
                      errorFields={dataTools.errorFields}
                      isDisabled={dataTools.loadingSubmit}
                      changeAttributes={handleChangeAttributes}                                           
                    />
              </Grid>
            }
            <div style={{ margin: "20px 0", width: "100%" }}>
              <div className={dataTools.messageError ? classes.errorRichTextEditor : classes.richTextEditor}>
                <MuiThemeProvider theme={defaultTheme}>
                  <MUIRichTextEditor
                    maxLength={400}
                    controls={["bold", "italic", "underline", "link"]}
                    label={t("common:Mensagem")}
                    value={dataTools.defaultMessageUser}
                    onChange={handleChangeMessageUser}
                    readOnly={dataTools.loadingSubmit}
                  />
                </MuiThemeProvider>
              </div>
              {
                dataTools.messageError &&
                <FormHelperText style={{ color: '#f44336', marginLeft: 11 }}>
                  {dataTools.messageError}
                </FormHelperText>
              }
            </div>
            <Button
              disabled={dataTools.loadingSubmit}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              {dataTools.loadingSubmit ? (
                <Fade
                  in={dataTools.loadingSubmit}
                  style={{
                    transitionDelay: dataTools.loadingSubmit ? "800ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <CircularProgress color="inherit" size={20} />
                </Fade>
              ) : (
                <>{_.get(dataTools, "data.submit_message", t("common:Enviar"))}</>
              )}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

RequestTool.defaultProps = {
  data: {
    name: "Erro ao carregar dados",
    description: "not-description",
    attribute: [],
  },
}
export default RequestTool
