import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({  
  rootDropzone: {
    backgroundColor: '#F0F0F0',
    border: '1px dashed #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: 4,
    width: '100%',
    height: 196,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        color: '#B3B3B3'
      },
      '& .pdf': {
        width: 372, 
        height: 195, 
        overflow: "hidden",
        [theme.breakpoints.down('xs')]: {
          width: 290,
        }
      },
      '& .image': {
        maxWidth: 372,
        '& img': {
          marginTop: 4,          
          height: 193,
        }
      }
    }
  },
  rootDialog:{
    '& .MuiDialog-paperWidthSm': {
     margin: '0 !important',
     padding: '20px',
     minWidth: '550px',
     [theme.breakpoints.down("sm")]: {
      minWidth: '100%'
    },
    }
  },
}))
