import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import _ from 'lodash'
import SelectUI from './SelectUI'
export default class CustomComboBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      value: {
        label: '',
        value: '',
      },
      hasData : false,
      data: {},
      disabled: (props.disabled) ? !!props.disabled : false,
      route: '',
      title: '',
      type_option_filter: 'name'
    }

    this.getValuesFormated = this.getValuesFormated.bind(this)
  }

  getValuesFormated(value) {
    return {
      label: Boolean(_.get(value, 'division.name', ''))
        ? _.get(value, 'division.name', '') + ' > ' + _.get(value, 'name', '')
        : _.get(value, 'name', ''),
      name: _.get(value, 'name', ''),
      value: _.get(value, 'id', ''),
      canPublish: _.get(value, 'canPublish', true), 
      division: _.get(value, 'division.name', '')
    }
  }

  data() {
    const SELF = this;
    const {requestHeaders, triggerError, optionSelected} = this.props;
    const {data, hasData, route, method, type_option_filter} = this.state;

    if(hasData) {
      if (!optionSelected) {
        return;
      }
      
      let selected = data.find(item=> item[type_option_filter] === optionSelected);
            
      if(!selected){
        return
      }
      
      const value = this.getValuesFormated(selected)

      this.setState({
        ...SELF.state,
          value
      })
    }

    const getValue = this.getValuesFormated

    axios({
      method: method,
      url: route,
      headers: { ...requestHeaders.headers },
    })   
      .then(function ({data}) {       
        const DATA = data.items ? data.items : data
        const newState = {
          ...SELF.state,
          loading: false,
          hasData: true,
          data: DATA
        };
        
        if (optionSelected){
          let selected = DATA.find(item=> item[type_option_filter] === optionSelected);
                  
          if(selected){
            newState['value'] = getValue(selected)
          }
        }
        SELF.setState(newState);
      }, function (resp) {
        SELF.setState({...SELF.state, loading: false});
        toast.error(resp.headers['x-message']);
      })
      .catch((err) => triggerError(err));
  }

  componentDidMount(){
    if(!this.state.hasData){
      this.data();
    }
  }

  componentDidUpdate (prevProps, prevState) {    
    if (this.props.optionSelected && this.props.optionSelected !== prevProps.optionSelected) {
      this.data();
    }
    return true;
  }

  render() {
    let selectValues = [];
    let data = this.state.data || [];
    let disabled = this.state.disabled;    
    let disabledItems = this.props.hasOwnProperty('disabledItems') ? this.props.disabledItems : [];
    
    const getValue = this.getValuesFormated

    Object.keys(data).map(function (i) {
      let object = data[i] || []; 
      const value = getValue(object)   

      selectValues.push({
        disabled: (disabledItems.indexOf(object.id) !== -1),
        ...value,        
      });

      return false;
    })

    return (     
        <SelectUI 
          selectValues={selectValues}
          disabled={disabled}
          nameField={this.state.route}
          title={this.state.title}
          valueSelect={this.state.value}
          handleChangeValue={this.props.handleChange} 
          error={this.props.error}  
          fieldRequired={this.props.field_required}       
        />    
    );
  }
}
