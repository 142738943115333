import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import axios from 'axios'
import {
    ListItemIcon,
    ListItem,
    Chip,
    ListItemText,
    Collapse,
    Typography,
    Button
} from '@material-ui/core'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import People from '@material-ui/icons/People'
import { ReactComponent as PersonCheckSVG } from './icons/person_check.svg'
import { ReactComponent as DeployedCodeSVG } from './icons/deployed_code.svg'
import Skeleton from "@material-ui/lab/Skeleton"
import RecognitionAndDemarcation from 'components/RecognitionAndDemarcation'

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        zIndex: 1300,
        [theme.breakpoints.down("sm")]: {
            height: 'auto',
            top: 0
        }
    },
    paper: {
        width: '85%',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            minHeight: '100vh',
            borderRadius: 0,
        }
    },
    paperHeader: {
        '& .title': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
            color: '#666',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
            },
            '& svg': {
                color: '#666',
            },
            '& h6': {
                marginLeft: 10
            }
        }
    },
    paperContent: {
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        '& .image': {
            width: '100%',
            minWidth: 100,
            minHeight: 400,
            marginRight: 20
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            paddingBottom: 80,
            '& .image': {
                minWidth: '100%',
                height: 170,
                minHeight: 100,
                marginRight: 0,
            },
        }
    },
    rootTags: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'auto',
        maxHeight: 400,
        margin: '8px 0',
        gap: "8px",
    }
}))

const getSkeletonChip = () => {
    const MARGIN_SKELETON = 5
    const BORDER_RADIUS_SKELETON = 16

    return (
        <Skeleton
            variant="rect"
            width="50px"
            height="20px"
            style={{ marginLeft: MARGIN_SKELETON, borderRadius: BORDER_RADIUS_SKELETON }}
        />
    )
}

export default function RecognitionAsset({
    DATA_ASSET,
    id_item,
    isDrawerOpen,
    setDrawerOpen,
    modelType = "person"
}) {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        data: [],
        loading: true,
        error: false,
        dialog_open: false
    })
    const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)

    const classes = useStyles()
    const { t } = useTranslation()

    const getData = useCallback((assetId, itemId) => {
        axios.get(`${pathServer}/item/${itemId}/asset/${assetId}/${modelType === "person" ? "persons" : "objects"}`, requestHeaders)
            .then(function ({ data }) {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: data
                }))
            })
            .catch((err) => {
                console.log(err)
                setState(prev => ({
                    ...prev,
                    loading: false,
                    error: true
                }))
            })
    }, [pathServer, requestHeaders, modelType])

    useEffect(() => {
        let isMounted = true
        if (isMounted && open && DATA_ASSET.id && id_item) {
            getData(DATA_ASSET.id, id_item)
        }
        return () => { isMounted = false }
    }, [getData, DATA_ASSET.id, id_item, open])


    useEffect(() => {
        let isMounted = true
        if (isMounted && !isDrawerOpen) {
            setOpen(false)
        }
        return () => { isMounted = false }
    }, [isDrawerOpen])

    const handleOpen = () => {
        setDrawerOpen()
        setOpen(prev => !prev)
    }

    const handleOpenDialog = () => {
        setState(prev => ({ ...prev, dialog_open: !prev.dialog_open }))
    }

    const isModelPerson = (modelType === "person")

    return (
        <div>
            <ListItem button onClick={handleOpen}>
                <ListItemIcon>
                    {isModelPerson ? <People /> : <DeployedCodeSVG style={{ fill: "rgba(0, 0, 0, 0.54)" }} />}
                </ListItemIcon>
                <ListItemText primary={isModelPerson ? t('DAM:Pessoas') : t('DAM:Objetos')} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{ padding: "0 8px 0 72px" }}>
                    {
                        state.loading && (
                            <>
                                <div>
                                    <Skeleton variant="text" width={40} style={{ margin: '10px 0' }} />
                                    <div style={{ display: 'flex' }}>
                                        {getSkeletonChip()}
                                        {getSkeletonChip()}
                                        {getSkeletonChip()}
                                        {getSkeletonChip()}
                                    </div>
                                </div>
                                <div>
                                    <Skeleton variant="text" width={40} style={{ margin: '10px 0' }} />
                                    <div style={{ display: 'flex' }}>
                                        {getSkeletonChip()}
                                        {getSkeletonChip()}
                                        {getSkeletonChip()}
                                        {getSkeletonChip()}
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        state.data.length > 0 ?
                            <div className={classes.rootTags}>
                                {
                                    !state.error && state.data.length > 0 &&
                                    state.data.map((i) => {
                                        if (i.name) {
                                            return <Chip key={i.index} size="small" label={i.name.toUpperCase()} color='primary' />
                                        }
                                        return null
                                    })
                                }
                            </div> : (
                                <Typography variant="body1">
                                    {t('common:Nenhum dado encontrado.')}
                                </Typography>
                            )
                    }
                    {
                        state.error && !state.loading &&
                        <Typography variant="body1">
                            {t('common:Erro ao carregar os dados, contate o suporte')}
                        </Typography>
                    }
                    <div style={{ width: '100%', marginBottom: 16 }}>
                        <Button
                            onClick={handleOpenDialog}
                            color="primary"
                            variant='contained'
                            startIcon={isModelPerson ? <PersonCheckSVG /> : <DeployedCodeSVG style={{ fill: "white" }} />}
                        >
                            {isModelPerson ? t('common:Reconhecer pessoas') : t('common:Reconhecer objetos')}
                        </Button>
                    </div>
                </div>
            </Collapse>
            <RecognitionAndDemarcation
                open={state.dialog_open}
                handleClose={handleOpenDialog}
                DATA_ASSET={DATA_ASSET}
                modelType={modelType}
            />
        </div>
    )
}
