import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'
import axios from "axios";
import { withTranslation } from "react-i18next";
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router'
import Edit from "./components/Edit";
import actions from 'flux/actions/AppActions'
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Slide, Avatar } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent';
import { useStyles } from "./styles";
import Loading from 'components/Loading'

import Settings from 'utils/Settings'
import Utils from 'utils/Utils'

import { useDataAssetIntegra } from "../../context"
import _ from 'lodash'
import { removeObsoleteUrlSessionStorage } from 'utils/Helpers'

const ItemContainer = ({ open, closeDialog, t, slugItem, name }) => {
  const [dataItem, setDataItem] = useState({})
  const [loading, setLoading] = useState(false)
  const { requestHeaders, pathServer, markedItems } = useSelector(
    (state) => state.appReducer
  )
  const { getDataItem } = useDataAssetIntegra()
  const classes = useStyles();
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const paramsUrl = useParams()

  const getData = useCallback((isMounted) => {   
    axios
      .get(`${pathServer}/item/form/${slugItem}`, requestHeaders)
      .then((resp) => {
        if (isMounted) {
          let DATA = {
            ...resp.data.item,
            canPublish: { value: _.get(resp.data, 'item.canPublish', false), id: 'division' }
          }

          if (resp.data.item.validity_date) {
            DATA['validity_date'] = Utils.formatDate(resp.data.item.validity_date, 'YYYY-MM-DD')
          }

          if (resp.data.item.start_date) {
            DATA['start_date'] = Utils.formatDate(resp.data.item.start_date, 'YYYY-MM-DD')
          }

          if (_.get(resp.data, 'item.publishPermisions', false)) {
            if (_.get(resp.data, 'item.publishPermisions.canPublishCategory', false)) {
              DATA.canPublish = { value: true, id: 'category' }
            }
            if (_.get(resp.data, 'item.publishPermisions.canPublishSegment', false)) {
              DATA.canPublish = { value: true, id: 'segment' }
            }
            if (_.get(resp.data, 'item.publishPermisions.canPublishDivision', false)) {
              DATA.canPublish = { value: true, id: 'division' }
            }
          }

          DATA.initial_publish = `${_.get(resp.data, 'item.published', true)}_${DATA.validity_date || ''}_${DATA.start_date || ''}`

          setDataItem(DATA)
        }
      }).catch((err) => {
        const message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao buscar dados do item, tente novamente.`))
        if (isMounted) {
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }
      });
  }, [requestHeaders, pathServer, enqueueSnackbar, slugItem, t])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && _.isEmpty(dataItem)) getData(isMounted)
    return () => { isMounted = false }
  }, [getData, dataItem])

  const updatePage = useCallback((data) => {
    try {
      const url = `/dam/${paramsUrl.region}/${_.get(data, 'division', paramsUrl.division)}/${_.get(data, 'segment', paramsUrl.segment)}/${_.get(data, 'category', paramsUrl.category)}/${_.get(data, 'taxonomy', paramsUrl.item)}`

      if (_.get(history, 'location.pathname', '') !== url) {
        history.push(url)
        removeObsoleteUrlSessionStorage()
        return
      }
      const callback = () => {
        getDataItem()
        closeDialog()
      }
      setLoading(false, callback())
    } catch (err) {
      console.log(err)
    }
  }, [
    history,
    paramsUrl.category,
    paramsUrl.division,
    paramsUrl.item,
    paramsUrl.region,
    paramsUrl.segment,
    closeDialog,
    getDataItem
  ])

  const handleSubmitEdit = useCallback((evt, dataEdit, pathItem) => {
    evt.preventDefault();
    setLoading(true,
      axios
        .put(`${pathServer}/item/${dataItem.id}`, dataEdit, requestHeaders)
        .then(
          function (resp) {
            const updateSetmarkedItems = markedItems.map((item) => {
              if (item.id === dataItem.id) {
                return { ...item, ...dataEdit, taxonomy: resp.data.taxonomy };
              }
              return item;
            });
            if (updateSetmarkedItems.length > 0) {
              dispatch(actions.setmarkedItems(updateSetmarkedItems))
            }
            enqueueSnackbar(resp.headers["x-message"], {
              ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
            })
            updatePage({ ...pathItem, taxonomy: _.get(resp, 'data.taxonomy.slug', null) })
          })
        .catch((err) => {
          let message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao salvar dados do item, tente novamente.`))

          if (_.get(err, 'response.status', '') === 409 && _.get(err, 'response.data.taxonomy', false)) {
            message = _.get(err, 'response.data.taxonomy')
          }

          if (_.get(err, 'response.status', '') === 422 && _.get(err, 'response.data.validity_date', false)) {
            message = _.get(err, 'response.data.validity_date')
          }

          if (_.get(err, 'response.status', '') === 406 && _.get(err, 'response.data.secondary_key_duplicated')) {
            message = _.get(err, 'response.data.secondary_key_duplicated')
          }

          setLoading(false,
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }))
        }))
  }, [dataItem.id, dispatch, enqueueSnackbar, updatePage, markedItems, pathServer, requestHeaders, t])

  return (
    <Fragment>
      <Dialog fullScreen open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Avatar
              alt="Logo Cliente"
              src={localStorage.getItem("logoInsideJPG")}
              onClick={closeDialog}
              style={{
                cursor: "pointer",
                marginRight: 20,
                width: 60,
                height: 60,
              }}
            />
            <div className="title">
              <Typography variant="h6">
                {t('DAM:Editar informações do item')}
              </Typography>
              <Typography variant="body2">
                {name}
              </Typography>
            </div>
            <IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Slide direction="up" in={open}>
          <DialogContent style={{ padding: 0, backgroundColor: '#eee', }}>
            {loading && <Loading showLoading />}
            {
              !_.isEmpty(dataItem) && (
                <Edit
                  dataItem={dataItem}
                  canPublish={_.get(dataItem, "canPublish", { value: false, id: 'error' })}
                  t={t}
                  handleSubmitEdit={handleSubmitEdit}
                  closeDialog={closeDialog}
                />
              )
            }
          </DialogContent>
        </Slide>
      </Dialog>
    </Fragment>
  );
};

export default withTranslation(["login", "common"])(ItemContainer)
