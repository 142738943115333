import React, { useEffect, useState, useCallback } from "react"
import axios from "axios"
import MUIRichTextEditor from "mui-rte"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useSnackbar } from "notistack"
import { Creators as ItemsActions } from "flux/ducks/Items"
import Icon from "@mdi/react"
import { mdiWallpaper } from "@mdi/js"

import {
  Paper,
  Typography,
  Chip,
  Avatar,
  Button,
  Tooltip,
  Grid,
  Container,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  OutlinedInput,
  Menu,
  MenuItem,
} from "@material-ui/core"

import UploadBucketDialog from '../UploadBucketDialog'
import ToolComment from '../ToolComment'
import HeaderPage from '../HeaderPage'
import CustomLoading from "./CustomLoading"
import ImageLazyLoad from "components/ImageLazyLoad"
import CheckSVG from "imagens/ui/fact_check-24px.svg"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import InputAdornment from '@material-ui/core/InputAdornment'
import Settings from 'utils/Settings'
import Utils from "utils/Utils"

import { useStyles } from "./styles"
import { withStyles } from '@material-ui/core/styles'
import CommentReprovedDialog from "../CommentReprovedDialog"

import ToolDetailsProvider, { useDataToolDetails } from "../context"
import DialogItemLink from './DialogItemLink'

const StyledMenu = withStyles({
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const Details = ({ description, match, workflow }) => {
  const [dataItem, setDataItem] = useState({ loading: true, openDialog: false })
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const SLUG = match.params.slug
  const ID = match.params.id
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { pathServer, requestHeaders, userData: { region } } = useSelector(
    (state) => state.appReducer
  )
  const { getDataAllComments } = useDataToolDetails()

  const getData = useCallback(async () => {
    let resultData = {}

    try {
      const { data } = await axios.get(`${pathServer}/tool/${SLUG}/record/${ID}`, requestHeaders)
      const task = { ...data.item, title: data.item.name }

      if (!workflow) {
        delete task.links
        delete task.description
        delete task.params_for_item
        delete task.approvers
      }

      resultData = task
    } catch (err) {
      resultData.errorData = true
    }

    setDataItem({
      ...resultData,
      loading: false,
    })
  }, [pathServer, requestHeaders, SLUG, ID, workflow])

  useEffect(() => {
    let isMounted = true
    if (isMounted) getData()
    return () => {
      isMounted = false
    }
  }, [getData])

  useEffect(() => {
    let isMounted = true
    if (isMounted) dispatch(
      ItemsActions.setSession({
        name: "pathname",
        data: history.location.pathname,
      })
    )
    return () => {
      isMounted = false
    }
  }, [dispatch, history])

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const setColorTextStatus = useCallback((text) => {
    switch (text) {
      case "Em execução":
      case "executar":
        return "#2196F3"
      case "Finalizada":
      case "finalizar":
        return "#4CAF50"
      default:
        return "#FF9800"
    }
  }, [])

  const setButtomVariant = useCallback((variant) => {
    switch (variant) {
      case "Finalizada":
      case "finalizar":
        return "contained"
      default:
        return "outlined"
    }
  }, [])

  const handleSubmit = useCallback((action, comment) => {
    const params = {
      transition: action
    }

    if (_.get(comment, 'text', '').length > 0) {
      params.message = comment
    }

    axios
      .post(`${pathServer}/tool/${SLUG}/record/${ID}/transit`, params, requestHeaders)
      .then((result) => {
        if (params.transition === 'finalizar') {
          setDataItem(prev => ({ ...prev, openDialog: true }))
        } else {
          enqueueSnackbar(result.headers['x-message'], {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          })
          getDataAllComments()
          getData()
          handleCloseMenu()
        }
      })
      .catch((err) => {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : ''
        enqueueSnackbar(Boolean(message) ? message : t("DAM:Erro ao salvar os dados."), {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
        console.log(err)
      })
  }, [ID, SLUG, enqueueSnackbar, getData, getDataAllComments, pathServer, requestHeaders, t])

  const handleCloseDialog = useCallback(() => {
    setDataItem(prev => ({ ...prev, openDialog: false }))
    getDataAllComments()
    getData()
  }, [getDataAllComments, getData])

  const handleErrorUrlSigned = useCallback(() => {
    setDataItem(prev => ({ ...prev, errorUrlSigned: true }))
  }, [])


  const MUIRichTextAccordion = ({ title, value = '' }) => (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>
          {t(title)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MUIRichTextEditor
          defaultValue={Utils.getFormattedDescription(value, t)}
          inlineToolbar={false}
          toolbar={false}
          readOnly={true}
        />
      </AccordionDetails>
    </Accordion>
  )

  return (
    <Grid container>
      <HeaderPage description={description} match={match} workflow={workflow} title={_.get(dataItem, 'title', '')} />

      <Container className={classes.rootContainer}>
        <Paper elevation={2} className={classes.rootPaper}>
          <Grid className={classes.wrapperHalf}>
            <Grid className={classes.half}>
              {dataItem.loading && <CustomLoading />}

              {!dataItem.loading && dataItem.errorData && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: 800,
                  }}
                >
                  <span>{t("common:Falha ao carregar dados.")}</span>
                </div>
              )}
              {_.get(dataItem, "id", false) && !dataItem.loading && (
                <>
                  <div className={classes.header}>
                    <img
                      src={_.get(dataItem, "toolThumbnail", CheckSVG)}
                      width="30"
                      height="30"
                      alt={_.get(dataItem, "item.name", "")}
                    />
                    <div className="titles">
                      <Typography variant="h6">
                        {_.get(dataItem, "name", t("common:Ordem de Serviço"))}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.body}>
                    <Grid style={{ marginTop: 10 }}>
                      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {_.get(dataItem, "created_by.name", false) && (
                          <div style={{ marginRight: 10 }}>
                            <Typography variant="body2" id="title">
                              {t("common:Solicitante")}
                            </Typography>
                            <Tooltip title={_.get(dataItem, "created_by.email", "")}>
                              <Chip
                                label={_.get(dataItem, "created_by.name", "")}
                                avatar={
                                  <Avatar>
                                    {_.get(dataItem, "created_by.name", "N")[0]}
                                  </Avatar>
                                }
                                className={classes.chip}
                              />
                            </Tooltip>
                          </div>
                        )}
                        {_.get(dataItem, "created_at", false) && (
                          <div>
                            <Typography variant="body2" id="title">
                              {t("common:Data de abertura")}
                            </Typography>
                            <input
                              type="text"
                              name="date"
                              value={Utils.formatDate(dataItem.created_at)}
                              style={{
                                padding: 8,
                                border: "1px solid #C4C4C4",
                                borderRadius: "4px",
                                fontSize: "0.875rem",
                                marginTop: 5
                              }}
                              readOnly
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    {_.get(dataItem, "recipients", []).length > 0 && (
                      <div style={{ marginTop: 20 }}>
                        <Typography variant="body2" id="title">
                          {t("common:Executor")}
                        </Typography>
                        {dataItem.recipients.map((recipient, index) => {
                          return (
                            <Tooltip
                              title={_.get(recipient, "email", "")}
                              key={_.get(recipient, "id", index)}
                            >
                              <Chip
                                label={_.get(recipient, "name", "")}
                                avatar={
                                  <Avatar>
                                    {_.get(recipient, "name", "R")[0]}
                                  </Avatar>
                                }
                                className={classes.chip}
                              />
                            </Tooltip>
                          )
                        })}
                      </div>
                    )}


                    {_.get(dataItem, "approvers", []).length > 0 && (
                      <div style={{ marginTop: 20 }}>
                        <Typography variant="body2" id="title">
                          {t("manager:Aprovador")}
                        </Typography>
                        {dataItem.approvers.map((recipient, index) => {
                          return (
                            <Tooltip
                              title={_.get(recipient, "email", "")}
                              key={_.get(recipient, "id", index)}
                            >
                              <Chip
                                label={_.get(recipient, "name", "")}
                                avatar={
                                  <Avatar>
                                    {_.get(recipient, "name", "R")[0]}
                                  </Avatar>
                                }
                                className={classes.chip}
                              />
                            </Tooltip>
                          )
                        })}
                      </div>
                    )}
                    {_.get(dataItem, "validators", []).length > 0 && (
                      <div style={{ marginTop: 20 }}>
                        <Typography variant="body2" id="title">
                          {t("manager:Validador")}
                        </Typography>
                        {dataItem.validators.map((recipient, index) => {
                          return (
                            <Tooltip
                              title={_.get(recipient, "email", "")}
                              key={_.get(recipient, "id", index)}
                            >
                              <Chip
                                label={_.get(recipient, "name", "")}
                                avatar={
                                  <Avatar>
                                    {_.get(recipient, "name", "R")[0]}
                                  </Avatar>
                                }
                                className={classes.chip}
                              />
                            </Tooltip>
                          )
                        })}
                      </div>
                    )}

                    {
                      _.get(dataItem, "item", false) && workflow && (
                        <>
                          <Grid>
                            <Typography variant="body1" style={{ fontWeight: 500, marginTop: 60 }}>
                              {t('manager:Informações para a criação do item na plataforma')}
                            </Typography>
                            <Typography variant="body2" style={{ marginBottom: 10 }}>
                              {t('manager:O rascunho do seu item foi criado. Caso deseje, é possível editar as informações de cadastro ao clicar nele')}
                            </Typography>
                          </Grid>
                          <Grid>
                            <FormControl fullWidth style={{ margin: '15px 0 30px' }} variant="outlined">
                              <InputLabel htmlFor="outlined-adornment">
                                {t('manager:Rascunho do item')}
                              </InputLabel>
                              <OutlinedInput
                                className={classes.formControl}
                                readOnly
                                id="outlined-adornment"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <ImageLazyLoad
                                      images={{ imgWEBP: _.get(dataItem, "item.thumbnail", ""), imgJPG: _.get(dataItem, "item.thumbnail", "") }}
                                      styles={{ width: 30, height: 30, borderRadius: 4 }}
                                      iconEmptyColorGray
                                    />
                                  </InputAdornment>}
                                labelWidth={130}
                                value={_.get(dataItem, "item.taxonomy.name", "")}
                                onClick={() => history.push(`/dam/${dataItem.item.link}`)}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      )
                    }

                    {
                      _.get(dataItem, "item", false) && !workflow && (
                        <>
                          <div style={{ marginTop: 20, border: '1px solid #e0e0e0', padding: 15 }}>
                            <Typography variant="body2" id="title">
                              {t("manager:Item da solicitação")}
                            </Typography>
                            <Typography variant="body2" >
                              {t("manager:Essa solicitação está associada a esse item")}
                            </Typography>
                            <List dense className={classes.root_list}>
                              <ListItem className={classes.item_row} button onClick={() => history.push(`/dam/${dataItem.item.link}`)}>
                                <ListItemAvatar>
                                  {
                                    _.get(dataItem, "errorUrlSigned", false) ? (
                                      <div
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}                             >
                                        <Icon path={mdiWallpaper} color="rgb(240, 240, 240)" />
                                      </div>) : (
                                      <img
                                        src={_.get(dataItem, "item.thumbnail", "")}
                                        width="100"
                                        height="100"
                                        alt={_.get(dataItem, "item.name", "")}
                                        onError={handleErrorUrlSigned}
                                      />
                                    )}
                                </ListItemAvatar>
                                <ListItemText primary={_.get(dataItem, "item.taxonomy.name", "")} />
                              </ListItem>
                            </List>
                          </div>
                        </>
                      )
                    }

                    <Grid style={{ marginTop: 20 }}>
                       {
                        _.get(dataItem, 'message', false) && (
                          <MUIRichTextAccordion title="common:Mensagem do solicitante" value={dataItem.message} />
                        )
                      }
                      {
                        _.get(dataItem, 'summary', false) && (
                          <MUIRichTextAccordion title="manager:Resumo da tarefa" value={dataItem.summary} />
                        )
                      }
                      {
                        _.get(dataItem, "attributes", false) &&
                        _.get(dataItem, "attributes", []).length > 0 && (
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <Typography className={classes.heading}>
                                {t('manager:Informações complementares')}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                {_.get(dataItem, "attributes", []).map((i) => {
                                  if (Boolean(i.value)) {
                                    let value = i.value
                                    if (Utils.checkIfDateIsValid(i.value) && !i.value instanceof Array) {
                                      value = Utils.formatDate(i.value)
                                    }
                                    if (i.value instanceof Array) {
                                      value = i.value.join(', ')
                                    }
                                    return (
                                      <Grid key={_.get(i, "id", _.get(i, "value", ''))} item xs={12} md={4}>
                                        <Typography style={{ fontWeight: 500, color: '#666' }} variant="body2">{_.get(i, 'name', _.get(i, 'label', ''))}</Typography>
                                        <Typography variant="body2">{value}</Typography>
                                      </Grid>
                                    )
                                  }
                                  return null
                                })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                      {
                        _.get(dataItem, "params_for_item.attributes", false) &&
                        _.get(dataItem, "params_for_item.attributes", []).length > 0 && (
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <Typography className={classes.heading}>
                                {t('manager:Informações da categoria')}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                {_.get(dataItem, "params_for_item.attributes", []).map((i) => {
                                  if (Boolean(i.value)) {
                                    let value = i.value
                                    if (Utils.checkIfDateIsValid(i.value) && !i.value instanceof Array) {
                                      value = Utils.formatDate(i.value)
                                    }
                                    if (i.value instanceof Array) {
                                      value = i.value.join(', ')
                                    }
                                    return (
                                      <Grid key={_.get(i, "id", _.get(i, "value", ''))} item xs={12} md={4}>
                                        <Typography style={{ fontWeight: 500, color: '#666' }} variant="body2">{i.label}</Typography>
                                        <Typography variant="body2">{value}</Typography>
                                      </Grid>
                                    )
                                  }
                                  return null
                                })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                      {
                        _.get(dataItem, "links", []).length > 0 && (
                          <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography className={classes.heading}>
                                {t('manager:Itens relacionados')}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense className={classes.root_list}>
                                {
                                  _.get(dataItem, "links", []).map(i => (
                                    <ListItem key={i.id} className={classes.item_row} button onClick={() => history.push(`/dam/${_.get(i, "link", "")}`)}>
                                      <ListItemAvatar>
                                        <ImageLazyLoad
                                          images={{ imgWEBP: _.get(i, "thumbnail", ""), imgJPG: _.get(i, "thumbnail", "") }}
                                          styles={{ width: 100, height: 100 }}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText primary={_.get(i, "taxonomy.name", "")} />
                                    </ListItem>
                                  ))
                                }
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                    </Grid>
                  </div>
                </>
              )}
            </Grid>

            <Grid>
              <Divider style={{ margin: '10px 10px 0 0', height: '100%' }} orientation="vertical" flexItem />
            </Grid>

            <Grid className={classes.half}>
              <Grid style={{ display: 'flex', alignItems: 'end' }}>
                <Grid style={{ marginRight: 30 }} item xs={12} md={4}>
                  {dataItem.marking && (
                    <div>
                      <Typography variant="body2" id="title">
                        {t("common:Situação")}
                      </Typography>
                      <input
                        type="text"
                        name="situation"
                        value={dataItem.marking}
                        style={{
                          padding: 8,
                          border: "1px solid #C4C4C4",
                          borderRadius: "4px",
                          color: setColorTextStatus(dataItem.marking),
                          fontSize: "0.875rem",
                          marginTop: 5
                        }}
                        readOnly
                      />
                    </div>
                  )}
                </Grid>
                {
                  _.get(dataItem, "transitions", []).length > 0 &&
                  <Grid style={{ margin: 3 }}>
                    <Button
                      style={{ minWidth: 175, padding: '3px 0px' }}
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      color="primary"
                      onClick={handleClickMenu}
                    >
                      {t('manager:Alterar status')}
                      <ArrowDropDownIcon style={{ marginLeft: 10 }} />
                    </Button>
                    <StyledMenu
                      className={classes.menu}
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      {_.get(dataItem, "transitions", []).map((i) => {
                        if (i.hasComment) {
                          return (
                            <CommentReprovedDialog transition={i} key={i.key}>
                              {(confirm) => (
                                <MenuItem
                                  color="primary"
                                  style={{ minWidth: 175, justifyContent: 'center' }}
                                  variant={setButtomVariant(i.key)}
                                  onClick={confirm((event, comment) => handleSubmit(i.key, comment))}
                                >
                                  {t(`${i.text}`)}
                                </MenuItem>
                              )}
                            </CommentReprovedDialog>
                          )
                        } else {
                          return (
                            <MenuItem
                              key={i.key}
                              variant={setButtomVariant(i.key)}
                              color="primary"
                              style={{ minWidth: 175, justifyContent: 'center' }}
                              onClick={() => handleSubmit(i.key)}
                            >
                              {t(`${i.text}`)}
                            </MenuItem>
                          )
                        }
                      })}
                    </StyledMenu>
                  </Grid>
                }
              </Grid>

              <UploadBucketDialog
                data={{
                  entity: `tasks-${ID}`,
                  tagging: `source_id=${ID}&&source=toolrecord`,
                  idRecord: ID,
                  slugTool: SLUG,
                  workflow: workflow,
                  recipients: _.get(dataItem, "recipients", []).map(i => i.id),
                  approvers: _.get(dataItem, "approvers", []).map(i => i.id),
                  marking: _.get(dataItem, 'marking', ''),
                  original_marking: _.get(dataItem, 'original_marking', '')
                }}
              />
              <ToolComment marking={_.get(dataItem, 'marking', '')} />
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: 18,
            }}
          >
            <Button
              style={{ height: 40, width: 115 }}
              variant="outlined"
              color="primary"
              onClick={() => history.push(`/${region}/${workflow ? "workflow" : "request"}/${SLUG}`)}
            >
              {t("common:Voltar")}
            </Button>
          </div>
        </Paper>
      </Container>

      {
        _.get(dataItem, 'openDialog', false) &&
        <DialogItemLink
          open={_.get(dataItem, 'openDialog', false)}
          handleClose={handleCloseDialog}
          params={{
            slug: SLUG,
            id: ID,
            pathServer,
            requestHeaders
          }}
        />
      }
    </Grid>
  )
}

const ToolDetails = (props) => {
  return (
    <ToolDetailsProvider>
      <Details {...props} />
    </ToolDetailsProvider>
  )
}

export default ToolDetails
