import React, { Fragment } from "react";
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { Typography, Box, Tooltip, CircularProgress } from "@material-ui/core";
import _ from 'lodash'

const ProgressBox = ({ item }) => {
  const { dataProgress } = useSelector(state => state.uploadReducer)
  const data = dataProgress.find(i => i.id === item.id)
  const { t } = useTranslation("DAM")

  return (
    <Fragment>     
          <Tooltip title={t("DAM:Enviando arquivo")}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="static"
                value={_.get(data, 'value', 0)}
                style={{
                  width: "25px",
                  height: "25px",
                  color: "#00B207",                  
                }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  style={{ fontSize: "0.50em" }}
                  color="textSecondary"
                >{`${_.get(data, 'value', 0)}%`}</Typography>
              </Box>
            </Box>
          </Tooltip>      
          
    </Fragment>
  );
};

export default ProgressBox;

