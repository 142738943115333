import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: 20,
    marginBottom: 60,
    maxWidth: 1500
  },
  rootPaper: {
    width: "100%",
    minHeight: 700,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  body: {
    color: "#666 !important",
    padding: 20,
    paddingTop: 0,
    "& #title": {
      fontWeight: 500,
      "& span": {
        fontSize: "0.94rem",
      },
    },
    "& #info": {
      fontWeight: 400,
      "& span": {
        fontSize: "0.875rem",
      },
      "& #mui-rte-container": {
        margin: 0,
      },
      "& #mui-rte-editor-container": {
        margin: 0,
        padding: 0,
      },
    },
    "& .MuiChip-root": {
      backgroundColor: "#fff",
      border: "1px solid gray",
    },
  },
  chip: {
    margin: 5,
    height: 25,
    "& .MuiChip-avatar": {
      width: 18,
      height: 18,
    },
  },
  contentItem: {
    display: "flex",
    padding: 8,
    width: "100%",
    border: "1px solid #C4C4C4",
    borderRadius: 4,
    fontSize: "0.875rem",
    "&:hover": {
      cursor: "pointer",
    },
    "& img": {
      borderRadius: 4,
    },
    "& .name": {
      display: "flex",
      flexDirection: "column",
      marginLeft: 15,
      marginTop: 10,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    "& img": {
      filter:
        "invert(43%) sepia(0%) saturate(370%) hue-rotate(176deg) brightness(91%) contrast(94%)",
    },
    "& h6, p": {
      color: "#666",
    },
    "& div.titles": {
      marginLeft: 10,
    }
  },
  root_list: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 4,
  },
  item_row: {
    borderRadius: 4,
    padding: 0,
    "& .MuiListItemAvatar-root": {
      marginRight: 10
    },
    "& .MuiTypography-root": {
      padding: '0 8px'
    },
  },
  wrapperHalf: {
    display: 'flex',
  },
  half: {
    width: '50%',
    padding: 40
  },
  formControl: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& input': {
      '&:hover': {
        cursor: 'pointer',
      }
    }
  },
  menu: {
    '& ul': {
      padding: 0
    }
  }
}))
