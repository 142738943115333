import React from 'react';
import PropTypes from 'prop-types';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {
  Grid,
  TextField,
  Divider,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next"

import { useStyles } from "./styles"

const DnsLine = ({ item, index, handleChangeDns, handleDeleteDns, loading }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} key={index}>
        <div className={classes.contentDns}>
          <Grid item xs={12} sm={5}>
            <TextField
              variant="outlined"
              label={t("DNS")}
              placeholder={t("common:Digite o dns.")}
              value={item.value}
              onChange={(evt) => handleChangeDns('value', index, evt.target)}
              style={{ width: '100%' }}
              disabled={loading}
              error={item.error_value}
              helperText={item.error_value ? item.error_value : ''}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginLeft: 30 }}>
            <TextField
              variant="outlined"
              label={t("common:Nome da plataforma")}
              placeholder={t("common:Digite o nome da plataforma.")}
              value={item.platform_name}
              onChange={(evt) => handleChangeDns('platform_name', index, evt.target)}
              style={{ width: '100%' }}
              disabled={loading}
              error={item.error_platform_name}
              helperText={item.error_platform_name ? item.error_platform_name : ''}
            />
          </Grid>

          <Grid item xs={12} sm={1} className='btn-delete'>
            <Tooltip title={t("common:Excluir")} placement="top" aria-label={t("common:Excluir")}>
              <IconButton color="default" disabled={loading} onClick={() => handleDeleteDns(index)}>
                <DeleteRoundedIcon style={{ color: '#666' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </div>

        <Grid item xs={12} sm={12}>
          <TextField
            variant="outlined"
            label={t("common:Descrição")}
            placeholder={t("common:Digite a descrição.")}
            value={item.description}
            onChange={(evt) => handleChangeDns('description', index, evt.target)}
            style={{ width: '100%' }}
            multiline
            rows={4}
            disabled={loading}
            error={item.error_description}
            helperText={item.error_description ? item.error_description : ''}
          />
        </Grid>

      </Grid>
    </>
  );
};

DnsLine.defaultProps = {
  item: {
    description: '',
    platform_name: '',
    value: '',
  },
  index: Math.random(),
  handleChangeDns: () => { },
  handleDeleteDns: () => { },
  loading: false
};

DnsLine.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    platform_name: PropTypes.string,
    value: PropTypes.string,
  }),
  index: PropTypes.number,
  handleChangeDns: PropTypes.func,
  handleDeleteDns: PropTypes.func,
  loading: PropTypes.bool
};

export default DnsLine;
