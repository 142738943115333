/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable  no-unused-vars*/
import ComboBox from "./index.js";
import connect from "react-redux/es/connect/connect";
import {mapDispatchToPropsToSettings, mapStateToPropsToSettings} from "../../utils/Flux";
import { withTranslation } from "react-i18next"

class UserComboBox extends ComboBox {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      entity: "user",
      title: props.title || this.props.t('Usuários'),
      subtitle: props.subtitle,
      field_required: Boolean(props.fieldRequired)
    }
  }

  componentDidMount() {
    super.componentDidMount.apply(this);
    this.getData();
  }
}


export default connect(
  mapStateToPropsToSettings,
  mapDispatchToPropsToSettings
)(withTranslation(["manager", "common"])(UserComboBox))