import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    form: {
        overflow: 'hidden',
        "& .MuiInputLabel-formControl": {
            zIndex: 0,
        }
    },
    wrapper: {
        padding: "30px 25px",
        '& .MuiFormLabel-root': {
            whiteSpace: 'pre-line',
            width: '90%'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            whiteSpace: 'inherit',
        }
    },
    chipInputRoot: {
        padding: 10,
    },
    helper: {
        display: "inline-block",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: ".7em",
        marginTop: "10px",
    },
    error: {
        display: "inline-block",
        color: "#FF0000",
        fontSize: ".7em",
        marginTop: "10px",
    },
}))