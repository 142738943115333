import React, { useEffect, useState, useCallback } from "react"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"

import { useSnackbar } from "notistack"
import { Creators as ItemsActions } from "flux/ducks/Items"
import { useStyles } from "./styles"

import {
  Grid,
  Container,
  Button,
  Typography,
  Tooltip,
  Chip,
  Avatar,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  FormHelperText
} from "@material-ui/core"

import _ from "lodash"

import DeleteIcon from '@material-ui/icons/Delete'
import { WrapperForms } from 'components/WrapperForms'
import SegmentComboBox from "components/ComboBox/SegmentComboBox"
import CategoryComboBox from "components/ComboBox/CategoryComboBox"
import CategoryAttributes, { createForm, validation, attributesForInput } from 'components/CategoryAttributes'
import RelatedItemsComboBox from "components/ComboBox/RelatedItemsComboBox"
import HeaderPage from '../HeaderPage'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIRichTextEditor from "mui-rte"
import { convertToRaw } from 'draft-js'

import Settings from 'utils/Settings'
import Utils from 'utils/Utils'

const LIMIT_RELATED_ITEMS = 5

const defaultTheme = createMuiTheme({
  overrides: {
    MUIRichTextEditor: {
      container: {
        margin: 0
      },
      toolbar: {
        borderBottom: '1px solid #eee',
        padding: '8px 0',
      },
      placeHolder: {
        margin: 0,
        padding: '19px 16px',
      },
      editorContainer: {
        margin: 0,
        padding: '19px 16px',
      }
    }
  }
})

const ToolFormWorkflow = ({ description, match, workflow }) => {
  const [dataItems, setDataItems] = useState({
    loading: true,
    dataText: { initial_value: '', data: {} },
    dataFields: {},
    errorFields: {},
    dataFieldsCategory: {},
    errorFieldsCategory: {},
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [showLng, setShowLng] = useState({})
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const SLUG = match.params.slug

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const { pathServer, requestHeaders, language: currentLanguage } = useSelector(
    (state) => state.appReducer
  )

  const getData = useCallback(() => {
    axios
      .get(`${pathServer}/tool/slug/${SLUG}`, requestHeaders)
      .then((result) => {
        setDataItems(prev => ({
          ...prev,
          id: _.get(result, 'data.id', ''),
          loading: false,
          title: _.get(result, 'data.title', ''),
          dataFields: createForm(_.get(result, 'data.attributes', [])),
          data: {
            ...prev.data,
            submit_message: Boolean(result.data.submit_message) ? _.get(result, 'data.submit_message', t("manager:Iniciar tarefa")) : t("manager:Iniciar tarefa"),
            approvers: _.get(result, 'data.approvers', []),
            recipients: _.get(result, 'data.recipients', []),
            validators: _.get(result, 'data.validators', []),
            attributes: (_.get(result, 'data.attributes', [])).map((item) => {
              item.value = '';
              item.mask_value = item.mask_value ? item.mask_value.trim() : "";
              return item
            })
          }
        }
        ))
      })
      .catch((err) => {
        setDataItems({ loading: false })
        history.goBack()
        console.log(err)
      })
  }, [pathServer, requestHeaders, SLUG, history, t])

  useEffect(() => {
    let isMounted = true
    if (isMounted) getData()
    return () => {
      isMounted = false
    }
  }, [getData])

  useEffect(() => {
    dispatch(
      ItemsActions.setSession({
        name: "pathname",
        data: history.location.pathname,
      })
    )
  }, [dispatch, history.location.pathname])

  const validAttributes = useCallback((dataFields, attributes) => {
    let formValid = true
    const errorFields = validation(dataFields, attributes)
    if (!_.isEmpty(errorFields)) {
      formValid = false
    }
    return { formValid, errorFields }
  }, [])

  const validForm = useCallback(() => {
    const params = { ...dataItems.data }
    let isValid = true
    let errors = {}

    if (_.get(params, `name.${currentLanguage}`, '').length === 0) {
      isValid = false
      errors[`name_${currentLanguage}`] = t('common:Este campo é obrigatório')
    }

    if (_.get(params, "segment", '').length === 0) {
      isValid = false
      errors.segment = t('common:Este campo é obrigatório')
    }

    if (_.get(params, "category", '').length === 0) {
      isValid = false
      errors.category = t('common:Este campo é obrigatório')
    }

    if (_.get(params, 'summary.text', '').length === 0) {
      isValid = false
      errors.summary = t('common:Este campo é obrigatório')
    }

    const attributes = validAttributes(_.get(dataItems, "dataFields", {}), _.get(params, "attributes", []))

    const category_attributes = validAttributes(_.get(dataItems, "dataFieldsCategory", {}), _.get(params, "category_attributes", []))

    if (!attributes.formValid || !category_attributes.formValid) {
      isValid = false
    }

    setDataItems(prev => ({
      ...prev,
      errors,
      errorFieldsCategory: category_attributes.errorFields,
      errorFields: attributes.errorFields
    }))

    return isValid
  }, [dataItems, t, validAttributes, currentLanguage])

  const getParameters = useCallback(() => {
    const params = { ...dataItems.data }

    // eslint-disable-next-line
    const [attributes, unset_attributes]
      = attributesForInput(_.get(dataItems, "dataFields", {}), _.get(params, 'attributes', []))

      // eslint-disable-next-line
    const [category_attributes, category_unsetAttributes]
      = attributesForInput(_.get(dataItems, "dataFieldsCategory", {}), _.get(params, 'category_attributes', []))

    params.approvers = _.get(params, 'approvers', []).map(i => _.get(i, 'value', _.get(i, 'id', '')))
    params.recipients = _.get(params, 'recipients', []).map(i => _.get(i, 'value', _.get(i, 'id', '')))
    params.validators = _.get(params, 'validators', []).map(i => _.get(i, 'value', _.get(i, 'id', '')))
    params.category = _.get(params, 'category.value', '')
    params.segment = _.get(params, 'segment.value', '')
    params.attributes = attributes
    params.category_attributes = category_attributes

    if (_.get(params, 'relatedItems', []).length > 0) {
      params.links = params.relatedItems.map(i => i.value)
    }

    params.name = Utils.removeEmptyValues(params.name)

    delete params.relatedItems

    return params
  }, [dataItems])

  const handleChangeEditorDraft = useCallback((editorState) => {
    if (Boolean(dataItems.dataText)) {
      const editorConverted = convertToRaw(editorState.getCurrentContent())
      const textPerLine = editorConverted.blocks.map((el) => el.text)

      const summary = {
        text: Boolean(textPerLine) ? textPerLine.join(" ") : '',
        formatter: {
          name: "MUI-RTE/DraftJs",
          version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
          format: JSON.stringify(editorConverted),
        },
      }

      setDataItems(prev => ({ ...prev, dataText: { data: summary }, data: { ...prev.data, summary: summary } }))
    }
  }, [dataItems])

  const handleSubmit = useCallback(() => {
    if (validForm()) {
      const params = getParameters()

      axios.post(`${pathServer}/tool/${SLUG}/send`, params, requestHeaders)
        .then((result) => {
          enqueueSnackbar(t("DAM:Dados salvo com sucesso."), {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          })
          history.goBack()
        })
        .catch((err) => {
          enqueueSnackbar(t("DAM:Erro ao salvar os dados."), {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          })
          console.log(err)
        })
    }
  }, [getParameters, enqueueSnackbar, history, pathServer, requestHeaders, t, validForm, SLUG])

  const handleChangeData = (field, value) => {
    setDataItems(prev => ({ ...prev, data: { ...prev.data, [field]: value } }))
  }

  const handleChangeDataForLng = (value, lng, field) => {
    setDataItems(prev => ({
      ...prev, data: {
        ...prev.data,
        [field]: {
          ...prev.data[field],
          [lng]: value
        }
      }
    }))
  }

  const handleChangeAttributes = (id, value) => {
    const newAttributes = { ...dataItems.dataFields }
    Object.entries(newAttributes).forEach(([key, item]) => {
      if (key === id) {
        newAttributes[key] = value
      }
    })
    setDataItems(prev => {
      let errorFields = prev.errorFields
      delete errorFields[id]
      return {
        ...prev,
        dataFields: newAttributes,
        errorFields: errorFields
      }
    })
  }

  const handleChangeAttributesCategory = (id, value) => {
    const newAttributes = { ...dataItems.dataFieldsCategory }
    Object.entries(newAttributes).forEach(([key, item]) => {
      if (key === id) {
        newAttributes[key] = value
      }
    })
    setDataItems(prev => {
      let errorFields = prev.errorFieldsCategory
      delete errorFields[id]
      return {
        ...prev,
        dataFieldsCategory: newAttributes,
        errorFieldsCategory: errorFields
      }
    })
  }

  const getCategory = useCallback((option) => {
    axios.get(`${pathServer}/attributesByCategory/${option.value}`, requestHeaders)
      .then(function (resp) {
        setDataItems(prev => ({
          ...prev,
          data: {
            ...prev.data,
            category: option,
            category_attributes: (_.get(resp, 'data.items', [])).map((item) => {
              item.value = '';
              item.mask_value = item.mask_value ? item.mask_value.trim() : "";
              return item
            })
          },
          dataFieldsCategory: createForm(_.get(resp, 'data.items', []))
        }))
      })
      .catch((err) => {
        console.log(err)
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("Erro inesperado. Contate o suporte");
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
  }, [pathServer, requestHeaders, t, enqueueSnackbar])

  const handleChangeRelatedItems = (items) => {
    if (!items) {
      setDataItems(prev => ({ ...prev, data: { ...prev.data, relatedItems: [] } }))
      return
    }
    if (items.length <= LIMIT_RELATED_ITEMS) {
      setDataItems(prev => ({ ...prev, data: { ...prev.data, relatedItems: items } }))
    }
  }

  const handleClickLngMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLngMenu = () => {
    setAnchorEl(null);
  };

  const handleShowLng = (value) => {
    setShowLng(prev => ({ ...prev, [value]: true }))
    setAnchorEl(null)
  }

  const handleDeleteLng = (lng, field) => {
    setShowLng(prev => {
      const copy = { ...prev }
      delete copy[[lng]]
      return copy
    })
    setDataItems(prev => ({
      ...prev, data: {
        ...prev.data,
        [field]: {
          ...prev.data[field],
          [lng]: ''
        }
      }
    }))
  }

  return (
    <>
      <HeaderPage description={description} match={match} workflow={workflow} title={_.get(dataItems, 'title', '')} />
      <Container className={classes.rootContainer} spacing={3}>
        <WrapperForms flexDisplay>
          <Grid item xs={3} style={{ paddingRight: 20 }}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 500 }}>
                {t('manager:Ferramenta de workflow')}
              </Typography>
              <Typography>
                {_.get(dataItems, 'title', '')}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ margin: '20px 0 20px' }}>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {t("manager:Aprovador")}
              </Typography>
              <Tooltip title={_.get(dataItems, "data.approvers[0].name", "")}>
                <Chip
                  label={_.get(dataItems, "data.approvers[0].name", "")}
                  avatar={
                    <Avatar>
                      {_.get(dataItems, "data.approvers[0].name", "N")[0]}
                    </Avatar>
                  }
                  className={classes.chip}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} style={{ margin: '10px 0 20px 0' }}>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {t("manager:Executor")}
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                  _.get(dataItems, "data.recipients", []).map(i => {
                    return (
                      <div key={_.get(i, "id", Math.random())}>
                        <Tooltip title={_.get(i, "name", "")}>
                          <Chip
                            label={_.get(i, "name", "")}
                            avatar={
                              <Avatar>
                                {_.get(i, "name", "N")[0]}
                              </Avatar>
                            }
                            className={classes.chip}
                          />
                        </Tooltip>
                      </div>
                    )
                  })
                }
              </div>
            </Grid>
            {_.get(dataItems, "data.validators", []).length > 0 &&
              <Grid item xs={12} style={{ margin: '20px 0 20px' }}>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {t("manager:Validador")}
                </Typography>
                <Tooltip title={_.get(dataItems, "data.validators[0].name", "")}>
                  <Chip
                    label={_.get(dataItems, "data.validators[0].name", "")}
                    avatar={
                      <Avatar>
                        {_.get(dataItems, "data.validators[0].name", "N")[0]}
                      </Avatar>
                    }
                    className={classes.chip}
                  />
                </Tooltip>
              </Grid>
            }
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2} style={{ borderLeft: '2px solid #eee', paddingLeft: 20 }}>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Typography component='h5' variant='h5' style={{ fontWeight: 500 }}>
                  {t('manager:Criação de nova tarefa')}
                </Typography>
                <Typography variant='body2'>
                  {`*${t('common:Campo obrigatório')}`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {t('manager:Defina qual será o nome da tarefa')}:
                </Typography>
                <div>

                  <TextField
                    variant="outlined"
                    name="name"
                    label={`${t("manager:Nome da tarefa")}*`}
                    value={_.get(dataItems, `data.name.${currentLanguage}`, '')}
                    onChange={(evt) => handleChangeDataForLng(_.get(evt, 'target.value', ''), currentLanguage, 'name')}
                    style={{ width: '100%', margin: '12px 0 0 0' }}
                    error={_.get(dataItems, `errors.name_${currentLanguage}`, false)}
                    helperText={_.get(dataItems, `errors.name_${currentLanguage}`, false) ? _.get(dataItems, `errors.name_${currentLanguage}`, '') : null}
                    disabled={dataItems.loading}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    <Button
                      color='primary'
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClickLngMenu}
                      disabled={(Object.keys(showLng).length) === (Settings.DATA_SUPPORTED_LNG.length - 1)}
                    >
                      {t('manager:Adicionar idioma')}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseLngMenu}
                    >
                      {
                        Settings.DATA_SUPPORTED_LNG.map((lng) => {
                          return (
                            (lng.lng !== currentLanguage) && !(showLng[lng.lng]) &&
                            <MenuItem key={lng.lng} onClick={() => handleShowLng(_.get(lng, 'lng', ''))}>{t(`manager:${lng.short_name}`)}</MenuItem>
                          )
                        })
                      }
                    </Menu>
                  </div>
                </div>
                {
                  Settings.DATA_SUPPORTED_LNG.map((lng) => {
                    return (
                      showLng[lng.lng] &&
                      <Grid item xs={12} key={lng.lng}>
                        <Typography variant="body2">
                          {t(`manager:Esse nome será visto pelos usuários que utilizam o idioma na plataforma`, { idm: t(`manager:${lng.short_name}`) })}
                        </Typography>
                        <Grid item xs={12} style={{ display: 'flex', margin: '20px 0' }}>
                          <TextField
                            variant="outlined"
                            name="name"
                            label={`${t('manager:Nome da tarefa')}`}
                            value={_.get(dataItems, `data.name.${lng.lng}`, '')}
                            onChange={(evt) => handleChangeDataForLng(_.get(evt, 'target.value', ''), _.get(lng, 'lng', ''), 'name')}
                            disabled={dataItems.loading}
                            style={{ margin: 0 }}
                          />
                          <IconButton
                            style={{ backgroundColor: 'transparent', marginLeft: 20 }}
                            onClick={() => handleDeleteLng(_.get(lng, 'lng', ''), 'name')}
                          >
                            <DeleteIcon style={{ color: '#666' }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })
                }
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    {t('manager:Resumo da tarefa')}
                  </Typography>
                  <Typography variant="body2">
                    {t('manager:Explique aos envolvidos o que se pretende alcançar com essa tarefa')}
                  </Typography>
                </div>
                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                  <div className={classes.formField} style={{ height: "auto" }}>
                    <div className={_.get(dataItems, 'errors.summary', false) ? classes.errorRichTextEditor : classes.wrapperRichText}>
                      <MuiThemeProvider theme={defaultTheme}>
                        <MUIRichTextEditor
                          maxLength={500}
                          controls={["bold", "italic", "underline", "link"]}
                          label={t("manager:Essa tarefa será para...")}
                          value={_.get(dataItems, 'dataText.initial_value', '')}
                          onChange={(evt) => handleChangeEditorDraft(evt)}
                        />
                      </MuiThemeProvider>
                    </div>
                    {
                      _.get(dataItems, 'errors.summary', false) &&
                      <FormHelperText style={{ color: '#f44336', marginLeft: 11 }}>
                        {_.get(dataItems, 'errors.summary', '')}
                      </FormHelperText>
                    }
                  </div>
                </div>
              </Grid>
              {
                _.get(dataItems, 'data.attributes', []).length > 0 &&
                <>
                  <Grid item xs={12} className={classes.rootItem}>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {t("manager:Informações complementares da tarefa")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                      {t("manager:Preencha essas informações para auxiliar na execução da tarefa")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.rootItem}>
                    <CategoryAttributes
                      attributes={_.get(dataItems, 'data.attributes', [])}
                      dataFields={_.get(dataItems, 'dataFields', {})}
                      errorFields={_.get(dataItems, 'errorFields', {})}
                      changeAttributes={handleChangeAttributes}
                    />
                  </Grid>
                </>
              }

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {t("common:Relacionar item")}
                </Typography>
                <Typography variant='body2'>
                  {t("manager:Busque os itens cadastrados na plataforma que possam auxiliar na tarefa")}
                </Typography>
                <RelatedItemsComboBox
                  limitItens={LIMIT_RELATED_ITEMS}
                  selectedItems={_.get(dataItems, 'data.relatedItems', []).length}
                  defaultItems={_.get(dataItems, 'data.relatedItems', [])}
                  handleChange={(item, action) => handleChangeRelatedItems(item, action)}
                  title={t("manager:Buscar item")}
                  withoutItemGallery
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {t("DAM:Informações para criação do item")}
                </Typography>
                <Typography variant='body2'>
                  {t("manager:Ao finalizar essa tarefa, será criado automaticamente um item na plataforma com o histórico do que foi realizado. Como o item deve ser cadastrado?")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SegmentComboBox
                  optionSelected={_.get(dataItems, 'data.segment.value', '')}
                  handleChange={(selectedItem) =>
                    handleChangeData("segment", selectedItem)
                  }
                  error={_.get(dataItems, 'errors.segment', false)}
                  title='Selecione o segmento'
                  field_required
                />
              </Grid>
              <Grid item xs={12}>
                <CategoryComboBox
                  optionSelected={_.get(dataItems, 'data.category.label', '')}
                  handleChange={(selectedItem) =>
                    getCategory(selectedItem)
                  }
                  error={_.get(dataItems, 'errors.category', false)}
                  title='Selecione a categoria'
                  positionMenu='relative'
                  notGalleryType
                  field_required
                />
              </Grid>
              {
                _.get(dataItems, 'data.category_attributes', []).length > 0 &&
                <CategoryAttributes
                  attributes={_.get(dataItems, 'data.category_attributes', [])}
                  dataFields={_.get(dataItems, 'dataFieldsCategory', {})}
                  errorFields={_.get(dataItems, 'errorFieldsCategory', {})}
                  changeAttributes={handleChangeAttributesCategory}
                />
              }
            </Grid>
            <div className='paper-actions' style={{ marginTop: 20 }}>
              <Button
                style={{ height: 40, width: 115, marginRight: 20 }}
                variant="outlined"
                color="primary"
                onClick={() => history.goBack()}
              >
                {t("common:Voltar")}
              </Button>
              <Button
                style={{ height: 40 }}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={_.get(dataItems, 'loading', false)}
              >
                {_.get(dataItems, 'data.submit_message', '')}
              </Button>
            </div>
          </Grid>
        </WrapperForms>
      </Container>
    </>
  )
}

export default ToolFormWorkflow
