import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid
} from "@material-ui/core";

import zoomInIcon from "imagens/zoom-in-icon.svg"
import zoomOutIcon from "imagens/zoom-out-icon.svg"
import resetZoomIcon from "imagens/reset-zoom-icon.svg"
import ZoomIn from "@material-ui/icons/ZoomIn";

import ImageLazyLoad from "components/ImageLazyLoad"

import { useStyles } from "./styles";

const ZoomCheck = ({
  image,
  name,
  subtitle,
  children,
  hideCallback,
  actions
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const show = (callback) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    };
    setOpen(true);
  };

  const hide = (event) => {
    event.stopPropagation();
    setOpen(false);
    hideCallback();
  };

  return (
    <React.Fragment>
      {children(show)}
      {open && (
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
          onClick={evt => evt.stopPropagation()}
        >
          <DialogTitle id="alert-dialog-title" className={classes.titleZoom}>
            <div className="info">
              <ZoomIn />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='body' style={{ color: "#616161" }}>
                  {name}
                </Typography>
                <Typography variant='caption' style={{ color: "#9E9E9E" }}>
                  {subtitle}
                </Typography>
              </div>
            </div>
          </DialogTitle>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            centerOnInit
          >
            {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
              <React.Fragment>
                <TransformComponent >
                  <DialogContent className={classes.zoomDiv}>
                    <ImageLazyLoad
                      images={{ imgWEBP: image, imgJPG: image }}
                      styles={{ width: '100%', height: 'auto' }}
                    />
                  </DialogContent>
                </TransformComponent>

                <Grid container className={classes.gridContainer}>
                  <Grid item xs={0} md={2} />
                  <Grid item xs={12} md={4} className={classes.buttonZoom}>
                    <IconButton onClick={() => zoomIn()}>
                      <img src={zoomInIcon} alt="Zoom In Icon" />
                    </IconButton>

                    <IconButton onClick={() => {
                      resetTransform()
                      setTimeout(() => centerView(), 300)
                    }}>
                      <img src={resetZoomIcon} alt="Reset Zoom Icon" />
                    </IconButton>

                    <IconButton onClick={() => zoomOut()}>
                      <img src={zoomOutIcon} alt="Zoom Out Icon" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.buttonActions}>
                    <Button variant="outlined" color="primary" onClick={hide}>
                      {t(`common:Voltar`)}
                    </Button>
                    {actions}
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </TransformWrapper>
        </Dialog>
      )}
    </React.Fragment>
  );
};

ZoomCheck.defaultProps = {
  name: "",
  subtitle: "",
  hideCallback: () => { },
  actions: null
}

export default ZoomCheck;
