import React, { useState, useEffect, useCallback, useMemo } from "react"
import axios from 'axios'
import { useSelector } from "react-redux"
import { CircularProgress } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import LinkRoundedIcon from "@material-ui/icons/LinkRounded"

import _ from "lodash"
import PaperTitle from "../PaperTitle"
import { useSnackbar } from "notistack"
import { useStyles } from "./styles"

import ItemAccordion from './ItemAccordion'

const RelatedItems = ({ idItem }) => {
  const [state, setState] = useState({ data: [], loading: true })
  const { pathServer, requestHeaders, userData: { region } } = useSelector((state) => state.appReducer)

  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const getData = useCallback((id) => {
    axios
      .get(`${pathServer}/item/${id}/links`, requestHeaders)
      .then(({ data }) => {
        const DATA = [..._.get(data, 'item.links', []), ..._.get(data, 'item.linked_to', [])]
        
        const removeDuplicate = DATA.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
        }, Object.create(null))

        setState({ data: removeDuplicate, loading: false })
      })
      .catch((err) => {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("manager:Erro inesperado. Contate o suporte")
        setState(({ data: [], loading: false }),
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          })
        )
      })
  }, [pathServer, requestHeaders, enqueueSnackbar, t])

  useEffect(() => {
    let isMounted = true
    if (isMounted) getData(idItem)
    return () => { isMounted = false }
  }, [getData, idItem])

  const constants = useMemo(() => {
    const listLinksOrder = state.data.sort(function (a, b) {
      const a_ = _.get(a, "taxonomy.name", "zzzzz").toLowerCase()
      const b_ = _.get(b, "taxonomy.name", "zzzzz").toLowerCase()
      return a_ > b_ ? 1 : b_ > a_ ? -1 : 0
    })
    
    return ({
      data_length: listLinksOrder.length,
      listLinksRemoveDuplicate: listLinksOrder
    })
  }, [state.data])

  return (
    <>
      <PaperTitle
        title={t("common:Itens relacionados")}
        subTitle={
          constants.data_length === 1
            ? t("common:item encontrado", { qtd: constants.data_length })
            : t("common:itens encontrados", { qtd: constants.data_length })
        }
        icon={LinkRoundedIcon}
      />

      {state.loading ? (
        <>
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        </>
      ) : (
        <div className={classes.listWrapper}>
          {constants.listLinksRemoveDuplicate.length > 0 &&
            constants.listLinksRemoveDuplicate.map((value) =>
              <ItemAccordion data={value} key={_.get(value, 'id', '')} region={region} />
            )}
        </div>
      )}
    </>
  )
}

export default RelatedItems
