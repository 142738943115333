import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next"
import Template from "../../Template";
import { useSnackbar } from 'notistack'
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Typography,
    Divider
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WrapperPaperForms } from 'components/WrapperForms'
import { useSelector } from "react-redux";

const Usage = (props) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar() 
    const alternativeNameId = props.match.params.id
    const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
    const [data, setData] = useState({alternative_name: {title: '', id: ''}, references: { distributions: [], categories: []}})

    const getData = useCallback(() => {
        axios
            .get(`${pathServer}/alternative-name/findReferences/${alternativeNameId}`, requestHeaders)
            .then((response) => setData(response.data))
            .catch((err) => {
                const message = t('common:Erro ao carregar os dados, contate o suporte')
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            })
    }, [pathServer, alternativeNameId, requestHeaders, enqueueSnackbar, t])

    useEffect(() => {
        let isMounted = true
        if (isMounted) getData()
        return () => {
            isMounted = false
        }
    }, [getData])

    return (
        <>
                    <Template area={9} localHistory={props.history} customClassName={"container-center"}>
                        <WrapperPaperForms title={t("common:Utilização do nome alternativo")}>
                            <Grid>
                                <Typography variant="body1">
                                    {t(`manager:está sendo utilizado em`, { name: data.alternative_name.title })}:
                                </Typography>
                            </Grid>
                            <Grid style={{ marginTop: 20 }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                               <Typography style={{ fontWeight: 500 }}>{t("common:Distribuições")}</Typography>
                           </AccordionSummary>
                           <Divider />
                           <AccordionDetails>
                               <Grid container>
                                   {
                                       data.references.distributions && data.references.distributions.length > 0 ?
                                           data.references.distributions.map((i, k) => <Grid key={`${k}-${i}`} item xs={12}><Typography style={{ marginTop: 23 }}>{i}</Typography></Grid>) :

                                           <Typography style={{ marginTop: 15 }}>{t("common:Este nome alternativo não está associado a nenhuma distribuição")}</Typography>
                                   }
                               </Grid>
                           </AccordionDetails>
                       </Accordion>
                       <Accordion>
                           <AccordionSummary
                               expandIcon={<ExpandMoreIcon />}
                               aria-controls="panel1a-content"
                               id="panel1a-header"
                           >
                               <Typography style={{ fontWeight: 500 }}>{t("common:Categorias")}</Typography>
                           </AccordionSummary>
                           <Divider />
                           <AccordionDetails>
                               <Grid container>
                                   {
                                      data.references.categories &&data.references.categories.length > 0 ?
                                          data.references.categories.map((i, k) => <Grid key={`${k}-${i}`} item xs={12}><Typography style={{ marginTop: 23 }}>{i}</Typography></Grid>) :

                                           <Typography style={{ marginTop: 15 }}>{t("common:Este nome alternativo não está associado a nenhuma categoria")}</Typography>
                                   }
                               </Grid>
                           </AccordionDetails>
                       </Accordion>
                   </Grid>
                   <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                       <Button
                           style={{ height: 40, width: 115, marginRight: 20, marginTop: 20 }}
                           variant="outlined"
                           color="primary"
                           onClick={() => props.history.push("/manager/advanced-settings")}>
                           {t("common:Voltar")}
                       </Button>
                   </div>
               </WrapperPaperForms>
           </Template>
        </>
    )
}

export default Usage;
