import React, { useCallback } from "react"
import _ from 'lodash'
import MaskedInput from "react-maskedinput"
import { useTranslation } from "react-i18next"
import { Grid, FormHelperText, TextField } from "@material-ui/core"
import { useStyles } from "./styles"

import InputDatePicker from 'components/InputDatePicker'
import ReactSelect from './ReactSelect'

import Utils from "utils/Utils"

const SimpleForm = ({ attribute, value, changeAttributes, disabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleChangeAttribute = useCallback((evt, id) => {
    const value = _.get(evt, 'target.value', evt)
    changeAttributes(id, value)
  }, [changeAttributes])

  const formatMaskValue = useCallback((mask) => {
    const newMask = mask.replace(/[\\"]/g, '').replace(/X/g, "#").replace(/x/g, "*")
    return newMask
  }, [])

  if (attribute.field_type === "date") {
    const defaultValue = Boolean(value) ? new Date(value) : null
    return (
      <Grid item xs={12}>
        <InputDatePicker
          title={_.get(attribute, 'name', "")}
          onChange={(evt) => handleChangeAttribute(evt, attribute.id)}
          defaultValue={defaultValue}
          isDisabled={disabled}
        />
      </Grid>
    )
  }

  if (attribute.field_type === "list") {
    return (
      <ReactSelect
        isMulti={attribute.list_type === "multiple"}
        data={{ ...attribute, isDisabled: disabled }}
        value={value}
        handleChangeAttribute={handleChangeAttribute}
      />
    )
  }

  if (attribute.mask_value && Utils.maskValid(attribute.mask_value)) {
    return (
      <div className={classes.maskedInput}>
        <label>{attribute.name}</label>
        <MaskedInput
          style={{ padding: "17.5px 14px", fontSize: "1em" }}
          type="text"
          mask={formatMaskValue(attribute.mask_value)}
          value={value}
          name={attribute.name}
          onChange={(evt) => handleChangeAttribute(evt, attribute.id)}
          disabled={disabled}
        />
        <FormHelperText style={{ color: 'inherit', marginLeft: 11 }} component="span">
          {`${t('common:Exemplo')}: ${attribute.mask_value.replace(/[\\"]/g, '')}`}
        </FormHelperText>
      </div>
    )
  }

  return (
    <Grid item xs={12}>
      <TextField
        value={value}
        name={_.get(attribute, 'name', "")}
        type="text"
        onChange={(evt) => handleChangeAttribute(evt, attribute.id)}
        label={_.get(attribute, 'name', "")}
        variant="outlined"
        style={{ width: "100%" }}
        disabled={disabled}
      />
    </Grid>
  )
}

export default SimpleForm
