import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Tooltip, Typography, Button } from "@material-ui/core";

import ProgressBox from "../ProgressBox";

import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { useStyles } from "../../styles"

const ViewStatus = ({ item }) => {
    const { userData: { region } } = useSelector((state) => state.appReducer);
    const history = useHistory()
    const classes = useStyles()
    const { t } = useTranslation("DAM")

    const linkItemAutomation = item.linkData
            ? `/dam/${region}/${item.linkData.division}/${item.linkData.segment}/${item.linkData.category}/${item.linkData.slug}`
            : null

    switch (item.status_view) {
        case 'upload':
            return <div style={{marginRight: 10, display: 'flex'}}>
                <ProgressBox item={item} />
            </div>
        case 'upload-complete':
            return (
                <Tooltip title={t("DAM:Processamento do thumb")}>
                    <Typography className='typeFont'>
                        {t("common:processando arquivo")}...</Typography>
                </Tooltip>
            )
        case 'reprocess':
            return (
                <Typography className='typeFont'>
                    {t("DAM:preparando reprocessamento da integração")}...
                </Typography>
            )
        case 'integration-error':
            return (
                <Tooltip title={t("DAM:Error na automação")}>
                    <CheckCircleRoundedIcon className="sentError" style={{marginRight: 5}} />
                </Tooltip>
            )
        case 'dispatched':
            return (
                <Typography className='typeFont'>
                    {t("DAM:criando item")}...
                </Typography>
            )

        case 'created-item':
            return (
                <div className={classes.viewItemWrapper}>
                    <Typography className='typeFont'>
                        {t("DAM:item criado")}
                    </Typography>
                    <Button
                        className="clear"
                        variant="contained"
                        onClick={() => history.push(linkItemAutomation)}
                    >
                        {t("DAM:Ver Item")}
                    </Button>
                </div>
            )
        case 'waiting-to-add':
            return (
                <Typography className='typeFont'>
                    {t("DAM:associando arquivo")}...
                </Typography>
            )

        case 'waiting-for-data':
            return (
                <Typography className='typeFont'>
                    {t("DAM:extraindo dados para criar item")}...
                </Typography>
            )
        case 'associated-item':
            return (
                <div className={classes.viewItemWrapper}>
                    <Typography className='typeFont'>
                        {t("DAM:arquivo associado")}
                    </Typography>
                    <Button
                        className="clear"
                        variant="contained"
                        onClick={() => history.push(linkItemAutomation)}
                    >
                        {t("DAM:Ver Item")}
                    </Button>
                </div>
            )

        default:
            return (
                <Typography className='typeFont'>
                    {t("DAM:Erro com o status.")}
                </Typography>
            )
    }
}

export default ViewStatus
