import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  avatar: {
    color: "#fff",
    backgroundColor: localStorage.getItem("primaryColor"),
    marginRight: "20px",
    "& svg": {
      fontSize: "1.5rem",
    },
  },
  root: {
    display: "flex",
    padding: 20,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    },
    '& #icon-button': {
      padding: 0,
      marginRight: 15
    }
  },
  search: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: 5,
    marginLeft: 18,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
    "& .icon": {
      width: 44,
      height: "100%",
      display: "flex",
      justifyContent: "center",
    },
    "& .icon.search": {
      pointerEvents: "none",
      margin: 0,
    },
  },
  tablePagination: {
    '& .MuiTablePagination-toolbar': {
      [theme.breakpoints.down("sm")]: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'

      },
    }
  },
  iconButton: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    padding: 9,
    marginRight: 15
  },
  speedDial: {
    height: 35,
    position: 'absolute',
    left: '-65px',
    top: '3px',
    '& .MuiSpeedDial-fab': {
      color: 'inherit',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: 29,
      height: 29,
      minHeight: 29,
    },
    "& .MuiSpeedDialAction-fab": {
      borderRadius: 4,
      width: 120,
      opacity: 1,
      padding: 5,
      lineHeight: 1,
    }
  },
  radioTitle: {
    "& .MuiTypography-root": {
      fontWeight: 500,
      color: '#666'
    }
  },
  dialogTitle: {
    color: '#666',
    margin: 5,
    padding: '17px 0px'
  }
}))
