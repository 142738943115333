
import { useCallback } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import { useSnackbar } from "notistack"

import { Creators as pageBucketActions } from "flux/ducks/page_bucket"
import AppActions from "flux/actions/AppActions"
import UploadActions from "flux/actions/UploadActions"
import Utils from 'utils/Utils'

export default function useContent() {
    const {
        filters_params,
        search,
        totals,
        files,
        pageFindAll,
    } = useSelector(state => state.pageBucketReducer)
    const {
        requestHeaders,
        pathServer,
        supportsWebp
    } = useSelector(state => state.appReducer)
    const { filesInProgress } = useSelector(state => state.uploadReducer)
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const showMessage = useCallback((message, type) => {
        if (typeof message !== 'string') {
            message = t("manager:Erro inesperado. Contate o suporte")
        }
        enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            },
        })
    }, [enqueueSnackbar, t])

    const onError = useCallback((errors) => {
        errors.forEach((error) => showMessage(error.message, "error"))
    }, [showMessage])

    const getData = useCallback((parameters, newFilters = {}) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        const options = { ...requestHeaders, cancelToken: source.token }

        axios.post(`${pathServer}/asset/find`, parameters, options)
            .then((resp) => {
                const DATA = _.get(resp, 'data.items', [])
                const PAGE = _.get(resp, 'data.page', 1)

                dispatch(pageBucketActions.setDataState({
                    checkedData: true,
                    files: DATA,
                    pageFindAll: PAGE,
                    totals: {
                        returned: resp.data.total,
                        current: resp.data.total,
                        deleted: 0,
                    },
                    loading: false,
                    loadingData: false,
                    searchReloading: false,
                    initiating: false,
                    search: _.get(parameters, 'search', ''),
                    ...(!_.isEmpty(newFilters) && { filters_params: newFilters }),
                }))
            })
            .catch((err) => {
                dispatch(pageBucketActions.setDataState({
                    loading: false,
                    loadingData: false,
                }))
                switch (parseInt(err.response.status)) {
                    case 401:
                        dispatch(AppActions.openDialog("expiredToken"))
                        break
                    default:
                        const message = Utils.ajaxErrorGetMessage(err, t)
                        showMessage(message, "error")
                }
            })
    }, [dispatch, pathServer, requestHeaders, showMessage, t])

    const getFormatedFilters = useCallback((params) => {
        let data = {
            ...(!_.isEmpty(params.order) && { ...params.order }),
            ...(Boolean(params.fileTypes) && { fileTypes: params.fileTypes }),
            ...(
                (!_.isEmpty(params.byUpload) && Boolean(params.byUpload.user_id))
                && { user_id: params.byUpload.user_id }
            ),
            ...(
                (!_.isEmpty(params.byUpload) && Boolean(params.byUpload.onlyIntegrationErrors))
                && { onlyIntegrationErrors: params.byUpload.onlyIntegrationErrors }
            ),
            ...(
                (!_.isEmpty(params.rangeDate) && Boolean(params.rangeDate.start))
                && { startDate: params.rangeDate.start }
            ),
            ...((!_.isEmpty(params.rangeDate) && Boolean(params.rangeDate.end))
                && { endDate: params.rangeDate.end }
            ),
            ...(Boolean(params.id) && { id: params.id }),
        }
        return data
    }, [])

    const getParatmeters = useCallback((page = 1, params = {}) => {
        const thumbField = supportsWebp
            ? "thumbnail_card_webp"
            : "thumbnail_card_original"
        const thumbFieldZoom = supportsWebp
            ? "thumbnail_zoom_webp"
            : "thumbnail_zoom_original"
        const only = [
            "id",
            "client_name",
            "extension",
            "updated_at",
            "created_at",
            "status_pipeline",
            thumbField,
            thumbFieldZoom,
            "status_integration",
            "status_integration_code",
            "status_integration_message",
        ]

        const parameters = {
            limit: 40,
            page: page,
            only,
            ...(Boolean(search) && { search }),
            ...(_.isEmpty(params) ? { ...getFormatedFilters(filters_params) } : { ...getFormatedFilters(params) }),
        }

        return parameters
    }, [getFormatedFilters, supportsWebp, search, filters_params])

    const setParametersFilters = useCallback((field, params) => {
        const filters = { [field]: params }
        dispatch(pageBucketActions.setFiltersParams({ value: filters }))
        const parameters = getParatmeters(1, filters)
        getData(parameters, filters)
    }, [getParatmeters, getData, dispatch])

    const handleReprocessItem = useCallback((file) => {
        if (filesInProgress.some(i => i.path_file === file.path_file)) {
            showMessage(t("DAM:Não pode enviar exatamente o mesmo arquivo simultaneamente."), "error")
            return
        }
        dispatch(pageBucketActions.setStateByField({ field: "loading", value: true }))
        dispatch(UploadActions.addFileReprocess(file)).then(() =>
            axios
                .get(`${pathServer}/asset/run/integration/${file.id}`, requestHeaders)
                .then(
                    (resp) => {
                        if (resp.data.asset) {
                            const reprocessingAsset = { ...file, ...resp.data.asset }
                            dispatch(UploadActions.updateFileReprocess(reprocessingAsset))
                            dispatch(AppActions.unmarkAsset(file))
                            const params = getParatmeters(1, search)
                            getData(params)
                        }
                    })
                .catch((err) => {
                    const message = Utils.ajaxErrorGetMessage(err, t)
                    dispatch(UploadActions.removeFileReprocess({ ...file, status_integration: "ERROR" }))
                    showMessage(message, "error")
                    dispatch(pageBucketActions.setStateByField({ field: "loading", value: false }))
                })
        )
    }, [dispatch, filesInProgress, getData, getParatmeters, pathServer, requestHeaders, search, showMessage, t])

    const handleConfirmDelete = useCallback((file) => {
        dispatch(pageBucketActions.setStateByField({ field: "loading", value: true }))
        axios
            .delete(`${pathServer}/asset/${file.id}`, requestHeaders)
            .then(() => {
                dispatch(pageBucketActions.setDeleteFile({ value: file.id }))
                dispatch(AppActions.unmarkAsset(file))
            })
            .catch((err) => {
                const message = Utils.ajaxErrorGetMessage(err, t)
                dispatch(pageBucketActions.setStateByField({ field: "loading", value: false }))
                showMessage(message, "error")
            })
    }, [dispatch, pathServer, requestHeaders, showMessage, t])

    const getPage = useCallback(() => {
        return pageFindAll + 1
    }, [pageFindAll])

    const handleLoadMore = useCallback(() => {
        if (totals.current > files.length) {
            const page = getPage()
            const parameters = getParatmeters(page)
            getData(parameters)
        }
    }, [totals, files, getData, getParatmeters, getPage])

    const onThumbnailUpdated = useCallback((data) => {
        dispatch(pageBucketActions.thumbnailUpdated({ data }))
    }, [dispatch])

    return {
        onError,
        getData,
        setParametersFilters,
        handleReprocessItem,
        handleConfirmDelete,
        handleLoadMore,
        getParatmeters,
        onThumbnailUpdated,
        getPage,
        showMessage
    }
}
