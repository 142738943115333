import React from "react"

import { withSnackbar } from "notistack"
import Dropzone from "react-dropzone"

import imgUpload from "../../../../imagens/ui/ico-upload.svg"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Grid, Typography, Button } from "@material-ui/core"
import UploadDialog from "../../Core/UploadDialog"
import _ from "lodash"

class BackgroundImageUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedImage: props.currentImage,
      loading: false,
    }
    this.handleRemovePicture = this.handleRemovePicture.bind(this)
    this.onDropRejected = this.onDropRejected.bind(this)
    this.handleChangeImage = props.handleChangeImage.bind(this)
    this.getBase64 = this.getBase64.bind(this)
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  async onDropUpload(files) {
    const SELF = this
    if (files.length <= 0) {
      return
    }
    const file = await this.getBase64(files[0]);
    SELF.handleChangeImage({ target: { value: file } }, "background_image")
    SELF.setState({ ...SELF.state, selectedImage: file})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentImage !== this.props.currentImage) {
      this.setState({ selectedImage: this.props.currentImage })
    }
  }

  onDropRejected() {
    const { t, enqueueSnackbar } = this.props
    let message = t("Arquivo inválido. Arquivo até 5MB em JPG ou PNG")
    if (
      Boolean(this.props.type) &&
      Boolean(this.props.type.indexOf("/x-icon"))
    ) {
      message = t("Arquivo inválido. Arquivo até 5MB em ICO ou PNG")
    }
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    })
  }

  handleRemovePicture(event) {
    event.preventDefault()
    const SELF = this
    SELF.setState({
      ...SELF.state,
      loading: false,
      selectedImage: null,
    })
  }

  render() {
    const { t } = this.props
    const loading = false

    return (
      <React.Fragment>
        <Typography style={{ color: "#000000" }} variant="body1">
          {t("manager:Imagem de fundo")}
        </Typography>

        {this.props.checkedImage && (
          <Dropzone
            onDrop={this.onDropUpload.bind(this)}
            accept={"image/jpeg, image/png"}
            maxSize={5242880}
            onDropRejected={this.onDropRejected}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <div className="content">
                  <input {...getInputProps()} />
                  <>
                    {this.state.selectedImage ? (
                      <div className="image">
                        <img
                          src={
                            _.isString(this.state.selectedImage)
                              ? this.state.selectedImage
                              : URL.createObjectURL(this.state.selectedImage)
                          }
                          width={250}
                          alt="Imagem de fundo selecionada"
                        />
                      </div>
                    ) : (
                      <>
                        <img
                          src={imgUpload}
                          width={50}
                          alt={t(
                            "manager:Clique aqui ou arraste uma imagem para enviar"
                          )}
                        />
                        <br />
                        <Typography variant="body2">
                          Clique aqui ou arraste uma imagem para enviar"
                        </Typography>
                      </>
                    )}
                  </>
                  {/* )} */}
                </div>
              </div>
            )}
          </Dropzone>
        )}

        {this.props.checkedImage && this.state.selectedImage && (
          <Grid item xs={12} style={{ margin: "20px 0" }}>
            <div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ height: 40, marginRight: 25 }}
                  onClick={this.handleRemovePicture}
                  disabled={loading || this.state.loading}
                >
                  {t("common:Excluir")}
                </Button>
                <UploadDialog
                  t={t}
                  title={t("common:Adicionar nova imagem")}
                  descriptionUpload={t(
                    "manager:Clique aqui ou arraste uma imagem para enviar"
                  )}
                  onDropUpload={this.onDropUpload.bind(this)}
                  onDropRejected={this.onDropRejected}
                  loading={loading}
                  acceptFiles={
                    Boolean(this.props.type)
                      ? "image/x-icon"
                      : "image/jpeg, image/png"
                  }
                >
                  {(confirm) => (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ height: 40 }}
                      onClick={confirm()}
                    >
                      {t("common:Adicionar nova imagem")}
                    </Button>
                  )}
                </UploadDialog>
              </div>
            </div>
          </Grid>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ appReducer }) => {
  return {
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(["manager", "common"])(withSnackbar(BackgroundImageUploader)))
