import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './flux/store';
import { ToastContainer } from "react-toastify";
import ThemeContainer from "./components/Core/Theme";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ErrorBoundary from "components/Core/ErrorBoundary"

render((
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeContainer />
        <ToastContainer position="bottom-right" />
      </Provider>
    </I18nextProvider>
  </ErrorBoundary>
), document.getElementById('root'));