import React, { useState, Fragment, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useSnackbar } from "notistack";
import { withTranslation } from "react-i18next";

import Loading from "components/Loading/index";
import Template from "../Template";
import ErrorBoundary from "components/Core/ErrorBoundary";
import DialogConfirm from "components/Dialogs/DialogConfirm"
import DialogProfile from "components/Dialogs/MoveUserProfile"

import { Paper, Avatar, Button, Typography } from "@material-ui/core";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Settings from 'utils/Settings'
import _ from 'lodash'
import { useStyles } from "./styles";
import Utils from "utils/Utils";

const FIELDS = [
  { id: 'email', title: 'Email', field: 'email', translate: false },
  { id: 'profile', title: 'Perfil', field: 'profile.name', translate: false },
  { id: 'situation', title: 'Situação', field: 'situation', translate: true },
  { id: 'host', title: 'Anfitrião', field: 'host', translate: true },
  { id: 'allower', title: 'Moderado por', field: 'allower', translate: true },
]

const UserDefault = ({ history, t, match }) => {
  const [state, setState] = useState({
    loading: true,
    data: {},
    chekedData: false,
    dialog_open: "",
    loading_btn: ""
  })
  const { requestHeaders, pathServer,
    data: { currentPolicy = {} } } = useSelector(
      (state) => state.appReducer
    )
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const idUser = match.params.id

  const handleSetOpenDialog = useCallback((value = "") => {
    setState(prev => ({ ...prev, dialog_open: value }))
  }, [])

  const getData = useCallback(() => {
    const callback = () => {
      axios.get(`${pathServer}/user/${idUser}`, requestHeaders)
        .then((resp) => {
          setState(prev => ({
            ...prev,
            loading: false,
            data: resp.data.user,
            chekedData: true,
            dialog_open: "",
            loading_btn: ""
          }))
        })
        .catch((err) => {
          let message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading: false, chekedData: true }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }))
        })
    }
    setState(prev => ({ ...prev, loading: true }), callback())
  }, [requestHeaders, pathServer, enqueueSnackbar, idUser, t])

  useEffect(() => {
    let isMounted = true
    if (isMounted && !state.chekedData) {
      getData()
    }
    return () => { isMounted = false }
  }, [getData, state.chekedData])

  const handleSubmitUnlock = useCallback((userId) => {
    const callback = () => {
      axios.post(`${pathServer}/moderate/unblock/${userId}`, {}, requestHeaders)
        .then(function (resp) {
          enqueueSnackbar(resp.headers["x-message"], {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          })
          getData()
        })
        .catch(function (err) {
          let message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading_btn: "" }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }))
        })
    }
    setState(prev => ({ ...prev, dialog_open: "", loading_btn: "unlockUser" }), callback())
  }, [pathServer, requestHeaders, enqueueSnackbar, getData, t])

  const handleModerate = useCallback((action, userId) => {
    const callback = () => {
      let action_path = action ? "allow" : "deny";
      axios.post(`${pathServer}/moderate/${action_path}/${userId}`, {}, requestHeaders)
        .then(function (resp) {
          enqueueSnackbar(resp.headers["x-message"], {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          });
          getData()
        })
        .catch(function (err) {
          let message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading_btn: "" }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }))
        });
    }

    setState(prev => ({ ...prev, dialog_open: "", loading_btn: action ? "approval" : "disapproval" }), callback())
  }, [pathServer, requestHeaders, enqueueSnackbar, getData, t])

  const handleDelete = useCallback((id) => {
    const callback = () => {
      axios.delete(`${pathServer}/user/${id}`, requestHeaders)
        .then(function (resp) {
          enqueueSnackbar(resp.headers["x-message"], {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          });
          history.push("/manager/users");
        })
        .catch(function (err) {
          let message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading_btn: "" }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }))
        });
    }

    setState(prev => ({ ...prev, dialog_open: "", loading_btn: "delete" }), callback())
  }, [pathServer, requestHeaders, enqueueSnackbar, history, t])

  const handleSubmitInvite = useCallback((id) => {
    const callback = () => {
      axios.post(`${pathServer}/guest/resendInvite/${id}`, {}, requestHeaders)
        .then(function (resp) {
          setState(prev => ({ ...prev, loading_btn: "resendInvite" }),
            enqueueSnackbar(resp.headers["x-message"], {
              ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
            }))
        })
        .catch(function (err) {
          let message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading_btn: "" }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }))
        });
    }
    setState(prev => ({ ...prev, dialog_open: "", loading_btn: "resendInvite" }), callback())
  }, [pathServer, requestHeaders, enqueueSnackbar, t])

  const handleUpdateProfile = useCallback((userId, profileId) => {
    const callback = () => {
      axios.patch(`${pathServer}/user/${userId}/profile`, { profile: profileId }, requestHeaders)
        .then(function (resp) {
          enqueueSnackbar(t("manager:Perfil do usuário alterado com sucesso."), {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          });
          getData()
        })
        .catch(function (err) {
          let message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading_btn: "" }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }))
        })
    }
    setState(prev => ({ ...prev, dialog_open: "", loading_btn: "moveProfile" }), callback())
  }, [pathServer, requestHeaders, enqueueSnackbar, t, getData])

  const can = (action) => _.includes(_.get(state, "data.actions", []), action);

  return (
    <Fragment>
      <Template localHistory={history} customClassName={"container-center"}>
        <ErrorBoundary>
          <Loading showLoading={state.loading} />        
            <Paper elevation={2} style={{ padding: "40px 0" }}>
              {
                <div className={classes.rootAvatar}>
                  {_.get(state, 'data.urls.thumbnail.original', false) ? (
                    <Avatar
                      alt="Thumbnail"
                      className={classes.avatar}
                      src={_.get(state, 'data.urls.thumbnail.original', '')}
                    />
                  ) : (
                    <Tooltip title="Alterar Imagem" placement="top">
                      <button
                        className="btnEditImg"
                        onClick={() => {
                          if (_.get(state, 'data.id', false)) {
                            history.push(`/manager/users/upload/${_.get(state, 'data.id', '')}`)
                          } else {
                            return {}
                          }
                        }}
                      >
                        <Avatar className={classes.avatar}>
                          <AccountCircleRoundedIcon fontSize="large" />
                        </Avatar>
                      </button>
                    </Tooltip>
                  )}

                  {_.get(state, 'data.name', false) && <h2>{_.get(state, 'data.name', '')}</h2>}
                </div>
              }

              {
                FIELDS.map(i => {
                  if (state.data.hasOwnProperty(i.id)) {
                    return (
                      <div className={classes.row} key={i.id}>
                        <Typography variant='body2' className="title">
                          {t(`common:${i.title}`)}
                        </Typography>
                        <Typography variant='body2'>
                          {i.translate ? t(`common:${_.get(state, `data.${i.field}`, 'err')}`) : _.get(state, `data.${i.field}`, 'err')}
                        </Typography>
                      </div>
                    )

                  }
                  return null
                })
              }

              {
                _.get(state, 'data.approved_at', false) &&
                <div className={classes.row}>
                  <Typography variant='body2' className="title">
                    {t("common:Data de aprovação")}
                  </Typography>
                  <Typography variant='body2'>
                    {Utils.formatDate(_.get(state, 'data.approved_at', ''))}
                  </Typography>
                </div>
              }

              {
                _.get(state, 'data.created_at', false) &&
                <div className={classes.row}>
                  <Typography variant='body2' className="title">
                    {t("common:Data de criação")}
                  </Typography>
                  <Typography variant='body2'>
                    {Utils.formatDate(_.get(state, 'data.created_at', ''))}
                  </Typography>
                </div>
              }

              {
                _.get(currentPolicy, 'reference', false) &&
                _.get(currentPolicy, 'file', false) && (
                  <div className={classes.row}>
                    <Typography variant='body2' className="title">{t("Política de privacidade")}</Typography>
                    {
                      _.get(state, 'data.current_policy.accepted', false) ?
                        <Typography variant='body2'>{t("common:Sim")}</Typography> : <Typography variant='body2'>{t("common:Não")}</Typography>
                    }
                  </div>
                )}

              {
                _.get(state, 'data.expiry_date', false) &&
                <div className={classes.row}>
                  <Typography variant='body2' className="title">{t("Expiração")}</Typography>
                  <Typography variant='body2'>{Utils.formatDate(_.get(state, 'data.expiry_date', ''))}</Typography>
                </div>
              }
              {
                Array.isArray(_.get(state, 'data.attributes', []))
                && _.get(state, 'data.attributes', []).map((el) => {
                  if (Boolean(el.value)) {
                    return (
                      <div className={classes.row} key={el.id}>
                        <Typography variant='body2' className="title">{t(el.label)}</Typography>
                        <Typography variant='body2'>{el.value}</Typography>
                      </div>
                    )
                  }
                  return null
                })
              }

              <div className={classes.btnWrapper}>
                <Button
                  disabled={Boolean(state.loading_btn)}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSetOpenDialog("moveProfile")}
                >
                  {
                    state.loading_btn === "moveProfile" ? (
                      <CircularProgress size={20} color="primary" />
                    ) : t('manager:Mover de perfil')
                  }
                </Button>
                <>
                  {can("approve") &&
                    <Fragment>
                      <Button
                        disabled={Boolean(state.loading_btn)}
                        variant="contained"
                        className="action ok"
                        onClick={() => handleSetOpenDialog("approval")}
                      >
                        {
                          state.loading_btn === "approval" ? (
                            <CircularProgress size={20} color="primary" />
                          ) : t('common:aprovar')
                        }
                      </Button>

                      <Button
                        disabled={Boolean(state.loading_btn)}
                        variant="contained"
                        className="action not"
                        onClick={() => handleSetOpenDialog("disapproval")}
                      >
                        {
                          state.loading_btn === "disapproval" ? (
                            <CircularProgress size={20} color="primary" />
                          ) : t('common:reprovar')
                        }
                      </Button>
                    </Fragment>
                  }
                  {can("delete") && (
                    <Button
                      disabled={Boolean(state.loading_btn)}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSetOpenDialog("deleteConfirm")}
                    >
                      {
                        state.loading_btn === "delete" ? (
                          <CircularProgress size={20} color="primary" />
                        ) : t('Revogar e excluir acesso')
                      }
                    </Button>
                  )}
                </>
                {can("resendInvite") && (
                  <Button
                    disabled={Boolean(state.loading_btn)}
                    variant="contained"
                    color='primary'
                    onClick={() => handleSubmitInvite(_.get(state, 'data.id', ''))}
                  >
                    {
                      state.loading_btn === "resendInvite" ? (
                        <CircularProgress size={20} color="primary" />
                      ) : t('common:reenviar convite')
                    }
                  </Button>
                )}

                {can("unblock") &&
                  <Button
                    disabled={Boolean(state.loading_btn)}
                    variant="contained"
                    className="action not"
                    onClick={() => handleSetOpenDialog("unlockUser")}
                  >
                    {
                      state.loading_btn === "unlockUser" ? (
                        <CircularProgress size={20} color="primary" />
                      ) : t('common:desbloquear')
                    }
                  </Button>
                }
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 20px 0 20px' }}>
                <Button
                  disabled={Boolean(state.loading_btn)}
                  className="cancel"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/manager/users")}
                >
                  {t("common:Voltar")}
                </Button>
              </div>
            </Paper>
         
        </ErrorBoundary>
      </Template>

      <DialogConfirm
        title={t("manager:Desbloquear usuário")}
        description={t(`common:Tem certeza que deseja desbloquear?`, { name: _.get(state, 'data.name', '') })}
        open={_.get(state, 'dialog_open', '') === "unlockUser"}
        handleClose={() => handleSetOpenDialog("")}
        handleConfirm={() => handleSubmitUnlock(_.get(state, 'data.id', ''))}
      />
      <DialogConfirm
        title={t("common:Aprovação de usuário")}
        description={t(`common:Tem certeza que deseja aprovar?`, { name: _.get(state, 'data.name', '') })}
        open={_.get(state, 'dialog_open', '') === "approval"}
        handleClose={() => handleSetOpenDialog("")}
        handleConfirm={() => handleModerate(true, _.get(state, 'data.id', ''))}
      />
      <DialogConfirm
        title={t("common:Reprovação de usuário")}
        description={t(`common:Tem certeza que deseja reprovar?`, { name: _.get(state, 'data.name', '') })}
        open={_.get(state, 'dialog_open', '') === "disapproval"}
        handleClose={() => handleSetOpenDialog("")}
        handleConfirm={() => handleModerate(false, _.get(state, 'data.id', ''))}
      />
      <DialogConfirm
        title={t("manager:Exclusão de usuário")}
        description={t(
          `common:Tem certeza que deseja revogar e excluir o acesso de?`, { name: _.get(state, 'data.name', '') }
        )}
        open={_.get(state, 'dialog_open', '') === "deleteConfirm"}
        handleClose={() => handleSetOpenDialog("")}
        handleConfirm={() => handleDelete(_.get(state, 'data.id', ''))}
      />
      <DialogProfile
        open={_.get(state, 'dialog_open', '') === "moveProfile"}
        handleClose={() => handleSetOpenDialog("")}
        handleConfirm={(profileId) => handleUpdateProfile(_.get(state, 'data.id', ''), profileId)}
      />
    </Fragment>
  );
};

export default withTranslation(["common"])(UserDefault);
