import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  listWrapper: {
    marginBottom: 30,
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 230px)",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    "& button:first-child": {
      borderTop: "1px solid #DDDDDD",
    },
    "& #selected": {
      boxShadow: `rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
                rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 3px 0px`,
    },
  },
  image: {
    border: "1px solid #F0F0F0",
    borderRadius: 4,
    maxWidth: 154,
    "&:hover": {
      cursor: "pointer",
      boxShadow: `rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
      rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 3px 0px`,
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    '& span.MuiSkeleton-root': {
      borderRadius: 4,
    }
  },
  row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "5px 23px",
    height: 166,
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rootBar: { 
    position: "fixed",
    zIndex: 3000,
    top: 100,
    right: 0,
    margin: 0,
    width: '100%',
    backgroundColor: '#fff',
    color: '#666',
    "& #rootToolBarFiltersBucket": {     
      margin: 0
    },  
    '& a': {
      color: '#666'
    },
    '& #content-divider': {
      display: 'flex',
      alignItems: 'center',
    },
    '& #line-divider': {
      height: 35,
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      margin: '0 5px',
    },
    '& #svg-primary': {
      color: `${theme.palette.primary.main} !important`,
    },
    [theme.breakpoints.down("sm")]: {     
      '& div.MuiToolbar-root': {
        flexWrap: 'wrap'
      }
    }
  },
  barFilter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props => props.isBucket ? '30vw' : '100%',    
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      paddingTop: 10,      
    }
  },
  barInputSearch: {    
    width: '40vw',     
    justifyContent: 'center',
    '& #input_search': {
        backgroundColor: '#eee',
        '& .MuiInputBase-root': {
            width: '100%',
            '& input': {
                color: '#666 !important'
            }
        }            
    },        
    [theme.breakpoints.down("sm")]: {
        width: '100%',
        padding: '10px 0'
    }
  },
  barActionsItens: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: props => props.isBucket ? '30vw' : '100%',
    '& .MuiFormControlLabel-root': {
      margin: 0
    },
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      padding: '5px 0'
    }
  }
}))
