import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios'
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import _ from 'lodash'
import { useSnackbar } from "notistack"

import {
    Grid
} from '@material-ui/core';

import Row from './Row'

import Skeleton from '@material-ui/lab/Skeleton'

import { useStyles } from './styles'
import Utils from 'utils/Utils'

const RecipesDownloadsBtns = ({ data, path, onlyAsset }) => {
    const [state, setState] = useState({ loading: true, hasData: false, dataRecipes: [] })
    const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
    const { t } = useTranslation()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    
    const getData = useCallback((path) => {
        axios.get(`${pathServer}/${path}`, requestHeaders)
            .then((result) => {
                const setResult = _.get(result, 'data.recipes', []).map(value => {
                    value.params = {
                        items: [
                            {
                                id: _.get(data, 'item_id', ''),
                                version: _.get(data, 'versionIndex', ''),
                                ...(_.get(data, 'assets_ids', false) && { assets: _.get(data, 'assets_ids', []) })
                            }
                        ],
                        ...(Boolean(_.get(value, 'name', '').trim()).length > 0 && { name: value.name }),
                        ...(_.get(data, 'download_item', false) && { download_item: true }),
                        ...(_.get(value, 'slug', false) && { type: value.slug }),
                        ...(_.get(data, 'include_metadata', false) && { include_metadata: true })
                    }

                    value.hasHighLow = data.hasHighLow

                    return value
                })
                setState({
                    loading: false, hasData: true, dataRecipes: [
                        ..._.get(data, 'options', []),
                        ...setResult
                    ]
                })
            })
            .catch((err) => {
                const message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
                setState({ loading: false, hasData: false, dataRecipes: [] }, enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                }))
            })
    }, [pathServer, requestHeaders, enqueueSnackbar, t, data])

    useEffect(() => {
        let isMounted = true
        if (!state.hasData && Boolean(path) && isMounted) {
            getData(path)
        }
        return () => { isMounted = false }
    }, [getData, state.hasData, path, data])

    return (
        <Grid container spacing={2} className={classes.gridContainer}>
            {
                state.loading ? (
                    <>
                        <Grid item xs={12} sm={4} className='skeleton-wrapper'>
                            <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                        </Grid>
                        <Grid item xs={12} sm={4} className='skeleton-wrapper'>
                            <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                        </Grid>
                        <Grid item xs={12} sm={4} className='skeleton-wrapper'>
                            <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                        </Grid>
                        <Grid item xs={12} sm={4} className='skeleton-wrapper'>
                            <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                        </Grid>
                    </>
                ) : (
                    <>
                        {
                            state.dataRecipes.length > 0 && state.dataRecipes.map((i, key) => {
                                if (!i.hasHighLow) {
                                    return null
                                }

                                return (
                                    <Row
                                        key={`${i.id}-${key}`}
                                        title={t(`common:${_.get(i, 'name', i.title)}`)}
                                        params={i.params || {}}
                                        alternativenameId={_.get(data, 'alternativename_id', '')}
                                        onlyAsset={onlyAsset}
                                        dataDate={_.get(data, 'date_end', '')}
                                    />
                                )
                            })
                        }
                    </>
                )
            }
        </Grid>
    );
}


export default RecipesDownloadsBtns