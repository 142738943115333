import React, { useCallback, useState } from 'react';
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom';
import {
    Typography,
    Accordion,
    AccordionSummary,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ImageLazyLoad from "components/ImageLazyLoad"
import DetailItemAccordion from "./DetailItemAccordion"

const useStyles = makeStyles((theme) => ({
    rootSummary: {        
        "& #btn-info": {
            color: theme.palette.primary.main
        },
        "& svg": {
            color: theme.palette.primary.main
        }
    }
}));

export default function ItemAccordion({ data = {}, region = '' }) {
    const [expand, setExpand] = useState(false)
    const classes = useStyles();
    const { t } = useTranslation()
    const history = useHistory()

    const handleExpand = useCallback(() => {    
        setExpand(prev => !prev)
    }, [])

    const handlePushItem = useCallback((event) => {
        event.stopPropagation()
        const { division, segment, category, taxonomy } = data
        if (division && segment && category && taxonomy) {
            history.push(`/dam/${region}/${division.slug}/${segment.slug}/${category.slug}/${taxonomy.slug}`)
        }
        return null
    }, [region, data, history])
    
    return (
        <Accordion onChange={handleExpand}>
            <AccordionSummary className={classes.rootSummary}>
                <div style={{ marginRight: 15 }} onClick={handlePushItem}>
                    <ImageLazyLoad
                        styles={{ width: 50, height: 50 }}
                        images={{
                            imgJPG: _.get(data, 'thumbnail.thumbnail_pre_original', ''),
                            imgWEBP: _.get(data, 'thumbnail.thumbnail_pre_webp', '')
                        }}
                        alt={_.get(data, 'name', '')}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', maxWidth: 200 }}>
                    <div onClick={handlePushItem}>
                        <Typography variant='caption'>
                            {_.get(data, 'taxonomy.name', '')}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <Typography variant='caption' id="btn-info">
                            {t("DAM:Ver informações do Item")}
                        </Typography>
                        <div style={{ padding: 0 }}
                            className={
                                expand
                                    ? "MuiButtonBase-root MuiIconButton-root MuiAccordionSummary-expandIcon Mui-expanded MuiIconButton-edgeEnd"
                                    : "MuiButtonBase-root MuiIconButton-root MuiAccordionSummary-expandIcon MuiIconButton-edgeEnd"
                            }>
                            <span className='MuiIconButton-label'>
                                <ExpandMoreIcon />
                            </span>
                        </div>
                    </div>
                </div>
            </AccordionSummary>

            {expand && <DetailItemAccordion idItem={_.get(data, 'id', '')} />}
        </Accordion>
    );
}