import React, { useState } from 'react';
import { useSelector } from "react-redux"
import axios from 'axios'
import { useSnackbar } from "notistack"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

import CreatableUIMult from 'components/ComboBox/CreatableUIMult'

import Utils from 'utils/Utils'

const DialogCreateMetadata = ({
  idMeta,
  title,
  open,
  handleClose,
  handleUpdateMetadataValues
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [dataValues, setDataValues] = useState([])
  const [inputValue, setInputValue] = useState('')
  const { requestHeaders, pathServer } = useSelector(
    (state) => state.appReducer
  )
  const classes = useStyles();
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeCreatable = (values) => {
    setDataValues(values)
  };

  const handleInputChangeCreatable = (inputValue) => {
    setInputValue(inputValue)
  };

  const createOption = (label) => ({
    label: label,
    value: label,
  })

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        let newValue = Array.isArray(dataValues) ? [...dataValues] : []

        if (inputValue.includes(',')) {
          inputValue.split(',').forEach((i) => {
            newValue.push(createOption(i))
          })
        } else {
          newValue = Array.isArray(dataValues) ? [...dataValues, createOption(inputValue)] : [createOption(inputValue)]
        }

        setInputValue('')
        setDataValues(newValue)
        event.preventDefault()
        break;
      default:
        break;
    }
  }

  const validForm = () => {
    const values = dataValues.map(i => i.value)

    const hasDuplicate = Utils.hasDuplicates(dataValues)

    if(hasDuplicate) {
      setError(t("common:Este campo contem itens duplicados"))
      return false
    }

    if (values.length === 0) {
      setError(`* ${t("common:Este campo é obrigatório")}`)
      return false
    }

    return true
  }

  const submitForm = () => {
    if (!validForm()) {
      return
    }

    setLoading(true)
    const parameters = {
      values: dataValues.map(i => i.value)
    }

    axios.put(`${pathServer}/attribute/${idMeta}/addlist`, parameters, requestHeaders)
      .then((resp) => {
        setLoading(false)
        handleUpdateMetadataValues(dataValues, idMeta)       
      })
      .catch((err) => {
        setLoading(false)
        const message = err.response
          ? t(err.response.data.slug) || err.response.data.message || err.response.headers["x-message"]
          : t("Erro inesperado. Contate o suporte")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
        console.log(err)
      });      
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          <div>{t("common:Adicionar metadado a lista de", { name: title })}</div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: '0' }}>
        <Grid item xs={12} style={{ padding: '20px 0' }}>
          <CreatableUIMult
            title={t("common:Valores")}
            description={t("common:Digite o valor desejado e aperte ENTER ou TAB para adicionar. Para lista de valores, utilize vírgula (,) para separar.")}
            idMeta = {idMeta}
            values={dataValues}
            handleChangeCreatable={handleChangeCreatable}
            error={error}
            inputValue={inputValue}
            handleInputChangeCreatable={handleInputChangeCreatable}
            handleKeyDown={handleKeyDown}
            isDisabled={loading}
          />
        </Grid>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            style={{ height: 40, width: 115, marginRight: 20 }}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {t("common:Cancelar")}
          </Button>
          <Button
            style={{ height: 40, width: 115 }}
            variant="contained"
            color="primary"
            onClick={(evt) => {
              evt.stopPropagation()
              submitForm()
            }}
            disabled={loading}
          >
            {t("common:Salvar")}
          </Button>

        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogCreateMetadata
