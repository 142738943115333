import React, { useCallback, useMemo } from "react"

import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom'

import { Button } from '@material-ui/core'

import DialogSharedItems from "components/Dialogs/DialogSharedItems"
import ShareRoundedIcon from "@material-ui/icons/ShareRounded"

import { useDataItems } from 'pages/DAM/Items/context'

function MenuAllShared({ totalMarkedItems = 0, totalResultItems = 0 }) {
    const { params_filter_items } = useSelector(state => state.appBarReducer)
    const { getParamsToItems, isShortcut } = useDataItems()

    const { t } = useTranslation()
    const { shortcut_slug } = useParams()

    const getParams = useCallback(() => {
        const paramsToItems = getParamsToItems()
        delete paramsToItems.limit
        delete paramsToItems.only
        delete paramsToItems.page
        delete paramsToItems.search_after

        return { ...paramsToItems, ...params_filter_items,  shareType: "email"}
    }, [getParamsToItems, params_filter_items])

    const constants = useMemo(
        () => ({
            params: getParams(),
            disableBtnDownload: ((totalResultItems === 0) || (totalMarkedItems > 0) || (totalResultItems > 1000))
        }), [totalMarkedItems, totalResultItems, getParams])

    return (
        <DialogSharedItems
            disabled={constants.disableBtnDownload}
            pathDefault={!isShortcut ? 'item/find/share' : `item/share/shortcut/${shortcut_slug}`}
            paramsToItems={constants.params}
        >
            {(confirm) => {
                return (
                    <div className='buttonAllShared'>
                    <Button
                        style={{ height: 32 }}
                        startIcon={<ShareRoundedIcon />}
                        variant="contained"
                        color='primary'
                        disabled={constants.disableBtnDownload}
                        onClick={confirm()}
                    >
                        {t('DAM:compartilhar tudo')}
                    </Button>
                    </div>
                );
            }}
        </DialogSharedItems>
    )
}


export default MenuAllShared
