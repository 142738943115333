import React from 'react';
import _ from "lodash"
import { useTranslation } from "react-i18next"

import ShareIcon from '@material-ui/icons/Share';
import PaperTitle from "../PaperTitle"

import { useDataAssetIntegra } from "../context"
import SharedStepsWrapper from 'components/SharedStepsWrapper'

const SendEmail = ({ disabled }) => {
  const { stateItem } = useDataAssetIntegra()
  const { t } = useTranslation()

  return (
    <>
      <PaperTitle
        title={t("DAM:Compartilhar item")}
        icon={ShareIcon}
      />

      <SharedStepsWrapper
        disabled={disabled}
        category_id={_.get(stateItem, 'data.category_id', '')}
        id_item={_.get(stateItem, 'data.id', '')}
        versionIndex={_.get(stateItem, 'versionIndex', 0)}
        data={{
          name: _.get(stateItem, 'data.name', ''),
          id: _.get(stateItem, 'data.id', ''),
          hasHighLow: _.get(stateItem, 'hasHighLow', false),
          copyLink: false
        }}
      />
    </>
  )
}

export default SendEmail
