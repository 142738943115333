import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {      
    '& .listFiles': {
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',           
    },
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 240px)",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  }, 
  row: {
    display: 'flex', 
    borderBottom: '1px solid #DDDDDD',    
    paddingRight: 17,
    "&:hover": {  
      backgroundColor: "#F0F0F0",       
      "& button": {      
        cursor: "pointer", 
        backgroundColor: "#F0F0F0",      
      },   
    },
  },  
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleMobile: {
    marginLeft: theme.spacing(2),
    flex: 1,
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  wrapperAccordion: {
    display: "flex",
    position: "fixed",
    bottom: "80px",
    zIndex: 1000,
    right: "44px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      bottom: 20,
      right: 20
    },
  }, 
  card: {
    width: 250,
    height: 365,
    margin: theme.spacing(1),
  },
  cardSelected: {
    position: "relative",
    bottom: "calc(100% - 1px)",
    width: 250,
    height: 366,
    zIndex: 0,
    border: `3px solid ${theme.palette.primary.main}`,
  },
  cardContent: {
    padding: "9px 16px",
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    flexDirection: 'column'
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 16px",   
    backgroundColor: "#fff",
    width: "100%",
    height: "75px",  
    "&:hover": {
      backgroundColor: "#F0F0F0",      
    },  
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 155
  },
}))
