import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  listWrapper: {
    marginTop: 30,
     overflow: 'auto',
     maxHeight: 'calc(100vh - 170px)',
    "& .container-version": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 16px",
      borderBottom: "1px solid #DDDDDD",   
      backgroundColor: "#fff",
      "& button": {
        cursor: "pointer",        
      },
      "&:hover": {
        backgroundColor: "#f0f0f0",
      }, 
         
    },
    '& div.container-version:first-child': {      
      borderTop: "1px solid #DDDDDD",
    },
    "& .container-version.selected": {
      backgroundColor: "#f0f0f0",
    },
  },
}));
