import React from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import ImageLazyLoad from "../../ImageLazyLoad"
import AppActions from "flux/actions/AppActions"
import Utils from 'utils/Utils'

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next"
import { Tooltip } from "@material-ui/core"
import { ReactComponent as EmptyFolderIcon} from 'imagens/empty-folder.svg'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '230px !important',
    height: '230px !important',
    backgroundColor: '#ffffff',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    borderRadius: 8,
    margin: '0 20px 10px 0',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  image: {
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      cursor: 'pointer',
      display: 'block',
      margin: '0 auto',
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      '& .ico': {
        height: 120,
        width: 'auto',
      }
    },
    '& .textContainer': {
      cursor: 'pointer',
      textAlign: 'center',
      width: '100%',
      padding: 10,
      '& .textContent': {
        fontSize: 'calc(16px + 6 * ((100vw - 320px) / 680))',
        fontWeight: 500,
        color: '#666',
        width: '100%',
        whiteSpace: "break-spaces",
        wordBreak: "break-word"
      }
    },
  },
  root_grouping: {
    '& .title': {
      fontWeight: 500,
      fontSize: 27,
      color: '#666666',
      textOverflow: 'ellipsis',
      overflow: 'hidden',      
    },
  }
}))
const Card = ({
  slugSegment,
  slugCategory,
  slugItem,
  imgJPG,
  imgWEBP,
  title,
  slug,
  region,
  regionShortcut,
  grouping,
  handleClickOverride = false,
  isGroupingCards,
  updatedAt,
  count
}) => {
  let urlSegment = slugSegment ? "/" + slugSegment : ""
  let urlCategory = slugCategory ? "/" + slugCategory : ""
  let urlItem = slugItem ? "/" + slugItem : ""
  const regionCurrent = useSelector(state => state.appReducer.userData.region)
  const { pathClient, requestHeaders } = useSelector(
    (state) => state.appReducer
  )
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClick = () => {
    if (regionShortcut) {
      if (regionCurrent === regionShortcut) {
        grouping
          ? history.push(`/gallery/${regionShortcut}/${slug}`)
          : history.push(`/shortcut/${regionShortcut}/${slug}`)
      } else {
        dispatch(AppActions.changeRegion(
          regionShortcut,
          { pathClient, requestHeaders },
          () => grouping
            ? history.push(`/gallery/${regionShortcut}/${slug}`)
            : history.push(`/shortcut/${regionShortcut}/${slug}`),
          false))
      }
    } else {     
      history.push("/dam/" + region + "/" + slug + urlSegment + urlCategory + urlItem)
    }
  }

  const hasImage = imgJPG || imgWEBP
  const maxLengthTitle = title.length <= 47 ? title : `${title.slice(0, 47)}...`

  const txtContent = (
    <CardContent className="textContainer">
      <Tooltip title={title}>
        <Typography className="textContent">
          {maxLengthTitle}
        </Typography>
      </Tooltip>
    </CardContent>
  )

  const imageContent = !hasImage ? (
    txtContent
  ) : (
    <ImageLazyLoad styles={{width: 230}}   images={{imgWEBP: imgWEBP, imgJPG: imgJPG}} alt={title} />
  )

  return (
    <>
      <div style={{ position: 'relative', margin: '10px 10px 10px 0' }}>
        <li className={classes.card} style={{ zIndex: isGroupingCards && 1, position: isGroupingCards && 'relative' }} onClick={!handleClickOverride ? handleClick : handleClickOverride}>
          {
            !isGroupingCards ? (
              <div className={classes.image}>{imageContent}</div>
            ) : (
              <>
                <div className={classes.root_grouping} >
                  {
                    Boolean(imgJPG) && Boolean(imgWEBP) ?
                    <>
                      <ImageLazyLoad styles={isGroupingCards ? {height: 132} : {width: 230}} height='100%' images={{imgWEBP: imgWEBP, imgJPG: imgJPG}} alt={title} />
                    </>
                    :
                    <div style={{width: 230, height: '137px', background: '#E0E0E0', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <EmptyFolderIcon />
                    </div>
                  }
                  <div style={{ display: 'flex', flexDirection: 'column', background: '#fff', padding: '10px 5px' }}>
                    <Tooltip title={title}>
                      <Typography variant="body2" className="title" noWrap>{title}</Typography>
                    </Tooltip>
                    <Typography variant="caption">{t('common:última atualização')}:</Typography>
                    {
                      updatedAt ?
                        <Typography variant="caption">{Utils.formatDateFull(updatedAt)}</Typography>
                        :
                        <Typography variant="caption">{t('common:sem registro')}</Typography>
                    }
                  </div>
                </div>
              </>
            )
          }
        </li>
        {
          isGroupingCards && count === 2 ?
            <FakeCard /> :
            isGroupingCards && count >= 3 ?
              <>
                <FakeCard top={10} right={10} />
                <FakeCard />
              </> : null
        }
      </div>
    </>
  )
}

const FakeCard = ({ top, right }) => {
  const classes = useStyles()

  return (
    <div className={classes.card} style={{ position: 'absolute', margin: 0, top: top || 5, right: right || 15, zIndex: -1 }}>
      <>
        <div className={classes.root_grouping}>
          <div style={{ width: 230, height: 132, background: '#E0E0E0' }}></div>
        </div>
      </>
    </div>
  )
}

export default Card
