import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import axios from 'axios'

import {
    Tooltip,
    IconButton,
} from "@material-ui/core";

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"

import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import DialogConfirmValidation from "components/Alert/DialogConfirmValidation";
import AlertDialogSimple from 'components/Alert/AlertDialogSimple'
import AppActions from "flux/actions/AppActions";

import Loading from "components/Loading"
import Utils from 'utils/Utils'

import { useDataItems } from 'pages/DAM/Items/context'
import { Creators as ItemsActions } from "flux/ducks/Items"

const DeleteItems = ({ defaultOpen, totalMarkedItems, limitExceeded }) => {
    const [state, setState] = useState({ loading: false, dialog_open: false })
    const { markedItems, requestHeaders, pathServer, userData: { region } } = useSelector((state) => state.appReducer);
    const { name_dialog_open } = useSelector((state) => state.itemsReducer);

    const { showItemsSelected } = useDataItems()

    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const history = useHistory()

    const handleClickDialog = useCallback((field = '', value) => {
        if (value && !showItemsSelected) {
            dispatch(ItemsActions.setOpenDialogItemsMarked({ open: value ? field : '' }))
            history.push(`/items/${region}`)
        }
    }, [showItemsSelected, history, region, dispatch])

    const handleConfirmDeleteAllMarked = useCallback((text) => {
        const idsMarkedItems = markedItems.map((item) => item.id)
        setState(prev => ({ ...prev, loading: true }),
            axios
                .delete(`${pathServer}/items`, {
                    ...requestHeaders,
                    data: { uuids: idsMarkedItems, confirm_message: text },
                })
                .then(function () {
                    setState(prev => ({ ...prev, dialog_open: true }))
                })
                .catch((err) => {
                    const message = Utils.ajaxErrorGetMessage(err, t)
                    setState(prev => ({ ...prev, loading: false }),
                        enqueueSnackbar(message, {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                        })
                    )
                })
        )
    }, [enqueueSnackbar, markedItems, pathServer, requestHeaders, t])

    const handleCloseDialog = useCallback(() => {
        const callback = () => {
            dispatch(AppActions.clearMarkedItems())
            dispatch(ItemsActions.setOpenDialogItemsMarked({ open: '' }))
        }
        setState(prev => ({ ...prev, dialog_open: false, loading: false }), callback())
    }, [dispatch])

    return (
        <>
            <Loading showLoading={state.loading} />

            <DialogConfirmValidation
                openDefault={((name_dialog_open === 'openDelete') || defaultOpen)}
                handleSetOpen={(value) => handleClickDialog('openDelete', value)}
                title={t("DAM:Itens para exclusão")}
                description={
                    totalMarkedItems === 1
                        ? t("common:Você deseja realmente excluir o item selecionado?")
                        : t("common:Você deseja realmente excluir todos os itens selecionados?", { total: totalMarkedItems })
                }
                phraseInput={
                    totalMarkedItems === 1
                        ? t(`common:Excluir item`, { total: totalMarkedItems })
                        : t(`common:Excluir itens`, { total: totalMarkedItems })
                }
                icon={<DeleteRoundedIcon />}
                handleDelete={handleConfirmDeleteAllMarked}
                total={totalMarkedItems}
                disabled={limitExceeded}
            >
                {(confirm) => (
                    <Tooltip title={limitExceeded ? t("common:Limite de 30 itens selecionados") : t("manager:Excluir")} placement="top">
                        <div>
                            <IconButton
                                disableRipple
                                aria-haspopup="true"
                                color="inherit"
                                style={{ padding: 10 }}
                                onClick={confirm()}
                                disabled={limitExceeded}
                            >
                                <DeleteRoundedIcon
                                    id={((name_dialog_open === 'openDelete') || defaultOpen) ? 'svg-primary' : ''}
                                />
                            </IconButton>
                        </div>
                    </Tooltip>
                )}
            </DialogConfirmValidation>

            <AlertDialogSimple
                open={state.dialog_open}
                icon={<DeleteRoundedIcon id="main" />}
                handleClose={() => handleCloseDialog()}
                title={t(`common:Exclusão em andamento`)}
                description={t("DAM:Os itens em que não há permissão para gerenciar não serão excluídos.")}
            />
        </>
    );
};

DeleteItems.defaultProps = {
    totalMarkedItems: 0,
    limitExceeded: false,
    defaultOpen: ""
}

DeleteItems.propTypes = {
    totalMarkedItems: PropTypes.number,
    limitExceeded: PropTypes.bool,
    defaultOpen: PropTypes.string
}

export default DeleteItems

