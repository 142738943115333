import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import axios from "axios"
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { Typography, Grid, Paper } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import Loading from "components/Loading"
import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"
import AssetsdndContainer from 'containers/AssetsDndContainer'
import FormCreateOrEditItem from 'components/FormCreateOrEditItem'

import AppActions from "flux/actions/AppActions"
import { Creators as AssetActions } from "flux/ducks/Asset"
import Utils from 'utils/Utils'
import Settings from 'utils/Settings'
import { createEditForm, createForm } from 'components/CategoryAttributes'
import { useStyles } from './styles'

const LIMIT_ASSETS = 150

const CreateItem = () => {
    const {
        data: { name, description },
        markedAssets,
        pathServer,
        requestHeaders
    } = useSelector(state => state.appReducer)
    const { pathname } = useSelector(state => state.assetReducer)
    const { paramsDataToCreateItems, filtersOptions, dataLevels } = useSelector((state) => state.itemsReducer)
    const [state, setState] = useState({
        loading: true,
        dataTags: markedAssets.map(i => ({ id: i.id, value: [] })),
        defaultData: {},
        checkedData: false
    })
    const history = useHistory()
    const { t } = useTranslation()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const handleBack = useCallback(() => {
        if (Boolean(pathname)) {
            history.push(pathname)
        } else {
            history.goBack()
        }
    }, [history, pathname])

    const insert = useCallback((params) => {
        const callback = () => {
            axios.post(`${pathServer}/item`, params, requestHeaders)
                .then((resp) => {
                    const callback = () => {
                        enqueueSnackbar(resp.headers["x-message"] || t("Registro inserido com sucesso."), {
                            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                        });
                        dispatch(AssetActions.clearPathname())
                        dispatch(AssetActions.setAllData([]))
                        dispatch(AppActions.markListAssets([]))
                        history.goBack()
                    }
                    setState(prev => ({ ...prev, loading: false }), callback())
                },
                    (err) => {
                        let message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))

                        if (err.response.status === 422) {
                            message = _.get(err, 'response.data.validity_date', t("manager:Erro inesperado. Contate o suporte"))
                        }
                        if (err.response.status === 409) {
                            message = _.get(err, 'response.data.taxonomy', t("manager:Erro inesperado. Contate o suporte"))
                        }
                        setState(prev => ({ ...prev, loading: false }),
                            enqueueSnackbar(message, {
                                variant: "error",
                                autoHideDuration: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center",
                                },
                            }))
                    })
                .catch((err) => {
                    let message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
                    setState(prev => ({ ...prev, loading: false }),
                        enqueueSnackbar(message, {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                        }))
                })
        }
        setState(prev => ({ ...prev, loading: true }), callback())
    }, [dispatch, enqueueSnackbar, history, pathServer, requestHeaders, t])

    const formValidationAndGetParams = useCallback((params) => {
        let dataTags = _.get(state, 'dataTags', [])
        let parameters = {}
        let tags = {}

        if (markedAssets.length <= LIMIT_ASSETS) {
            parameters = { ...params }
            if (dataTags.length > 0) {
                for (let index in dataTags) {
                    tags[dataTags[index].id] = dataTags[index].value.map(i => i.value)
                }
            }
            parameters.assets = markedAssets.map(asset => asset.id)
            parameters.tags = tags
            if (markedAssets.length === 0) {
                parameters.is_draft = true;
            }
            insert(parameters)
        } else {
            enqueueSnackbar(t("Limite de 150 assets foi excedido.", {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
            }))
        }
    }, [state, markedAssets, insert, enqueueSnackbar, t])

    const handleAddDataTags = useCallback((data) => {
        setState(prev => ({ ...prev, dataTags: data }))
    }, [])

    const getParamsByContextData = useCallback(() => {
        let data = {}

        Object.entries(paramsDataToCreateItems).forEach(([key, value]) => {
            if (typeof value === 'object' && !_.isEmpty(value)) {
                data[key] = value
            }
            if (typeof value === 'string' && value.length > 0) {
                data[key] = value
            }
        })

        if (filtersOptions.length > 0) data["filtersOptions"] = filtersOptions
        if (!_.isEmpty(dataLevels)) data["levels"] = dataLevels

        return data
    }, [filtersOptions, paramsDataToCreateItems, dataLevels])

    const getData = useCallback(async () => {
        const parameters = getParamsByContextData()

        if (_.isEmpty(parameters)) {
            setState(prev => ({ ...prev, loading: false, checkedData: true }))
        } else {
            try {
                const result = await axios.post(`${pathServer}/item/context`, parameters, requestHeaders)
                let newData = {}
                const category_id = _.get(result, 'data.category.id', _.get(result, 'data.category_id', ''))

                if (Boolean(category_id)) {
                    const categoryResult = await axios.get(`${pathServer}/attributesByCategory/${category_id}`, requestHeaders)
                    const dataAttributes = _.get(categoryResult, 'data.items', []).map((dataAttribute) => {
                        dataAttribute.mask_value = dataAttribute.mask_value ? dataAttribute.mask_value.trim() : '';
                        return dataAttribute
                    })


                    if (_.isEmpty(_.get(result, 'data.attributes', {}))) {
                        newData.dataFields = createForm(dataAttributes)
                        newData.attributes = dataAttributes
                    } else {
                        let attributes = []
                        Object.entries(_.get(result, 'data.attributes', {})).forEach(([key, item]) => {
                            attributes.push({ attribute_id: key, value: item.values, label: item.label })
                        })

                        newData.dataFields = createEditForm(attributes, dataAttributes)
                        newData.attributes = dataAttributes

                    }
                }

                if (!_.isEmpty(_.get(result, 'data.attributes', {})) && !Boolean(category_id)) {
                    delete result.data.attributes
                }

                if (_.get(result, 'data.category.canPublish', false) || _.get(result, 'data.segment.canPublish', false)) {
                    if (_.get(result, 'data.segment.canPublish', false)) {
                        newData.isCanPublish = { value: true, id: "segment" }
                    }
                    if (_.get(result, 'data.category.canPublish', false)) {
                        newData.isCanPublish = { value: true, id: "category" }
                    }
                }

                setState(prev => ({
                    ...prev,
                    loading: false,
                    defaultData: {
                        name: _.get(parameters, 'searchText', ''),
                        ..._.get(result, 'data', {}),
                        ...newData
                    },
                    checkedData: true
                }))
            } catch (err) {
                let message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
                setState(prev => ({ ...prev, loading: false, checkedData: true }),
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    }))
            }
        }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, getParamsByContextData])

    useEffect(() => {
        let isMounted = true;
        if (isMounted && !state.checkedData) getData()
        return () => { isMounted = false }
    }, [getData, state.checkedData])

    return (
        <>
            <Helmet>
                <title>DAM - {name}</title>
                <meta name="description" content={description} />
            </Helmet>
            {state.loading && <Loading showLoading />}
            <HeaderMaterialDesign title={t("Novo Item")} localHistory={history} />

            <Grid
                container
                style={{ width: "100%", padding: "20px 0 60px 0" }}
                direction={markedAssets.length === 0 ? "column" : null}
                alignItems={markedAssets.length === 0 ? "center" : null}
                justifyContent={markedAssets.length === 0 ? "center" : null}
            >
                {markedAssets.length > 0 ? (
                    <Grid item xs={12} sm={5} style={{ padding: "0 10px" }} >
                        <Paper elevation={2} className={classes.paper}>
                            <Alert
                                severity={markedAssets.length > LIMIT_ASSETS ? "error" : "info"}
                                style={{ width: "100%", textAlign: "center", margin: 0 }}
                            >
                                {markedAssets.length > LIMIT_ASSETS
                                    ? t("Limite de 150 assets foi excedido.")
                                    : t("common:Selecione e arraste os thumbnails para definir a ordem de apresentação no item. O primeiro thumbnail será a imagem de capa do item nas listas.")}
                            </Alert>
                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{ marginLeft: 10, color: "#666" }}
                            >
                                {t("common:Arquivos")}
                            </Typography>

                            <AssetsdndContainer handleAddDataTags={handleAddDataTags} />
                        </Paper>
                    </Grid>
                ) : null}

                <Grid item xs={12} sm={7} style={{ padding: "0 10px", width: `${markedAssets.length === 0 ? "60vw" : null}` }}>
                    {
                        state.checkedData && (
                            <FormCreateOrEditItem
                                goback={handleBack}
                                submit={formValidationAndGetParams}
                                defaultData={state.defaultData}
                            />
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default CreateItem