import React from "react";
import _ from "lodash"
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import {
  Typography,
  DialogContent,
  FormControlLabel,
  Switch,
  makeStyles
} from "@material-ui/core";

import Utils from "utils/Utils";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "20px 40px 40px 40px",
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    },
  },
}))

const CurrentPolicyBlockedDialog = ({
  acceptAnswer = false,
  handleChange,
  acceptAnswerisReject = false
}) => {
  const { currentPolicy, name } = useSelector(state => state.appReducer.data)
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <DialogContent className={classes.dialogContent}>
      {acceptAnswerisReject ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">
            {t(
              `login:Lamentamos que você não esteja de acordo com a Política de privacidade requerido por. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.`,
              { client: name }
            )}
          </Typography>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={acceptAnswer}
                  onChange={handleChange}
                  name="currentPolicy"
                />
              }
              style={{ marginRight: 0 }}
            />
            <div style={{ display: "flex" }}>
              <Typography variant="body2">
                {t("common:Declaro ter lido a")}
                <span
                  onClick={() => Utils.makeDownload(_.get(currentPolicy, "file", ""))}
                  style={{
                    margin: "0 3px",
                    cursor: "pointer",
                    color: "#1976d2",
                  }}
                >
                  {t("common:Política de privacidade")}
                </span>
                {t("common:requerido por atualizado em", {
                  client: name,
                })}
                {Utils.formatDate(_.get(currentPolicy, "reference", ""))}
                .
              </Typography>
            </div>
          </div>

          <Typography variant="body2" style={{ marginTop: 20, color: '#666' }}>
            <Trans i18nKey="common:nps-access-request">
              Com o seu consentimento, a Yapoli utiliza os dados pessoais, como nome <strong style={{ fontWeight: 500 }}>completo </strong> e <strong style={{ fontWeight: 500 }}>e-mail</strong> com a finalidade de criação de ID para a utilização da plataforma {{ name }}. Além disso, os dados mencionados podem ser alterados/excluidos a qualquer momento pelo usuário, basta solicitar através do e-mail dpo@yapoli.com.
            </Trans>
          </Typography>
        </>
      )}
    </DialogContent>
  );
};

export default CurrentPolicyBlockedDialog
