import React from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Paper, Typography, Container, Box } from '@material-ui/core';

import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"

import { makeStyles } from "@material-ui/core/styles";

import AppActions from "flux/actions/AppActions"

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: '0px 15px',
        boxShadow: 'none'
    },
    rootContainer: {
        paddingTop: 20,
        marginBottom: 70,
        '& h5': {      
          fontWeight: 500, 
          color: '#666'     
        },
        '& p': {
          color: '#666' 
        },    
        [theme.breakpoints.down("sm")]: {
          paddingBottom: 90
        },
      }
}))

const ListWithHeader = ({ status = '' }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    if (status === 401) {
        dispatch(AppActions.openDialog("expiredToken"))
    }

    return (
        <>
            <HeaderMaterialDesign
                title="Configurações"
                localHistory={history}
                pattern="settings"
            />
            <Container maxWidth="md" className={classes.rootContainer}>
                <Paper elevation={2} style={{ height: 800, width: "100%" }}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box className={classes.root}>
                            <Typography variant="h5" component="h3">
                                {t("common:Acesso negado")}
                            </Typography>
                            <Typography component="p">
                                {t("common:Contate o administrador ou solicite a permissão para acessar o conteúdo.")}
                            </Typography>
                        </Box>
                    </div>
                </Paper>
            </Container>
        </>
    )
}

export default ListWithHeader;