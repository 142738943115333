import React, { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios'
import _ from 'lodash'
import { useSnackbar } from "notistack"

import AlertDialogSimple from 'components/Alert/AlertDialogSimple'
import AlertContentPublication from 'components/Dialogs/AlertContentPublication'

import { Typography, CircularProgress, Box } from '@material-ui/core';

import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { mdiProgressDownload } from "@mdi/js"
import Icon from "@mdi/react"

import Utils from "utils/Utils"
import { Creators as DownloadCentralActions } from "flux/ducks/Download_central"

import { useStyles } from './styles'

const Row = ({ title, params, alternativenameId = '', onlyAsset = false }) => {
    const [state, setState] = useState({ loading: false, dialogConfirm: { open: false, text: '' }, openDialogAlert: false })
    const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
    const classes = useStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const getParameters = useCallback((parameters) => {
        return ({
            ...parameters,
            ...(Boolean(alternativenameId) && { alternativename_id: alternativenameId }),
        })
    }, [alternativenameId])

    const handleDownload = useCallback((parameters) => {
        setState(prev => ({ ...prev, loading: true }))
        const payload = getParameters(parameters)

        axios({
            method: 'POST',
            url: `${pathServer}/item/download`,
            data: payload,
            headers: { ...requestHeaders.headers },
        })
            .then((resp) => {
                const linkDownload = resp.data.url

                if (!params.synchronous) {
                    setState(prev => ({ ...prev, openDialogAlert: true, loading: false }))
                    dispatch(DownloadCentralActions.setReloadDownloadData())
                } else {
                    Utils.makeDownload(linkDownload, () => setState(prev => ({ ...prev, loading: false })))
                }
            })
            .catch((err) => {
                if (_.get(err, 'response.status', '') === 423) {
                    setState(prev => ({ ...prev, dialogConfirm: { open: true, text: _.get(err, 'response.headers["x-message"]', '') }, loading: false }))
                    return
                }
                const message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
                setState(prev => ({ ...prev, loading: false }), enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                }))

            })
    }, [enqueueSnackbar, params, pathServer, requestHeaders.headers, t, getParameters, dispatch])

    const closeDialogConfirm = () => {
        setState(prev => ({ ...prev, dialogConfirm: { open: false, text: '' } }))
    }

    const handleDialogConfirm = () => {
        setState(prev => ({ ...prev, dialogConfirm: { open: false, text: '' } }))
        handleDownload({ ...params, force: true })
    }

    const getTypeInfo = useCallback((type) => {
        switch (type) {
            case "path_low":
            case "path_clone":
                return 'JPG'

            default:
                return '*'
        }
    }, [])

    return (
        <>
            <button
                disabled={state.loading}
                className={classes.row}
                onClick={() => handleDownload(params)}
                data-testid="btn-download"
            >
                <div style={{ display: "flex" }}>
                    <div className={classes.boxInfo}>
                        <span>{getTypeInfo(params.type)}</span>
                    </div>

                    <div className={classes.info}>
                        <Typography
                            component="div"
                            variant="body2"
                            style={{ lineHeight: 1.2 }}
                        >
                            <Box fontWeight={500}>{title}</Box>
                        </Typography>
                    </div>
                </div>
                {state.loading
                    ? <CircularProgress size={20} data-testid="icon-loading" />
                    : <GetAppRoundedIcon data-testid="icon-get_app" />}
            </button>

            <AlertDialogSimple
                open={state.openDialogAlert}
                icon={<Icon path={mdiProgressDownload} size={2} id="main" />}
                handleClose={() => setState(prev => ({ ...prev, openDialogAlert: false }))}
                title={t(`common:Download em andamento`)}
                description={t(`common:Acompanhe na Central de downloads a sua solicitação. Uma notificação será enviada quando os Itens estiverem prontos.`)}
            />

            <AlertContentPublication
                open={state.dialogConfirm.open}
                text={state.dialogConfirm.text}
                handleDialogConfirm={handleDialogConfirm}
                closeDialogConfirm={closeDialogConfirm}
            />
        </>
    )
}

export default Row
