export const styles = (theme) => ({
    paperForm: {       
        padding: 20,
        marginBottom: 50,
        '& .full-width': {
            width: '100%'
        },
        '& .form-field': {
            width: '100%',
            position: 'relative',
            color: '#666',
            '& label': {
                display: 'block',
                fontSize: '.94rem',
                marginBottom: 5,
                fontWeight: 500,
            },
            '& label.section': {
                fontSize: '1.125rem',
            },
            '& p.subtitle': {
                fontSize: '0.875rem',
                marginTop: 5,
                marginBottom: 10,
                lineHeight: '1.2rem',
            },
            '& .info': {
                opacity: 0.7,
                display: 'block',
                fontSize: 11,
                margin: '5px 0',
                fontWeight: 400,
            },
            '& input, textarea': {
                width: '100%',
                borderRadius: 4,
                textAlign: 'left',
                fontSize: 15,
                border: '1px solid hsl(0,0%,80%)',
                padding: '10px 12px',
                '&.has-error ': {
                    border: '1px solid #f44336',
                },
                '&::placeholder': {
                    fontSize: '0.875rem',
                },
            },
            '& div.error ': {
                color: '#f44336',
                margin: 0,
                fontSize: '0.75rem',
                marginTop: 3,
                textAlign: 'left',
                lineHeight: 1.66,
                letterSpacing: '0.03333em'
            },
            'select': {
                width: '100%',
                borderRadius: 4,
                textAlign: 'left',
                fontSize: 15,
                color: '#666',               
                padding: '10px 8px',
            },
            '& .mask-email': {
                display: 'none',
            },
            '& .refer': {
                '& .ct-input.email': {
                    display: 'flex',
                    alignItems: 'center',
                    '& input': {
                        width: '50%',
                    },
                    '& .mask-email': {
                        paddingLeft: 10,
                        width: '50%',
                        display: 'block',
                    }
                }
            }
        },       
    },
    rootSelect: {
        width: '100%',
        '& .select-custom__control--is-focused': {            
          borderColor: `${theme.palette.primary.main} !important`,
          boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
      },
    }
})