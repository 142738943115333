export const settings = {
  area: 9,
  route: "advanced-settings",
  entity: "sso",
  title: {
    singular: "Single Sign-On",
  },
  page: 0,
  initial_page: 0,
  isNotPageOne: true
}