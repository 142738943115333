import React, { useEffect, useState, useCallback } from "react"
import axios from "axios"
import _ from 'lodash'
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import {
  Grid,
  Container,
  CircularProgress,
  Button,
  Tooltip,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core"

import Utils from "utils/Utils"
import { slugify } from "utils/Helpers"
import { useStyles } from "./styles"

import CustomNoRows from "./CustomNoRows"
import HeaderTable from "./HeaderTable"
import DateFilter from './DateFilter'
import StatusFilter from './StatusFilter'
import { useDataRequestTools } from "./context"
import HeaderPage from '../HeaderPage'

const ToolsList = ({ description, match, workflow, ...props }) => {
  const [dataItems, setDataItems] = useState({
    rows: [], page: 0, pageTotal: 0, pageLimit: 10, pageList: [], loading: true,
    toolThumbnail: '', title: '', searchText: ''
  })
  const [searchText, setSearchText] = useState("")
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const slug = match.params.slug

  const { pathServer, requestHeaders, userData: { region } } = useSelector(
    (state) => state.appReducer
  )
  const { status, date, setMessage } = useDataRequestTools()

  const HEADER_COLUMNS = [
    ...(workflow ? [{ align: "left", minWidth: 100, label: t("common:Tarefa") }] : []),
    { align: "left", minWidth: 100, label: t("common:Solicitante") },
    { align: "left", minWidth: 100, label: t("common:Executor (es)") },
    ...(workflow ? [{ align: "left", minWidth: 100, label: t("common:Aprovador") }] : []),
    { align: "left", minWidth: 100, label: t("common:Data de abertura"), has_comp: true, comp: () => <DateFilter /> },
    { align: "left", minWidth: 100, label: t("common:Status"), has_comp: true, comp: () => <StatusFilter type={workflow ? "workflow" : "request"} /> },
    { align: "left", minWidth: 100, label: t("common:Detalhes") }
  ]

  const getData = useCallback((page_ = 0, pageList_ = []) => {
    const payload = {
      limit: dataItems.pageLimit,
      page: page_,
    }

    if (Boolean(status)) {
      payload["marking"] = status
    }

    if (Boolean(searchText)) {
      payload["search"] = searchText
    }

    if (page_ !== 0) {
      const getLast = pageList_.find((i) => i.page === page_ - 1)
      if (Boolean(getLast)) {
        payload["last"] = getLast.last
      }
    }

    if (Boolean(date.startDate)) {
      payload['date_start'] = Utils.formatDate(date.startDate, "YYYY-MM-DD")
    }

    if (Boolean(date.endDate)) {
      payload['date_end'] = Utils.formatDate(date.endDate, "YYYY-MM-DD")
    }

    axios
      .post(`${pathServer}/tool/${slugify(slug)}/records`, payload, requestHeaders)
      .then(
        (result) => {
          const DATA = {
            rows: _.get(result, 'data.items', []),
            page: _.get(result, 'data.page', 0),
            pageTotal: _.get(result, 'data.total', 0),
            loading: false,
            toolThumbnail: _.get(result, 'data.thumbnail', ''),
            title: _.get(result, 'data.tool_title', ''),
          }

          if (result.data.last) {
            DATA.pageList = {
              page: result.data.page,
              last: result.data.last,
              index: result.data.last.pk,
            }
          }

          setDataItems(prev => ({ ...prev, ...DATA, pageList: [...prev.pageList, DATA.pageList] }))
        })
      .catch((err) => {
        let message = "Nenhum dado encontrado."

        if (err.response.status === 404) {
          if (
            payload.hasOwnProperty('searchText') ||
            payload.hasOwnProperty('marking') ||
            payload.hasOwnProperty('date_start') ||
            payload.hasOwnProperty('date_end')
          ) {
            message = 'Não encontramos o que você procura. Que tal uma nova busca?'
          }
        }
        setDataItems(prev => ({ ...prev, loading: false, rows: [] }))
        setMessage(message)
      })
  }, [pathServer, requestHeaders, slug, dataItems.pageLimit, searchText, status, date, setMessage])

  useEffect(() => {
    let isMounted = true
    if (isMounted) getData()
    return () => {
      isMounted = false
    }
  }, [getData])

  const handleChangeRowsPerPage = useCallback((evt) => {
    if (evt.target.value !== dataItems.pageLimit) {
      setDataItems(prev => ({ ...prev, pageLimit: evt.target.value }))
    } else {
      return
    }
  }, [dataItems.pageLimit])

  const handleChangePage = useCallback((evt, pageNew) => {
    evt.preventDefault()
    if (pageNew !== dataItems.page) {
      setDataItems(prev => ({ ...prev, loading: true, page: pageNew }))
      getData(pageNew, dataItems.pageList)
    } else {
      return
    }
  }, [dataItems.page, dataItems.pageList, getData])

  const handleRunSearch = useCallback((text) => {
    setSearchText(text)
  }, [])

  const setColorTextStatus = useCallback((text) => {
    switch (text) {
      case t('common:Em execução'):
      case t('common:executar'):
        return "#2196F3"
      case t('common:Finalizada'):
      case t('common:finalizar'):
        return "#4CAF50"
      default:
        return "#FF9800"
    }
  }, [t])

  return (
    <Grid container>
      <HeaderPage description={description} match={match} workflow={workflow} title={_.get(dataItems, 'title', '')} />

      <Container className={classes.rootContainer}>
        <Paper elevation={2} className={classes.rootPaper}>
          <HeaderTable
            handleSubmitSearch={handleRunSearch}
            hasSearchText={searchText}
            toolThumbnail={_.get(dataItems, 'toolThumbnail', '')}
            title={{
              name: _.get(dataItems, 'title', '')
            }}
            data={{ region, slug: slugify(slug), workflow }}
          />
          {!_.get(dataItems, 'loading', true) && _.get(dataItems, 'rows', []).length > 0 && (
            <>
              <TableContainer className={classes.tableContainer}>
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      {HEADER_COLUMNS.map((column, key) => (
                        <TableCell
                          key={`${column.label}-${key}`}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <div style={{ display: 'flex' }}>
                            {column.label}
                            {
                              column.has_comp &&
                              column.comp()
                            }
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: "100%" }}>
                    {_.get(dataItems, 'rows', []).map((row) => (
                      <TableRow key={row.id}>
                        {
                          workflow && (
                            <TableCell component="th" scope="row">
                              <Tooltip
                                title={_.get(row, 'name', '')}
                                placement="bottom"
                              >
                                <div style={{ maxWidth: 150, overflow: "hidden" }}>
                                  <Typography variant="body2" noWrap>
                                    {_.get(row, 'name', '')}
                                  </Typography>
                                </div>
                              </Tooltip>
                            </TableCell>
                          )
                        }
                        <TableCell component="th" scope="row">
                          <Tooltip
                            title={`${_.get(row, 'created_by.name', '')} (${_.get(row, 'created_by.email', '')})`}
                            placement="bottom"
                          >
                            <div style={{ maxWidth: 150, overflow: "hidden" }}>
                              <Typography variant="body2" noWrap>
                                {`${_.get(row, 'created_by.name', '')} (${_.get(row, 'created_by.email', '')})`}
                              </Typography>
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Tooltip
                            title={
                              _.get(row, 'recipients', []).map(
                                (recipient) =>
                                  `${recipient.name} (${recipient.email})`
                              )
                                .toString()}
                            placement="bottom"
                          >
                            <div>
                              <div
                                style={{
                                  maxHeight: 16,
                                  overflow: "hidden",
                                  maxWidth: 150,
                                }}
                              >
                                {_.get(row, 'recipients', []).map((recipient) => {
                                  return (
                                    <Typography
                                      variant="body2"
                                      noWrap
                                      key={recipient.id}
                                    >
                                      {`${recipient.name} (${recipient.email})`}
                                    </Typography>
                                  )
                                })}
                              </div>
                              {_.get(row, 'recipients', []).length > 3 ? "..." : ""}
                            </div>
                          </Tooltip>
                        </TableCell>

                        {
                          workflow && (
                            <TableCell component="th" scope="row">
                              <Tooltip
                                title={
                                  _.get(row, 'approvers', []).map(
                                    (recipient) =>
                                      `${recipient.name} (${recipient.email})`
                                  )
                                    .toString()}
                                placement="bottom"
                              >
                                <div>
                                  <div
                                    style={{
                                      maxHeight: 16,
                                      overflow: "hidden",
                                      maxWidth: 150,
                                    }}
                                  >
                                    {_.get(row, 'approvers', []).map((recipient) => {
                                      return (
                                        <Typography
                                          variant="body2"
                                          noWrap
                                          key={recipient.id}
                                        >
                                          {`${recipient.name} (${recipient.email})`}
                                        </Typography>
                                      )
                                    })}
                                  </div>
                                  {_.get(row, 'approvers', []).length > 3 ? "..." : ""}
                                </div>
                              </Tooltip>
                            </TableCell>
                          )
                        }

                        <TableCell>
                          {Utils.formatDate(_.get(row, 'created_at', ''))}
                        </TableCell>

                        {row.marking && (
                          <TableCell
                            style={{ color: setColorTextStatus(row.marking) }}
                          >
                            {row.marking}
                          </TableCell>
                        )}

                        <TableCell>
                          <Button
                            onClick={() =>
                              history.push(`/${region}/${workflow ? "workflow" : "request"}/${slug}/${_.get(row, 'id', '')}`)
                            }
                            variant="contained"
                            color="primary"
                            style={{ height: 30 }}
                          >
                            {t("common:Detalhes")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={dataItems.pageTotal}
                rowsPerPage={dataItems.pageLimit}
                page={dataItems.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={`${t("common:Registros por página")}:`}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t("common:de")} ${count}`
                }
              />
            </>
          )}

          {!_.get(dataItems, 'loading', true) && _.get(dataItems, 'rows', []).length === 0 && <CustomNoRows />}

          {_.get(dataItems, 'loading', true) && _.get(dataItems, 'rows', []).length === 0 && (
            <div className={classes.rootLoading}>
              <CircularProgress />
            </div>
          )}
        </Paper>
      </Container>
    </Grid>
  )
}

export default ToolsList
