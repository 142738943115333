import _ from 'lodash'

export const Types = {
  SET_DOWNLOAD_DATA: "downloadCentral/SET_DOWNLOAD_DATA",
  UPDATE_ITEMS_DATA: "downloadCentral/UPDATE_ITEMS_DATA",
  SET_RELOAD_DOWNLOAD_DATA: "downloadCentral/SET_RELOAD_DOWNLOAD_DATA",
  SET_MORE_ITEMS: "downloadCentral/SET_MORE_ITEMS",
  CLEAR_ITEMS_DATA_ERRORS: "downloadCentral/CLEAR_ITEMS_DATA_ERRORS",
}

const INITIAL_STATE = {
  reloadDownloadData: false,
  items: [],
  hasData: false,
  items_total: 0,
  highlightItem: null,
  pageLast: '',
  checkedData: false,
  loading: true
}

export default function downloadCentralReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_DOWNLOAD_DATA:
      return {
        ...state, ...action.payload
      }
    case Types.UPDATE_ITEMS_DATA:
      return {
        ...state,
        items: state.items.map(i => {
          if (i.id === _.get(action, 'payload.id')) {
            i.region_name = _.get(action, 'payload.region', '')
            i.state = _.get(action, 'payload.state', i.state)
          }
          return i
        })
      }
    case Types.SET_RELOAD_DOWNLOAD_DATA:
      return {
        ...state, reloadDownloadData: !state.reloadDownloadData
      }
    case Types.SET_MORE_ITEMS:
      return {
        ...state, items: [...state.items, ...action.payload]
      }
    case Types.CLEAR_ITEMS_DATA_ERRORS:
      return {
        ...state,
        items: state.items.map(i => {
          if (i.error) {
            delete i.error
          }
          return i
        })
      }

    default:
      return state
  }
}

export const Creators = {
  setDownloadData: (payload) => ({
    type: Types.SET_DOWNLOAD_DATA,
    payload
  }),
  updateItemsData: (payload) => ({
    type: Types.UPDATE_ITEMS_DATA,
    payload
  }),
  setReloadDownloadData: () => ({
    type: Types.SET_RELOAD_DOWNLOAD_DATA
  }),
  setMoreItems: (payload) => ({
    type: Types.SET_MORE_ITEMS,
    payload
  }),
  clearItemsDataErrors: () => ({
    type: Types.CLEAR_ITEMS_DATA_ERRORS,
  })
}