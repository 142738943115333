

import React, { useCallback, useState } from 'react';
import { useSelector } from "react-redux"
import axios from "axios"
import { useTranslation, Trans } from "react-i18next"
import { Typography, Tooltip, Avatar, useMediaQuery } from "@material-ui/core"
import { slugify } from 'utils/Helpers'
import { useHistory } from 'react-router';
import { useSnackbar } from "notistack"
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import noImage from "imagens/ui/wallpaper_white_24dp.svg"
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import Utils from 'utils/Utils'
import _ from 'lodash'
import clipboardCopy from 'utils/clipboardCopy'

import { useStyles } from './styles'
import {simpleGet} from "utils/Request"
import Settings from "utils/Settings";

export const ContentRow = ({ data, changeRegion, type = '' }) => {
  const [dataSharedLink, setDataSharedLink] = useState([])
  const { t } = useTranslation()
  const history = useHistory()
  const { requestHeaders, pathClient, userData: { region, user_token, upload_config } } = useSelector(
    (state) => state.appReducer
  )
  const classes = useStyles()
  const mobile = useMediaQuery('(max-width:600px)');
  const { enqueueSnackbar } = useSnackbar()

  const handleError = useCallback(err => {
    const message = err.response
      ? err.response.data.message || err.response.headers["x-message"]
      : t("Erro inesperado. Contate o suporte");
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  }, [enqueueSnackbar, t])

  const getNameItem = useCallback((name) => {
    const LENGTH = 110
    const length_name = name.length

    if (length_name < LENGTH) {
      return name
    } else {
      return `${name.slice(0, LENGTH)}...`
    }
  }, [])

  const textStrong = useCallback((action, NAME_USER, NAME_ITEM) => {
    return (
      <Trans i18nKey="common:descriptionNotifications-1">
        {{ NAME_USER }} <strong style={{ fontWeight: 500 }}>{{ action }}</strong> o item {{ NAME_ITEM }}
      </Trans>
    )
  }, [])

  const getDescription = useCallback((event) => {
    const NAME_USER = _.get(data, 'modifier_detail.name', '')
    const NAME_ITEM = getNameItem(_.get(data, 'item_detail.taxonomy.name', _.get(data, 'download_detail.name', '')))

    switch (event) {
      case 'import-item':
        return (
          <Typography variant="body2">
            {t("DAM:A importação via CSV terminou. Clique para copiar link para baixar arquivo com os detalhes.")}
          </Typography>
        )
      case 'item-created':
        return textStrong(t(`common: criou `), NAME_USER, NAME_ITEM)
      case 'item-published':
        return textStrong(t(`common: publicou `), NAME_USER, NAME_ITEM)
      case 'item-unpublished':
        return textStrong(t(`common: despublicou `), NAME_USER, NAME_ITEM)
      case 'item-version-added':
        const action = t(`common: adicionou uma nova versão `)
        return (
          <Trans i18nKey="common:descriptionNotifications-1">
            {{ NAME_USER }} <strong style={{ fontWeight: 500 }}>{{ action }}</strong> no item {{ NAME_ITEM }}
          </Trans>
        )
      case 'item-near-validity':
        const text = t(`common:data de vigência`)
        const date = Utils.formatDate(_.get(data, 'item_detail.validity_date', ''))
        return (
          <>
            <Trans i18nKey="common:descriptionNotifications-2">
              A <strong style={{ fontWeight: 500 }}>{{ text }}</strong> do item {{ NAME_ITEM }} {`está próxima a expirar.   `}
            </Trans>
            <span className={classes.headerDate}>
              <CalendarTodayRoundedIcon />
              <Typography
                variant="body2"
                color="textPrimary"
                style={{ fontSize: ".7rem", display: 'inline' }}
              >
                {date}
              </Typography>
            </span>
          </>
        )
      case "share-created":
        return (
          <span>
            <Typography variant="body2">
              {t(`DAM:Clique aqui para copiar o link de compartilhamento`)}
            </Typography>
            <Typography variant="caption">
              {
                _.get(data, 'additional_data.items_quantity', 0) === 1
                  ? `${_.get(data, 'additional_data.items_quantity', 1)} ${t('common:item')} `
                  : `${_.get(data, 'additional_data.items_quantity', 0)} ${t('common:itens')}`
              }
            </Typography>
          </span>
        )
      case 'tool-record-comment-created':
        const taks_name = _.get(data, 'record_detail.name', '')
        return (
          <>
            <Trans i18nKey="common:commentTaksNotification">
              {{ NAME_USER }} <strong style={{ fontWeight: 500 }}>comentou</strong> na tarefa {{ taks_name }}.
            </Trans>
          </>
        )
      default:
        return ''
    }
  }, [classes.headerDate, data, getNameItem, t, textStrong])

  const getLinkToItem = useCallback(async () => {
    const region_ = slugify(_.get(data, 'item_detail.region.name', ''))
    axios.get(`${pathClient}/${region_}/item/${data.item_id}`, requestHeaders)
      .then(result => {
        try {
          const regionResult = _.get(result.data, 'item.region.slug', '')
          const division = _.get(result.data, 'item.division.slug', '')
          const segment = _.get(result.data, 'item.segment.slug', '')
          const category = _.get(result.data, 'item.category.slug', '')
          const taxonomy = _.get(result.data, 'item.taxonomy.slug', '')
          const linkUrl = `/dam/${regionResult}/${division}/${segment}/${category}/${taxonomy}`
          if (regionResult && division && segment && category && taxonomy) {
            changeRegion(regionResult, { requestHeaders, pathClient }, () => history.push(linkUrl))
          } else {
            history.push('/item-access-denied')
          }
        } catch (err) {
          handleError(err)
        }
      }).catch(err => handleError(err))
  }, [pathClient, requestHeaders, history, handleError, data, changeRegion])

  const removeFromShared = useCallback(
    id => setTimeout(() => setDataSharedLink(dataSharedLink.filter(d => d.id !== id)), 2000)
  , [setDataSharedLink, dataSharedLink])

  const addToShared = useCallback(
    obj => setDataSharedLink([...dataSharedLink, obj])
  , [setDataSharedLink, dataSharedLink])

  const copyToClipboard = useCallback((link, id) => {
    try {
      clipboardCopy(link)
      addToShared({id, copy: true})
      removeFromShared(id)
    }
    catch (e) {
      addToShared({id, error: true })
      removeFromShared(id)
    }
  }, [addToShared, removeFromShared])

  const errorClipboard = useCallback((id) => {
    addToShared({ id, error: true })
    removeFromShared(id)
  }, [addToShared, removeFromShared])

  const copyLinkToClipboard = useCallback((id) => {
    try {
      const link = _.get(data, 'additional_data.link', '')
      const event_type = _.get(data, 'event', '')
      const must_sign = _.get(data, 'additional_data.must_sign', false)
      if(!must_sign) {
        copyToClipboard(link, id)
        return
      }
      const params = {
        file: link,
        bucket: _.get(upload_config, 'data_bucket', Settings.BUCKET_NAME_DATA),
        event_type: event_type
      }
      simpleGet(`${Settings.URL_API}/api/url`, params, {user_token})
        .then(url => copyToClipboard(url, id))
        .catch(err => errorClipboard(id))
    }
    catch (e) {
      errorClipboard(id)
    }
  }, [data, copyToClipboard, errorClipboard, user_token, upload_config])

  const getLinkToTask = useCallback(() => {
    const region_ = slugify(_.get(data, 'record_detail.region.name', ''))
    const tool_slug = _.get(data, 'record_detail.tool_slug', '')
    const record_id = _.get(data, 'record_id', '')
    if (region !== region_) {
      changeRegion(region_, { requestHeaders, pathClient }, () => history.push(`/${region_}/workflow/${tool_slug}/${record_id}`))
    } else {
      history.push(`/${region_}/workflow/${tool_slug}/${record_id}`)
    }
  }, [data, changeRegion, history, requestHeaders, pathClient, region])

  const isEventToClipboard = event =>
    ["share-created", "import-item"].includes(event)

  const handleClick = useCallback((id) => {
    const event = _.get(data, 'event', null)
    if (_.get(data, 'expired', false) && type === 'download') {
      return
    }
    if (isEventToClipboard(event)) {
      copyLinkToClipboard(id)
      return
    }
    if (event === 'tool-record-comment-created') {
      getLinkToTask()
      return
    }
    getLinkToItem()
  }, [data, copyLinkToClipboard, getLinkToItem, getLinkToTask, type])

  const getIconSharedLink = useCallback((id) => {
    let icon = <Tooltip title={t('common:Copiar link')}><FileCopyRoundedIcon fontSize='small' /></Tooltip>
    const foundShared = dataSharedLink.find(o => o.id === id)

    if(!foundShared) {
      return icon;
    }

    if (foundShared.copy) {
      icon = <DoneRoundedIcon fontSize='small' />
    }
    if (foundShared.error) {
      icon = <ErrorRoundedIcon fontSize='small' />
    }
    return icon
  }, [dataSharedLink, t])

  const getRegionDetail = useCallback(() => {
    const region_name = _.get(data, `download_detail.region.name`, _.get(data, `item_detail.region.name`, _.get(data, `record_detail.region.name`, _.get(data, `share_detail.region.name`, ''))))
    const region_thumbnail = _.get(data, `download_detail.region.thumbnail`, _.get(data, `item_detail.region.thumbnail`, _.get(data, `record_detail.region.thumbnail`, _.get(data, `share_detail.region.thumbnail`, noImage))))
    return { name: region_name, thumbnail: region_thumbnail }
  }, [data])

  const regionDetail = getRegionDetail()
  const event = _.get(data, 'event', null)
  const id = _.get(data, 'id', null)

  return (
    <li
      className={classes.root}
      id={_.get(data, 'read', false) ? 'read' : ''}
      onClick={() => handleClick(id)}
    >
      <div className={classes.info}>
        {
          type === 'download' && event !== "share-created" &&
          <div className={classes.info_download}>
            {
              _.get(data, 'expired', false)
                ? <GetAppRoundedIcon style={{ opacity: .6 }} />
                : <GetAppRoundedIcon />
            }
          </div>
        }

        {
          isEventToClipboard(event) && (
            <div className='read' style={{ padding: 0, marginLeft: 20 }}>{getIconSharedLink(id)}</div>
          )
        }

        <div style={{ width: '100%' }}>
          <div className='content'>
            <Typography component='span' variant={mobile ? 'body2' : 'body1'}>
              {getDescription(_.get(data, 'event', ''))}
            </Typography>
          </div>

          <div className='footer'>
            <Tooltip title={regionDetail.name} placement="top">
              <Avatar
                alt={regionDetail.name}
                style={{ width: 20, height: 20, marginRight: 5 }}
                src={regionDetail.thumbnail}
              />
            </Tooltip>
            <Typography variant={mobile ? 'caption' : 'body2'}>
              {Utils.formatDateFull(_.get(data, 'created_at', ''))}
            </Typography>
          </div>
        </div>

        {
          !_.get(data, 'read', false) &&

          <div className='read' style={{ paddingRight: 30 }}>
            <Tooltip title={t('common:Não lida')} placement="top">
              <div id='not-read' />
            </Tooltip>
          </div>
        }
      </div>
    </li>
  )
}
