import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useStyles } from "./styles"

const DialogConfirm = ({
  t,
  title,
  listNames = false,
  disabled,
  description,
  children,
  icon = null,
  hideCallback,
}) => {
  const [open, setOpen] = useState(false)
  const [callbackState, setCallbackState] = useState(null)
 
  const classes = useStyles()

  const show = (callback) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    }

    setOpen(true)

    setCallbackState(() => () => callback(event))
  }

  const hide = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setOpen(false)
    setCallbackState(null)
    hideCallback(false)
  }

  const confirm = (event) => {
    callbackState()
    hide(event)
  }

  return (
    <React.Fragment>
      {children(show)}
      {open && (
        <Dialog
          open={open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            <div className="info">
              {icon !== null && icon}
              <div>{title}</div>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: "0" }}>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
            {listNames && <DialogContentText>{listNames}</DialogContentText>}
          </DialogContent>

          <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={hide}
              disabled={disabled}
              style={{marginRight: 20}}
            >
              {t(`common:Cancelar`)}
            </Button>
            <Button
              onClick={confirm}
              variant="contained"
              color="primary"
              disabled={disabled}
              data-testid='dialog-confirm-btn-confirm'
            >
              {t(`common:Confirmar`)}
            </Button>            
          </div>
        </Dialog>
      )}
    </React.Fragment>
  )
}

DialogConfirm.defaultProps = {
  t: () => {},
  title: "",
  listNames: false,
  disabled: false,
  description: "",
  icon: null,
  hideCallback: () => {},
}

export default DialogConfirm
