import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootGrid: {
    width: "100%", 
    padding: "20px 0",     
    justifyContent: 'center',    
  },
  paperForm: {   
    padding: 20,
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 50,
    '& .full-width': {
        width: '100%'
    },
    '& .form-field': {
        width: '100%',
        position: 'relative',
        color: '#666',
        '& label': {
            display: 'block',
            fontSize: '.94rem',
            marginBottom: 5,
            fontWeight: 500,
        },
        '& label.section': {
            fontSize: '1.125rem',
        },
        '& p.subtitle': {
            fontSize: '0.875rem',
            marginTop: 5,
            marginBottom: 10,
            lineHeight: '1.2rem',
        },
        '& .info': {
            opacity: 0.7,
            display: 'block',
            fontSize: 11,
            margin: '5px 0',
            fontWeight: 400,
        },

        '& input, textarea': {
            width: '100%',
            borderRadius: 4,
            textAlign: 'left',
            fontSize: 15,
            border: '1px solid #bababa',
            padding: '10px 12px',
            '&.has-error ': {
                borderBottom: '1px solid #a9021b',
            },
            '&::placeholder': {
                fontSize: '0.875rem',
            },
        },
        'select': {
            width: '100%',
            borderRadius: 4,
            textAlign: 'left',
            fontSize: 15,
            color: '#666',
            border: '1px solid #bababa',
            padding: '10px 8px',                
        }            
    }
  },
  rootForm: {
    padding: "0 10px",
    '& div.react-select__value-container': {
      padding: '13.5px 14px',
    },
    '& div.react-select__single-value': {
      padding: '2px 0'
    },
    "& form": {
      width: '100%',
      '& .labelDescription': {
        top: "12px",
        position: "relative",
        background: "#fff",
        display: 'inline-block',
        left: "11px",
        padding: "0 3px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.00938em',
        zIndex: 1
    },
    },

  },
  fieldDateRoot: {
    margin: '15px 0 24px 0',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column'
    },
    '& div.select': {
      width: '30%',
      height: 48,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
      },
    },
    '& .select-custom__value-container': {
      padding: '18.6px 14px'
    }
  },
  fieldDate: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '70%',
    color: '#666',
    '& :disabled':{
      color: 'currentColor'
    },
    '& input': {
      color: '#666',
      '&::-webkit-calendar-picker-indicator': {
        filter: 'invert(42%) sepia(0%) saturate(264%) hue-rotate(165deg) brightness(92%) contrast(89%)'
      }
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      '& span.text-range':{
        margin: '20px 0',
      },
    },
  },
  wrapperRichText:{
    minHeight: "200px",
    border: "1px solid #bababa",
    borderRadius: "4px",
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  appBar: {
    position: "relative",  
    padding: "18px 20px",
    '& .title': {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  },
}));
