import React from 'react'
import { useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import { useStyles } from "../styles"
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import {
    Toolbar,
    Typography,
    Tooltip,
    Button,
    IconButton,
    Badge,
    FormControlLabel,
    Checkbox,
    ListItemIcon
} from '@material-ui/core'
import FiltersBucket from 'pages/DAM/Bucket/Filters'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import NoteAddRoundedIcon from '@material-ui/icons/NoteAddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AppActions from "flux/actions/AppActions"
import { Creators as AssetActions } from "flux/ducks/Asset"
import { useDataItemFiles } from './context'
import ActionsBucketManagerAssets from './ActionsBucketManagerAssets'
const ToolsBar = ({ handleClose }) => {
    const {
        dataSelected,
        dataAssets,
        setDataAssets,
        setDataSelected,
        componentStatus,
        handleComponentStatus,
        totalAssets,
    } = useDataItemFiles()
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()

    const handleClearItemList = () => {
        setDataSelected([])
    }

    const handleStatusComponent = (status) => {
        handleComponentStatus(status)
    }

    const handleShowSelected = (evt) => {
        if (evt.target.checked) {
            handleStatusComponent('show_selected')
        } else {
            handleStatusComponent('initial')
        }
    }

    const handleRemoveAssets = () => {
        const dataFilter = dataAssets.filter(value => {
            const find = dataSelected.find(i => i.id === value.id)
            return !find
        })
        handleClearItemList()
        setDataAssets(dataFilter)
        enqueueSnackbar(t("DAM:Os ativos removidos permaneceram no local de origem, no item ou no bucket."),
            {
                variant: "info",
                autoHideDuration: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
            }
        )
    }

    const navigationCreateItem = () => {
        dispatch(AppActions.markListAssets(dataAssets))
        history.push('/item/new')
    }

    const handleRemoveAllAssets = () => {
        dispatch(AssetActions.setAllData([]))
        handleClose()
    }

    const total = componentStatus.current === 'initial' ? dataAssets.length : totalAssets
    const isBucket = componentStatus.current === 'search_bucket'

    return (
        <div className={classes.rootBar}>
            {isBucket ? <FiltersBucket simpleBucket toolbarManagerAssets={<ActionsBucketManagerAssets />} /> :
                <Toolbar>
                    <div className={classes.barFilter}>
                        <Typography variant='body2'>
                            {total > 1 ? t(`DAM:total_ativos`, { total: total }) : t(`DAM:total_ativo`, { total: total })}
                        </Typography>

                        {
                            (componentStatus.current === 'initial') &&
                            <>
                                <div id="line-divider" style={{ marginLeft: 14 }} />

                                <Tooltip title={t("DAM:Adicionar ativo")} placement="top">
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color={(componentStatus.current === 'search_bucket') ? "primary" : "inherit"}
                                            startIcon={
                                                <ListItemIcon style={{ minWidth: 10 }}>
                                                    <NoteAddRoundedIcon color={(componentStatus.current === 'search_bucket') ? "primary" : "inherit"} />
                                                </ListItemIcon>
                                            }
                                            onClick={() => {
                                                handleStatusComponent('search_bucket')
                                            }}
                                            disabled={dataAssets.length >= 150}
                                            style={{ border: 'none', padding: 10 }}
                                        >
                                            {t("DAM:Adicionar ativo")}
                                        </Button>
                                    </div>
                                </Tooltip>
                            </>
                        }
                    </div>

                    <div className={classes.barInputSearch} />

                    <div className={classes.barActionsItens}>
                        {
                            dataSelected.length > 0 && (
                                <>
                                    <Tooltip title={t("DAM:Mostrar selecionados")} placement="top">
                                        <FormControlLabel
                                            control={<Checkbox icon={
                                                <Badge badgeContent={dataSelected.length} color="primary">
                                                    <CheckCircleOutlineRoundedIcon />
                                                </Badge>
                                            } checkedIcon={
                                                <Badge badgeContent={dataSelected.length} color="primary">
                                                    <CheckCircleRoundedIcon color="primary" />
                                                </Badge>
                                            }
                                                name="show_selected"
                                                style={{ margin: 0 }}
                                                checked={componentStatus.current === 'show_selected'}
                                                onClick={evt => handleShowSelected(evt)}
                                            />}
                                        />
                                    </Tooltip>

                                    <Tooltip title={t("DAM:Remover ativo do item")} placement="top">
                                        <div>
                                            <IconButton onClick={() => handleRemoveAssets()}>
                                                <DeleteRoundedIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>

                                    <Tooltip title={t("DAM:Limpar lista")} placement="top">
                                        <Button
                                            onClick={handleClearItemList}
                                            style={{ height: 40, marginRight: 10 }}
                                        >
                                            {t("DAM:Limpar")}
                                        </Button>

                                    </Tooltip>
                                </>
                            )
                        }
                        <Button
                            onClick={handleRemoveAllAssets}
                            variant='outlined'
                            color='primary'
                            style={{ height: 40, marginRight: 10 }}
                        >
                            {t("DAM:Remover selecionados")}
                        </Button>
                        <Button
                            onClick={navigationCreateItem}
                            variant='contained'
                            color='primary'
                            style={{ height: 40 }}
                        >
                            {t("DAM:Criar item")}
                        </Button>
                    </div>
                </Toolbar>
            }
        </div>
    )
}


export default ToolsBar
