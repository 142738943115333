import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from "@material-ui/core"

const InfiniteScrollSimple = ({
    children,
    getData,
    loading,
    textsearch,
    totalCurrent,
    totalItens = 1,
}) => {
    const [pageNum, setPageNum] = useState({ page: 1 });
    const [lastElement, setLastElement] = useState(null);
    const { t } = useTranslation()
    const prevCount = usePrevious(textsearch);
    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];
                if (first.isIntersecting) {
                    setPageNum((no) => ({ page: no.page + 1 }));
                }
            })
    );

    useEffect(() => {
        let isMounted = true
        if (pageNum.page) {
            if (isMounted) getData(pageNum.page);
        }
        return () => { isMounted = false }
    }, [pageNum, getData]);

    useEffect(() => {
        let isMounted = true
        if (prevCount !== textsearch && prevCount !== undefined) {
            if (isMounted) setPageNum({ page: 1 })
        }
        return () => { isMounted = false }
    }, [textsearch, prevCount])

    useEffect(() => {
        let isMounted = true
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            if (isMounted) currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
            isMounted = false
        };
    }, [lastElement]);

    return (
        <div>
            {children}

            {
                totalCurrent < totalItens &&
                <div ref={setLastElement} style={{ width: '100%', height: 1 }} />
            }
           
            {
             pageNum > 1 &&
             totalCurrent === totalItens 
             && !loading 
             && totalItens > 0 &&             
             (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 20,
                    }}
                >
                    <Typography variant="subtitle1">
                        {t("common:Parece que você chegou ao fim")}
                    </Typography>
                </div>
            )}

        </div>
    );
};

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export default InfiniteScrollSimple