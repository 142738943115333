import React, { Fragment, useState, useCallback } from 'react';
import _ from 'lodash'
import Select, { components } from 'react-select';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { Typography, FormControl, Box, TextField, Grid } from "@material-ui/core";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'inherit',
    cursor: 'default',
    fontSize: 'inherit',
    padding: '8px 12px',
    "&:hover": {
      backgroundColor: '#DEEBFF',
    },
  },
  menuPaper: {
    color: '#666 !important',
    [theme.breakpoints.down("sm")]: {
      width: 'calc(100vw - 60px) !important',
    },
  },
  rootSelect: {
    width: '100%',
    '& .select-custom__control--is-focused': {
      borderColor: `${theme.palette.primary.main} !important`,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
    },
    '& .select-custom__control--is-disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
      backgroundColor: 'transparent !important'
    },
  },
  fieldDateRoot: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column'
    },
    '& .select-custom__value-container': {
      padding: '18.6px 14px'
    }
  },
  fieldDate: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '70%',
    color: '#666',
    marginLeft: 16,
    '& :disabled': {
      color: 'currentColor'
    },
    '& input': {
      color: '#666',
      '&::-webkit-calendar-picker-indicator': {
        filter: 'invert(42%) sepia(0%) saturate(264%) hue-rotate(165deg) brightness(92%) contrast(89%)'
      }
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      '& span.text-range': {
        margin: '20px 0',
      },
    },
  },
}))

const MyOption = props => {
  const { innerProps, innerRef } = props;
  const classes = useStyles();
  const { t } = useTranslation('common')

  return (
    <div ref={innerRef} {...innerProps} className={classes.root}>
      <Typography variant='body1' style={{ fontWeight: 500 }}>{props.data.label}</Typography>
      <Typography variant='body2'>{props.data.subtitle}</Typography>
      {
        props.data.id !== 'option-validity' &&
        <Typography variant='body2'>
          {t('common:Período')}: {t('common: indeterminado')}
        </Typography>
      }
    </div>
  );
};

const MyContainer = props => {
  const classes = useStyles();

  return (
    <Fragment>
      <components.Menu {...props} className={classes.menuPaper}>{props.children}</components.Menu>
    </Fragment>
  );
};

export const SelectPublished = ({
  handleChange,
  canPublish = true,
  valueDateDefault = {},
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const OPTIONS = [
    { id: 'option-published', label: t('common:Publicado'), subtitle: t('common:O item pode ser visualizado e utilizado pelos usuários que possuem permissão no perfil.'), value: true },
    { id: 'option-unpublished', label: t('common:Despublicado'), subtitle: t('common:O item ficará oculto dos usuários que não possuírem perfil para gerenciar o conteúdo.'), value: false },
    { id: 'option-validity', label: t('common:Com vigência'), subtitle: t('common:O item que possuir um conteúdo permitido para ser veiculado e compartilhado por um período específico.'), value: true }
  ]

  const getDefaultOption = useCallback(() => {
    if (!valueDateDefault) {
      return OPTIONS.find(opt => opt.id === 'option-published')
    }
    if (_.get(valueDateDefault, 'startDate', _.get(valueDateDefault, "validityDate", ""))) {
      return OPTIONS.find(opt => opt.id === 'option-validity')
    }
    if (_.get(valueDateDefault, "isPublished", false)) {
      return OPTIONS.find(opt => opt.id === 'option-published')
    } else {
      return OPTIONS.find(opt => opt.id === 'option-unpublished')
    }
  }, [OPTIONS, valueDateDefault])

  const [selected, setSelected] = useState(getDefaultOption())

  const container = () => ({
    width: '100%',
  });

  const selectStyles = {
    container: (styles) => ({ ...styles, ...container() }),
    menu: provided => ({ ...provided, zIndex: 3 })
  };

  const onChangeSelect = (option) => {
    let newState = {}
    if (option.id === 'option-validity') {
      newState.isPublished = option.value
      newState.hasDate = true
    } else {
      newState.isPublished = option.value
      newState.hasDate = false
      newState.startDate = ""
      newState.validityDate = ""
    }
    setSelected(option)
    handleChange(newState)
  }

  const onChangeDates = (field, value) => {
    handleChange({ [field]: value })
  }

  return (
    <Grid item xs={12} className={classes.fieldDateRoot}>
      <FormControl style={{ width: "100%" }}>
        <Select
          isDisabled={!canPublish}
          className="basic-single"
          classNamePrefix="select-custom"
          name="select"
          defaultValue={getDefaultOption()}
          value={selected}
          options={OPTIONS}
          styles={selectStyles}
          isSearchable={false}
          onChange={(value) => onChangeSelect(value)}
          components={{ Option: MyOption, Menu: MyContainer }}
        />
      </FormControl>
      {
        _.get(valueDateDefault, 'hasDate', false) &&
        <Box className={classes.fieldDate}>
          <TextField
            id="date-initial"
            type="date"
            label={t('common:Data inicial')}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 150 }}
            value={_.get(valueDateDefault, 'startDate', '')}
            onChange={(evt) => onChangeDates('startDate', _.get(evt, 'target.value', ''))}
          />
          {
            (_.get(valueDateDefault, 'startDate', '').length > 0) &&
            <Box
              style={{ marginLeft: 5, cursor: 'pointer' }}
              onClick={() => onChangeDates('startDate', "")}
            >
              <HighlightOffRoundedIcon />
            </Box>
          }
          <Typography variant="body" component="span" style={{ margin: '0 18px' }}>
            {t('common:até')}
          </Typography>
          <TextField
            id="date-end"
            type="date"
            label={t('common:Data final')}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 150 }}
            value={_.get(valueDateDefault, 'validityDate', '')}
            onChange={(evt) => onChangeDates('validityDate', _.get(evt, 'target.value', ''))}
            error={_.get(valueDateDefault, 'error', false)}
            helperText={_.get(valueDateDefault, 'error', '')}
          />
          {
            (_.get(valueDateDefault, 'validityDate', '').length > 0) &&
            <Box
              style={{ marginLeft: 5, cursor: 'pointer' }}
              onClick={() => onChangeDates('validityDate', "")}
            >
              <HighlightOffRoundedIcon />
            </Box>
          }
        </Box>
      }
    </Grid>
  );
}
