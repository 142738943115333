import { useCallback } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useSnackbar } from "notistack"
import { useTranslation } from 'react-i18next'
import Utils from 'utils/Utils'

export default function useUtils() {
  const { requestHeaders, pathServer, supportsWebp } = useSelector((state) => state.appReducer)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const postError = useCallback(err => {
    const message = Utils.ajaxErrorGetMessage(err, t)
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    })
  }, [enqueueSnackbar, t])

  const getPitContext = useCallback((callback) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = { ...requestHeaders, cancelToken: source.token }
    axios.get(`${pathServer}/item/pit`, options)
      .then(({ data }) => callback(data))
      .catch((err) => {
        postError(err)
        callback("checked")
      })
  }, [requestHeaders, pathServer, postError])

  const deletePitContext = useCallback((callback = null) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = { ...requestHeaders, cancelToken: source.token }
    const optionsSession = JSON.parse(sessionStorage.getItem("itemsFilters"))

    if (Boolean(optionsSession.pit)) {
      const setSession = () => {
        const newOptionsSession = { ...optionsSession }
        newOptionsSession.pit = ''
        sessionStorage.setItem("itemsFilters", JSON.stringify(newOptionsSession))
      }

      axios.delete(`${pathServer}/item/pit`, { data: { pit: _.get(optionsSession, "pit", ''), }, ...options })
        .then(() => {
          setSession()
          if (callback) callback()
        })
        .catch((err) => postError(err))
    }
  }, [requestHeaders, pathServer, postError])

  const getOnlyParams = useCallback(() => {
    const ONLY_PARAMS = [
      "id",
      "name",
      "created_at",
      "updated_at",
      "taxonomy.slug",
      "taxonomy.name",
      "assets.id",
      "segment.slug",
      "division.slug",
      "category.slug",
      "published",
      "validity_date",
      "start_date",
      (supportsWebp
        ? "assets.thumbnail_card_webp"
        : "assets.thumbnail_card_original"
      ),
    ]
    return ONLY_PARAMS
  }, [supportsWebp])

  return {
    getPitContext,
    deletePitContext,
    postError,
    getOnlyParams
  }
}
