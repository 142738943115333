import React, { useState } from "react";
import { Button, Typography, TextField, Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";

import { useStyles } from "./styles";


const DialogConfirm = ({
  open,
  t,
  handleClose,
  handleConfirm,
  hasAlterDate,
  isCommentPublish = false
}) => {
  const [comment, setComment] = useState('')
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          <div>{t("common:A edição feita terá os seguintes impactos")}:</div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: '0' }}>
        {
          hasAlterDate && (
            <Typography
              variant="body1"
              gutterBottom
            >
              {t('common:Publicação do item ou data de vigência alteradas')}
            </Typography>
          )
        }
        <Typography
          variant="body1"
          gutterBottom
        >
          {`${t('common:Taxonomia do item')}: ${t('common:caso o nome ou segmento tenham sido editados;')}`}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          {`${t('common:Versões anteriores do item')}: ${t('common:caso o nome, descrição e segmento tenham sido editados, serão replicados para as versões anteriores.')}`}
        </Typography>
        {
          isCommentPublish && (
            <div>
              <Divider style={{ margin: '20px 0' }} />

              <DialogTitle id="alert-dialog-title" className={classes.title}>
                <div className="info">
                  <div>{t("common:Publicação do item")}</div>
                </div>
              </DialogTitle>
              <Typography variant="body2" gutterBottom>
                {t("common:Adicione um comentário sobre a publicação deste item")}
              </Typography>
              <TextField
                placeholder={t("common:Comente aqui")}
                onChange={(evt) => setComment(evt.target.value)}
                multiline
                rows={7}
                style={{ width: '100%', marginTop: 5 }}
                variant='outlined'
                autoFocus
                value={comment}
              />
            </div>
          )
        }
        
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={evt => {
            setComment('')
            handleClose(evt)
          }}
        >
          {t(`common:Cancelar`)}
        </Button>
        <Button
          onClick={evt => handleConfirm(evt, comment)}
          variant="contained"
          color="primary"
          style={{ marginLeft: 20 }}
        >
          {t(`common:Sim`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation(["DAM"])(DialogConfirm);
