import React, { useState, useEffect } from "react"

import { useTranslation } from "react-i18next";

import {
    ListItemIcon,
    ListItem,
    ListItemText,
    Collapse,
    Typography,
    makeStyles,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { mdiCloudDownload } from "@mdi/js"
import Icon from "@mdi/react"

import DownloadStepsWrapper from 'components/DownloadStepsWrapper'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .title': {
            padding: '20px 20px 0 20px'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiGrid-container': {
            flexDirection: 'column',
            overflow: 'inherit',
            flexWrap: 'nowrap',
            width: '100%',
            margin: 0,
            '& .MuiGrid-root': {
                maxWidth: '100%'
            }
        }
    },
    btns_root: {
        overflow: 'auto',
        [theme.breakpoints.down("sm")]: {
            maxHeight: '100%'
        },
        '& div.MuiGrid-container': {
            maxHeight: '100%'
        }
    }
}))

export default function DownloadAsset({
    id_asset,
    id_item,
    version_item,
    hasHighLow,
    setDrawerOpen,
    isDrawerOpen,
    isDisabled,
    dates,
    category_id
}) {
    const [state, setState] = useState({ open: false })
    const { t } = useTranslation()
    const classes = useStyles()

    useEffect(() => {
        let isMounted = true
        if (!isDrawerOpen && isMounted) {
            setState(prev => ({ ...prev, open: false }))
        }
        return () => { isMounted = false }
    }, [isDrawerOpen])

    const handleOpen = () => {
        setState(prev => ({ ...prev, open: !prev.open }), setDrawerOpen())
    }

    return (
        <div>
            <ListItem button onClick={handleOpen} disabled={isDisabled}>
                <ListItemIcon>
                    <Icon path={mdiCloudDownload} size={1} color="#616161" />
                </ListItemIcon>
                <ListItemText primary={t('DAM:Baixar ativo')} />
                {state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {
                (isDrawerOpen && state.open) && (
                    <Collapse in={state.open} timeout="auto" unmountOnExit className={classes.root}>
                        <div style={{ whiteSpace: 'pre-line', marginBottom: 20, color: '#666', width: 339 }}>
                            <Typography variant='body1' className='title'>
                                {t('common:O ativo que não possuir suporte para as especificações será mantido na versão original.')}
                            </Typography>
                        </div>

                        <DownloadStepsWrapper
                            id_asset={id_asset}
                            id_item={id_item}
                            version_item={version_item}
                            hasHighLow={hasHighLow}
                            dates={dates}
                            category_id={category_id}
                        />
                    </Collapse>
                )
            }
        </div>
    );
}