import React, { useCallback, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    makeStyles,
    lighten,
    Checkbox,
    Typography
} from '@material-ui/core';

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import DetailsIcon from "imagens/preview-icon.svg";

import DialogPublish from "components/Alert/DialogPublish";
import DeleteValidationPhrase from 'components/Dialogs/DeleteValidationPhrase';
import Head from './Head'
import ImageLazyLoad from "components/ImageLazyLoad";
import AppActions from "flux/actions/AppActions";

import Utils from 'utils/Utils';

import { useDataItems } from '../../context';
import useItems from '../../hook/useItems'

const useStyles = makeStyles((theme) => ({
    table: {
        '& tr.table-body-row.Mui-selected': {
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        },
        '& tr.table-body-row.loading > .MuiTableCell-body': {
          color: lighten(theme.palette.primary.light, 0.65)
        },
        '& .table-body-row': {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
        }
    }
}))

export default function EnhancedTable({ onlyView = false }) {
    const { userData: { region }, markedItems } = useSelector((state) => state.appReducer)
    const { handleConfirmPublish, handleConfirmDelete } = useItems()
    const history = useHistory()
    const classes = useStyles();
    const { t } = useTranslation()
    const dispatch = useDispatch();

    const {
        getData,
        pageCurrent,
        totalItems,
        getParamsToItems,
        userData: { canSeePublicationFilter },
        removedDuplicates,
        dataItems
    } = useDataItems()

    const [loading, setLoading] = useState(-1)

    const runAction = (index, callback) => {
      setLoading(index)
      callback()
    }

    const endLoading = () => setLoading(-1)
    const linkUrl = useCallback((data) => {
        return `/dam/${region}/${_.get(data, "division.slug", "")}/${_.get(
            data,
            "segment.slug",
            ""
        )}/${_.get(data, "category.slug", "")}/${_.get(
            data,
            "taxonomy.slug",
            ""
        )}`
    }, [region])

    const handleChangeSelected = useCallback((evt, row) => {
        if (_.get(evt, 'target.checked', false)) {
            dispatch(AppActions.setmarkedItems(row))
        } else {
            dispatch(AppActions.removeMarkedItems(row))
        }
    }, [dispatch])

    const handleChangePage = useCallback((evt, newPage) => {
        evt.preventDefault()
        const paramsToItems = getParamsToItems()

        getData(paramsToItems, newPage + 1)
    }, [getData, getParamsToItems])

    const handleSelectAllClick = useCallback((event) => {
        const newDataAdd = removedDuplicates(dataItems, markedItems)
        const newDataRemove = removedDuplicates(markedItems, dataItems)

        if (_.get(event, 'target.checked', false)) {
            dispatch(AppActions.setmarkedItems([...markedItems, ...newDataAdd]))
        } else {
            if (newDataRemove.length === 0) {
                dispatch(AppActions.clearMarkedItems())
            } else {
                dispatch(AppActions.setmarkedItems(newDataRemove))
            }
        }
    }, [dataItems, dispatch, markedItems, removedDuplicates])

    const isSelected = useCallback((id) => markedItems.some(i => i.id === id), [markedItems])

    const maxCount = useMemo(() => removedDuplicates(dataItems, markedItems).length,
        [removedDuplicates, dataItems, markedItems])


    return (
        <Paper style={{ margin: '10px 20px' }}>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <Head
                        selectedData={markedItems.map(i => i.id)}
                        data={dataItems}
                        onSelectAllClick={handleSelectAllClick}
                        maxCount={maxCount}
                    />

                    <TableBody>
                        {dataItems.map((row, index) => {
                            const published = row.hasOwnProperty("published")
                                ? row.published
                                : true
                            const isItemSelected = isSelected(row.id)
                            const isLoading = loading === index
                            const tableRowClass = isLoading ? 'table-body-row loading' : 'table-body-row'

                            return (
                                <TableRow
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    className={tableRowClass}
                                    onClick={() => {
                                      if(!isLoading) {
                                        history.push(linkUrl(row))
                                      }
                                    }}
                                >
                                    <TableCell>
                                        <Checkbox
                                            disabled={isLoading}
                                            color='primary'
                                            checked={Boolean(isItemSelected)}
                                            onClick={(evt) => {
                                                evt.stopPropagation()
                                                return handleChangeSelected(evt, row)
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                                            <ImageLazyLoad
                                                images={{
                                                    imgJPG: _.get(row, 'assets[0].thumbnail_card_original', ''),
                                                    imgWEBP: _.get(row, 'assets[0].thumbnail_card_webp', '')
                                                }}
                                                styles={{ width: 38, height: 38 }}
                                            />
                                        </div>
                                    </TableCell>

                                    <TableCell style={{ maxWidth: 250 }} align="left">
                                        <Tooltip placement='bottom-start' title={_.get(row, 'taxonomy.name', '')}>
                                          <Typography noWrap>{_.get(row, 'taxonomy.name', '')}</Typography>
                                        </Tooltip>
                                    </TableCell>

                                    <TableCell align="left">{_.get(row, 'assets', []).length}</TableCell>
                                    <TableCell align="left">
                                        {(row.canPublish || canSeePublicationFilter) && !isLoading ?
                                            <DialogPublish
                                                t={t}
                                                title={t('common:Publicação do item')}
                                                description={published ? t('common:Você tem certeza que deseja despublicar o item?') : t('common:Você tem certeza que deseja publicar o item?')}
                                            >
                                                {(confirm) => (
                                                    <Tooltip placement='bottom-start' title={onlyView ? '' : published ? t('common:Despublicar') : t('common:Publicar')}>
                                                        <div>
                                                            <IconButton
                                                                disabled={onlyView}
                                                                className={classes.button}
                                                                aria-label="Publish"
                                                                onClick={confirm((evt, comment) =>
                                                                  runAction(index, () =>
                                                                    handleConfirmPublish(
                                                                      row.id,
                                                                      published ? "unpublish" : "publish",
                                                                      comment,
                                                                      () => endLoading()
                                                                    )
                                                                  ))
                                                                }
                                                            >
                                                                {(row.canPublish || canSeePublicationFilter) ? (published ? <VisibilityIcon /> : <VisibilityOffIcon />) : '-'}
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </DialogPublish> : ''
                                        }
                                    </TableCell>

                                    <TableCell align="left">
                                        {row.validity_date && row.start_date &&
                                            `${Utils.formatDate(row.start_date)} a ${Utils.formatDate(row.validity_date)}`
                                        }
                                        {
                                            row.validity_date && !row.start_date &&
                                            Utils.formatDate(row.validity_date)
                                        }
                                        {
                                            row.start_date && !row.validity_date &&
                                            Utils.formatDate(row.start_date)
                                        }
                                        {
                                            !row.start_date && !row.validity_date &&
                                            '-'
                                        }
                                    </TableCell>

                                    <TableCell align="left">
                                        <Tooltip title={t("common:Detalhes")}>
                                            <IconButton onClick={() => history.push(linkUrl(row))} disabled={isLoading}>
                                                <img src={DetailsIcon} alt="details" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>

                                    <TableCell align="left">
                                        <DeleteValidationPhrase
                                            entity={t("common:Item")}
                                            name={_.get(row, "taxonomy.name", "")}
                                        >
                                            {(confirm) => (
                                                <Tooltip placement='bottom-start' title={onlyView ? '' : t("DAM:Excluir item")}>
                                                    <div>
                                                        <IconButton
                                                            disabled={onlyView || isLoading}
                                                            className={classes.button}
                                                            aria-label="Delete"
                                                            onClick={confirm(() =>
                                                              runAction(index, () =>
                                                                handleConfirmDelete(row.id),
                                                                () => endLoading()
                                                              )
                                                            )}
                                                        >
                                                            <DeleteRoundedIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </DeleteValidationPhrase>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalItems.total}
                page={pageCurrent > 0 ? pageCurrent - 1 : 0}
                onChangePage={handleChangePage}
                rowsPerPage={totalItems.rowsPerPage}
                rowsPerPageOptions={[]}
                labelRowsPerPage={`${t("common:Registros por página")}:`}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t("common:de")} ${count}`
                }
            />
        </Paper >
    )
}
