import React, { useCallback } from 'react'
import { useTranslation } from "react-i18next"

import {
    Chip,
    Typography,
    Box,
    ListItemIcon,
    ListItemText,
    Button,
    useTheme
} from '@material-ui/core'

import People from '@material-ui/icons/People'
import { useDataRecognition } from "./context"
import { changeBackground } from './RootBoundingbox'
import { useStyles } from "./styles"

export default function PersonsInAssetSidebar({ handleClose, isModelPerson }) {
    const { state } = useDataRecognition()
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()

    const handleClickChip = useCallback((evt, box_id) => {
        changeBackground(evt, box_id, theme.palette.primary.main)
    }, [theme])

    return (
        <Box className={classes.rootBoxSidebar}>
            <Box className='header'>
                <ListItemIcon style={{ minWidth: 32, marginRight: 24 }}>
                    <People style={{ height: 32, width: 32 }} />
                </ListItemIcon>
                <ListItemText primary={isModelPerson ? t('DAM:Pessoas') : t('DAM:Objetos')} />
            </Box>
            <Box className='content'>
                <div className={classes.rootTags}>
                    {
                        state.bounding_boxes.length > 0 && state.bounding_boxes.map((i) => {
                            if (Boolean(i.label)) {
                                return (
                                    <Chip
                                        key={i.id}
                                        size="small"
                                        label={i.label.toUpperCase()}
                                        color='primary'
                                        onClick={evt => handleClickChip(evt, i.id)}
                                    />
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </div>
                <div style={{ padding: "16px 24px" }}>
                    {
                        state.bounding_boxes.length === 0 &&
                        <Typography variant="body1">
                            {t('common:Nenhum dado encontrado.')}
                        </Typography>
                    }
                </div>
                <div className='footer'>
                    <Button
                        onClick={handleClose}
                        color='primary'
                        variant='contained'
                    >
                        {t('DAM:Fechar')}
                    </Button>
                </div>
            </Box>
        </Box>

    )
}
