import React from "react"
import connect from "react-redux/es/connect/connect"
import axios from "axios"

import { responseError } from "../../utils/Ajax"
import { mapStateToPropsToDam, mapDispatchToPropsToDam } from "../../utils/Flux"
import { withTranslation } from "react-i18next"

import _ from 'lodash'

import { Wrapper } from './Template'

class Divisions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingShort: true,
      data: [],
      shortcuts: [],
      title: ''
    }
    this.responseError = responseError.bind(this)
    this._isMounted = false    
  }

  componentDidMount() {
    const { hasInvalidRegion, invalidateRegion } = this.props
    this._isMounted = true

    if (hasInvalidRegion) {
      invalidateRegion()
    } else {
      this.getData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    const headerPrev = prevProps.requestHeaders.headers
    const headerNext = this.props.requestHeaders.headers   
    
    if(headerPrev['X-Localization'] !== headerNext['X-Localization']){
      this.getData()
    }   
  }
  
  getData() {
    const SELF = this
    const { pathServer, triggerError, requestHeaders } = SELF.props
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    const options = { ...requestHeaders, cancelToken: source.token }
 
    axios
      .post(`${pathServer}/division/item`, {}, options)
      .then(
        (resp) => {
          const title = resp.data.items.length <= 1 ? 'Divisão' : 'Divisões'
          SELF._isMounted &&
            SELF.setState(
              {
                ...SELF.state,
                loading: false,
                data: resp.data.items,
                title
              },
              this.getShortcuts()
            )
        },
        (err) =>
          SELF._isMounted &&
          SELF.setState(
            {
              ...SELF.state,
              loading: false,
            },
            SELF.responseError(err)
          )
      )
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message)
        } else {
          triggerError(err)
        }
      })
  }

  getShortcuts() {
    const SELF = this
    const { pathServer, triggerError, requestHeaders } = SELF.props
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const options = { ...requestHeaders, cancelToken: source.token }

    axios
      .get(`${pathServer}/shortcuts`, options)
      .then(
        (resp) => {          
          SELF._isMounted &&
            SELF.setState({
              ...SELF.state,
              shortcuts: resp.data,
              loadingShort: false,
            })
        },
        (err) => SELF.responseError(err)
      )
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message)
        } else {
          triggerError(err)
        }
      })
  }

  render() {   
    const dataPropsCard = (item) => ({
      slug: _.get(item, 'slug', ''),      
    })      

    return (
      <Wrapper        
        pageDescription={_.get(this.props, 'data.description', '')}
        loading={this.state.loading}
        data={_.get(this.state, 'data', [])}
        region={_.get(this.props, 'region', '')}
        shortcuts
        loadingShort={this.state.loadingShort}
        dataShortcuts={_.get(this.state, 'shortcuts', [])}   
        dataPropsCard={dataPropsCard}    
        title={this.state.title} 
      />     
    )
  }
}

export default connect(
  mapStateToPropsToDam,
  mapDispatchToPropsToDam
)(withTranslation("common")(Divisions))
