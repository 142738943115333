import moment from 'moment'
import React from "react";
import _ from 'lodash'
import {
  mdiPlayBox,
  mdiAlphaABox,
  mdiPdfBox,
  mdiTextBox,
  mdiVectorRadius,
  mdiZipBox,
  mdiGoogleSpreadsheet,
  mdiFilePresentationBox,
  mdiImage,
  mdiDrawingBox,
  mdiFileCadBox,
  mdiFileAlert,
  mdiText
} from "@mdi/js";

import Skeleton from "@material-ui/lab/Skeleton"
import FileAlert from '../imagens/icons/file-alert.svg'
import i18n from "i18next"
import { MONTHS } from '../i18n'

class Utils { 
  static getStoredData(cname) {
    const data = localStorage.getItem(cname);

    if (data === "") {
      console.log("Nao encontrado " + cname);
    }

    return data;
  } 

  static isMobile() {
    let wW = window.innerWidth;
    if (wW > 989) {
      return false;
    } else {
      return true;
    }
  }

  static getTimestamp() {
    return "?=timestamp=" + Math.floor(Date.now() / 1000);
  }

  static gotoLogout() {
    document.location.href = document.location.protocol + '//' + document.location.host + '/login';
  }

  static gotoDam() {
    document.location.href = document.location.protocol + '//' + document.location.host + '/dam';
  }

  static cutText(_str, _number) {
    if(_str.length > _number) {
      return _str.substring(0, _number) + "...";
    }

    return _str;
  }

  static formattedDateDiff(dt1, dt2) {
    const d1 = moment(dt1);
    const d2 = moment(dt2);
    const diffDuration = moment.duration(d2.diff(d1));

    if(diffDuration.days() > 0) {
      return diffDuration.days() + (diffDuration.days() > 1 ? " dias" : " dia");

    } else if(diffDuration.hours() > 0) {
      return diffDuration.hours() + (diffDuration.hours() > 1 ? " horas" : " hora") + " e " + diffDuration.minutes() + (diffDuration.minutes() > 1 ? " minutos" : "minuto");

    } else if(diffDuration.minutes() > 0) {
      return diffDuration.minutes() + (diffDuration.minutes() > 1 ? " minutos" : "minuto");

    } else if(diffDuration.seconds() > 0) {
      return diffDuration.seconds() + (diffDuration.seconds() > 1 ? " segundos" : " segundo");

    }
  }

  static getChartVals(_obj, _key, _isDate, _format) {
    let arr = [];
    for (let index in _obj) {
      if (_obj.hasOwnProperty(index)) {
        let attr = _obj[index];
        if (_isDate) {
          arr.push(moment(attr[_key]).format(_format));
        } else {
          arr.push(attr[_key]);
        }
      }
    }
    return arr;
  }

  static hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
  }

  static convertFieldTypes(_type) {
    switch (_type) {
      case "String":
        return "text";
      case "Number":
        return "tel";
      case "E-mail":
        return "email";
      case "Password":
        return "password";
      default:
        break;
    }
  }

  static getChartValsInInnerKey(_obj, _key, _innerKey) {
    let arr = [];
    for (let index in _obj) {
      if (_obj.hasOwnProperty(index)) {
        arr.push(_obj[index][_key][_innerKey]);
      }
    }
    return arr;
  }

  static convertOptionList(_params) {
    let arr = _params;
    const listOptions = arr.map((string) =>
      <option id="" key={string}>{string}</option>
    );
    return listOptions;
  }

  static decreaseBrightness(hex, percent) {
    let r = parseInt(hex.substr(1, 2), 16),
      g = parseInt(hex.substr(3, 2), 16),
      b = parseInt(hex.substr(5, 2), 16);

    return '#' +
      ((0 | (1 << 8) + r * (100 - percent) / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + g * (100 - percent) / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + b * (100 - percent) / 100).toString(16)).substr(1);
  }

  static increaseBrightness(hex, percent) {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }

    let r = parseInt(hex.substr(0, 2), 16),
      g = parseInt(hex.substr(2, 2), 16),
      b = parseInt(hex.substr(4, 2), 16);

    return '#' +
      ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
  }

  static maskValid(value) {
    if(value.indexOf('A')>-1)
      return true;
    else if(value.indexOf('a')>-1)
      return true;
    else if(value.indexOf('1')>-1)
      return true;
    else if(value.indexOf('*')>-1)
      return true;
    else if(value.indexOf('#')>-1)
      return true;

    return false;
  }

  static getTitleFromUrl(t) {
    var pathname = window.location.pathname;
    var items = pathname.split('/');
    items.shift();

    if(items[0] === "dam" || items[0] === "") {
      switch(items.length) {
        case 1:
        case 2:
          return "DAM - Divisões"
        case 3:
          return "Segmentos em " + this.makeTitle(items[items.length-1]);
        case 4:
          return "Categorias em " + this.makeTitle(items[items.length-1]);
        case 5:
          return "Items em " + this.makeTitle(items[items.length-2]) + " > " + this.makeTitle(items[items.length-1]);
        case 6:
          return "Detalhe do item " + this.makeTitle(items[items.length-1])
        default:
          return items.join(" > ");
      }
    } else {
      return items.join(" > ");
    }
  }


  static getRegionFromUrl(other_regions, region) {
    var pathname = window.location.pathname;
    var items = pathname.split('/');
    items.shift();

    if(other_regions.length > 0 && items.length > 1) {
      const getRegions = other_regions.map(i => i.slug)
      const existingRegions = getRegions.some(s => s === items[1])
      const regionCurrent = items[1] === region

      if(regionCurrent || existingRegions){
        return items[1]
      }
      if(!regionCurrent && !existingRegions) {
        return 'region-invalid'
      }

      if(items[0] !== "dam") {
        return null
      }

    } else {
      return null
    }
  }

  static makeTitle(slug) {
    var words = slug.split('-');

    for(var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(' ');
  }

  static toBoolean(val) {
    if(typeof(val) === "string") {
      if(val === "true") return true;
      if(val === "false") return false;
      if(val === "1") return true;
      if(val === "0") return false;
    }

    return val;
  }

  static getSekeletonLi() {
    return (
      <>
      <li style={{ padding: "0 20px 10px 0" }}>
        <Skeleton
          variant="rect"
          width={230}
          height={230}
          style={{ borderRadius: 8 }}
        />
      </li>
      <li style={{ padding: "0 20px 10px 0" }}>
        <Skeleton
          variant="rect"
          width={230}
          height={230}
          style={{ borderRadius: 8 }}
        />
      </li>
      <li style={{ padding: "0 20px 10px 0" }}>
        <Skeleton
          variant="rect"
          width={230}
          height={230}
          style={{ borderRadius: 8 }}
        />
      </li>
      </>
    )
  }

  static WebpIsSupported = async () => {
    // If the browser doesn't has the method createImageBitmap, you can't display webp format
    if (!window.createImageBitmap) return false;

    // Base64 representation of a white point image
    const webpData =
      "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";

    // Retrieve the Image in Blob Format
    const blob = await fetch(webpData).then(r => r.blob());

    // If the createImageBitmap method succeeds, return true, otherwise false
    return createImageBitmap(blob).then(() => true, () => false);
  }

  static getImagemSrcFromThumbnail(url) {
    return Utils.WebpIsSupported() ? url.webp : url.original;
  }

  static prettyBytes(bytes) {
    if (bytes === 0) { return "0.00 B"; }
    var e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes/Math.pow(1024, e)).toFixed(2)+' '+' KMGTP'.charAt(e)+'B';
  }

  static getLanguageFromText(text) {
    switch(text) {
      case 'Espanhol':
      case 'es-ar':
        return 'es';
      case 'Português':
      case 'pt-br':
        return 'pt';
      case "Inglês":
      case "en":
        return "en";
      default: return null;
    }
  }

  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static versionName(versions, index, t) {
    let amountVersions = _.get(versions, 'length', 0)
    if (index === 0) {
      return t("common:Versão mais recente")
    }
    return `${amountVersions - index}${t('ª')} ${t('Versão')}`
  }

  static checkIfDateIsAfter = (start, end) => {
    let isAfter = moment.utc(start).isAfter(end)
    return isAfter
  }

  static checkIfDateIsValid = (date) => {
    let isValid = false
    if(date.indexOf('T03:00:00.000Z') !== -1) {
      isValid = moment.utc(date, 'YYYY-MM-DD').isValid()
    }
    return isValid
  }

  static getDate = (format = "DD/MM/YYYY") => {
    let date = moment.utc().format(format)
    return date
  }

  static formatDate = (date, format = "DD/MM/YYYY") => {
    const lng = i18n.language
    let dateFormated = null

    if(lng === 'en') {
      dateFormated = moment.utc(date).format("MM/DD/YYYY")
    }
    dateFormated = moment.utc(date).format(format)
    return dateFormated
  }

  static formatDateFull = (date) => {
    const lng = i18n.language

    const momentFormat = (text) => {
      return moment(date).format(text)
    }

    switch (lng) {
      case 'pt':
        return `${momentFormat('DD')} de ${MONTHS.pt[momentFormat('MMMM').toLowerCase()]} de ${momentFormat('YYYY')} às ${momentFormat('HH')}h${momentFormat('mm')}`
      case 'es':
        return `${momentFormat('DD')} de ${MONTHS.es[momentFormat('MMMM').toLowerCase()]} de ${momentFormat('YYYY')} a las ${momentFormat('HH')}h${momentFormat('mm')}`
      default:
        return momentFormat('LLL')
    }
  }

  static formatDateForVersions = (date, t) => {
    const momentFormat = (text) => {
      return moment(date).format(text)
    }

      if(moment(date).isValid()) {
        return `${momentFormat('DD/MM/YYYY')} ${t('DAM:às')} ${momentFormat('HH')}h${momentFormat('mm')}`
      } else {
        return 'Erro! data inválida'
      }
  }


  static getThumb = (thumbnail, supportsWebp) => {
    if(!thumbnail) {
      return FileAlert
    }

    return supportsWebp
            ?  _.get(thumbnail, 'thumbnail_pre_webp', _.get(thumbnail, 'thumbnail_card_webp', ''))
            : _.get(thumbnail, 'thumbnail_pre_original', _.get(thumbnail, 'thumbnail_card_original', ''))

  }

  static getFormattedDescription = (dataDescription = {}, t) => {
    let format;
    if (Boolean(dataDescription) && dataDescription.hasOwnProperty("formatter")) {
      try {
        format = JSON.parse(dataDescription.formatter.format);

        if (format.blocks[0].text.length === 0) {
          let row = {
            blocks: [
              {
                key: "999",
                text: t("DAM:Sem descrição"),
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
            entityMap: {},
          };

          return JSON.stringify(row);
        }

        Object.keys(format.entityMap).map((key) => {
          const e = format.entityMap[key];
          if (e.type === "LINK" && !/^(?:f|ht)tps?:\/\//.test(e.data.url)) {
            e.data.url = `http://${e.data.url}`;
          }

          return e;
        });

        return JSON.stringify(format);
      } catch (e) {
        let row = {
          blocks: [
            {
              key: "999",
              text: Boolean(dataDescription.text) ? dataDescription.text : '',
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        };
        return JSON.stringify(row);
      }
    } else {
      let row = {
        blocks: [
          {
            key: "999",
            text: Boolean(_.get(dataDescription, 'text', false))
              ? dataDescription.text
              : (typeof dataDescription === 'string')
                ? dataDescription
                : '',
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      };

      return JSON.stringify(row);
    }
  };

  static getIconSVG = (_type, _path) => {
    const dataIcons = [
      {type: "video/mp4", icon: mdiPlayBox},
      {type: "video/mov", icon: mdiPlayBox},
      {type: "video/avi", icon: mdiPlayBox},
      {type: "video/mpeg", icon: mdiPlayBox},
      {type: "video/webm", icon: mdiPlayBox},
      {type: "video/ogg", icon: mdiPlayBox},
      {type: "video/x-m4v", icon: mdiPlayBox},
      {type: "video/3gpp", icon: mdiPlayBox},
      {type: "video/x-ms-vob", icon: mdiPlayBox},
      {type: "video/x-matroska", icon: mdiPlayBox},
      {type: "video/x-flv", icon: mdiPlayBox},
      {type: "video/MP2T", icon: mdiPlayBox},
      {type: "application/mxf", icon: mdiPlayBox},
      {type: "application/ttf", icon: mdiAlphaABox},
      {type: "application/font-woff", icon: mdiAlphaABox},
      {type: "application/pdf", icon: mdiPdfBox},
      {type: "application/doc", icon: mdiTextBox},
      {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", icon: mdiTextBox},
      {type: "application/txt", icon: mdiTextBox},
      {type: "application/csv", icon: mdiTextBox},
      {type: "image/svg", icon: mdiVectorRadius},
      {type: "image/svg+xml", icon: mdiVectorRadius},
      {type: "application/ai", icon: mdiVectorRadius},
      {type: "application/cdr", icon: mdiVectorRadius},
      {type: "application/x-zip-compressed", icon: mdiZipBox},
      {type: "application/x-rar-compressed", icon: mdiZipBox},
      {type: "application/x-tar", icon: mdiZipBox},
      {type: "application/java-archive", icon: mdiZipBox},
      {type: "application/x-7z-compressed", icon: mdiZipBox},
      {type: "application/gzip", icon: mdiZipBox},
      {type: "application/x-webarchive", icon: mdiZipBox},
      {type: "application/xls", icon: mdiGoogleSpreadsheet},
      {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", icon: mdiGoogleSpreadsheet},
      {type: "application/xlsx", icon: mdiGoogleSpreadsheet},
      {type: "application/vnd.oasis.opendocument.spreadsheet", icon: mdiGoogleSpreadsheet},
      {type: "application/vnd.oasis.opendocument.spreadsheet-template", icon: mdiGoogleSpreadsheet},
      {type: "application/vnd.ms-excel ", icon: mdiGoogleSpreadsheet},
      {type: "application/ppt", icon: mdiFilePresentationBox},
      {type: "application/vnd.openxmlformats-officedocument.presentationml.presentation", icon: mdiFilePresentationBox},
      {type: "application/key", icon: mdiFilePresentationBox},
      {type: "image/jpeg", icon: mdiImage},
      {type: "image/tiff", icon: mdiImage},
      {type: "image/tif", icon: mdiImage},
      {type: "image/png", icon: mdiImage},
      {type: "image/jpg", icon: mdiImage},
      {type: "image/gif", icon: mdiImage},
      {type: "image/webp", icon: mdiImage},
      {type: ".psd", icon: mdiDrawingBox},
      {type: ".psb", icon: mdiDrawingBox},
      {type: "audio/cad", icon: mdiFileCadBox},
      {type: 'text/plain', icon: mdiText }
    ]

    const typeString = _path.slice(_path.indexOf('.'))
    const icon = dataIcons.find(item => item.type === (_type || typeString))

    return !!icon ? icon.icon : mdiFileAlert

  };

  static enabledExtensionsVideos = (extension) => {
    const extensions = ['mp4','mkv','mov']

    return !!extensions.find(i => i === extension)
  }

  static modulo = (a, n) => ((a % n) + n) % n

  static sortedList = (array) => {
    const data = array.sort(function (a, b) {
      const a_ = a.toLowerCase()
      const b_ = b.toLowerCase()
      return a_ > b_ ? 1 : b_ > a_ ? -1 : 0
    })
    return data
  }

  static makeDownload = (linkDownload, callback = null) => {
    window.location.href = linkDownload
    if (callback) {
      callback();
    }
  }

  static getFailOver = (obj, path, defaultValue) => {
    return _.get(obj, path, defaultValue) || defaultValue
  }

  static hasDuplicates = (data) =>{
    let newValues = []
    let hasDuplicate = false

    for (let key in data) {
        if(newValues.some(i => i.value.toLowerCase().trim() === data[key].value.toLowerCase().trim())) {
          hasDuplicate = true
        } else {
          newValues.push(data[key])
        }
    }

    return hasDuplicate
  }

  static removeEmptyValues = (obj) => {
    let data = {}

    Object.entries(obj).forEach(([key, value]) => {
      if(Boolean(value) && typeof value === 'string') {
          if(Boolean(value.trim())) {
            data[key] = value
          }
      } else {
        if(!_.isEmpty(value) || (Array.isArray(value) && value.length > 0)) {
          data[key] = value
        }
      }
    })
    return data
  }

  static formatSlugToLabel = (slug) => {
    const text = slug.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase()
    }).split("-").join(" ")

    return text
  }

  static getBreadcrumbsGallery = (text, region, shortcutSLug) => {
    let breadcrumbs = []
    let key = '';
    try {
      if(typeof text !== 'string'){
        key = _.get(text,'key', '');
        text = _.get(text,'label', '');
      }
      else {
        key = text;
      }

      const dataText = text.split('/')
      const dataKey = key.split('/')

      const getUrls = dataKey.map(i => {
        const index = key.indexOf(i)
        return key.slice(0, index).slice(0, -1)
      }).filter(i => i)

      breadcrumbs = dataText.map((i, k) => {
        return ({
          label: unescape(i.replace('|', '-')),
          url: `/gallery/${region}/${shortcutSLug}/${getUrls[k] ? getUrls[k] : text}`
        })
      })
      return breadcrumbs
    } catch (e) {
      return []
    }
  }

  static validateEmail = (value) => {
    return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || value === "";
  }

  static ajaxErrorGetMessage = (err, t, textDefault) => {
    let message = Boolean(textDefault) ? textDefault : t("common:Erro inesperado. Contate o suporte")

    if(err.response) {
      if(_.get(err, 'response.headers["x-message"]', false)){
        message = err.response.headers["x-message"]
      }
      if(_.get(err, 'response.data.message', false)) {
        message = err.response.data.message
      }
      if(_.get(err, 'response.data.errors[0].message', false)) {
        message = err.response.data.errors[0].message
      }
    }

    return message
  }

  static isOrdinaryAssetUpload = entity => entity === "item" || entity === "asset";
  static isSameUploadContext = (contextEntity, objectEntity) =>
    Utils.isOrdinaryAssetUpload(objectEntity) && Utils.isOrdinaryAssetUpload(objectEntity)
      ? true : contextEntity === objectEntity
  static isSameFileContextProcessed = (item, contextItem) =>
    _.get(item, "id", "processed") === _.get(contextItem, "id", "current");
  static removeDuplicateDataForIDs = (previus, newData) => {
    let data = []
    newData.forEach(item => {
      if (!previus.some(i => i.id === item.id)) {
        data.push(item)
      }
    })
    return [...previus, ...data]
  }
}

export default Utils;
