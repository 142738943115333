import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom"
import { useParams } from 'react-router-dom'
import { useSelector } from "react-redux"
import axios from 'axios'
import MUIRichTextEditor from "mui-rte"
import { useSnackbar } from 'notistack'
import { useTranslation } from "react-i18next"

import {
    Stepper,
    Step,
    StepLabel,
    Grid,
    Container,
    Paper,
    useMediaQuery,
    StepConnector,
    Box
} from "@material-ui/core"

import Regions from './Region'
import Profiles from './Profile'
import ComplementaryData from './ComplementaryData'
import PersonalData from './PersonalData'
import ButtonAlreadyRegistered from './AlreadyRegistered';
import Loading from 'components/Loading'
import Header from './Header'

import _ from 'lodash'
import Settings from "utils/Settings"

import { useStyles } from "./styles_index"

const INITIAL_STATE = {
    statusView: 'stepper-viwer',
    activeStep: 0,
    dataTextSupport: '',
}

const DATA_TEXT = [
    { id: 'support_text_region', step: 0 },
    { id: 'support_text_profile', step: 1 },
    { id: 'support_text_complementary', step: 2 },
    { id: 'support_text_personal', step: 3 },
]

const STEPS = [
    'Região de acesso',
    'Tipo de perfil',
    'Dados complementares',
    'Dados pessoais'
]

export default function HorizontalLabelPositionBelowStepper({
    type,
    initialState = INITIAL_STATE,
    setUserData
}) {
    const { id_user } = useParams()
    const [state, setState] = useState({ ...initialState, checkedTextSupport: false, checkedDataUser: false })
    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:600px)')
    const { enqueueSnackbar } = useSnackbar()    
    const isInvitation = type === 'invitation'

    const { requestHeaders, data: { client_id, client_slug } } = useSelector(
        (state) => state.appReducer
    )

    const getData = useCallback(() => {
        const callback = () => {
            axios
                .get(`${Settings.URL_API}/api/${client_slug}/guest/findGuest/${id_user}`, requestHeaders)
                .then((result) => {
                    const DATA = _.get(result, 'data', {})
                    let newData = { ...DATA, name: '', checkedDataUser: true }

                    if (
                        Boolean(_.get(DATA, 'profile.domain_email', ''))
                        && (_.get(DATA, 'profile.type_approval', '') === "yourself")
                    ) {
                        let email = _.get(DATA, 'email', '')
                        const index = email.indexOf('@')                   
                        newData.email = email.slice(0, index)
                    }

                    if(Boolean(_.get(DATA, 'profile.domain_email', ''))) {
                        newData.profile = {
                            ...DATA.profile,
                            domain: DATA.profile.domain_email
                        } 
                    }

                    setState(prev => ({
                        ...prev, ...newData, id: id_user, activeStep: 2, statusView: 'stepper-viwer'
                    }))
                })
                .catch((err) => {
                    history.push('/expired-invitation')
                    console.log(err)
                })
        }
        setState(prev => ({ ...prev, statusView: 'loading' }), callback())

    }, [requestHeaders, id_user, history, client_slug])

    const getDataTextSupport = useCallback((clientId) => {
        axios
            .get(`${Settings.URL_API}/client/supportText/${clientId}`, requestHeaders)
            .then(
                function ({ data }) {
                    const getIdbyStep = DATA_TEXT.find(i => i.step === state.activeStep)

                    if (getIdbyStep && Boolean(getIdbyStep.id)) {
                        const text = _.get(data, `${getIdbyStep.id}.text`, "")
                        if (Boolean(text) && text.length > 0) {
                            setState(prev => ({
                                ...prev,
                                dataTextSupport: _.get(data, `[${getIdbyStep.id}].formatter.format`, ""),
                                checkedTextSupport: true
                            }))
                        }
                    }
                }
            )
            .catch(err => {
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
            })
    }, [requestHeaders, enqueueSnackbar, t, state.activeStep])

    useEffect(() => {
        let isMounted = true
        if (isMounted && client_id) {
            if (!state.checkedTextSupport) getDataTextSupport(client_id)
            if (isInvitation && !state.checkedDataUser) getData()
        }
        return () => { isMounted = false }
    }, [getDataTextSupport, getData, client_id, state.checkedTextSupport, state.checkedDataUser, isInvitation])
   
    const handleBack = (evt, step) => {
        setState(prev => ({ ...prev, activeStep: (step >= 0) ? step : prev.activeStep - 1 }))
    };
  
    const handleClickNext = (data) => {   
        if (!_.isEmpty(data)) {
            setState(prev => ({
                ...prev,
                ...data,                              
            }))
        }
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <Regions
                    handleClick={handleClickNext}
                    requestHeaders={requestHeaders}
                    client_slug={client_slug}
                />;
            case 1:
                return <Profiles
                    region={state.region}
                    handleClick={handleClickNext}
                    handleBack={handleBack}
                    requestHeaders={requestHeaders}
                    client_slug={client_slug}
                    isMobile={isMobile}
                />;
            case 2:
                return <ComplementaryData
                    profileId={_.get(state, 'profile.id', '')}
                    handleClick={handleClickNext}
                    handleBack={handleBack}
                    type={type}
                    requestHeaders={requestHeaders}
                    client_slug={client_slug}
                    isMobile={isMobile}
                />;
            default:
                return <PersonalData
                    dataSelected={state}
                    handleBack={handleBack}                   
                    type={type}
                    setUserData={setUserData}
                />;
        }
    }

    return (
        <>
            {
                state.statusView === 'loading' ? <Loading showLoading /> : (
                    <>
                        <Header type={type} />
                        <Box className={classes.root}>
                            <Grid item xs={12}>
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <Stepper
                                        activeStep={state.activeStep}
                                        alternativeLabel
                                        className={classes.rootStepper}
                                        connector={isMobile ? <div /> : <StepConnector />}
                                    >
                                        {STEPS.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{t(`common:${label}`)}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <Container maxWidth="md" className={classes.rootContainer}>
                                        {
                                            Boolean(state.dataTextSupport) &&
                                            <Grid item xs={12} className='root_richtexteditor'>
                                                <MUIRichTextEditor
                                                    defaultValue={state.dataTextSupport}
                                                    inlineToolbar={false}
                                                    toolbar={false}
                                                    readOnly
                                                    style={{ fontSize: 12 }}
                                                />
                                            </Grid>
                                        }

                                        <Paper elevation={2} className='container-paper'>
                                            {getStepContent(state.activeStep)}
                                        </Paper>
                                        <ButtonAlreadyRegistered />
                                    </Container>
                                </Box>
                            </Grid>
                        </Box>
                    </>
                )
            }
        </>
    );
}