import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  maskedInput: {
    position: 'relative',
    color: '#666',
    '& input': {
      height: '53.2px',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      color: 'inherit',
      padding: '26.5px 14px',
    },
    '& label': {
      position: 'absolute',
      top: '-5px',
      left: 10,
      backgroundColor: '#fff',
      fontSize: '0.75em',
      padding: '0 5px',
      color: '#f4433',
    },
    '& :hover': {
      border: '0.5px solid rgba(0, 0, 0, 0.54)',
    },
    '& input:focus': {
      outline: `${theme.palette.primary.main}`,
      border: `2px solid ${theme.palette.primary.main} `,
    },
  },
  error: {
    '& input': {
      border: '1px solid #f44336',
    },
    '&label': {
      color: '#f44336'
    },
    '& :hover': {
      border: '1px solid #f44336',
    },
    '& input:focus': {
      outline: '#f44336',
      border: `2px solid #f44336 !important`,
    }
  }
}));
