import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import AccordionDownloadCentral from './AccordionDownloadCentral'

const AccordionDownload = () => {
    const { pathname } = useLocation()

    const constans = useMemo(() => ({
        hide: (
            (pathname.indexOf('/bucket') !== -1) ||
            (pathname.indexOf('/downloads') !== -1) ||
            (pathname.indexOf('/manager/') !== -1)
        )
    }), [pathname])

    return !constans.hide && (
        <AccordionDownloadCentral />
    )
}

export default AccordionDownload
