import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch } from "react-redux"

import ItemsProvider from './context'
import Header from './Header'
import ItemsFilters from './ItemsFilters'
import ModeWiewItems from './ModeWiewItems'
import AccessDeniedAndRegion from './AccessDeniedAndRegion'

import { Creators as ItemsActions } from "flux/ducks/Items"
import useUtils from './hook/useUtils'

const Items = ({ hasInvalidRegion, draft, isShortcut = false, galleryBreadcrumbs = [] }) => {
    const [pitContext, setPitContext] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const match = useRouteMatch()
    const { getPitContext, deletePitContext } = useUtils()

    const deletePit = useCallback(() => {
        if (Boolean(pitContext)) {
            deletePitContext()
        }
    }, [pitContext, deletePitContext])

    const getPit = useCallback(() => {
        if (!Boolean(pitContext)) {
            const callback = (data) => {
                setPitContext(data,
                    dispatch(ItemsActions.setSession({
                        name: "pit",
                        data: data,
                    })))
            }
            getPitContext(callback)
        }
    }, [pitContext, getPitContext, dispatch])


    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            if (hasInvalidRegion) {
                history.push("/region-invalid")
            }
            dispatch(ItemsActions.setSession({
                name: "pathname",
                data: history.location.pathname,
            }))
        }

        return () => {
            isMounted = false
        }
    }, [hasInvalidRegion, history, dispatch])

    useEffect(() => {
        let isMounted = true
        if (isMounted) getPit()
        return () => {
            deletePit()
            isMounted = false
        }
    }, [getPit, deletePit])

    return (
        <ItemsProvider
            draft={draft}
            isShortcut={isShortcut}
            showSelected={match.path === "/items/:region"}
            pitContext={pitContext}
        >
            <AccessDeniedAndRegion>
                <Header inheritedBreadcrumbs={galleryBreadcrumbs} />               
                {
                    pitContext && (
                        <>
                            <ItemsFilters />
                            <ModeWiewItems />
                        </>
                    )
                }
            </AccessDeniedAndRegion>
        </ItemsProvider>
    )
}
export default Items
