import React from "react"

import { SortableContainer } from "react-sortable-hoc"

import { useStyles } from "./styles";

import AssetdndItem from './AssetdndItem'

const SortableList = SortableContainer(
    ({ items, handleChangeTags, isTagsComponent, dataValues }) => {
        const classes = useStyles()

        return (
            <div className={classes.itemsWrapper}>
                {items.map((value, index) => (
                    <div key={`item-${value.id}`}>
                        <AssetdndItem
                            index={index}
                            asset={value}
                            assetDnd={index}
                            handleChangeTags={handleChangeTags}
                            isTagsComponent={isTagsComponent}
                            dataValues={dataValues}
                        />
                    </div>

                ))}
            </div>
        )
    }
)

export default SortableList
