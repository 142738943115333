import React, { useState, useCallback, useEffect } from "react"
import axios from 'axios'
import _ from "lodash"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Typography, Tooltip } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { ReactComponent as FamilyHistorySVG } from 'imagens/ui/family_history.svg'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'

import { useDataAssetIntegra } from "../context"
import PaperTitle from "../PaperTitle"
import Utils from 'utils/Utils'
import clipboardCopy from 'utils/clipboardCopy'
import { useStyles } from "./styles"

const ClipBoard = ({ text = '', id = '' }) => {
    const [isFileCop, setIsFileCop] = useState(true)
    const { t } = useTranslation()
    const classes = useStyles()

    function changeVisibility(evt, id) {
        evt.stopPropagation()
        const elem = document.getElementById(id)

        if (Boolean(elem)) {
            if (elem.style.visibility === "hidden") {
                elem.style.visibility = "visible"
            } else {
                elem.style.visibility = "hidden"
            }
        }
    }

    const handleClick = (_text, _id) => {
        setIsFileCop(false, clipboardCopy(_text))
        setTimeout(() => {
            const elem = document.getElementById(_id)
            if (Boolean(elem)) elem.style.visibility = "hidden"
            setIsFileCop(true)
        }, 2000)
    }

    const textTooltip = isFileCop ? t('common:Copiar texto') : t('common:Copiado com sucesso')

    return (
        <Tooltip title={textTooltip}>
            <div
                className={classes.rowButton}
                onMouseOver={evt => changeVisibility(evt, id)}
                onMouseOut={evt => changeVisibility(evt, id)}
                onClick={() => handleClick(text, id)}
            >
                <Typography variant="body2" id="info" style={{ maxWidth: "90%", padding: "0 24px", margin: "8px 0" }}>
                    {text}
                </Typography>

                {
                    isFileCop ? (
                        <div id={id} style={{ display: "flex", alignItems: "center", visibility: "hidden" }}>
                            <FileCopyRoundedIcon color="primary" fontSize='small' />
                        </div>
                    ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <DoneRoundedIcon color="primary" fontSize='small' />
                        </div>
                    )
                }
            </div>
        </Tooltip>
    )
}

const DistributedFiles = () => {
    const [state, setState] = useState({
        checkedData: false, loadingData: true, data: [], error: false
    })
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
    const { stateItem: { data: { id: idItem }, loading } } = useDataAssetIntegra()
    const { t } = useTranslation()
    const classes = useStyles()

    const getData = useCallback(() => {
        axios
            .get(`${pathServer}/item/${idItem}/distributed_files`, requestHeaders)
            .then(({ data }) => setState({ checkedData: true, loadingData: false, data: data, error: false }))
            .catch((err) => {
                const message = Utils.ajaxErrorGetMessage(err, t)
                setState({ checkedData: true, loadingData: false, data: [], error: message })
            })
    }, [pathServer, requestHeaders, t, idItem])

    useEffect(() => {
        let isMounted = true
        if (isMounted && !state.checkedData) getData()
        return () => { isMounted = false }
    }, [getData, state.checkedData])

    if (loading || state.loadingData) {
        return (
            <>
                <PaperTitle />
                <div className={classes.paperWrapper}>
                    <div className={classes.rowWrapper}>
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="80%" />
                    </div>
                    <div className={classes.rowWrapper}>
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="80%" />
                    </div>
                    <div className={classes.rowWrapper}>
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="80%" />
                    </div>
                </div>
            </>
        )
    }


    if (state.error) {
        return (
            <div className={classes.rowWrapper}>
                <Typography component="div" variant="body2" id="title">
                    {t("DAM:Ocorreu um erro inesperado.")}
                </Typography>
                <Typography component="div" variant="body2" id="info">
                    {state.error}
                </Typography>
            </div>
        )
    }

    return (
        <>
            <PaperTitle title={t("DAM:Distribuições")} icon={FamilyHistorySVG} />
            <div className={classes.paperWrapper} style={{ padding: 0 }}>
                <div className={classes.rowWrapper}>
                    {
                        _.get(state, 'data', []).map(item => {
                            return (
                                <div className={classes.row} key={`${item.name}-${Math.random()}`}>
                                    <Typography component="div" variant="body2" id="title" style={{ padding: "0 24px 8px 24px" }}>
                                        {t("common:Nome")}: {item.name}
                                    </Typography>
                                    {
                                        _.get(item, 'files', []).map(file => {
                                            const id = `${item.url}-${Math.random()}`
                                            return (
                                                <ClipBoard text={file.url} id={id} />
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default DistributedFiles
