import React from "react"
import MUIRichTextEditor from "mui-rte"

import { Typography, Box, Link } from "@material-ui/core"
import InfoRoundedIcon from "@material-ui/icons/InfoRounded"
import Skeleton from "@material-ui/lab/Skeleton"
import { useTranslation } from "react-i18next"

import PaperTitle from "../PaperTitle"
import _ from "lodash"
import { useStyles } from "./styles"
import Utils from "utils/Utils"

import InputEditSchema from './InputEditSchema'

const RowWrapper = ({ children, category_id = "", itemId = "", data = {} }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const validInfo = _.get(data, "value", t("DAM:Sem nome"))

  const getInfo = () => {
    if (Utils.checkIfDateIsValid(validInfo)) {
      return Utils.formatDate(validInfo)
    }
    if (Array.isArray(validInfo)) {
      return validInfo.join(", ")
    } else {
      return validInfo
    }
  }

  if (Array.isArray(validInfo) && validInfo.length >= 1) {
    return (
      <div className={classes.rowWrapper}>
        <Typography component="div" variant="body2" id="title">
          {_.get(data, "label", "no-label")}
        </Typography>
        {
          _.get(data, "is_master_metadata", false) ? (
            <Box className={classes.rootChips}>
              {
                validInfo.map(i => (
                  <InputEditSchema
                    key={Math.random()}
                    metadata_name={i}
                    category_id={category_id}
                    attribute_id={_.get(data, "attribute_id", "")}
                    itemId={itemId}
                  >
                    {(confirm) => (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={confirm()}
                        style={{ textDecoration: "underline" }}
                      >
                        {i}
                      </Link>
                    )}
                  </InputEditSchema>
                ))
              }
            </Box>
          ) : (
            <Typography component="div" variant="body2" id="info">
              {getInfo()}
            </Typography>
          )
        }
      </div>
    )
  }

  return (
    <div className={classes.rowWrapper}>
      <Typography component="div" variant="body2" id="title">
        {_.get(data, "label", "no-label")}
      </Typography>

      <Typography component="div" variant="body2" id="info">
        {children ? children : getInfo()}
      </Typography>
    </div>
  )
}

const Details = ({ data, loading }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { name, attribute, description, validity_date, start_date, category_id, itemId } = data

  const getDateInitialOrFinal = (initial, final) => {
    if (initial && final) {
      return `${Utils.formatDate(initial)} ${t('common:até')} ${Utils.formatDate(final)}`
    }
    if (initial && !final) {
      return Utils.formatDate(initial)
    }
    if (!initial && final) {
      return Utils.formatDate(final)
    }

  }

  return (
    <>
      {loading ? (
        <>
          <PaperTitle />
          <div className={classes.paperWrapper}>
            <div className={classes.rowWrapper}>
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="80%" />
            </div>
            <div className={classes.rowWrapper}>
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="80%" />
            </div>
            <div className={classes.rowWrapper}>
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="80%" />
            </div>
          </div>
        </>
      ) : (
        <>
          <PaperTitle title={t("DAM:Informações do item")} icon={InfoRoundedIcon} />
          <div className={classes.paperWrapper}>
            {name && <RowWrapper data={{ label: t("common:Nome"), value: name }} />}

            {description && (
              <RowWrapper data={{ label: t("common:Descrição") }}>
                <MUIRichTextEditor
                  defaultValue={Utils.getFormattedDescription(description, t)}
                  inlineToolbar={false}
                  toolbar={false}
                  readOnly={true}
                  style={{ fontSize: 12 }}
                />
              </RowWrapper>
            )}

            {
              (start_date || validity_date) &&
              <RowWrapper data={{
                label: t("common:Vigência de uso do item"),
                value: getDateInitialOrFinal(start_date, validity_date)
              }}
              />
            }

            {attribute.length > 0 &&
              attribute.map((item, index) => {
                return _.get(item, "value", "").length > 0 && (
                  <RowWrapper
                    key={index}
                    category_id={category_id}
                    itemId={itemId}
                    data={item}
                  />
                )
              })}
          </div>
        </>
      )}
    </>
  )
}

Details.defaultProps = {
  data: {
    name: "Erro ao carregar dados",
    description: "not-description",
    attribute: [],
  },
}
export default Details
