import React, { useState } from 'react'

import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'

import { IconButton, Tooltip, Badge, MenuItem, Typography } from "@material-ui/core"
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded'

import Notifications from '../../Notifications'

export default function Menu({ isMobile = false, changeRegion }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const { count } = useSelector((state) => state.headerAppReducer)
    const { t } = useTranslation()

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            {
                isMobile ? (
                    <MenuItem component='div' onClick={handleMenuOpen}>
                        <IconButton
                            disableRipple
                            color="inherit"
                            aria-owns={Boolean(anchorEl) ? "material-appbar" : undefined}
                            aria-haspopup="true"
                        >
                            <Badge badgeContent={count} color="secondary">
                                <NotificationsNoneRoundedIcon />
                            </Badge>
                        </IconButton>
                        <Typography>{t("common:Notificações")}</Typography>
                    </MenuItem>
                ) : (
                    <Tooltip title={t("common:Notificações")}>
                        <IconButton
                            disableRipple
                            onClick={handleMenuOpen}
                            color="inherit"
                        >
                            <Badge badgeContent={count} color="secondary">
                                <NotificationsNoneRoundedIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                )
            }

            {
                Boolean(anchorEl) &&
                <Notifications
                    anchorEl={anchorEl}
                    handleMenuClose={handleMenuClose}
                    changeRegion={changeRegion}
                />
            }
        </>
    );
}