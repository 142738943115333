import { useEffect, useState } from 'react'
import { useSelector } from "react-redux"

const DATA_IDS = {
    'pt': process.env.REACT_APP_AMPLIFIQUEME_PT,
    'es': process.env.REACT_APP_AMPLIFIQUEME_ES,
    'en': process.env.REACT_APP_AMPLIFIQUEME_EN,
}

const NpsWidget = () => {
    const { language, userData: { user_email } } = useSelector((state) => state.appReducer)
    const [lng, setLng] = useState(language)

    window.amplData = {
        email: user_email,
        survey: DATA_IDS[lng || 'pt'],
    }

    try {
        window.AmplSurvey.run('run')
    } catch (error) {
        console.error('Erro ao chamar a função do aplifique.me: AmplSurvey.run()', error)
    }

    useEffect(() => {
        if (language !== lng) {
            try {
                window.AmplSurvey.stop()
            } catch (error) {
                console.error('Erro ao chamar a função do aplifique.me: AmplSurvey.stop()', error)
            }
            setLng(language)
        }
    }, [language, lng])


    return null
}

export default NpsWidget
