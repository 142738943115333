import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
    Tabs,
    Tab,
    Grid,
    Paper,
    Typography
} from '@material-ui/core';

import Template from "../Template";
import PlatformConfig from './PlatformConfig'
import LinkContent from './partials/LinkContent'
import DnsContent from './partials/DnsContent'
import SecurityConfig from './SecurityConfig'
import LayoutConfig from './LayoutConfig'
import RequestPersonalization from './RequestPersonalization/RequestPersonalization'


function TabPanel(props) {
    const { children, value, index, title, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const history = useHistory()
    const { t } = useTranslation()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Template area={8} localHistory={history} customClassName={"container-center"}>

            <Paper elevation={2} style={{ padding: 20 }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 20
                }}>
                    <Typography variant='h5'>
                        {t('common:Personalização da plataforma')}                        
                    </Typography>
                    <Typography variant='body2'>
                        {`*${t('common:Campo obrigatório')}`}
                    </Typography>
                </div>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                        aria-label="tabs-config-platform"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={t('common:Nome')}  {...a11yProps(0)} />
                        <Tab label={t('common:Link')} {...a11yProps(1)} />
                        <Tab label={t('common:Segurança')} {...a11yProps(2)} />
                        <Tab label="Layout" {...a11yProps(3)} />
                        <Tab label={t('solicitacaoAcesso:Personalizar solicitações')} {...a11yProps(4)} />
                    </Tabs>
                </Grid>

                <TabPanel value={value} index={0} title={''}>
                    <PlatformConfig />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <LinkContent />
                    <DnsContent />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <SecurityConfig />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <LayoutConfig />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <RequestPersonalization />
                </TabPanel>
            </Paper>

        </Template>
    );
}
