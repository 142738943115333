import React from "react"
import { pick } from "ramda"
import CreateDefault from "../Core/CreateDefault"
import { settings } from "./_settings.js"

import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { compose } from "recompose"
import UserComboBox from "../../../components/ComboBox/UserComboBox"

import { arrayMove } from "react-sortable-hoc";
import MUIRichTextEditor from "mui-rte"
import AttributeComboBox from "../../../components/ComboBox/AttributeComboBox";
import TableAttribute from "../Categories/partials/TableAttributes";
import { EditorState, convertToRaw } from "draft-js"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import axios from "axios"
import _ from "lodash"


import Utils from "../../../utils/Utils"
import Settings from "../../../utils/Settings"

import DialogConfirm from "../../../components/Dialogs/DialogConfirm"

import { withSnackbar } from "notistack"

import {
  Grid,
  Typography,
  TextField,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Button,
  IconButton
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete'

class Form extends CreateDefault {

  constructor(props) {
    super(props)
    const { t, workflow, typeForm } = props;
    this.state = {
      ...this.state,
      ...settings,
      fields: [
        "title",
        "support_text",
        "allows_user_message",
        "submit_message",
        "recipients",
        "attributes",
        "approvers",
        "validators",
        "type"
      ],
      usersInRecipients: [],
      support_text_default: "",
      hasData: true,
      anchorEl: null,
      showLngInput: {},
      data: {
        title: "",
        support_text: EditorState.createEmpty(),
        allows_user_message: false,
        submit_message: { pt: '', en: '', es: '' },
        recipients: [],
        approvers: [],
        validators: [],
        attributes: [],
        taxonomy: [],
        type: workflow ? "WORKFLOW" : "REQUEST"
      },
      taxonomy: null,
      taxonomyItems: [
        { label: t("common:Cliente"), value: "client.name", },
        { label: t("common:Região"), value: "region.name" },
        { label: t("common:Divisão"), value: "division.name" },
        { label: t("common:Segmento"), value: "segment.name" },
        { label: t("common:Categoria"), value: "category.name" },
        { label: t("common:Item"), value: "item.name" }
      ],
      dialogs: {
        updateDialogOpen: false,
      },
      title: {
        singular: workflow
          ? typeForm === "create"
            ? "Criação da ferramenta de workflow"
            : "Edição da ferramenta de workflow"
          : "Ferramenta de solicitação",
        plural: workflow
          ? typeForm === "create"
            ? "Criação das ferramentas de workflow"
            : "Edição das ferramentas de workflow"
          : "Ferramentas de solicitação",
      },
    }

    this.superInsert = super.insert

    const createTheme = createMuiTheme()
    this.defaultTheme = Object.assign(createTheme, {
      overrides: {
        MUIRichTextEditor: {
          container: {
            margin: 0,
          },
          toolbar: {
            borderBottom: "1px solid #eee",
            padding: "2px",
            "& svg": {
              fontSize: "1.2rem",
            },
          },
          placeHolder: {
            margin: 0,
            padding: "19px 16px",
            fontSize: "0.875rem",
          },
          editorContainer: {
            margin: 0,
            padding: "19px 16px",
          },
        },
      },
    })
  }

  componentDidMount() {
    const ID = this.props.match.params.id
    this.setState((prev) => ({
      ...prev,
      id: ID,
    }))
    if (ID) this.getData(ID)
  }

  getData(id) {
    const SELF = this
    const { requestHeaders, pathServer } = SELF.props

    axios
      .get(`${pathServer}/${SELF.state.entity}/${id}`, requestHeaders)
      .then(
        function (resp) {
          const usersInRecipients = _.get(resp.data, "item.recipients", []).map(
            (i) => ({
              value: i.id,
              label: i.name,
              disabled: true,
            })
          )

          let support_text
          if (_.get(resp.data, "item.support_text.text", null)) {
            support_text = Utils.getFormattedDescription(
              _.get(resp.data, "item.support_text", "")
            )
          }

          let showLngInput = {}

          if (_.get(resp.data, "item.submit_message", {})) {
            Object.keys(_.get(resp.data, "item.submit_message", {})).forEach(k => {
              showLngInput[k] = true
            })
          }

          SELF.setState((prev) => ({
            ...prev,
            showLngInput,
            data: {
              title: _.get(resp.data, "item.title", ""),
              attributes: _.get(resp.data, "item.attributes", []),
              taxonomy: _.get(resp.data, "item.taxonomy", []),
              allows_user_message: _.get(
                resp.data,
                "item.allows_user_message",
                false
              ),
              submit_message: _.get(resp.data, "item.submit_message", {}),
              recipients: _.get(resp.data, "item.recipients", []).map(
                (i) => i.id
              ),
              approvers: _.get(resp.data, "item.approvers", []),
              validators: _.get(resp.data, "item.validators", []),
              type: _.get(resp.data, "item.type", "REQUEST"),
            },
            support_text_default: support_text,
            usersInRecipients: usersInRecipients,
          }))
        },
        function (error) {
          SELF.setState({ ...SELF.state, loading: false })
          SELF.showMessage(error.response.headers["x-message"], 'error')
        }
      )
      .catch((err) => console.log(err))
  }


  validateForm(parameters) {
    const { t, workflow } = this.props
    let formValid = true
    let formErrors = {}

    if (_.get(parameters, "title", false)) {
      if (parameters.title.length > 100) {
        formErrors.title = t("common:Máx. 100 caracteres")
        formValid = false
      }
    } else {
      formErrors.title = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (_.get(parameters, "recipients", []).length <= 0) {
      formErrors.recipients = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (workflow) {
      if (_.get(parameters, "approvers", []).length <= 0) {
        formErrors.approvers = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      }
    }

    if (_.get(parameters, "submit_message.pt", false)) {
      if (parameters.submit_message.pt.length > 50) {
        formErrors.submit_message_pt = t("common:Máx. 50 caracteres")
        formValid = false
      }
    }

    if (_.get(parameters, "submit_message.en", false) || _.get(parameters, "submit_message.es", false)) {
      if (_.get(parameters, "submit_message.en", '').length > 50) {
        formErrors.submit_message_en = t("common:Máx. 50 caracteres")
        formValid = false
      }
      if (_.get(parameters, "submit_message.es", '').length > 50) {
        formErrors.submit_message_es = t("common:Máx. 50 caracteres")
        formValid = false
      }
    }

    this.setState({ ...this.state, formErrors, formValid })

    return formValid
  }

  getParameters() {
    const SELF = this
    let { data, dataOriginal, fields } = SELF.state
    const { typeForm, workflow, currentLanguage } = this.props
    const IS_CREATE = typeForm === "create"
    let parameters = pick(fields, data)

    if (_.get(parameters, 'approvers', []).length > 0) {
      parameters.approvers = parameters.approvers.map(i => _.get(i, 'value', _.get(i, 'id', '')))
    }

    if (_.get(parameters, 'validators', []).length > 0) {
      parameters.validators = parameters.validators.map(i => _.get(i, 'value', _.get(i, 'id', '')))
    }


    if (
      _.get(parameters, 'submit_message.pt', '').length === 0
      && _.get(parameters, 'submit_message.en', '').length === 0
      && _.get(parameters, 'submit_message.es', '').length === 0) {
      parameters.submit_message = { [currentLanguage]: 'enviar' }
    }

    parameters.submit_message = Utils.removeEmptyValues(parameters.submit_message)

    if (IS_CREATE) {
      return {
        ...parameters,
        attributes: parameters.attributes.map(i => ({ id: i.id, name: i.name, order: i.order, required: i.required })),
        ...(workflow && { type: 'workflow' })
      }
    } else {
      let modifyParameters = Object.keys(parameters).filter(
        (fieldName) => parameters[fieldName] !== dataOriginal[fieldName]
      )
      return pick(modifyParameters, parameters)
    }
  }

  //HANDLER ATTRIBUTE
  handleAddAttribute(attribute) {
    let SELF = this;
    let { data } = SELF.state;
    let { t } = SELF.props;
    let newRegister = {
      required: 0,
      id: attribute.value,
      name: attribute.label,
      order: data.attributes.length,
    };

    if (data.attributes.length <= 19) {
      data.attributes.push(newRegister);
      data.attributes = this.setAttributesOrder(data.attributes)

      SELF.setState({ ...SELF.state, data })
    }

    else {
      SELF.showMessage(t("Máximo de 20 metadados permitidos"), "warning")
      return
    }
  }

  handleRemoveAttribute(id) {
    let { data, taxonomyItems } = this.state;
    data.attributes = data.attributes.filter((item) => item.id !== id);
    data.attributes = this.setAttributesOrder(data.attributes)

    if (taxonomyItems.find(i => i.value === `attribute.${id}`)) {
      taxonomyItems = taxonomyItems.filter((item) => item.value !== `attribute.${id}`)
    }

    this.setState({ ...this.state, data, taxonomyItems })
  }

  handleCheckAttribute(id) {
    const { t } = this.props;
    let { data, taxonomyItems } = this.state;
    let attribute = data.attributes.find((item) => item.id === id);

    if (attribute.required) {
      if (taxonomyItems.find(i => i.value === `attribute.${id}`)) {
        return
      } else {
        let newRegister = {
          label: `${t("Metadado")} - ${attribute.name}`,
          value: `attribute.${id}`,
          disabled: false
        };
        taxonomyItems.push(newRegister);
      }
    } else {
      taxonomyItems = taxonomyItems.filter((item) => item.value !== `attribute.${id}`)
    }

    this.setState({ ...this.state, data, taxonomyItems })
  }

  handleMoveAttribute = ({ oldIndex, newIndex }) => {
    const newState = { ...this.state };
    newState.data.attributes = arrayMove(newState.data.attributes, oldIndex, newIndex);
    newState.data.attributes = this.setAttributesOrder(newState.data.attributes)
    this.setState(newState);
  };

  setAttributesOrder = (attributes) => {
    attributes = attributes.map((attribute, index) => {
      attribute.order = index
      return attribute
    })
    return attributes
  }


  handleAddRecipients(attributeName, selectOption) {
    let { value, label } = selectOption
    let newState = { ...this.state }

    if (attributeName === "recipients") {
      newState.data[attributeName].push(value)
      newState.usersInRecipients.push({ value, label, disabled: true })
    } else {
      newState.data[attributeName] = [selectOption]
    }

    this.setState(newState)
  }

  handleRemoveRecipients(id) {
    const inRecipients = this.state.usersInRecipients.filter(
      (item) => item.value !== id
    )
    const recipients = this.state.data.recipients.filter((item) => item !== id)

    this.setState({
      ...this.state,
      usersInRecipients: inRecipients,
      data: { ...this.state.data, recipients },
    })
  }

  handleChangeEditorDraft = (editorState) => {
    const SELF = this.state

    const editorConverted = convertToRaw(editorState.getCurrentContent())

    const textPerLine = editorConverted.blocks.map((el) => el.text)

    const support_text = {
      text: textPerLine.join(" "),
      formatter: {
        name: "MUI-RTE/DraftJs",
        version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
        format: JSON.stringify(editorConverted),
      },
    }

    this.setState({
      ...SELF,
      data: {
        ...SELF.data,
        support_text: support_text,
      },
    })
  }

  handleClickLngMenu = (event) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handleShowLng = (value) => {
    this.setState(prev => ({
      ...prev,
      anchorEl: null,
      showLngInput: {
        ...prev.showLngInput,
        [value]: true
      },
    }))
  }

  handleDeleteLng = (lng) => {
    const copyShowLngInput = { ...this.state.showLngInput }
    const copyData = { ...this.state.data }
    const copyFormErrors = { ...this.state.formErrors }

    delete copyShowLngInput[lng]
    copyData.submit_message[lng] = ''
    delete copyFormErrors[`submit_message_${lng}`]

    this.setState(prev => ({
      ...prev,
      showLngInput: copyShowLngInput,
      data: copyData,
      formErrors: copyFormErrors
    }))
  }

  handleChangeSubmitMessage = (lng, value) => {
    const copyData = { ...this.state.data }
    copyData.submit_message[lng] = value

    this.setState((prev) => ({
      ...prev,
      data: copyData
    }))
  }

  insert(evt) {
    evt.preventDefault()
    if (this.props.typeForm === "create") {
      this.superInsert(evt)
    } else {
      this.setState((prev) => ({
        ...prev,
        dialogs: {
          ...prev.dialogs,
          updateDialogOpen: true,
        },
      }))
    }
  }

  handleInsert = async (evt) => {
    evt.persist()
    await this.handleClose("updateDialogOpen")
    await this.superInsert(evt)
  }

  getForm() {
    const SELF = this
    const { t, workflow, currentLanguage } = SELF.props
    const { data } = SELF.state
    let attributesInRequestToolsCategory = [];

    if (data.hasOwnProperty('attributes')) {
      data['attributes'].forEach((item) => {
        attributesInRequestToolsCategory.push(item.id)
      });
    }

    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant='body2'>
            {
              workflow ? t('manager:Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta')
                : t('manager:A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.')
            }
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="title"
            label={`${t("manager:Nome da ferramenta")}*`}
            variant="outlined"
            value={data.title}
            onChange={(evt) => SELF.handleChange("title", evt)}
            error={Boolean(this.state.formErrors.title)}
            helperText={this.state.formErrors.title ? this.state.formErrors.title : ''}
            disabled={this.props.typeForm === "edit"}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant='body1' style={{ fontWeight: 500 }}>
            {
              workflow ? t("manager:Descreva qual o objetivo da ferramenta de", { tool: t('manager:workflow') })
                : t("manager:Descreva qual o objetivo da ferramenta de", { tool: t('manager:solicitação') })
            }
          </Typography>
          <Typography variant='body2' style={{ marginBottom: 20 }}>
            {t("manager:Explique aos envolvidos o que se pretende alcançar com essa ferramenta")}
          </Typography>
          <div
            style={{
              minHeight: 110,
              border: "1px solid #bababa",
              borderRadius: 4,
            }}
          >
            <MuiThemeProvider theme={this.defaultTheme}>
              <MUIRichTextEditor
                maxLength={400}
                controls={["bold", "italic", "underline", "link"]}
                label={t("manager:Essa ferramenta será utilizada para...")}
                value={this.state.support_text_default}
                onChange={this.handleChangeEditorDraft}
              />
            </MuiThemeProvider>
          </div>
          {
            this.state.formErrors.support_text &&
            <div className="error">{this.state.formErrors.support_text}</div>
          }
        </Grid>

        {
          workflow && (
            <Grid item xs={12}>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                {t("manager:Selecione quem será o aprovador da ferramenta de workflow")}
              </Typography>
              <Typography variant='body2' style={{ marginBottom: 20 }}>
              {t("manager:Apenas usuários cadastrados na plataforma podem ser", {"type": t("manager:aprovadores")})}
              </Typography>
              <UserComboBox
                title={t('manager:Selecione o usuário')}
                fieldRequired
                disabledItems={_.get(this.state, 'data.approvers', [])}
                optionSelected={_.get(this.state, 'data.approvers[0].label', _.get(this.state, 'data.approvers[0].name', ''))}
                handleChange={(selectedItem) =>
                  SELF.handleAddRecipients("approvers", selectedItem)
                }
                error={this.state.formErrors.approvers}
                disabled={this.props.typeForm === "edit"}
              />
            </Grid>
          )
        }

        {
          workflow && (
            <Grid item xs={12}>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                {t("manager:Selecione quem será o validador da ferramenta de workflow (se houver)")}
              </Typography>
              <Typography variant='body2' style={{ marginBottom: 20 }}>
                {t("manager:Apenas usuários cadastrados na plataforma podem ser", {"type": t("manager:validadores")})}
              </Typography>
              <UserComboBox
                title={t('manager:Selecione o usuário')}
                disabledItems={_.get(this.state, 'data.validators', [])}
                optionSelected={_.get(this.state, 'data.validators[0].label', _.get(this.state, 'data.validators[0].name', ''))}
                handleChange={(selectedItem) =>
                  SELF.handleAddRecipients("validators", selectedItem)
                }
                error={this.state.formErrors.validators}
                disabled={this.props.typeForm === "edit"}
              />
            </Grid>
          )
        }

        <Grid item xs={12}>
          <Typography variant='body1' style={{ fontWeight: 500 }}>
            {workflow
              ? t("manager:Selecione quem será o executor da ferramenta de", { tool: t('manager:workflow') })
              : t("manager:Selecione quem será o executor da ferramenta de", { tool: t('manager:solicitação') })}
          </Typography>
          <Typography variant="body2" style={{ marginBottom: 20 }}>
          {t("manager:Apenas usuários cadastrados na plataforma podem ser", {"type": t("manager:executores")})}
          </Typography>
          <UserComboBox
            title={workflow ? t('manager:Selecione os usuários') : t('manager:Selecione o usuário')}
            fieldRequired
            disabledItems={this.state.data.recipients}
            value={""}
            handleChange={(selectedItem) =>
              SELF.handleAddRecipients("recipients", selectedItem)
            }
            error={this.state.formErrors.recipients}
          />
        </Grid>

        {_.get(this.state, "usersInRecipients", []).length > 0 && (
          <Grid
            item
            xs={12}
            style={{
              border: "1px solid #C4C4C4",
              borderRadius: 4,
              padding: 10,
              marginBottom: 20
            }}
          >
            {_.get(this.state, "usersInRecipients", []).map((item, key) => (
              <Chip
                key={key}
                label={item.label}
                onDelete={(evt) => this.handleRemoveRecipients(item.value)}
                avatar={<Avatar>{_.get(item, "label.0", "")}</Avatar>}
                style={{ margin: 5 }}
              />
            ))}
          </Grid>
        )}

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant='body1' style={{ fontWeight: 500 }}>
            {t('manager:Selecione os metadados que complementam a solicitação dessa ferramenta')}
          </Typography>
          <Typography variant='body2'>
            {t('manager:Quais metadados podem auxiliar na execução da ferramenta?')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <AttributeComboBox
            disabledItems={attributesInRequestToolsCategory}
            handleChange={(selectedItem) => this.handleAddAttribute(selectedItem)}
            error={this.state.formErrors.attributes}
            field_required={false}
            title={t('manager:Selecione os metadados')}
          />
        </Grid>
        {
          data.attributes.length > 0 &&
          <Grid item xs={12} style={{ margin: '20px 0' }}>
            <TableAttribute
              switchCells={["required"]}
              items={data.attributes}
              taxonomy={data.taxonomy}
              style={{ marginTop: 0 }}
              handleRemoveAttribute={(id, evt) => this.handleRemoveAttribute(id, evt)}
              handleCheckAttribute={(id) => this.handleCheckAttribute(id)}
              handleMoveAttribute={(obj) => this.handleMoveAttribute(obj)}
            />
          </Grid>
        }

        <Grid style={{ margin: '10px 0 25px' }}>
          <Grid>
            <Grid>
              <Typography variant='body1' style={{ fontWeight: 500 }}>
                {t("manager:Defina qual será a legenda do botão")}
              </Typography>
              <Typography variant='body2' style={{ marginBottom: 20 }}>
                {t("manager:Adicione um nome para o botão da ação da ferramenta")}
              </Typography>
            </Grid>
            <TextField
              name={`submit_message_${currentLanguage}`}
              label={`${t(`manager:Enviar, Compartilhar, Executar...`)}`}
              variant="outlined"
              value={_.get(data, `submit_message.${currentLanguage}`, '')}
              onChange={(evt) => SELF.handleChangeSubmitMessage(currentLanguage, evt.target.value)}
              error={Boolean(_.get(this.state, `formErrors.submit_message_${currentLanguage}`, false))}
              helperText={_.get(this.state, `formErrors.submit_message_${currentLanguage}`, '')}
              style={{ margin: 0, width: '100%' }}
            />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              <Button
                color='primary'
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleClickLngMenu}
                disabled={(Object.keys(this.state.showLngInput).length) === (Settings.DATA_SUPPORTED_LNG.length - 1)}
              >
                {t('manager:Adicionar idioma')}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={() => this.handleClickLngMenu({ event: { currentTarget: null } })}
              >
                {
                  Settings.DATA_SUPPORTED_LNG.map((lng) => {
                    return (
                      (lng.lng !== currentLanguage) && !(this.state.showLngInput[lng.lng]) &&
                      <MenuItem key={lng.lng} onClick={() => this.handleShowLng(_.get(lng, 'lng', ''))}>{t(`manager:${lng.short_name}`)}</MenuItem>
                    )
                  })
                }
              </Menu>
            </div>
            <div style={{ width: 48 }} />
          </Grid>

          <Grid />
          {
            Settings.DATA_SUPPORTED_LNG.map((lng) => {
              return (
                (lng.lng !== currentLanguage) && (this.state.showLngInput[lng.lng]) &&
                <Grid key={lng.lng}>
                  <Typography variant='body2' style={{ margin: '20px 0 10px' }}>
                    {t(`manager:Esse nome será visto pelos usuários que utilizam o idioma na plataforma`, { idm: t(`manager:${lng.short_name}`) })}
                  </Typography>
                  <Grid style={{ display: 'flex' }}>
                    <TextField
                      name={`submit_message_${lng.lng}`}
                      label={t('manager:Legenda do botão em', { idm: t(`manager:${lng.short_name}`) })}
                      variant="outlined"
                      value={data.submit_message[lng.lng]}
                      onChange={(evt) => SELF.handleChangeSubmitMessage(lng.lng, evt.target.value)}
                      style={{ margin: 0, width: '100%' }}
                      error={Boolean(this.state.formErrors[`submit_message_${lng.lng}`])}
                      helperText={this.state.formErrors[`submit_message_${lng.lng}`] ? this.state.formErrors[`submit_message_${lng.lng}`] : ''}
                    />
                    <IconButton
                      style={{ backgroundColor: 'transparent', height: 56 }}
                      onClick={() => this.handleDeleteLng(_.get(lng, 'lng', ''))}
                    >
                      <DeleteIcon style={{ color: '#666' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            })
          }
        </Grid>

        {_.get(this.state, "dialogs.updateDialogOpen", false) && (
          <DialogConfirm
            handleClose={() => this.handleClose("updateDialogOpen")}
            handleConfirm={this.handleInsert}
            title={t("Confirmação")}
            icon
            description={t('common:A alteração feita será aplicada na Ferramenta. Deseja continuar?',
              {
                name: _.get(
                  this.state,
                  "data.title",
                  ""
                )
              })}
            open={_.get(this.state, "dialogs.updateDialogOpen", false)}
          />
        )}
      </React.Fragment>
    )
  }
}

export const mapStateToProps = ({ appReducer }) => {
  return {
    region_id: appReducer.userData.region_id,
    region: appReducer.userData.region,
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
    currentLanguage: appReducer.language
  }
}

export default compose(
  connect(mapStateToProps)
)(withTranslation(["manager", "common"])(withSnackbar(Form)))
