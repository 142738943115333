/** AppActions **/
export const IS_UPDATING = 'IS_UPDATING';
export const INVALID_DNS = 'INVALID_DNS';
export const INVALID_REGION = 'INVALID_REGION';
export const UPDATED_VERSION = 'UPDATED_VERSION';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_REGION = 'UPDATE_REGION';
export const FINISH_INITIAL_LOADING = 'FINISH_INITIAL_LOADING';
export const TRIGGER_ERROR = 'TRIGGER_ERROR';
export const START_LOADING_PAGE = 'START_LOADING_PAGE';
export const FINISH_LOADING_PAGE = 'FINISH_LOADING_PAGE';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const CHANGE_REGION = 'CHANGE_REGION';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_MENU = 'CHANGE_MENU';
export const ITEM_ID_FOR_VERSIONS = 'ITEM_ID_FOR_VERSIONS';
export const BIND_CHANNEL_SUCCEEDED = 'BIND_CHANNEL_SUCCEEDED';
export const BIND_CHANNEL_FAILED = 'BIND_CHANNEL_FAILED';
export const BINDING_CHANNEL = 'BINDING_CHANNEL';
export const CHANNEL_UNSUBSCRIBE = 'CHANNEL_UNSUBSCRIBE';
export const ASSET_TO_FINISH = 'ASSET_TO_FINISH';
export const ASSET_FINISHED = 'ASSET_FINISHED';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS'
export const MARK_ASSET = 'MARK_ASSET'
export const MARK_LIST_ASSETS = 'MARK_LIST_ASSETS'
export const UNMARK_ASSET = 'UNMARK_ASSET'
export const CLEAR_MARKED_ASSETS = 'CLEAR_MARKED_ASSETS'
export const REGION_CHECKED = 'REGION_CHECKED'
export const SHOW_UPLOAD_CANCEL_DIALOG = 'SHOW_UPLOAD_CANCEL_DIALOG'
export const HIDE_UPLOAD_CANCEL_DIALOG = 'HIDE_UPLOAD_CANCEL_DIALOG'
export const ADD_UPLOAD_FILES = 'ADD_UPLOAD_FILES'
export const ADD_FILE_REPROCESS = 'ADD_FILE_REPROCESS'
export const REMOVE_FILE_REPROCESS = 'REMOVE_FILE_REPROCESS'
export const UPDATE_FILE_REPROCESS = 'UPDATE_FILE_REPROCESS'
export const CLEAR_UPLOAD_FILES = 'CLEAR_UPLOAD_FILES'
export const UPLOAD_FILE_FINISHED = 'UPLOAD_FILE_FINISHED'
export const UPLOAD_FILE_PROCESSED = 'UPLOAD_FILE_PROCESSED'
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR'
export const REMOVE_FILE_FINISHED = 'REMOVE_FILE_FINISHED'
export const UPLOAD_FILE_CANCELLED = 'UPLOAD_FILE_CANCELLED'
export const UPLOAD_FILES_CANCELED_ALL = 'UPLOAD_FILES_CANCELED_ALL'
export const UPLOAD_FILE_REMOVE = 'UPLOAD_FILE_REMOVE'
export const SET_EVAPORATE_REDUCER = 'SET_EVAPORATE_REDUCER'
export const UPLOAD_PAUSED = 'UPLOAD_PAUSED'
export const UPLOAD_ALL_FILES_PAUSED = 'UPLOAD_ALL_FILES_PAUSED'
export const INCREASE_REQUEST_COUNT = 'INCREASE_REQUEST_COUNT'
export const DECREASE_REQUEST_COUNT = 'DECREASE_REQUEST_COUNT'
export const REMOVE_SELECTED_ITEMS = 'REMOVE_SELECTED_ITEMS'
export const CLEAR_ITEMS_SELECTEDS = 'CLEAR_ITEMS_SELECTEDS'
export const SHOW_CONFIRM_DIALOG_REGION = 'SHOW_CONFIRM_DIALOG_REGION'
export const HIDDEN_CONFIRM_DIALOG_REGION = 'HIDDEN_CONFIRM_DIALOG_REGION'
export const SET_USER_MODE_VIEW = 'SET_USER_MODE_VIEW'
export const SET_HAS_SUPPORT_CURRENT_REGION = 'SET_HAS_SUPPORT_CURRENT_REGION'
export const SET_DATA_PROGRESS_FILE = 'SET_DATA_PROGRESS_FILE'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_COLOR_PLATFORM = 'SET_COLOR_PLATFORM'
export const SET_ITEM_CONTEXT = 'ITEM_CONTEXT'
export const CLEAR_ITEM_CONTEXT = 'CLEAR_CONTEXT'
export const SET_UPDATED_ITEM = 'SET_UPDATED_ITEM'
