import React from 'react'
import { useTranslation } from "react-i18next"
import PropTypes from 'prop-types';

import {  
    Paper,
    Typography, 
  } from '@material-ui/core';

export const Content = ({children, title}) => {
    const { t } = useTranslation()

    return (
        <Paper elevation={2} style={{padding: 20, marginTop: 20}}>  
          <Typography variant="h5" style={{marginBottom: 20}}>
            {t(`${title}`)}
          </Typography>
          {children}
        </Paper>
    )
}

Content.propTypes = {
  // children: PropTypes.element,
  title: PropTypes.string
};