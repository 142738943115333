import React, { useState, useEffect } from 'react'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    content: {
        '& p': {
            marginBottom: 15
        }
    },
    color: {
        width: '58px',
        height: '25px',
        borderRadius: '2px',
        background: props => props.current,
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        opacity: 1,
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
        marginTop: 5,
        left: 254
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    disabled: {
        background: '#EBEBE4',
        padding: '5px',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
    },
    disabledColor: {
        width: '58px',
        height: '25px',
        borderRadius: '2px',
        background: '#EBEBE4'
    }
}))

const ColorPicker = ({ handleChangeImage, checkedColor, currentColor }) => {
    const [colorPrimary, setColorPrimary] = useState({ last: '', current: currentColor })
    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    const classes = useStyles({ ...colorPrimary })

    useEffect(() => {
      setColorPrimary(prev => ({ ...prev, current: currentColor }));
    }, [currentColor]);

    const handleClick = () => {
        setDisplayColorPicker(prev => !prev)
    };

    const handleChange = (color) => {
        setColorPrimary(prev => ({ ...prev, current: color.hex }))
        handleChangeImage({ target: { value: color.hex } }, 'background')
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    return (
        <div>
            <Grid item xs={12} className={classes.content}>
                <div className={!checkedColor ? classes.disabled : classes.swatch} onClick={checkedColor ? handleClick : null}>
                    <div className={checkedColor ? classes.color : classes.disabledColor} />
                </div>
            </Grid>
            {displayColorPicker ? <div className={classes.popover}>
                <div className={classes.cover} onClick={handleClose} />
                <ChromePicker color={currentColor ? currentColor : _.get(colorPrimary, 'current', '')} onChange={checkedColor ? handleChange : null} />
            </div> : null}
        </div>
    )
}


export default ColorPicker
