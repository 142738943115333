import React from 'react';
import _ from 'lodash'
import { useTranslation } from "react-i18next"

import {
    Button,
    Typography,
    Card,
    IconButton,
    Tooltip
} from '@material-ui/core';

import ZoomCheck from 'components/Dialogs/ZoomCheck'
import GetAppIcon from '@material-ui/icons/GetApp';
import { useStyles } from "../styles"

function CardAsset({ data = {}, handleOnlyDownload, disabled }) {
    const { t } = useTranslation()
    const classes = useStyles()
   
    return (
        <Card className={classes.card}>
            <ZoomCheck
                image={_.get(data, 'thumbnail', '')}
                name={_.get(data, 'name', '')}
                subtitle={_.get(data, 'size', 0)}
                actions={
                    <Button
                        onClick={() => handleOnlyDownload(_.get(data, 'name', ''), _.get(data, 'id', ''))}
                        variant="contained" color="primary" style={{ marginLeft: 16 }}
                        disabled={disabled}
                    >
                        {t("DAM:Download")}
                    </Button>
                }
            >
                {(confirm) => (
                    <div
                        onClick={confirm()}
                        title={_.get(data, 'name', '')}
                        style={{
                            width: "250px",
                            height: "250px",
                            backgroundImage: `url("${_.get(data, 'thumbnail', '')}")`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        className='image-card'
                    />
                )}
            </ZoomCheck>
            <div className="footer-card">
                <div style={{ maxWidth: "calc(100% - 48px)" }}>
                    <Tooltip title={_.get(data, 'name', '')}>
                        <Typography variant='body2' noWrap>
                            {_.get(data, 'name', '')}
                        </Typography>
                    </Tooltip>
                    <Typography variant='caption'>
                        {_.get(data, 'size', 0)}
                    </Typography>
                </div>
                <IconButton
                    disabled={disabled}
                    onClick={() => handleOnlyDownload(_.get(data, 'name', ''), _.get(data, 'id', ''))}
                >
                    <GetAppIcon color="primary" />
                </IconButton>
            </div>
        </Card>
    );
}
function CardFile({ data = {} }) {
    const classes = useStyles()
    return (
        <Card className={classes.card}>
            <div style={{ padding: 8, height: 60, lineHeight: 0 }}>
                <Tooltip title={_.get(data, 'taxonomy.name', '')}>
                    <Typography variant='body2' noWrap>
                        {_.get(data, 'taxonomy.name', '')}
                    </Typography>
                </Tooltip>
                <Typography variant='caption'>
                    {`${_.get(data, 'assets', 0).length} arquivos - ${_.get(data, 'size', 0)}`}
                </Typography>
            </div>
            <div
                title={_.get(data, 'taxonomy.name', '')}
                style={{
                    width: "250px",
                    height: "250px",
                    backgroundImage: `url("${_.get(data, 'thumb', '')}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
        </Card>
    );
}

export { CardAsset, CardFile }
