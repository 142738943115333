import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    display: "flex",
    flexDirection: "column", 
    padding: 30,
    paddingTop: 0,
    wordWrap: "break-word",  
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#666",
    marginTop: 20,
    "& #title": {
      fontWeight: 500,
    },
    "& #info": {
      marginTop: 10,
      fontWeight: 300,           
    },
  },
}))
