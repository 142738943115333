import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import CommentIcon from '@material-ui/icons/Comment';
import { ReactComponent as PublishIcon } from "imagens/publish.svg"
import { useStyles } from "../DialogConfirm/styles"
import { useTheme } from "@material-ui/core"

const DialogPublish = ({
  t,
  title,
  listNames = false,
  disabled,
  description,
  children,
  icon = null,
  hideCallback,
  currentComment
}) => {
  const [open, setOpen] = useState(false)
  const [callbackState, setCallbackState] = useState(null)
  const [comment, setComment] = useState('')

  const classes = useStyles()
  const theme = useTheme()

  const handleChange = (evt) => {
    const value = evt.target.value
    setComment(value)
  }

  const show = (callback) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    }

    setOpen(true)
    setCallbackState(() => (comment) => callback(event, comment))
  }

  const hide = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setOpen(false)
    setCallbackState(null)
    hideCallback(false)
  }

  const confirm = (event) => {
    callbackState(comment)
    hide(event)
  }

  return (
    <React.Fragment>
      {children(show)}
      {open && (
        <Dialog
          open={open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
          onClick={evt => evt.stopPropagation()}
        >
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
            <div style={{ borderRadius: '50%', width: 50, height: 50, background: theme.palette.primary.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              { currentComment ? <CommentIcon style={{ width: 30, height: 30, color:'#FFF' }} /> : <PublishIcon style={{ width: 30, height: 30 }} /> }
            </div>
            <DialogTitle id="alert-dialog-title" style={{ padding: '0px 10px' }}>
              <div className="info">
                {icon !== null && icon}
                <div>{title}</div>
              </div>
            </DialogTitle>
          </div>
          <DialogContent style={{ padding: "0" }}>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
            {listNames && <DialogContentText>{listNames}</DialogContentText>}
            <TextField
              placeholder={t("common:Comente aqui")}
              onChange={(evt) => handleChange(evt)}
              multiline
              rows={7}
              style={{ width: '100%', marginTop: 5 }}
              variant='outlined'
              autoFocus
              value={currentComment || comment}
              disabled={currentComment}
            />
          </DialogContent>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={hide}
              disabled={disabled}
              style={{ marginRight: !currentComment ? 20 : 0 }}
            >
              {!currentComment ? t(`common:Cancelar`) : t('common:Fechar')}
            </Button>
            {
              !currentComment &&
              <Button
                onClick={confirm}
                variant="contained"
                color="primary"
                disabled={disabled}
                data-testid='dialog-confirm-btn-confirm'
              >
                {t(`common:Confirmar`)}
              </Button>
            }
          </div>
        </Dialog>
      )}
    </React.Fragment>
  )
}

DialogPublish.defaultProps = {
  t: () => { },
  title: "",
  listNames: false,
  disabled: false,
  description: "",
  icon: null,
  hideCallback: () => { },
}

export default DialogPublish
