import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contentTitle: {
    width: '100%',
    marginBottom: 20, 
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#666'
  },
  contentForm: {
    width: '100%',
    color: "#666",
    '& h6': {
      color: '#666',
      marginBottom: 20
    }, 
    '& * svg.color-666': {
      color: '#666'
    },   
    '& #margin-bottom': {
      marginBottom: 11
    },
    '& .MuiFormControl-root': {
      width: '100%', 
      marginBottom: 20   
    },
    '& .paper-actions': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        height: 40
      },    
    },
    '& .react-select__control': {      
      '& * input': {
        padding: '11.5px 14px !important'
      },          
    },
    '& .react-select__control--is-focused': {
      boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,      
     },   
     '& .labelDescription': {
      top: "8px",
      position: "relative",
      background: "#fff",
      display: 'inline-block',
      left: "11px",
      padding: "0 3px",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: '0.00938em',
      zIndex: 1
  }, 
  '& .account-phone-inuput': {
    marginBottom: 20,
    "& input": {
      width: '100%',
      height: 56
    }
  },
  '& .error': {
    color: '#f44336',
    marginLeft: 14,
    fontSize: '0.75rem',
    marginTop: 5,
    textAlign: 'left',
  }    
  }
}));
