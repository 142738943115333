import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import axios from 'axios'

import {
    ListItemIcon,
    ListItem,
    Chip,
    ListItemText,
    Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LoupeRoundedIcon from '@material-ui/icons/LoupeRounded';

import Skeleton from "@material-ui/lab/Skeleton"

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        zIndex: 1300,
        [theme.breakpoints.down("sm")]: {
            height: 'auto',
            top: 0
        }
    },
    paper: {
        width: '85%',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            minHeight: '100vh',
            borderRadius: 0,
        }
    },
    paperHeader: {
        '& .title': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
            color: '#666',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
            },
            '& svg': {
                color: '#666',
            },
            '& h6': {
                marginLeft: 10
            }
        }
    },
    paperContent: {
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        '& .image': {
            width: '100%',
            minWidth: 100,
            minHeight: 400,
            marginRight: 20
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            paddingBottom: 80,
            '& .image': {
                minWidth: '100%',
                height: 170,
                minHeight: 100,
                marginRight: 0,
            },
        }
    },
    rootTags: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 10px',
        overflow: 'auto',
        maxHeight: 400,
        '& > *': {
            margin: 5,
        },
    }
}))


const GroupTags = ({ id_asset, id_item }) => {
    const [dataTags, setDataTags] = useState({
        tags: [], labels: []
    })
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const { requestHeaders, pathServer } = useSelector(
        (state) => state.appReducer
    )
    const classes = useStyles()
    const { t } = useTranslation()

    const getData = useCallback(() => {
        axios.get(`${pathServer}/item/${id_item}/asset/${id_asset}/tags`, requestHeaders)
            .then(function ({ data }) {
                setDataTags({
                    tags: Array.isArray(data.tags) ? data.tags : [],
                    labels: Array.isArray(data.labels) ? data.labels : [],
                })

                setError(false)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setError(true)
                setLoading(false)
            });
    }, [pathServer, requestHeaders, id_asset, id_item])

    useEffect(() => {
        getData()
        return () => { }
    }, [getData])

    

    const getSkeletonChip = () => {
        const MARGIN_SKELETON = 5
        const BORDER_RADIUS_SKELETON = 16

        return (
            <Skeleton
                variant="rect"
                width="50px"
                height="20px"
                style={{ marginLeft: MARGIN_SKELETON, borderRadius: BORDER_RADIUS_SKELETON }}
            />
        )
    }

    return (
        <div style={{ padding: 20, paddingTop: 0 }}>
            {
                loading && (
                    <>
                        <div>
                            <Skeleton variant="text" width={40} style={{ margin: '10px 0' }} />
                            <div style={{ display: 'flex' }}>
                                {getSkeletonChip()}
                                {getSkeletonChip()}
                                {getSkeletonChip()}   
                                {getSkeletonChip()}                             
                            </div>
                        </div>
                        <div>
                            <Skeleton variant="text" width={40} style={{ margin: '10px 0' }} />
                            <div style={{ display: 'flex' }}>
                                {getSkeletonChip()}
                                {getSkeletonChip()}
                                {getSkeletonChip()}
                                {getSkeletonChip()}
                            </div>
                        </div>
                    </>
                )
            }
            {
                dataTags.tags.length > 0 &&
                <div style={{ width: '100%' }}>
                    <p>{t('common:Marcador')}</p>
                    <div className={classes.rootTags}>
                        {
                            !error && dataTags.tags.length > 0 &&
                            dataTags.tags.map((i, k) => <Chip key={i + k} size="small" label={i} color='primary' />)
                        }
                        {
                            error && (
                                <Chip label={t('Erro ao carregar os dados')} />
                            )
                        }
                    </div>
                </div>
            }
            {
                dataTags.labels.length > 0 &&
                <div style={{ width: '100%' }}>
                    <p>{t('common:TAG automatizada')}</p>
                    <div className={classes.rootTags}>
                        {
                            !error && dataTags.labels.length > 0 &&
                            dataTags.labels.map((i, k) => <Chip key={i + k} size="small" label={i} />)
                        }
                        {
                            error && (
                                <Chip label={t('Erro ao carregar os dados')} />
                            )
                        }
                    </div>
                </div>
            }
        </div>

    )
}

export default function DetailsAsset({ id_asset, id_item, isDrawerOpen, setDrawerOpen }) {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    
    useEffect(() => {
        if (!isDrawerOpen) {
            setOpen(false)
        }
    }, [isDrawerOpen])

    const handleOpen = () => {
        setDrawerOpen()
        setOpen(prev => !prev)
    }

    return (
        <div>
            <ListItem button onClick={handleOpen}>
                <ListItemIcon>
                    <LoupeRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={t('DAM:Tags')} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <GroupTags id_asset={id_asset} id_item={id_item} />
            </Collapse>
        </div>
    );
}