import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch } from "react-redux"
import { Box } from "@material-ui/core"

import Card from "components/Layout/Card/Card"
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"
import { useIntersect } from 'components/Intersect'

import Utils from "utils/Utils"
import { Creators as ItemsActions } from "flux/ducks/Items"

import { useStyles } from './styles'

const GroupingCards = ({ match, setStateComponent, getData, stateRoot, page }) => {
    const [isIntersecting, ref] = useIntersect()

    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()

    const handleLoadMore = useCallback(() => {
        const params = {
            ...(Boolean(page.last) && { search_after: page.last }),
            ...(Boolean(page.updated) && { updated: page.updated })
        }
        if (stateRoot.data_length < page.total) {
            getData(params)
        }
    }, [page.last, page.total, stateRoot.data_length, getData, page.updated])


    const handleClickOverride = useCallback((item) => {
        const { grouped, slug, region, next_level_name, keys } = item

        const newValue = grouped.map((i, idx) => ({
            [i]: keys[idx], id: `${i}-${keys[idx]}`
        }))

        const newFiltersOptions = _.get(stateRoot, 'params.filtersOptions', []).map((i, k, array) => {
            const [key, value] = Object.entries(array[k])[0]
            return {
                [key]: value, id: `${key}-${value}`
            }
        })

        var _slug = slug;
        if (slug.indexOf('#') !== -1 || slug.indexOf('/') !== -1) {
            _slug = slug.replaceAll('#', escape('#'))
            _slug = slug.replaceAll('/', encodeURIComponent('/'));
        }

        let DATA = {
            filters: [...newFiltersOptions, ...newValue].map(i => ({
                ...i,
                disabled: true,
                isInitial: true
            })),
            ...(stateRoot.params.sorting && { order: stateRoot.params.sorting }),
            ...(stateRoot.params.showPublished && { showPublished: stateRoot.params.showPublished }),
            ...(stateRoot.params.search && { search: stateRoot.params.search }),
        }

        dispatch(ItemsActions.setSession({
            name: "filtersShortcut",
            data: DATA,
        }))

        if (_.get(match.params, 'grouped', '')) {
            history.replace(`/gallery/${region}/${_.get(match.params, 'shortcut_slug', '')}/${_.get(match.params, 'grouped', '')}/${_slug}`)
        } else {
            history.replace(`/gallery/${region}/${_.get(match.params, 'shortcut_slug', '')}/${_slug}`)
        }

        if (!next_level_name) {
            setStateComponent('list-items')
        }
    }, [stateRoot, dispatch, history, match.params, setStateComponent])

    useEffect(() => {
        let isMounted = true
        if (isMounted && isIntersecting) {
            handleLoadMore()
        }
        return () => {
            isMounted = false
        }
    }, [isIntersecting, handleLoadMore])

    return (
        <Box component="section" className={classes.container}>
            <ul className={classes.items}>
                {
                    stateRoot.data.length === 0 && !stateRoot.loading && (
                        <SimpleAlertMessage
                            severity="info"
                            alertTitle="Ops!"
                            message={t("common:Não há metadados a serem mostrados nesta galeria.")}
                        />
                    )
                }
                {
                    stateRoot.loading
                        ? Utils.getSekeletonLi()
                        : stateRoot.data.map((item, index) => (
                            <Card
                                updatedAt={_.get(item, 'updated_at', '')}
                                count={_.get(item, 'count', '')}
                                wrapperSlug={_.get(item, 'slug', '')}
                                key={index}
                                imgJPG={_.get(item, 'thumbnails.thumbnail_card_original', '')}
                                imgWEBP={_.get(item, 'thumbnails.thumbnail_card_webp', '')}
                                title={_.get(item, 'name', '')}
                                region={_.get(item, 'region', '')}
                                handleClickOverride={() => handleClickOverride(item)}
                                isGroupingCards
                            />
                        ))
                }
                <div ref={ref} />
            </ul>
        </Box>
    )
}

export default GroupingCards
