import { pick } from "ramda"
import AppActions from "../flux/actions/AppActions"
import { createSelector } from "reselect"

const getStateData = (appReducer) => appReducer.data

const clientDataSelector = createSelector(getStateData, (data) => ({
  ...pick(["name", "description", "primaryColor"], data),
}))

export const mapStateToPropsToDam = ({ appReducer }) => {
  return {
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
    pathClient: appReducer.pathClient,
    supportsWebp: appReducer.supportsWebp,   
    hasInvalidRegion: appReducer.hasInvalidRegion,
    data: clientDataSelector(appReducer),
    region: appReducer.userData.region,
    markedItems: appReducer.markedItems,
  }
}
export const mapDispatchToPropsToDam = (dispatch) => {
  return {
    triggerError: (exception) => dispatch(AppActions.triggerError(exception)),     
    closeDialogExpiredToken: () =>
      dispatch(AppActions.openDialog("expiredToken")),
    openDialogPolicy: () => dispatch(AppActions.setUserData({ field: "current_policy_accepted", value: false })),
    bindToChannel: (channel, message, callback) =>
      dispatch(AppActions.bindToChannel(channel, message, callback)),
    clientChannelUnsubscribe: (channel) =>
      dispatch(AppActions.clientChannelUnsubscribe(channel)),    
  }
}

export const mapStateToPropsToSettings = ({ appReducer }) => {
  return {
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,   
    userId: localStorage.getItem("user_id"),
    policies: appReducer.userData.policies,
    region_id: appReducer.userData.region_id,
    client_slug: appReducer.data.client_slug,
    region: appReducer.userData.region,
    palette: appReducer.palette,
    currentPolicy: appReducer.data.currentPolicy,
    dataClientName: appReducer.data.name,
    pathClient: appReducer.pathClient,
  }
}
export const mapDispatchToPropsToSettings = (dispatch) => {
  return {
    triggerError: (exception) => dispatch(AppActions.triggerError(exception)),    
    closeDialogExpiredToken: () =>
      dispatch(AppActions.openDialog("expiredToken")),
    setHasSupportCurrentRegion: (option) => 
      dispatch(AppActions.setHasSupportCurrentRegion(option)),
  }
}
