import * as ActionTypes from "../../flux/actionTypes";
import _ from 'lodash'
import Utils from "utils/Utils"

const INITIAL_STATE = {
  uploadFiles: [],
  filesInProgress: [],
  filesInProgressLength: 0,
  finishedUploadFiles: [],
  dataProgress: [],
  postCancelData: null,
  cancelDialog: {
    show: false,
    messageKey: null
  },
  evaporate: {},
  currentItem: null,
};

const updateProcessedFilesWithContext = (uploadFiles, contextItem) =>
  uploadFiles.map((file) => {
    if(file.linkData && file.linkData.id === contextItem.id) {
      file.finishedSuccessfully = true
    }
    return file
  })

const updateProcessedFiles = (asset, item, uploadFiles, currentItem) => {
  return uploadFiles.map((file) => {
    if (file.id === asset.id) {
      file.thumbnail_card_webp = asset.thumbnail_card_webp;
      file.thumbnail_card_original = asset.thumbnail_card_original;
      file.updated_at = asset.updated_at;
      file.status_pipeline = asset.status_pipeline;

      if (asset.status_integration) {
        file.status_integration = asset.status_integration

        if(asset.status_integration === 'REPROCESS') {
          file.status_view = 'reprocess'
          file.reprocessing = true
        }

        if(asset.status_integration === 'WAITING_TO_ADD') {
            file.status_view = 'waiting-to-add'
            file.related_item = true
        }

        if(asset.status_integration === 'WAITING_FOR_DATA') {
          file.status_view = 'waiting-for-data'
          file.related_item = true
      }

      if(asset.status_integration === 'DISPATCHED') {
          file.status_view = 'dispatched'
        }

        if(asset.status_integration === 'DONE' && item && item.category && item.segment && item.division && item.slug) {
          if(Utils.isSameFileContextProcessed(item, currentItem)) {
            file.finishedSuccessfully = true
          }
          file.linkData = item
          file.status_view = file.related_item ? 'associated-item' : 'created-item'
        }

        if(asset.status_integration === 'ERROR' && !asset.reprocessing) {
          file.status_view = 'integration-error'
          file.error = true;
          file.dateError = Utils.getDate("YYYY-MM-DD HH:mm:ss");
        }

      } else {
        if (asset.status_pipeline === 'THUMB_GENERATED' || asset.status_pipeline === 'DONE') {
          file.finishedSuccessfully = true
        } else {
          file.status_view = 'pipeline-error'
          file.error = true;
          file.error_msg = asset.error_msg;
          file.dateError = Utils.getDate("YYYY-MM-DD HH:mm:ss");
        }
      }
    }

    return file;
  })
}

export const uploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_EVAPORATE_REDUCER:
      return {
        ...state,
        evaporate: {
          ...(!_.isEmpty(state.evaporate) && { ...state.evaporate }),
          ...(Boolean(action.payload.entity) && {
            [action.payload.entity]: action.payload.evaporate
          })
        }
      }

    case ActionTypes.SHOW_UPLOAD_CANCEL_DIALOG:
      return {
        ...state,
        cancelDialog: {
          show: true,
          messageKey: action.messageKey
        },
        postCancelData: action.postCancelData,
      };

    case ActionTypes.HIDE_UPLOAD_CANCEL_DIALOG:
      return {
        ...state,
        cancelDialog: {
          show: false,
          messageKey: null
        },
        postCancelData: null,
      };

    case ActionTypes.ADD_UPLOAD_FILES:
      return {
        ...state,
        uploadFiles: [...action.files, ...state.uploadFiles],
        filesInProgress: [...state.filesInProgress, ...action.files.map(i => ({path_file: i.path_file, entity: i.entity}))],
        filesInProgressLength: state.filesInProgress.length + action.files.length
      };

    case ActionTypes.UPLOAD_FILE_FINISHED:
      return {
        ...state,
        uploadFiles: state.uploadFiles.map((file) => {
          if (file.id === action.data.file.id) {
            file.uploadFinished = true;
            file.status_view = 'upload-complete'
          }

          return file;
        }),
        filesInProgress: state.filesInProgress.filter(
          (path) => path.path_file !== action.data.file.path_file
        ),
        filesInProgressLength: (state.filesInProgressLength + 1)
      };

    case ActionTypes.UPLOAD_FILE_PROCESSED:
      const { data, item } = action.data
      const newFiles = updateProcessedFiles(data, item, state.uploadFiles, state.currentItem);

      return {
        ...state,
        uploadFiles: newFiles,
        finishedUploadFiles: newFiles.filter((i) => i.finishedSuccessfully),
      };

    case ActionTypes.UPLOAD_FILE_ERROR:
      const dataFile = { ...action.data.file, error_msg: action.data.error}
      return {
        ...state,
        uploadFiles: updateProcessedFiles(dataFile, null, state.uploadFiles, state.currentItem),
        filesInProgressLength: (state.filesInProgressLength - 1)
       };

    case ActionTypes.CLEAR_UPLOAD_FILES:
      return {
        ...state,
        uploadFiles: [],
        filesInProgress: [],
        filesInProgressLength: 0,
        finishedUploadFiles: [],
      };

    case ActionTypes.UPLOAD_FILES_CANCELED_ALL:
      return {
        ...state,
        uploadFiles: state.uploadFiles.filter(item => !Utils.isOrdinaryAssetUpload(item.entity)),
        filesInProgress: state.filesInProgress.filter(item => !Utils.isOrdinaryAssetUpload(item.entity)),
        filesInProgressLength: state.filesInProgress.filter(item => !Utils.isOrdinaryAssetUpload(item.entity)).length,
        postCancelData: null,
        cancelAll: false,
        cancelDialog: {
          show: false,
          messageKey: null
        },
      };

    case ActionTypes.UPLOAD_FILE_REMOVE:
      return {
        ...state,
        uploadFiles: state.uploadFiles.filter((item) => item.id !== action.item.id),
        filesInProgress: state.filesInProgress.filter(
          (item) => item.path_file !== action.item.path_file
        ),
        filesInProgressLength: state.filesInProgress.filter(
          (item) => item.path_file !== action.item.path_file
        ).length,
        finishedUploadFiles: state.finishedUploadFiles.filter(
          (item) => item.path_file !== action.item.path_file
        ),
      };

    case ActionTypes.UPLOAD_PAUSED:
      return {
        ...state,
        uploadFiles: state.uploadFiles.map((file) => {
          if (file.id === action.id) {
            file.upload_paused = action.pause;
          }
          return file;
        }),
      };

    case ActionTypes.UPLOAD_ALL_FILES_PAUSED:
      return {
        ...state,
        uploadFiles: state.uploadFiles.map((file) => {
          if (!file.uploadFinished) {
            file.upload_paused = action.pause;
          }
          return file;
        }),
      };

    case ActionTypes.ADD_FILE_REPROCESS:
      const reprocessFile = {
        reprocessing: true,
        uploadFinished: true,
        entity: action.file.entity ?? 'asset',
        name: action.file.client_name,
        error: false,
        id: action.file.id,
        path: action.file.client_name,
        status_integration: 'REPROCESS',
        type: action.file.extension,
        updated_at: action.file.updated_at,
        error_msg: action.file.status_integration_message,
        thumbnail_card_webp: action.file.thumbnail_card_webp || action.file.thumbnail_card_original,
        thumbnail_card_original: action.file.thumbnail_card_webp || action.file.thumbnail_card_original,
      }

      const newFilesWithReprocessing
        = updateProcessedFiles(reprocessFile, null, [...state.uploadFiles, reprocessFile], state.currentItem)

      return {
        ...state,
        uploadFiles: newFilesWithReprocessing,
        filesInProgress: [...state.filesInProgress, {path_file: reprocessFile.id, entity: reprocessFile.entity}],
        filesInProgressLength: state.filesInProgress.length + 1
      }

    case ActionTypes.UPDATE_FILE_REPROCESS:
      return {
        ...state,
        uploadFiles: updateProcessedFiles(action.file, null, state.uploadFiles, state.currentItem)
      }

    case ActionTypes.REMOVE_FILE_REPROCESS:
      return {
        ...state,
        uploadFiles: state.uploadFiles.filter(f => f.id !== action.file.id)
      }

      case ActionTypes.SET_DATA_PROGRESS_FILE:
        return {
            ...state,
            dataProgress: [...state.dataProgress, action.data].map(file => {
              if (file.id === action.data.id) {
                file.value = action.data.value
              }
              return file
          })
        }
    case ActionTypes.SET_ITEM_CONTEXT:
      return {
        ...state,
        currentItem: action.item,
        uploadFiles: updateProcessedFilesWithContext(state.uploadFiles, action.item)
      }

    case ActionTypes.CLEAR_ITEM_CONTEXT:
      return {
        ...state,
        currentItem: null
      }
    default:
      return state;
  }
};
