import React, { useState, useCallback } from "react"
import _ from 'lodash'
import { useTranslation } from "react-i18next"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core"

import BoxSchemas from '../BoxSchemas';

import { useStyles } from "./styles"

const DialogChangeSchemas = ({ children, onChangeSchemas, schemaSlected = {} }) => {
  const [state, setState] = useState({
    open: false, schemas: _.isEmpty(schemaSlected) ? [] : [{
      value: _.get(schemaSlected, "id", _.get(schemaSlected, "value", "")),
      label: _.get(schemaSlected, "name", _.get(schemaSlected, "label", ""))
    }]
  })
  const { t } = useTranslation()
  const classes = useStyles()

  const show = useCallback(() => () => {
    setState(prev => ({ ...prev, open: true }))
  }, [])

  const hide = useCallback(() => {
    setState(prev => ({ ...prev, open: false }))
  }, [])

  const handleChangeSchema = useCallback((values = []) => {
    const schemas = Array.isArray(values) ? values : []
    setState(prev => ({ ...prev, schemas }), onChangeSchemas(_.get(schemas, "[0]", {})))
  }, [onChangeSchemas])

  return (
    <React.Fragment>
      {children(show)}
      {state.open && (
        <Dialog
          open={state.open}
          onClose={hide}
          className={classes.root}
          maxWidth="sm"
          PaperProps={{ style: { overflowY: 'visible' } }}
        >
          <DialogTitle style={{ padding: 0, marginBottom: 16 }}>
            {t("manager:Incluir Esquema de Metadados")}
          </DialogTitle>
          <DialogContent style={{ padding: '0', overflowY: 'visible' }}>
            <Typography variant='body2' style={{ marginBottom: 20 }}>
              {t('manager:Defina qual esquema será considerado neste atributo.')}
            </Typography>
            <BoxSchemas
              defaultData={state.schemas.map(i => ({ value: i.value, label: i.label }))}
              selectedLimit={state.schemas.length}
              handleChange={(values) => handleChangeSchema(values)} />
          </DialogContent>
          <DialogActions style={{ padding: 0, marginTop: 16 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={hide}
            >
              {t(`common:Fechar`)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default DialogChangeSchemas
