import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import _ from 'lodash'

import SelectAsync from './SelectAsync'

const RelatedItemsComboBox = ({ handleChange, selectedLimit = 0, selectionLimit = 30, defaultData = [] }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { requestHeaders, pathServer } = useSelector(
    (state) => state.appReducer
  );
  const [loading, setLoading] = useState(false);
 
  const loadOptions = _.debounce((inputValue = '', callback) => {
    if(inputValue.length > 1) {
    setLoading(true);
      axios
        .get(`${pathServer}/alternative-name/query/${inputValue}`, requestHeaders)
        .then(function (resp) {
          const DATA = resp.data.items.map(i => ({
            value: i.id,
            label: i.title
          }))
          callback(DATA)
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error ao carregar itens: ", err);
          const message = err.response
            ? err.response.data.message || err.response.headers["x-message"]
            : t("Erro inesperado. Contate o suporte");
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        })
      }
  }, 2000);


  return (
    <React.Fragment>
      <SelectAsync 
        title={t("manager:Nomes alternativos")}
        loading={loading}        
        loadOptions={loadOptions}
        handleChange={handleChange}
        selectedLimit={selectedLimit}
        selectionLimit={selectionLimit}
        defaultData={defaultData}               
      />        
    </React.Fragment>
  );
};

export default RelatedItemsComboBox;
