/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable  no-unused-vars*/
import ComboBox from "./index.js";
import connect from "react-redux/es/connect/connect";
import { mapDispatchToPropsToSettings, mapStateToPropsToSettings } from "../../utils/Flux";
import { withTranslation } from 'react-i18next';

class CategoryComboBox extends ComboBox {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      entity: "category",
      title: props.title ? props.title : props.t("Categoria"),
      disabled: this.props.isDisabled,
      field_required: this.props.field_required
    }
  }

  componentDidMount() {
    super.componentDidMount.apply(this);
    this.getData(this.props.onLoadDataCallback);
  }

  getFormParams() {
    return {
      only: ["id", "name", "canLink", "isGalleryType"],
      ...(this.props.notGalleryType && { isGalleryType: false })
    };
  }

  getSelectValue(object) {
    const values = super.getSelectValue(object);
    return { ...values, canLink: object.canLink || false, isGalleryType: object.isGalleryType }
  }
}

export default connect(mapStateToPropsToSettings, mapDispatchToPropsToSettings)(withTranslation(['common'])(CategoryComboBox));
