import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  list_root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%' 
  },
  list_item: {
    "& .MuiListItemAvatar-root": {
      marginRight: 10
    },
    "& .MuiTypography-root": {
      padding: '0 8px'
    },
    "& #skeletonResponsive": {
      borderRadius: 4
    },
    '& div.info': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: "100%",    
      color: "#666",      
    },
    "& .icon": {
      marginTop: 3,
      marginLeft: 5,
      cursor: 'pointer',
      "& svg": {
        color: "#666"
      }
    }
  },  
  row_info: {
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& .actions": {
      display: 'flex'
    },
    "& p.MuiTypography-body1": {
      fontSize: '0.875rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
      color: "#666"
    },
    "& .icon": {
      marginTop: 3,
      marginLeft: 5,
      cursor: 'pointer',
      "& svg": {
        color: "#666"
      }
    }
  },  
  error: {    
    "& p, svg": {
      color: "#F4511E !important"
    }    
  },
}));
