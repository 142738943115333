import React from "react"
import TablePagination from "@material-ui/core/TablePagination"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"

const CustomFooterTable = ({
  rowsLength = 0,
  rowsPerPage,
  page = 0,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  isNotPageOne
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  
  return (
    <TablePagination
      className={classes.tablePagination}
      rowsPerPageOptions={[10, 20, 30]}
      component="div"
      count={rowsLength}
      rowsPerPage={rowsPerPage}
      page={isNotPageOne ? page : page - 1 }
      onChangePage={(evt, page_) => !loading ? handleChangePage(isNotPageOne ? page_ : page_ + 1): {}}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelRowsPerPage={`${t("common:Registros por página")}:`}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} ${t("common:de")} ${count}`
      }
    />
  )
}

export default CustomFooterTable
