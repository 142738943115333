import React from "react"

import { settings } from "./_settings.js"

import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ColorPicker from './ColorPicker'

import axios from "axios"
import _ from "lodash"

import { withSnackbar } from "notistack"
import { compose } from "recompose"

import {
  Grid,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Radio,
  RadioGroup
} from "@material-ui/core";

import DialogConfirm from "components/Dialogs/DialogConfirm"
import DialogDeleteValidation from "components/Dialogs/DeleteValidationPhrase"

import Settings from 'utils/Settings'
import BackgroundImageUploader from "./BackgroundImageUploader.js"

class Form extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      fields: [
        "is3d",
        "pdf",
        "image",
        "name",
        "video"
      ],
      hasData: true,
      data: {
        is3d: {
          copy: false
        },
        pdf: {
          copy: false
        },
        video: {
          copy: false
        },
        isImage: false,
        image: {
          dimensions: {
            method: 'proportional',
            strategy: 'adaptive'
          },
          format: '',
          type: '',
          background: '',
          background_image: '',
          background_path: '',
          resolution: {
            x: '',
            y: '',
          },
          colorspace: {
            profile: '',
          },
          compression: {
            quality: '',
            depth: '',
            strip: false,
            quant: false,
            type: '',
          }
        },
        name: {
          pt: '', en: '', es: ''
        },
      },
      dialogs: {
        updateDialogOpen: false,
      },
      formErrors: {},
      fieldBackground: 'default'
    }

    this.handleChangeImage = this.handleChangeImage.bind(this)

    this.IS_CREATE = props.typeForm === "create"
  }

  componentDidMount() {
    const ID = this.props.match.params.id
    this.setState((prev) => ({
      ...prev,
      id: ID,
    }))
    if (ID) this.getData(ID)
  }

  getData(id) {
    const SELF = this
    const { requestHeaders, pathClient } = SELF.props

    axios
      .get(`${pathClient}/${SELF.state.entity}/${id}`, requestHeaders)
      .then(
        function (resp) {
          let background = 'default'

          if (_.get(resp, 'data.item.image.background', '') === 'transparent') {
            background = 'transparent'
          }

          if (/^#[0-9A-F]{6}$/i.test(_.get(resp, 'data.item.image.background', ''))) {
            background = 'color'
          }

          if (_.get(resp, 'data.item.image.background_path', '')) {
            background = 'background_image'
          }

          SELF.setState((prev) => ({
            ...prev,
            data: {
              ...prev.data,
              ..._.get(resp, 'data.item', {}),
              image: {
                ..._.get(resp, 'data.item.image', {}),
                dimensions: {
                  ..._.get(resp, 'data.item.image.dimensions', {}),
                  ...(!_.get(resp, 'data.item.image.dimensions.method') && { method: 'proportional' })
                }
              },
              is3d: _.get(resp, 'data.item.3d', { copy: false }),
              pdf: _.get(resp, 'data.item.pdf', { copy: false }),
              video: _.get(resp, 'data.item.video', { copy: false }),
              isImage: !_.isEmpty(_.get(resp, 'data.item.image', {})),
            },
            fieldBackground: background,
          }))
        },
        function (error) {
          SELF.setState({ ...SELF.state, loading: false })
          SELF.showMessage(error.response.headers["x-message"], 'error')
        }
      )
      .catch((err) => console.log(err))
  }

  getParamsAxios() {
    const { pathClient } = this.props

    return {
      method: this.IS_CREATE ? "post" : "put",
      url: this.IS_CREATE
        ? `${pathClient}/${this.state.entity}`
        : `${pathClient}/${this.state.entity}/${this.state.id}`,
    }
  }

  validateForm(parameters) {
    const { t } = this.props
    let formValid = true
    let formErrors = {}

    if (_.get(parameters, "name.pt", '').length === 0 && this.IS_CREATE) {
      formErrors.name_pt = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (_.get(parameters, "name.en", '').length === 0 && this.IS_CREATE) {
      formErrors.name_en = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (_.get(parameters, "name.es", '').length === 0 && this.IS_CREATE) {
      formErrors.name_es = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    this.setState({ ...this.state, formErrors, formValid })

    return formValid
  }

  showMessage = (message, type) => {
    const params = type === "success"
      ? { ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS }
      : {
        variant: type,
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }

    this.props.enqueueSnackbar(message, params)
  }

  submitForm(e) {
    e.preventDefault()
    const SELF = this
    const { t, requestHeaders } = SELF.props
    const parameters = SELF.getParameters()

    let formValid = SELF.validateForm(parameters)

    if (!formValid) {
      return
    }
    const IS_CREATE = this.props.typeForm === "create"

    if (!IS_CREATE) {
      delete parameters["title"]
    }

    const paramsAxios = this.getParamsAxios()

    SELF.setState({ ...SELF.state, loading: true })

    axios({
      method: paramsAxios.method,
      url: paramsAxios.url,
      data: parameters,
      headers: { ...requestHeaders.headers },
    })
      .then(
        function (resp) {
          SELF.showMessage(resp.headers["x-message"], 'success')
          SELF.props.history.push(`/manager/${SELF.state.route}`)
        },
        (err) => {
          if (err.response.status === 422) {
            const message = err.response
              ? t(err.response.data.slug) || err.response.data.message || err.response.headers["x-message"]
              : t("Erro inesperado. Contate o suporte")
            SELF.showMessage(message, 'error')
            SELF.setState({
              ...SELF.state,
              loading: false,
              dialog: {
                open: true,
                data: err.response.data
              }
            })
          } else {
            SELF.responseError(err)
          }
        }
      )
      .catch(function (error) {
        SELF.setState({ ...SELF.state, loading: false })
        SELF.showMessage(t("common:Erro ao processar, contate o suporte"), 'error')
      })
  }


  insert(evt) {
    evt.preventDefault()
    if (this.IS_CREATE) {
      this.submitForm(evt)
    } else {
      this.setState((prev) => ({
        ...prev,
        dialogs: {
          ...prev.dialogs,
          updateDialogOpen: true,
        },
      }))
    }
  }

  handleInsert = async (evt) => {
    evt.persist()
    await this.handleClose("updateDialogOpen")
    await this.submitForm(evt)
  }

  handleChangeCheked(evt, field) {
    const value = evt.target.checked

    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [field]: {
          copy: value
        }
      },
    }))
  }

  handleChangeName(evt, field) {
    const value = evt.target.value

    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        name: {
          ...prev.data.name,
          [field]: value
        }
      },
    }))
  }

  handleChangeChekedImage(evt) {
    const value = evt.target.checked

    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        isImage: value
      },
    }))
  }

  handleChangeCheckedOptions(evt, group, field) {
    const value = evt.target.checked

    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        image: {
          ...prev.data.image,
          [group]: {
            ...prev.data.image[group],
            [field]: value
          }
        }
      },
    }))
  }

  handleChangeOptions(evt, group, field) {
    const value = evt.target.value

    if (field === 'quality' && (value < 0 || value > 100)) {
      return
    }

    if ((field === 'width' || field === 'height') && parseInt(value) < 0) {
      return
    }

    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        image: {
          ...prev.data.image,
          [group]: {
            ...prev.data.image[group],
            [field]: value
          }
        }
      },
    }))

    console.log(this.state.data.image)
  }

  handleChangeImage(evt, field) {
    const value = evt.target.value

    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        image: {
          ...prev.data.image,
          [field]: value
        }
      }
    }))
  }

  handleCheckBackground(name) {
    if (name === 'background_image') {
      this.setState((prev) => ({
        ...prev,
        fieldBackground: name,
        data: {
          ...prev.data,
          image: {
            ...prev.data.image,
            background: "",
          }
        }
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        fieldBackground: name,
        data: {
          ...prev.data,
          image: {
            ...prev.data.image,
            background_image: ""
          }
        }
      }))
    }
  }

  getParameters() {
    const SELF = this
    let { data } = SELF.state

    let params = {
      name: { ...data.name }
    }

    const removeEmpty = (option) => {
      let data = {}

      Object.entries(option).forEach(([name, value]) => {
        if (Boolean(value) && value !== 'item_taxonomy') {
          if ((typeof value === 'string' && parseInt(value) > 0) || value) {
            data[name] = value
          }
        }
      })

      return data
    }

    const setValues = (item) => {
      let _data = {}

      if (typeof item === 'string') {
        return item
      }

      Object.entries(item).forEach(([name, value]) => {
        if (Boolean(value)) {
          _data[name] = value
        }
      })

      return _data
    }

    Object.entries(data).forEach(([key, item]) => {
      if (Boolean(item.copy) && item.copy) {
        params[key] = item
      }
      if (key === 'image') {
        Object.entries(item).forEach(([name, value]) => {
          if (Boolean(value)) {
            const values = setValues(value)

            if (typeof values === 'string' && values !== 'item_taxonomy') {
              params[key] = {
                ...params[key],
                [name]: values
              }
            }

            if (typeof values === 'object' && !_.isEmpty(values)) {
              const hasValue = removeEmpty(values)
              if (!_.isEmpty(hasValue)) {
                params[key] = {
                  ...params[key],
                  [name]: hasValue
                }
              }
            }
          }
        })
      }
    })

    if (data.is3d.copy) {
      params['3d'] = data.is3d
      delete params.is3d
    } else {
      delete params['3d']
    }

    if (!this.state.data.isImage) {
      delete params.image
    }

    if (!this.IS_CREATE) {
      delete params.name
    }

    if ((_.get(data, 'image.dimensions.width', '0') === '0') && (_.get(data, 'image.dimensions.height', '0') === '0')) {
      delete params.image
    }

    if ((!_.isEmpty(data, 'image.background_path', ''))) {
      delete params.image.background_path
    }

    return params
  }

  getDisabledSubmit() {
    const { loading, data: { is3d, pdf, isImage, video } } = this.state

    if (loading) {
      return loading
    }

    if (!is3d.copy && !pdf.copy && !isImage && !video.copy) {
      return true
    }
  }

  handleClose = (dialog) => {
    this.setState((prev) => ({
      ...prev,
      dialogs: {
        ...prev.dialogs,
        [dialog]: false,
      },
    }))
  }

  delete = () => {
    const SELF = this
    const { pathClient, requestHeaders } = SELF.props
    SELF.setState({ ...SELF.state, loading: true })

    axios
      .delete(
        `${pathClient}/${SELF.state.entity}/${SELF.state.id}`,
        requestHeaders
      )
      .then(
        function (resp) {
          SELF.setState(
            {
              ...SELF.state,
              loading: false,
            },
            () => SELF.props.history.push(`/manager/${SELF.state.route}`)
          )
          SELF.showMessage(resp.headers["x-message"], 'success')
        },
        (resp) => {
          SELF.setState({ ...SELF.state, loading: false })
          SELF.showMessage(resp.headers["x-message"], 'error')
        }
      )
      .catch((err) => {
        SELF.showMessage(SELF.props.t("Erro ao deletar os dados, contate o administrador."), 'error')
      })
  }


  getForm() {
    const SELF = this
    const { t } = SELF.props
    const { data, dialogs } = SELF.state

    const OPTIONS = [
      { id: 'item_taxonomy', value: 'Manter original', label: 'Manter original' },
      { id: 0, value: 'imagick::COMPRESSION_UNDEFINED', label: 'COMPRESSION_UNDEFINED' },
      { id: 1, value: 'imagick::COMPRESSION_NO', label: 'COMPRESSION_NO' },
      { id: 2, value: 'imagick::COMPRESSION_BZIP', label: 'COMPRESSION_BZIP' },
      { id: 3, value: 'imagick::COMPRESSION_DXT1', label: 'COMPRESSION_DXT1' },
      { id: 4, value: 'imagick::COMPRESSION_DXT3', label: 'COMPRESSION_DXT3' },
      { id: 5, value: 'imagick::COMPRESSION_DXT5', label: 'COMPRESSION_DXT5' },
      { id: 6, value: 'imagick::COMPRESSION_FAX', label: 'COMPRESSION_FAX' },
      { id: 7, value: 'imagick::COMPRESSION_GROUP4', label: 'COMPRESSION_GROUP4' },
      { id: 8, value: 'imagick::COMPRESSION_JPEG', label: 'COMPRESSION_JPEG' },
      { id: 9, value: 'imagick::COMPRESSION_JPEG2000', label: 'COMPRESSION_JPEG2000' },
      { id: 10, value: 'imagick::COMPRESSION_LOSSLESSJPEG', label: 'COMPRESSION_LOSSLESSJPEG' },
      { id: 11, value: 'imagick::COMPRESSION_LZW', label: 'COMPRESSION_LZW' },
      { id: 12, value: 'imagick::COMPRESSION_RLE', label: 'COMPRESSION_RLE' },
      { id: 13, value: 'imagick::COMPRESSION_ZIP', label: 'COMPRESSION_ZIP' },
    ]


    const disabledFieldProportinal = ((_.get(this.state, 'data.image.dimensions.width', 0)
                                     + _.get(this.state, 'data.image.dimensions.height', 0)) <= 0)
    const disableResizeStrategy = _.get(data, 'image.dimensions.method', null) !== 'filled'
    const strategy = _.get(this.state, 'data.image.dimensions.strategy', 'adaptive')


    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginBottom: 20, display: "flex", justifyContent: "space-between" }}>
          <Typography variant='h5'>
            {this.IS_CREATE ? t("common:Criar") : t("common:Editar")}
          </Typography>

          <Typography variant='body2'>
            {`*${t('common:Campo obrigatório')}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body2' style={{ marginBottom: 20 }}>
            {t("manager:Crie a sua própria especificação de imagens para distribuição CDN, para downloads e compartilhamentos em categorias. Dúvidas entre em contato com suporte@yapoli.com.")}.
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={_.get(data, 'is3d.copy', false)}
                  onChange={evt => this.handleChangeCheked(evt, 'is3d')}
                  color="primary"
                />
              }
              label="3D"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={_.get(data, 'pdf.copy', false)}
                  color="primary"
                  onChange={evt => this.handleChangeCheked(evt, 'pdf')}
                />
              }
              label="PDF"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={_.get(data, 'video.copy', false)}
                  onChange={evt => this.handleChangeCheked(evt, 'video')}
                  color="primary"
                />
              }
              label={t("manager:Vídeo")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.isImage}
                  onChange={evt => this.handleChangeChekedImage(evt)}
                  color="primary"
                />
              }
              label={t("manager:Imagem")}
            />
          </FormGroup>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name-pt"
              variant="outlined"
              fullWidth
              id="name-pt"
              label={`${t("manager:Nome em português")} *`}
              value={_.get(data, 'name.pt', '')}
              onChange={evt => this.handleChangeName(evt, 'pt')}
              disabled={!this.IS_CREATE}
              error={Boolean(this.state.formErrors.name_pt)}
              helperText={this.state.formErrors.name_pt ? this.state.formErrors.name_pt : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name-pt"
              variant="outlined"
              fullWidth
              id="name-pt"
              label={`${t("manager:Nome em inglês")} *`}
              value={_.get(data, 'name.en', '')}
              onChange={evt => this.handleChangeName(evt, 'en')}
              disabled={!this.IS_CREATE}
              error={Boolean(this.state.formErrors.name_en)}
              helperText={this.state.formErrors.name_en ? this.state.formErrors.name_en : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name-pt"
              variant="outlined"
              fullWidth
              id="name-pt"
              label={`${t("manager:Nome em espanhol")} *`}
              value={_.get(data, 'name.es', '')}
              onChange={evt => this.handleChangeName(evt, 'es')}
              disabled={!this.IS_CREATE}
              error={Boolean(this.state.formErrors.name_es)}
              helperText={this.state.formErrors.name_es ? this.state.formErrors.name_es : ''}
            />
          </Grid>
        </Grid>

        {
          _.get(data, 'is3d.copy', false) &&
          <Grid item xs={12}>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {t("common:3D")}
            </Typography>
            <Typography variant='body2'>
              {t("manager:Os ativos em 3D seguirão as especificações do arquivo original.")}
            </Typography>
          </Grid>
        }

        {
          _.get(data, 'pdf.copy', false) &&
          <Grid item xs={12}>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {t("common:PDF")}
            </Typography>
            <Typography variant='body2'>
              {t("manager:Os ativos em PDF seguirão as especificações do arquivo original.")}
            </Typography>
          </Grid>
        }

        {
          _.get(data, 'video.copy', false) &&
          <Grid item xs={12}>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {t("common:Vídeo ")}
            </Typography>
            <Typography variant='body2'>
              {t("manager:Os ativos de vídeos seguirão as especificações do arquivo original.")}
            </Typography>
          </Grid>
        }

        {
          data.isImage &&
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant='body2' style={{ fontWeight: 500, marginBottom: 30 }}>
              {`${t("manager:Imagem")} (JPG e PNG)`}
            </Typography>

            <Grid style={{ justifyContent: 'flex-end' }} container spacing={2}>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="format">{t("manager:Extensão")}</InputLabel>
                  <Select
                    labelId="format"
                    id="format"
                    value={_.get(data, 'image.format', 'item_taxonomy')}
                    onChange={evt => {
                      const newData = {
                        ...this.state,
                        fieldBackground: 'default',
                        data: {
                          ...this.state.data,
                          image: {
                            ...this.state.data.image,
                            format: evt.target.value
                          }
                        }
                      }
                      delete newData.data.image.background
                      this.setState(newData)
                    }}
                    label="Extensão"
                  >
                    <MenuItem value={'item_taxonomy'}>{t("manager:Manter original")}</MenuItem>
                    <MenuItem value={"png"}>PNG</MenuItem>
                    <MenuItem value={"jpg"}>JPG</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="compression.quality"
                  variant="outlined"
                  id="compression.quality"
                  type='number'
                  label={t("manager:Qualidade (de 0 a 100)")}
                  value={_.get(data, 'image.compression.quality', 0)}
                  onChange={evt => this.handleChangeOptions(evt, 'compression', 'quality')}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  name="dimensions.width"
                  variant="outlined"
                  fullWidth
                  id="dimensions.width"
                  label={`${t("manager:Largura")} (px)`}
                  type='number'
                  value={_.get(data, 'image.dimensions.width', 0)}
                  onChange={evt => this.handleChangeOptions(evt, 'dimensions', 'width')}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  name="dimensions.height"
                  variant="outlined"
                  fullWidth
                  id="dimensions.height"
                  type='number'
                  label={`${t("manager:Altura")} (px)`}
                  value={_.get(data, 'image.dimensions.height', 0)}
                  onChange={evt => this.handleChangeOptions(evt, 'dimensions', 'height')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="format">{t("manager:Modo de cor")}</InputLabel>
                  <Select
                    labelId="format"
                    id="format"
                    value={_.get(data, 'image.colorspace.profile', 'item_taxonomy')}
                    onChange={evt => this.handleChangeOptions(evt, 'colorspace', 'profile')}
                    label="Modo de cor"
                  >
                    <MenuItem value={'item_taxonomy'}>{t("manager:Manter original")}</MenuItem>
                    <MenuItem value={'adobe'}>Adobe RGB 1998</MenuItem>
                    <MenuItem value={'sRGB'}>sRGB</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <Typography variant="body2">
                    {t('manager:Como gostaria de redimensionar?')}
                  </Typography>
                  <RadioGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <FormControlLabel
                      disabled={disabledFieldProportinal}
                      label={t('manager:Proporcionalmente')}
                      control={
                        <Radio
                          color='primary'
                          value='proportional'
                          checked={_.get(this.state, 'data.image.dimensions.method', '') === 'proportional'}
                          onClick={(evt) => this.handleChangeOptions(evt, 'dimensions', 'method')}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disabledFieldProportinal}
                      label={t('manager:Medidas exatas')}
                      control={
                        <Radio
                          color='primary'
                          value='filled'
                          checked={_.get(this.state, 'data.image.dimensions.method', '') === 'filled'}
                          onClick={(evt) => this.handleChangeOptions(evt, 'dimensions', 'method')}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="format">{t("manager:Profundidade")}</InputLabel>
                  <Select
                    labelId="format"
                    id="format"
                    value={_.get(data, 'image.compression.depth', 'item_taxonomy')}
                    onChange={evt => this.handleChangeOptions(evt, 'compression', 'depth')}
                    label="Profundidade"
                  >
                    <MenuItem value={'item_taxonomy'}>{t("manager:Manter original")}</MenuItem>
                    <MenuItem value={8}>8 bit</MenuItem>
                    <MenuItem value={16}>16 bit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <Typography variant="body2">
                    {t('manager:Estratégia de redimensionamento')}:
                  </Typography>
                  <RadioGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <FormControlLabel
                      disabled={disableResizeStrategy}
                      label={t('manager:Adaptiva')}
                      control={
                        <Radio
                          color='primary'
                          value='adaptive'
                          checked={strategy === 'adaptive'}
                          onClick={(evt) => this.handleChangeOptions(evt, 'dimensions', 'strategy')}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableResizeStrategy}
                      label={t('manager:Automática')}
                      control={
                        <Radio
                          color='primary'
                          value='auto'
                          checked={strategy === 'auto'}
                          onClick={(evt) => this.handleChangeOptions(evt, 'dimensions', 'strategy')}
                        />
                      }
                    />
                    <FormControlLabel
                      disabled={disableResizeStrategy}
                      label={t('manager:Dinâmica')}
                      control={
                        <Radio
                          color='primary'
                          value='dynamic'
                          checked={strategy === 'dynamic'}
                          onClick={(evt) => this.handleChangeOptions(evt, 'dimensions', 'strategy')}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="format">{`${t("manager:Resolução")} (DPI)`}</InputLabel>
                  <Select
                    labelId="format"
                    id="format"
                    value={_.get(data, 'image.resolution.x', 'item_taxonomy')}
                    onChange={evt => {
                      this.handleChangeOptions(evt, 'resolution', 'x')
                      this.handleChangeOptions(evt, 'resolution', 'y')
                    }}
                    label="Resolução (DPI)"
                  >
                    <MenuItem value={'item_taxonomy'}>{t("manager:Manter original")}</MenuItem>
                    <MenuItem value={72}>72</MenuItem>
                    <MenuItem value={75}>75</MenuItem>
                    <MenuItem value={96}>96</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={150}>150</MenuItem>
                    <MenuItem value={300}>300</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex' }}>
                  <FormControl variant="outlined">
                    <Typography variant="body2">{t('manager:Plano de fundo de imagem PNG')}</Typography>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} onChange={evt => this.handleChangeImage(evt, 'background')}>
                      <FormControlLabel
                        label={t('manager:Manter original')}
                        control={<Radio onClick={() => this.handleCheckBackground('default')} checked={this.state.fieldBackground === 'default'} value='item_taxonomy' color="primary" />}
                      />
                      <FormControlLabel
                        label={t('manager:Transparente')}
                        control={<Radio onClick={() => this.handleCheckBackground('transparent')} checked={_.get(data, 'image.format', '') !== 'png' ? false : this.state.fieldBackground === 'transparent'} value='transparent' disabled={_.get(data, 'image.format', '') !== 'png'} color="primary" />}
                      />
                      <div >
                        <FormControlLabel
                          label={<ColorPicker handleChangeImage={this.handleChangeImage} checkedColor={this.state.fieldBackground === 'color'} currentColor={_.get(this.state, 'data.image.background', '')} />}
                          control={<Radio onClick={() => this.handleCheckBackground('color')} checked={this.state.fieldBackground === 'color'} color='primary' />}
                        />
                      </div>
                      <div>

                        <FormControlLabel
                          label={<BackgroundImageUploader handleChangeImage={this.handleChangeImage} checkedImage={this.state.fieldBackground === 'background_image'} currentImage={_.get(this.state, 'data.image.background_path', null)} name="Imagem de fundo" description="Selecione uma imagem de fundo" specifications="imagem" />}
                          control={<Radio onClick={() => this.handleCheckBackground('background_image')} checked={this.state.fieldBackground === 'background_image'} color="primary" />}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="format">{t("manager:Compressão")}</InputLabel>
                  <Select
                    labelId="format"
                    id="format"
                    value={_.get(data, 'image.compression.type', 'item_taxonomy')}
                    onChange={evt => this.handleChangeOptions(evt, 'compression', 'type')}
                    label="Compressão"
                  >
                    {
                      OPTIONS.map(i => <MenuItem key={i.id} value={i.id}>{t(`manager:${i.label}`)}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <div style={{ display: 'flex', marginBottom: 20 }}>
                    <Switch
                      checked={_.get(data, 'image.compression.quant', false)}
                      onChange={evt => this.handleChangeCheckedOptions(evt, 'compression', 'quant')}
                      color="primary"
                    />
                    <div>
                      <Typography variant='body1' style={{ fontWeight: 500 }}>
                        Quant
                      </Typography>
                      <Typography variant='body2'>
                        {t("manager:Processo de pngquant que utiliza algoritmo de quantização para compactar pngs")}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Switch
                      checked={_.get(data, 'image.compression.strip', false)}
                      color="primary"
                      onChange={evt => this.handleChangeCheckedOptions(evt, 'compression', 'strip')}
                    />
                    <div>
                      <Typography variant='body1' style={{ fontWeight: 500 }}>
                        Strip
                      </Typography>
                      <Typography variant='body2'>
                        {t("manager:Exclusão de metadados da imagem")}
                      </Typography>
                    </div>
                  </div>
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        }

        {_.get(dialogs, "updateDialogOpen", false) && (
          <DialogConfirm
            handleClose={() => this.handleClose("updateDialogOpen")}
            handleConfirm={this.handleInsert.bind(this)}
            title={t("Confirmação")}
            icon
            description={t('common:A alteração feita será aplicada na Receita. Deseja continuar?')}
            open={_.get(dialogs, "updateDialogOpen", false)}
          />
        )}

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          {this.props.typeForm !== "create" && (
            <DialogDeleteValidation
              entity={this.props.t(`manager:${this.state.title.singular}`)}
              name={this.state.data.title || ""}
            >
              {(confirm) => (
                <Button
                  style={{
                    height: 40,
                    width: 115,
                    marginRight: 20,
                    color: "#F4511E",
                    border: "1px solid #F4511E",
                  }}
                  variant="outlined"
                  onClick={confirm(this.delete.bind(this))}
                  disabled={this.state.loading}
                >
                  {this.props.t("common:Excluir")}
                </Button>
              )}
            </DialogDeleteValidation>
          )}
          <Button
            style={{ height: 40, width: 115, marginRight: 20 }}
            variant="outlined"
            color="primary"
            onClick={() =>
              this.props.history.push(`/manager/${this.state.route}`)
            }
          >
            {t("common:Voltar")}
          </Button>
          <Button
            style={{ height: 40, width: 115 }}
            variant="contained"
            color="primary"
            onClick={this.insert.bind(this)}
            disabled={this.getDisabledSubmit()}
          >
            {t("common:Salvar")}
          </Button>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return this.getForm()
  }
}

export const mapStateToProps = ({ appReducer }) => {
  return {
    region_id: appReducer.userData.region_id,
    region: appReducer.userData.region,
    requestHeaders: appReducer.requestHeaders,
    pathClient: appReducer.pathClient,
  }
}

export default compose(
  connect(mapStateToProps)
)(withTranslation(["manager", "common"])(withSnackbar(Form)))
