import React from 'react'
import {
    Paper,
    Typography
} from "@material-ui/core";

import { useStyles } from "./styles"

export const WrapperForms = ({ children, flexDisplay }) => {
    const classes = useStyles()

    return (
        <Paper elevation={2} style={{ padding: 20, marginBottom: 60 }}>
            <form
                noValidate
                autoComplete="off"
                className={classes.contentForm}
                style={{ display: flexDisplay && 'flex' }}
            >
                {children}
            </form>
        </Paper>
    )
}

export const WrapperPaperForms = ({ title, children, flexDisplay }) => {
    const classes = useStyles()

    return (
        <Paper elevation={2} style={{ padding: 20, marginBottom: 60 }}>
            {
                    <div className={classes.contentTitle}>
                        <Typography variant='h5'>
                            {title}
                        </Typography>
                    </div>
            }
            <form
                style={{ display: flexDisplay && 'flex' }}
                noValidate
                autoComplete="off"
                className={classes.contentForm}
            >
                {children}
            </form>
        </Paper>
    )
}