import React, { createContext, useState, useContext, useCallback } from "react"
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"
import _ from 'lodash'

const ToolDetailsContext = createContext()

export default function ToolDetailsProvider({ children }) {
  const [data, setData] = useState({ loading: false, dataComments: [] })
  const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
  const match = useRouteMatch()

  const getDataAllComments = useCallback(async () => {
    let resultData = {}   

    try {
      const resp = await axios.get(`${pathServer}/tool/${match.params.slug}/record/${match.params.id}/allComments`, requestHeaders)
      resultData.dataComments = _.get(resp, 'data.items', [])
    } catch (err) {
      resultData.dataComments = []  
      resultData.errorServer = true      
    } 

    try {
      const { data } = await axios.get(`${pathServer}/tool/${match.params.slug}/record/${match.params.id}/can/comment`, requestHeaders)
      resultData.canComment = data
    } catch (err) {    
      resultData.canComment = false 
    } 

    setData({ loading: false, ...resultData })
  }, [pathServer, requestHeaders, match.params.slug, match.params.id])

  return (
    <ToolDetailsContext.Provider
      value={{
        data,
        getDataAllComments
      }}
    >
      {children}
    </ToolDetailsContext.Provider>
  )
}

export function useDataToolDetails() {
  const {
    data,
    getDataAllComments
  } = useContext(ToolDetailsContext)

  return {
    data,
    getDataAllComments
  }
}
