import React from "react"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"
import { Grid, Button, Typography, Paper } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import HeaderMaterialDesign from "../Header/HeaderMaterialDesign/HeaderMaterialDesign"
import _ from 'lodash'
import { useStyles } from "./styles"

const AccessDenied = ({ msg, title = "Yapoli" }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const expiredToken = useSelector(state => state.appReducer.dialogs.expiredToken)

  if(_.get(history, 'location.pathname', false)) {
    sessionStorage.setItem("pathAfterLogin", history.location.pathname)
  }

  return (
    <Grid container id="dashboard">
      <Helmet>
        <title data-testid='title'>{t(`common:${title}`)}</title>        
      </Helmet>

      {
        !expiredToken &&
        <HeaderMaterialDesign title="DAM" localHistory={history} />
      }

      <Grid className={classes.main}>
        <Paper elevation={2} className={classes.content}>
          <div className={classes.logo} />

          <div className="containerText">
            <Typography data-testid='msg'>{t(`common:${msg}`)}</Typography>
          </div>
          <Button
            style={{
              width: 250,
              minHeight: 50,
              borderRadius: 4,
            }}
            variant="contained"
            color="primary"
            onClick={() => history.push("/dam")}
          >
            {t("login:Ir para o DAM")}
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AccessDenied
