import React from "react";
import { useTranslation } from 'react-i18next'

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useStyles } from "./styles";
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

const AlertDialogSimple = ({
  title,
  description,
  open,  
  handleClose,
  listNames = false,
  icon,
  children
}) => {  
  const classes = useStyles();
  const {t} = useTranslation()

  return open && (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          {icon ? icon : <ErrorRoundedIcon id="main" />}
          <div>{title}</div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: '0' }}>
        {
          !children
            ? (
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            ) : (
              <>
                {children}
              </>
            )
        }
        {
          listNames &&
          <DialogContentText>
            {listNames}
          </DialogContentText>
        }
        <div className={classes.actions}>
          <Button
            style={{ height: 40, width: 115 }}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {t("common:Fechar")}
          </Button>       
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertDialogSimple
