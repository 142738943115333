import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { useTranslation } from 'react-i18next';
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import Loading from "components/Loading";

const DialogDeleteList = ({
  open,
  handleClose,
  handleDelete,
  entity,
  name
}) => {
  const [phrase, setPhrase] = useState("");
  const [loading, setLoading] = useState(false);
  const { palette } = useSelector((state) => state.appReducer);
  const classes = useStyles({ palette });
  const { t } = useTranslation();

  const overheadHandleDelete = () => {
    setLoading(true);
    handleDelete();
  }

  const checkPhrase = t(`common:Excluir-entity`, { name: entity })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <Loading showLoading={loading} />

      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          <DeleteRoundedIcon id="main" />
          <div>{t(`common:para exclusão`, {name: entity})}</div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: "0", overflowY: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            {t(`common:Você deseja realmente excluir`, {name: name})}
            <br />
            <span style={{ fontWeight: 700 }}>
              {t("DAM:(essa ação é irreversível)")}
            </span>
          </DialogContentText>

          <TextField
            id="outlined-basic"
            label={`${t(`DAM:Escreva aqui`)} "${checkPhrase.toUpperCase()}"`}
            variant="outlined"
            style={{ width: "100%", marginTop: "10px" }}
            onChange={(evt) => setPhrase(evt.target.value)}
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {t(`common:Cancelar`)}
          </Button>
          <Button
            disabled={!(checkPhrase.toUpperCase() === phrase)}
            onClick={overheadHandleDelete}
            variant="contained"
            className={`delete ${checkPhrase.toUpperCase() === phrase ? "active" : null}`}
          >
            {`${t('common:Excluir')} ${entity}`}
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default withTranslation(["DAM"])(DialogDeleteList);
