import React from 'react';
import _ from 'lodash'
import { useTranslation } from "react-i18next"

import {
    Grid,
    Typography
} from '@material-ui/core';

import { CardAsset, CardFile } from './Cards'

function Content({ cardAsset = false, data = [], dataLoadingAsset = [], title = "", handleOnlyDownload }) {
    const { t } = useTranslation()
    
    return (
        <Grid item xs={12} className='content-cards'>
            <div className='content-cards-title sub-header'>
                <Typography variant='h6' className='p-h6'>
                    {t(`${title}`)}
                </Typography>
            </div>
            <Grid
                style={{
                    width: "100%",
                    margin: 0,
                    display: "flex",
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                {
                    data.map((item, key) => {
                        return (
                            <Grid key={_.get(item, 'id', key)}>
                                {cardAsset
                                    ? <CardAsset
                                        data={item}
                                        handleOnlyDownload={handleOnlyDownload}
                                        disabled={dataLoadingAsset.some(i => i === _.get(item, 'id', ''))}
                                    />
                                    : <CardFile data={item} />
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    );
}

export default Content
