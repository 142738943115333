import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import {
    Toolbar,
    Typography,
    useMediaQuery
} from '@material-ui/core';

import SearchInput from 'components/Input/SearchInput'
import MarkedItems from './MarkedItems/bucket'
import MenuSorting from './MenuSorting/bucket'
import MenuTypes from './MenuTypes/bucket'
import MenuByUpload from './MenuByUpload/bucket'
import MenuRangeDate from './MenuRangeDate/bucket'
import ButtonCreateItems from './ButtonCreateItem'

import { useStyles } from "./styles";
const FilterBucket = ({ data, simpleBucket = false, toolbarManagerAssets = null }) => {
    const classes = useStyles();
    const { t } = useTranslation()
    const mobile = useMediaQuery('(max-width:600px)')
    return (
        <div className={classes.rootBar} data-disableselect={true} id="rootToolBarFiltersBucket">
            <Toolbar>
                {
                    !mobile ? (
                        <>
                            <div className={classes.barFilter}>
                                <Typography variant="body2" style={{ margin: '0 12px' }}>
                                    {
                                        data.params.total +
                                        " " +
                                        (data.params.total === 1
                                            ? t("common:Arquivo")
                                            : t("common:Arquivos"))
                                    }
                                </Typography>

                                <div id="line-divider" />

                                <MenuSorting
                                    filtersParams={data.params.filters_params.order}
                                    setParametersFilters={data.func.setParametersFilters}
                                />

                                <div id="line-divider" />

                                <MenuTypes
                                    filtersParams={data.params.filters_params.fileTypes}
                                    setParametersFilters={data.func.setParametersFilters}
                                />

                                <div id="line-divider" />

                                <MenuByUpload
                                    filtersParams={data.params.filters_params.byUpload}
                                    setParametersFilters={data.func.setParametersFilters}
                                    hasIntegration={data.params.hasIntegration}
                                />

                                <div id="line-divider" />

                                <MenuRangeDate
                                    filtersParams={data.params.filters_params.rangeDate}
                                    setParametersFilters={data.func.setParametersFilters}
                                />

                            </div>
                            <div className={classes.barInputSearch}>
                                <SearchInput
                                    showClearButton
                                    placeholder={`${t("common:Buscar no Bucket")}...`}
                                    handleSubmitSearch={data.func.handleSubmitSearch}
                                    value={''}
                                />
                            </div>
                            <div className={classes.barActionsItens}>
                                {
                                    !simpleBucket && (
                                        <ButtonCreateItems
                                            createItemByBucket={() => data.func.handleCreateItem()}
                                            bucket
                                        />
                                    )
                                }
                                <MarkedItems
                                    handleCreateItem={data.func.handleCreateItem}
                                    checkedValue={data.params.checkedValue}
                                    handleViewItens={data.func.handleViewItens}
                                    getData={data.func.getData}
                                    simpleBucket={simpleBucket}
                                />
                                {
                                    (simpleBucket && toolbarManagerAssets) && toolbarManagerAssets
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.barFilter} style={{ padding: '8px 0' }}>
                                <Typography variant="body2" style={{ margin: '0 12px' }}>
                                    {
                                        data.params.total +
                                        " " +
                                        (data.params.total === 1
                                            ? t("common:Arquivo")
                                            : t("common:Arquivos"))
                                    }
                                </Typography>

                                <SearchInput
                                    showClearButton
                                    placeholder={`${t("common:Buscar no Bucket")}...`}
                                    handleSubmitSearch={data.func.handleSubmitSearch}
                                    value={''}
                                />
                            </div>

                            <div className={classes.barActionsItens} style={{ padding: '8px 0', justifyContent: 'space-between' }}>
                                <div className='mobile'>
                                    <MenuSorting
                                        filtersParams={data.params.filters_params.order}
                                        setParametersFilters={data.func.setParametersFilters}
                                    />

                                    <div id="line-divider" style={{ marginRight: 15 }} />

                                    <MenuTypes
                                        filtersParams={data.params.filters_params.fileTypes}
                                        setParametersFilters={data.func.setParametersFilters}
                                    />

                                    <div id="line-divider" style={{ marginRight: 15 }} />


                                    <MenuByUpload
                                        filtersParams={data.params.filters_params.byUpload}
                                        setParametersFilters={data.func.setParametersFilters}
                                        hasIntegration={data.params.hasIntegration}
                                    />

                                    <div id="line-divider" style={{ marginRight: 15 }} />

                                    <MenuRangeDate
                                        filtersParams={data.params.filters_params.rangeDate}
                                        setParametersFilters={data.func.setParametersFilters}
                                    />
                                </div>
                                {
                                    !simpleBucket && (
                                        <ButtonCreateItems
                                            createItemByBucket={() => data.func.handleCreateItem()}
                                            bucket
                                        />
                                    )
                                }
                                <MarkedItems
                                    handleCreateItem={data.func.handleCreateItem}
                                    checkedValue={data.params.checkedValue}
                                    handleViewItens={data.func.handleViewItens}
                                    getData={data.func.getData}
                                    simpleBucket={simpleBucket}
                                />
                                {
                                    (simpleBucket && toolbarManagerAssets) && toolbarManagerAssets
                                }
                            </div>
                        </>
                    )
                }
            </Toolbar>
        </div>
    )
}
FilterBucket.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.shape({
        params: PropTypes.shape({
            total: PropTypes.number,
            checkedValue: PropTypes.bool,
            hasIntegration: PropTypes.bool,
        }),
        func: PropTypes.shape({
            handleCreateItem: PropTypes.func,
            handleSubmitSearch: PropTypes.func,
            handleViewItens: PropTypes.func,
            getData: PropTypes.func,
        })
    })
}
export default FilterBucket
