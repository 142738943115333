import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import {
    Tabs,
    Tab,
    Grid,
    Paper,
    Typography
} from '@material-ui/core';

import Template from "../Template"

import ListRecipes from './Recipes/ListRecipes'
import FormRecipes from './Recipes/FormRecipes'

import ListAlternativeName from './AlternativeName/ListAlternativeName'
import FormAlternativeName from './AlternativeName/FormAlternativeName'

import ListSSO from './SSO/List'
import FormSSO from './SSO/Form'

import { useStyles } from "./styles"
function TabPanel(props) {
    const { children, value, index, title, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const index = parseInt(sessionStorage.getItem("advanced-settings-index")) || 0
    const [value, setValue] = React.useState(index)
    const history = useHistory()
    const { t } = useTranslation()
    const classes = useStyles()
    const { path } = useRouteMatch()

    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem("advanced-settings-index", newValue)
        history.push('/manager/advanced-settings')
    };

    return (
        <Template area={10} localHistory={history} customClassName={"container-center"}>
            <Paper elevation={2}>

                <Grid item xs={12} style={{ padding: 20 }}>
                    <Typography variant='h5'>
                        {t('common:Configurações avançadas')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        centered
                        aria-label="tabs-config-platform"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={t('manager:Especificação de imagem')}  {...a11yProps(0)} />
                        <Tab label={t('manager:Nome alternativo')} {...a11yProps(1)} />
                        <Tab label={t('manager:Single Sign-On')} {...a11yProps(2)} />
                    </Tabs>
                </Grid>

                <TabPanel value={value} index={0}>
                    <div className={classes.grid_root}>
                        {
                            path.indexOf('/new') !== -1 &&
                            <div className='form-root'>
                                <FormRecipes typeForm="create" {...props} />
                            </div>
                        }

                        {
                            path.indexOf('/edit') !== -1 &&
                            <div className='form-root'>
                                <FormRecipes typeForm="edit" {...props} />
                            </div>
                        }
                        {
                            path.indexOf('/new') === -1 && path.indexOf('/edit') === -1 &&
                            <ListRecipes history={history} />
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className={classes.grid_root}>
                        {
                            path.indexOf('/new') !== -1 &&
                            <div className='form-root'>
                                <FormAlternativeName typeForm="create" {...props} />
                            </div>
                        }

                        {
                            path.indexOf('/edit') !== -1 &&
                            <div className='form-root'>
                                <FormAlternativeName typeForm="edit" {...props} />
                            </div>
                        }
                        {
                            path.indexOf('/new') === -1 && path.indexOf('/edit') === -1 &&
                            <ListAlternativeName history={history} />
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div className={classes.grid_root}>
                        {
                            path.indexOf('/new') !== -1 &&
                            <div className='form-root'>
                                <FormSSO typeForm="create" {...props} />
                            </div>
                        }

                        {
                            path.indexOf('/edit') !== -1 &&
                            <div className='form-root'>
                                <FormSSO typeForm="edit" {...props} />
                            </div>
                        }
                        {
                            path.indexOf('/new') === -1 && path.indexOf('/edit') === -1 &&
                            <ListSSO history={history} />
                        }
                    </div>
                </TabPanel>
            </Paper>
        </Template>
    );
}
