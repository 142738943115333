import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      margin: '0 !important',
      padding: '40px',
      minWidth: '550px',
      color: '#666'
    },
    [theme.breakpoints.down("sm")]: {
      '& .MuiDialog-paperWidthSm': {
        minWidth: '100%',
      },
    },
  },
  title: {
    padding: '0 0 16px 0',
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div.info': {
        display: "flex",
        alignItems: 'center',
      },
      "& svg#main": {
        width: "40px",
        height: "40px",
        backgroundColor: '#FF9800',
        borderRadius: "50%",
        padding: "7px",
        marginRight: "10px",
        color: "#fff",
      },
    },
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
    padding: 0
  },
}));
