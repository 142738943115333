import React from 'react'
import { Paper, Box, Typography } from '@material-ui/core'
import { useStyles } from './styles'

export default function PaperCustom({ children, title = '', subTitle = "" }) {
    const classes = useStyles()
    return (
        <Paper className={classes.paper}>
            <Box style={{ marginBottom: 24 }}>
                <Typography variant='h5' style={{ fontWeight: 500 }}>
                    {title}
                </Typography>
                {
                    subTitle &&
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                        {subTitle}
                    </Typography>
                }
            </Box>
            {children}
        </Paper>
    );
}
