import React, { useState, useEffect, useCallback } from 'react';
import _ from "lodash"
import { useTranslation } from "react-i18next"

import axios from "axios"
import Settings from "../../utils/Settings";
import { useStyles } from "./styles"

import {
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    Typography,
    Button,
    CircularProgress,    
    Box
} from "@material-ui/core"

import { useSnackbar } from "notistack";
import noThumb from "../../imagens/ui/ico_users.svg"


const Profiles = ({ region, handleClick, handleBack, requestHeaders, client_slug, isMobile }) => {
    const [state, setState] = useState({
        data: [], loading: true, dataError: false
    })
    const classes = useStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar();    
    
    const getData = useCallback(async () => {
        try {
            const { data } = await axios.get(`${Settings.URL_API}/client/${client_slug}/${region}/profiles`, requestHeaders)

            if (_.get(data, 'items', []).length <= 0) {
                enqueueSnackbar(t("solicitacaoAcesso:Não há perfil para selecionar"), {
                    variant: "warning",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                });                
                handleBack(null, 0)
            } else {
                setState(prev => ({ ...prev, data: _.get(data, 'items', []), loading: false }))
            }
        } catch (e) {
            console.log(e)
            setState(prev => ({ ...prev, loading: false, dataError: true }))
        }
    }, [requestHeaders, client_slug, enqueueSnackbar, region, handleBack, t])

    useEffect(() => {
        let isMounted = true
        if (isMounted && state.loading) getData()
        return () => { isMounted = false }
    }, [getData, state.loading])

    return (
        <>
            {
                state.loading ? (
                    <Box className={classes.loading}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {
                            state.dataError ? (
                                <Box className={classes.loading}>
                                    {t('common:Erro ao carregar os dados.')}
                                </Box>
                            ) : (
                                <>
                                    <List className={classes.rootList}>
                                        {
                                            state.data.map((item, index) => (
                                                <ListItem
                                                    key={_.get(item, "id", "")}
                                                    button
                                                    onClick={() => handleClick({
                                                        complementary: {},
                                                        profile: {
                                                            id: _.get(item, "id", ""),
                                                            name: _.get(item, "name", "Noname"),
                                                            type: _.get(item, "type_approval", "fail"),
                                                            domain: _.get(item, "domain_email", null)
                                                        },
                                                        activeStep: 2
                                                    })}
                                                    id={index === (state.data.length - 1) ? 'last' : ''}
                                                >
                                                    {
                                                        !isMobile &&
                                                        <ListItemAvatar>
                                                            <Avatar src={_.get(item, "urls.thumbnail.original", null) || noThumb} />
                                                        </ListItemAvatar>
                                                    }
                                                    <Box className='profile-description'>
                                                        <Typography variant='body1' style={{ fontWeight: 500, width: '50%' }}>
                                                            {_.get(item, 'name', '')}
                                                        </Typography>
                                                        <Typography variant='body2' style={{ width: '50%' }}>
                                                            {_.get(item, 'description', '')}
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    <Box className={classes.btnActions}>
                                        <Button variant="outlined" color="primary" onClick={handleBack}>
                                            {t('common:Voltar')}
                                        </Button>
                                    </Box>
                                </>
                            )
                        }
                    </>
                )
            }
        </>
    );
};

export default Profiles;
