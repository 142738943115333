import React from "react";

import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";

import arrayMove from "array-move";

import { useDataItemFiles } from '../context'

import { makeStyles } from "@material-ui/core/styles"
import { Grid } from '@material-ui/core'
import DragIndicatorRoundedIcon from "@material-ui/icons/DragIndicatorRounded"

import { CardFile } from "pages/DAM/Bucket/Cards"

export const useStyles = makeStyles((theme) => ({
    handle: {
        display: "flex",
        alignItems: "center",
        cursor: 'grab',
        position: 'absolute',
        top: 10
    },
}))

const Handle = SortableHandle(({ tabIndex }) => {
    const classes = useStyles()

    return (
        <div className={classes.handle} tabIndex={tabIndex}>
            <span>{tabIndex + 1}</span>
            <DragIndicatorRoundedIcon fontSize='small' />
        </div>
    )
}
);

const SortableItem = SortableElement(props => {
    const { value: item } = props;

    const {
        dataSelected,
        setDataSelected,
    } = useDataItemFiles()

    const handleClickSelected = (file) => {
        if (dataSelected.some(i => i.id === file.id)) {
            const filter = dataSelected.filter(i => i.id !== file.id)
            setDataSelected(filter)
        } else {
            setDataSelected(prev => [...prev, file])
        }
    }

    return (
        <CardFile
            file={item}
            dataMarked={dataSelected.some(i => i.id === item.id)}
            handleClickSelected={handleClickSelected}
            policies={[]}
            setZindex
        >
            <Handle tabIndex={item.index} />
        </CardFile>
    );
});


const SortableList = SortableContainer(props => {
    const { items, ...restProps } = props;
    return (
        <Grid container spacing={2}>
            {items.map((item, index) => (
                <SortableItem
                    key={`item-${item.id}-${index}`}
                    index={index}
                    value={{ ...item, index: index }}
                    {...restProps}
                />
            ))}
        </Grid>
    );
});


function SortableListWrapper() {
    const { dataAssets, setDataAssets } = useDataItemFiles()

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const list = arrayMove(dataAssets, oldIndex, newIndex)
        setDataAssets(list)
    };

    return (
        <SortableList
            useDragHandle
            axis="xy"
            items={dataAssets}
            onSortEnd={onSortEnd}
        />
    );
}

export default SortableListWrapper
