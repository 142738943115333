import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& svg': {
      color: '#fff'
    },
    '& button': {
      color: '#fff',
      '&:hover': {
        backgroundColor: '#666'
      }

    },
    '& div': {
      color: '#fff',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#666 !important'
    }
  },
}));

export default function PaginationControlled({ count, handleChange, page }) {
  const classes = useStyles();

  const onChange = (event, value) => {
    event.preventDefault()
    handleChange(value - 1);
  };

  return (
    <div className={classes.root}>
      <Pagination count={count} page={page + 1} onChange={onChange} />
    </div>
  );
}
