import React from 'react'

import { useStyles } from './styles'

import {
    Typography,
    Paper,    
} from "@material-ui/core";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { useTranslation } from "react-i18next"

const ExpiredInvitation = () => {
    const { t } = useTranslation()    
    const classes = useStyles()
    
    return (                   
            <div className={classes.expired_root}>
                <Paper className={classes.expired_paper}>
                    <div style={{ display: 'flex' }}>
                        <ErrorOutlineIcon color='primary' />
                        <Typography style={{ fontWeight: 500, marginLeft: 4 }}>
                            {t('common:Oops! Link indisponível')}
                        </Typography>
                    </div>
                    <div style={{ padding: '20px 70px' }}>
                        <Typography>
                            {t('common:Entre em contato com  o gestor de conteúdo para reenviar o convite ou faça a solicitação de acesso e aguarde a moderação.')}
                        </Typography>
                    </div>
                </Paper>
            </div>        
    )
}

export default ExpiredInvitation