import React from "react"
import Autosuggest from "react-autosuggest"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import axios from "axios"
import { toast } from "react-toastify"
import connect from "react-redux/es/connect/connect"
import {
  mapDispatchToPropsToSettings,
  mapStateToPropsToSettings,
} from "../../../../utils/Flux"
import { withTranslation } from "react-i18next"

import {Breadcrumbs, Typography} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

import _ from "lodash"

class InputPermissions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
      single: "",
    }
  }
  renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps

    return (
      <div className="form-field full-width">
        <input
          type="text"
          name="permissions"
          inputprops={{
            inputRef: (node) => {
              ref(node)
              inputRef(node)
            },
          }}
          {...other}
          ref={ref}
        />
      </div>
    )
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    const getTitle = () => {
      const getName = (data) => {
        let newData = []

        if(_.get(suggestion, 'name', false)) {
          newData.push(suggestion.name)          
        } else {
          for (var prop in data) {    
            if(data[prop].name) {
              newData.push(data[prop].name)
            }
            if(data[prop].value){
              newData.push(`Valor: ${data[prop].value}`)
            }    
          } 
        }

        return newData
      }
      
      const data = 
          _.get(suggestion, 'region.display_name', false)
        ? [_.get(suggestion, 'region.display_name', '') , ..._.get(suggestion, 'hierarchy', []), ...getName(_.get(suggestion, 'entity_data', []))]
        : [..._.get(suggestion, 'hierarchy', []), ...getName(_.get(suggestion, 'entity_data', []))]

      return (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {data.map((i, key) => (
            <Typography key={key} color="textPrimary">
              {i}
            </Typography>
          ))}
        </Breadcrumbs>
      )
    }

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>{getTitle()}</div>
      </MenuItem>
    )
  }

  getSuggestions(value) {
    const SELF = this
    const { requestHeaders, pathClient, triggerError } = this.props
    const inputValue = (value ? value : "").trim()
    const query = inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
    const inputLength = inputValue.length

    if (inputLength <= 2) {
      return []
    }

    axios
      .get(
        pathClient + `/policy/query/${encodeURIComponent(query)}`,
        requestHeaders
      )
      .then(
        function (resp) {
          const items = resp.data.items

          let array = []

          Object.entries(items).forEach(([key, value]) => {
            if (value.length === 0) {
              return
            }

            if (Array.isArray(value)) {
              array = [...array, value[0]]
            } else {
              array = [
                ...array,
                ...value.permissions.map((i) => ({
                  ...i,
                  region: { name: key, thumb: value.thumb, display_name: value.name },
                })),
              ]
            }
          })

          SELF.setState({
            ...SELF.state,
            suggestions: array,
          })
        },
        function (err) {
          SELF.setState({ ...SELF.state, suggestions: [] })
          toast.error(err.response.headers["x-message"])
        }
      )
      .catch((err) => triggerError(err))
  }

  getSuggestionValue(suggestion) {
    return suggestion
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value)
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      ...this.state,
      single: "",
      suggestions: [],
    })
  }

  handleChange = (name) => (event, { newValue }) => {
    const { addPermission } = this.props
    const entity = _.get(newValue, 'entity_data', _.get(newValue, 'entity', false))
    
    if (
      ((event.type === "keydown" && event.keyCode === 13) ||
        event.type === "click") &&
        entity
    ) {
      addPermission(newValue)
      event.preventDefault()
    }
    
    if (entity) {
      this.setState({
        ...this.state,
        [name]: Array.isArray(entity) ? newValue.entity_data[0].name : entity,
      })
    } else {
      this.setState({
        ...this.state,
        [name]: newValue,
      })
    }
  }

  render() {
    const SELF = this
    const { t } = SELF.props
    const classes = {
      root: {
        height: 250,
        flexGrow: 1,
      },
      container: {
        position: "relative",
      },
      suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 50,
        marginTop: "1px",
        left: 0,
        right: 0,
        maxHeight: 310,
        overflow: "auto",
      },
      suggestion: {
        display: "block",
      },
      suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
      },
      divider: {
        height: "2px",
      },
    }

    const { suggestions } = SELF.state
    const {
      renderInputComponent,
      handleSuggestionsFetchRequested,
      handleSuggestionsClearRequested,
      handleChange,
    } = SELF

    const autosuggestProps = {
      renderInputComponent,
      suggestions: suggestions,
      onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: handleSuggestionsClearRequested,
    }

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          renderSuggestion={(suggestion, { query, isHighlighted }) =>
            SELF.renderSuggestion(suggestion, { query, isHighlighted })
          }
          getSuggestionValue={(suggestion) =>
            SELF.getSuggestionValue(suggestion)
          }
          inputProps={{
            classes,
            id: "react-autosuggest-simple",
            placeholder: t("common:Procure uma permissão (Mín. 03 caracteres)"),
            value: this.state.single,
            onChange: handleChange("single"),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToPropsToSettings,
  mapDispatchToPropsToSettings
)(withTranslation(["manager", "common"])(InputPermissions))
