import React, { Fragment, useCallback } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import UploadActions from 'flux/actions/UploadActions';
import { useSelector, useDispatch } from "react-redux";

import Settings from 'utils/Settings'

import { Button, Tooltip } from "@material-ui/core";

import DialogConfirm from 'components/Alert/DialogConfirm'
import { useStyles } from "../../styles"

const AccordionActions = () => {
    const classes = useStyles();
    const { uploadFiles } = useSelector(state => state.uploadReducer);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("DAM")

    const clearFileOnlyData = () => {
        dispatch(UploadActions.uploadClearFiles());
    }

    const uploadCancelAllFilesDispatch = useCallback(() => {
        dispatch(UploadActions.uploadCancelAllFiles())
            .then((result) => {
                enqueueSnackbar(t("DAM:Uploads cancelados com sucesso."), {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                });
            })
            .catch((error) => {
                console.log("Erro ao tentar cancelar todos os uploads.", error);
                return enqueueSnackbar(t("DAM:Erro ao tentar cancelar todos os uploads."), {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                });
            });
    }, [dispatch, enqueueSnackbar, t])

    const setNameAllFilesCancel = useCallback(() => {
        const listNames = uploadFiles.filter(item => !item.uploadFinished).map(item => item.name).join(', ')
        return listNames
    }, [uploadFiles])

    const handleResumeAllFiles = () => {
        dispatch(UploadActions.resumeAll()).then(
            () => {
                enqueueSnackbar(t("DAM:Uploads retomados com sucesso."), {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                });
            },
            (error) => {
                console.log(error);
                enqueueSnackbar(t(`DAM:Erro ao tentar retomar todos os uploads`), {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                });
            }
        );
    };

    const uploadSent = uploadFiles.find((i) => !i.uploadFinished)
    const uploadPaused = uploadFiles.some((i) => i.upload_paused)

    return (
        <div className={classes.actions}>
            {uploadSent ? (
                <Fragment>
                    <div className="iconsActions" />
                    {
                        uploadPaused &&
                        <Tooltip title={t("DAM:Retomar uploads pausados")}>
                            <Button
                                className="clear"
                                variant="contained"
                                onClick={handleResumeAllFiles}
                            >
                                {t("common:Retomar uploads")}
                            </Button>
                        </Tooltip>
                    }

                    <DialogConfirm
                        t={t}
                        title={t("DAM:Cancelar upload")}
                        description={t(
                            `DAM:Você tem certeza que deseja cancelar o upload dos arquivos?`
                        )}
                        listNames={setNameAllFilesCancel()}
                    >
                        {(confirm) => (
                            <Button
                                className="clear"
                                variant="contained"
                                onClick={confirm(uploadCancelAllFilesDispatch)}
                            >
                                {t("common:Cancelar uploads em andamento")}
                            </Button>
                        )}
                    </DialogConfirm>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="iconsActions" />
                    <Button
                        disabled={uploadSent}
                        className="clear"
                        variant="contained"
                        onClick={clearFileOnlyData}
                    >
                        {t("common:Fechar")}
                    </Button>
                </Fragment>
            )}
        </div>
    )
};

export default AccordionActions
