import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import AccordionBucketContainer from "containers/AccordionBucketContainer"

const AccordionBucket = () => {
    const { pathname } = useLocation()

    const constans = useMemo(() => ({
        hide: (
            (pathname.indexOf('/downloads') !== -1) ||
            (pathname.indexOf('/manager/') !== -1)
        )
    }), [pathname])

    return !constans.hide && (
        <AccordionBucketContainer />
    )
}

export default AccordionBucket
