import { useCallback } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useDataItems } from '../context'
import useUtils from './useUtils'

export default function useItems() {
  const { requestHeaders, pathServer } = useSelector(
    (state) => state.appReducer
  )
  const {
    updateRecord,
    getRenewedData,
    getParamsToItems,
  } = useDataItems()
  const { postError } = useUtils()

  const handleConfirmPublish = useCallback((publishId = '', operation = '', comment = '', onFinish = null) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = { ...requestHeaders, cancelToken: source.token }
    axios.post(`${pathServer}/item/${operation}/${publishId}`, { comment: comment }, options)
      .then((resp) => {
        if (onFinish) {
          onFinish()
        }
        updateRecord(resp.data)
      })
      .catch((err) => {
        postError(err)
        if (onFinish) {
          onFinish()
        }
      })
  }, [requestHeaders, pathServer, updateRecord, postError])

  const handleConfirmDelete = useCallback((id = '', onFinish = null) => {
    const paramsToItems = getParamsToItems()
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = { ...requestHeaders, cancelToken: source.token }
    axios.delete(`${pathServer}/item/${id}`, options)
      .then((resp) => {
        if (onFinish) {
          onFinish()
        }
        getRenewedData(paramsToItems)
      })
      .catch((err) => {
        postError(err)
        if (onFinish) {
          onFinish()
        }
      })
  }, [requestHeaders, pathServer, getRenewedData, getParamsToItems, postError])

  return {
    handleConfirmPublish,
    handleConfirmDelete,
  }
}
