import React from "react";

import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";

import arrayMove from "array-move";
import { useTranslation } from 'react-i18next'
import { useDataEditFiles } from '../context'

import { makeStyles } from "@material-ui/core/styles"

import DragIndicatorRoundedIcon from "@material-ui/icons/DragIndicatorRounded"

import ImageLazyLoad from 'components/ImageLazyLoad'

import CreatableInputOnly from "components/CreatableInputOnly"

import _ from 'lodash'

import { Tooltip, Chip } from "@material-ui/core"
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded"

export const useStyles = makeStyles((theme) => ({
    asset: {
        float: 'left',
        padding: '0 5px 10px 5px',
        width: 240,        
        position: 'relative',
        zIndex: 1400,
        '& .content': {
            padding: '8px 12px',
            height: '100%',
            borderRadius: 4,
            cursor: 'pointer',
            backgroundColor: '#fff'
        },
    },
    markedAsset: {
        width: 230,
        height: 353,
        position: "absolute",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
        zIndex: 3,
        "& .content": {
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.primary.main,
            opacity: 0.08,
        },
        "& div.icon": {
            position: "absolute",
            padding: 10,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            "& svg": {
                color: theme.palette.primary.main,
                margin: 5,
            },
        },
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-8px',
        marginRight: '-8px',
        whiteSpace: 'nowrap',
        "&:after": {
            content: "",
            clear: 'both',
            display: 'table',
        }
    },
    container: {
        display: 'block',
    },
    handle: {
        marginBottom: 15,
        display: "flex",
        alignItems: "center",
        cursor: 'grab',
    },
    labelDescription: {
        top: 9,
        left: 11,
        color: 'rgba(0, 0, 0, 0.54)',
        display: 'inline-block',
        padding: '0 3px',
        zIndex: 1,
        position: 'relative',
        fontSize: 14,
        background: '#fff',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.00938em',
    },
    createInput: {
        marginTop: 7,
        '& > div': {
            overflow: 'auto',
            maxHeight: 74, 
        }
    }
}))

const Handle = SortableHandle(({ tabIndex }) => {
    const classes = useStyles()

    return (
        <div className={classes.handle} tabIndex={tabIndex}>
            <span>{tabIndex + 1}</span>
            <DragIndicatorRoundedIcon fontSize='small' />
        </div>
    )
}
);

const SortableItem = SortableElement(props => {
    const classes = useStyles()
    const { value: item, handleChangeRemoveTags } = props;
    const { t } = useTranslation()

    const {
        dataTags,
        setDataTags,
        dataSelected,
        setDataSelected,
        setActionsHistory,
    } = useDataEditFiles()

    const dataValue = dataTags.find(i => i.id === item.id)

    const handleChangeTags = (value, id) => {
        if(dataTags.some(i => i.id === id)) {
            const data = dataTags.map(i => {
                if (i.id === id && !(i.value.some(i => i.value === value))) {
                    i.value = [...i.value, { label: value, value: value, id: id }]
                }
                return i
            })
            setDataTags(data)
        } else {
            setDataTags(prev => ([...prev, { id: id, value: [{ label: value, value: value, id: id }] }]))
        }      
        
        setActionsHistory(prev => ([...prev, 'tags-change']))        
    }

    const handleClickSelected = (value) => {
        const index = dataSelected.findIndex(i => i.id === value.id) !== -1

        if (index) {
            const filter = dataSelected.filter(i => i.id !== value.id)
            setDataSelected(filter)
        } else {
            setDataSelected(prev => [...prev, value])
        }
        return
    }

    const customStyles = {
        control: () => ({
            minHeight: 74,
            border: '1px solid #bababa',
            borderRadius: 4,
            display: 'flex'
        })
    }

    const cutsomComponents = {
        MultiValue: props => {
            const value = JSON.stringify({id: props.data.id, value: props.data.value })
            return (
                <Tooltip title={props.data.value}>
                    <Chip
                        label={props.data.value}                        
                        color="primary"
                        onDelete={() => handleChangeRemoveTags(value)}    
                        style={{ margin: 4, maxWidth: 170 }}                    
                    />
                </Tooltip>
            )
        }
    }

    const isSelected = (dataSelected.findIndex(i => i.id === item.id) !== -1)

    return (
        <div className={classes.asset}>
            {isSelected && (
                <div className={classes.markedAsset} onClick={() => handleClickSelected(item)}>
                    <div className="icon">
                        <CheckCircleRoundedIcon />
                    </div>
                    <div className="content" />
                </div>
            )}
            <div className="content" onClick={() => handleClickSelected(item)}>
                <Handle tabIndex={item.index} />
                <div style={{ width: 200, height: 200 }}>
                    <ImageLazyLoad
                        images={{imgJPG: item[`thumbnail_card_original`], imgWEBP: item[`thumbnail_card_webp`]}}
                        styles={{width: '100%'}}                        
                    />                    
                </div>
                <div className={classes.createInput}  onClick={(event) => event.stopPropagation()}>
                        <label className={classes.labelDescription}>{t('common:Marcadores')}</label>

                        <CreatableInputOnly
                            assetId={item.id}
                            dataValues={[..._.get(dataValue, 'value', [])]}
                            handleChangeTags={handleChangeTags}
                            valueLimited={10}
                            t={t}
                            customStyles={customStyles}
                            cutsomComponents={cutsomComponents}
                        />
                </div>

            </div>
        </div>
    );
});


const SortableList = SortableContainer(props => {
    const { items, ...restProps } = props;
    const classes = useStyles()
    return (
        <div className={classes.list}>
            {items.map((item, index) => (
                <SortableItem
                    key={`item-${item.id}-${index}`}
                    index={index}
                    value={{ ...item, index: index }}
                    {...restProps}
                />
            ))}
        </div>
    );
});


function SortableListWrapper() {
    const classes = useStyles()

    const {
        dataAssets,
        setDataAssets,
        dataTags,
        setDataTags,
        setActionsHistory
    } = useDataEditFiles()

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const list = arrayMove(dataAssets, oldIndex, newIndex)
        setActionsHistory(prev => ([...prev, 'array-move']))
        setDataAssets(list)
    };

    const handleChangeRemoveTags = (tag) => {
        const { value, id } = JSON.parse(tag)

        const data = dataTags.map(i => {
            if (i.id === id) {
                i.value = i.value.filter(i => i.value !== value)

            }
            return i
        })
        setActionsHistory(prev => ([...prev, 'tags-remove']))
        setDataTags(data)
    }

    const shouldCancelStart = (e) => {
        e.preventDefault()
        var targetEle = e;
        if (!targetEle.id) {
            targetEle = e.target;
        }
        if (targetEle.id === 'MultiValueRemove') {
            const { attributes: { value: { value } } } = targetEle
            handleChangeRemoveTags(value)
        }
    }

    return (
        <div className={classes.container}>
            <SortableList
                useDragHandle
                axis="xy"
                items={dataAssets}
                onSortEnd={onSortEnd}
                shouldCancelStart={shouldCancelStart}
                handleChangeRemoveTags={handleChangeRemoveTags}
            />
        </div>
    );
}

export default SortableListWrapper
