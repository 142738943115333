import React from 'react';
import { useTranslation, Trans } from "react-i18next"
import {Popover, Typography, Button, Link} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles"

import HelpRoundedIcon from '@material-ui/icons/HelpRounded'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: '1000001 !important',
        '& .MuiPaper-root': {
            left: 'calc(50% - 300px) !important',
            top: 'calc(50% - 80.025px) !important'
        }
    }
}))

export default function SimplePopover({ isColorPrimary = true, title }) {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>            
            {
                isColorPrimary ? (
                    <Button
                        endIcon={<HelpRoundedIcon />}
                        component='a'
                        size="small"
                        color={isColorPrimary ? "primary" : 'default'}
                        onClick={handleClick}
                        style={{ margin: '10px 0', ...(!isColorPrimary && { color: '#fff' }) }}
                    >
                        {t("login:Critérios de senha")}
                    </Button>
                ) : (
                    <Trans i18nKey="login:cliqueaquiparasabermais">
                        Clique <Link onClick={handleClick} style={{cursor: 'pointer', color: '#c0c0c0'}}>aqui</Link> para saber mais.
                    </Trans>
                )
            }

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}               
                className={classes.root}
            >
                <div style={{ padding: 20, maxWidth: 600 }}>
                    <Typography variant='h6'>
                        {t("login:Critérios para definição de senha")}:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body2'>
                            {t("solicitacaoAcesso:Requisito de Senha")}:{t("solicitacaoAcesso: A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial. E não pode ter sido utilizada nas últimas 5 senhas.")}
                            </Typography>
                        </li>                        
                    </ul>
                </div>
            </Popover>
        </>
    );
}