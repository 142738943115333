import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    Typography,
    AccordionDetails,
    AccordionSummary,
    Accordion,
    Divider,
    Button,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux"
import ListItemCustom from './ListItem'
import useDownloadCentral from './useDownloadCentral'

import { Creators as DownloadCentralActions } from "flux/ducks/Download_central"


export const useStyles = makeStyles((theme) => ({
    root: {
      marginBottom: "5px",
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
          transform: 'rotate(0deg)',
          padding: '0 8px',
          '& span svg.MuiSvgIcon-root': {
              fontSize: '35px'
          }
      },
      '& .MuiAccordionSummary-expandIcon': {
          transform: 'rotate(180deg)',
          padding: '0 8px',
          '& span svg.MuiSvgIcon-root': {
              fontSize: '35px'
          }
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
          borderRadius: '5px 5px 0 0',
          maxHeight: '50px',
          minHeight: '50px',
      },
      '&. .MuiAccordion-rounded:last-child': {
          borderRadius: '5px'
      },
      '&.MuiAccordion-rounded:first-child': {
          borderRadius: '5px'
      },
      '& .MuiSvgIcon-root': {
          fontSize: '25px'
      },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        maxHeight: 400,
        overflow: 'auto',
        "& #ListItemCustom-li": {
            margin: 0,
            border: 'none',
            borderTop: '1px solid #EEE'
        },
        "& #ListItemCustom-date-info": {
            display: 'none'
        }
    },
    summary: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "5px",
        maxHeight: '50px',
        minHeight: '50px',
        "& div": {
            display: "flex",
            alignItems: "center",
        },
    },
    heading: {
        color: "#fff",
        marginLeft: "15px",
        fontSize: theme.typography.pxToRem(15),
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 1,
    },
}))

const AccordionDownloadCentral = () => {
    const { getData } = useDownloadCentral()
    const {
        checkedData,
        reloadDownloadData,
        loading,
        items,
    } = useSelector((state) => state.downloadCentralReducer)

    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        let isMounted = true
        if (isMounted && !checkedData) {
            getData(isMounted)
        }
        if (isMounted && reloadDownloadData && !loading) {
            getData(isMounted)
            dispatch(DownloadCentralActions.setReloadDownloadData())
        }
        return () => {
            isMounted = false
        }
    }, [checkedData, getData, reloadDownloadData, loading, dispatch])

    const constants = useMemo(() => {
        const inProgress = items.filter(i => (i.state === "in_progress") || (i.state === "created"))
        const errors = items.filter(i => i.error)

        return ({
            inProgressData: inProgress,
            errorsData: errors,
            show: ((inProgress.length > 0) || (errors.length > 0))
        })
    }, [items])

    return constants.show && (
        <div className={classes.root}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="default" style={{ color: "#fff" }} />}
                    aria-controls="AccordionCentralDownloads-content"
                    id="AccordionCentralDownloads-header"
                    className={classes.summary}
                >
                    <div style={{ display: "flex", width: "100%" }}>
                        <CloudUploadRoundedIcon
                            fontSize="default"
                            style={{ color: "#fff" }}
                        />
                        <div style={{ width: "95%" }}>
                            <Typography className={classes.heading}>
                                {t("DAM:Central de downloads")}
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {
                        constants.errorsData.length > 0 &&
                        constants.errorsData.map(i => <ListItemCustom key={i.id} data={i} />)
                    }
                    {
                        constants.inProgressData.length > 0 &&
                        constants.inProgressData.map(i => <ListItemCustom key={i.id} data={i} isHandleDownloadFile />)
                    }
                </AccordionDetails>
                <Divider />
                <div className={classes.actions}>
                    {
                        constants.errorsData.length > 0 &&
                        <Button color="primary" onClick={() => dispatch(DownloadCentralActions.clearItemsDataErrors())}>
                            {t("DAM:Limpar")}
                        </Button>
                    }
                    <Button color="primary" onClick={() => history.push(`/downloads`)}>
                        {t("DAM:Abrir central de downloads")}
                    </Button>
                </div>
            </Accordion>
        </div>
    );
};

export default AccordionDownloadCentral
