import React from "react";
import _ from 'lodash'

import { 
  IconButton, 
  SnackbarContent, 
  Tooltip,
  Zoom, 
  Button,
  Grid, 
  Avatar,
  TextField,
  Typography
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import TranslateIcon from '@material-ui/icons/Translate'
import conf from '../res/conf';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { withStyles } from '@material-ui/core/styles';
import Settings from './Settings'

import url from 'url'
import querystring from 'querystring'

import PopoverPassword from 'components/PopoverPassword'

const AvatarIcon = withStyles({
  root: {
    color: '#7D7D7D',
    backgroundColor: '#fff',
    '& svg': {
      fontSize: '3rem !important'
    }
  },
})(({ classes }) => (
  <Avatar className={classes.root}>
    <AccountCircleRoundedIcon fontSize='large' />
  </Avatar>
));


export function getColumnThumbnail(data, callback) {
  return {
    name: "Thumbnail",
    options: {
      filter: false,
      customBodyRender: ({id, thumbnail}) => {
        if (thumbnail) {
          return (
            <Tooltip
              title="Alterar Imagem"
              placement="right"
              TransitionComponent={Zoom}
            >
              <button           
                style={{
                  border: 'none',
                  background: 'transparent',
                  textAlign: 'left',
                  padding: 0,
                  fontFamily: 'inherit',    
                  cursor: "pointer"                                    
                }}  
                onClick={(evt) => {
                  evt.stopPropagation();
                  callback(id);
                }}
              >
                {thumbnail.length > 0 ? (
                  <Avatar
                    alt="Thumbnail"
                    width="46px"
                    heigth="46px"
                    src={
                      thumbnail + "?=timestamp=" + Math.floor(Date.now() / 1000)
                    }
                  />
                ) : (
                  <AvatarIcon />
                )}
              </button>
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    }
  }
}

export function getColumnEdit(callback, showIcon = true, t = null) {
  return {
    name: t ? t("common:Editar") : "Editar",
    options: {
      filter: false,
      customBodyRender: ({id}) => {
        if(showIcon) {
          return (
            <IconButton onClick={() => callback(id)}>
              <EditIcon />
            </IconButton>
          );
        } else {
          return("");
        }
      }
    }
  }
}

export function getColumnDelete(callback, checkShowIcon = null, t = null) {
  return {
    name: t ? t("common:Excluir") : "Excluir",
    options: {
      filter: false,
      customBodyRender: ({id, name, value = null}) => {
        let showIcon = true;

        if(checkShowIcon) {
          showIcon = checkShowIcon(value);
        }

        if(showIcon) {
          return (
            <IconButton  onClick={() => callback(id, name)}>
              <DeleteRoundedIcon />
            </IconButton>
          );
        } else {
          return "";
        }
      }
    }
  }
}

export function getDomain() {
  const host = window.location.hostname;

  if(host.includes("-previous-homol")) {
    return host.replace(/-previous-homol/, '');
  }
  if(host.includes("-homol")) {
    return host.replace(/-homol/, '');
  }
  for (let i = 0; i < conf.stages.length; i++) {
    if(conf.stages[i].hosts.includes(host)) {
      return conf.stages[i].domain;
    }
  }

  return host;
}

export function getLang(domain) {
  for (let i = 0; i < conf.stages.length; i++) {
    if(conf.stages[i].domain.includes(domain)) {
      if(conf.stages[i].lang) {
        return conf.stages[i].lang;
      }
    }
  }

  return null;
}

export function slugify(string, separator = '-') {
  let a
  if (separator === '_') {
    a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/,:;'
  } else {
    a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  }
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, separator)
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, `${separator}and${separator}`)
    .replace(/[^\w-]+/g, '')
    .replace(/-+/g, separator)
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export const redirect = (dataRegion, requestHeaders, history, pathClient, changeRegion   ) => {
  const pathname = sessionStorage.getItem("pathAfterLogin")
  const regionCurrent = pathname ? pathname.split('/')[2] : ''

  if(Boolean(pathname)) {    
    if(regionCurrent) {
      if(dataRegion === regionCurrent) {
        history.push(pathname)
      } else {
        changeRegion(regionCurrent, { requestHeaders, pathClient }, () => history.push(pathname))
      }
    } else {
      history.push(pathname)
    }    
    sessionStorage.removeItem("pathAfterLogin")
  } else {
    history.push(`/`)
  }
}  

export const getLanguageFromLocalization = localization => {
  const index = localization.indexOf('-')
  return index !== -1 ? localization.slice(0, index) : localization
}

export const validHexColor = (color) => {
  if(/^#[0-9A-F]{6}$/i.test(color)) {
    return color
  } else {
    return Settings.COLOR_PLATFORM_DEFAULT
  }
}

export const genereteHash = (text) => {
  return text.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
};

export const removeObsoleteUrlSessionStorage = () => {
  let dataHistory = JSON.parse(sessionStorage.getItem("userHistory")) || {
    data: []
  }
  dataHistory.data.shift()
  sessionStorage.setItem("userHistory", JSON.stringify(dataHistory))
}

export const saveUserHistory = (dataLink) => {
  let dataHistory = JSON.parse(sessionStorage.getItem("userHistory")) || {
    data: []
  }

  let { url = '', params: {
    region = ''
  } } = dataLink
  
  if(!_.get(dataHistory, 'link', false)) {
    dataHistory['link'] = { url, region }
    dataHistory['navigation_from_history'] = false 
    sessionStorage.setItem("userHistory", JSON.stringify(dataHistory))
    return
  }

  const previousLink = _.get(dataHistory, 'link.url', '/')
  
  if(previousLink === url) {
    return
  }
 
  if(!_.get(dataHistory, 'navigation_from_history', false)) {    
    dataHistory['data'] = [dataHistory['link'], ..._.get(dataHistory, 'data', [])]      
  }
     
  dataHistory['link'] = { url, region }
  dataHistory['navigation_from_history'] = false 
  
  sessionStorage.setItem("userHistory", JSON.stringify(dataHistory))
}

export const redirectUserHistory = (region, historyPush, changeRegion) => {
  const dataHistory = JSON.parse(sessionStorage.getItem("userHistory"))
  const updatedHistory = [..._.get(dataHistory, 'data', [])]

  if(updatedHistory.length === 0) {
    historyPush(`/dam/${region}`)  
    return
  }

  const link = updatedHistory.shift()
  
  sessionStorage.setItem("userHistory", JSON.stringify({...dataHistory, data: updatedHistory, navigation_from_history: true}))
  
  if((region === link.region) || !Boolean(link.region)) {
    historyPush(link.url)  
  } else {
    changeRegion(link) 
  } 
   
}

export const getRequiredLngCombo = () => {       
  const index = Settings.DATA_SUPPORTED_LNG.findIndex(i => i.lng === 'pt')
  const dataFiltered = Settings.DATA_SUPPORTED_LNG.filter(i => i.lng !== 'pt')

  return [Settings.DATA_SUPPORTED_LNG[index], ...dataFiltered]
}

export const getParamsUri = (uri) => {
  const parsedUrl = url.parse(uri)
  const parsedQs = querystring.parse(parsedUrl.query)

  return parsedQs
}

export const getParamsUriRedirect = (formated) => { 
  const uri = `${window.location.href}-oauth`
      
  if(formated) {
    const index = uri.indexOf('?')
    return uri.slice(0, index)
  }

  return uri
}

export const contentSnackbar = (snackbarId, message, t, closeSnackbar) => (
  <SnackbarContent style={{ backgroundColor: '#F4511E' }}
    message={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
          <CancelRoundedIcon fontSize="small" />
        </div>
        <div className="MuiAlert-message">
          {message} <PopoverPassword isColorPrimary={false} title={t('common:Clique aqui para saber mais.')} />
        </div>
      </div>
    }
    action={
      <Button
        style={{ color: '#FFF' }}
        aria-label="close"
        size="small"
        onClick={evt => closeSnackbar(snackbarId)}>{t('common:Entendi')}
      </Button>
    }
  />
)

export const contentDataLng = (dataLng, data, loading, handleChange, t) => {
  return (
    <Grid container key={dataLng.lng}>
      <Grid item xs={12} style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20
      }}>
        <TranslateIcon fontSize='small' style={{ color: '#666' }} />
        <Typography variant="body1" style={{ color: '#666', marginLeft: 10, fontWeight: 500 }}>
          {t(`manager:${_.get(dataLng, 'short_name', '')}`)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          name="name"
          label={dataLng.lng !== 'pt' ? t("DAM:Nome do projeto") : `${t("DAM:Nome do projeto")}*`}
          value={_.get(data, `name[${dataLng.lng}]`, '')}
          onChange={(event) => handleChange(_.get(event, 'target.value', ''), dataLng.lng, "name")}
          style={{ width: '100%' }}
          disabled={loading}
          error={_.get(data, `errors.name_${dataLng.lng}`, false)}
          helperText={_.get(data, `errors.name_${dataLng.lng}`, false) ? 'Este campo é obrigatório' : ''}

        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          name="description"
          label={dataLng.lng !== 'pt' ? t("common:Descrição") : `${t("common:Descrição")}*`}
          value={_.get(data, `description[${dataLng.lng}]`, '')}
          multiline
          rows={4}
          onChange={(event) => handleChange(_.get(event, 'target.value', ''), dataLng.lng, "description")}
          style={{ width: '100%' }}
          disabled={loading}
          error={_.get(data, `errors.description_${dataLng.lng}`, false)}
          helperText={_.get(data, `errors.description_${dataLng.lng}`, false) ? 'Este campo é obrigatório' : ''}
        />
      </Grid>
    </Grid>
  )
}