import React from "react"

import EditDefault from "../Core/Edit"
import { Grid, Divider } from "@material-ui/core"
import AttributeComboBox from "../../../components/ComboBox/AttributeComboBox"
import RegionComboBox from "../../../components/ComboBox/RegionComboBox"
import TableAttribute from "./partials/TableAttributes"
import Select from "react-select"
import BoxPermissions from "./partials/BoxPermissions"
import Moderation from "./partials/Moderation"
import { omit } from "ramda"
import { arrayMove } from "react-sortable-hoc"
import { settings } from "./_settings.js"
import axios from "axios"
import { toast } from "react-toastify"
import connect from "react-redux/es/connect/connect"
import {
  mapDispatchToPropsToSettings,
  mapStateToPropsToSettings,
} from "../../../utils/Flux"
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles'
import DialogConfirmExpiration from "./DialogConfirmExpiration"
import _ from "lodash"
import Names from './Names'
import { withTranslation } from "react-i18next"
import { withSnackbar } from "notistack"
import { v4 as uuidv4 } from 'uuid';

class Edit extends EditDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      structures: [],
      fields: [
        "name",
        "description",
        "domain_email",
        "type_approval",
        "attributes",
        "approvers",
        "expiration",
        "inactivity_expiration",
        "sso_id"
      ],
      usersInApprovers: [],
      approvers: [],
      type: {
        label: '', value: ''
      },
      requireConfirmation: false,
      typeItems: [
        { label: "Yourself", value: "yourself" },
        { label: "Group", value: "group" },
      ],
      openDialogExpiration: {
        state: false,
        title: '',
        description: '',
        expirationDataUser: []
      },
      dataNames: {},
      inputValueExpiration: "",
      inputValueInactivityExpiration: '',
      dataSelectExpiration: this.getDataSelectExpiration()
    }
    this.getUrl = this.getUrl.bind(this)
    this.postValidForm = this.postValidForm.bind(this)
    this.handleChangeNames = this.handleChangeNames.bind(this)
  }

  componentDidMount() {
    const ID = this.props.match.params.id
    if (!this.state.hasData) {
      this.getStructures(ID)
    }
  }

  postValidForm() {
    const { t } = this.props
    const { data, dataOriginal } = this.state

    if (
      _.get(data, "expiration.id", 0) !==
      _.get(dataOriginal, "expiration", 0) &&
      data.inactivity_expiration.id === 0
    ) {
      this.setState({
        ...this.state,
        openDialogExpiration: {
          ...this.state.openDialogExpiration,
          state: true,
          title: t('manager:Alteração de perfil temporário'),
          description: t('manager:A aplicação de um novo tempo para perfil temporário impactará em todos os usuários atrelados ao Perfil. Deseja continuar?')
        },
      })
      return false
    }

    if (
      _.get(data, "inactivity_expiration.id", 0) !==
      _.get(dataOriginal, "inactivity_expiration", 0) &&
      data.expiration.id === 0
    ) {
      this.setState({
        ...this.state,
        openDialogExpiration: {
          ...this.state.openDialogExpiration,
          state: true,
          title: t('manager:Alteração de período de inatividade'),
          description: t('manager:A aplicação de um novo tempo para Inatividade de perfis impactará em todos os usuários atrelados ao Perfil. Deseja continuar?')
        },
      })
      return false
    }
    return true
  }

  getDataSelectExpiration() {
    let data = [{ label: this.props.t("common:Sem expiração"), value: 0 }]
    for (var i = 1; i <= 1095; i++) {
      data.push({ label: `${i} ${i === 1 ? this.props.t("common:dia") : this.props.t("common:dias")}`, value: i })
    }
    return data
  }

  getUrl(id) {
    const { pathClient } = this.props
    return `${pathClient}/${this.state.entity}/${id ? id : this.state.id}`
  }

  getStructures(ID) {
    const SELF = this
    const { requestHeaders, pathClient, triggerError } = this.props

    axios
      .get(pathClient + `/policy/from/profile/${ID}`, requestHeaders)
      .then(
        function (resp) {
          const structures = { ...resp.data.items }

          if (resp.data.items) {
            Object.entries(structures).forEach(([key, value]) => {
              if (key === "#noregion") {
                structures[key] = {
                  permissions: value,
                }
              } else {
                structures[key] = {
                  ...structures[key],
                  permissions: structures[key].permissions.map(i => ({ id: uuidv4(), ...i }))
                }
              }
            })

            SELF.setState({
              ...SELF.state,
              structures,
              hasData: true,
            })
            SELF.getData(ID)
          } else {
            SELF.setState({
              ...SELF.state,
              structures: [],
              hasData: true,
            })
            SELF.getData(ID)
          }
        },
        function (err) {
          SELF.setState({ ...SELF.state, structures: [] })
          toast.error(err.response.headers["x-message"])
        }
      )
      .catch((err) => triggerError(err))
  }

  formatGetData(newState) {
    let { data } = newState

    if (data.type_auth === "sso") {
      data.type_auth = { label: "SSO", value: "sso" }
    } else {
      data.type_auth = { label: this.props.t("manager:Pela plataforma Yapoli"), value: "yapoli" }
    }

    if (!data.hasOwnProperty("attributes")) {
      newState.data.attributes = []
    }

    if (data.hasOwnProperty("approvers")) {
      if (Array.isArray(data.approvers)) {
        data.approvers.forEach((item) => {
          newState["approvers"].push({
            value: item.id,
            label: item.label,
            disabled: true,
          })
          newState.usersInApprovers.push(item.id)
        })
      }
    }


    if (data.expiration) {
      data.expiration = {
        id: data.expiration,
        name: `${data.expiration} ${data.expiration <= 1 ? this.props.t("common:dia") : this.props.t("common:dias")}`,
      }
    } else {
      data.expiration = {
        id: 0,
        name: this.props.t("common:Sem expiração"),
      }
    }

    if (data.inactivity_expiration) {
      data.inactivity_expiration = {
        id: data.inactivity_expiration,
        name: `${data.inactivity_expiration} ${data.inactivity_expiration <= 1 ? this.props.t("common:dia") : this.props.t("common:dias")}`,
      }
    } else {
      data.inactivity_expiration = {
        id: 0,
        name: this.props.t("common:Sem expiração"),
      }
    }

    newState.type = newState.typeItems.find(
      (item) => item.value === data.type_approval
    )
    return newState
  }

  validateForm(parameters) {
    const { t } = this.props
    let { data, dataNames } = this.state
    let formValid = true
    let formErrors = {}

    Object.entries(dataNames).forEach(([lng, item]) => {
      if (_.get(item, 'name.value', false) ||
        _.get(item, 'description.value', false)
      ) {
        Object.entries(item).forEach(([field, data]) => {
          switch (field) {
            case 'name':
              if (_.get(data, 'value', '').trim().length === 0) {
                formErrors.name = {
                  ...formErrors.name,
                  [lng]: `* ${t("common:Este campo é obrigatório")}`
                }
                formValid = false
              }
              break;
            case 'description':
              if (_.get(data, 'value', '').trim().length === 0) {
                formErrors.description = {
                  ...formErrors.description,
                  [lng]: `* ${t("common:Este campo é obrigatório")}`
                }
                formValid = false
              }
              break;
            default:
              break;
          }
        })
      }
    })

    if (!_.isEmpty(dataNames)) {
      if (!_.get(parameters, 'name', false) && !_.get(parameters, 'description', false)) {
        formErrors.name = { pt: `* ${t("common:Este campo é obrigatório")}` }
        formErrors.description = { pt: `* ${t("common:Este campo é obrigatório")}` }
        formValid = false
      }
    }

    if (parameters.approvers.length === 0) {
      formErrors.approvers = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (data.type_auth.value === "sso") {
      if (!parameters.sso_id) {
        formErrors.sso_id = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      }
      if (!parameters.domain_email) {
        formErrors.domain_email = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      } else {
        const re = /\b([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}\b/

        if (parameters.domain_email.indexOf("@") !== -1) {
          formErrors.domain_email = t("manager:Digite o domínio sem o @")
          formValid = false
        }
        if (!re.test(parameters.domain_email)) {
          formErrors.domain_email = t("manager:Digite um domínio valido")
          formValid = false
        }
      }
    } else {
      if (!data.type_approval) {
        formErrors.type_approval = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      } else {
        if (data.type_approval === "yourself") {
          if (!parameters.domain_email) {
            formErrors.domain_email = `* ${t("common:Este campo é obrigatório")}`
            formValid = false
          } else {
            const re = /\b([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}\b/

            if (parameters.domain_email.indexOf("@") !== -1) {
              formErrors.domain_email = t("manager:Digite o domínio sem o @")
              formValid = false
            }
            if (!re.test(parameters.domain_email)) {
              formErrors.domain_email = t("manager:Digite um domínio valido")
              formValid = false
            }
          }
        }
      }
    }

    this.setState({ ...this.state, formErrors, formValid })

    return formValid
  }

  getParameters() {
    let parameters = super.getParameters()
    let { structures, data, openDialogExpiration, dataNames } = this.state

    if (_.get(parameters, "attributes", false)) {
      parameters.attributes = parameters.attributes.map((item) =>
        omit(["handleRemoveAttribute", "label"], item)
      )
    }

    let policies = {}

    Object.entries(structures).forEach(([key, value]) => {
      let data = []

      value.permissions.forEach((item) => {
        Object.entries(item.policies).forEach(([key, police]) => {
          if (item.entity) {
            data.push(police)
          } else {
            item.allow.forEach((action) => {
              if (police.includes(action)) {
                data.push(action)
              }
            })
          }
        })
      })

      policies[key] = data
    })

    Object.entries(policies).forEach(([key, value]) => {
      if (value.length <= 0) {
        delete policies[key]
      }
    })

    if (Object.keys(policies).length > 0) {
      parameters["policies"] = policies
    }

    parameters["region_id"] = _.get(data, "region.id", "")
    parameters["approvers"] = this.state.approvers.map((item) => item.value)
    parameters["_unset"] = []

    if (data.type_auth.value === 'sso') {
      parameters["sso_id"] = this.state.data.sso_id
      parameters["domain_email"] = this.state.data.domain_email
      parameters["type_approval"] = "yourself"
      parameters["type_auth"] = "sso"
    } else {
      if (data.type_approval === "group") {
        parameters["_unset"].push("domain_email")
        parameters["_unset"].push("sso_id")
        parameters["type_auth"] = "yapoli"
        delete parameters.sso_id
        delete parameters.domain_email
      }
      if (data.type_approval === "yourself") {
        parameters["_unset"].push("sso_id")
        parameters["type_auth"] = "yapoli"
        parameters["domain_email"] = this.state.data.domain_email
        delete parameters.sso_id
      }
    }

    if (parameters.hasOwnProperty("expiration")) {
      if (parameters.expiration.id === 0) {
        delete parameters.expiration
      } else {
        parameters["expiration"] = parameters.expiration.id
      }
    }

    if (parameters.hasOwnProperty("inactivity_expiration")) {
      if (parameters.inactivity_expiration.id === 0) {
        delete parameters.inactivity_expiration
      } else {
        parameters["inactivity_expiration"] = parameters.inactivity_expiration.id
      }
    }

    if (Array.isArray(openDialogExpiration.expirationDataUser) && openDialogExpiration.expirationDataUser.length > 0) {
      parameters["force"] = true
    }

    if (dataNames) {
      let paramsNames = {}

      Object.entries(dataNames).forEach(([lng, item]) => {
        if (_.get(item, 'name.value', false) &&
          _.get(item, 'description.value', false)
        ) {
          paramsNames = {
            name: {
              ...paramsNames.name,
              [lng]: _.get(item, 'name.value', '')
            },
            description: {
              ...paramsNames.description,
              [lng]: _.get(item, 'description.value', '')
            }
          }
        }
      })

      parameters["name"] = paramsNames.name
      parameters["description"] = paramsNames.description
    }

    return parameters
  }

  handleChangeNames(data) {
    this.setState({ ...this.state, dataNames: data })
  }

  handleUpdateError(err, SELF) {
    if (err.response.status === 423) {
      SELF.setState({
        ...SELF.state,
        loading: false,
        openDialogExpiration: {
          ...SELF.state.openDialogExpiration,
          state: true,
          title: this.props.t("common:Alteração do prazo de perfil"),
          expirationDataUser: err.response.data.users,
        },
      })
    } else {
      return super.handleUpdateError(err, SELF)
    }
  }

  handleCloseDialogExpiration = () => {
    this.setState({
      openDialogExpiration: {
        state: false,
        title: '',
        description: '',
        expirationDataUser: [],
      },
    })
  }

  handleConfirmDialogExpiration = () => {
    if (this.state.requireConfirmation) {
      this.setState({
        ...this.state,
        openDialogExpiration: {
          state: false,
          title: '',
          description: '',
          expirationDataUser: [],
        },
        progress: {
          dialogOpen: true,
          confirm: true,
          message: this.props.t("Confirmação"),
        },
      })
    } else {
      this.setState(
        {
          ...this.state,
          loading: true,
          openDialogExpiration: {
            state: false,
            title: '',
            description: '',
            expirationDataUser: [],
          }
        },
        this.handleUpdate()
      )
    }
  }

  // HANDLER ATTRIBUTE
  handleAddAttribute(attribute) {
    const SELF = this
    const { data } = SELF.state
    const dataAttributes = _.get(data, "attributes") || []
    const newRegister = {
      required: 0,
      id: attribute.value,
      name: attribute.label,
      order: dataAttributes.length,
    }
    dataAttributes.push(newRegister)
    data.attributes = dataAttributes
    data.attribute = null
    SELF.setState({ ...SELF.state, data })
  }

  handleRemoveAttribute(id) {
    let { data } = this.state
    data.attributes = data.attributes.filter((item) => item.id !== id)
    this.setState({ ...this.state, data })
  }

  handleMoveAttribute = ({ oldIndex, newIndex }) => {
    const newState = { ...this.state }
    newState.data.attributes = arrayMove(
      newState.data.attributes,
      oldIndex,
      newIndex
    )
    this.setState(newState)
  }

  handleAddPermission(item) {
    let structures = { ...this.state.structures }

    if (item.entity && !item.region) {
      if (structures["#noregion"].permissions.findIndex(i => i.entity === item.entity) === -1) {
        structures["#noregion"] = {
          permissions: [...structures["#noregion"].permissions, item],
        }
      }
    } else {
      if (_.get(structures, `${item.region.name}`, false)) {
        const dataPermissions = [...structures[item.region.name].permissions]

        const permissionExists = dataPermissions.find(i =>
          i.entity_data[0].name === item.entity_data[0].name &&
          i.entity_data[0].value === item.entity_data[0].value &&
          _.isEqual(i.hierarchy, item.hierarchy))


        if (!permissionExists) {
          const policy = { ...item, id: uuidv4() }
          structures[item.region.name] = {
            permissions: [...structures[item.region.name].permissions, policy],
            thumb: item.region.thumb,
            name: item.region.display_name
          }
        }
      } else {
        const policy = { ...item, id: uuidv4() }
        structures[item.region.name] = {
          permissions: [policy],
          thumb: item.region.thumb,
          name: item.region.display_name
        }
      }

    }

    this.setState({ ...this.state, structures })
  }

  handleChangeTypeAuth(option) {
    this.setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        type_auth: option,
        domain_email: "",
        sso_id: ""
      }
    }))
  }

  handleChangeComboBoxSSO(optionSSO) {
    this.setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        sso_id: optionSSO.value
      },
      formErrors: {
        ...prev.formErrors,
        sso_id: "",
        domain_email: "",
        approvers: "",
      }
    }))
  }

  getFirstPolicyAction = (policies) => {
    const arrayPolicies = Object.entries(policies)
    if (arrayPolicies.length > 0) {
      const first = arrayPolicies[0]
      if (first.length === 2) {
        return first[1].split("-")[0]
      }
    }
    return null
  }

  handleRemovePermission(region, item) {
    let { structures } = this.state
    let permissionsFromRegion = _.get(structures, `${region}.permissions`, [])

    if (permissionsFromRegion.find((el) => el.id === item)) {
      permissionsFromRegion = permissionsFromRegion.filter(
        (el) => el.id !== item
      )
    } else if (permissionsFromRegion.find((el) => el.entity === item)) {
      permissionsFromRegion = permissionsFromRegion.filter(
        (el) => el.entity !== item
      )
      this.setState({ ...this.state, structures: structures })
    }
    structures[region]["permissions"] = permissionsFromRegion

    this.setState({ ...this.state, structures: structures })
  }

  handleChangePolicy(region, permission, item) {
    let { structures } = this.state
    const permissionsFromRegion = _.get(structures, `${region}.permissions`, [])

    const s =
      permissionsFromRegion.find((element) => element.id === item) ||
      permissionsFromRegion.find((element) => element.entity === item)
    let allow = s ? s.allow : null

    if (allow) {
      const found = allow.find((a) => a === permission)

      if (found) {
        allow = allow.filter((el) => el !== permission)
      } else {
        allow.push(permission)
      }
      ; (
        permissionsFromRegion.find((element) => element.id === item) ||
        permissionsFromRegion.find((element) => element.entity === item)
      ).allow = allow

      structures[region]["permissions"] = permissionsFromRegion

      this.setState({ ...this.state, structures: structures })
    }
  }

  handleTypeApproval(type) {
    let newState = { ...this.state }
    newState.type = type
    newState.data.type_approval = type.value
    this.setState(newState)
  }

  handleAddApprover(attributeName, selectOption) {
    let { value, label } = selectOption
    let newState = { ...this.state }
    newState[attributeName].push({ value, label, disabled: true })
    newState.usersInApprovers.push(value)

    this.setState(newState)
  }

  handleRemoveApprover(id) {
    let approvers = this.state.approvers.filter((item) => item.value !== id)
    this.setState({ ...this.state, approvers })
  }

  handleOnFocus(text, nameBox) {
    this.handleChangeComboBox(nameBox, { value: 0, label: this.props.t("common:Sem expiração") })

    this.props.enqueueSnackbar(text, {
      variant: "info",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
        autoHideDuration: 2000,
      },
    })
  }

  onInputChangeExpiration(value, nameField) {
    const valueValid = value.replace(/[^0-9]/g, '')

    if (!Number.isInteger(Number(valueValid))) {
      return
    } else {
      this.setState({ ...this.state, [nameField]: valueValid })
    }
  }

  handleChangeChangeBoxExpiration(nameBox, selectedItem, nameField) {
    this.handleChangeComboBox(nameBox, selectedItem)
    this.setState({ ...this.state, [nameField]: "" })
  }

  getForm() {
    const SELF = this
    let attributesInProfile = []
    let { data } = SELF.state
    let { t } = SELF.props
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      return <div />
    }
    if (
      data.hasOwnProperty("attributes") &&
      Array.isArray(data["attributes"])
    ) {
      data["attributes"].forEach((item) => {
        attributesInProfile.push(item.id)
      })
    } else {
      data["attributes"] = []
    }

    return (
      <React.Fragment>
        <Names
          handleChangeNames={this.handleChangeNames}
          formErrors={_.get(this.state, 'formErrors', {})}
          data={{
            name: _.get(this.state, 'data.name', {}),
            description: _.get(this.state, 'data.description', {}),
          }}
        />

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width" style={{ marginBottom: 20 }}>
            <label className='section'>{t("common:Região de entrada")}</label>
            <p className="subtitle">
              {t("common:Essa região será utilizada no primeiro login realizado pelo usuário.")}
            </p>
          </div>
          <RegionComboBox
            title={t("common:Região")}
            optionSelected={_.get(data, "region.name", "")}
            handleChange={(selectedItem) =>
              SELF.handleChangeComboBox("region", selectedItem)
            }
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width" style={{ marginBottom: 20 }}>
            <label className='section'>{t("common:Informações adicionais")}</label>
          </div>
          <AttributeComboBox
            title={t('common:Selecione o metadado')}
            disabledItems={attributesInProfile}
            value={data["attribute"]}
            handleChange={(selectedItem) =>
              this.handleAddAttribute(selectedItem)
            }
            field_required={false}
            showAllRegions={true}
          />
        </Grid>

        {
          _.get(data, 'attributes', []).length > 0 && (
            <>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <TableAttribute
                  items={data.attributes}
                  style={{ marginTop: 0 }}
                  handleRemoveAttribute={(id, evt) =>
                    this.handleRemoveAttribute(id, evt)
                  }
                  handleCheckAttribute={(id, evt) =>
                    this.handleCheckAttribute(id, evt)
                  }
                  handleMoveAttribute={(obj) => this.handleMoveAttribute(obj)}
                  t={this.props.t}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Divider />
              </Grid>
            </>
          )
        }

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <BoxPermissions
            structures={SELF.state.structures}
            handleChangePolicy={(region, permission, item) =>
              SELF.handleChangePolicy(region, permission, item)
            }
            addPermission={(item) => SELF.handleAddPermission(item)}
            removePermission={(region, item) =>
              SELF.handleRemovePermission(region, item)
            }
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Moderation
          type_approval={SELF.state.type}
          usersInApprovers={SELF.state.usersInApprovers}
          approvers={SELF.state.approvers}
          formErrors={SELF.state.formErrors}
          domain_email={data.domain_email}
          sso_id={data.sso_id}
          typeAuth={data.type_auth}
          handleChangeTypeAuth={SELF.handleChangeTypeAuth.bind(SELF)}
          handleAddApprover={SELF.handleAddApprover.bind(SELF)}
          handleRemoveApprover={SELF.handleRemoveApprover.bind(SELF)}
          handleChange={SELF.handleChange.bind(SELF)}
          handleTypeApproval={SELF.handleTypeApproval.bind(SELF)}
          handleChangeComboBoxSSO={SELF.handleChangeComboBoxSSO.bind(SELF)}
        />

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width">
            <label className='section'>{t("common:Notificações")}</label>
            <p className="subtitle">
              {t(
                "common:Todos os usuários com permissão de visualizar itens do conteúdo acima, receberão notificações sobre as atualizações."
              )}
            </p>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 7 }}>
          <Divider />
        </Grid>


        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width">
            <label className='section'>{t("common:Expiração")}</label>
            <p className="subtitle">
              {t(
                "common:Escolha apenas um dos tipos de expiração de perfil abaixo."
              )}
            </p>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }} className={this.props.classes.rootSelect}>
          <div className="form-field full-width">
            <label>{t("common:Perfil temporário")}</label>
            <p className="subtitle">
              {t(
                "common:Essa funcionalidade aplica o conceito de perfil temporário. Assim que aplicada, o prazo de expiração (bloqueio do usuário) passa a ser contado em dias a partir da data de aprovação da solicitação de acesso deste usuário."
              )}
            </p>
            <Grid item xs={3}>
              <Select
                menuIsOpen={
                  Boolean(data.inactivity_expiration.id > 0) > 0
                    ? false
                    : undefined
                }
                classNamePrefix="select-custom"
                id={"select-attribute"}
                name="expiration"
                value={{
                  label: data.expiration.name,
                  value: data.expiration.id,
                }}
                onChange={(selectedItem) =>
                  SELF.handleChangeChangeBoxExpiration('expiration', selectedItem, 'inputValueExpiration')
                }
                onFocus={() => Boolean(data.inactivity_expiration.id > 0)
                  ? this.handleOnFocus(t("manager:A inatividade em Peril foi desativada."), 'inactivity_expiration')
                  : {}
                }
                onInputChange={(evt) => this.onInputChangeExpiration(evt, 'inputValueExpiration')}
                inputValue={this.state.inputValueExpiration}
                options={this.state.dataSelectExpiration}
                noOptionsMessage={() => t("manager:Nenhuma opção")}
                styles={{
                  menuList: (provided) => ({
                    ...provided,
                    height: 110,
                  }),
                }}
              />
              <div className="error">{SELF.state.formErrors.expiration}</div>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }} className={this.props.classes.rootSelect}>
          <div className="form-field full-width">
            <label>{t("common:Inatividade em perfil")}</label>
            <p className="subtitle">
              {t(
                "common:Essa funcionalidade aplica um prazo, em dias, de inatividade no perfil, onde o usuários que está atrelado a ele será bloqueado no tempo definido, contando a partir do seu último acesso a plataforma."
              )}
            </p>
            <Grid item xs={3}>
              <Select
                menuIsOpen={
                  Boolean(data.expiration.id) > 0
                    ? false
                    : undefined
                }
                classNamePrefix="select-custom"
                id={"select-attribute"}
                name="inactivity_expiration"
                value={{
                  label: data.inactivity_expiration.name,
                  value: data.inactivity_expiration.id,
                }}
                onChange={(selectedItem) =>
                  SELF.handleChangeChangeBoxExpiration('inactivity_expiration', selectedItem, 'inputValueInactivityExpiration')
                }
                onFocus={() => Boolean(data.expiration.id > 0)
                  ? this.handleOnFocus(t("manager:A inatividade em Peril foi desativada."), 'expiration')
                  : {}
                }
                onInputChange={(evt) => this.onInputChangeExpiration(evt, 'inputValueInactivityExpiration')}
                inputValue={this.state.inputValueInactivityExpiration}
                options={this.state.dataSelectExpiration}
                noOptionsMessage={() => t("manager:Nenhuma opção")}
                styles={{
                  menuList: (provided) => ({
                    ...provided,
                    height: 110,
                  }),
                }}
              />
              <div className="error">{SELF.state.formErrors.inactivity_expiration}</div>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }} />

      </React.Fragment>
    )
  }

  getAdditionalRender() {

    return (
      _.get(this.state, 'openDialogExpiration.state', false) && (
        <DialogConfirmExpiration
          handleClose={this.handleCloseDialogExpiration}
          handleConfirm={this.handleConfirmDialogExpiration}
          data={this.state.openDialogExpiration}
        />
      )
    )
  }
}

export default compose(withSnackbar, withStyles(styles),
  connect(mapStateToPropsToSettings,
    mapDispatchToPropsToSettings))(withTranslation(["manager", "common"])(Edit));


