import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

const AlertAttributes = ({
  open,
  t,
  handleClose,
  data
}) => {
  const { palette } = useSelector((state) => state.appReducer);
  const classes = useStyles({ palette });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          <ErrorRoundedIcon id="main" />
          <span>{t('manager:Impossibilidade de permissionamento')}</span>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: '0' }}>
        <DialogContentText id="alert-dialog-description">
          {t('manager:Não é possível permissionar os metadados abaixo pelos seguintes erros')}
        </DialogContentText>

        {
          Object.keys(data).map(function (att, index) {
              return (
                <div  key={index}>
                  <DialogContentText><strong style={{fontWeight: 500}}>{att}</strong> - {t(`manager:${data[att]}`)}</DialogContentText>
                </div>
              )
            })
        }

        <DialogActions className={classes.actions}>
          <Button
            style={{ height: 40, width: 115 }}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {t("common:Voltar")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation(["DAM"])(AlertAttributes);
