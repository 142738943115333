import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";

import DialogConfirm from "components/Dialogs/DialogConfirm";
import UploadActions from "flux/actions/UploadActions";
import AppActions from 'flux/actions/AppActions';
import AuthenticationActions from 'flux/actions/AuthenticationActions';
import { useTranslation } from 'react-i18next';

const UploadWrapper = ({ children }) => {
    const [listening, setListening] = useState(false);
    const [bindChannel, setBindChannel] = useState(true)
    const { cancelDialog, filesInProgressLength } = useSelector(state => state.uploadReducer)
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const listener = useRef(e => {
        const confirmationMessage = t('troca-regiao-upload');
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    });
   
    useEffect(() => {
        if (filesInProgressLength === 0) {
            dispatch(UploadActions.setEvaporate({}))
        }
        if (filesInProgressLength > 0 && !listening) {
            window.addEventListener('beforeunload', listener.current);
            setListening(true)
        } else if (filesInProgressLength === 0 && listening) {
            window.removeEventListener('beforeunload', listener.current);
            setListening(false)
        }
    }, [filesInProgressLength, listening, dispatch]);
    
    useEffect(() => {
        if (bindChannel && filesInProgressLength > 0) {            
            dispatch(
                AppActions.bindToChannel("private-web-platform", "asset-processed", (notification) => {
                    switch (notification.data.status_pipeline) {
                        case `THUMB_GENERATED`:
                        case `DONE`:
                            dispatch(UploadActions.uploadFileProcessed(notification))
                            break;
                        case 'PROCESSING_ERROR':
                            console.log('Erro ao processar imagem em máquina!', notification)
                            dispatch(UploadActions.uploadFileError(notification.data, notification.message))
                            break;
                        default:
                            break;
                    }
                }, "upload")
            )
            setBindChannel(false)
        }
        // TODO: Checar mecanismo para fazer unbind
    }, [filesInProgressLength, bindChannel, dispatch])

    const confirmChange = () =>
        dispatch(UploadActions.uploadCancelAllFiles())
            .then(postCancelData => {                
                if (!!postCancelData) {
                    const { postCancelEvent } = postCancelData;
                    switch (postCancelEvent) {
                        case 'logout':
                            const { target } = postCancelData;
                            dispatch(AuthenticationActions.logout(target));
                            break;
                        case 'changeRegion':
                            const { regionSlug, data, callback } = postCancelData;
                            dispatch(AppActions.changeRegion(regionSlug, data, callback, false));
                            break;
                        default:
                            console.log(t("common:Evento não identificado"), postCancelEvent)
                    }
                }
            })
            .catch(error => {
                console.log(t("DAM:Erro ao tentar cancelar todos os uploads."), error);
                return enqueueSnackbar(t("DAM:Erro ao tentar cancelar todos os uploads."), {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
            });
    const cancelChange = () => dispatch(UploadActions.cancelRegionChange())

    return <React.Fragment>
        {cancelDialog.show &&
            <DialogConfirm
                title={t("common:Cancelar uploads em andamento")}
                open
                description={t(cancelDialog.messageKey)}
                handleClose={cancelChange}
                handleConfirm={confirmChange}
            />
        }
        {children}
    </React.Fragment>
}

export default UploadWrapper;