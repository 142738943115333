import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next"
import Template from "../Template";
import { mapDispatchToPropsToSettings, mapStateToPropsToSettings } from "utils/Flux";
import connect from "react-redux/es/connect/connect";
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Typography,
    Divider
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WrapperPaperForms } from 'components/WrapperForms'

const Usage = (props) => {
    const { t } = useTranslation()
    const attributeId = props.match.params.id
    const { requestHeaders, pathServer } = props
    const [data, setData] = useState([])
    const getData = useCallback(() => {
        axios
            .get(`${pathServer}/attribute/${attributeId}/references`, requestHeaders)
            .then((response) => setData(response.data))
    }, [pathServer, attributeId, requestHeaders])

    useEffect(() => {
        let isMoutend = true
        if (isMoutend) getData()
        return () => {
            isMoutend = false
        }
    }, [getData])

    return (
        <>
            <Template area={3} localHistory={props.history} customClassName={"container-center"}>
                <WrapperPaperForms title={t("manager:Detalhes do metadado")}>
                    <Grid>
                        <Typography variant="body1">
                            {t(`manager:está sendo utilizado em`, { name: data.name })}:
                        </Typography>
                    </Grid>
                    <Grid style={{ marginTop: 20 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontWeight: 500 }}>{t("common:Categoria")}</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <Grid container>
                                    {
                                        data.categories && data.categories.length > 0 ?
                                            data.categories.map((i, k) => <Grid key={`${k}-${i}`} item xs={12}><Typography style={{ marginTop: 23 }}>{i}</Typography></Grid>) :

                                            <Typography style={{ marginTop: 15 }}>{t("manager:Este metadado não está associado a nenhuma categoria")}</Typography>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontWeight: 500 }}>{t("common:Perfil")}</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <Grid container>
                                    {
                                        data.profiles && data.profiles.length > 0 ?
                                            data.profiles.map((i, k) => <Grid key={`${k}-${i}`} item xs={12}><Typography style={{ marginTop: 23 }}>{i}</Typography></Grid>) :

                                            <Typography style={{ marginTop: 15 }}>{t("manager:Este metadado não está associado a nenhum perfil")}</Typography>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontWeight: 500 }}>{t("common:Ferramenta de solicitação")}</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <Grid container>
                                    {
                                        data.tools && data.tools.length > 0 ?
                                            data.tools.map((i, k) => <Grid key={`${k}-${i}`} item xs={12}><Typography style={{ marginTop: 23 }}>{i}</Typography></Grid>) :

                                            <Typography style={{ marginTop: 15 }}>{t("manager:Este metadado não está associado a nenhuma ferramenta de solicitação")}</Typography>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontWeight: 500 }}>{t("manager:Nome alternativo")}</Typography>
                            </AccordionSummary>
                            <Divider />

                            <AccordionDetails>
                                <Grid container>
                                    {
                                        data.names && data.names.length > 0 ?
                                            data.names.map((i, k) => <Grid key={`${k}-${i}`} item xs={12}><Typography style={{ marginTop: 23 }}>{i}</Typography></Grid>) :

                                            <Typography style={{ marginTop: 15 }}>{t("manager:Este metadado não está associado a nenhuma ferramenta de solicitação")}</Typography>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                        <Button
                            style={{ height: 40, width: 115, marginRight: 20, marginTop: 20 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => props.history.push("/manager/metadata/list")}>
                            {t("common:Voltar")}
                        </Button>
                    </div>
                </WrapperPaperForms>
            </Template>
        </>
    )
}

export default connect(mapStateToPropsToSettings, mapDispatchToPropsToSettings)(withTranslation(['manager', 'common'])(Usage));
