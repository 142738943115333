import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperWidthMd': {
      margin: '0 !important',
      padding: '40px',
      minWidth: '550px'
    }
  },
  title: {
    padding: '0 0 16px 0',
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      color: "#666",
      '& div.info': {
        display: "flex",
        alignItems: 'center',
      },
      "& * svg": {
        width: "40px",
        height: "40px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        padding: "4px",
        marginRight: "10px",
        fill: "#fff",
      },
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 135,
    border: `2px dashed ${theme.palette.primary.main}`,
    cursor: 'pointer',
  },
  contentFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 135,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    "& .content": {
      display: 'flex',
      alignItems: 'center',
      "& svg.icon": {
        marginRight: 16
      }
    }
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: '0',
    marginTop: '20px',
  }  
}));
