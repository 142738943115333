import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Menu, makeStyles, Tooltip, Button } from '@material-ui/core';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';

import DateFilter from './DateFilter'

const useStyles = makeStyles((theme) => ({
    rootButton: {
        '& span.MuiButton-startIcon': {
            margin: 0
        }
    },
    rootMenu: {
        [theme.breakpoints.down("sm")]: {
            '& .MuiPaper-root': {
                top: '60px !important',
                maxWidth: '100% !important',
                width: '100% !important',
                left: '0 !important',
                height: 'calc(100vh - 60px) !important',
                maxHeight: 'calc(100vh - 60px) !important',
                borderRadius: '0 !important',
                '& .rdrMonth': {
                    borderRight: 'none !important',
                    width: '100% !important'
                },
                '& .rdrCalendarWrapper': {
                    width: '100%'
                }
            }
        }
    }
}));

const MenuSortingView = ({
    data,
    handleMenuItemClick,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()
    const { t } = useTranslation()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (values) => {
        handleMenuItemClick(values)
        setAnchorEl(null);
    }

    return (
        <>
            <Tooltip title={t("common:Período")} placement="top">
                <div className={classes.rootButton}>
                    <Button
                        style={!_.isEmpty(data) ? {} : { color: '#666' }}
                        color={!_.isEmpty(data) ? "primary" : "default"}
                        endIcon={
                            Boolean(anchorEl)
                                ? <KeyboardArrowUpRoundedIcon fontSize='small' />
                                : <KeyboardArrowDownRoundedIcon fontSize='small' />
                        }
                        onClick={handleClick}
                    >
                        {t("common:Período")}
                    </Button>

                </div>
            </Tooltip>

            <Menu
                id="menu-ordination"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.rootMenu}
            >
                <li>
                    <DateFilter
                        handleSubmit={handleClickMenu}
                        startDate={_.get(data, 'start', null)}
                        endDate={_.get(data, 'end', null)}
                        handleClose={handleClose}
                    />
                </li>
            </Menu>
        </>
    )
}

MenuSortingView.defaultProps = {
    title: '',
    options: [],
    data: {},
    handleMenuItemClick: () => { }
}

MenuSortingView.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    data: PropTypes.object,
    handleMenuItemClick: PropTypes.func,

}

export default MenuSortingView