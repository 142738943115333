import React, { useState, useCallback, useEffect } from 'react'
import { ChromePicker } from 'react-color'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import Settings from 'utils/Settings'

import {
    Grid,
    Typography
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles"
import AppActions from "flux/actions/AppActions"

const useStyles = makeStyles((theme) => ({
    content: {
        '& p': {
            marginBottom: 15
        }
    },
    color: {
        width: '58px',
        height: '25px',
        borderRadius: '2px',
        background: props => props.current,
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: props => props.loading ? 'no-drop' : 'pointer',
        opacity: props => props.loading ? .5 : 1,
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
        marginTop: 5,
        marginLeft: 32
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
}))

const FormLayout = () => {
    const [state, setState] = useState({
        loading: true, displayColorPicker: false, colorPrimary: { last: '', current: '' }
    })
    const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
    const ID_PLATFORM = useSelector(state =>
        _.get(state, 'appReducer.data.client_id', '')
    )
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const classes = useStyles({ ...state.colorPrimary, loading: state.loading })
    const dispatch = useDispatch()

    const getData = useCallback(() => {       
        axios.get(pathServer + "/client/layout", requestHeaders)
            .then(function (resp) {
                const { primary_color } = resp.data;
                setState(prev => ({
                    ...prev,
                    colorPrimary: { last: primary_color, current: primary_color },
                    loading: false
                }))
            })
            .catch((err) => {
                console.log(err)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");

                setState(prev => ({ ...prev, loading: false }),
                    enqueueSnackbar(message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    }))
            });

    }, [pathServer, requestHeaders, enqueueSnackbar, t])

    useEffect(() => {
        getData()
        return () => { }
    }, [getData])


    const validateFields = (color) => {
        const newData = { ...color }

        if (/^#[0-9A-F]{6}$/i.test(newData.value)) {
            newData['error_color'] = false
        } else {
            enqueueSnackbar(t('manager:Campo deve ser hexadecimal. #XXXXXX'), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            })
            newData['error_color'] = true
        }

        const invalidForm = [newData].some(i => i.error_color)
        setState(prev => ({ ...prev, colorPrimary: newData }))
        return invalidForm
    }

    const submitForm = (color) => {
        if (validateFields(color)) {
            return
        }

        const callback = () => {
            const valueColor = _.get(color, 'value', Settings.COLOR_PLATFORM_DEFAULT)
            axios.put(`${pathServer}/client/${ID_PLATFORM}`, { primary_color: valueColor }, requestHeaders)
                .then((resp) => {                   
                    setState(prev => ({
                        ...prev,
                        loading: false,
                        colorPrimary: { last: prev.colorPrimary.current, current: valueColor },                        
                    }), dispatch(AppActions.setColorPlatform(valueColor)))
                })
                .catch((err) => {
                    console.log(err)
                    const message = err.response
                        ? err.response.data.message || err.response.headers["x-message"]
                        : t("Erro inesperado. Contate o suporte");

                    setState(prev => ({ ...prev, loading: false }), enqueueSnackbar(message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    }))
                });
        }

        setState(prev => ({ ...prev, loading: true, displayColorPicker: false }), callback())
    }

    const handleClick = () => {
        if (!state.loading) {
            setState(prev => ({ ...prev, displayColorPicker: !prev.displayColorPicker }))
        } else {
            return null
        }
    }

    const handleClose = () => {
        if (_.get(state, 'colorPrimary.current', '') !== _.get(state, 'colorPrimary.last', '')) {
            submitForm({ value: _.get(state, 'colorPrimary.current', '') })
        } else {
            setState(prev => ({ ...prev, displayColorPicker: false }))
        }
    };

    const handleChange = (color) => {
        setState(prev => ({ ...prev, colorPrimary: { ...prev.last, current: color.hex } }))
    };

    return (
        <div>
            <Grid item xs={12} className={classes.content}>
                <Typography variant="body1">
                    {t("manager:Cor primária")}:
                </Typography>
                <div className={classes.swatch} onClick={handleClick}>
                    <div className={classes.color} />
                </div>
            </Grid>

            {state.displayColorPicker ? <div className={classes.popover}>
                <div className={classes.cover} onClick={handleClose} />
                <ChromePicker color={_.get(state, 'colorPrimary.current', '')} onChange={handleChange} />
            </div> : null}

        </div>
    )
}


export default FormLayout