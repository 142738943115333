import * as ActionTypes from '../actionTypes';
import Utils from "utils/Utils";

const actions = {}

actions.logout = (target = "", history) => {
  return async (dispatch, getState) => {
    const { uploadReducer: { uploadFiles } } = getState()

    const redirect = () => {
      dispatch({ type: ActionTypes.CLEAR_USER_DATA, target })
      dispatch({type: "items/RESET_SESSION_STORAGE" })
      try {
        history.push('/login')
      }
      catch (e) {
        Utils.gotoLogout()
      }
    }

    if (uploadFiles.some(i => !i.uploadFinished)) {
      dispatch({
        type: ActionTypes.SHOW_UPLOAD_CANCEL_DIALOG,
        messageKey: "logout-upload",
        postCancelData: { postCancelEvent: 'logout', target: 'logout' }
      })
      return
    }

    redirect()
  }
}

export default actions