import React, { useEffect, useCallback } from 'react';
import axios from 'axios'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AppActions from "flux/actions/AppActions";
import { Creators as HeaderAppActions } from "flux/ducks/header_app"

import { styleRoot, styleHeader, getDescription } from './utils'
import {
  Typography,
} from "@material-ui/core"

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import NotificationsIcon from '@material-ui/icons/NotificationsActive';
import Settings from 'utils/Settings'

import useDownloadCentral from 'pages/DownloadCentral/useDownloadCentral'

export default function NotificationsPusher() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const token = useSelector(state => state.appReducer.userData.user_token)
  const { pathServer, pathClient, requestHeaders } = useSelector(state => state.appReducer)
  const { t } = useTranslation()
  const { downloadFile } = useDownloadCentral()

  const getCountNotification = useCallback((path, callback) => {
    axios.post(path, {}, requestHeaders)
      .then((result) => {
        dispatch(callback({ data: _.get(result, 'data.count', 0) }))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [requestHeaders, dispatch])

  const contentSnackbar = useCallback((key, data) => {
    const { message, title = "Notificações" } = data
    return (
      <div key={key} style={styleRoot}>
        <div style={styleHeader}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NotificationsIcon fontSize='small' style={{ marginRight: 10 }} />
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              {t(`common:${title}`)}
            </Typography>
          </div>
          <button onClick={() => closeSnackbar(key)}
            style={{
              border: 'none',
              background: 'transparent',
              textAlign: 'left',
              padding: 0,
              fontFamily: 'inherit',
              cursor: "pointer",
            }}
          >
            <CloseRoundedIcon fontSize='small' style={{ color: '#fff' }} />
          </button>
        </div>
        <div className='message'>
          <Typography variant="body2">
            {message}
          </Typography>
        </div>
      </div>
    )
  }, [t, closeSnackbar])

  useEffect(() => {
    dispatch(AppActions.bindToChannel("private-web-platform", "asset-processed-thumbnail", ({ message }) => {
      if (window.location.pathname.indexOf("bucket") > -1) {
        return;
      }
      enqueueSnackbar(message, {
        autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        content: (key, message) => contentSnackbar(key, { message })
      });
    }, "notifications-pusher"
    ));

    dispatch(AppActions.bindToChannel("private-web-platform", "new-notification",
      ({ details, event }) => {
        enqueueSnackbar(getDescription(event, details), {
          autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          content: (key, message) => contentSnackbar(key, { message })
        }, getCountNotification(`${pathClient}/notification/pending`, HeaderAppActions.setCountNotifications));
      }, "notifications-pusher"
    ));

    dispatch(AppActions.bindToChannel("private-web-platform", "download-finished", (data) => {     
      if(_.get(data, 'state', '') === "generated")downloadFile(data, "automatic") 
      getCountNotification(`${pathServer}/item/download/countUnread`, HeaderAppActions.setDownloadCount)
    }, "notifications-pusher"
    ));
    return () => dispatch(AppActions.unbindContext("private-web-platform", "notifications-pusher"))

  }, [enqueueSnackbar, dispatch, token, contentSnackbar, t, getCountNotification, pathClient, pathServer, downloadFile])

  return null
}
