import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector';

import Settings from "utils/Settings"

const resources = {
  pt: {
    common: {
      "Esquema": "Esquema",
      "Esquema de Metadados": "Esquema de Metadados",
      "A categoria trata-se de uma galeria, portanto o nome do item terá o mesmo nome do arquivo.": "A categoria trata-se de uma galeria, portanto o nome do item terá o mesmo nome do arquivo.",
      "x horas e y minutos": "{{hours}} horas e {{minutes}} minutos",
      "x horas": "{{hours}} horas",
      "Erro ao criar metadado.": "Erro ao criar metadado.",
      "Digite o valor desejado e aperte ENTER ou TAB para adicionar. Para lista de valores, utilize vírgula (,) para separar.": "Digite o valor desejado e aperte ENTER ou TAB para adicionar. Para lista de valores, utilize vírgula (,) para separar.",
      "Moderadores": "Moderadores",
      "incomplete-allowed": "Cadastro incompleto",
      "Publicação dos Itens": "Publicação dos Itens",
      "Defina seu ZIP": "Defina seu ZIP",
      "Itens dentro do ZIP": "Itens dentro do ZIP",
      "Cada Item em uma pasta": "Cada Item em uma pasta",
      "Todos os Itens em uma única pasta": "Todos os Itens em uma única pasta",
      "solicitar download": "solicitar download",
      "Acompanhe na Central de downloads a sua solicitação. Uma notificação será enviada quando os Itens estiverem prontos.": "Acompanhe na Central de downloads a sua solicitação. Uma notificação será enviada quando os Itens estiverem prontos.",
      "Verifique as atualizações dos itens e o Link para compartilhamento.": "Verifique as atualizações dos itens e o Link para compartilhamento.",
      "Não há downloads": "Não há downloads",
      "Contate o administrador ou solicite a permissão para acessar o conteúdo.": "Contate o administrador ou solicite a permissão para acessar o conteúdo.",
      "Expirado": "Expirado",
      "Processando": "Processando",
      "Selecione o formato": "Selecione o formato",
      "Se desejar, nomeie o seu ZIP": "Se desejar, nomeie o seu ZIP",
      "Receber notificação": "Receber notificação",
      "Na plataforma": "Na plataforma",
      "Por e-mail": "Por e-mail",
      "iniciar download": "iniciar download",
      "Central de download": "Central de download",
      "Paleta de cores": "Paleta de cores",
      "Visualize as cores que se destacam no item": "Visualize as cores que se destacam no item",
      "Imagem web em alta": "Imagem web em alta",
      "Imagem web em baixa": "Imagem web em baixa",
      "Arquivo original": "Arquivo original",
      "Ativo original": "Ativo original",
      "Tarefa": "Tarefa",
      "Selecione qual ferramenta você deseja criar": "Selecione qual ferramenta você deseja criar",
      "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.": "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.",
      "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta.": "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta.",
      "commentTaksNotification": "{{ NAME_USER }} <2>comentou</2> na tarefa {{ taks_name }}",
      "Limpar comentário": "Limpar comentário",
      "Uploads de arquivos": "Uploads de arquivos",
      "Anexar": "Anexar",
      "task_recusar": "Reprovado",
      "task_invalidar": "Invalidado",
      "task_validar": "Válido",
      "self-approved": "Auto aprovado",
      "no-allower-info": "Sem informações de aprovador",
      "allower-was-purged": "Usuário removido",
      "Selecione o segmento": "Selecione o segmento",
      "Selecione a categoria": "Selecione a categoria",
      "Adicione um comentário": "Adicione um comentário",
      "Adicione um comentário...": "Adicione um comentário...",
      "Copiar link": "Copiar link",
      "Link copiado com sucesso": "Link copiado com sucesso",
      "Compartilhar por link": "Compartilhar por link",
      "Compartilhar por e-mail": "Compartilhar por e-mail",
      "Como você deseja compartilhar?": "Como você deseja compartilhar?",
      "Escolha o nome para o compartilhamento.": "Escolha o nome para o compartilhamento.",
      "Uma notificação será enviada quando o link estiver pronto": "Uma notificação será enviada quando o link estiver pronto",
      "última atualização": "última atualização",
      "sem registro": "sem registro",
      "Operação concluída.": "Operação concluída",
      "shared-generated": "Link para compartilhamento gerado com sucesso.",
      "Ferramentas": "Ferramentas",
      "Ferramenta": "Ferramenta",
      "ferramenta": "ferramenta",
      "Atalho": "Atalho",
      "Tamanho": "Tamanho",
      "Adicione um comentário sobre a publicação deste item": "Adicione um comentário sobre a publicação deste item",
      "Aprovação de usuário": "Aprovação de usuário",
      "Reprovação de usuário": "Reprovação de usuário",
      "Você tem certeza que deseja publicar o item?": "Você tem certeza que deseja publicar o item?",
      "Você tem certeza que deseja despublicar o item?": "Você tem certeza que deseja despublicar o item?",
      "Não há registro de publicação deste item.": "Não há registro de publicação deste item.",
      "Comentário da publicação": "Comentário da publicação",
      "Acompanhe o histórico de publicação do item": "Acompanhe o histórico de publicação do item",
      "o item em": "{{user}} {{operation}} o item em {{date}}",
      "pela-vigencia": "pela Vigência",
      "pelo-usuario": "pelo Usuário",
      "por no dia": "{{operation}} {{reason}} {{user}} em {{date}}",
      "Galeria de itens": "Galeria de itens",
      "Ao tornar a Categoria uma galeria, cada item conterá apenas um (01) ativo. Ela ficará visível como um Atalho e não será possível alterar a categoria após a sua criação.": "Ao tornar a Categoria uma galeria, cada item conterá apenas um (01) ativo. Ela ficará visível como um Atalho e não será possível alterar a categoria após a sua criação.",
      "Para compartilhar com pessoas externas, insira abaixo o e-mail dos destinatários": "Para compartilhar com pessoas externas, insira abaixo o e-mail dos destinatários",
      "Insira os e-mails": "Insira os e-mails",
      "Distribuições": "Distribuições",
      "Utilização do nome alternativo": "Utilização do nome alternativo",
      "Este nome alternativo não está associado a nenhuma categoria": "Este nome alternativo não está associado a nenhuma categoria",
      "Este nome alternativo não está associado a nenhuma distribuição": "Este nome alternativo não está associado a nenhuma distribuição",
      "reenviar convite": "reenviar convite",
      "Oops! Link indisponível": "Oops! Link indisponível",
      "Entre em contato com  o gestor de conteúdo para reenviar o convite ou faça a solicitação de acesso e aguarde a moderação.": "Entre em contato com  o gestor de conteúdo para reenviar o convite ou faça a solicitação de acesso e aguarde a moderação.",
      "approved": "Aprovado",
      "disapproved": "Reprovado",
      "blocked": "Bloqueado",
      "requested": "Usuário não foi moderado",
      "invited": "Convidado",
      "invited-registered": "Convidado cadastrado",
      "invited-expired": "Convidado expirado",
      "allowed": "Auto aprovado",
      "Anfitrião": "Anfitrião",
      "Data de criação": "Data de criação",
      "Data de aprovação": "Data de aprovação",
      "Segurança de senha": "Segurança de senha",
      " sua senha deve ter um mínimo de 8 caracteres contendo letras, números e caractere especial.": " sua senha deve ter um mínimo de 8 caracteres contendo letras, números e caractere especial.",
      "Uma notificação será enviada quando os itens estiverem prontos para o download.": "Uma notificação será enviada quando os itens estiverem prontos para o download.",
      "Link expirado": "Link expirado",
      "Geral": "Geral",
      "Limite de itens selecionados": "Limite de {{value}} itens selecionados",
      "Todos os itens dessa página foram selecionados.": "Todos os {{name}} itens dessa página foram selecionados.",
      "ativo": "ativo",
      "ativos": "ativos",
      "Deseja publicar o item": "Deseja publicar o item {{name}}",
      "Deseja despublicar o item": "Deseja despublicar o item {{name}}",
      "Publicar": "Publicar",
      "Despublicar": "Despublicar",
      "Miniatura": "Miniatura",
      "Nome do item": "Nome do item",
      "Ativos": "Ativos",
      "Status": "Status",
      "Data de vigência": "Data de vigência",
      "Visualizar em card": "Visualizar em card",
      "Visualizar em lista": "Visualizar em lista",
      "Caso queira nomear o seu arquivo ZIP com o download realizado,  escreva aqui": "Caso queira nomear o seu arquivo ZIP com o download realizado,  escreva aqui",
      "Incompatível com as categorias  de todos os itens": "Incompatível com as categorias  de todos os itens",
      "Download em andamento": "Download em andamento",
      "Exclusão em andamento": "Exclusão em andamento",
      "Você receberá uma notificação quando o download for concluído.": "Você receberá uma notificação quando o download for concluído.",
      "Nome original": "Nome original",
      "Nome alternativo": "Nome alternativo",
      "Nome alternativo em espanhol": "Nome alternativo em espanhol",
      "Nome alternativo em inglês": "Nome alternativo em inglês",
      "Utilizado em": "Utilizado em",
      "Adicionar metadado a lista de": "Adicionar metadado a lista de {{name}}",
      "adicionar metadado a lista": "adicionar metadado a lista",
      "Este campo contem itens duplicados": "Este campo contem itens duplicados",
      "Este campo deve conter mais que 3 caracteres": "Este campo deve conter mais que 3 caracteres",
      "Alerta": "Alerta",
      "aqui": "aqui",
      "Saiba mais  ": "Saiba mais  ",
      "Ao criar um metadado, verifique se são dados de acordo com a LGPD.": "Ao criar um metadado, verifique se são dados de acordo com a LGPD.",
      "Mais zoom": "Mais zoom",
      "Menos zoom": "Menos zoom",
      "Reiniciar": "Reiniciar",
      "Pode selecionar no maximo itens": "Pode selecionar no maximo {{count}} itens",
      "O ativo que não possuir suporte para as especificações será mantido apenas ": "O ativo que não possuir suporte para as especificações será mantido apenas ",
      "Os ativos não possuem especificações em comum.": "Os ativos não possuem especificações em comum.",
      "Ativos originais": "Ativos originais",
      "Data inicial maior que a data final": "Data inicial maior que a data final",
      "Selecione uma data ou um período.": "Selecione uma data ou um período.",
      "na versão original": "na versão original",
      "iten_count": "{{count}} item",
      "itens_count": "{{count}} itens",
      "Nenhum dado encontrado.": "Nenhum dado encontrado.",
      "Falha ao carregar dados.": "Falha ao carregar dados.",
      "Todas": "Todas",
      "Nenhuma data selecionada": "Nenhuma data selecionada",
      "Aberta": "Aberta",
      "Em execução": "Em execução",
      "Finalizada": "Finalizada",
      "Imagens da web em alta qualidade": "Imagens da web em alta qualidade",
      "Imagens da web em baixa qualidade": "Imagens da web em baixa qualidade",
      "Imagens da web em alta qualidade_required": "Imagens da web em alta qualidade *",
      "Imagens da web em baixa qualidade_required": "Imagens da web em baixa qualidade *",
      "O ativo que não possuir suporte para as especificações será mantido na versão original.": "O ativo que não possuir suporte para as especificações será mantido na versão original.",
      "Especificações de imagem": "Especificações de imagem",
      "Configurações avançadas": "Configurações avançadas",
      "Marcador": "Marcador",
      "TAG automatizada": "TAG automatizada",
      "Ao escolher essa opção, somente ativos digitais do tipo imagem serão disponibilizados no download.": "Ao escolher essa opção, somente ativos digitais do tipo imagem serão disponibilizados no download.",
      "Segurança": "Segurança",
      "o item": "o item {{item_name}}",
      "item relacionado": "{{qtd}} item relacionado",
      "itens relacionados": "{{qtd}} itens relacionados",
      "Itens 3D": "Itens 3D",
      "Buscar texturas": "Buscar texturas",
      "Selecione a textura para o objeto 3D": "Selecione a textura para o objeto 3D",
      "data_hora": "data_hora",
      "Download em massa": "Download em massa",
      "download-generated": "Solicitação pronta. Acesse a Central para baixar.",
      "download-error-generated": "Erro ao realizar o <2>download em massa.</2>",
      "data de vigência": "data de vigência",
      "Vigência de uso do item até": "Vigência de uso do item até",
      "Escolha qual a melhor opção de publicação do item.": "Escolha qual a melhor opção de publicação do item.",
      "Ferramenta de solicitação": "Ferramenta de solicitação",
      "Ferramentas de solicitação": "Ferramentas de solicitação",
      "Não": "Não",
      "O que deseja fazer?": "O que deseja fazer?",
      "Publicação do item ou data de vigência alteradas": "Publicação do item ou data de vigência alteradas",
      "Confirmação de Email": "Confirmação de Email",
      "Marcadores": "Marcadores",
      "Selecione pelo nome": "Selecione pelo nome",
      "Arquivos": "Arquivos",
      "Arquivo": "Arquivo",
      "arquivo": "arquivo",
      "arquivos": "arquivos",
      "processando arquivo": "processando arquivo",
      'Tentar novamente': 'Tentar novamente',
      'Reprocessar arquivo': 'Reprocessar arquivo',
      "Especificações": "Especificações",
      "Entendi": "Entendi",
      "Executor": "Executor",
      "Selecione": "Selecione",
      "Erro ao deletar, contate o administrador.": "Erro ao deletar, contate o administrador.",
      "Erro ao deletar, tente novamente.": "Erro ao deletar, tente novamente.",
      "Gerenciador de usuários": "Gerenciador de usuários",
      "Bloqueado": "Bloqueado",
      "Pendente": "Pendente",
      "Criação de segmento": "Criação de segmento",
      "Edição de segmento": "Edição de segmento",
      "Alterando Segmento": "Alterando Segmento",
      "Validação de campo inexistente": "Validação de campo inexistente",
      "Fuso Horário": "Fuso Horário",
      "dia": "dia",
      "dias": "dias",
      "Sim": "Sim",
      "Confirme a nova senha": "Confirme a nova senha",
      "Alteração de senha": "Alteração de senha",
      "Expiração do login": "Expiração do login",
      "Edição de dados da conta": "Edição de dados da conta",
      "Ir para o DAM": "Ir para o DAM",
      "Avaliar": "Avaliar",
      "item": "item",
      "itens": "itens",
      "selecionados": "selecionados",
      "Você não tem permissão para compartilhar estes itens": "Você não tem permissão para compartilhar estes itens",
      "Compartilhar com pessoas externas": "Compartilhar com pessoas externas",
      "não estou de acordo": "não estou de acordo",
      "estou de acordo": "estou de acordo",
      "Sua sessão foi expirada. Favor logar novamente para poder prosseguir com suas atividades.": "Sua sessão foi expirada. Favor logar novamente para poder prosseguir com suas atividades.",
      "Sessão Expirada.": "Sessão Expirada.",
      "Tem certeza que deseja realizar essa alteração?": "Tem certeza que deseja realizar essa alteração?",
      "Não encontramos o que você procura. Que tal uma nova busca?": "Não encontramos o que você procura. Que tal uma nova busca?",
      "Solicitar acesso": "Solicitar acesso",
      "Solicitações": "Solicitações",
      "Alterar Região": "Alterar Região",
      "Aplicações": "Aplicações",
      "Configurações": "Configurações",
      "Buscar no DAM": "Buscar no DAM",
      "Buscar no Bucket": "Buscar no Bucket",
      "Notificações": "Notificações",
      "Erro ao carregar dados da imagem.": "Erro ao carregar dados da imagem.",
      "Ordenar itens de A-Z": "Ordenar itens de A-Z",
      "Ordenar itens de Z-A": "Ordenar itens de Z-A",
      "Ordenar itens pelos mais recentes": "Ordenar itens pelos mais recentes",
      "Ordenar itens pelos mais antigos": "Ordenar itens pelos mais antigos",
      "Selecionar": "Selecionar",
      "Ordenação de itens": "Ordenação de itens",
      "Todos os": "Todos os",
      "Não selecionar nenhum item": "Não selecionar nenhum item",
      "Seleção de itens": "Seleção de itens",
      "Somente os": "Somente os",
      "Todos": "Todos",
      "Publicados": "Publicados",
      "Despublicados": "Despublicados",
      "itens publicados": "itens publicados",
      "Visualização de itens": "Visualização de itens",
      "Evento não identificado": "Evento não identificado",
      "Limite de 40 caracteres por tag foi excedido.": "Limite de 40 caracteres por tag foi excedido.",
      "Limite de 10 tags por assets foi excedido.": "Limite de 10 tags por assets foi excedido.",
      "Escreva aqui": "Escreva aqui",
      "Nome da categoria": "Nome da categoria",
      "Expiração": "Expiração",
      "aprovar": "aprovar",
      "reprovar": "reprovar",
      "Revogar e excluir acesso de": "Revogar e excluir acesso de",
      "desbloquear": "desbloquear",
      "Tem certeza que deseja desbloquear?": "Tem certeza que deseja desbloquear {{name}}?",
      "Tem certeza que deseja aprovar?": "Tem certeza que deseja aprovar {{name}}?",
      "Tem certeza que deseja reprovar?": "Tem certeza que deseja reprovar {{name}}?",
      "Tem certeza que deseja revogar e excluir o acesso de?": "Tem certeza que deseja revogar e excluir o acesso de {{name}}?",
      "Situação": "Situação",
      "A alteração feita será aplicada na Ferramenta. Deseja continuar?": "A alteração feita será aplicada na Ferramenta {{name}}. Deseja continuar?",
      "Está gostando da plataforma?": "Está gostando da plataforma {{name}}?",
      "Gostaríamos de saber como tem sido a sua experiência com para aperfeiçoarmos cada vez mais.": "Gostaríamos de saber como tem sido a sua experiência com {{name}} para aperfeiçoarmos cada vez mais.",
      "Política de privacidade da": "Política de privacidade da {{name}}",
      "Política de privacidade": "Política de privacidade",
      "Avalie a": "Avalie {{name}}",
      "nps-access-request": "Com o seu consentimento, a Yapoli utiliza seus dados pessoais, como <1>nome completo</1> e <1>e-mail</1>, com a finalidade da utilização da plataforma {{dataClientName}}. Além disso, os dados mencionados podem ser alterados/excluidos a qualquer momento através do e-mail dpo@yapoli.com.",
      "qtd_arquivo": "{{qtd}} arquivo",
      "qtd_arquivos": "{{qtd}} arquivos",
      "Gerando o arquivo para download…": "Gerando o arquivo {{name}} para download…",
      "para exclusão": "{{name}} para exclusão",
      "Você deseja realmente excluir": "Você deseja realmente excluir {{name}}?",
      "Excluir-entity": "Excluir {{name}}",
      "ativo encontrado para a ": "{{qtd}} ativo encontrado para a ",
      "ativos encontrados para a ": "{{qtd}} ativos encontrados para a ",
      "requerido por atualizado em": "requerido por {{client}} atualizado em ",
      "Lamentamos que você não esteja de acordo com os Termos de uso e Política de privacidade requerido por. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.":
        "Lamentamos que você não esteja de acordo com os Termos de uso e Política de privacidade requerido por {{client}}. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.",
      "versões encontradas": "{{qtd}} versões encontradas",
      "versão encontrada": "{{qtd}} versão encontrada",
      "itens encontrados": "{{qtd}} itens encontrados",
      "item encontrado": "{{qtd}} item encontrado",
      "Item criado": "Item criado {{name}}",
      "item criado": "item criado {{name}}",
      "arquivo associado ao item": "arquivo associado ao item {{name}}",
      "Insira abaixo os e-mails dos destinatários para compartilhar.": "Insira abaixo os e-mails dos destinatários para compartilhar.",
      "Mostrar arquivo selecionado": "Mostrar {{asset}} arquivo selecionado.",
      "Mostrar arquivos selecionados":
        "Mostrar {{assets}} arquivos selecionados.",
      "Excluir arquivo selecionado": "Excluir {{total}} arquivo selecionado",
      "Excluir arquivos selecionados":
        "Excluir {{total}} arquivos selecionados",
      "Excluir item selecionado": "Excluir {{total}} item selecionado",
      "Excluir itens selecionados": "Excluir {{total}} itens selecionados",
      "Você deseja realmente excluir o arquivo selecionado?":
        "Você deseja realmente excluir o arquivo selecionado?",
      "Você deseja realmente excluir todos os arquivos selecionados?":
        "Você deseja realmente excluir todos os {{total}} arquivos selecionados?",
      "Excluir arquivo": "Excluir {{total}} arquivo",
      "Excluir arquivos": "Excluir {{total}} arquivos",
      "Você deseja realmente excluir o item selecionado?":
        "Você deseja realmente excluir o item selecionado?",
      "Você deseja realmente excluir todos os itens selecionados?":
        "Você deseja realmente excluir todos os {{total}} itens selecionados?",
      "Excluir item": "Excluir {{total}} item",
      "Excluir itens": "Excluir {{total}} itens",
      "Solicitação de acesso": "Solicitação de acesso",
      "O formulário tem erros":
        "O formulário tem erros. Corrija para prosseguir.",
      "Campo obrigatório": "Campo obrigatório",
      "Cliente": "Cliente",
      "Região": "Região",
      "Regiões": "Regiões",
      "Divisão": "Divisão",
      "Divisões": "Divisões",
      "Segmento": "Segmento",
      "Segmentos": "Segmentos",
      "Plataforma": "Plataforma",
      "Metadado": "Metadado",
      "Metadados": "Metadados",
      "Categoria": "Categoria",
      "Categorias": "Categorias",
      "Gerenciador": "Gerenciador",
      "Ferramenta de distribuição": "Ferramenta de distribuição",
      "Itens": "Itens",
      "Item": "Item",
      "Perfil": "Perfil",
      "perfil": "perfil",
      "Perfis": "Perfis",
      "Usuário": "Usuário",
      "Usuários": "Usuários",
      "Moderação": "Moderação",
      "Carregando": "Carregando",
      "Dados retornados com sucesso": "Dados retornados com sucesso.",
      "Nenhum resultado encontrado": "Nenhum resultado encontrado",
      "Busca": "Busca",
      "Cancelar": "Cancelar",
      "Confirmar": "Confirmar",
      "Criar": "Criar",
      "Confirmação": "Confirmação",
      "Ver detalhes": "Ver detalhes",
      "Detalhes": "Detalhes",
      "Incluir": "Incluir",
      "Incluir Região": "Incluir Região",
      "Nome": "Nome",
      "Descrição": "Descrição",
      "Mensagem": "Mensagem",
      "E-mail": "E-mail",
      "Moderado por": "Moderado por",
      "Telefone": "Telefone",
      "Download": "Download",
      "Tipo Aprovação": "Tipo aprovação",
      "Domínio Email": "Domínio e-mail",
      "Upload": "Upload",
      "Enviar": "Enviar",
      "Adicionar": "Adicionar",
      "Enviar por e-mail": "Enviar por e-mail",
      "Visualizar": "Visualizar",
      "Gerenciar": "Gerenciar",
      "Erro ao carregar os dados, contate o suporte":
        "Erro ao carregar os dados, contate o suporte.",
      "Erro ao processar, contate o suporte":
        "Erro ao processar, contate o suporte.",
      "Este campo é obrigatório": "Este campo é obrigatório",
      "Metadado obrigatório": "Metadado obrigatório",
      "Voltar": "Voltar",
      "Voltar etapa": "Voltar etapa",
      "Etapa 01 de 02.": "Etapa 01 de 02.",
      "Etapa 02 de 02.": "Etapa 02 de 02.",
      "Etapa 01 de 03.": "Etapa 01 de 03.",
      "Etapa 02 de 03.": "Etapa 02 de 03.",
      "Etapa 03 de 03.": "Etapa 03 de 03.",
      "Escolha o nome para download.": "Escolha o nome para download.",
      "Escolha o tipo de download.": "Escolha o tipo de download.",
      "Salvar": "Salvar",
      "Conta": "Conta",
      "Alterar Senha": "Alterar Senha",
      "Sair": "Sair",
      "E-mail inválido": "E-mail inválido",
      "Acesso negado": "Acesso negado.",
      "Favor, contatar o administrador e solicitar permissão caso seja necessário acessar este conteudo":
        "Favor, contatar o administrador e solicitar permissão caso seja necessário acessar este conteudo.",
      "Nome inválido.": "Nome inválido.",
      "Descrição inválida.": "Descrição inválida.",
      "Máscara inválida.": "Máscara inválida",
      "Excluir registro": "Excluir registro",
      "Favor selecionar uma região": "Favor selecionar uma região",
      "Favor selecionar uma divisão": "Favor selecionar uma divisão",
      "Tipo do campo": "Tipo do campo",
      "Máscara padrão": "Máscara padrão",
      "Exemplo": "Exemplo",
      "Valores": "Valores",
      "Tipo de valores": "Tipo de valores",
      "Tipo": "Tipo",
      "É necessário preencher este campo": "É necessário preencher este campo",
      "Aprovado": "Aprovado",
      "Reprovado": "Reprovado",
      "Permissões": "Permissões",
      "Ações": "Ações",
      "Novo": "Novo",
      "Fechar": "Fechar",
      "Ordenar": "Ordenar",
      "Obrigatório": "Obrigatório",
      "ª": "ª",
      "Parece que você chegou ao fim": "Parece que você chegou ao fim.",
      "O arquivo ultrapassa o limite de GB permitido.":
        "O arquivo {{fileName}} ultrapassa o limite de {{limit}}GB permitido.",
      "Arquivo para upload acima do limite 30":
        "Arquivo {{fileName}} muito grande. Ultrapassa o limite permitido de 30GB",
      "Arquivo para upload vazio.": "Arquivo {{fileName}} está vazio.",
      "Tipo de arquivo não permitido":
        "Arquivo {{fileName}} é do tipo {{fileType}} que não é permitido",
      "Erro desconhecido ao subir arquivo":
        "Erro desconhecido ao subir o arquivo {{fileName}}. Relatado:",
      "Usuário não foi moderado": "Usuário não foi moderado",
      "Moderar": "Moderar",
      "A edição feita terá os seguintes impactos":
        "A edição feita terá os seguintes impactos",
      "Fazendo upload de arquivos":
        "Fazendo upload de {{total}} arquivos",
      "Fazendo upload de arquivo":
        "Fazendo upload de {{total}} arquivo",
      "logout-upload": "Seu upload está em andamento e, ao sair, eles serão cancelados. Você tem certeza que quer sair?",
      "troca-regiao-upload": "Seu upload está em andamento e, ao trocar de Região, eles serão cancelados. Você tem certeza que quer trocar de Região?",
      "Cancelar uploads em andamento": "Cancelar uploads em andamento",
      "Código externo divisão": "Código externo divisão",
      "Código externo categoria": "Código externo categoria",
      "Erro ao tentar reprocessar, contate o suporte":
        "Erro ao tentar reprocessar, contate o suporte",
      "ACEITAR E FECHAR": "ACEITAR E FECHAR",
      "Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência. Ao usar nosso serviço, você concorda com nossos termos e condições de uso.":
        "Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência. Ao usar nosso serviço, você concorda com nossos termos e condições de uso.",
      "Eu li e concordo com os": "Eu li e concordo com os",
      "Termos de uso e Política de privacidade": "Termos de uso e Política de privacidade",
      "requerido por": "requerido por",
      "Não há notificações": "Não há notificações.",
      "Motivo desconhecido": "Motivo desconhecido",
      "Requisição inválida": "Requisição inválida"
    },
    login: {
      "Critérios de senha": "Critérios de senha",
      "cliqueaquiparasabermais": "Clique <1>aqui</1> para saber mais.",
      "Critérios para definição de senha": "Critérios para definição de senha",
      "A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.": "A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.",
      "Não podem ser utilizadas as últimas 05 senhas.": "Não podem ser utilizadas as últimas 05 senhas.",
      "Sua senha deve ser diferente das 05 últimas senhas já cadastradas, com no mínimo 08 caracteres, contendo letra, número e caractere especial.": " Sua senha deve ser diferente das 05 últimas senhas já cadastradas, com no mínimo 08 caracteres, contendo letra, número e caractere especial.",
      "Digite a sua nova senha": "Digite a sua nova senha",
      "Senha atualizada com sucesso.": "Senha atualizada com sucesso.",
      "Ir para o login": "Ir para o login",
      "Lamentamos que você não esteja de acordo com a Política de privacidade requerido por. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.": "Lamentamos que você não esteja de acordo com a Política de privacidade requerido por {{client}}. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.",
      "Atualização da Política de privacidade": "Atualização da Política de privacidade",
      "Esqueceu a Senha?": "Esqueceu a Senha?",
      "Acessar": "Acessar",
      "Digite sua senha": "Digite sua senha",
      "Esqueci a senha": "Esqueci a senha",
      "Esqueceu a senha?": "Esqueceu a senha?",
      "Insira o endereço de email associado à sua conta que lhe enviaremos um link de redefinição de senha":
        "Insira o endereço de email associado à sua conta que lhe enviaremos um link de redefinição de senha.",
      "Logout realizado com sucesso": "Logout realizado com sucesso.",
      "Voltar para o login": "Voltar para o login",
      "Senha atual": "Senha atual",
      "Nova senha": "Nova senha",
      "Confirmar Senha": "Confirmar Senha",
      "É necessário informar a senha atual":
        "É necessário informar a senha atual.",
      "A nova senha deve ter no minimo 6 caracteres":
        "A nova senha deve ter no minimo 6 caracteres.",
      "A senha e a confirmação não correspondem":
        "A senha e a confirmação não correspondem.",
      "Senha muito curta": "Senha muito curta",
      "Telefone inválido": "Telefone inválido",
      "Editar dados da conta": "Editar dados da conta",
      "Parâmetros para autenticação inválidos. Contate o suporte": "Parâmetros para autenticação inválidos. Contate o suporte"
    },
    recoverPassword: {
      "Sua senha já foi alterada": "Sua senha já foi alterada",
    },
    solicitacaoAcesso: {
      "Requisito de Senha": "Requisito de Senha",
      " A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.": " A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.",
      " A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial. E não pode ter sido utilizada nas últimas 5 senhas.": "A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial. E não pode ter sido utilizada nas últimas 5 senhas.",
      "Ajude o novo usuário da plataforma a saber qual opção ele deve escolher em cada passo da solicitação de acesso.": "Ajude o novo usuário da plataforma a saber qual opção ele deve escolher em cada passo da solicitação de acesso.",
      "Personalizar solicitações": "Personalizar solicitações",
      "Finalizar cadastro": "Finalizar cadastro",
      "Enviar cadastro": "Enviar cadastro",
      "Faça seu login": "Faça seu login",
      "Cadastro efetuado com sucesso!": "Cadastro efetuado com sucesso!",
      "Não há perfil para selecionar": "Não há perfil para selecionar",
      "Solicitaçāo enviada com sucesso!": "Solicitaçāo enviada com sucesso!",
      "Você receberá um e-mail de confirmação após a avaliaçāo do moderador.": "Você receberá um e-mail de confirmação após a avaliaçāo do moderador.",
      "Escolha sua região": "Escolha sua região",
      "Cada região possui diferentes tipos de conteúdo":
        "Cada região possui diferentes tipos de conteúdo.",
      "Escolha o seu perfil": "Escolha o seu perfil",
      "Cada perfil possui diferentes tipos de permissões":
        "Cada perfil possui diferentes tipos de permissões.",
      "Dados complementares": "Dados complementares",
      "Preencha seus dados complementares":
        "Preencha seus dados complementares.",
      "Dados pessoais": "Dados pessoais",
      "Preencha seus dados pessoais": "Preencha seus dados pessoais.",
      "Já possui cadastro? Faça aqui o login":
        "Já possui cadastro? Faça aqui o login.",
      "Passo anterior": "Passo anterior",
      "Próximo passo": "Próximo passo",
      "próximo": "próximo",
      "Nome Completo": "Nome Completo",
      "Senha": "Senha",
      "Informe seu e-mail": "Informe seu e-mail",
      "Informe seu nome completo": "Informe seu nome completo",
      "Informe seu número de telefone": "Informe seu número de telefone",
      "Informe uma senha": "Informe uma senha",
      "Informe o email do moderador": "Informe o email do moderador",
      "Enviar solicitação": "Enviar solicitação",
      "Moderação de acesso": "Moderação de acesso",
      "Solicitação enviada com sucesso!": "Solicitação enviada com sucesso!",
    },
    DAM: {
      "Os itens em que não há permissão para gerenciar não serão excluídos.": "Os itens em que não há permissão para gerenciar não serão excluídos.",
      "Item despublicado": "Item despublicado",
      "Pessoas": "Pessoas",
      "Deseja continuar?": "Deseja continuar?",
      "compartilhar tudo": "compartilhar tudo",
      "Tente novamente ou entre em contato com o suporte.": "Tente novamente ou entre em contato com o suporte.",
      "Não foi possível concluir a solicitação do download.": "Não foi possível concluir a solicitação do download.",
      "Abrir central de downloads": "Abrir central de downloads",
      "Ver informações do Item": "Ver informações do Item",
      "Com quem você deseja compartilhar?": "Com quem você deseja compartilhar?",
      "Deixe a sua mensagem aqui": "Deixe a sua mensagem aqui",
      "Total de Itens": "Total de Itens",
      "Baixar tudo": "Baixar tudo",
      "O seu Perfil não possui permissão para baixar estes Itens.": "O seu Perfil não possui permissão para baixar estes Itens.",
      "Central de downloads": "Central de downloads",
      "Confira os downloads que você solicitou.": "Confira os downloads que você solicitou.",
      "Ver todos": "Ver todos",
      "item_selecionado": "{{qtd}} item selecionado",
      "itens_selecionados": "{{qtd}} itens selecionados",
      "selecionar_x_itens": "Selecionar {{qtd}} itens",
      "Navegação": "Navegação",
      "Por hierarquia": "Por hierarquia",
      "Visualização": "Visualização",
      "Em card": "Em card",
      "Em lista": "Em lista",
      "Enviar para validação": "Enviar para validação",
      "Comente sobre os anexos que estão sendo enviados.": "Comente sobre os anexos que estão sendo enviados.",
      "Tarefa invalidada": "Tarefa invalidada",
      "A tarefa foi invalidada e voltará para a execução.": "A tarefa foi invalidada e voltará para a execução.",
      "Tarefa reprovada": "Tarefa reprovada",
      "A tarefa foi reprovada e voltará para a execução.": "A tarefa foi reprovada e voltará para a execução.",
      "Comente o que deve ser alterado.": "Comente o que deve ser alterado.",
      "Os materiais enviados": "Os materiais enviados",
      "Invalidar projeto": "Invalidar projeto",
      "O projeto voltará para execução. Explique por que foi invalidado.": "O projeto voltará para execução. Explique por que foi invalidado.",
      "Reprovar projeto": "Reprovar projeto",
      "O projeto voltará para execução. Explique por que foi reprovado.": "O projeto voltará para execução. Explique por que foi reprovado.",
      "Novo projeto": "Novo projeto",
      "Nome do projeto": "Nome do projeto",
      "Informações complementares do projeto": "Informações complementares do projeto",
      "Quais informações precisam ser preenchidas durante a execução do projeto?": "Quais informações precisam ser preenchidas durante a execução do projeto?",
      "Relacione itens já cadastrados na plataforma que possam auxiliar no projeto.": "Relacione itens já cadastrados na plataforma que possam auxiliar no projeto.",
      "Informações para criação do item": "Informações para criação do item",
      "Como o item deve ser criado após a finalização do projeto?": "Como o item deve ser criado após a finalização do projeto?",
      "Galeria": "Galeria",
      "Iniciar Projeto": "Iniciar Projeto",
      "Clique aqui para copiar o link de compartilhamento": "Clique aqui para copiar o link de compartilhamento",
      "Lista de itens": "Lista de itens",
      "Gerar link": "Gerar link",
      "Clique no botão abaixo para gerar o link. Uma notificação será enviada quando o link estiver pronto para ser compartilhado.": "Clique no botão abaixo para gerar o link. Uma notificação será enviada quando o link estiver pronto para ser compartilhado.",
      "Clique no botão abaixo para compartilhar via link": "Clique no botão abaixo para compartilhar via link",
      "Copiar link": "Copiar link",
      "Integração não processada": "Integração não processada",
      "Visualizar": "Visualizar",
      "Visualizar por": "Visualizar por",
      "Meus uploads": "Meus uploads",
      "Tipo de arquivo": "Tipo de arquivo",
      "Todos": "Todos",
      "Imagem": "Imagem",
      "Vídeo": "Vídeo",
      "Áudio": "Áudio",
      "Documentos": "Documentos",
      "Compactadores": "Compactadores",
      "Compactados": "Compactados",
      "Tipos": "Tipos",
      "Tipo": "Tipo",
      "Apresentações": "Apresentações",
      "Planilhas": "Planilhas",
      "Ordenação de arquivos": "Ordenação de arquivos",
      "E-mail enviado com sucesso.": "E-mail enviado com sucesso.",
      "A resposta chegará em seu e-mail de acesso ao ": "A resposta chegará em seu e-mail de acesso ao ",
      "Todos os itens": "Todos os itens",
      "Escreva aqui a sua dúvida": "Escreva aqui a sua dúvida",
      "Envie a sua dúvida sobre o conteúdo para o suporte de": "Envie a sua dúvida sobre o conteúdo para o suporte de {{name}}",
      "Suporte": "Suporte",
      "Digite o e-mail": "Digite o e-mail",
      "Escreva uma mensagem para o destinatário": "Escreva uma mensagem para o destinatário",
      "Informações do ativo": "Informações do ativo",
      "Baixar ativo": "Baixar ativo",
      "Ao escolher a opção com o asterisco (*), somente ativos digitais do tipo imagem serão disponilibizados no download.": "Ao escolher a opção com o asterisco (*), somente ativos digitais do tipo imagem serão disponilibizados no download.",
      "Selecionar ativo": "Selecionar ativo",
      "Visualizar modelo 3D": "Visualizar modelo 3D",
      "Ativos selecionados": "Ativos selecionados",
      "Ativo pertence ao item": "Ativo pertence ao item",
      "Os ativos removidos permanecem no item de origem.": "Os ativos removidos permanecem no item de origem.",
      "Os ativos removidos permaneceram no item de origem, ou se adicionado, no bucket.": "Os ativos removidos permaneceram no item de origem, ou se adicionado, no bucket.",
      "Criação de item": "Criação de item",
      "Criar item": "Criar item",
      "Detalhes do ativo": "Detalhes do ativo",
      "Fechar": "Fechar",
      "Os ativos removidos permanecem no bucket.": "Os ativos removidos permanecem no bucket.",
      "Os ativos removidos permaneceram no local de origem, na versão anterior ou, se adicionado, no bucket.": "Os ativos removidos permaneceram no local de origem, na versão anterior ou, se adicionado, no bucket.",
      "Gerenciar ativos do item": "Gerenciar ativos do item",
      "Não há nenhum ativo neste momento. Que tal procurar no bucket?": "Não há nenhum ativo neste momento. Que tal procurar no bucket?",
      "Versão em branco": "Versão em branco",
      "total_ativo": '{{total}} ativo',
      "total_ativos": '{{total}} ativos',
      "Ativos do item": "Ativos do item",
      "Gerenciar ativos": "Gerenciar ativos",
      "Editar informações": "Editar informações",
      "Editar informações do item": "Editar informações do item",
      "Adicionar nova versão": "Adicionar nova versão",
      "Adicionar ativo": "Adicionar ativo",
      "Remover ativo do item": "Remover ativo do item",
      "Gostaria de deixar algum comentário na nova versão?": "Gostaria de deixar algum comentário na nova versão?",
      "Sem comentário": "Sem comentário",
      "Nova versão de item criada com sucesso.": "Nova versão de item criada com sucesso.",
      "Os ativos removidos permanecem na versão anterior.": "Os ativos removidos permanecem na versão anterior.",
      "Edição salva com sucesso.": "Edição salva com sucesso",
      "Comentários": "Comentários",
      "Ordenação de itens": "Ordenação de itens",
      "Itens de A-Z": "Itens de A-Z",
      "Itens de Z-A": "Itens de Z-A",
      "Arquivos de A-Z": "Arquivos de A-Z",
      "Arquivos de Z-A": "Arquivos de Z-A",
      "Mais recentes": "Mais recentes",
      "Mais antigos": "Mais antigos",
      "Limpar filtros X": "Limpar filtros X",
      "Comente aqui": "Comente aqui",
      "Selecione ativos para adicionar ao item": "Selecione ativos para adicionar ao item",
      "Arquivos selecionados": "Arquivos selecionados",
      "Mostrar selecionados": "Mostrar selecionados",
      "Filtrar metadados": "Filtrar metadados",
      "Buscar metadado": "Buscar metadado",
      "Ao trocar de Região, a seleção de Itens será desfeita. Deseja continuar?": "Ao trocar de Região, a seleção de Itens será desfeita. Deseja continuar?",
      "Ao trocar de Região, a seleção de Ativos será desfeita. Deseja continuar?": "Ao trocar de Região, a seleção de Ativos será desfeita. Deseja continuar?",
      "em": "em",
      "por": "por",
      "às": "às",
      "Formato": "Formato",
      "Criado em": "Criado em",
      "Executar o video": "Executar o video",
      "Abrir em tela cheia": "Abrir em tela cheia",
      "Download do arquivo": "Download do arquivo",
      "Compartilhar item": "Compartilhar item",
      "Arquivos do item": "Arquivos do item",
      "Informações do item": "Informações do item",
      "Nenhum arquivo foi selecionado, volte e selecione no mínimo um arquivo": "Nenhum arquivo foi selecionado, volte e selecione no mínimo um arquivo",
      "Por favor, selecione um arquivo para salvar": "Por favor, selecione um arquivo para salvar",
      "Versões do item": "Versões do item",
      "Você tem certeza que deseja excluir esta versão?": "Você tem certeza que deseja excluir esta versão?",
      "Excluir versão": "Excluir versão",
      "Descrição da versão mais recente": "Descrição da versão mais recente",
      "Acesso não autorizado": "Acesso não autorizado",
      "Não autenticado ou login expirado.": "Não autenticado ou login expirado.",
      "Tente sair e entrar novamente. Se o problema persistir, favor contatar o administrador do sistema informando o problema.": "Tente sair e entrar novamente. Se o problema persistir, favor contatar o administrador do sistema informando o problema.",
      "Houve erro ao obter os dados para processar o bucket.": "Houve erro ao obter os dados para processar o bucket.",
      "Arquivo excluído do DAM. Reenviar para criação do item.": "Arquivo excluído do DAM. Reenviar para criação do item.",
      "Ocorreu um erro inesperado.": "Ocorreu um erro inesperado.",
      "Carregar mais": "Carregar mais",
      "Hierarquia": "Hierarquia",
      "Erro ao salvar os dados.": "Erro ao salvar os dados.",
      "Dados salvo com sucesso.": "Dados salvo com sucesso.",
      "Limpar lista": "Limpar lista",
      "Limpar": "Limpar",
      "Remover da lista": "Remover da lista",
      "Erro ao carregar dados do item": "Erro ao carregar dados do item",
      "Error na automação": "Error na automação",
      "Ver Item": "Ver Item",
      "associando arquivo": "associando arquivo",
      "extraindo dados": "extraindo dados",
      "extraindo dados para criar item": "extraindo dados para criar item",
      "preparando reprocessamento da integração": "preparando reprocessamento da integração",
      "Erro ao tentar cancelar o upload": "Erro ao tentar cancelar o upload",
      "Data final de vigência": "Data final de vigência",
      "Este item teve erro na geração da taxonomia. Exclua-o para incluir novamente": "Este item teve erro na geração da taxonomia. Exclua-o para incluir novamente",
      "Erro geral na automação via API.": "Erro geral na automação via API.",
      "O item não pode ser criado pois há uma falha na comunicação entre os servidores. Favor tente novamente mais tarde.": "O item não pode ser criado pois há uma falha na comunicação entre os servidores. Favor tente novamente mais tarde.",
      "O item não pode ser verificado pois há uma falha na autenticação entre os servidores. Favor tente novamente mais tarde.": "O item não pode ser verificado pois há uma falha na autenticação entre os servidores. Favor tente novamente mais tarde.",
      "O item não foi encontrado no processo de integração e, portanto, não pode ser criado ou associado. Favor entrar em contato com sua equipe de dados.": "O item não foi encontrado no processo de integração e, portanto, não pode ser criado ou associado. Favor entrar em contato com sua equipe de dados.",
      "Erro ao carregar": "Erro ao carregar",
      "Você tem certeza que deseja cancelar o upload dos arquivos?": "Você tem certeza que deseja cancelar o upload dos arquivos?",
      "Uploads cancelados com sucesso.": "Uploads cancelados com sucesso.",
      "Enviando arquivo": "Enviando arquivo",
      "Enviar erro": "Enviar erro",
      "arquivo associado": "arquivo associado",
      "Despublicar item": "Despublicar item",
      "Publicar item": "Publicar item",
      "Upload pausado com sucesso.": "Upload pausado com sucesso.",
      "Erro ao tentar pausar o upload": "Erro ao tentar pausar o upload",
      "Upload retomado com sucesso.": "Upload retomado com sucesso.",
      "Erro ao tentar retomar o upload": "Erro ao tentar retomar o upload",
      "Pausar upload": "Pausar upload",
      "Retomar upload": "Retomar upload",
      "item criado": "item criado",
      "Processamento finalizado": "Processamento finalizado",
      "Processamento do thumb": "Processamento do thumb",
      "Pausar uploads em progresso": "Pausar uploads em progresso",
      "Retomar uploads pausados": "Retomar uploads pausados",
      "Erro ao tentar retomar todos os uploads": "Erro ao tentar retomar todos os uploads",
      "Uploads retomados com sucesso.": "Uploads retomados com sucesso.",
      "Você tem certeza que deseja despublicar": "Você tem certeza que deseja despublicar",
      "Uploads pausados com sucesso.": "Uploads pausados com sucesso.",
      "Erro ao tentar pausar todos os uploads": "Erro ao tentar pausar todos os uploads",
      "Erro ao tentar cancelar todos os uploads.": "Erro ao tentar cancelar todos os uploads.",
      "Ultrapassou o limite máximo de upload de itens ao mesmo tempo.": "Ultrapassou o limite máximo de upload de  itens ao mesmo tempo.",
      "Limite de itens atingido. Permitido apenas itens anexados no total.": "Limite de itens atingido. Permitido apenas {{limit}} itens anexados no total.",
      "Não pode enviar exatamente o mesmo arquivo simultaneamente.": "Não pode enviar exatamente o mesmo arquivo simultaneamente.",
      "Rascunhos": "Rascunhos",
      "Alterar Imagem": "Alterar Imagem",
      "Idioma": "Idioma",
      "Dados do usuário": "Dados do usuário",
      "Exluir arquivo": "Exluir arquivo",
      "Exluir versão": "Exluir versão",
      "Despublicar arquivo": "Esconder item",
      "Publicar arquivo": "Tornar item visível",
      "Item com erro": "Item com erro",
      "Arquivos para download": "Arquivos para download",
      "Item compartilhado com sucesso": "Item compartilhado com sucesso.",
      "Arquivo compartilhado com sucesso": "Arquivo compartilhado com sucesso.",
      "Compartilhar ativo": "Compartilhar ativo",
      "Gerenciar versões": "Gerenciar versões",
      "Versão mais recente": "Versão mais recente",
      "Versão": "Versão",
      "versão": "versão",
      "Comentário": "Comentário",
      "Upload concluído com sucesso. Aguarde o processamento das imagens":
        "Upload concluído com sucesso. Aguarde o processamento das imagens",
      "Importar arquivos": "Importar arquivos",
      "Solte os arquivos aqui ou clique para fazer o upload":
        "Solte os arquivos aqui ou clique para fazer o upload.",
      "texto-IA-bucket":
        "Utilizamos inteligência artificial no processamento do arquivo. Assim que finalizado esse processo, a imagem do arquivo estará disponível.",
      "Excluir arquivo": "Excluir arquivo",
      "Você tem certeza que deseja deletar esse asset do repositório?":
        "Você tem certeza que deseja deletar esse asset do repositório?",
      "Você tem certeza que deseja publicar": "Você tem certeza que deseja tornar visível",
      "Você tem certeza que deseja excluir a versão":
        "Você tem certeza que deseja excluir a versão {{deleteName}} ?",
      "É necessário preencher o campo Categoria":
        "É necessário preencher o campo Categoria.",
      "É necessário preencher o campo Segmento":
        "É necessário preencher o campo Segmento.",
      "Novo Item": "Novo Item",
      "Nome do Item": "Nome do Item",
      "Descrição do Item": "Descrição do Item",
      "Download terminado": "Download terminado!",
      "Erro no download. Tente novamente, caso o erro persista, contate o suporte":
        "Erro no download. Tente novamente, caso o erro persista, contate o suporte",
      "Autorizando o download": "Autorizando o download de {{itemName}}",
      "Excluir": "Excluir",
      "Item não está publicado. Visível somente para administradores":
        "Item não está publicado. Visível somente para administradores.",
      "Informe ao menos um e-mail": "Informe ao menos um e-mail",
      "Há e-mail(s) com erro": "Há e-mail(s) com erro",
      "Pressione <Enter> para inserir mais de um e-mail":
        "Pressione <Enter> para inserir mais de um e-mail",
      "Compartilhamento efetuado com sucesso":
        "Compartilhamento efetuado com sucesso!",
      "Excluir item": "Excluir {{total}} item",
      "Excluir itens": "Excluir {{total}} itens",
      "Excluir itens selecionados": "Eliminar {{total}} elementos selecionados",
      "Não é possível fazer streaming do vídeo. O tipo não é suportado pelo navegador ou a extensão não corresponde ao conteúdo":
        "Não é possível fazer streaming do vídeo. O tipo não é suportado pelo navegador ou a extensão não corresponde ao conteúdo.",
      "Erro ao fazer o streaming do vídeo. A permissão para executar pode ter vencido. Execute-o novamente":
        "Erro ao fazer o streaming do vídeo. A permissão para executar pode ter vencido. Execute-o novamente.",
      "Pronto para download": "{{name}} acabou de ficar pronto para download",
      "Você tem certeza que deseja cancelar o upload do arquivo":
        "Você tem certeza que deseja cancelar o upload do arquivo {{file}}?",
      "Você tem certeza que deseja reprocessar a integração deste arquivo?":
        "Você tem certeza que deseja reprocessar a integração deste arquivo?",
      "Você selecionou mais itens que o permitido para download em massa.":
        "Download em massa pode ter um máximo de {{maximum}} itens. Você selecionou {{selected}} items",
      "Download em andamento. Você será notificado quando concluído e poderá baixar em notificações.":
        "Download em andamento. Você será notificado quando concluído e poderá baixar em notificações.",
      "A importação via CSV terminou. Clique para copiar link para baixar arquivo com os detalhes.":
        "A importação via CSV terminou. Clique para copiar link para baixar arquivo com os detalhes.",
      "Relevância": "Relevância"
    },
    manager: {
      "Editar variantes do atributo": "Editar variantes do atributo",
      "esquema de metadados": "esquema de metadados",
      "Defina qual esquema será considerado neste atributo.": "Defina qual esquema será considerado neste atributo.",
      "Esquema de Metadados": "Esquema de Metadados",
      "Incluir Esquema de Metadados": "Incluir Esquema de Metadados",
      "Defina quais metadados serão considerados atributos deste esquema.": "Defina quais metadados serão considerados atributos deste esquema.",
      "Chave secundária do Item": "Chave secundária do Item",
      "Defina quais metadados serão adicionados à chave secundária do item.": "Defina quais metadados serão adicionados à chave secundária do item.",
      "Perfil do usuário alterado com sucesso.": "Perfil do usuário alterado com sucesso.",
      "Mover": "Mover",
      "Mover de perfil": "Mover de perfil",
      "Mover usuário para outro perfil": "Mover usuário para outro perfil",
      "Essa ação pode ser realizada apenas para perfis em que seu usuário é moderador. Defina o novo perfil do usuário": "Essa ação pode ser realizada apenas para perfis em que seu usuário é moderador. Defina o novo perfil do usuário",
      "Por autenticação": "Por autenticação",
      "Pela plataforma Yapoli": "Pela plataforma Yapoli",
      "Cliente ID": "Cliente ID",
      "Escopo": "Escopo",
      "URL de autorização": "URL de autorização",
      "URL de token": "URL de token",
      "Chave secreta": "Chave secreta",
      "Mudar Senha": "Mudar Senha",
      "URL do serviço": "URL do serviço",
      "Configure os parâmetros do seu modelo de autenticação Single Sign ON - SSO. Dúvidas entre em contato com o suporte@yapoli.com": "Configure os parâmetros do seu modelo de autenticação Single Sign ON - SSO. Dúvidas entre em contato com o suporte@yapoli.com",
      "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos downloads e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com": "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos downloads e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com",
      "Escolha o modelo de autenticação": "Escolha o modelo de autenticação",
      "Escolha qual será o tipo de autenticação do perfil": "Escolha qual será o tipo de autenticação do perfil",
      "Lista de cadastro integrado": "Lista de cadastro integrado",
      "Digite o domínio para acesso": "Digite o domínio para acesso",
      "Os ativos de vídeos seguirão as especificações do arquivo original.": "Os ativos de vídeos seguirão as especificações do arquivo original.",
      "Categoria relacionada": "Categoria relacionada",
      "Relacionamento de imagens": "Relacionamento de imagens",
      "Ao habilitar, selecione a Categoria que possui Itens relacionados para que ocorra a identificação automática das imagens.": "Ao habilitar, selecione a Categoria que possui Itens relacionados para que ocorra a identificação automática das imagens.",
      "Quando habilitado, será possível relacionar de forma manual os itens cadastrados na plataforma, durante a criação e edição do item.": "Quando habilitado, será possível relacionar de forma manual os itens cadastrados na plataforma, durante a criação e edição do item.",
      "Política de privacidade": "Política de privacidade",
      "Essa solicitação está associada a esse item": "Essa solicitação está associada a esse item",
      "Item da solicitação": "Item da solicitação",
      "Comentário para validação": "Comentário para validação",
      "Comentário da reprovação": "Comentário da reprovação",
      "Comentário da invalidação": "Comentário da invalidação",
      "Os arquivos selecionados foram aprovados": "Os arquivos selecionados foram aprovados",
      "Os arquivos selecionados aguardam aprovação": "Os arquivos selecionados aguardam aprovação",
      "Rascunho do item": "Rascunho do item",
      "Alterar status": "Alterar status",
      "1 comentário": "1 comentário",
      "comentários": "{{qtd}} comentários",
      "Informações para a criação do item na plataforma": "Informações para a criação do item na plataforma",
      "O rascunho do seu item foi criado. Caso deseje, é possível editar as informações de cadastro ao clicar nele": "O rascunho do seu item foi criado. Caso deseje, é possível editar as informações de cadastro ao clicar nele",
      "Resumo  da tarefa": "Resumo  da tarefa",
      "Informações complementares": "Informações complementares",
      "Itens relacionados": "Itens relacionados",
      "Anexar arquivos para a tarefa": "Anexar arquivos para a tarefa",
      "Anexe arquivos para auxiliar na tarefa, como referências, documentos, etc.": "Anexe arquivos para auxiliar na tarefa, como referências, documentos, etc.",
      "Solte os arquivos aqui ou clique para fazer o upload.": "Solte os arquivos aqui ou clique para fazer o upload.",
      "Arquivos anexados": "Arquivos anexados",
      "Selecione os arquivos que devem ir para aprovação": "Selecione os arquivos que devem ir para aprovação",
      "Envie mensagens aos envolvidos no projeto": "Envie mensagens aos envolvidos no projeto",
      "Paleta de cores do item": "Paleta de cores do item",
      "Será extraído as cores predominantes do item.": "Será extraído as cores predominantes do item.",
      "Legenda do botão em": "Legenda do botão em {{idm}}",
      "Selecione quem será o aprovador da ferramenta de workflow": "Selecione quem será o aprovador da ferramenta de workflow",
      "Selecione quem será o validador da ferramenta de workflow (se houver)": "Selecione quem será o validador da ferramenta de workflow (se houver)",
      "workflow": "workflow",
      "solicitação": "solicitação",
      "Defina qual será o nome da ferramenta de": "Defina qual será o nome da ferramenta de {{tool}}",
      "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta": "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta",
      "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.": "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.",
      "Nome da ferramenta": "Nome da ferramenta",
      "Descreva qual o objetivo da ferramenta de": "Descreva qual o objetivo da ferramenta de {{tool}}",
      "Explique aos envolvidos o que se pretende alcançar com essa ferramenta": "Explique aos envolvidos o que se pretende alcançar com essa ferramenta",
      "Essa ferramenta será utilizada para...": "Essa ferramenta será utilizada para...",
      "Defina qual será a legenda do botão": "Defina qual será a legenda do botão",
      "Adicione um nome para o botão da ação da ferramenta": "Adicione um nome para o botão da ação da ferramenta",
      "Enviar, Compartilhar, Executar...": "Enviar, Compartilhar, Executar...",
      "Selecione quem será o executor da ferramenta de": "Selecione quem será o executor da ferramenta de {{tool}}",
      "Apenas usuários cadastrados na plataforma podem ser": "Apenas usuários cadastrados na plataforma podem ser {{type}}",
      "Selecione os usuários": "Selecione os usuários",
      "Selecione o usuário": "Selecione o usuário",
      "Selecione os metadados que complementam a solicitação dessa ferramenta": "Selecione os metadados que complementam a solicitação dessa ferramenta",
      "Quais metadados podem auxiliar na execução da ferramenta?": "Quais metadados podem auxiliar na execução da ferramenta?",
      "Iniciar tarefa": "Iniciar tarefa",
      "Nome da tarefa": "Nome da tarefa",
      "Adicionar idioma": "Adicionar idioma",
      "Criação de nova tarefa": "Criação de nova tarefa",
      "Defina qual será o nome da tarefa": "Defina qual será o nome da tarefa",
      "Idioma padrão da plataforma": "Idioma padrão da plataforma",
      "Esse nome será visto pelos usuários que utilizam o idioma na plataforma": "Esse nome será visto pelos usuários que utilizam o idioma {{idm}} na plataforma",
      "Resumo da tarefa": "Resumo da tarefa",
      "Explique aos envolvidos o que se pretende alcançar com essa tarefa": "Explique aos envolvidos o que se pretende alcançar com essa tarefa",
      "Essa tarefa será para...": "Essa tarefa será para...",
      "Informações complementares da tarefa": "Informações complementares da tarefa",
      "Preencha essas informações para auxiliar na execução da tarefa": "Preencha essas informações para auxiliar na execução da tarefa",
      "Busque os itens cadastrados na plataforma que possam auxiliar na tarefa": "Busque os itens cadastrados na plataforma para que possam auxiliar na tarefa",
      "Ao finalizar essa tarefa, será criado automaticamente um item na plataforma com o histórico do que foi realizado. Como o item deve ser cadastrado?": "Ao finalizar essa tarefa, será criado automaticamente um item na plataforma com o histórico do que foi realizado. Como o item deve ser cadastrado?",
      "Aprovador da tarefa": "Aprovador da tarefa",
      "Ferramenta de workflow": "Ferramenta de workflow",
      "A tarefa foi finalizada com sucesso.": "A tarefa foi finalizada com sucesso.",
      "O que gostaria de fazer agora?": "O que gostaria de fazer agora?",
      "Voltar para o dash": "Voltar para o dash",
      "abrir item": "abrir item",
      "Esta ferramenta não está associada a nenhuma categoria": "Esta ferramenta não está associada a nenhuma categoria",
      "Utilização da ferramenta": "Utilização da ferramenta",
      "Workflow": "Workflow",
      "Buscar item": "Buscar item",
      "Quais informações precisam ser preenchidas durante a execução do workflow?": "Quais informações precisam ser preenchidas durante a execução do workflow?",
      "Selecione os metadados": "Selecione os metadados",
      "criação da ferramenta de workflow": "Criação da ferramenta de workflow",
      "edição da ferramenta de workflow": "Edição da ferramenta de workflow",
      "Ferramenta criada para": "Ferramenta criada para",
      "Selecione quem será o executor dessa ferramenta dentre os usuários da plataforma.": "Selecione quem será o executor dessa ferramenta dentre os usuários da plataforma.",
      "Executor(es)": "Executor(es)",
      "Selecione quem será o aprovador dessa ferramenta dentre os usuários da plataforma.": "Selecione quem será o aprovador dessa ferramenta dentre os usuários da plataforma.",
      "Aprovador(es)": "Aprovador(es)",
      "REQUEST": "Solicitação",
      "Solicitação": "Solicitação",
      "WORKFLOW": "Workflow",
      "Aprovador": "Aprovador",
      "Executor": "Executor",
      "Categoria Aberta": "Categoria Aberta",
      "Esquemas de atributos": "Esquemas de Atributos",
      "Defina qual esquema será considerado nesta categoria": "Defina qual esquema será considerado nesta categoria",
      "Em categorias abertas os compartilhamentos não expiram após cada download.": "Em categorias abertas os compartilhamentos não expiram após cada download.",
      "Não bloquear": "Não bloquear",
      "Acesso do usuário": "Acesso do usuário",
      "A plataforma poderá bloquear o acesso do usuário após tentativas excessivas de login.": "A plataforma poderá bloquear o acesso do usuário após tentativas excessivas de login.",
      "Bloquear após tentativas": "Bloquear após {{qtd}} tentativas",
      "Ao editar uma categoria, a taxonomia deve conter o nome do item, quando esta tem itens atrelados.": "Ao editar uma categoria, a taxonomia deve conter o nome do item, quando esta tem itens atrelados.",
      "Essa Categoria não poderá ser alterada após sua criação.": "Essa Categoria não poderá ser alterada após sua criação.",
      "Essa Categoria não pode ser alterada por ser do tipo galeria de itens.": "Essa Categoria não pode ser alterada por ser do tipo galeria de itens.",
      "Como gostaria de redimensionar?": "Como gostaria de redimensionar?",
      "Proporcionalmente": "Proporcionalmente",
      "Medidas exatas": "Medidas exatas",
      "Plano de fundo de imagem PNG": "Plano de fundo de imagem PNG",
      "Português": "Português",
      "Português*": "Português*",
      "Inglês": "Inglês",
      "Espanhol": "Espanhol",
      "Explique como identificar qual Região o solicitante deve pedir acesso": "Explique como identificar qual Região o solicitante deve pedir acesso",
      "Explique como identificar qual Perfil o solicitante deve pedir acesso": "Explique como identificar qual Perfil o solicitante deve pedir acesso",
      "Explique porque são necessários os dados complementares": "Explique porque são necessários os dados complementares",
      "Explique porque são necessários os dados pessoais": "Explique porque são necessários os dados pessoais",
      "Defina qual será o separador de palavras nas nomenclaturas para download e para a distribuição.": "Defina qual será o separador de palavras nas nomenclaturas para download e para a distribuição.",
      "Para adicionar aperte ENTER ou TAB. Para colar de uma lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar.": "Para adicionar aperte ENTER ou TAB. Para colar de uma lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar.",
      "Separador de palavras": "Separador de palavras",
      "Expressão de busca": "Expressão de busca",
      "Dicionário de substituição": "Dicionário de substituição",
      "_ (Underline)": "_ (Underline)",
      "- (Hífen)": "- (Hífen)",
      "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar": "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar",
      "Defina como aparecerá o nome do ativo ao baixar e/ou nas integrações do item.": "Defina como aparecerá o nome do ativo ao baixar e/ou nas integrações do item.",
      "sobre expressão regular.": "sobre expressão regular.",
      "Convidar": "Convidar",
      "Exclusão de usuário": "Exclusão de usuário",
      "Ops, algo deu errado": "Ops, algo deu errado",
      "Limite de 20 e-mails inseridos alcançado. Revise a lista e tente novamente.": "Limite de 20 e-mails inseridos alcançado. Revise a lista e tente novamente.",
      "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de e-mails, utilize vírgula (,) ou ponto e vírgula (;) para separar": "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de e-mails, utilize vírgula (,) ou ponto e vírgula (;) para separar",
      "Convidar usuários": "Convidar usuários",
      "Convide colaboradores para esse Perfil que ainda não possuem acesso a plataforma. Acompanhe o status do convite na página de usuários.": "Convide colaboradores para esse Perfil que ainda não possuem acesso a plataforma. Acompanhe o status do convite na página de usuários.",
      "Insira o e-mail de seus convidados": "Insira o e-mail de seus convidados",
      "Enviar convite": "Enviar convite",
      "Convite enviado com sucesso.": "Convite enviado com sucesso.",
      "Acesse a Página de usuário para acompanhar os convites.": "Acesse a Página de usuário para acompanhar os convites.",
      "ok, entendi": "ok, entendi",
      "Manter original": "Manter original",
      "Defina qual será o nome que representará esse nome alternativo ao baixar o item": "Defina qual será o nome que representará esse nome alternativo ao baixar o item",
      "Será aplicado somente em itens da mesma região.": "Será aplicado somente em itens da mesma região.",
      "Nomes alternativos": "Nomes alternativos",
      "Adicione as opções de nomes alternativos para baixar, compartilhar e para CDN.": "Adicione as opções de nomes alternativos para baixar, compartilhar e para CDN.",
      "The alternatives names may not have more than 10 items.": "Os nomes de alternativas podem não ter mais de 10 itens.",
      "Itens pertencentes a região ": "Itens pertencentes a região ",
      "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos download e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com": "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos download e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com",
      "Especificação de imagem": "Especificação de imagem",
      "Nome alternativo": "Nome alternativo",
      "Erro ao editar metadados.": "Erro ao editar metadados.",
      "Busque quais metadados serão adicionados ao nome alternativo": "Busque quais metadados serão adicionados ao nome alternativo",
      "Defina qual será o nome que representará essa taxonomia alternativa ao baixar o item": "Defina qual será o nome que representará essa taxonomia alternativa ao baixar o item",
      "Para dúvidas sobre o conteúdo cadastrado nessa região, quem deve receber o e-mail de suporte?": "Para dúvidas sobre o conteúdo cadastrado nessa região, quem deve receber o e-mail de suporte?",
      "E-mail para suporte de conteúdo": "E-mail para suporte de conteúdo",
      "Este metadado não está associado a nenhuma categoria": "Este metadado não está associado a nenhuma categoria",
      "Este metadado não está associado a nenhum perfil ": "Este metadado não está associado a nenhum perfil",
      "Este metadado não está associado a nenhuma ferramenta de solicitação": "Este metadado não está associado a nenhuma ferramenta de solicitação",
      "Detalhes do metadado": "Detalhes do metadado",
      "está sendo utilizado em": "{{name}} está sendo utilizado em",
      "está sendo utilizada em": "{{name}} está sendo utilizado em",
      "Busque os metadados que complementam a solicitação dessa ferramenta.": "Busque os metadados que complementam a solicitação dessa ferramenta.",
      "Escreva a especificação": "Escreva a especificação",
      "Especificações da imagem": "Especificações da imagem",
      "Adicione as especificações de formatos da imagem para baixar e compartilhar.": "Adicione as especificações de formatos da imagem para baixar e compartilhar.",
      "Extensão": "Extensão",
      "Taxonomia do item": "Taxonomia do item",
      "Modo de cor": "Modo de cor",
      "Largura": "Largura",
      "Altura": "Altura",
      "Profundidade": "Profundidade",
      "Resolução": "Resolução",
      "Compressão": "Compressão",
      "Qualidade (de 0 a 100)": "Qualidade (de 0 a 100)",
      "Plano de fundo": "Plano de fundo",
      "Transparente": "Transparente",
      "Processo de pngquant que utiliza algoritmo de quantização para compactar pngs": "Processo de pngquant que utiliza algoritmo de quantização para compactar pngs",
      "Exclusão de metadados da imagem": "Exclusão de metadados da imagem",
      "Os ativos em 3D seguirão as especificações do arquivo original.": "Os ativos em 3D seguirão as especificações do arquivo original.",
      "Os ativos em PDF seguirão as especificações do arquivo original.": "Os ativos em PDF seguirão as especificações do arquivo original.",
      "Nome em português": "Nome em português",
      "Nome em inglês": "Nome em inglês",
      "Nome em espanhol": "Nome em espanhol",
      "Nome alternativo em português": "Nome alternativo em português",
      "Nome alternativo em inglês": "Nome alternativo em inglês",
      "Nome alternativo em espanhol": "Nome alternativo em espanhol",
      "Imagem": "Imagem",
      "Crie a sua própria especificação de imagens para distribuição CDN, para downloads e compartilhamentos em categorias. Dúvidas entre em contato com suporte@yapoli.com.": "Crie a sua própria especificação de imagens para distribuição CDN, para downloads e compartilhamentos em categorias. Dúvidas entre em contato com suporte@yapoli.com.",
      "Especificações de imagem": "Especificações de imagem",
      "Habilitar": "Habilitar",
      "Desabilitar": "Desabilitar",
      "Os Perfis permitidos terão acesso aos itens, mas apenas perfis com permissão de Gerenciar terão visualização na Categoria pela navegação no DAM.": "Os Perfis permitidos terão acesso aos itens, mas apenas perfis com permissão de Gerenciar terão visualização na Categoria pela navegação no DAM.",
      "A Categoria ficará visível para todos durante a navegação no DAM.": "A Categoria ficará visível para todos durante a navegação no DAM.",
      "Esse arquivo será mostrado a todos os usários que não possuirem um idioma definido ao entrar na plataforma.": "Esse arquivo será mostrado a todos os usários que não possuirem um idioma definido ao entrar na plataforma.",
      "Esse arquivo será mostrado a todos os usários que possuirem o inglês como idioma da plataforma.": "Esse arquivo será mostrado a todos os usários que possuirem o inglês como idioma da plataforma.",
      "Esse arquivo será mostrado a todos os usários que possuirem o espanhol como idioma da plataforma.": "Esse arquivo será mostrado a todos os usários que possuirem o espanhol como idioma da plataforma.",
      "Personalize o nome da plataforma e de uma descrição para que outros usuários conheçam sua funcionalidade.": "Personalize o nome da plataforma e de uma descrição para que outros usuários conheçam sua funcionalidade.",
      "Adicione domínios e links externos como sites, redes sociais e outros web sites utilizados por você, clientes e fornecedores.": "Adicione domínios e links externos como sites, redes sociais e outros web sites utilizados por você, clientes e fornecedores.",
      "A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma obrigatório preenchido as informações serão utilizadas como padrão em todos os idiomas.": "A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma obrigatório preenchido as informações serão utilizadas como padrão em todos os idiomas.",
      "Personalize a plataforma com a sua identidade visual.": "Personalize a plataforma com a sua identidade visual.",
      "Miniatura": "Miniatura",
      "Defina quais metadados serão adicionados na taxonomia do item.": "Defina quais metadados serão adicionados na taxonomia do item.",
      "Digite aqui os valores para o campo tipo Lista": "Digite aqui os valores para o campo tipo Lista",
      "Selecione a miniatura correspondente ao idioma desejado.": "Selecione a miniatura correspondente ao idioma desejado.",
      "Adicionar miniatura": "Adicionar miniatura",
      "A inatividade em Peril foi desativada.": "A inatividade em Peril foi desativada.",
      "Data": "Data",
      "Lista": "Lista",
      "Número": "Número",
      "Texto": "Texto",
      "Nenhuma opção": "Nenhuma opção",
      "Digite um domínio valido": "Digite um domínio valido",
      "Digite o domínio sem o @": "Digite o domínio sem o @",
      "A alteração do prazo deste perfil impactará nos usuários ativos listado abaixo. Deseja continuar?": "A alteração do prazo deste perfil impactará nos usuários ativos listado abaixo. Deseja continuar?",
      "Criação de divisão": "Criação de divisão",
      "Edição de divisão": "Edição de divisão",
      "Itens da divisão serão alterados.": "Itens da divisão serão alterados.",
      "Alterando Divisão": "Alterando Divisão",
      "Erro ao carregar os dados, contate o suporte": "Erro ao carregar os dados, contate o suporte",
      "Serão alterados.": "Serão alterados.",
      "Concluído": "Concluído",
      "Alterando": "Alterando",
      "Erro ao processar, contate o administrador.": "Erro ao processar, contate o administrador.",
      "O formulário tem erros, para continuar corrija-os.": "O formulário tem erros, para continuar corrija-os.",
      "Erro ao deletar os dados, contate o administrador.": "Erro ao deletar os dados, contate o administrador.",
      "Criação de": "Criação de",
      "Edição de": "Edição de",
      "Buscar pelo nome": "Buscar pelo nome",
      "Nenhum dado encontrado.": "Nenhum dado encontrado.",
      "Deve haver ao menos um link": "Deve haver ao menos um link",
      "Nome obrigatório.": "Nome obrigatório.",
      "Defina qual ferramenta de solicitação está associada a essa categoria.": "Defina qual ferramenta de solicitação está associada a essa categoria.",
      "Defina quais metadados serão considerados atributos desta categoria.": "Defina quais metadados serão considerados atributos desta categoria.",
      "Adicione o nome alternativo para visualização em downloads e compartilhamentos.": "Adicione o nome alternativo para visualização em downloads e compartilhamentos.",
      "Permissível": "Permissível",
      "Chave Secundária": "Chave Secundária",
      "Editável": "Editável",
      "Item não pode ser desativado pois está na taxonomia.": "Item não pode ser desativado pois está na taxonomia.",
      "Item não pode ser removido pois está na taxonomia.": "Item não pode ser removido pois está na taxonomia.",
      "Erro inesperado. Contate o suporte": "Erro inesperado. Contate o suporte",
      "carregando": "carregando",
      "Impossibilidade de permissionamento": "Impossibilidade de permissionamento",
      "Criação de metadado": "Criação de metadado",
      "Digite aqui os valores para o campo tipo lista": "Digite aqui os valores para o campo tipo lista",
      "Edição de metadado": "Edição de metadado",
      "A aplicação de um novo tempo para perfil temporário impactará em todos os usuários atrelados ao Perfil. Deseja continuar?": "A aplicação de um novo tempo para perfil temporário impactará em todos os usuários atrelados ao Perfil. Deseja continuar?",
      "Alteração de perfil temporário": "Alteração de perfil temporário",
      "Alteração de período de inatividade": "Alteração de período de inatividade",
      "A aplicação de um novo tempo para Inatividade de perfis impactará em todos os usuários atrelados ao Perfil. Deseja continuar?": "A aplicação de um novo tempo para Inatividade de perfis impactará em todos os usuários atrelados ao Perfil. Deseja continuar?",
      "Nome da ferramenta de solicitação": "Nome da ferramenta de solicitação",
      "Nome da ferramenta do workflow": "Nome da ferramenta do workflow",
      "Legenda do botão": "Legenda do botão",
      "Ao excluir uma ferramenta você estará excluindo todo fluxo atrelado a ela. Os dados da ferramenta serão perdidos e ela não aparecerá para nenhum perfil. Você deseja continuar?": "Ao excluir uma ferramenta você estará excluindo todo fluxo atrelado a ela. Os dados da ferramenta serão perdidos e ela não aparecerá para nenhum perfil. Você deseja continuar?",
      "Personalize a legenda do botão.": "Personalize a legenda do botão.",
      "Desbloquear usuário": "Desbloquear usuário",
      "Máximo caracteres": "Máximo {{value}} caracteres",
      "Ferramentas de solicitação": "Ferramentas de solicitação",
      "Ferramenta de solicitação": "Ferramenta de solicitação",
      "ferramentas de solicitação": "ferramentas de solicitação",
      "ferramenta de solicitação": "ferramenta de solicitação",
      "Texto de apoio.": "Texto de apoio.",
      "Tem certeza que deseja deletar a": "Tem certeza que deseja deletar a",
      "Idioma": "Idioma",
      "Moeda": "Moeda",
      "Excluir": "Excluir",
      "Erro ao enviar imagem": "Erro ao enviar imagem",
      "Nenhum resultado encontrado": "Nenhum resultado encontrado",
      "Clique aqui ou arraste uma imagem para enviar":
        "Clique aqui ou arraste uma imagem para enviar.",
      "Remover Imagem": "Remover imagem",
      "Selecione o arquivo para upload": "Selecione o arquivo para upload",
      "Arquivo inválido. Arquivo até 2MB em JPG ou PNG":
        "Arquivo inválido. Arquivo até 2MB em JPG ou PNG.",
      "Arquivo inválido. Arquivo até 2MB em ICO ou PNG":
        "Arquivo inválido. Arquivo até 2MB em ICO ou PNG.",
      "Arquivo inválido. Arquivo até 5MB em JPG ou PNG":
        "Arquivo inválido. Arquivo até 5MB em JPG ou PNG.",
      "Arquivo inválido. Arquivo até 5MB em ICO ou PNG":
        "Arquivo inválido. Arquivo até 5MB em ICO ou PNG.",
      "Domínio inválido": "Domínio inválido",
      "Idioma inválido": "Idioma inválido",
      "Moeda inválido": "Moeda inválido",
      "Timezone inválido": "Timezone inválido",
      "Editar região": "Editar região",
      "Nome da região": "Nome da região",
      "Domínio padrão": "Domínio padrão",
      "Confirmação de exclusão": "Confirmação de exclusão",
      "Tem certeza que deseja deletar a região":
        "Tem certeza que deseja deletar a região",
      "Digite o nome da região": "Digite o nome da região",
      "Alterando segmentos e itens": "Alterando segmentos e itens",
      "Alterando itens": "Alterando itens",
      "Itens da divisão serão alterados": "Itens da divisão serão alterados.",
      "Itens do segmento serão alterados": "Itens do segmento serão alterados.",
      "Nome da divisão": "Nome da divisão",
      "Código externo": "Código externo",
      "Código externo segmento": "Código externo segmento",
      "Tem certeza que deseja deletar a divisão":
        "Tem certeza que deseja deletar a divisão",
      "Imagem enviada com sucesso": "Imagem enviada com sucesso",
      "Nome do segmento": "Nome do segmento",
      "Tem certeza que deseja deletar o segmento":
        "Tem certeza que deseja deletar o segmento",
      "Favor selecionar um tipo de campo": "Favor selecionar um tipo de campo",
      "Nome do metadado": "Nome do metadado",
      "Digite o valor e aperte ENTER ou TAB para salvar e digitar outro":
        "Digite o valor e aperte ENTER ou TAB para salvar e digitar outro.",
      "Tem certeza que deseja deletar o metadado":
        "Tem certeza que deseja deletar o metadado",
      "Nome da Categoria": "Nome da Categoria",
      "Taxonomia do Item": "Taxonomia do Item",
      "Metadado não pode ser excluído, pois faz parte da taxonomia":
        "Metadado não pode ser excluído, pois faz parte da taxonomia",
      "Nome do perfil": "Nome do perfil",
      "Descrição do perfil": "Descrição do perfil",
      "Tipo de Moderação": "Tipo de Moderação",
      "Escopo de acesso": "Escopo de acesso",
      "Busque uma permissão (mínimo 3 caracteres)":
        "Busque uma permissão (mínimo 3 caracteres)",
      "Editar dados da plataforma": "Editar dados da plataforma",
      "Nome do cliente": "Nome do cliente",
      "Nome da plataforma": "Nome da plataforma",
      "Cor primária": "Cor primária",
      "Cor secundária": "Cor secundária",
      "Cor ternária": "Cor ternária",
      "Cor da fonte": "Cor da fonte",
      "Cor da fonte flutuante": "Cor da fonte flutuante",
      "Cor da fonte ativa": "Cor da fonte ativa",
      "Digite o hexadecimal correspondente à cor":
        "Digite o hexadecimal correspondente à cor",
      "Legenda": "Legenda",
      "Salvar alterações": "Salvar alterações",
      "Alterar imagens da plataforma": "Alterar imagens da plataforma",
      "Plano de fundo do login": "Plano de fundo do login",
      "Logo da pagina inicial": "Logo da pagina inicial",
      "Logo para menu superior": "Logo para menu superior",
      "Logo para compartilhamento":
        "Logo para compartilhamento (thumb whatsapp)",
      "Logo interno": "Logo interno",
      "Clique para ativar": "Clique para ativar",
      "Clique para desativar": "Clique para desativar",
      "Ao proceder com esta alteração, todos os itens na":
        "Ao proceder com esta alteração, todos os itens na",
      "serão alterados. As URLs dos respectivos itens poderão ser reescritas e a operação levará algum tempo. Ao final do processo você será notificado":
        "serão alterados. As URLs dos respectivos itens poderão ser reescritas e a operação levará algum tempo. Ao final do processo você será notificado.",
      "texto-alteracao-entity-1":
        "Ao proceder com esta alteração, todos os itens do ",
      "texto-alteracao-entity-2":
        "serão alterados. As URLs dos respectivos itens poderão ser reescritas e a operação levará algum tempo. Ao final do processo você será notificado",
      "Está seguro de que deseja proceder com a alteração agora?":
        "Está seguro de que deseja proceder com a alteração agora?",
      "Fechar, o processamento continuará":
        "Fechar, o processamento continuará",
      "Esta tela pode ser fechada": "Esta tela pode ser fechada",
      "Único": "Único",
      "Múltiplo": "Múltiplo",
      "Em atualização": "Em atualização",
      "Arquivo inválido. Somente um arquivo em formato CSV permitido.":
        "Arquivo inválido. Somente um arquivo em formato CSV permitido.",
      "Clique aqui ou arraste um arquivo para enviar":
        "Clique aqui ou arraste um arquivo para enviar",
      "Criar itens": "Criar itens",
      "aprovadores": "aprovadores",
      "executores": "executores",
      "validadores": "validadores",
    },
  },
  es: {
    common: {
      "Esquema": "Esquema",
      "Esquema de Metadados": "Esquema de Metadatos",
      "A categoria trata-se de uma galeria, portanto o nome do item terá o mesmo nome do arquivo.": "La categoría es una galería, por lo que el nombre del elemento tendrá el mismo nombre que el archivo.",
      "Revogar e excluir acesso": "Revocar y eliminar",
      "Tempo de expiração": "Tiempo de expiración",
      "Erro ao criar metadado.": "Error al crear metadatos.",
      "Digite o valor desejado e aperte ENTER ou TAB para adicionar. Para lista de valores, utilize vírgula (,) para separar.": "Escriba el valor deseado y presione ENTER o TAB para agregar. Para la lista de valores, use la coma (,) para separar.",
      "Moderadores": "Moderadores",
      "incomplete-allowed": "Registro incompleto",
      "Publicação dos Itens": "Publicación de Artículos",
      "Defina seu ZIP": "Establece tu ZIP",
      "Itens dentro do ZIP": "Artículos dentro del ZIP",
      "Cada Item em uma pasta": "Cada artículo en una carpeta",
      "Todos os Itens em uma única pasta": "Todos los artículos en una sola carpeta",
      "solicitar download": "Solicitar descarga",
      "Acompanhe na Central de downloads a sua solicitação. Uma notificação será enviada quando os Itens estiverem prontos.": "Rastrea tu solicitud en el Centro de descargas. Se enviará una notificación cuando los artículos estén listos.",
      "Verifique as atualizações dos itens e o Link para compartilhamento.": "Verifique las actualizaciones de artículos y comparta el enlace.",
      "Não há downloads": "No hay descargas.",
      "download-generated": "Solicitud lista. Accede al Centro para realizar la descarga.",
      "Contate o administrador ou solicite a permissão para acessar o conteúdo.": "Contactar con el administrador o solicitar permiso para acceder al contenido",
      "Expirado": "Venció",
      "Processando": "Procesando",
      "Selecione o formato": "Seleccione el formato",
      "Se desejar, nomeie o seu ZIP": "Si lo deseas, le puse nombre a tu ZIP",
      "Receber notificação": "Obtener la notificación",
      "Na plataforma": "En la plataforma",
      "Por e-mail": "Por e-mail",
      "iniciar download": "Comienza a descargar",
      "Central de download": "Centro de descargas",
      "Paleta de cores": "Paleta de colores",
      "Visualize as cores que se destacam no item": "Visualiza los colores que destacan en el artículo",
      "Imagem web em alta": "Imagen web de alta calidad",
      "Imagem web em baixa": "Imagen web de baja calidad",
      "Ativo original": "Activo original",
      "Ferramentas": "Herramientas",
      "Tarefa": "Asignación",
      "Selecione qual ferramenta você deseja criar": "Seleccione la herramienta que desea crear",
      "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.": "Desde artículos registrados en la plataforma, enviar una solicitud para que otros usuarios pueden realizar alguna tarea.",
      "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta.": "Cree un flujo de trabajo que requiera aprobación de tareas y genere un artículo nuevo al terminar la herramienta.",
      "commentTaksNotification": "{{ NAME_USER }} <2>comentó</2> la tarea {{ taks_name }}",
      "Limpar comentário": "Limpiar comentario",
      "Uploads de arquivos": "Carga de archivos",
      "Anexar": "Adjuntar",
      "self-approved": "Autoaprobado",
      "no-allower-info": "Sin información del aprobador",
      "allower-was-purged": "Usuario eliminado",
      "Selecione o segmento": "Seleccionar segmento",
      "Selecione a categoria": "Selecciona una categoría",
      "Adicione um comentário": "Agregue un comentario",
      "Adicione um comentário...": "Agregue un comentario...",
      "Link copiado com sucesso": "Enlace copiado correctamente",
      "Compartilhar por link": "Compartir por enlace",
      "Compartilhar por e-mail": "Compartir por correo electrónico",
      "Como você deseja compartilhar?": "¿Cómo quieres compartir?",
      "Escolha o nome para o compartilhamento.": "Elija el nombre para el recurso compartido.",
      "Uma notificação será enviada quando o link estiver pronto": " Se enviará una notificación cuando el enlace esté listo",
      "última atualização": "última actualización",
      "Operação concluída.": "Operación concluída",
      "shared-generated": "Enlace para compartir generado con éxito.",
      "Ferramenta": "Instrumento",
      "Atalho": "Atajo",
      "Tamanho": "Tamaño",
      "Aprovação de usuário": "Aprobación de usuario",
      "Reprovação de usuário": "Desaprobación de usuario",
      "Você tem certeza que deseja publicar o item?": "¿Estás seguro que quieres publicar el item?",
      "Você tem certeza que deseja despublicar o item?": "¿Estás seguro de que quieres anular la publicación?",
      "cliqueaquiparasabermais": "Clique <1>aqui</1> para saber mais.",
      "Não há registro de publicação deste item.": "No hay registro de publicación de este artículo.",
      "pela-vigencia": "por la Duracion",
      "pelo-usuario": "por el Usuario",
      "por no dia": "{{operation}} {{reason}} {{user}} en {{date}}",
      "Comentário da publicação": "Comentario de la publicación",
      "Acompanhe o histórico de publicação do item": "Seguir el historial de publicaciones del artículo",
      "o item em": "{{user}} {{operation}} el artículo  en {{date}}",
      "Galeria de itens": "Galería de elementos",
      "Ao tornar a Categoria uma galeria, cada item conterá apenas um (01) ativo. Ela ficará visível como um Atalho e não será possível alterar a categoria após a sua criação.": "Al convertir la Categoría en una galería, cada artículo solo contendrá un (01) activo. Será visible como acceso directo y no será posible cambiar la categoría después de su creación.",
      "Para compartilhar com pessoas externas, insira abaixo o e-mail dos destinatários": "Para compartir, ingrese el correo electrónico de los destinatarios",
      "Insira os e-mails": "Ingrese los correos electrónicos",
      "Distribuições": "Distribuciones",
      "Utilização do nome alternativo": "Uso de nombre alternativo",
      "Este nome alternativo não está associado a nenhuma categoria": "Este nombre alternativo no está asociado a ninguna categoría",
      "Este nome alternativo não está associado a nenhuma distribuição": "Este nombre alternativo no está asociado a ninguna distribuición",
      "reenviar convite": "reenviar invitacíon",
      "Oops! Link indisponível": "¡Ups! Enlace no disponible.",
      "Entre em contato com  o gestor de conteúdo para reenviar o convite ou faça a solicitação de acesso e aguarde a moderação.": "Póngase en contacto con el administrador de contenido para reenviar la invitación o solicitar acceso y esperar la moderación.",
      "approved": "Aprobado",
      "disapproved": "Rechazado",
      "blocked": "Obstruido",
      "requested": "El usuario no fue moderado",
      "invited": "Invitado",
      "invited-registered": "Invitado registrado",
      "invited-expired": "Invitado caducado",
      "allowed": "Auto Aprobado",
      "Anfitrião": "Anfitrión",
      "Data de criação": "Fecha de creación",
      "Data de aprovação": "Fecha de aprobación",
      "Segunça de senha": "Seguridad de la contraseña",
      " sua senha deve ter um mínimo de 8 caracteres contendo letras, números e caractere especial.": " su contraseña debe tener al menos 8 caracteres y contener letras, números y un carácter especial.",
      "Uma notificação será enviada quando os itens estiverem prontos para o download.": "La notificación se enviará cuando los elementos estén listos para descargar.",
      "Link expirado": "Enlace caducado",
      "Geral": "General",
      "Limite de itens selecionados": "Límite de {{value}} artículos seleccionados",
      "Todos os itens dessa página foram selecionados.": "Se han seleccionado los {{name}} elementos de esta página.",
      "ativo": "activo",
      "ativos": "activos",
      "Deseja publicar o item": "Desea publicar el ítem {{name}}",
      "Deseja despublicar o item": "Desea despublicar el ítem {{name}}",
      "Nome do item": "Nombre del árticulo",
      "Ativos": "Activos",
      "Status": "Estado",
      "Data de vigência": "Fecha efectiva",
      "Visualizar em card": "Ver en tarjeta",
      "Visualizar em lista": "Ver en lista",
      "Caso queira nomear o seu arquivo ZIP com o download realizado,  escreva aqui": "Si desea nombrar su archivo ZIP después de la descarga, escríbalo aquí",
      "Incompatível com as categorias  de todos os itens": "Incompatible con todas las categorías de artículos",
      "Escolha o nome para download.": "Seleccione el nombre para descarga",
      "Escolha o tipo de download.": "Seleccione el tipo de descarga",
      "Download em andamento": "Descarga en curso",
      "Exclusão em andamento": "Eliminación en curso",
      "Você receberá uma notificação quando o download for concluído.": "Recibirás una notificación cuando se complete la descarga.",
      "Nome original": "Nombre original",
      "Nome alternativo": "Nombre alternativo",
      "Erro ao editar metadados.": "Error while editing metadata.",
      "Nome alternativo em espanhol": "Nombre alternativo en español",
      "Nome alternativo em inglês": "Nombre alternativo en ingles",
      "Utilizado em": "Utilizado en",
      "Adicionar metadado a lista de": "Agregar metadatos a la lista {{name}}",
      "adicionar metadado a lista": "Agregar metadatos a la lista",
      "Este campo contem itens duplicados": "Este campo contiene elementos duplicados",
      "Este campo deve conter mais que 3 caracteres": "Este campo debe contener más de 3 caracteres",
      "Alerta": "Alerta",
      "aqui": "aquí",
      "Saiba mais  ": "Aprende más  ",
      "Ao criar um metadado, verifique se são dados de acordo com a LGPD.": "Al crear metadatos, asegúrese de que sean datos compatibles con el LGPD.",
      "Mais zoom": "Acercarse",
      "Menos zoom": "Disminuir el zoom",
      "O ativo que não possuir suporte para as especificações será mantido apenas ": "El activo que no soporte las especificaciones se mantendrá únicamente en ",
      "Os ativos não possuem especificações em comum.": "Los activos no tienen especificaciones en común.",
      "Ativos originais": "Activos originales",
      "Data inicial maior que a data final": "Fecha de inicio mayor que la fecha de finalización",
      "Selecione uma data ou um período.": "Seleccione una fecha o período.",
      "na versão original": "la versión original",
      "iten_count": "{{count}} artículo",
      "itens_count": "{{count}} artículos",
      "Nenhum dado encontrado.": "Datos no encontrados.",
      "Todas": "Todos",
      "Nenhuma data selecionada": "Sin fecha seleccionada",
      "Aberta": "Abierto",
      "Em execução": "En ejecución",
      "Finalizada": "Terminado",
      "O ativo que não possuir suporte para as especificações será mantido na versão original.": "El activo que no soporte las especificaciones se mantendrá en la versión original.",
      "Especificações de imagem": "Especificaciones de imagen",
      "Configurações avançadas": "Configuraciones avanzadas",
      "Marcador": "Etiqueta",
      "TAG automatizada": "Etiqueta automatizada",
      "Ao escolher essa opção, somente ativos digitais do tipo imagem serão disponibilizados no download.": "Al elegir esta opción, solo los activos digitales del tipo de imagen estarán disponibles en la descarga.",
      "Segurança": "LA SEGURIDAD",
      "item relacionado": "{{qtd}} artículo relacionado",
      "itens relacionados": "{{qtd}} artículos relacionado",
      "Itens 3D": "Artícluos 3D",
      "Buscar texturas": "Buscar texturas",
      "Selecione a textura para o objeto 3D": "Seleccionar textura para objeto 3D",
      "data_hora": "fecha_hora",
      "Download em massa": "Descarga masiva",
      "download-error-generated": "Error al realizar la <2>descarga masiva.</2>",
      "Ferramenta de solicitação": "Solicitar herramienta",
      "Ferramentas de solicitação": "Solicitar herramientas",
      "selecionados": "seleccionado",
      "Evento não identificado": "Evento no identificado",
      "Limite de 10 tags por assets foi excedido.": "Se superó el límite de 10 etiquetas por activo.",
      "Limite de 40 caracteres por tag foi excedido.": "Se superó el límite de 40 caracteres por etiqueta.",
      "Não": "No",
      "O que deseja fazer?": "¿Qué es lo que quieres hacer?",
      "Publicação do item ou data de vigência alteradas": "Publicación del artículo o fecha de vigencia modificada",
      "Confirmação de Email": "Confirmación de correo electrónico",
      "Marcadores": "Marcadores",
      "Reiniciar": "Reanudar",
      "Selecione pelo nome": "Seleccionar por nombre",
      "Erro ao carregar dados da imagem.": "Error al cargar los datos de la imagen.",
      "Solicitações": "Peticiones",
      "Sua sessão foi expirada. Favor logar novamente para poder prosseguir com suas atividades.": "Su sesión ha caducado. Vuelva a iniciar sesión para continuar con sus actividades.",
      "Sessão Expirada.": "Sesión expirada.",
      "Entendi": "Lo compreendi",
      "Erro ao deletar, contate o administrador.": "Error deleting, contact administrator.",
      "Erro ao deletar, tente novamente.": "Error deleting, try again.",
      "Gerenciador de usuários": "administrador de usuarios",
      "Bloqueado": "Obstruido",
      "Pendente": "Pendiente",
      "Criação de segmento": "Creación de segmento",
      "Edição de segmento": "Edición de segmento",
      "Alterando Segmento": "Cambio de segmento",
      "A alteração feita será aplicada na Ferramenta. Deseja continuar?": "El cambio realizado se aplicará en la herramienta {{name}}. ¿Desea continuar?",
      "Validação de campo inexistente": "Sin validación de campo",
      "Fuso Horário": "Huso horario",
      "dia": "día",
      "dias": "días",
      "A aplicação de um novo tempo para perfil temporário impactará em todos os usuários atrelados ao Perfil. Deseja continuar?": "A aplicação de um novo tempo para perfil temporário impactará em todos os usuários atrelados ao Perfil. Deseja continuar?",
      "Sim": "Sí",
      "Confirme a nova senha": "Confirme la nueva contraseña",
      "Alteração de senha": "Cambiar contraseña",
      "Expiração do login": "Caducidad de inicio de sesión",
      "Edição de dados da conta": "Editar los datos de la cuenta",
      "Ir para o DAM": "ir a DAM",
      "Nome da categoria": "Nombre de categoría",
      "aprovar": "aprobar",
      "reprovar": "reprobar",
      "Revogar e excluir acesso de": "Revocar y eliminar el acceso de",
      "desbloquear": "desbloquear",
      "Tem certeza que deseja desbloquear?": "¿Estás seguro de que deseas desbloquear {{name}}?",
      "Tem certeza que deseja aprovar?": "¿Está seguro de que desea aprobar a {{name}}?",
      "Tem certeza que deseja reprovar?": "¿Estás seguro de que quieres reprobar a {{name}}?",
      "Tem certeza que deseja revogar e excluir o acesso de?": "¿Está seguro de que desea revocar y eliminar el acceso de {{name}}?",
      "Informações adicionais": "Información adicional",
      "Região de acesso": "Región de acceso",
      "Tipo de perfil": "Tipo de perfil",
      "Dados complementares": "Datos complementarios",
      "Dados pessoais": "Datos personales",
      "Nome completo": "Nombre completo",
      "Moderador": "Moderador",
      "Declaro ter lido a": "Declaro que he leído el",
      "Personalização da plataforma": "Customización de la plataform",
      "Nome da plataforma": "Nombre de la plataforma",
      "Nome da empresa": "Nombre de la empresa",
      "Links externos": "Enlaces externos",
      "Digite a legenda.": "Introduzca el subtítulo.",
      "Digite o link.": "Introduzca el enlace.",
      "Digite o dns.": "Introduzca el DNS.",
      "Digite a descrição.": "Escriba la descripción.",
      "Digite o nome da plataforma.": "Introduzca el nombre de la plataforma.",
      "Adicionar nova imagem": "Añade una nueva imagen.",
      "Adicionar Link": "Adicionar enlace",
      "Adicionar dns": "Adicionar DNS",
      "Imagens da plataforma": "Imágenes de la plataforma",
      "Especificações": "Especificações",
      "tamanho máximo": "tamaño máximo",
      "Imagem aplicada ao fundo da área de login.": "Imágen aplicada al fondo del área del inicio de sesión",
      "Logo da página inicial": "Logo de la página inicial",
      "Imagem aplicada na página de login sob o background.": "Imágen aplicada en la página de inicio de sesión en segundo plano.",
      "Logo para menu superior": "Logotipo para el menú superior",
      "Imagem aplicada na barra superior da página de login": "Imagen aplicada a la barra superior de la página del início de sesión",
      "Imagem aplicada no ícone do navegador.": "Imagem aplicada ao ícono del navegador.",
      "Logo interno": "Logotipo interno",
      "Imagem aplicada na barra superior dentro da plataforma.": "Imagen aplicada a la barra superior dentro de la plataforma.",
      "Logo do e-mail": "Logotipo del correo",
      "Imagem aplicada nos e-mails enviados pela plataforma.": "Imagen aplicada a la barra superior adentro de la platafma",
      "Print da tela para compartilhamento de arquivos": "Impresión de pantallas para compartir archivos",
      "Imagem que será anexada em redes sociais e plataformas externas.": "Imagen que se adjuntará en las redes sociales y plataformas externas.",
      "Está gostando da plataforma?": "¿Disfrutas de la plataforma {{name}}?",
      "Gostaríamos de saber como tem sido a sua experiência com para aperfeiçoarmos cada vez mais.": "Nos gustaríamos conocer su experiencia con {{name}} que podamos mejorar aún más.",
      "Ao clicar em avaliar, você será direcionado para uma plataforma externa. Tempo médio de avaliação": "Al hacer clic en la evaluación, se le llevará a una plataforma externa. Tiempo medio de evaluación",
      "Avaliar": "Evaluación",
      "nps-access-request": "Con su consentimiento, Yapoli colecta solamente sus datos personales como <1>nombre completo, número de teléfono</1> y <1>correo electrónico</1> con la finalidad de acceder a la plataforma {{dataClientName}}. Además, los datos mencionados pueden ser modificados/eliminados en cualquier momento a través del correo a dpo@yapoli.com.",
      "Política de privacidade da Yapoli": "Política de Privacidad de Yapoli",
      "Política de privacidade da": "Política de Privacidad de {{name}}",
      "Política de privacidade": "Política de Privacidad",
      "Nos conte sobre a sua experiência com": "Cuéntenos sobre su experiencia con",
      "Avalie a": "Calificar {{name}}",
      "Data inicial": "Fecha inicial",
      "até": "hasta",
      "Data final deve ser maior": "La fecha de finalización debe ser mayor",
      "Tem certeza que deseja realizar essa alteração?": "Está seguro que desea realizar este cambio?",
      "Buscar ferramentas de solicitação": "Buscar herramientas de solicitud",
      "Relacionar Itens": "Relacionar artículos",
      "descriptionNotifications-1": "{{NAME_USER}} <2>{{action}}</2> del artículo {{NAME_ITEM}}",
      "descriptionNotifications-2": "La <1>{{text}}</1> del artículo {{NAME_ITEM}} está a punto de vencer. (Fecha fin: {{date}})",
      "bulkDownloadDescriptionNotification":
        "{{NAME_ITEM}} está completo.",
      "bulkDownloadErrorNotification": "La <1>descarga masiva</1> solicitada no se pudo completar debido al motivo: {{reason}}. Vuelva a intentarlo o póngase en contacto con el soporte.",
      "data de vigência": "fecha efectiva",
      "Não lida": "No leído",
      "mostrar mais": "Ver más",
      "Confira as atualizações dos itens.": "Ver actualizaciones de artículo",
      "Item não encontrado": "artículo no encontrado",
      " criou ": " ha creado ",
      " publicou ": " ha publicado ",
      " despublicou ": " ha tornado inédito ",
      " adicionou uma nova versão ": " agregó una nueva versión ",
      "no item": "del artículo {{item_name}}",
      "o item": "del artículo {{item_name}}",
      "do item": "del artículo {{item_name}}",
      "Notificações": "Notificaciones",
      "Todos os usuários com permissão de visualizar itens do conteúdo acima, receberão notificações sobre as atualizações.": "Todos los usuarios con permiso de visualizar artículo del contenido arriba, van a recibir notificaciones sobre las actualizaciones.",
      "O item pode ser visualizado e utilizado pelos usuários que possuem permissão no perfil.": "Los usuarios que tienen permiso en el perfil pueden ver y utilizar el artículo.",
      "O item ficará oculto dos usuários que não possuírem perfil para gerenciar o conteúdo.": "El artículo se ocultará a los usuarios que no tengan un perfil para administrar el contenido.",
      "O item que possuir um conteúdo permitido para ser veiculado e compartilhado por um período específico.": "El artículo que tiene contenido que se puede publicar y compartir durante un período específico.",
      "Em relação a publicação, o item deverá ser criado como": "En cuanto a la publicación, el artículo debe crearse como",
      "Data final da publicação": "Fecha de publicación final",
      "Insira uma data": "Por favor ingrese una fecha",
      "Período": "Período",
      " indeterminado": " Indefinido",
      "Com vigência": "Eficaz",
      "qtd_arquivo": "{{qtd}} archivo",
      "qtd_arquivos": "{{qtd}} archivos",
      "Gerando o arquivo para download…": "Generando el artículo {{name}} para download…",
      "Download do item": "Download del artículo",
      "Imagens web em alta": "Imágenes web en alta",
      "Imagens web em baixa": "Imágenes web en baja",
      "Opções de download": "Opciones de download",
      "O item está fora do prazo de vigência estabelecido para o material. Por isso, não poderá ser divulgado ou compartilhado com terceiros. Deseja continuar?": "El artículo está fuera del plazo establecido para el material. Por lo tanto no se puede divulgar ni compartir con terceros. Desea continuar?",
      "Item com data de vigência": "artículo con fecha de vigencia",
      "desativado": "desactivado",
      "A vigência no item foi desativada. Para ativá-la torne o item despublicado.": "El período en el artículo ha sido desactivado. Para activarlo anule la publicación del artículo.",
      "A vigência no item foi ativada.": "Se ha activado el período en el artículo.",
      "Expiração": "El Vencimiento",
      "Sem expiração": "Sin expiración",
      "Permissão": "Permiso",
      "Escolha apenas um dos tipos de expiração de perfil abaixo.": "Elige solo uno de los siguientes tipos de expiración de perfil.",
      "Perfil temporário": "Perfil temporal",
      "Essa funcionalidade aplica o conceito de perfil temporário. Assim que aplicada, o prazo de expiração (bloqueio do usuário) passa a ser contado em dias a partir da data de aprovação da solicitação de acesso deste usuário.": "Esta funcionalidad aplica el concepto de perfil temporal. Tan pronto como se aplica, el período de vencimiento (bloqueo de usuario) comienza a contarse en días a partir de la fecha de aprobación de la solicitud de acceso de este usuario.",
      "A inatividade em Perfil foi desativada.": "Se ha desactivado la inactividad de este perfil.",
      "O Perfil temporário foi desativado.": "El perfil temporal ha sido desactivado.",
      "Inatividade em perfil": "Inactividad de perfil",
      "Essa funcionalidade aplica um prazo, em dias, de inatividade no perfil, onde o usuários que está atrelado a ele será bloqueado no tempo definido, contando a partir do seu último acesso a plataforma.": "Esta funcionalidad aplica un período, en días, de inactividad en el perfil donde el usuario vinculado a él será bloqueado en el tiempo definido, contando desde su último acceso a la plataforma.",
      "Região de entrada": "Región de entrada",
      "Essa região será utilizada no primeiro login realizado pelo usuário.": "Esta región se utilizará en el primer inicio de sesión realizado por el usuario.",
      "Selecione o metadado": "Seleccione el metadato",
      "Tipo de moderação": "Tipo de moderación",
      "Selecione o tipo de moderação": "Seleccione el tipo de moderación",
      "Domínio do e-mail": "Dominio del correo electrónico",
      "Escopo de acesso na região": "Alcance de acceso en la región",
      "Escopo de acesso do gerenciador": "Alcance del acceso del administrador",
      "Procure uma permissão (Mín. 03 caracteres)": "Buscar un permiso (Mínimo 03 caracteres)",
      "O item publicado pode ser visualizado e utilizado por todos os usuários que possuam permissão de visualização atribuida no perfil, enquanto o item que não for publicado ficará oculto de todos os usuários, exceto aqueles que tenham permissão de gerenciamento atribuída.": "​El artículo publicado puede ser visto y utilizado por todos los usuarios que tengan el permiso de visualización asignado en el perfil, mientras que el artículo que no está publicado se ocultará a todos los usuarios, excepto a aquellos que tengan asignado el permiso de gestión.",
      "Aplicar vigência de uso no item": "Aplicar duración de uso al artículo",
      "Aplica-se a vigência de uso no item caso este material possua uma data final em que o conteúdo não possa mais ser veiculado e compartilhado.": "La duración de uso del artículo se aplica si este material tiene una fecha de finalización en la que el contenido ya no se puede transmitir ni compartir.",
      "Data final": "Fecha final",
      "Relacionar item": "Relacionar artículo",
      "Buscar itens": "Buscar artículos",
      "Pode selecionar no maximo itens": "Puede seleccionar un máximo de {{count}} elementos",
      "Edição do item": "Edición del artículo",
      "Publicação do item": "Publicación del artículo",
      "Escolha qual a melhor opção de publicação do item.": "Elija la mejor opción para publicar el artículo.",
      "Vigência de uso do item até": "Plazo de uso del artículo hasta",
      "Vigência de uso do item": "Duración de uso del artículo",
      "para exclusão": "{{name}} por exclusión",
      "Você deseja realmente excluir": "Realmente quieres eliminar {{name}}?",
      "Excluir-entity": "Eliminar {{name}}",
      'Solicitante': 'Solicitante',
      'Executor (es)': 'Ejecutor (es)',
      'Data de abertura': 'Fecha de apertura',
      'Falha ao carregar dados.': 'No se pudieron cargar los dados.',
      'Mensagem do solicitante': 'Mensaje del solicitante',
      'Ordem de Serviço': 'Orden de Servicio',
      'Máx. 50 caracteres': 'Máx. 50 caracteres',
      'Título da ferramenta. Máx. 100 caracteres': 'Título de la herramienta. Máx. 100 caracteres',
      'Texto de apoio. Máx. 400 caracteres': 'Texto de apoyo. Máx. 400 caracteres',
      'Nome do botão. Máx. 50 caracteres': 'Nombre del Botón. Máx. 50 caracteres ',
      'Máx. 100 caracteres': 'Máx. 100 caracteres',
      'Título da ferramenta': 'Título de la herramienta',
      'Texto de Apoio': 'Texto de apoyo',
      'Explique para qual objetivo essa ferramenta está sendo criada.': 'Explique para qué se está creando esa herramient.',
      'Executor': 'Ejecutor',
      'Defina abaixo os usuários homologados na plataforma que poderão receber as solicitações da ferramenta com objetivo de executá-las.': 'Defina a continuación los usuarios aprobados en la plataforma que podrán recibir solicitudes de la herramienta para ejecutarlas.',
      'Busque pelo nome do executor': 'Busque por el nombre del ejecutor',
      'Busque pelo nome': 'Busque por el nombre',
      'Permitir que o usuário escreva uma mensagem?': '¿Permitir que el usuario escriba un mensaje?',
      ' Adicionar botão': 'Agregar Botón',
      ' Texto do botão para ação do item, como por exemplo, Salvar, Enviar, Compartilhar.': 'Texto del botón para acción del elemento, como Guardar, Enviar, Compartir.',
      'Nome do botão': 'Nombre del Botón',
      'Reprocessar arquivo': 'Reprocesar archivo',
      'Tentar novamente': 'Intentar nuevamente',
      'Data em que o usuário será bloqueado. Quando amarelo, faltam 3 dias para o bloqueio. Quando vermelho, já está bloqueado.': 'Cuando es amarillo, quedan 3 días hasta el bloqueo. Cuando está rojo está bloqueado.',
      'Você tem certeza que deseja deletar esse arquivo do repositório?': 'Está seguro de que desea eliminar este archivo del repositorio?',
      'arquivo não processado': 'Archivo sin procesar.',
      'de': 'en',
      "Todos os": "Todos los",
      "itens": "artículos",
      "Tornar item publicado": "Hacer que el artículo sea publicado",
      "Pressione enter/tab": "Presione enter/tab",
      "itens publicados": "artículos publicados",
      "itens despublicados": "artículos despublicados",
      'Nenhuma opção': 'Sin opción',
      'A alteração do prazo de expiração deste perfil impactará nos usuários ativos listado abaixo. Deseja continuar?': 'Cambiar la fecha de vencimiento de este perfil afectará a los usuarios activos que se enumeran a continuación. ¿Desea continuar?',
      'O prazo de expiração do perfil foi alterado e passará a contabilizar para todos os usuários atrelados a este perfil. Deseja continuar?': 'La fecha de vencimiento de este perfil se ha cambiado y ahora contará para todos los usuarios vinculados a este perfil. ¿Desea continuar?',
      "ativo encontrado para a ": "{{qtd}} recurso encontrado para la ",
      "ativos encontrados para a ": "{{qtd}} recursos encontrados para la ",
      "Atalhos": "Atajos",
      "Arquivo enviado com sucesso.": "El archivo ha subido correctamente.",
      "Erro inesperado ao enviar o PDF. Contate o suporte":
        "Error inesperado al enviar el PDF. Soporte de contacto.",
      "Arquivo inválido. Arquivo de até 6mb e em formato PDF":
        "Archivo inválido. Archivo de hasta 6 mb y en formato PDF",
      "Selecione o pdf para upload": "Seleccione el pdf para cargar",
      "Clique aqui ou arraste o PDF para enviar":
        "Haga clic aquí o arrastre el PDF para enviar",
      "Erro ao carregar o PDF.": "Error al cargar PDF.",
      "Última atualização": "Última actualización",
      "Nenhum PDF encontrado.": "No se encontró PDF.",
      " atualizado em": " actualizado en",
      "requerido por atualizado em": "requerido por {{client}} actualizado en ",
      "estou de acordo": "Estoy de acuerdo",
      "não estou de acordo": "No estoy de acuerdo",
      "Declaro ter lido e estar de acordo com os":
        "Declaro que he leído y estoy de acuerdo con los",
      "Termos de uso e Política de privacidade":
        "Términos de uso y la Política de privacidad",
      "Lamentamos que você não esteja de acordo com os Termos de uso e Política de privacidade requerido por. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.":
        "Lamentamos que no esté de acuerdo con los Términos de uso y la Política de privacidad requeridos por {{client}}. Desafortunadamente, no podemos liberar su acceso. Por favor, comuníquese con su gerente.",
      "Atualização de Termos de uso e Política de privacidade":
        "Actualización de los Términos de uso y la Política de privacidad",
      "Primeira versão": "Primera versión",
      "Segunda versão": "Segunda versión",
      "Terceira versão": "Tercera versión",
      "Quarta versão": "Cuarta versión",
      "Quinta versão": "Quinta versión",
      "Sexta versão": "Sexta versión",
      "Sétima versão": "Séptima versión",
      "Oitava versão": "Octava versión",
      "Nona versão": "Novena versión",
      "Décima versão": "Décima versión",
      "versões encontradas": "{{qtd}} versiónes encontradas",
      "versão encontrada": "{{qtd}} versión encontrada",
      "itens encontrados": "{{qtd}}  artículos encontrados",
      "item encontrado": "{{qtd}} artículo encontrado",
      "Itens relacionados": "Artículo relacionado",
      "Relacionar itens": "Elementos de Lista",
      "Checando arquivo para criação de item":
        "Comprobando archivo para la creación del artículo",
      "checando arquivo": "comprobando archivo",
      "Item criado": "Item criado {{name}}",
      "item criado": "item criado {{name}}",
      "Arquivo associado ao item": "Archivo asociado al artículo {{name}}",
      "arquivo associado ao item": "archivo asociado al artículo {{name}}",
      "Você pode selecionar no máximo 150 assets":
        "Puede seleccionar un máximo de 150 activos.",
      "Você pode selecionar no máximo 150 ativos":
        "Puede seleccionar un máximo de 150 activos.",
      "Arquivo": "Archivo",
      "Arquivos": "Archivos",
      "arquivo": "archivo",
      "arquivos": "archivos",
      "Excluir arquivo selecionado": "Eliminar {{total}} archivo seleccionado",
      "Excluir arquivos selecionados":
        "Eliminar {{total}} archivos seleccionados",
      "Excluir item selecionado": "Eliminar {{total}} elemento seleccionado",
      "Excluir itens selecionados":
        "Eliminar {{total}} elementos seleccionados",
      "Você deseja realmente excluir o arquivo selecionado?":
        "Realmente desea eliminar el archivo seleccionado?",
      "Você deseja realmente excluir todos os arquivos selecionados?":
        "Realmente desea eliminar todos los {{total}} archivos seleccionados?",
      "Excluir arquivo": "Eliminar {{total}} archivo",
      "Excluir arquivos": "Eliminar {{total}} archivos",
      "Você deseja realmente excluir o item selecionado?":
        "Realmente desea eliminar el elemento seleccionado?",
      "Você deseja realmente excluir todos os itens selecionados?":
        "Realmente desea eliminar todos los {{total}} elementos seleccionados?",
      "Excluir item": "Eliminar {{total}} elemento",
      "Excluir itens": "Eliminar {{total}} elementos",
      "Subir nova versão": "Nueva versión",
      "Baixar item": "Descargar",
      "Selecione e arraste os thumbnails para definir a ordem de apresentação no item. O primeiro thumbnail será a imagem de capa do item nas listas.":
        "Seleccione y arrastre las miniaturas para definir el orden de presentación en el elemento. La primera miniatura será la imagen de portada del artículo en las listas." /*google*/,
      "Baixar arquivo": "Descargar archivo",
      "Arquivo original": "Archivo original",
      "Imagens da web em alta": "Imágenes web de alta",
      "Imagens da web em baixa": "Imágenes web de baja",
      "Imagens da web em alta qualidade": "Imágenes web de alta calidad",
      "Imagens da web em baixa qualidade": "Imágenes web de baja calidad",
      "Imagens da web em alta qualidade_required": "Imágenes web de alta calidad *",
      "Imagens da web em baixa qualidade_required": "Imágenes web de baja calidad *",
      "Arquivos originais": "Archivos originales",
      "Adiciona arquivo para o item": "Agregar archivo al elemento {{item}}",
      "Criar nova versão para o item":
        "Crear una nueva versión para el artículo {{item}}",
      "Descrição do item": "Descripción del Artículo",
      "Nome do Perfil": "Nombre del segmento",
      "Situação": "Situación",
      "Selecione": "Seleccione",
      "Digite o código": "Escribe el código",
      "Ordenar itens de A-Z": "Ordenar elementos A-Z",
      "Ordenar itens de Z-A": "Ordenar elementos Z-A",
      "Ordenar itens pelos mais recentes": "Ordenar artículos por más nuevos",
      "Ordenar itens pelos mais antigos": "Ordenar elementos por más antiguos",
      "Nova versão": "Nueva versión",
      "Editar": "Editar",
      "Compartilhar": "para compartir",
      "Excluir": "Eliminar",
      "Adicionar arquivos": "Agregar archivos",
      "Edição de arquivo": "Edición de archivos",
      "Limpar itens selecionados": "Borrar {{total}} elementos seleccionados",
      "Insira abaixo os e-mails dos destinatários para compartilhar.": "Ingrese los correos electrónicos de los destinatarios a continuación para compartir.",
      "Mostrar arquivo selecionado": "Mostrar {{asset}} activo seleccionado.",
      "Mostrar arquivos selecionados":
        "Mostrar {{assets}} activos seleccionados.",
      "processando arquivo": "archivo de procesamiento",
      "Arquivos para exclusão": "Archivos para borrar",
      "Alterar Região": "Cambia región",
      "Aplicações": "Aplicaciones",
      "Configurações": "Ajustes",
      "Buscar no DAM": "Buscar en DAM",
      "Buscar no Bucket": "Buscar Bucket",
      "Solicitar acesso": "Solicitar acceso",
      "Busca": "Búsqueda",
      "Recuperação de senha": "Recuperación de contraseña",
      "Redefinição de senha": "Restablecimiento de contraseña",
      "Selecionar": "Seleccione",
      "Nenhum item encontrado": "No se encontraron artículos",
      "Ordenação de itens": "Pedido de artículos",
      "Visualização de itens": "Ver artículos",
      "Somente os": "Solo el",
      "itens visíveis": "artículos visibles",
      "itens não visíveis": "artículos no visibles",
      "Seleção de itens": "Selección de artículos",
      "Não selecionar nenhum item": "No seleccione elementos",
      "Visíveis": "visible",
      "Não Visíveis": "Não visíveis",
      "Não Visível": "Não visível",
      "Itens não visíveis não podem ser removidos dos relacionamentos":
        "Artículos no visibles no se pueden eliminar de las relaciones",
      "Não há itens a serem mostrados neste filtro.":
        "No hay elementos para mostrar en este filtro.",
      "Ordenação A-Z": "Clasificación A-Z",
      "Ordenação Z-A": "Clasificación Z-A",
      "Mais recente": "Más reciente",
      "Mais antigo": "Más antiguo",
      "Você pode selecionar no máximo 30 assets":
        "Puede seleccionar un máximo de 15 activos.",
      "Limite de 150 assets foi excedido.":
        "Se ha excedido el límite de 150 activos.",
      "Selecione e arraste para definir a ordem que os assets serão apresentados no item. O asset definido como primeiro, será o que aparecerá na listagem de itens (hero).":
        "Seleccione y arrastre para definir el orden en que los activos se presentarán en el elemento. El activo definido como primero será el que aparecerá en la lista de elementos (héroe).",
      "Adicionar nova versão": "Agregar nueva versión",
      "Limite de 255 foi excedido": "Se ha excedido el límite de 255.",
      "Este campo é obrigatorio": "Este campo es obligatorio.",
      "Obrigatório no mínimo 3 caractere": "Obligatorio al menos 3 caracteres.",
      "Criar nova versão": "Crear nueva versión",
      "Nenhum assets foi selecionado, volte e selecione no mínimo um asset":
        "No se seleccionaron activos, regrese y seleccione al menos un activo.",
      "Comentário sobre a nova versão": "Comenta sobre la nueva versión",
      "Descrição da versão": "Descripción de la versión",
      "Solicitação de acesso": "Solicitación de acceso",
      "O formulário tem erros":
        "El formulario tiene errores. Por favor corrija para continuar.",
      "Campo obrigatório": "Campo obligatorio",
      "Cliente": "Cliente",
      "Região": "Región",
      "Regiões": "Regiones",
      "Divisão": "División",
      "Divisões": "Divisiones",
      "Segmento": "Segmento",
      "Segmentos": "Segmentos",
      "Plataforma": "Plataforma",
      "Metadado": "Metadato",
      "Metadados": "Metadatos",
      "Categoria": "Categoría",
      "Categorias": "Categorías",
      "Atributo": "Atributo",
      "Atributos": "Atributos",
      "Gerenciador": "Gerente",
      "Ferramenta de distribuição": "Herramienta de distribución",
      "Itens": "Items",
      "Item": "Item",
      "Perfil": "Perfil",
      "perfil": "perfil",
      "Perfis": "Perfiles",
      "Usuário": "Usuario",
      "Usuários": "Usuarios",
      "Moderação": "Moderación",
      "Carregando": "Cargando",
      "Dados retornados com sucesso": "Datos recuperados con éxito.",
      "Nenhum resultado encontrado": "No se han encontrado resultados",
      "Buscar": "Búsqueda",
      "ADICIONAR": "AGREGAR",
      "Cancelar": "Cancelar",
      "Confirmar": "Confirmar",
      "Criar": "Crear",
      "Confirmação": "Confirmación",
      "Ver detalhes": "Ver detalles",
      "Detalhes": "Detalles",
      "Nome": "Nombre",
      "Descrição": "Descripción",
      "Mensagem": "Mensaje",
      "E-mail": "Correo",
      "Moderado por": "Moderado por",
      "Telefone": "Teléfono",
      "Download": "Descarga",
      "Tipo Aprovação": "Tipo de Aprobación",
      "Domínio Email": "Dominio de Correo",
      "Upload": "Carga",
      "Enviar": "Enviar",
      "Adicionar": "Agregar",
      "Visualizar": "Ver",
      "Enviar por e-mail": "Enviar por correo",
      "Gerenciar versões": "Administrar versiones",
      "Versão mais recente": "Última Versión",
      "Versão": "Version",
      "versão": "version",
      "Gerenciar": "Administrar",
      "Thumbnail": "Thumbnail",
      "Procurar": "Buscar",
      "Download CSV": "Descargar CSV",
      "Parâmetros Inválidos": "Parâmetros Inválidos",
      "Ver Colunas": "Ver Colunas",
      "Registros por Página": "Registros por Página",
      "Link": "Enlace",
      "Links": "Enlaces",
      "NUEVO LINK": "NUEVO ENLACE",
      "DNS": "DNS",
      "NUEVO DNS": "NUEVO DNS",
      "No.": "No.",
      "Legenda": "Subtitulo",
      "SIM": "SÍ",
      "NÃO": "NO",
      "Erro ao carregar os dados, contate o suporte":
        "Error al cargar los datos, contacte el soporte.",
      "Este campo é obrigatório": "Este campo es obligatorio",
      "Metadado obrigatório": "Metadato obligatorio",
      "Voltar": "Volver",
      "Voltar etapa": "Volver etapa",
      "Avançar": "Siguiente",
      "Salvar": "Guardar",
      "Conta": "Cuenta",
      "Alterar Senha": "Cambiar contraseña",
      "Sair": "Salir",
      "E-mail inválido": "Correo inválido",
      "Acesso negado": "Acesso denegado.",
      "Favor, contatar o administrador e solicitar permissão caso seja necessário acessar este conteudo":
        "Póngase en contacto con el administrador y solicite permiso si es necesario acceder a este contenido.",
      "Acesso do usuário foi aprovado com sucesso":
        "Acceso del usuário aprobado con êxito",
      "Erro ao processar. Contacte o administrador":
        "Error al procesar. Contacte al administrador",
      "Registro alterado com sucesso": "Registro ha cambiado con êxito",
      "Nome inválido.": "Nombre inválido.",
      "Descrição inválida.": "Descripción inválida.",
      "Máscara inválida.": "Máscara inválida",
      "Favor selecionar uma região": "Por favor seleccione una región",
      "Favor selecionar uma divisão": "Por favor seleccione una división",
      "Excluir registro": "Borrar registro",
      "Tipo do campo": "Tipo de campo",
      "Máscara padrão": "Máscara estándar",
      "Exemplo": "Ejemplo",
      "Valores": "Valores",
      "Tipo de valores": "Tipo de valores",
      "Tipo": "Tipo",
      "É necessário preencher este campo": "Debes completar este campo",
      "Aprovado": "Aprobado",
      "Reprovado": "Rechazado",
      "Permissões": "Permisos",
      "Ações": "Acciones",
      "Novo": "Nuevo",
      "Fechar": "Cerrar",
      "Ordenar": "Ordenar",
      "Obrigatório": "Requerido",
      "Nova Versão": "Nueva versión",
      "Nova versão do item": "Nueva versión del artículo",
      "Erro ao processar, entre novamente no item":
        "Error al procesar, vuelva a ingresar el artículo.",
      "ª": "º",
      "Nome do segmento": "Nombre del segmento",
      "Não encontramos o que você procura. Que tal uma nova busca?":
        "Desafortunadamente no encontramos lo que estás buscando. ¿Qué tal una nueva búsqueda?",
      "Parece que você chegou ao fim": " Parece que has llegado al final.",
      "O arquivo ultrapassa o limite de GB permitido.":
        "El archivo {{fileName}} supera el límite permitido de {{limit}}GB",
      "Arquivo para upload vazio.": "El archivo {{fileName}} es vacío.",
      "Arquivo para upload acima do limite 30":
        "Muy grande archivo {{fileName}}. Excede el límite permitido de 30GB",
      "Tipo de arquivo não permitido":
        "{{fileName}} es un archivo {{fileType}} que no está permitido",
      "Erro desconhecido ao subir arquivo":
        "Error desconocido al cargar el archivo {{fileName}}. Lo que se informó:",
      "Usuário não foi moderado": "El usuario no fue moderado",
      "Moderar": "Moderar",
      "Taxonomia do item": "Taxonomía del artículo",
      "Versões anteriores do item": "Versiones anteriores del artículo",
      "caso o nome, descrição e segmento tenham sido editados, serão replicados para as versões anteriores.":
        "si el nombre, la descripción y el segmento se han editado, se replicarán a versiones anteriores.",
      "caso o nome ou segmento tenham sido editados;":
        "si el nombre o segmento ha sido editado;",
      "A edição feita terá os seguintes impactos":
        "La edición realizada tendrá los siguientes impactos",
      "Taxonomia do item: caso o nome ou segmento tenham sido editados; Versões anteriores do item: caso o nome, descrição e segmento tenham sido editados, serão replicados para as versões anteriores.":
        "Taxonomía del artículo: si el nombre o segmento ha sido editado; Versiones anteriores del elemento: si el nombre, la descripción y el segmento se han editado, se replicarán a las versiones anteriores.",
      "Fazendo upload de arquivos":
        "Subiendo {{total}} archivos",
      "Fazendo upload de arquivo":
        "Subiendo {{total}} archivo",
      "Upload finalizado": "Carga finalizada",
      "troca-regiao-upload": "Su carga está en progreso y al cambiar de región se cancelarán. ¿Está seguro de que desea cambiar de región?",
      "logout-upload": "Su carga está en progreso y al salir se cancelará. Estás seguro que quieres irte?",
      "Cancelar uploads em andamento": "Cancelar uploads en curso",
      "Código externo segmento": "Código externo segmento",
      "Código externo divisão": "Código externo división",
      "Código externo categoria": "Código externo categoría",
      "Somente imagens web em alta": "Solo imágenes web emergentes",
      "Somente imagens web em baixa": "Solo imágenes de baja web",
      "Solicitação enviada com sucesso": "Solicitud enviada con éxito.",
      "Confira a caixa de entrada e spam de ":
        " Revise su bandeja de entrada y spam ",
      "Erro ao tentar reprocessar, contate o suporte":
        "Error al intentar reprocesar. Contacte el soporte.",
      "ACEITAR E FECHAR": "ACEPTAR Y CERRAR",
      "Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência. Ao usar nosso serviço, você concorda com nossos termos e condições de uso.":
        "Usamos cookies y otras tecnologías similares para mejorar su experiencia. Al utilizar nuestro servicio, acepta nuestros términos y condiciones de uso.",
      "Eu li e concordo com os": "He leído y acepto las",
      "requerido por": "requerido por",
      "Não há notificações": "No hay notificaciones",
      "Motivo desconhecido": "Motivo desconocido",
      "Requisição inválida": "Requisición no valida"
    },
    login: {
      "Critérios de senha": "Criterios de contraseñas",
      "cliqueaquiparasabermais": "Haz clic <1>aquí</1> para saber más.",
      "Critérios para definição de senha": "Criterios para establecer contraseñas",
      "A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.": "La contraseña debe tener al menos 8 caracteres y contener al menos 1 letra mayúscula, 1 letra minúscula, 1 número y 1 carácter especial.",
      "Não podem ser utilizadas as últimas 05 senhas.": "Las últimas 5 contraseñas no se pueden utilizar.",
      "Sua senha deve ser diferente das 05 últimas senhas já cadastradas, com no mínimo 08 caracteres, contendo letra, número e caractere especial.": "Su contraseña debe ser diferente a las últimas 05 contraseñas ya registradas, con al menos 08 caracteres, conteniendo letra, número y carácter especial.",
      "Digite a sua nova senha": "Introduzca su nueva contraseña",
      "Senha atualizada com sucesso.": "Contraseña actualizada exitosamente.",
      "Ir para o login": "ir a iniciar sesión",
      "Lamentamos que você não esteja de acordo com a Política de privacidade requerido por. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.": "Lamentamos que no esté de acuerdo con la Política de privacidad requerida por {{client}}. Desafortunadamente, no podemos darle acceso. Comuníquese con su gerente.",
      "Atualização da Política de privacidade": "Actualización de la política de privacidad",
      "Compartilhamento de arquivos": "Compartición de archivos",
      "Solicitação de redefinição de senha não encontrada.":
        "Solicitud de restablecimiento de contraseña no encontrada.",
      "Senha muito curta": "Contraseña demasiado corta.",
      "Senhas não conferem.": "Las contraseñas no coinciden.",
      "ID do usúario não foi encontrado.":
        "No se encontró la identificación de usuario.",
      "Entendi": "Entender",
      "O e-mail e a senha fornecidos não correspondem às informações em nossos registros ou não estão aprovados. Verifique-as ou entre em contato com o administrador.":
        "El correo electrónico y la contraseña proporcionados no coinciden con la información en nuestros registros o no están aprobados. Verifíquelos o comuníquese con su administrador.",
      "Redefinição de senha": "Restablecimiento de contraseña",
      "Redefinir a senha": "Restablecer la contraseña",
      "Repita a nova senha": "Repite la nueva contraseña",
      "Insira abaixo uma nova senha":
        "Ingrese una nueva contraseña a continuación.",
      "Insira abaixo o endereço de e-mail associado à sua conta e, em seguida, enviaremos um e-mail com o link de redefinição de senha":
        "Ingrese la dirección de correo electrónico asociada con su cuenta a continuación, y luego le enviaremos un correo electrónico con el enlace para restablecer la contraseña.",
      "da": "la",
      "solicitação de acesso": "solicitud de acceso",
      "solicitar acesso": "solicitar acceso",
      "Ainda não possui acesso ao ": "Aún no tienes acceso a ",
      "Esqueceu a Senha?": "¿Olvidaste la contraseña?",
      "Acessar": "Acceder",
      "Logout realizado com sucesso.": "Cierre de sesión exitoso.",
      "Região inválida.": "Región inválida.",
      "Rota inválida.": "Ruta inválida.",
      "E-mail inválido": "Correo inválido",
      "Digite sua senha": "Ingrese su contraseña",
      "Esqueci a senha": "Olvidé la contraseña",
      "Esqueceu a senha?": "¿Olvidaste la contraseña?",
      "Insira o endereço de email associado à sua conta que lhe enviaremos um link de redefinição de senha":
        "Ingrese el correo asociado a su cuenta y le enviaremos un enlace para restablecer la contraseña.",
      "Logout realizado com sucesso": "La sesión finalizó con éxito.",
      "Voltar para o login": "Volver al login",
      "Senha atual": "Contraseña actual",
      "Nova senha": "Nueva contraseña",
      "Confirmar Senha": "Confirmar contraseña",
      "É necessário informar a senha atual": "Contraseña actual requerida.",
      "A nova senha deve ter no minimo 6 caracteres":
        "La nueva contraseña debe tener al menos 6 caracteres.",
      "A senha e a confirmação não correspondem":
        "La contraseña y la confirmación no coinciden.",
      "Telefone inválido": "Teléfono inválido",
      "Editar dados da conta": "Editar datos de la cuenta",
    },
    recoverPassword: {
      "Sua senha já foi alterada": "Su contraseña fue cambiada",
    },
    solicitacaoAcesso: {
      "Requisito de Senha": "Requisito de contraseña",
      " A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.": " la contraseña debe tener al menos 8 caracteres y contener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.",
      " A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial. E não pode ter sido utilizada nas últimas 5 senhas.": "la contraseña debe tener al menos 8 caracteres y contener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial. Y no puede haber sido utilizado en las últimas 5 contraseñas.",
      "Ajude o novo usuário da plataforma a saber qual opção ele deve escolher em cada passo da solicitação de acesso.": "Ayuda al nuevo usuario de la plataforma a saber qué opción debe elegir en cada paso de la solicitud de acceso.",
      "Personalizar solicitações": "Personalizar solicitudes",
      "Finalizar cadastro": "Finalizar el registro",
      "Enviar cadastro": "Enviar registro",
      "Faça seu login": "Acceso",
      "Cadastro efetuado com sucesso!": "¡Registro completado con éxito!",
      "Solicitação enviada com sucesso!": "¡Solicitud enviada con éxito!",
      "Não há perfil para selecionar": "No hay perfil para seleccionar",
      "Solicitaçāo enviada com sucesso!": "¡Solicitud enviada con éxito!",
      "Você receberá um e-mail de confirmação após a avaliaçāo do moderador.": "Recibirá un correo electrónico de confirmación después de la evaluación del moderador.",
      "Escolha sua região": "Elige tu región",
      "Cada região possui diferentes tipos de conteúdo":
        "Cada región tiene diferentes tipos de contenido.",
      "Escolha o seu perfil": "Elige tu perfil",
      "Cada perfil possui diferentes tipos de permissões":
        "Cada perfil tiene diferentes tipos de permisos.",
      "Dados complementares": "Datos complementarios",
      "Preencha seus dados complementares":
        "Complete sus datos complementarios.",
      "Dados pessoais": "Datos personales",
      "Preencha seus dados pessoais": "Complete sus datos personales.",
      "Já possui cadastro? faça o":
        "¿Ya registrado? Inicia sesión",
      "Passo anterior": "Paso anterior",
      "Próximo passo": "Proximo passo",
      "próximo": "proximo",
      "Nome Completo": "Nombre Completo",
      "Senha": "Contraseña",
      "Informe seu e-mail": "Ingrese su correo",
      "Informe seu nome completo": "Ingrese su nombre completo",
      "Informe seu número de telefone": "Ingrese su número de teléfono",
      "Informe uma senha": "Ingrese una contraseña",
      "Informe o email do moderador": "Ingrese el e-mail del moderador",
      "E-mail já cadastrado": "Correo ya registrado",
      "Solicitação realizada com sucesso! Aguarde moderação":
        "¡Solicitud exitosa! Espera moderación",
      "Enviar solicitação": "Enviar solicitación",
      "Moderação de acesso": "Moderación de acceso",
      "Parâmetros para autenticação inválidos. Contate o suporte": "Parámetros de autenticación no válidos. Contacte el soporte"
    },
    DAM: {
      "Os itens em que não há permissão para gerenciar não serão excluídos.": "Los elementos que no se pueden administrar no se eliminarán",
      "Item despublicado": "Artículo no publicado",
      "Pessoas": "Personas",
      "Deseja continuar?": "¿Desea continuar?",
      "compartilhar tudo": "Compartir todo",
      "Tente novamente ou entre em contato com o suporte.": "Vuelva a intentarlo o póngase en contacto con el soporte.",
      "Não foi possível concluir a solicitação do download.": "No se pudo completar la solicitud de descarga.",
      "Abrir central de downloads": "Abrir Centro de Descargas",
      "Ver informações do Item": "Ver información del artículo",
      "Com quem você deseja compartilhar?": "¿Con quién quieres compartir?",
      "Deixe a sua mensagem aqui": "Deja aquí tu mensaje",
      "Total de Itens": "Artículos totales",
      "O seu Perfil não possui permissão para baixar estes Itens.": "Tu perfil no tiene permiso para descargar estos artículos.",
      "Central de downloads": "Centro de descargas",
      "Confira os downloads que você solicitou.": "Verifica las descargas que solicitaste.",
      "item_selecionado": "{{qtd}} artículos seleccionado",
      "itens_selecionados": "{{qtd}} artículos seleccionados",
      "Navegação": "Navegación",
      "Por hierarquia": "Por jerarquía",
      "Em card": "En tarjeta",
      "Em lista": "En lista",
      "Enviar para validação": "Enviar para validación",
      "Comente sobre os anexos que estão sendo enviados.": "Comente sobre los anexos que están enviados.",
      "Tarefa invalidada": "Tarifa invalidada",
      "A tarefa foi invalidada e voltará para a execução.": "La tarea ha sido invalidada y reanudará su ejecución.",
      "Tarefa reprovada": "Tarea fallida",
      "A tarefa foi reprovada e voltará para a execução.": "La tarea ha fallado y reanudará la ejecución.",
      "Comente o que deve ser alterado.": "Comenta lo que hay que cambiar.",
      "Os materiais enviados": "Los materiales enviados",
      "Invalidar projeto": "Proyecto inválido.",
      "O projeto voltará para execução. Explique por que foi invalidado.": "El proyecto volverá a la ejecución. ¿Por qué fue invalidado?",
      "Reprovar projeto": "Proyecto desaprobado",
      "O projeto voltará para execução. Explique por que foi reprovado.": " El proyecto volverá a la ejecución. ¿Por qué fue desaprobado?",
      "Novo projeto": "Nuevo proyecto",
      "Nome do projeto": "Nombre del proyecto",
      "Informações complementares do projeto": "Información adicional del proyecto",
      "Quais informações precisam ser preenchidas durante a execução do projeto?": "¿Qué información se debe completar durante la ejecución del proyecto?",
      "Relacione itens já cadastrados na plataforma que possam auxiliar no projeto.": "Enumere los artículos ya registrados en la plataforma que pueden ayudar en el proyecto.",
      "Informações para criação do item": "Información de creación de artículo",
      "Como o item deve ser criado após a finalização do projeto?": "¿Cómo se debe crear el elemento una vez finalizado el proyecto?",
      "Galeria": "Galería",
      "Iniciar Projeto": "Iniciar proyecto",
      "Clique aqui para copiar o link de compartilhamento": "Haz clic aquí para copiar el enlace para compartir",
      "Lista de itens": "Lista de artículos",
      "Gerar link": "Generar enlace",
      "Clique no botão abaixo para gerar o link. Uma notificação será enviada quando o link estiver pronto para ser compartilhado.": "Haz clic en el botón de abajo para generar el enlace. Se enviará una notificación cuando el enlace esté listo para ser compartido.",
      "Clique no botão abaixo para compartilhar via link": "Haz clic en el botón de abajo para compartir a través del enlace",
      "Copiar link": "Copiar Link",
      "Integração não processada": "Integraciones sin procesar",
      "Visualizar": "Para ver",
      "Visualizar por": "Vista por",
      "Meus uploads": "Mis subidas",
      "Tipo de arquivo": "Tipo de archivo",
      "Todos": "Todos",
      "Imagem": "Imagen",
      "Vídeo": "Video",
      "Áudio": "Audio",
      "Documentos": "Documentos",
      "Compactados": "Comprimidos",
      "Compactadores": "Compactadores",
      "Tipos": "Tipos",
      "Tipo": "Tipo",
      "Apresentações": "Introducciones",
      "Planilhas": "Hojas de trabajo",
      "Ordenação de arquivos": "Ordenar archivos",
      "E-mail enviado com sucesso.": "Email enviado con éxito.",
      "Todos os itens": "Todos los artículos",
      "Escreva aqui a sua dúvida": "Escriba su pregunta aquí",
      "Envie a sua dúvida sobre o conteúdo para o suporte de": "La respuesta te llegará en tu email de acceso a {{name}}",
      "Suporte": "Soporte",
      "Digite o e-mail": "Correo electrónico",
      "Escreva uma mensagem para o destinatário": "Escribir un mensaje al destinatario",
      "Informações do ativo": "Información de activos",
      "Baixar ativo": "Descarga activa",
      "Ao escolher a opção com o asterisco (*), somente ativos digitais do tipo imagem serão disponilibizados no download.": "Al elegir la opción con un asterisco (*), solo los activos digitales del tipo imagen estarán disponibles en la descarga.",
      "Selecionar ativo": "Seleccionar activo",
      "Visualizar modelo 3D": "Ver modelo 3D",
      "Ativos selecionados": "Activos seleccionados",
      "Ativo pertence ao item": "El activo pertenece al artículo",
      "Os ativos removidos permanecem no item de origem.": "Los activos eliminados permanecen en el elemento de origen.",
      "Os ativos removidos permaneceram no item de origem, ou se adicionado, no bucket.": "Los activos eliminados permanecieron en el elemento de origen o, si se agregaron, en el depósito.",
      "Criação de item": "La creación de artículo",
      "Criar item": "Crear artículo",
      "Detalhes do ativo": "Detalles de activos",
      "Fechar": "Cerca",
      "Os ativos removidos permanecem no bucket.": "Los activos eliminados permanecen en el depósito.",
      "Os ativos removidos permaneceram no local de origem, na versão anterior ou, se adicionado, no bucket.": "Los activos eliminados permanecieron en la ubicación de origen, la versión anterior o, si se agregaron, el depósito.",
      "Gerenciar ativos do item": "Administrar activos de artículos",
      "Não há nenhum ativo neste momento. Que tal procurar no bucket?": "No hay activos en este momento. ¿Qué hay de mirar en el cubo?",
      "Versão em branco": "Versión en blanco",
      "total_ativo": '{{total}} activo',
      "total_ativos": '{{total}} activos',
      "Ativos do item": "Activos del artículo",
      "Gerenciar ativos": "Administrar activos",
      "Editar informações": "Editar informacion",
      "Editar informações do item": "Editar información del artículo",
      "Adicionar nova versão": "Agregar nueva versión",
      "Adicionar ativo": "Agregar activo",
      "Remover ativo do item": "Eliminar activo del elemento",
      "Gostaria de deixar algum comentário na nova versão?": "¿Quieres dejar un comentario sobre la nueva versión?",
      "Sem comentário": "Sin comentarios",
      "Nova versão de item criada com sucesso.": "Nueva versión del artículo creada con éxito.",
      "Os ativos removidos permanecem na versão anterior.": "Los activos eliminados permanecen en la versión anterior.",
      "Edição salva com sucesso.": "Edición guardada con éxito.",
      "Comentários": "Comentarios",
      "Ordenação de itens": "Pedidos de artículos",
      "Itens de A-Z": "Artículos de la A a la Z",
      "Itens de Z-A": "Artículos Z-A",
      "Arquivos de A-Z": "Archivos de la A a la Z",
      "Arquivos de Z-A": "Archivos Z-A",
      "Mais recentes": "Más reciente",
      "Mais antigos": "Más viejo",
      "Limpar filtros X": "Filtro limpio X",
      "Comente aqui": "Comenta aquí",
      "Selecione ativos para adicionar ao item": "Seleccionar activos para agregar al artículo",
      "Arquivos selecionados": "Archivos seleccionados",
      "Mostrar selecionados": "Mostrar seleccionado",
      "Filtrar metadados": "Filtrar metadatos",
      "Buscar metadado": "Obtener metadatos",
      "Ao trocar de Região, a seleção de Itens será desfeita. Deseja continuar?": "Al cambiar la región, la selección del artículo se deshará. ¿Desea continuar?",
      "Ao trocar de Região, a seleção de Ativos será desfeita. Deseja continuar?": "Al cambiar la región, la selección del Activos se deshará. ¿Desea continuar?",
      "O item não pode ser criado pois há uma falha na comunicação entre os servidores. Favor tente novamente mais tarde.": "El artículo no se puede crear porque hay un error de comunicación entre los servidores. Por favor, inténtelo de nuevo más tarde.",
      "O item não pode ser verificado pois há uma falha na autenticação entre os servidores. Favor tente novamente mais tarde.": "El artículo no se puede verificar porque hay un error de autenticación entre los servidores. Por favor, inténtelo de nuevo más tarde.",
      "O item não foi encontrado no processo de integração e, portanto, não pode ser criado ou associado. Favor entrar em contato com sua equipe de dados.": "El artículo no se encontró en el proceso de incorporación y, por lo tanto, no se puede crear ni asociar. Póngase en contacto con su equipo de datos.",
      "em": "el",
      "por": "por",
      "às": "a las",
      "Formato": "Formato",
      "Criado em": "Creado el",
      "Executar o video": "Reproducir el video",
      "Abrir em tela cheia": "Abrir en pantalla completa",
      "Download do arquivo": "Descarga de archivos",
      "Editar item": "Editar artículo",
      "Arquivos do item": "Archivos de elementos",
      "Informações do item": "Información del artículo",
      "Compartilhar item": "Compartir artículo",
      "Nenhum arquivo foi selecionado, volte e selecione no mínimo um arquivo": "No se seleccionó ningún archivo, regrese y seleccione al menos un archivo",
      "Por favor, selecione um arquivo para salvar": "Seleccione un archivo para guardar",
      "Versões do item": "Versiones de artículos",
      "Você tem certeza que deseja excluir esta versão?": "¿Está seguro de que desea eliminar esta versión?",
      "Excluir versão": "Eliminar versión",
      "Descrição da versão mais recente": "Descripción de la última versión",
      "Acesso não autorizado": "Acceso no autorizado",
      "Não autenticado ou login expirado.": " Inicio de sesión no autenticado o caducado.",
      "Tente sair e entrar novamente. Se o problema persistir, favor contatar o administrador do sistema informando o problema.": "Intente cerrar sesión y volver a iniciar sesión. Si el problema persiste, comuníquese con el administrador del sistema con el problema.",
      "Houve erro ao obter os dados para processar o bucket.": "Se produjo un error al obtener los datos para procesar el depósito.",
      "Arquivo excluído do DAM. Reenviar para criação do item.": "Archivo excluido de DAM. Reenviar para la creación del artículo.",
      "Ocorreu um erro inesperado.": "Ha ocurrido un error inesperado.",
      "Carregar mais": "Carga más",
      "Hierarquia": "Jerarquía",
      "Erro ao salvar os dados.": "Error al guardar los datos.",
      "Dados salvo com sucesso.": "Datos guardados exitosamente.",
      "Limpar lista": "Limpiar lista",
      "Limpar": "Limpio",
      "Remover da lista": "Quitar de la lista",
      "Erro ao carregar dados do item": "Error al cargar los datos del artículo",
      "Error na automação": "Error de automatización",
      "Ver Item": "Ver artículo",
      "associando arquivo": "asociar archivo",
      "criando item": "creando artículo",
      "extraindo dados para criar item": "extraer datos para crear un artículo",
      "preparando reprocessamento da integração": "preparación del reprocesamiento de integración",
      "Erro ao tentar cancelar o upload": "Error al intentar cancelar la carga",
      "Data final de vigência": "Fecha efectiva",
      "Este item teve erro na geração da taxonomia. Exclua-o para incluir novamente": "Este artículo tuvo un error al generar la taxonomía. eliminarlo para agregar de nuevo",
      "Erro geral na automação via API.": "Error general en la automatización vía API.",
      "Erro ao carregar": "Error al cargar",
      "Você tem certeza que deseja cancelar o upload dos arquivos?": "¿Está seguro de que desea cancelar la carga de archivos?",
      "Uploads cancelados com sucesso.": "Subidas canceladas correctamente.",
      "Enviando arquivo": "Envío de archivo",
      "Enviar erro": "Enviar erro",
      "arquivo associado": "archivo asociado",
      "Processamento finalizado": "Procesamiento terminado",
      "Processamento do thumb": "Procesamiento de Thumb",
      "Despublicar item": "Anular la publicación de artículo",
      "Publicar item": "Publicar artículo",
      "item criado": "item criado",
      "Erro ao tentar cancelar todos os uploads.": "Error al intentar cancelar todas las cargas.",
      "Ultrapassou o limite máximo de upload de itens ao mesmo tempo.": "Superó el límite máximo de carga de {{limit}} articulos al mismo tiempo.",
      "Limite de itens atingido. Permitido apenas itens anexados no total.": "Límite de artículos alcanzado. Solo se permiten {{limit}} artículos adjuntos en total.",
      "Não pode enviar exatamente o mesmo arquivo simultaneamente.": "No puede cargar exactamente el mismo archivo simultáneamente.",
      "Rascunhos": "Borradores",
      "Alterar Imagem": "Cambiar imagen",
      "Idioma": "Idioma",
      "Dados do usuário": "Datos del usuario",
      "Escreva aqui": "Escribe aquí",
      "itens selecionados": "artículos seleccionados",
      "Itens selecionados": "Artículos seleccionados",
      "item selecionado": "item seleccionado",
      "Você pode excluir no máximo 30 itens.":
        "Puede eliminar un máximo de 30 elementos.",
      "Lista de itens selecionados": "Lista de artículos seleccionados",
      "Itens para exclusão": "Artículos para eliminar",
      " itens selecionados?": " artículos seleccionados?",
      "(essa ação é irreversível)": "(esta acción es irreversible)",
      "Você deseja realmente limpar da lista todos os ":
        "¿De verdad quieres borrar todo",
      " itens selecionados?(essa ação é irreversível)":
        " elementos seleccionados? (esta acción es irreversible)",
      "Você deseja realmente excluir todos os ":
        "Realmente quieres eliminar todo ",
      "Você deseja realmente excluir o único item selecionado?(essa ação é irreversível)":
        "Realmente desea eliminar el único elemento seleccionado? (Esta acción es irreversible)",
      "Você deseja realmente limpar da lista o único item selecionado?":
        "Realmente desea borrar el único elemento seleccionado de la lista?",
      "Compartilhar com pessoas externas": "Compartir con personas externas",
      "Você não tem permissão para compartilhar estes itens":
        "No tiene permiso para compartir estos elementos.",
      "Remetente desconhecido": "Remitente desconocido",
      "Link não encontrado.": "Enlace no encontrado.",
      "Este link foi desativado ou não existe.":
        "Este enlace ha sido deshabilitado o no existe.",
      "Baixar tudo": "Descargar todo",
      "Download realizado com sucesso.": "Descarga exitosa.",
      "arquivos": "archivos",
      "Compartilhado por": "Compartido por",
      "Este link será desativado em": "Este enlace se desactivará en",
      "Exluir arquivo": "Eliminar archivo",
      "Exluir versão": "Eliminar versión",
      "Publicar arquivo": "Hacer visible el archivo",
      "Despublicar arquivo": "Esconder archivo",
      "Você tem certeza que deseja deletar esse asset do repositório?":
        "Está seguro de que desea eliminar este activo del repositorio?",
      "Você tem certeza que deseja publicar": "Estás seguro de que deseas hacer visible",
      "Você tem certeza que deseja despublicar": "Estás seguro de que deseas ocultar",
      "Você tem certeza que deseja excluir a versão":
        "Estás seguro de que deseas eliminar la versión {{deleteName}} ?",
      "Item com erro": "Item con error",
      "Arquivos para download": "Archivos para descargar",
      "Item compartilhado com sucesso": "Artículo compartido correctamente.",
      "Arquivo compartilhado com sucesso": "Archivo compartido correctamente.",
      "Compartilhar ativo": "Compartir archivo",
      "Upload concluído com sucesso. Aguarde o processamento das imagens":
        "Carga completada con éxito. Espera a que se procesen las imágenes.",
      "Importar arquivos": "Importar archivos",
      "Preparando…": "Preparando…",
      "Solte os arquivos aqui ou clique para fazer o upload":
        "Suelte los archivos aquí o haz clic para cargar.",
      "texto-IA-bucket":
        "Utilizamos inteligencia artificial en el procesamiento del archivo. Tan pronto se complete este proceso, la imagen del archivo estará disponible.",
      "Selecionar asset": "Seleccionar activo",
      "Excluir arquivo": "Borrar archivo",
      "Comentário": "Comentar",
      "É necessário preencher o campo Categoria":
        "Debes completar el campo Categoría",
      "É necessário preencher o campo Segmento":
        "Debes completar el campo Segmento.",
      "Novo Item": "Nuevo artículo",
      "Nome do Item": "Nombre del artículo",
      "Descrição do Item": "Descripción del artículo",
      "Download terminado": "Descarga terminada!",
      "Erro no download. Tente novamente, caso o erro persista, contate o suporte":
        "Error en la descarga. Intente nuevamente, si el error persiste, comuníquese con el soporte",
      "Autorizando o download": "Autorizando la descarga de {{itemName}}",
      "Item não está publicado. Visível somente para administradores":
        "artículo no está publicado. Visible solo para administradores.",
      "Informe ao menos um e-mail": "Ingrese ao menos un correo electrónico",
      "Há e-mail(s) com erro": "Hay un más correo electrónico con error",
      "Pressione <Enter> para inserir mais de um e-mail":
        "Presione <Enter> para ingresar más de un correo",
      "Compartilhamento efetuado com sucesso": "Compartido exitosamente!",
      "Excluir item": "Eliminar {{total}} archivo",
      "Excluir itens": "Eliminar {{total}} archivos",
      "Não é possível fazer streaming do vídeo. O tipo não é suportado pelo navegador ou a extensão não corresponde ao conteúdo":
        "No es posible transmitir el video. El navegador no admite el tipo o la extensión no coincide con el contenido.",
      "Erro ao fazer o streaming do vídeo. A permissão para executar pode ter vencido. Execute-o novamente":
        "Error al transmitir video. El permiso para ejecutar puede haber expirado. Ejecútalo de nuevo.",
      "Pronto para download": "{{name}} ajá está listo para download",
      "Reportar erro": "Informar error",
      "Você deseja realmente reportar esse problema?":
        "De verdad quieres informar de este problema?",
      "Descrição do erro": "Error de descripción",
      "Erro ao processar o arquivo ": "Error al procesar el archivo ",
      "Erro ao fazer upload do arquivo ": "Error al cargar el archivo ",
      "Retorno do servidor": "Regreso del servidor",
      "Este erro reportado será transformado em um chamado e a equipe de suporte da Yapolir etornará diretamente para ":
        "Este error informado se convertirá en una llamada y el equipo de soporte de Yapolir regresará directamente a ",
      " com mais informações sobre a ocorrência em até 24h a partir da data de envio":
        " con más información sobre la ocurrencia dentro de las 24 horas posteriores a la fecha de envío",
      "Erro reportado": "Error informado",
      "Ao reportar esse erro um chamado será aberto e a equipe de suporte da Yapoli retornará diretamente para ":
        "Al informar este error, se abrirá un ticket y el equipo de soporte de Yapoli regresará directamente a ",
      " com mais informações sobre a ocorrência em até 24h a partir da data de envio.":
        " con más información sobre el suceso dentro de las 24 horas posteriores a la fecha de envío.",
      "Erro reportado com sucesso.": "Error informado correctamente.",
      "Retomar uploads pausados": "Reanudar cargas en pausa",
      "Pausar uploads em progresso": "Pausar cargas en curso",
      "Uploads retomados com sucesso.":
        "Las cargas se reanudaron correctamente.",
      "Erro ao tentar retomar todos os uploads":
        "Error al intentar reanudar todas las cargas",
      "Uploads pausados com sucesso.": "Subidas pausadas correctamente.",
      "Erro ao tentar pausar todos os uploads":
        "Error al intentar pausar todas las cargas",
      "Upload pausado com sucesso.": "Subida pausada correctamente.",
      "Erro ao tentar pausar o upload": "Error al intentar pausar la carga",
      "Upload retomado com sucesso.": "Subida reanudada correctamente.",
      "Erro ao tentar retomar o upload": "Error al intentar reanudar la carga",
      "Cancelar upload": "Cancelar carga",
      "Você tem certeza que deseja cancelar o upload do arquivo":
        "Está seguro de que desea cancelar la carga del archivo {{file}}?",
      "Somente integrações não processadas": "Solo integraciones sin procesar",
      "Você tem certeza que deseja reprocessar a integração deste arquivo?":
        "Está seguro de que desea volver a procesar la integración de este archivo?",
      "Você selecionou mais itens que o permitido para download em massa.":
        "La descarga masiva puede tener un máximo de {{maximum}} elementos. Ha seleccionado {{selected}} artículos.",
      "Download em andamento. Você será notificado quando concluído e poderá baixar em notificações.":
        "Descarga en curso. Se le notificará cuando haya completado y podrá descargar en notificaciones.",
      "A importação via CSV terminou. Clique para copiar link para baixar arquivo com os detalhes.":
        "La importación a través de CSV ha finalizado. Haga clic para copiar el link para descargar el archivo con los detalles.",
      "Relevância": "Relevancia"
    },
    manager: {
      "Editar variantes do atributo": "Editar variantes de atributos",
      "esquema de metadados": "esquema de metadatos",
      "Defina qual esquema será considerado neste atributo.": "Define qué esquema se tendrá en cuenta en este atributo.",
      "Esquema de Metadados": "Esquema de Metadatos",
      "Incluir Esquema de Metadados": "Incluir Esquema de Metadatos",
      "Defina quais metadados serão considerados atributos deste esquema.": "Defina qué metadatos se considerarán atributos de este esquema.",
      "Chave secundária do Item": "Clave de artículo secundario",
      "Defina quais metadados serão adicionados à chave secundária do item.": "Defina qué metadatos se agregarán a la clave secundaria del elemento.",
      "Gerar CSV": "Generar CSV",
      "Perfil do usuário alterado com sucesso.": "El perfil de usuario ha cambiado con éxito.",
      "Mover": "Mover",
      "Mover de perfil": "Mover perfil",
      "Mover usuário para outro perfil": "Mover usuario a otro perfil",
      "Essa ação pode ser realizada apenas para perfis em que seu usuário é moderador. Defina o novo perfil do usuário": "Esta acción solo se puede realizar para perfiles en los que tu usuario sea moderador. Defina el nuevo perfil de usuario",
      "Por autenticação": "Por autenticación",
      "Pela plataforma Yapoli": "Por la plataforma Yapoli",
      "Cliente ID": "Identificación del cliente",
      "Escopo": "Alcance",
      "URL de autorização": "URL de autorización",
      "URL de token": "URL del token",
      "Chave secreta": "llave secreta",
      "Mudar Senha": "Cambiar la contraseña",
      "URL do serviço": "URL del servicio",
      "Configure os parâmetros do seu modelo de autenticação Single Sign ON - SSO. Dúvidas entre em contato com o suporte@yapoli.com": "Configura los parámetros de tu modelo de autenticación Single Sign ON - SSO. Si tiene alguna pregunta, comuníquese con suporte@yapoli.com",
      "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos downloads e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com": "Cree un nombre de taxonomía alternativo para sus elementos para que se muestren como una opción para descargar y compartir. Después de la creación, enlace en la categoría deseada. Si tiene alguna pregunta, comuníquese con suporte@yapoli.com",
      "Escolha o modelo de autenticação": "Elija el modelo de autenticación",
      "Escolha qual será o tipo de autenticação do perfil": "Elija qué tipo de autenticación de perfil será",
      "Lista de cadastro integrado": "Lista de registro integrada",
      "Digite o domínio para acesso": "Introduce el dominio para acceder",
      "Os ativos de vídeos seguirão as especificações do arquivo original.": "Los activos de video seguirán las especificaciones del archivo original.",
      "Categoria relacionada": "Categoría relacionada",
      "Relacionamento de imagens": "Relación de imagen",
      "Ao habilitar, selecione a Categoria que possui Itens relacionados para que ocorra a identificação automática das imagens.": "Al habilitar, seleccione la categoría que tiene artículos relacionados para que se produzca la identificación automática de imágenes",
      "Quando habilitado, será possível relacionar de forma manual os itens cadastrados na plataforma, durante a criação e edição do item.": "Cuando está habilitado, será posible listar manualmente los artículos registrados en la plataforma, durante la creación y edición de artículos.",
      "Política de privacidade": "Política de privacidad",
      "Essa solicitação está associada a esse item": "Esta solicitud esta asociada con este artículo",
      "Item da solicitação": "Artículo de solicitud",
      "Comentário para validação": "Comentario para validación",
      "Comentário da reprovação": "Comentario de desaprobación",
      "Comentário da invalidação": "Comentário de invalidación",
      "Os arquivos selecionados foram aprovados": "Los archivos seleccionados han sido aprobados",
      "Os arquivos selecionados aguardam aprovação": "Los archivos seleccionados están en espera de aprobación",
      "Rascunho do item": "Proyecto de artículo",
      "Alterar status": "Cambiar estado",
      "1 comentário": "1 comentario",
      "comentários": "{{qtd}} comentarios",
      "Informações para a criação do item na plataforma": "Información para crear el artículo en la plataforma",
      "O rascunho do seu item foi criado. Caso deseje, é possível editar as informações de cadastro ao clicar nele": "Se ha creado el borrador de su artículo. Si lo desea, puede editar la información de registro haciendo clic en ella.",
      "Resumo  da tarefa": "Resumen de tareas",
      "Informações complementares": "Informaciones complementarias",
      "Itens relacionados": "Artículos relacionados",
      "Anexar arquivos para a tarefa": "Adjuntar archivos a la tarea",
      "Anexe arquivos para auxiliar na tarefa, como referências, documentos, etc.": "Adjunte archivos para ayudar con la tarea, como referencias, documentos, etc.",
      "Solte os arquivos aqui ou clique para fazer o upload.": "Suelte los archivos aquí o haga clic para cargar.",
      "Arquivos anexados": "Archivos adjuntos",
      "Selecione os arquivos que devem ir para aprovação": "Seleccione los archivos que deben ir para aprobación",
      "Envie mensagens aos envolvidos no projeto": "Enviar mensajes a las partes interesadas del proyecto",
      "Paleta de cores do item": "Paleta de colores del artículo",
      "Será extraído as cores predominantes do item.": "Se extraerán los colores predominantes del artículo.",
      "workflow": "flujo de trabajo",
      "solicitação": "solicitud",
      "Legenda do botão em": "Título del botón en {{idm}}",
      "Selecione quem será o aprovador da ferramenta de workflow": "Selecccione quién será el aprobador de la herramienta de flujo de trabajo",
      "Selecione quem será o validador da ferramenta de workflow (se houver)": "Seleccione quién será el validador de la herramienta de flujo de trabajo (si corresponde)",
      "Defina qual será o nome da ferramenta de": "Definir cuál será el nombre de la herramienta de {{tool}}",
      "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta": "Cree un flujo de trabajo que requiera la aprobación de tareas y genere un nuevo elemento al final de la herramienta",
      "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.": "Desde elementos registrados en la plataforma, enviar una solicitud para que otros usuarios puedan realizar alguna tarea",
      "Nome da ferramenta": "Nombre de la herramienta",
      "Descreva qual o objetivo da ferramenta de": "Describir el propósito de la herramienta de {{tool}}",
      "Explique aos envolvidos o que se pretende alcançar com essa ferramenta": "Explique a los involucrados lo que quieres lograr con esta herramienta",
      "Essa ferramenta será utilizada para...": "Esta herramienta se utilizará para...",
      "Defina qual será a legenda do botão": "Definir cuál será el titulo del botón",
      "Adicione um nome para o botão da ação da ferramenta": "Agregue un nombre para el botón de acción de la herramienta",
      "Enviar, Compartilhar, Executar...": "Enviar, Compartir, Ejecutar...",
      "Selecione quem será o executor da ferramenta de": "Seleccione quién ejecutará la herramienta de {{tool}}",
      "Apenas usuários cadastrados na plataforma podem ser": "Solo los usuarios cadastrados en la plataforma pueden ser {{type}}",
      "Selecione os usuários": "Seleccionar usuarios",
      "Selecione o usuário": "Seleccionar usuario",
      "Selecione os metadados que complementam a solicitação dessa ferramenta": "Seleccione los metadatos que complementan la solicitud de esta herramienta",
      "Quais metadados podem auxiliar na execução da ferramenta?": "¿Qué metadatos pueden ayudar en la ejecución de la herramienta?",
      "Iniciar tarefa": "Iniciar tarea",
      "Nome da tarefa": "Nombre de la tarea",
      "Adicionar idioma": "Agregar idioma",
      "Criação de nova tarefa": "Nueva creación de tareas",
      "Defina qual será o nome da tarefa": "Definir cuál será el nombre de la tarea",
      "Idioma padrão da plataforma": "Idioma predeterminado de la plataforma",
      "Esse nome será visto pelos usuários que utilizam o idioma na plataforma": "Este nombre lo verán los usuarios que utilicen el idioma {{idm}} en la plataforma",
      "Resumo da tarefa": "Resumen de tareas",
      "Explique aos envolvidos o que se pretende alcançar com essa tarefa": "Explique a los involucrados lo que quiere lograr con esta tarea",
      "Essa tarefa será para...": "Esta tarea será para...",
      "Informações complementares da tarefa": "Información adicional de la tarea",
      "Preencha essas informações para auxiliar na execução da tarefa": "Rellene esta información para ayudar a realizar la tarea",
      "Busque os itens cadastrados na plataforma que possam auxiliar na tarefa": "Buscar elementos registrados en la plataforma que puedan ayudar en la tarea",
      "Ao finalizar essa tarefa, será criado automaticamente um item na plataforma com o histórico do que foi realizado. Como o item deve ser cadastrado?": "Al finalizar esta tarea, automáticamente se creará un artículo en la plataforma con el historial de lo realizado.¿Como se deve registrar el artículo?",
      "Aprovador da tarefa": "Aprobador de tareas",
      "Ferramenta de workflow": "Herramienta de flujo de trabajo",
      "A tarefa foi finalizada com sucesso.": "La tarea se completó con éxito.",
      "O que gostaria de fazer agora?": "¿Qué te gustaría hacer ahora?",
      "Voltar para o dash": "Volver al guión",
      "abrir item": "Abrir el articulo",
      "Esta ferramenta não está associada a nenhuma categoria": "Esta herramienta no está asociada a ninguna categoría",
      "Utilização da ferramenta": "Uso de herramienta",
      "Workflow": "Flujo de trabajo",
      "Buscar item": "Buscar archivo",
      "Informações complementares do projeto": "Información adicional del proyecto",
      "Quais informações precisam ser preenchidas durante a execução do workflow?": "¿Qué información debe completarse durante la ejecución del flujo de trabajo?",
      "Selecione os metadados": "Seleccione los metadatos",
      "criação da ferramenta de workflow": "Creación de herramientas de flujo de trabajo",
      "edição da ferramenta de workflow": "Edición de herramientas de flujo de trabajo",
      "Ferramenta criada para": "Herramienta creada para",
      "Selecione quem será o executor dessa ferramenta dentre os usuários da plataforma.": "Seleccione quién será el ejecutor de esta herramienta entre los usuarios de la plataforma.",
      "Executor(es)": "Ejecutor(es)",
      "Selecione quem será o aprovador dessa ferramenta dentre os usuários da plataforma.": "Seleccione quién será el aprobador de esta herramienta entre los usuarios de la plataforma.",
      "Aprovador(es)": "Aprobador(es)",
      "REQUEST": "Solicitação",
      "Solicitação": "Solicitación",
      "WORKFLOW": "Workflow",
      "ferramenta": "instrumento",
      "Nome da ferramenta de solicitação": "Nombre de herramienta",
      "Nome da ferramenta do workflow": "Nombre de herramienta",
      "Executor": "Ejecutor",
      "Aprovador": "Aprobador",
      "Categoria Aberta": "Categoría abierta",
      "Esquemas de atributos": "Esquemas de atributos",
      "Defina qual esquema será considerado nesta categoria": "Defina qué esquema será considerado en esta categoría",
      "Em categorias abertas os compartilhamentos não expiram após cada download.": "En las categorías abiertas, las acciones no caducan después de cada descarga.",
      "Não bloquear": "No bloquear",
      "Acesso do usuário": "Acceso de usuario",
      "A plataforma poderá bloquear o acesso do usuário após tentativas excessivas de login.": "La plataforma puede bloquear el acceso de los usuarios después de intentos de inicio de sesión excesivos.",
      "Bloquear após tentativas": "Bloquear después de {{qtd}} intentos",
      "Ao editar uma categoria, a taxonomia deve conter o nome do item, quando esta tem itens atrelados.": "Al editar una categoría, la taxonomía debe contener el nombre del artículo, cuando tiene ítems vinculados.",
      "Essa Categoria não poderá ser alterada após sua criação.": "Esta Categoría no se puede cambiar después de su creación.",
      "Essa Categoria não pode ser alterada por ser do tipo galeria de itens.": "Esta categoría no se puede cambiar ya que es un tipo de galería de artículos.",
      "Como gostaria de redimensionar?": "¿Cómo le gustaría cambiar el tamaño?",
      "Proporcionalmente": "Proporcionalmente",
      "Medidas exatas": "Medidas exactas",
      "Plano de fundo de imagem PNG": "Plano de fondo de imagen PNG",
      "Português": "Portugues",
      "Português*": "Portugues*",
      "Inglês": "Inglés",
      "Espanhol": "Español",
      "Explique como identificar qual Região o solicitante deve pedir acesso": "Explique como identificar cual Região o solicitante deve pedir acesso",
      "Explique como identificar qual Perfil o solicitante deve pedir acesso": "Explicar cómo identificar qué perfil debe solicitar acceso el solicitante",
      "Explique porque são necessários os dados complementares": "Explique por qué se necesitan datos adicionales",
      "Explique porque são necessários os dados pessoais": "Explicar por qué se necesitan los datos personales.",
      "Defina qual será o separador de palavras nas nomenclaturas para download e para a distribuição.": "Definir cuál será la palabra separador en las nomenclaturas de descarga y distribución.",
      "Para adicionar aperte ENTER ou TAB. Para colar de uma lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar.": "Para agregar presione ENTER o TAB. Para pegar de una lista de palabras, use una coma (;) o punto y coma (;) para separar.",
      "Separador de palavras": "Separador de palabras",
      "Expressão de busca": "Expresión de búsqueda",
      "Dicionário de substituição": "Diccionario de reemplazo",
      "_ (Underline)": "_ (Subrayar)",
      "- (Hífen)": "- (Guión)",
      "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar": "Para agregar presione ENTER o TAB. Para pegar de una lista de palabras, use una coma (;) o un punto y coma (;) para separar",
      "Defina como aparecerá o nome do ativo ao baixar e/ou nas integrações do item.": "Defina cómo aparecerá el nombre del activo al descargar y/o en las integraciones de elementos.",
      "sobre expressão regular.": "información sobre la expresión regular.",
      "Convidar": "To invite",
      "Exclusão de usuário": "User deletion",
      "Ops, algo deu errado": "Huy! Algo salió mal",
      "Limite de 20 e-mails inseridos alcançado. Revise a lista e tente novamente.": "Se alcanzó el límite de 20 correos electrónicos ingresados. Revise la lista y vuelva a intentarlo.",
      "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de e-mails, utilize vírgula (,) ou ponto e vírgula (;) para separar": "Ingrese el correo electrónico y presione ENTER o TAB para agregar. Para la lista de correo, use coma (,) o punto y coma (;) para separar",
      "Convidar usuários": "Invitar a los usuarios",
      "Convide colaboradores para esse Perfil que ainda não possuem acesso a plataforma. Acompanhe o status do convite na página de usuários.": "Invite a este perfil a empleados que aún no tienen acceso a la plataforma. Seguimiento del estado de la invitación en la página de usuarios.",
      "Insira o e-mail de seus convidados": "Ingrese el correo electrónico de sus invitados",
      "Enviar convite": "Enviar invitacion",
      "Convite enviado com sucesso.": "Invitación enviada con éxito.",
      "Acesse a Página de usuário para acompanhar os convites.": "Vaya a la página de usuario para realizar un seguimiento de las invitaciones.",
      "ok, entendi": "OK entendí",
      "Manter original": "Mantener original",
      "Defina qual será o nome que representará esse nome alternativo ao baixar o item": "Definir cuál será el nombre que representará este nombre alternativo al descargar el elemento",
      "Será aplicado somente em itens da mesma região.": "Solo se aplicará a artículos de la misma región.",
      "Nomes alternativos": "Nombres Alternativos",
      "Adicione as opções de nomes alternativos para baixar, compartilhar e para CDN.": "Agregue opciones de nombre alternativas para descargar, compartir y para CDN.",
      "The alternatives names may not have more than 10 items.": "Los nombres alternativos no pueden tener más de 10 elementos.",
      "Itens pertencentes a região ": "Artículos pertenecientes a la región ",
      "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos download e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com": "Crea un alias para la taxonomía de tus artículos para que aparezcan como opción en las descargas y compartilhamento. Después de la creación, enlace en la categoría deseada. Si tiene alguna consulta, comuníquese con support@yapoli.com",
      "Especificação de imagem": "Especificación de la imagen",
      "Nome alternativo": "Nombre alternativo",
      "Erro ao editar metadados.": "Error al editar metadatos.",
      "Busque quais metadados serão adicionados ao nome alternativo": "Buscar qué metadatos se añadirán al alias",
      "Defina qual será o nome que representará essa taxonomia alternativa ao baixar o item": "Defina qué nombre representará esta taxonomía alternativa cuando descargue el artículo",
      "Para dúvidas sobre o conteúdo cadastrado nessa região, quem deve receber o e-mail de suporte?": "Para preguntas sobre el contenido registrado en esta región, ¿quién debe recibir el correo electrónico de soporte?",
      "E-mail para suporte de conteúdo": "Correo electrónico para soporte de contenido",
      "Este metadado não está associado a nenhuma categoria": "Este metadato no está asociado a ninguna categoría",
      "Este metadado não está associado a nenhum perfil": "Este metadato no está asociado a ningún perfil",
      "Detalhes do metadado": "Detalles de metadatos",
      "está sendo utilizado em": "{{name}} se está utilizando en",
      "está sendo utilizada em": "{{name}} se está utilizanda en",
      "Busque os metadados que complementam a solicitação dessa ferramenta.": "Obtener los metadatos que complementan la solicitud de esta herramienta",
      "Adicione as especificações de formatos da imagem para baixar e compartilhar.": "Agregue especificaciones de formato de imagen para descargar y compartir.",
      "Extensão": "Extensión",
      "Taxonomia do item": "Taxonomía del ítem",
      "Modo de cor": "Modo de color",
      "Largura": "Ancho",
      "Altura": "Altura",
      "Profundidade": "Profundidad",
      "Resolução": "Resolución",
      "Compressão": "Compresión",
      "Qualidade (de 0 a 100)": "Calidad (0 a 100)",
      "Plano de fundo": "Plano de fondo",
      "Transparente": "Transparente",
      "Processo de pngquant que utiliza algoritmo de quantização para compactar pngs": "Proceso pngquant que utiliza un algoritmo de cuantificación para comprimir pngs",
      "Exclusão de metadados da imagem": "Eliminación de metadatos de imagen",
      "Os ativos em 3D seguirão as especificações do arquivo original.": "Los activos 3D seguirán las especificaciones del archivo original.",
      "Os ativos em PDF seguirão as especificações do arquivo original.": "Los activos PDF seguirán las especificaciones del archivo original.",
      "Nome em português": "Nombre en portugués",
      "Nome em inglês": "Nombre en ingles",
      "Nome em espanhol": "Nombre en español",
      "Nome alternativo em português": "Nombre alternativo en portugués",
      "Nome alternativo em inglês": "Nombre alternativo en ingles",
      "Nome alternativo em espanhol": "Nombre alternativo en español",
      "Imagem": "Imagen",
      "Crie a sua própria especificação de imagens para distribuição CDN, para downloads e compartilhamentos em categorias. Dúvidas entre em contato com suporte@yapoli.com.": "Cree su propia especificación de imágenes para la distribución de CDN, descargar y compartir en categorías. Si tiene alguna consulta, comuníquese con support@yapoli.com.",
      "Especificações de imagem": "Especificaciones de imagen",
      "Habilitar": "Habilitar",
      "Desabilitar": "Desactivar",
      "Os Perfis permitidos terão acesso aos itens, mas apenas perfis com permissão de Gerenciar terão visualização na Categoria pela navegação no DAM.": "Los perfiles permitidos tendrán acceso a los artículos, pero sólo los perfiles con el permiso de gestión podrán ver la categoría durante la navegación DAM.",
      "A Categoria ficará visível para todos durante a navegação no DAM.": "la Categoría será visible para todos durante la navegación DAM.",
      "A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma principal preenchido as informações serão utilizadas como padrão em todos os idiomas.": "La plataforma puede contener nombre y descripción según el idioma deseado. Si solo ha completado el idioma principal, la información se utilizará de forma predeterminada en todos los idiomas.",
      "Esse arquivo será mostrado a todos os usários que não possuirem um idioma definido ao entrar na plataforma.": "Este archivo se mostrará a todos los usuarios que no tengan un idioma definido al ingresar a la plataforma.",
      "Esse arquivo será mostrado a todos os usários que possuirem o inglês como idioma da plataforma.": "Este archivo se mostrará a todos los usuarios que tengan el inglés como idioma de la plataforma.",
      "Esse arquivo será mostrado a todos os usários que possuirem o espanhol como idioma da plataforma.": "Este archivo se mostrará a todos los usuarios que tengan el español como idioma de la plataforma.",
      "Personalize o nome da plataforma e de uma descrição para que outros usuários conheçam sua funcionalidade.": "Personaliza el nombre de la plataforma y una descripción para que otros usuarios conozcan su funcionalidad.",
      "Adicione domínios e links externos como sites, redes sociais e outros web sites utilizados por você, clientes e fornecedores.": "Agregue dominios externos y enlaces como sitios web, redes sociales y otros sitios web utilizados por usted / clientes / proveedores.",
      "A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma obrigatório preenchido as informações serão utilizadas como padrão em todos os idiomas.": "La plataforma puede contener nombre y descripción según el idioma deseado. Si solo ha completado el idioma obligatorio, la información se utilizará de forma predeterminada en todos los idiomas.",
      "Personalize a plataforma com a sua identidade visual.": "Personaliza la plataforma con tu identidad visual.",
      "Miniatura": "Miniatura",
      "Defina quais metadados serão adicionados na taxonomia do item.": "Defina qué metadatos se agregarán a la taxonomía del elemento.",
      "Digite aqui os valores para o campo tipo Lista": "Ingrese los valores para el campo Tipo de lista aquí",
      "Selecione a miniatura correspondente ao idioma desejado.": "Seleccione la miniatura correspondiente al idioma deseado.",
      "Adicionar miniatura": "Agregar miniatura",
      "A inatividade em Peril foi desativada.": "La inactividad del perfil ha sido deshabilitada.",
      "Data": "Fecha",
      "Lista": "Lista",
      "Número": "Número",
      "Texto": "Texto",
      "Nenhuma opção": "Sin opciones",
      "Digite um domínio valido": "Ingrese un dominio válido",
      "Digite o domínio sem o @": "Ingrese el dominio sin @",
      "A alteração do prazo deste perfil impactará nos usuários ativos listado abaixo. Deseja continuar?": "Cambiar el término de este perfil afectará a los usuarios activos que se enumeran a continuación. ¿Desea continuar?",
      "Criação de divisão": "Creación de división",
      "Edição de divisão": "Edición dividida",
      "Criação de região": "Creación de región",
      "Edição de região": "Edición de región",
      "Itens da divisão serão alterados.": "Se cambiarán los elementos de la división.",
      "Alterando Divisão": "Cambio de división",
      "Erro ao carregar os dados, contate o suporte": "Error al cargar datos, comuníquese con el soporte",
      "Concluído": "Concluido",
      "Alterando": "Cambiando",
      "Erro ao processar, contate o administrador.": "Error de procesamiento, comuníquese con el administrador.",
      "O formulário tem erros, para continuar corrija-os.": "El formulario tiene errores, corríjalos para continuar.",
      "Erro ao deletar os dados, contate o administrador.": "Error al eliminar datos, comuníquese con el administrador.",
      "Criação de": "La creación de",
      "Edição de": "Edición de",
      "Buscar pelo nome": "Buscar por nombre",
      "Nenhum dado encontrado.": "Datos no encontrados.",
      "Deve haver ao menos um link": "Debe haber al menos un enlace",
      "Nome obrigatório.": "Nombre requerido.",
      "Defina qual ferramenta de solicitação está associada a essa categoria.": "Defina qué herramienta de solicitud está asociada con esta categoría.",
      "Defina quais metadados serão considerados atributos desta categoria.": "Defina qué metadatos se considerarán atributos de esta categoría.",
      "Adicione o nome alternativo para visualização em downloads e compartilhamentos.": "Agregue nombre alternativo para mostrar en las descargas y compartidos.",
      "Permissível": "Permisible",
      "Chave Secundária": "Clave secundaria",
      "Editável": "Editable",
      "Item não pode ser desativado pois está na taxonomia.": "El elemento no se puede deshabilitar ya que está en la taxonomía.",
      "Item não pode ser removido pois está na taxonomia.": "El elemento no se puede eliminar ya que está en la taxonomía.",
      "Erro inesperado. Contate o suporte": "Error inesperado. Soporte de contacto",
      "carregando": "Cargando",
      "Não é possível permissionar os  metadados abaixo pelos seguintes erros": "No es posible autorizar los metadatos a continuación debido a los siguientes errores",
      "Impossibilidade de permissionamento": "Imposibilidad de permiso",
      "Criação de metadado": "Creación de metadatos",
      "Digite aqui os valores para o campo tipo lista": "Ingrese los valores para el campo de tipo de lista aquí",
      "Edição de metadado": "Edición de metadatos",
      "Alteração de perfil temporário": "Cambio de perfil temporal",
      "A aplicação de um novo tempo para Inatividade de perfis impactará em todos os usuários atrelados ao Perfil. Deseja continuar?": "A aplicação de um novo tempo para Inatividade de perfis impactará em todos os usuários atrelados ao Perfil. Deseja continuar?",
      "Alteração de período de inatividade": "Cambio de tiempo de inactividad",
      "Legenda do botão": "Título del botón",
      "Personalize a legenda do botão.": "Personaliza el título del botón",
      "Ao excluir uma ferramenta você estará excluindo todo fluxo atrelado a ela. Os dados da ferramenta serão perdidos e ela não aparecerá para nenhum perfil. Você deseja continuar?": "Al eliminar una herramienta, estará eliminando todo el flujo vinculado a ella. Los datos de la herramienta se perderán y no aparecerán para ningún perfil. ¿Quieres continuar?",
      "Desbloquear usuário": "Desbloquear usuario",
      "Máximo caracteres": "Máximo {{value}} caracteres",
      "Ferramentas de solicitação": "Solicitar herramientas",
      "Ferramenta de solicitação": "Solicitar herramientas",
      "ferramentas de solicitação": "solicitar herramientas",
      "ferramenta de solicitação": "solicitar herramientas",
      "Texto de apoio.": "Texto de apoyo.",
      "Tem certeza que deseja deletar a":
        "Estás seguro de que deseas eliminar el",
      "Idioma": "Idioma",
      "Moeda": "Moneda",
      "Timezone": "Timezone",
      "Excluir": "Borrar",
      "Erro ao enviar imagem": "Error al enviar imágen",
      "Nenhum resultado encontrado": "Ningún resultado encontrado",
      "Clique aqui ou arraste uma imagem para enviar":
        "Haz clic aquí o arrastre una imágen para enviar.",
      "Remover Imagem": "Eliminar imagen",
      "Selecione o arquivo para upload": "Seleccione el archivo para cargar",
      "Arquivo inválido. Arquivo até 2MB em JPG ou PNG":
        "Archivo inválido. Archivo de hasta 2 MB en JPG o PNG.",
      "Arquivo inválido. Arquivo até 2MB em ICO ou PNG":
        "Archivo inválido. Archivo de hasta 2 MB en ICO o PNG.",
      "Arquivo inválido. Arquivo até 5MB em JPG ou PNG":
        "Archivo inválido. Archivo de hasta 5 MB en JPG o PNG.",
      "Arquivo inválido. Arquivo até 5MB em ICO ou PNG":
        "Archivo inválido. Archivo de hasta 5 MB en ICO o PNG.",
      "Domínio inválido": "Dominio inválido",
      "Idioma inválido": "Idioma inválido",
      "Moeda inválido": "Moneda inválido",
      "Timezone inválido": "Timezone inválido",
      "Incluir Região": "Incluir región",
      "Incluir": "Incluir",
      "Adicionar região": "Agregar región",
      "Editar região": "Editar región",
      "Nome da região": "Nombre de la región",
      "Domínio padrão": "Domínio estándar",
      "Confirmação de exclusão": "Confirmación para borrar",
      "Tem certeza que deseja deletar a região":
        "Está seguro de que desea eliminar la región",
      "Incluir Divisão": "Incluir División",
      "Adicionar Divisão": "Agregar división",
      "Editar Divisão": "Editar división",
      "Digite o nome da região": "Ingrese el nombre de la región",
      "Alterando segmentos e itens": "Cambiar segmentos y artículos",
      "Alterando itens": "Cambiar artículos",
      "Itens da divisão serão alterados":
        "artículos de la división serán cambiados.",
      "Itens do segmento serão alterados":
        "Los artículos del segmento serán cambiados.",
      "Nome da divisão": "Nombre de la división",
      "Código externo": "Código externo",
      "Tem certeza que deseja deletar a divisão":
        "Está seguro de que desea eliminar la división",
      "Imagem enviada com sucesso": "Imágen enviada con éxito",
      "Incluir Segmento": "Incluir Segmento",
      "Adicionar Segmento": "Agregar Segmento",
      "Editar Segmento": "Editar Segmento",
      "Tem certeza que deseja deletar o segmento":
        "Está seguro de que desea eliminar el segmento",
      "Favor selecionar um tipo de campo":
        "Por favor seleccione un tipo de campo",
      "Incluir metadado": "Incluir metadato",
      "Editar metadado": "Editar metadato",
      "Nome do metadado": "Nombre del metadato",
      "Digite o valor e aperte ENTER ou TAB para salvar e digitar outro":
        "Ingrese el valor y presione ENTER o TAB para guardar e ingresar otro.",
      "Tem certeza que deseja deletar o metadado":
        "Está seguro de que desea eliminar este metadato",
      "Incluir categoria": "Incluir categoría",
      "Adicionar metadado": "Agregar categoría",
      "Nome da Categoria": "Nombre de categoría",
      "Taxonomia do Item": "Taxonomía del item",
      "Descrição da categoria": "Descripción de la categoría",
      "Metadado não pode ser excluído, pois faz parte da taxonomia":
        "Los metadatos no se pueden eliminar, ya que es parte de la taxonomía.",
      "Criar perfil": "Crear perfil",
      "Adicionar perfil": "Agregar perfil",
      "Editar perfil": "Editar perfil",
      "Nome do perfil": "Nombre del perfil",
      "Descrição do perfil": "Descripción del perfil",
      "Tipo de Moderação": "Tipo de moderación",
      "Escopo de acesso": "Alcance de acceso",
      "Busque uma permissão (mínimo 3 caracteres)":
        "Buscar un permiso (mínimo 3 caracteres)",
      "Editar dados da plataforma": "Editar datos de la plataforma",
      "Nome do cliente": "Nombre del cliente",
      "Nome da plataforma": "Nombre de la plataforma",
      "Cor primária": "Color primario",
      "Cor secundária": "Color secundario",
      "Cor ternária": "Color ternario",
      "Cor da fonte": "Color de la fuente",
      "Cor da fonte flutuante": "Color de la fuente flotante",
      "Cor da fonte ativa": "Color de la fuente activa",
      "Digite o hexadecimal correspondente à cor":
        "Ingrese el hexadecimal correspondiente al color",
      "Legenda": "Subtitulo",
      "Salvar alterações": "Guardar cambios",
      "Alterar imagens da plataforma": "Cambiar imágenes de plataforma",
      "Plano de fundo do login": "Fondo de inicio de sesión",
      "Logo da pagina inicial": "Logotipo de la página de inicio",
      "Logo para menu superior": "Logotipo para Menú Superior",
      "Logo para compartilhamento": "Logotipo para Compartir (thumb whatsapp)",
      "Logo interno": "Logotipo interno",
      "Logo do e-mail": "Logotipo del correo",
      "Favicon": "Favicon",
      "Clique para ativar": "Haz clic para activar",
      "Clique para desativar": "Haz clic para desactivar",
      "Ao proceder com esta alteração, todos os itens na":
        "Al proceder con este cambio, todos los artículos en",
      "serão alterados. As URLs dos respectivos itens poderão ser reescritas e a operação levará algum tempo. Ao final do processo você será notificado":
        "serán cambiados. Los Enlaces de los elementos respectivos pueden reescribirse y la operación llevará algún tiempo. Al final del proceso, se le notificará.",
      "texto-alteracao-entity-1":
        "Al proceder con este cambio, todos los artículos en ",
      "texto-alteracao-entity-2":
        "serán cambiados. Los Enlaces de los elementos respectivos pueden reescribirse y la operación llevará algún tiempo. Al final del proceso, se le notificará.",
      "Está seguro de que deseja proceder com a alteração agora?":
        "Está seguro de que desea continuar con el cambio ahora?",
      "Fechar, o processamento continuará":
        "Cerrar, el procesamiento continuará",
      "Esta tela pode ser fechada": "Esta pantalla se puede cerrar.",
      "Único": "Único",
      "Múltiplo": "Múltiple",
      "Em atualização": "En actualización",
      "Arquivo inválido. Somente um arquivo em formato CSV permitido.":
        "Archivo inválido. Solo se permite un archivo en formato CSV",
      "Clique aqui ou arraste um arquivo para enviar":
        "Haz clic aquí o arrastre un archivo para enviar",
      "Criar itens": "Crear artículos",
      "aprovadores": "aprobadores",
      "executores": "ejecutores",
      "validadores": "validadores",
    },
  },
  en: {
    common: {
      "Esquema": "Schema",
      "Esquema de Metadados": "Metadata Schema",
      "A categoria trata-se de uma galeria, portanto o nome do item terá o mesmo nome do arquivo.": "The category is a gallery, so the name of the item will have the same name as the file.",
      "x horas": "{{hours}} hours",
      "x horas e y minutos": "{{hours}} hours and {{minutes}} minutes",
      "Tempo de expiração": "Expiry time",
      "Erro ao criar metadado.": "Error creating metadata.",
      "Digite o valor desejado e aperte ENTER ou TAB para adicionar. Para lista de valores, utilize vírgula (,) para separar.": "Type the desired value and press ENTER or TAB to add. For list of values, use comma (,) to separate.",
      "Moderadores": "Moderators",
      "incomplete-allowed": "Incomplete registration",
      "Publicação dos Itens": "Item Publication",
      "Defina seu ZIP": "Set your ZIP",
      "Itens dentro do ZIP": "Items inside the ZIP",
      "Cada Item em uma pasta": "Each Item in a Folder",
      "Todos os Itens em uma única pasta": "All Items in a single folder",
      "solicitar download": "Request download",
      "Acompanhe na Central de downloads a sua solicitação. Uma notificação será enviada quando os Itens estiverem prontos.": "Track your request in the Download Center. Notification will be sent when the Items are ready.",
      "Verifique as atualizações dos itens e o Link para compartilhamento.": "Check item updates and Share Link.",
      "Não há downloads": "There are no downloads.",
      "download-generated": "Request ready. Access the Center to download.",
      "Contate o administrador ou solicite a permissão para acessar o conteúdo.": "Contact your administrator or request permission to access content.",
      "Expirado": "Expired",
      "Processando": "Processing",
      "Selecione o formato": "Select the format",
      "Se desejar, nomeie o seu ZIP": "If you wish, I named your ZIP",
      "Receber notificação": "Get notification",
      "Na plataforma": "On the platform",
      "Por e-mail": "By email",
      "iniciar download": "Start download",
      "Central de download": "Download center",
      "Paleta de cores": "Color palette",
      "Visualize as cores que se destacam no item": "Preview the colors that stand out on the item",
      "Imagem web em alta": "High quality web image",
      "Imagem web em baixa": "Low quality web image",
      "Ativo original": "Original asset",
      "Tarefa": "Task",
      "Selecione qual ferramenta você deseja criar": "Select which tool you want to create",
      "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.": "From items registered on the platform, send a request so that other users can perform some task.",
      "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta.": "Create a workflow that requires task approval and generate a new item when finishing the tool.",
      "commentTaksNotification": "{{ NAME_USER }} <2>commented</2> on task {{ taks_name }}",
      "Limpar comentário": "Clear comment",
      "Uploads de arquivos": "Upload files",
      "Anexar": "Attach",
      "self-approved": "Self approved",
      "no-allower-info": "No approver information",
      "allower-was-purged": "User removed",
      "Selecione o segmento": "Select segment",
      "Selecione a categoria": "Select category",
      "Adicione um comentário": "Add a comment",
      "Adicione um comentário...": "Add a comment...",
      "Copiar link": "Copy link",
      "Link copiado com sucesso": "Link copied successfully",
      "Compartilhar por link": "Share by link",
      "Compartilhar por e-mail": "Share by e-mail",
      "Como você deseja compartilhar?": "How do you want to share?",
      "Escolha o nome para o compartilhamento.": "Choose the name for the share.",
      "Uma notificação será enviada quando o link estiver pronto": "A notification will be sent when the link is ready",
      "última atualização": "last update",
      "sem registro": "no records",
      "Operação concluída.": "Operation completed",
      "shared-generated": "Share link successfully generated",
      "Ferramentas": "Tools",
      "Ferramenta": "Tool",
      "Atalho": "Shortcut",
      "Tamanho": "Size",
      "Adicione um comentário sobre a publicação deste item": "Add a comment about the publication of this Item",
      "Aprovação de usuário": "User approval",
      "Reprovação de usuário": "User disapproval",
      "Comente aqui": "Comment here",
      "Você tem certeza que deseja publicar o item?": "Are you sure you want publish the item?",
      "Você tem certeza que deseja despublicar o item?": "Are you sure you want unpublish the item?",
      "Não há registro de publicação deste item.": "There is no publication record regarding this item.",
      "pela-vigencia": "by Validity",
      "pelo-usuario": "by User",
      "por no dia": "{{operation}} {{reason}} {{user}} on {{date}}",
      "Comentário da publicação": "Publication comment",
      "Acompanhe o histórico de publicação do item": "Follow the items publication record",
      "o item em": "{{user}} {{operation}} the item on {{date}}",
      "cliqueaquiparasabermais": "Clique <1>aqui</1> para saber mais.",
      "Galeria de itens": "Item gallery",
      "Ao tornar a Categoria uma galeria, cada item conterá apenas um (01) ativo. Ela ficará visível como um Atalho e não será possível alterar a categoria após a sua criação.": "When making the Category a gallery, each item will only contain one (01) asset. It will be visible as a Shortcut and it will not be possible to change the category after its creation.",
      "Para compartilhar com pessoas externas, insira abaixo o e-mail dos destinatários": "To share, enter recipient’s email",
      "Insira os e-mails": "Enter the emails",
      "Distribuições": "Distributions",
      "Utilização do nome alternativo": "Alternative name use",
      "Este nome alternativo não está associado a nenhuma categoria": "This alternative name is not associated with any category",
      "Este nome alternativo não está associado a nenhuma distribuição": "This alternative name is not associated with any distribution",
      "reenviar convite": "resend invitation",
      "Oops! Link indisponível": "Oops! Link unavailable",
      "Entre em contato com  o gestor de conteúdo para reenviar o convite ou faça a solicitação de acesso e aguarde a moderação.": "Contact the content manager to resend the invitation or request access and wait for moderation.",
      "approved": "Approved",
      "disapproved": "Failed",
      "blocked": "Blocked",
      "requested": "User was not moderated",
      "invited": "Guest",
      "invited-registered": "Registered guest",
      "invited-expired": "Guest expired",
      "allowed": "Yourself",
      "Anfitrião": "Host",
      "Data de criação": "Creation date",
      "Data de aprovação": "Approval date",
      "Segurança de senha": "Password security",
      " sua senha deve ter um mínimo de 8 caracteres contendo letras, números e caractere especial.": " your password must be at least 8 characters long, containing letters, numbers and a special character.",
      "Uma notificação será enviada quando os itens estiverem prontos para o download.": "A notification will be sent when the items are ready to download.",
      "Link expirado": "Expired link",
      "Geral": "ALL",
      "Limite de itens selecionados": "Limit of {{value}} selected items",
      "Todos os itens dessa página foram selecionados.": "All {{name}} items on this page have been selected",
      "ativo": "asset",
      "ativos": "assets",
      "Deseja publicar o item": "Do you want to publish the item {{name}}",
      "Deseja despublicar o item": "Do you want to unpublish the item {{name}}",
      "Publicar": "Publish",
      "Despublicar": "Unpublish",
      "Miniatura": "Thumbnail",
      "Nome do item": "Item name",
      "Ativos": "Assets",
      "Visualizar em card": "View in card",
      "Visualizar em lista": "View in list",
      "Caso queira nomear o seu arquivo ZIP com o download realizado,  escreva aqui": "If you want to name your ZIP file after the download, write it here",
      "Incompatível com as categorias  de todos os itens": "Incompatible with all item categories",
      "Escolha o nome para download.": "Choose the name for the download",
      "Escolha o tipo de download.": "Choose the type for the download",
      "Download em andamento": "Download in progress",
      "Exclusão em andamento": "Deletion in progress",
      "Você receberá uma notificação quando o download for concluído.": "You will receive a notification when the download is complete.",
      "Nome original": " Orignal name",
      "Nome alternativo": "Alternative name",
      "Nome alternativo em espanhol": "Alternative name in Spanish",
      "Nome alternativo em inglês": "Alternative name in English",
      "Utilizado em": "Used in",
      "Adicionar metadado a lista de": "Add metadata to {{name}} list",
      "adicionar metadado a lista": "Add metadata to list",
      "Este campo contem itens duplicados": "This field contains duplicate items",
      "Este campo deve conter mais que 3 caracteres": "This field must contain more than 3 characters",
      "Alerta": "Alert",
      "aqui": "here",
      "Saiba mais  ": "Learn more  ",
      "Ao criar um metadado, verifique se são dados de acordo com a LGPD.": "When creating metadata, make sure it's LGPD.",
      "Mais zoom": "Zoom in",
      "Menos zoom": "Zoom out",
      "Reiniciar": "Reset",
      "O ativo que não possuir suporte para as especificações será mantido apenas ": "The asset that does not support the specifications will be kept only in ",
      "Os ativos não possuem especificações em comum.": "Assets do not have specifications in common.",
      "Ativos originais": "Original assets",
      "Data inicial maior que a data final": "Start date greater than end date",
      "Selecione uma data ou um período.": "Select a date or period.",
      "na versão original": "the original version",
      "iten_count": "{{count}} item",
      "itens_count": "{{count}} items",
      "Nenhum dado encontrado.": "No data found.",
      "Todas": "All",
      "Nenhuma data selecionada": "No date selected",
      "Aberta": "Open",
      "Em execução": "In action",
      "Finalizada": "Finished",
      "O ativo que não possuir suporte para as especificações será mantido na versão original.": "The asset that does not support the specifications will be kept in the original version.",
      "Especificações de imagem": "Image specifications",
      "Configurações avançadas": "Advanced Settings",
      "Marcador": "Tag",
      "TAG automatizada": "Automated tag",
      "Ao escolher essa opção, somente ativos digitais do tipo imagem serão disponibilizados no download.": "When choosing this option, only digital assets of the image type will be available in the download.",
      "Segurança": "Security",
      "o item": "file {{item_name}}",
      "item relacionado": "{{qtd}} related item",
      "itens relacionados": "{{qtd}} related items",
      "Itens 3D": "3D items",
      "Buscar texturas": "Search texture",
      "Selecione a textura para o objeto 3D": "Select texture for 3D object",
      "data_hora": "date_time",
      "Download em massa": "Mass download",
      "download-error-generated": "Error performing <2>mass download.</2>",
      "Ferramenta de solicitação": "Request tool",
      "Ferramentas de solicitação": "Request tool",
      "selecionados": "selected",
      "Evento não identificado": "Unidentified event",
      "Limite de 10 tags por assets foi excedido.": "Limit of 10 tags per asset has been exceeded.",
      "Limite de 40 caracteres por tag foi excedido.": "Limit of 40 characters per tag exceeded.",
      "Não": "No",
      "O que deseja fazer?": "What do you want to do?",
      "Publicação do item ou data de vigência alteradas": "Item publication or changed effective date",
      "Confirmação de Email": "Email confirmation",
      "Selecione pelo nome": "Select by name",
      "Erro ao carregar dados da imagem.": "Error loading image data.",
      "Solicitações": "Requests",
      "Sua sessão foi expirada. Favor logar novamente para poder prosseguir com suas atividades.": "Your session has expired. Please log in again to continue your activities.",
      "Sessão Expirada.": "Session expired.",
      "Entendi": "Ok",
      "Criação de região": "Region creation",
      "Edição de região": "Region editing",
      "Erro ao deletar, contate o administrador.": "Error deleting, contact administrator.",
      "Erro ao deletar, tente novamente.": "Error deleting, try again.",
      "Gerenciador de usuários": "User manager",
      "Bloqueado": "Blocked",
      "Pendente": "Pending",
      "Criação de segmento": "Segment creation",
      "Edição de segmento": "Segment editing",
      "Alterando Segmento": "Changing Segment",
      "A alteração feita será aplicada na Ferramenta. Deseja continuar?": "The change made will be applied in the {{name}} Tool. Do you wish to continue?",
      "Validação de campo inexistente": "No field validation",
      "Fuso Horário": "Time zone",
      "Sim": "Yes",
      "dia": "day",
      "dias": "days",
      "Confirme a nova senha": "Confirm the new password",
      "Alteração de senha": "Change Password",
      "Expiração do login": "Login Expiration",
      "Edição de dados da conta": "Editing account data",
      "Ir para o DAM": "go to DAM",
      "Nome da categoria": "Category name",
      "aprovar": "approve",
      "reprovar": "disapprove",
      "Revogar e excluir acesso": "Revoke and delete access",
      "desbloquear": "unblock",
      "Tem certeza que deseja desbloquear?": "Are you sure you want to unblock {{name}}?",
      "Tem certeza que deseja aprovar?": "Are you sure you want to approve {{name}}?",
      "Tem certeza que deseja reprovar?": "Are you sure you want to disapprove {{name}}?",
      "Tem certeza que deseja revogar e excluir o acesso de?": "Are you sure you want to revoke and delete access from {{name}}?",
      "Informações adicionais": "Additional information",
      "Região de acesso": "Access Region",
      "Tipo de perfil": "Profile type",
      "Dados complementares": "Complementary data",
      "Dados pessoais": "Personal data",
      "Nome completo": "Full name",
      "Moderador": "Moderator",
      "Declaro ter lido a": "I declare to have read",
      "Personalização da plataforma": "Platform customization",
      "Nome da plataforma": "Platform name",
      "Nome da empresa": "Company’s name",
      "Links externos": "External link",
      "Digite a legenda.": "Type the subtitle.",
      "Digite o link.": "Enter the link.",
      "Digite o dns.": "Enter the DNS.",
      "Digite a descrição.": "Enter description.",
      "Digite o nome da plataforma.": "Enter the platform name.",
      "Adicionar nova imagem": "Add new image",
      "Adicionar Link": "Add link",
      "Adicionar dns": "Add DNS",
      "Baixar": "Download",
      "Imagens da plataforma": "Platform Images",
      "Especificações": "Specifications",
      "tamanho máximo": "max size",
      "Imagem aplicada ao fundo da área de login.": "Image applied to the background of the login area.",
      "Logo da página inicial": "Homepage Logo",
      "Imagem aplicada na página de login sob o background.": "Image applied on the login page under the background.",
      "Logo para menu superior": "Top Menu logo",
      "Imagem aplicada na barra superior da página de login.": "Image applied to the top bar of the login page",
      "Imagem aplicada no ícone do navegador.": "Image applied to the browser icon.",
      "Logo interno": "Internal logo",
      "Imagem aplicada na barra superior dentro da plataforma.": "Image applied to the top bar inside the platform.",
      "Logo do e-mail": "Email logo",
      "Imagem aplicada nos e-mails enviados pela plataforma.": "Image applied in the e-mails sent by the platform.",
      "Print da tela para compartilhamento de arquivos": "Screenshot for file sharing",
      "Imagem que será anexada em redes sociais e plataformas externas.": "Image that will be attached on social networks and external platforms.",
      "Está gostando da plataforma?": "Are you enjoying the {{name}} platform?",
      "Gostaríamos de saber como tem sido a sua experiência com para aperfeiçoarmos cada vez mais.": "We'd like to know how your experience with {{name}} has been so that we can improve it even more.",
      "Ao clicar em avaliar, você será direcionado para uma plataforma externa. Tempo médio de avaliação": "By clicking rate, you will be directed to an external platform. Average evaluation time",
      "Avaliar": "Rate",
      "nps-access-request": "With your consent, Yapoli uses your personal data, such as <1>full name, phone number</1> and <1>email</1>, for the purpose of the {{dataClientName}} platform usage. In addition, the mentioned data can be changed/deleted at any time through the email dpo@yapoli.com.",
      "Política de privacidade da Yapoli": "Yapoli's Privacy Policy",
      "Política de privacidade da": "{{name}}'s Privacy Policy",
      "Política de privacidade": "Privacy Policy",
      "Avalie a": "Rate {{name}}",
      "Data inicial": "Starting date",
      "até": "to",
      "Data final deve ser maior": "End date must be higher",
      "Tem certeza que deseja realizar essa alteração?": "Are you sure you want to make this change?",
      "Buscar ferramentas de solicitação": "Search request tools",
      "Relacionar Itens": "Relate Items",
      "Nos conte sobre a sua experiência com": "Tell us about your experience with",
      "descriptionNotifications-1": "{{NAME_USER}} <2>{{action}}</2> the item {{NAME_ITEM}}",
      "descriptionNotifications-2": "The <1>{{text}}</1> of the item {{NAME_ITEM}} is about to expire.",
      "bulkDownloadDescriptionNotification": "{{NAME_ITEM}} is complete.",
      "bulkDownloadErrorNotification": "Requested <1>bulk download</1> could not be completed. Reason: {{reason}}. Please try again or contact support.",
      "data de vigência": "efective date",
      "Data de vigência": "Efective date",
      "Não lida": "Unread",
      "mostrar mais": "Show more",
      "Confira as atualizações dos itens.": "Check for item updates.",
      "Item não encontrado": "Item not found",
      " criou ": " created ",
      " publicou ": " published ",
      " despublicou ": " unpublished ",
      " adicionou uma nova versão ": " Added a new version of ",
      "Notificações": "Notifications",
      "Todos os usuários com permissão de visualizar itens do conteúdo acima, receberão notificações sobre as atualizações.": "All users with permission to view items from the above content will receive notifications about updates.",
      "Publicado": "Published",
      "Despublicado": "Unpublished",
      "O item pode ser visualizado e utilizado pelos usuários que possuem permissão no perfil.": "The item can be viewed and used by users who have permission on the profile.",
      "O item ficará oculto dos usuários que não possuírem perfil para gerenciar o conteúdo.": "The item will be hidden from users who do not have a profile to manage the content.",
      "O item que possuir um conteúdo permitido para ser veiculado e compartilhado por um período específico.": "The item that has content allowed to be served and shared for a specific period.",
      "Em relação a publicação, o item deverá ser criado como": "Regarding the publication, the item must be created as",
      "Data final da publicação": "Final publication date",
      "Insira uma data": "Please enter a date",
      "Período": "Period",
      "indeterminado": "Undefined",
      "Com vigência": "Effective",
      "qtd_arquivo": "{{qtd}} file",
      "qtd_arquivos": "{{qtd}} files",
      "Gerando o arquivo para download…": "Generating the file {{name}} to download…",
      "Download do item": "Download item",
      "Imagens web em alta": "Web images in high resolution",
      "Imagens web em baixa": "Web images in low resolution",
      "Opções de download": "Download options",
      "O item está fora do prazo de vigência estabelecido para o material. Por isso, não poderá ser divulgado ou compartilhado com terceiros. Deseja continuar?": "The item is out of the effective period established for the material. Therefore, it cannot be disclosed or shared with third parties. Do you wish to continue?",
      "Item com data de vigência": "Item with effective date",
      "desativado": "deactivated",
      "A vigência no item foi desativada. Para ativá-la torne o item despublicado.": "The term in the item has been deactivated. To activate it, make the item unpublished.",
      "A vigência no item foi ativada.": "The term in the item has been activated.",
      "Expiração": "Expiration",
      "Permissão": "Permission",
      "Sem expiração": "No expiration",
      "Escolha apenas um dos tipos de expiração de perfil abaixo.": "Choose only one of the profile expiration types below",
      "Perfil temporário": "Temporary profile",
      "Essa funcionalidade aplica o conceito de perfil temporário. Assim que aplicada, o prazo de expiração (bloqueio do usuário) passa a ser contado em dias a partir da data de aprovação da solicitação de acesso deste usuário.": "This feature applies the temporary profile concept. As soon as applied, the expiration period (user blocked) starts to be counted in days from the date of approval of this user's access request.",
      "A inatividade em Perfil foi desativada.": "The profile inactivity has been disable.",
      "O Perfil temporário foi desativado.": "The profile inactivity has been disabled.",
      "Inatividade em perfil": "Profile inactivity",
      "Essa funcionalidade aplica um prazo, em dias, de inatividade no perfil, onde o usuários que está atrelado a ele será bloqueado no tempo definido, contando a partir do seu último acesso a plataforma.": "This feature applies a period, in days, of inactivity to the profile where the user who is tied to it will be blocked in the defined period, counting from his last access to the platform.",
      "Região de entrada": "Input region",
      "Essa região será utilizada no primeiro login realizado pelo usuário.": "This region will be used in the first login performed by the user.",
      "Selecione o metadado": "Select metadata",
      "Tipo de moderação": "Moderation type",
      "Selecione o tipo de moderação": "Select the type of moderation",
      "Domínio do e-mail": "Email domain",
      "Escopo de acesso na região": "Access scope in the region",
      "Escopo de acesso do gerenciador": "Manager access scope",
      "Procure uma permissão (Mín. 03 caracteres)": "Search for a permission (Min. 03 characters)",
      "O item publicado pode ser visualizado e utilizado por todos os usuários que possuam permissão de visualização atribuida no perfil, enquanto o item que não for publicado ficará oculto de todos os usuários, exceto aqueles que tenham permissão de gerenciamento atribuída.": "The published item can be viewed and used by the users who have been granted visualization permission on the profile, while the item that is not published will be hidden from all users except those which haven management permission granted.",
      "Aplicar vigência de uso no item": "Apply validiy of use in the item",
      "Aplica-se a vigência de uso no item caso este material possua uma data final em que o conteúdo não possa mais ser veiculado e compartilhado.": "The validity of use of the item applies if the material has an end date when the content can no longer be transmitted and shared.",
      "Data final": "End date",
      "Relacionar item": "Relate item",
      "Buscar itens": "Search items",
      "Pode selecionar no maximo itens": "You can select a maximum of {{count}} items",
      "Edição do item": "Editing the item",
      "Publicação do item": "Item publication",
      "Escolha qual a melhor opção de publicação do item.": "Choose the best option for publishing the item.",
      "Vigência de uso do item até": "Item usage term up to",
      "Vigência de uso do item": "Validity of use of item",
      "para exclusão": "{{name}} for exclusion",
      "Você deseja realmente excluir": "Do you really want to delete {{name}}?",
      "Excluir-entity": "Delete {{name}}",
      'Solicitante': 'Requester',
      'Executor (es)': 'Executor (es)',
      'Data de abertura': 'Opening Date',
      'Falha ao carregar dados.': 'Fail to load data.',
      'Mensagem do solicitante': "Requestor's Message",
      'Ordem de Serviço': 'Order of Service',
      'Máx. 50 caracteres': 'Max. 50 characters',
      'Título da ferramenta. Máx. 100 caracteres': 'Tools Title. Max. 100 characters',
      'Texto de apoio. Máx. 400 caracteres': 'Support Text. Max. 400 characters',
      'Nome do botão. Máx. 50 caracteres': 'Button name. Max. 50 characters',
      'Máx. 100 caracteres': 'Max. 100 characters',
      'Título da ferramenta': 'Tools Title',
      'Texto de Apoio': 'Support Text',
      'Explique para qual objetivo essa ferramenta está sendo criada.': 'Explain for what purpose this tools is being created.',
      'Executor': 'Executor',
      'Defina abaixo os usuários homologados na plataforma que poderão receber as solicitações da ferramenta com objetivo de executá-las.': 'Define below the users approved on the platform who will be able to receive requests for the tool in order to execute them.',
      'Busque pelo nome do executor': 'Search by executor name',
      'Busque pelo nome': 'Search by name',
      'Permitir que o usuário escreva uma mensagem?': 'Allow the user to write a message?',
      ' Adicionar botão': 'Add Button',
      ' Texto do botão para ação do item, como por exemplo, Salvar, Enviar, Compartilhar.': 'Button text for item action, such as Save, Send, Share.',
      'Nome do botão': 'Button name',
      'Reprocessar arquivo': 'Reprocess File',
      'Tentar novamente': 'Try again',
      'Data em que o usuário será bloqueado. Quando amarelo, faltam 3 dias para o bloqueio. Quando vermelho, já está bloqueado.': 'When is yellow, there are 3 days until the block. When red, it`s already blocked.',
      'Você tem certeza que deseja deletar esse arquivo do repositório?': 'Are you sure you want to delete this file from the repository?',
      'arquivo não processado': 'Unprocessed file',
      'de': 'of',
      "Todos os": "All the",
      "itens": "items",
      "Marcadores": "Tags",
      "Tornar item publicado": "Publish item",
      "Pressione enter/tab": "Press enter/tab",
      "Todos": "All",
      "Publicados": "Published",
      "Despublicados": "Unpublished",
      "itens publicados": "published items",
      "itens despublicados": "unpublished items",
      "ativo encontrado para a ": "{{qtd}} asset found for the ",
      "ativos encontrados para a ": "{{qtd}} assets found for the ",
      "Atalhos": "Shortcuts",
      "Arquivo enviado com sucesso.": "File uploaded successfully.",
      "Erro inesperado ao enviar o PDF. Contate o suporte":
        "Unexpected error while sending the PDF. Contact support.",
      "Arquivo inválido. Arquivo de até 6mb e em formato PDF":
        "Invalid file. Up to 6mb file and in PDF format",
      "Selecione o pdf para upload": "Select the pdf to upload",
      "Clique aqui ou arraste o PDF para enviar":
        "Click here or drag the PDF to send",
      "Erro ao carregar o PDF.": "Error loading PDF.",
      "Última atualização": "Last update",
      "Nenhum PDF encontrado.": "No PDF found.",
      " atualizado em": " updated on",
      "requerido por atualizado em": "required by {{client}} updated on ",
      "estou de acordo": "I agree",
      "não estou de acordo": "I disagree",
      "Declaro ter lido e estar de acordo com os":
        "I declare that I have read and agree with the",
      "Termos de uso e Política de privacidade":
        "Terms of use and Privacy Policy",
      "Lamentamos que você não esteja de acordo com os Termos de uso e Política de privacidade requerido por. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.":
        "We regret that you do not agree with the Terms of use and Privacy Policy required by {{client}}. Unfortunately, we cannot release your access. Please, contact your manager.",
      "Atualização de Termos de uso e Política de privacidade":
        "Update of Terms of use and Privacy Policy",
      "Primeira versão": "First version",
      "Segunda versão": "Second version",
      "Terceira versão": "Third version",
      "Quarta versão": "Fourth version",
      "Quinta versão": "Fifth version",
      "Sexta versão": "Sixth version",
      "Sétima versão": "Seventh version",
      "Oitava versão": "Eighth version",
      "Nona versão": "Ninth version",
      "Décima versão": "Tenth version",
      "versões encontradas": "{{qtd}} found versions",
      "versão encontrada": "{{qtd}} version Found",
      "itens encontrados": "{{qtd}}  found items",
      "item encontrado": "{{qtd}} item found",
      "Itens relacionados": "Related Items",
      "Relacionar itens": "List Items",
      "Checando arquivo para criação de item":
        "Checking file for item creation",
      "checando arquivo": "checking file",
      "Item criado": "Item created {{name}}",
      "item criado": "item created {{name}}",
      "Arquivo associado ao item": "File associated with the item {{name}}",
      "arquivo associado ao item": "file associated with the item {{name}}",
      "Você pode selecionar no máximo 150 assets":
        "You can select a maximum of 150 assets.",
      "Você pode selecionar no máximo 150 ativos":
        "You can select a maximum of 150 assets.",
      "Arquivo": "File",
      "Arquivos": "Files",
      "arquivo": "file",
      "arquivos": "files",
      "Excluir arquivo selecionado": "Delete {{total}} selected file",
      "Excluir arquivos selecionados": "Delete {{total}} selected files",
      "Excluir item selecionado": "Delete {{total}} selected item",
      "Excluir itens selecionados": "Delete {{total}} selected items",
      "Você deseja realmente excluir o arquivo selecionado?":
        "Do you really want to delete the selected file?",
      "Você deseja realmente excluir todos os arquivos selecionados?":
        "Do you really want to delete all {{total}} selected files?",
      "Excluir arquivo": "Delete {{total}} file",
      "Excluir arquivos": "Delete {{total}} files",
      "Você deseja realmente excluir o item selecionado?":
        "Do you really want to delete the selected item?",
      "Você deseja realmente excluir todos os itens selecionados?":
        "Do you really want to delete all {{total}} selected items?",
      "Excluir item": "Delete {{total}} item",
      "Excluir itens": "Delete {{total}} items",
      "Limite de 150 assets foi excedido.":
        "150 asset limit has been exceeded.",
      "Subir nova versão": "New version",
      "Baixar item": "Download",
      "Selecione e arraste os thumbnails para definir a ordem de apresentação no item. O primeiro thumbnail será a imagem de capa do item nas listas.":
        "Select and drag the thumbnails to define the order of presentation in the item. The first thumbnail will be the cover image of the item in the lists.",
      "Baixar arquivo": "Download file",
      "Arquivo original": "Original file",
      "Imagens da web em alta": "High web images",
      "Imagens da web em baixa": "Low web images",
      "Imagens da web em alta qualidade": "High quality web images",
      "Imagens da web em baixa qualidade": "Low quality web images",
      "Imagens da web em alta qualidade_required": "High quality web images *",
      "Imagens da web em baixa qualidade_required": "Low quality web images *",
      "Arquivos originais": "Original files",
      "Criar nova versão para o item":
        "Create new version for the item {{item}}",
      "Adiciona arquivo para o item": "Add file to item {{item}}",
      "Descrição do item": "Item Description",
      "Salvar": "Save",
      "Voltar": "Back",
      "Voltar etapa": "Step back",
      "Etapa 01 de 02.": "Step 01 of 02.",
      "Etapa 02 de 02.": "Step 02 of 02.",
      "Etapa 01 de 03.": "Step 01 of 03.",
      "Etapa 02 de 03.": "Step 02 of 03.",
      "Etapa 03 de 03.": "Step 03 of 03.",
      "Registros por página": "Records per page",
      "Nome do Perfil": "Profile Name",
      "Tipo de campo": "Field Type",
      "Código externo": "External code",
      "Situação": "Status",
      "Selecione": "Select",
      "Nome do segmento": "Segment name",
      "Código externo segmento": "Segment external code",
      "Digite o código": "Insert the code",
      "Ordenar itens de A-Z": "Sort A to Z",
      "Ordenar itens de Z-A": "Sort Z to A",
      "Ordenar itens pelos mais recentes": "Sort by most recent",
      "Ordenar itens pelos mais antigos": "Sort by oldest",
      "Nova versão": "New version",
      "Compartilhar": "Share",
      "Excluir": "Delete",
      "Adicionar arquivos": "Add files",
      "Edição de arquivo": "File edition",
      "Limpar itens selecionados": "Clear {{total}} selected items",
      "Insira abaixo os e-mails dos destinatários para compartilhar.": "Enter recipient emails below to share.",
      "Mostrar arquivo selecionado": "Show {{asset}} selected file.",
      "Mostrar arquivos selecionados": "Show {{assets}} selected files.",
      "Cancelar": "Cancel",
      "Arquivos para exclusão": "Files to delete",
      "processando arquivo": "processing files",
      "Alterar Região": "Update Region",
      "Aplicações": "Applications",
      "Configurações": "Settings",
      "Buscar no DAM": "Search on DAM",
      "Buscar no Bucket": "Search on Bucket",
      "Solicitar acesso": "Request access",
      "Redefinição de senha": "Password Reset",
      "Recuperação de senha": "Password recovery",
      "Busca": "Search",
      "Selecionar": "Select",
      "Nenhum item encontrado": "No items found",
      "Incluir": "Include",
      "Incluir Região": "Include Region",
      "Ordenação de itens": "Order items",
      "Visualização de itens": "View items",
      "Somente os": "Only the",
      "itens visíveis": "Visible items",
      "itens não visíveis": "Not visible items",
      "Itens não visíveis não podem ser removidos dos relacionamentos":
        "Not visible items cannot be unlinked",
      "Seleção de itens": "Items selection",
      "Não selecionar nenhum item": "Do not select items",
      "Visíveis": "visible",
      "Não Visíveis": "Not visible",
      "Não Visível": "Not visible",
      "Não há itens a serem mostrados neste filtro.":
        "There are no items to be shown in this filter.",
      "Ordenação A-Z": "A-Z Sort",
      "Ordenação Z-A": "Z-A Sort",
      "Mais recente": "Most recent",
      "Mais antigo": "Most previous",
      "Você pode selecionar no máximo 15 assets":
        "You can select a maximum of 15 assets.",
      "Limite de 15 assets foi excedido.":
        "Limit of 15 assets has been exceeded.",
      "Selecione e arraste para definir a ordem que os assets serão apresentados no item. O asset definido como primeiro, será o que aparecerá na listagem de itens (hero).":
        "Select and drag to set the order that the assets will be displayed in the item. The asset defined as first, will be the one that appears in the item list (hero).",
      "Adicionar nova versão": "Add new version",
      "Limite de 255 foi excedido": "Limit of 255 assets has been exceeded.",
      "Este campo é obrigatorio": "Required field.",
      "Obrigatório no mínimo 3 caracteres": "Required at least 3 characters.",
      "Criar nova versão": "Create new version",
      "Nenhum assets foi selecionado, volte e selecione no mínimo um asset":
        "No assets were selected, please go back and select at least one.",
      "Comentário sobre a nova versão": "Comment about new version",
      "Descrição da versão": "Version description",
      "Solicitação de acesso": "Access Request",
      "O formulário tem erros": "Form has errors. Please correct to proceed.",
      "Campo obrigatório": "Required field",
      "Cliente": "Customer",
      "Região": "Region",
      "Regiões": "Regions",
      "Divisão": "Division",
      "Divisões": "Divisions",
      "Segmento": "Segment",
      "Segmentos": "Segments",
      "Plataforma": "Platform",
      "Metadado": "Metadata",
      "Metadados": "Metadata",
      "Categoria": "Category",
      "Categorias": "Categories",
      "Atributo": "Attribute",
      "Atributos": "Attributes",
      "Gerenciador": "Manager",
      "Ferramenta de distribuição": "Distribution tool",
      "Itens": "Items",
      "Item": "Item",
      "Perfil": "Profile",
      "perfil": "profile",
      "Perfis": "Profiles",
      "Usuário": "User",
      "Usuários": "Users",
      "Moderação": "Moderation",
      "Carregando": "Loading",
      "Dados retornados com sucesso": "Data returned successfully.",
      "Nenhum resultado encontrado": "No result found",
      "Buscar": "Search",
      "ADICIONAR": "ADD",
      "Confirmar": "Confirm",
      "Criar": "Create",
      "Confirmação": "Confirmation",
      "Ver detalhes": "See details",
      "Detalhes": "Details",
      "Nome": "Name",
      "Descrição": "Description",
      "Mensagem": "Message",
      "E-mail": "Email",
      "Moderado por": "Moderated by",
      "Telefone": "Telephone",
      "Download": "Download",
      "Tipo Aprovação": "Approval type",
      "Domínio Email": "Email domain",
      "Upload": "Upload",
      "Enviar": "Send",
      "Editar": "Edit",
      "Adicionar": "Add",
      "Visualizar": "View",
      "Enviar por e-mail": "Send by email",
      "Gerenciar versões": "Manage versions",
      "Versão mais recente": "Latest version",
      "Versão": "Version",
      "versão": "version",
      "Gerenciar": "Manage",
      "Thumbnail": "Thumbnail",
      "Procurar": "Search",
      "Download CSV": "Download CSV",
      "Parâmetros Inválidos": "Invalid Parameters",
      "Ver Colunas": "View Columns",
      "Registros por Página": "Records per page",
      "Link": "Link",
      "Links": "Links",
      "NUEVO LINK": "NEW LINK",
      "DNS": "DNS",
      "NUEVO DNS": "NEW DNS",
      "No.": "No.",
      "Legenda": "Subtitle",
      "SIM": "YES",
      "NÃO": "NO",
      "Erro ao carregar os dados, contate o suporte":
        "Error loading data. Please contact support.",
      "Este campo é obrigatório": "Required field",
      "Metadado obrigatório": "Required metadata",
      "Avançar": "Next",
      "Conta": "Account",
      "Alterar Senha": "Change Password",
      "Sair": "Exit",
      "E-mail inválido": "Invalid email",
      "Acesso negado": "Access denied.",
      "Favor, contatar o administrador e solicitar permissão caso seja necessário acessar este conteudo":
        "Please contact the administrator and request permission to access this content.",
      "Acesso do usuário foi aprovado com sucesso":
        "User access has been approved successfully",
      "Erro ao processar. Contacte o administrador":
        "An error occurred while processing your request. Please contact the administrator.",
      "Registro alterado com sucesso": "Record changed successfully.",
      "Nome inválido.": "Invalid name.",
      "Descrição inválida.": "Invalid description",
      "Máscara inválida.": "Invalid mask",
      "Favor selecionar uma região": "Please select a region",
      "Favor selecionar uma divisão": "Please select a division",
      "Excluir registro": "Delete record",
      "Tipo do campo": "Field type",
      "Máscara padrão": "Default mask",
      "Exemplo": "Example",
      "Valores": "Values",
      "Tipo de valores": "Values type",
      "Tipo": "Type",
      "É necessário preencher este campo": "Required field",
      "Aprovado": "Approved",
      "Reprovado": "Failed",
      "Permissões": "Permission",
      "Ações": "Actions",
      "Novo": "New",
      "Fechar": "Close",
      "Ordenar": "Sort",
      "Obrigatório": "Required",
      "Nova Versão": "New version",
      "Nova versão do item": "New version item",
      "Erro ao processar, entre novamente no item":
        "An error occurred, enter the item again.",
      "ª": "º",
      "Não encontramos o que você procura. Que tal uma nova busca?":
        "We haven't found what you're looking for. How about a new search?",
      "Parece que você chegou ao fim": " It looks like you've come to an end.",
      "O arquivo ultrapassa o limite de GB permitido.":
        "The file {{fileName}} exceeds the{{limit}}GB limit allowed.",
      "Arquivo para upload vazio.": "The file {{fileName}} is empty.",
      "Arquivo para upload acima do limite 30":
        "File {{fileName}} too large. Exceeds the permissible limit of 30GB",
      "Tipo de arquivo não permitido":
        "file {{fileName}} is of type {{fileType}} that is not allowed",
      "Erro desconhecido ao subir arquivo":
        "Unknown error while uploading file {{fileName}}. Reported:",
      "Usuário não foi moderado": "User was not moderated",
      "Moderar": "Moderate",
      "Taxonomia do item": "Item taxonomy",
      "Versões anteriores do item": "Previous versions of item",
      "caso o nome, descrição e segmento tenham sido editados, serão replicados para as versões anteriores.":
        "If the name, description and segment have been edited, they will be replicated to previous versions.",
      "caso o nome ou segmento tenham sido editados;":
        "If the name or segment has been edited;",
      "A edição feita terá os seguintes impactos":
        "The edition made will have the following impacts",
      "Taxonomia do item: caso o nome ou segmento tenham sido editados; Versões anteriores do item: caso o nome, descrição e segmento tenham sido editados, serão replicados para as versões anteriores.":
        "Item taxonomy: if the name or segment has been edited; Previous versions of the item: if the name, description and segment have been edited, they will be replicated to previous versions.",
      "Fazendo upload de arquivos":
        "Uploading {{total}} files",
      "Fazendo upload de arquivo":
        "Uploading {{total}} file",
      "Upload finalizado": "Upload concluded",
      "troca-regiao-upload": "Your upload is in progress and when changing Region they will be cancelled. Are you sure you want to change Region?",
      "logout-upload": "Your upload is in progress and upon leaving they will be cancelled. Are you sure you want out?",
      "Cancelar uploads em andamento": "Cancel uploads in progress",
      "Solicitação enviada com sucesso": "Request submitted successfully.",
      "Confira a caixa de entrada e spam de ": " Check your inbox and spam.",
      "Código externo divisão": "Division external code",
      "Código externo categoria": "Category external code",
      "Somente imagens web em alta": "Rising web images only",
      "Somente imagens web em baixa": "Only low web images",
      "Erro ao tentar reprocessar, contate o suporte":
        "Error on trying to reprocess. Please contact the support.",
      "ACEITAR E FECHAR": "ACCEPT AND CLOSE",
      "Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência. Ao usar nosso serviço, você concorda com nossos termos e condições de uso.":
        "We use cookies and other similar technologies to improve your experience. By using our services you agree with our terms and conditions.",
      "Eu li e concordo com os": "I've read and aggree to the",
      "requerido por": "required by",
      "Não há notificações": "There are no notifications",
      "Motivo desconhecido": "Unknown reason",
      "Requisição inválida": "Invalid request"
    },
    login: {
      "Critérios de senha": "Criteria for password",
      "cliqueaquiparasabermais": "Click <1>here</1> to learn more.",
      "Critérios para definição de senha": "Criteria for password definition",
      "A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.": "The password must have at least 8 characters containing: an uppercase letter, a lowercase letter, a number and a special character.",
      "Não podem ser utilizadas as últimas 05 senhas.": "The last 5 passwords cannot be used.",
      "Sua senha deve ser diferente das 05 últimas senhas já cadastradas, com no mínimo 08 caracteres, contendo letra, número e caractere especial.": "Your password must be different from the last 05 passwords already registered, with at least 08 characters, containing letter, number and special character.",
      "Digite a sua nova senha": "Enter your new password",
      "Senha atualizada com sucesso.": "Password updated successfully.",
      "Lamentamos que você não esteja de acordo com a Política de privacidade requerido por. Infelizmente, não podemos liberar seu acesso. Por gentileza, entre em contato com seu gestor.": "We regret that you do not agree with the Privacy Policy required by {{client}}. Unfortunately, we are unable to give you access. Please contact your manager.",
      "Ir para o login": "go to login",
      "Atualização da Política de privacidade": "Update of Privacy Policy",
      "Compartilhamento de arquivos": "Share files",
      "Solicitação de redefinição de senha não encontrada.":
        "Password reset request not found.",
      "Senha muito curta": "Password is too short.",
      "Senhas não conferem.": "Passwords do not match.",
      "ID do usúario não foi encontrado.": "User ID not found.",
      "Entendi": "Understand",
      "O e-mail e a senha fornecidos não correspondem às informações em nossos registros ou não estão aprovados. Verifique-as ou entre em contato com o administrador.":
        "Email and password provided do not match the information in our records or are not approved. Check them or contact the administrator.",
      "Redefinição de senha": "Password reset",
      "Redefinir a senha": "Reset Password",
      "Repita a nova senha": "Repeat new password",
      "Insira abaixo uma nova senha": "Insert a new password below.",
      "Insira abaixo o endereço de e-mail associado à sua conta e, em seguida, enviaremos um e-mail com o link de redefinição de senha":
        "Enter below the email address associated with your account and we will send you an link to reset password.",
      "da": "from",
      "solicitação de acesso":
        "request access",
      "Ainda não possui acesso ao ": "Still don't have access to ",
      "Esqueceu a Senha?": "Forgot Password?",
      "Acessar": "Access",
      "Logout realizado com sucesso.": "Logout Successful.",
      "Região inválida.": "Invalid Region.",
      "Rota inválida.": "Invalid route.",
      "E-mail inválido": "Invalid email",
      "Digite sua senha": "Enter your password",
      "Esqueci a senha": "Forgot Password",
      "Esqueceu a senha?": "Forgot Password?",
      "Insira o endereço de email associado à sua conta que lhe enviaremos um link de redefinição de senha":
        "Please insert the email address associated with your account and we will send a link to reset your password.",
      "Logout realizado com sucesso": "Logout successful.",
      "Voltar para o login": "Back to login",
      "Senha atual": "Current password",
      "Nova senha": "New password",
      "Confirmar Senha": "Confirm Password",
      "É necessário informar a senha atual": "Must enter current password.",
      "A nova senha deve ter no minimo 6 caracteres":
        "New password must have at least 6 characters.",
      "A senha e a confirmação não correspondem":
        "Password and confirmation do not match.",
      "Telefone inválido": "Invalid phone",
      "Editar dados da conta": "Edit Account Data",
      "Parâmetros para autenticação inválidos. Contate o suporte"
        : "Invalid authentication parameters. Contact the support"
    },
    recoverPassword: {
      "Sua senha já foi alterada": "Password changed successfully",
    },
    solicitacaoAcesso: {
      "Requisito de Senha": "Password Requirement",
      " A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.": " The password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, number and special character.",
      " A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial. E não pode ter sido utilizada nas últimas 5 senhas.": "The password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, number and special character. And it cannot have been used in the last 5 passwords.",
      "Ajude o novo usuário da plataforma a saber qual opção ele deve escolher em cada passo da solicitação de acesso.": "Help the new platform user to know which option he should choose at each step of the access request",
      "Personalizar solicitações": "Customize requests",
      "Finalizar cadastro": "Finalize registration",
      "Enviar cadastro": "Send registration",
      "Faça seu login": "Login",
      "Cadastro efetuado com sucesso!": "Registration successfully Complete!",
      "Solicitação enviada com sucesso!": "Request sent successfully!",
      "Não há perfil para selecionar": "There is no profile to select",
      "Solicitaçāo enviada com sucesso!": "Request sent successfully!",
      "Você receberá um e-mail de confirmação após a avaliaçāo do moderador.": "You will receive a confirmation email after the moderator’s review.",
      "Escolha sua região": "Choose your region",
      "Cada região possui diferentes tipos de conteúdo":
        "Each region has different content types.",
      "Escolha o seu perfil": "Choose your profile",
      "Cada perfil possui diferentes tipos de permissões":
        "Each profile has different types of permissions.",
      "Dados complementares": "Complementary data",
      "Preencha seus dados complementares": "Fill in your complementary data.",
      "Dados pessoais": "Personal information",
      "Preencha seus dados pessoais": "Fill in your personal information.",
      "Já possui cadastro? faça o":
        "Already registered? Sign",
      "Passo anterior": "Previous step",
      "Próximo passo": "Next step",
      "próximo": "next",
      "Nome Completo": "Full Name",
      "Senha": "Password",
      "Informe seu e-mail": "Enter your email",
      "Informe seu nome completo": "Enter your full name",
      "Informe seu número de telefone": "Enter your phone number",
      "Informe uma senha": "Enter your password",
      "Informe o email do moderador": "Enter moderator email",
      "E-mail já cadastrado": "Email already registered",
      "Solicitação realizada com sucesso! Aguarde moderação":
        "Request submitted successfully. Waiting moderation",
      "Enviar solicitação": "Send request",
      "Moderação de acesso": "Access moderation",
    },
    DAM: {
      "Os itens em que não há permissão para gerenciar não serão excluídos.": "Items that are not allowed to manage will not be deleted",
      "Item despublicado": "Item unpublished",
      "Pessoas": "Persons",
      "Deseja continuar?": "Do you wish to continue?",
      "compartilhar tudo": "Share everything",
      "Tente novamente ou entre em contato com o suporte.": "Try again or contact support.",
      "Não foi possível concluir a solicitação do download.": "Could not complete download request.",
      "Abrir central de downloads": "Open Download Center",
      "Ver informações do Item": "View Item info",
      "Com quem você deseja compartilhar?": "With whom do you want to share?",
      "Deixe a sua mensagem aqui": "Please leave your message here",
      "Total de Itens": "Items Total",
      "O seu Perfil não possui permissão para baixar estes Itens.": "Your Profile is not allowed to download these Items.",
      "Central de downloads": "Download center",
      "Confira os downloads que você solicitou.": "Check the downloads you requested.",
      "Ver todos": "View all",
      "item_selecionado": "{{qtd}} selected item",
      "itens_selecionados": "{{qtd}} selected items",
      "Navegação": "Navigation",
      "Por hierarquia": "By hierarchy",
      "Visualização": "Visualization",
      "Em card": "On card",
      "Em lista": "In list",
      "Enviar para validação": "Submit for validation",
      "Comente sobre os anexos que estão sendo enviados.": "Comment on attachments being sent.",
      "Tarefa invalidada": "Invalidated task",
      "A tarefa foi invalidada e voltará para a execução.": "The task has been invalidated and will resume execution. ",
      "Tarefa reprovada": "Failed task",
      "A tarefa foi reprovada e voltará para a execução.": "The task has failed and will resume execution.",
      "Comente o que deve ser alterado.": "Comment what needs to be changed.",
      "Os materiais enviados": "The materials sent",
      "Invalidar projeto": "Invalid project",
      "O projeto voltará para execução. Explique por que foi invalidado.": "The project will return to execution. Why was it invalidated?",
      "Reprovar projeto": "Failure Project",
      "O projeto voltará para execução. Explique por que foi reprovado.": "The project will return to execution. Why was it disapproved?",
      "Novo projeto": "New project",
      "Nome do projeto": "Project name",
      "Informações complementares do projeto": "Additional project information",
      "Quais informações precisam ser preenchidas durante a execução do projeto?": "What information needs to be filled in during project execution?",
      "Relacione itens já cadastrados na plataforma que possam auxiliar no projeto.": "List items already registered on the platform that can assist in the project.",
      "Informações para criação do item": "Item creation information",
      "Como o item deve ser criado após a finalização do projeto?": "How should the item be created after the project is completed?", "Galeria": "Gallery",
      "Iniciar Projeto": "Start project",
      "Clique aqui para copiar o link de compartilhamento": "Click here to copy the share link",
      "Lista de itens": "List of items",
      "Gerar link": "Generate link",
      "Clique no botão abaixo para gerar o link. Uma notificação será enviada quando o link estiver pronto para ser compartilhado.": "Click the button below to generate the link. A notification will be sent when the link is ready to be shared.",
      "Clique no botão abaixo para compartilhar via link": "Click the button below to share via link",
      "Copiar link": "Copy Link",
      "Integração não processada": "Unprocessed integration",
      "Visualizar": "View by",
      "Visualizar por": "View by",
      "Meus uploads": "My uploads",
      "Tipo de arquivo": "File type",
      "Todos": "All",
      "Imagem": "Image",
      "Vídeo": "Video",
      "Áudio": "Audio",
      "Documentos": "Documents",
      "Compactadores": "Compactors",
      "Compactados": "Compressed",
      "Tipos": "Types",
      "Tipo": "Type",
      "Apresentações": "Introductions",
      "Planilhas": "Worksheets",
      "Ordenação de arquivos": "File ordering",
      "E-mail enviado com sucesso.": "Email successfully sent.",
      "A resposta chegará em seu e-mail de acesso ao ": "The answer will arrive in your access email to ",
      "Todos os itens": "All items",
      "Escreva aqui a sua dúvida": "Write your question here",
      "Envie a sua dúvida sobre o conteúdo para o suporte de": "Submit your content query to {{name}} support",
      "Suporte": "Support",
      "Digite o e-mail": "Enter email",
      "Escreva uma mensagem para o destinatário": "Write a message to the recipient",
      "Informações do ativo": "Asset information",
      "Baixar ativo": "Download active",
      "Ao escolher a opção com o asterisco (*), somente ativos digitais do tipo imagem serão disponilibizados no download.": "When choosing the option with an asterisk (*), only digital assets of the image type will be available in the download.",
      "Selecionar ativo": "Select asset",
      "Visualizar modelo 3D": "View 3D model",
      "Ativos selecionados": "Selected assets",
      "Ativo pertence ao item": "Asset belongs to item",
      "Os ativos removidos permanecem no item de origem.": "Removed assets remain in the source item.",
      "Os ativos removidos permaneceram no item de origem, ou se adicionado, no bucket.": "The removed assets remained in the source item, or if added, in the bucket.",
      "Criação de item": "Creation of item",
      "Criar item": "Create item",
      "Detalhes do ativo": "Asset details",
      "Fechar": "Close",
      "Os ativos removidos permanecem no bucket.": "Removed assets remain in the bucket.",
      "Os ativos removidos permaneceram no local de origem, na versão anterior ou, se adicionado, no bucket.": "Removed assets remained in source location, previous version or, if added, bucket.",
      "Gerenciar ativos do item": "Manage Item Assets",
      "Não há nenhum ativo neste momento. Que tal procurar no bucket?": "There are no actives at this time. How about looking in the bucket?",
      "Versão em branco": "Blank version",
      "total_ativo": '{{total}} asset',
      "total_ativos": '{{total}} assets',
      "Ativos do item": "Item assets",
      "Gerenciar ativos": "Manage assets",
      "Editar informações do item": "Edit item information",
      "Editar informações": "Edit information",
      "Adicionar nova versão": "Add new version",
      "Adicionar ativo": "Add asset",
      "Remover ativo do item": "Remove asset from item",
      "Gostaria de deixar algum comentário na nova versão?": "Would you like to leave a comment on the new version?",
      "Sem comentário": "No comments",
      "Nova versão de item criada com sucesso.": "New version of item created with success.",
      "Os ativos removidos permanecem na versão anterior.": "The removed assets remain in the previous version.",
      "Edição salva com sucesso.": "Edition saved with success.",
      "Comentários": "Comments",
      "Ordenação de itens": "Ordering of items",
      "Itens de A-Z": "A-Z items",
      "Itens de Z-A": "Z-A items",
      "Arquivos de A-Z": "A-Z files",
      "Arquivos de Z-A": "Z-A files",
      "Mais recentes": "Most recent",
      "Mais antigos": "Older",
      "Limpar filtros X": "Clean filter X",
      "Comente aqui": "Comment here",
      "Selecione ativos para adicionar ao item": "Select assets to add to item",
      "Arquivos selecionados": "Selected files",
      "Mostrar selecionados": "Show selected",
      "Filtrar metadados": "Filter metadata",
      "Buscar metadado": "Fetch metadata",
      "Ao trocar de Região, a seleção de Itens será desfeita. Deseja continuar?": "When changing the Region, the Item selection will be undone. Do you wish to continue?",
      "Ao trocar de Região, a seleção de Ativos será desfeita. Deseja continuar?": "When changing the Region, the Asset selection will be undone. Do you wish to continue?",
      "O item não pode ser criado pois há uma falha na comunicação entre os servidores. Favor tente novamente mais tarde.": "The item cannot be created as there is a communication failure between the servers. Please try again later.",
      "O item não pode ser verificado pois há uma falha na autenticação entre os servidores. Favor tente novamente mais tarde.": "The item cannot be verified as there is an authentication failure between the servers. Please try again later.",
      "O item não foi encontrado no processo de integração e, portanto, não pode ser criado ou associado. Favor entrar em contato com sua equipe de dados.": "The item was not found in the onboarding process and therefore cannot be created or associated. Please get in touch with your data team.",
      "em": "on",
      "por": "by",
      "às": "at",
      "Formato": "Format",
      "Criado em": "Created on",
      "Executar o video": "Play the video",
      "Abrir em tela cheia": "Open in full screen",
      "Download do arquivo": "Download the file",
      "Editar item": "Edit Item",
      "Arquivos do item": "Item files",
      "Informações do item": "Item information",
      "Compartilhar item": "Share item",
      "Nenhum arquivo foi selecionado, volte e selecione no mínimo um arquivo": "No files were selected, go back and select at least one file",
      "Por favor, selecione um arquivo para salvar": "Please select a file to save",
      "Versões do item": "Item versions",
      "Você tem certeza que deseja excluir esta versão?": "Are you sure you want to delete this version?",
      "Excluir versão": "Delete version",
      "Descrição da versão mais recente": "Description of the latest version",
      "Acesso não autorizado": "Unauthorized access",
      "Não autenticado ou login expirado.": "Unauthenticated or expired login.",
      "Tente sair e entrar novamente. Se o problema persistir, favor contatar o administrador do sistema informando o problema.": "Try to sign out and sign in again. If the problem persists, please contact your system administrator with the problem.",
      "Houve erro ao obter os dados para processar o bucket.": "There was an error getting the data to process the bucket.",
      "Arquivo excluído do DAM. Reenviar para criação do item.": "File excluded from DAM. Resend for item creation.",
      "Ocorreu um erro inesperado.": "An unexpected error has occurred.",
      "Carregar mais": "Load more",
      "Hierarquia": "Hierarchy",
      "Erro ao salvar os dados.": "Error saving data.",
      "Dados salvo com sucesso.": "Data saved successfully.",
      "Limpar lista": "Clear list",
      "Limpar": "Clean",
      "Remover da lista": "Remove from list",
      "Erro ao carregar dados do item": "Error loading item data",
      "Error na automação": "Automation error",
      "Ver Item": "View item",
      "associando arquivo": "associating file",
      "criando item": "creating item",
      "extraindo dados para criar item": "extracting data to create item",
      "preparando reprocessamento da integração": "preparing integration reprocessing",
      "Erro ao tentar cancelar o upload": "Error trying to cancel upload",
      "Data final de vigência": "Effective date",
      "Este item teve erro na geração da taxonomia. Exclua-o para incluir novamente": "This item had an error in generating the taxonomy. delete it to add again",
      "Erro geral na automação via API.": "General error in automation via API.",
      "Erro ao carregar": "Error loading",
      "Você tem certeza que deseja cancelar o upload dos arquivos?": "Are you sure you want to cancel the upload of files?",
      "Uploads cancelados com sucesso.": "Uploads successfully canceled.",
      "Enviando arquivo": "Sending File",
      "Enviar erro": "Submit error",
      "arquivo associado": "file associated",
      "Processamento finalizado": "Finished Processing",
      "Processamento do thumb": "Thumb Processing",
      "Despublicar item": "Unpublish Article",
      "Publicar item": "Publish item",
      "item criado": "item created",
      "Erro ao tentar cancelar todos os uploads.": "Error trying to cancel all uploads.",
      "Ultrapassou o limite máximo de upload de itens ao mesmo tempo.": "Exceeded the maximum upload limit of {{limit}} items at the same time.",
      "Limite de itens atingido. Permitido apenas itens no anexados total.": "Item limit reached. Only {{limit}} attached items in total allowed.",
      "Não pode enviar exatamente o mesmo arquivo simultaneamente.": "It cannot upload exactly the same file simultaneously.",
      "Rascunhos": "Drafts",
      "Alterar Imagem": "Change Image",
      "Idioma": "Language",
      "Dados do usuário": "User data",
      "Escreva aqui": "Write here",
      "Itens selecionados": "Selected items",
      "itens selecionados": "selected items",
      "item selecionado": "Selected item",
      "Você pode excluir no máximo 30 itens.":
        "You can delete a maximum of 30 items.",
      "Lista de itens selecionados": "Selected items list",
      "Itens para exclusão": "Items to delete",
      " itens selecionados?": " Selected items?",
      "(essa ação é irreversível)": "(this action is irreversible)",
      "Você deseja realmente limpar da lista todos os ":
        "Do you really want to clear all selected",
      " itens selecionados?(essa ação é irreversível)":
        "  items from the list? (this action is irreversible)",
      "Você deseja realmente excluir todos os ":
        "Do you really want to delete all",
      "Você deseja realmente excluir o único item selecionado?(essa ação é irreversível)":
        "Do you really want to delete the only item selected?(this action is irreversible)",
      "Você deseja realmente limpar da lista o único item selecionado?":
        "Do you really want to clear the only selected item from the list?",
      "Compartilhar com pessoas externas": "Share with external people",
      "Você não tem permissão para compartilhar estes itens":
        "You do not have permission to share these items.",
      "Remetente desconhecido": "Unknown sender",
      "Link não encontrado.": "Link not found.",
      "Este link foi desativado ou não existe.":
        "This link has been disabled or does not exist.",
      "Baixar tudo": "Download all",
      "Download realizado com sucesso.": "Download successful.",
      "arquivos": "Files",
      "Compartilhado por": "Shared by",
      "Este link será desativado em": "This link will be disabled in",
      "Exluir arquivo": "Delete file",
      "Exluir versão": "Delete version",
      "Publicar arquivo": "Make item visible",
      "Despublicar arquivo": "Hide file",
      "Você tem certeza que deseja deletar esse asset do repositório?":
        "Are you sure you want to delete this asset from the repository",
      "Você tem certeza que deseja publicar": "Are you sure you want to make visible",
      "Você tem certeza que deseja despublicar": "Are you sure you want to hide",
      "Você tem certeza que deseja excluir a versão":
        "Are you sure you want to delete the version {{deleteName}}?",
      "Item com erro": "Item error",
      "Arquivos para download": "Files to download",
      "Item compartilhado com sucesso": "Item shared successfully.",
      "Arquivo compartilhado com sucesso": "File shared successfully.",
      "Compartilhar ativo": "Share asset",
      "Upload concluído com sucesso. Aguarde o processamento das imagens":
        "Upload completed successfully. Wait until all images are loaded.",
      "Importar arquivos": "Import files",
      "Preparando…": "Preparing…",
      "Solte os arquivos aqui ou clique para fazer o upload":
        "Drop files here or click to upload.",
      "texto-IA-bucket":
        "We use artificial intelligence in file processing. Once this process is finished, the file image will be available.",
      "Selecionar asset": "Select asset",
      "Excluir arquivo": "Delete file",
      "Excluir": "Delete",
      "Comentário": "Comment",
      "É necessário preencher o campo Categoria": "Field Category is required.",
      "É necessário preencher o campo Segmento": "Field Segment is required.",
      "Novo Item": "New Item",
      "Nome do Item": "Item name",
      "Descrição do Item": "Item description",
      "Download terminado": "Download completed successfully!",
      "Erro no download. Tente novamente, caso o erro persista, contate o suporte":
        "An error occurred while processing your request. Please, try again. If the problem persists, contact support",
      "Autorizando o download": "Authorizing download {{itemName}}",
      "Item não está publicado. Visível somente para administradores":
        "Item not published. Visible only to administrators.",
      "Informe ao menos um e-mail": "Inform at least one email",
      "Há e-mail(s) com erro": "Invalid email",
      "Pressione <Enter> para inserir mais de um e-mail":
        "Press <Enter > to insert another email",
      "Compartilhamento efetuado com sucesso": "Shared successfully!",
      "Excluir item": "Delete {{total}} item",
      "Excluir itens": "Delete {{total}} items",
      "Não é possível fazer streaming do vídeo. O tipo não é suportado pelo navegador ou a extensão não corresponde ao conteúdo":
        "Cannot stream the video. Your browser does  not support this type of file or the extension does not match the content.",
      "Erro ao fazer o streaming do vídeo. A permissão para executar pode ter vencido. Execute-o novamente":
        "Error while streaming video. Permission to run may have expired. Please, try again.",
      "Pronto para download": "{{name}} ready to download",
      "Reportar erro": "Report error",
      "Você deseja realmente reportar esse problema?":
        "Do you really want to report this problem?",
      "Descrição do erro": "Error description",
      "Erro ao processar o arquivo ": "Error processing file ",
      "Erro ao fazer upload do arquivo ": "Error loading file ",
      "Retorno do servidor": "Server return",
      "Este erro reportado será transformado em um chamado e a equipe de suporte da Yapoli retornará diretamente para ":
        "This reported error will be transformed into a ticket and the Yapoli support team will return directly to ",
      " com mais informações sobre a ocorrência em até 24h a partir da data de envio":
        " with more information about the occurrence within 24 hours from the date of dispatch",
      "Erro reportado": "Error reported",
      "Ao reportar esse erro um chamado será aberto e a equipe de suporte da Yapoli retornará diretamente para ":
        "While reporting this error a ticket will be opened and the Yapoli support team will return directly to ",
      " com mais informações sobre a ocorrência em até 24h a partir da data de envio.":
        " with more information about the occurrence within 24 hours from the date of dispatch.",
      "Erro reportado com sucesso.": "Error reported successfully.",
      "Retomar uploads pausados": "Resume paused uploads",
      "Pausar uploads em progresso": "Pause uploads in progress",
      "Uploads retomados com sucesso.": "Uploads resumed successfully.",
      "Erro ao tentar retomar todos os uploads":
        "Error attempting to resume all uploads",
      "Uploads pausados com sucesso.": "Uploads paused successfully.",
      "Erro ao tentar pausar todos os uploads":
        "Error attempting to pause upload",
      "Upload pausado com sucesso.": "Upload paused successfully.",
      "Erro ao tentar pausar o upload": "Error trying to pause upload",
      "Upload retomado com sucesso.": "Upload resumed successfully.",
      "Erro ao tentar retomar o upload": "Error trying to resume upload",
      "Cancelar upload": "Cancel upload",
      "Você tem certeza que deseja cancelar o upload do arquivo":
        "Are you sure you want to cancel the file upload {{file}}?",
      "Somente integrações não processadas": "Only unprocessed integration",
      "Você tem certeza que deseja reprocessar a integração deste arquivo?":
        "Are you sure you want to reprocess this file integration?",
      "Você selecionou mais itens que o permitido para download em massa.":
        "Bulk download can have a maximum of {{maximum}} items. You have select {{selected}} items",
      "Download em andamento. Você será notificado quando concluído e poderá baixar em notificações.":
        "Download in progress. You will be notified when completed and will be able to download in notifications.",
      "A importação via CSV terminou. Clique para copiar link para baixar arquivo com os detalhes.":
        "The CSV import finished. Click to copy a link to download file with details.",
      "Relevância": "Relevance"
    },
    manager: {
      "Editar variantes do atributo": "Edit attribute variants",
      "esquema de metadados": "metadata schema",
      "Defina qual esquema será considerado neste atributo.": "Define which schema will be considered in this attribute.",
      "Esquema de Metadados": "Metadata Schema",
      "Incluir Esquema de Metadados": "Include Metadata Schema",
      "Defina quais metadados serão considerados atributos deste esquema.": "Define which metadata will be considered attributes of this schema",
      "Chave secundária do Item": "Secondary Item Key",
      "Defina quais metadados serão adicionados à chave secundária do item.": "Define which metadata will be added to the item's secondary key",
      "Gerar CSV": "Generate CSV",
      "Perfil do usuário alterado com sucesso.": "User profile changed successfully.",
      "Mover": "move",
      "Mover de perfil": "Change Profile",
      "Mover usuário para outro perfil": " Move user to another profile",
      "Essa ação pode ser realizada apenas para perfis em que seu usuário é moderador. Defina o novo perfil do usuário": "This action can only be performed for profiles in which your user is a moderator. Define the new user profile",
      "Por autenticação": "By Authentication",
      "Pela plataforma Yapoli": "By the Yapoli platform",
      "Cliente ID": "Client ID",
      "Escopo": "Scope",
      "URL de autorização": "Authorization URL",
      "URL de token": "Token URL",
      "Chave secreta": "Secret Key",
      "Mudar Senha": "Change Password",
      "URL do serviço": "Service URL",
      "Configure os parâmetros do seu modelo de autenticação Single Sign ON - SSO. Dúvidas entre em contato com o suporte@yapoli.com": "Configure the parameters of your Single Sign ON - SSO authentication model. If you have any questions, please contact suporte@yapoli.com",
      "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos downloads e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com": "Create an alternative taxonomy name for your items so that they are displayed as an option on downloads and sharing. After creation, link in the desired category. If you have any questions, please contact suporte@yapoli.com",
      "Escolha o modelo de autenticação": "Choose authentication model",
      "Escolha qual será o tipo de autenticação do perfil": "Choose what type of profile authentication will be",
      "Lista de cadastro integrado": "Integrated registration list",
      "Digite o domínio para acesso": "Enter the domain to access",
      "Os ativos de vídeos seguirão as especificações do arquivo original.": "Video assets will follow the specifications of the original file.",
      "Categoria relacionada": "Related Category",
      "Relacionamento de imagens": "Image relationship",
      "Ao habilitar, selecione a Categoria que possui Itens relacionados para que ocorra a identificação automática das imagens.": "When enabling, select the Category that has Related Items for automatic identification of images to occur.",
      "Quando habilitado, será possível relacionar de forma manual os itens cadastrados na plataforma, durante a criação e edição do item.": "When enabled, it will be possible to manually list the items registered on the platform, during item creation and editing.",
      "Política de privacidade": "Privacy policy",
      "Essa solicitação está associada a esse item": "This request is associated with this item",
      "Item da solicitação": "Request item",
      "Comentário para validação": "Validation comment",
      "Comentário da reprovação": "Disapproval comment",
      "Comentário da invalidação": "Invalidation comment",
      "Os arquivos selecionados foram aprovados": "The selected items have been approved",
      "Os arquivos selecionados aguardam aprovação": "The selected files are awaiting approval",
      "Rascunho do item": "Item draft",
      "Alterar status": "Change status",
      "1 comentário": "1 comment",
      "comentários": "{{qtd}} comments",
      "Informações para a criação do item na plataforma": "Information for creating the item on the platform",
      "O rascunho do seu item foi criado. Caso deseje, é possível editar as informações de cadastro ao clicar nele": "Your item draft has been created. If you wish, you can edit the registration information by clicking on it.",
      "Resumo  da tarefa": "Task summary",
      "Informações complementares": "Additional information",
      "Itens relacionados": "Related items",
      "Anexar arquivos para a tarefa": "Attach files to the task",
      "Anexe arquivos para auxiliar na tarefa, como referências, documentos, etc.": "Attach files to help with the task, such as references, documents, etc.",
      "Solte os arquivos aqui ou clique para fazer o upload.": "Drop files here or click to upload.",
      "Arquivos anexados": "Attached files",
      "Selecione os arquivos que devem ir para aprovação": "Select the files that must go for approval",
      "Envie mensagens aos envolvidos no projeto": "Send messages to project stakeholders",
      "Paleta de cores do item": "Item color palette",
      "Será extraído as cores predominantes do item.": "The predominant colors of the item will be extracted.",
      "solicitação": "request",
      "Legenda do botão em": "Button caption in {{idm}}",
      "Selecione quem será o aprovador da ferramenta de workflow": "Select who will be the workflow tool approver",
      "Selecione quem será o validador da ferramenta de workflow (se houver)": "Select who will be the workflow tool validator (if any)",
      "Defina qual será o nome da ferramenta de": "Define what will be the {{tool}} tool name",
      "Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta": "Create a workflow that requires approval of tasts and generate a new item at the end of the tool",
      "A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.": "From items registeres on the platform, send a request so that other users can perform some task",
      "Nome da ferramenta": "Tool name",
      "Descreva qual o objetivo da ferramenta de": "Describe the purpose of the {{tool}} tool",
      "Explique aos envolvidos o que se pretende alcançar com essa ferramenta": "Explain to those involved what is expected to be accomplished with this tool",
      "Essa ferramenta será utilizada para...": "This tool will be used to...",
      "Defina qual será a legenda do botão": "Define what will be the button's caption",
      "Adicione um nome para o botão da ação da ferramenta": "Add a name for the tool's action button",
      "Enviar, Compartilhar, Executar...": "Submit, Share, Execute...",
      "Selecione quem será o executor da ferramenta de": "Select who will execute the {{tool}} tool",
      "Apenas usuários cadastrados na plataforma podem ser": "Only users registered on the platform can be {{type}}",
      "Selecione os usuários": "Select the users",
      "Selecione o usuário": "Select the user",
      "Selecione os metadados que complementam a solicitação dessa ferramenta": "Select the metadata that complements the request for this tool",
      "Quais metadados podem auxiliar na execução da ferramenta?": "What metadata can help in the execution of the tool?",
      "Iniciar tarefa": "Start task",
      "Nome da tarefa": "Task name",
      "Adicionar idioma": "Add language",
      "Criação de nova tarefa": "New task creation",
      "Defina qual será o nome da tarefa": "Define what will be the name of the task",
      "Idioma padrão da plataforma": "Platform default language",
      "Esse nome será visto pelos usuários que utilizam o idioma na plataforma": "This name will be seen by users who use the {{idm}} language on the platform",
      "Resumo da tarefa": "Task summary",
      "Explique aos envolvidos o que se pretende alcançar com essa tarefa": "Explain to those involved what you want to achieve with this task",
      "Essa tarefa será para...": "This task will be for...",
      "Informações complementares da tarefa": "Additional task information",
      "Preencha essas informações para auxiliar na execução da tarefa": "Fill in this information to assist in performing the task",
      "Busque os itens cadastrados na plataforma que possam auxiliar na tarefa": "Search for items registered on the platform that can assist in the task",
      "Ao finalizar essa tarefa, será criado automaticamente um item na plataforma com o histórico do que foi realizado. Como o item deve ser cadastrado?": "At the end of this task, an item will be automatically created on the platform with the history of what has been done. How should the item be registered?",
      "Aprovador da tarefa": "Task approver",
      "Ferramenta de workflow": "Workflow tool",
      "A tarefa foi finalizada com sucesso.": "The task was successfully completed.",
      "O que gostaria de fazer agora?": "What would you like to do now?",
      "Voltar para o dash": "Back to dash",
      "abrir item": "Open item",
      "Esta ferramenta não está associada a nenhuma categoria": "This tool is not associated with any category",
      "Utilização da ferramenta": "Tool usage",
      "Buscar item": "Search item",
      "Informações complementares do projeto": "Additional project information",
      "Quais informações precisam ser preenchidas durante a execução do workflow?": "What information needs to be filled in during the workflow execution?",
      "Selecione os metadados": "Select the metadata",
      "criação da ferramenta de workflow": "Workflow tool creation",
      "edição da ferramenta de workflow": "Workflow tool edition",
      "Ferramenta criada para": "Tool created for",
      "Selecione quem será o executor dessa ferramenta dentre os usuários da plataforma.": "Select who will be the executor of this tool among the users of the platform.",
      "Executor(es)": "Executor(s)",
      "Selecione quem será o aprovador dessa ferramenta dentre os usuários da plataforma.": "Select who will be the approver of this tool among the platform users.",
      "Aprovador(es)": "Approver(s)",
      "REQUEST": "Request",
      "Solicitação": "Request",
      "WORKFLOW": "Workflow",
      "ferramenta": "tool",
      "Executor": "Executor",
      "Aprovador": "Approver",
      "Categoria Aberta": "Open Category",
      "Defina qual esquema será considerado nesta categoria": "Define which scheme will be considered in this category",
      "Esquemas de atributos": "Attribute schemas",
      "Em categorias abertas os compartilhamentos não expiram após cada download.": "In Open Categories shares do not expire after each download.",
      "Não bloquear": "Do not block",
      "Acesso do usuário": "User access",
      "A plataforma poderá bloquear o acesso do usuário após tentativas excessivas de login.": "The platform can block the user's access after excessive login attempts.",
      "Bloquear após tentativas": "Block after {{qtd}} attempts",
      "Ao editar uma categoria, a taxonomia deve conter o nome do item, quando esta tem itens atrelados.": "When editing a category, the taxonomy must contain the name of the item, when it has linked items.",
      "Essa Categoria não poderá ser alterada após sua criação.": "This Category cannot be changed after its creation.",
      "Essa Categoria não pode ser alterada por ser do tipo galeria de itens.": "This Category cannot be changed as it is an item gallery type.",
      "Como gostaria de redimensionar?": "How would you like to resize?",
      "Proporcionalmente": "Proportionally",
      "Medidas exatas": "Exact measures",
      "Plano de fundo de imagem PNG": "PNG image background",
      "Português": "Portuguese",
      "Português*": "Portuguese*",
      "Inglês": "English",
      "Espanhol": "Spanish",
      "Explique como identificar qual Região o solicitante deve pedir acesso": "Explain how to identify which Region the applicant should request access",
      "Explique como identificar qual Perfil o solicitante deve pedir acesso": "Explain how to identify which Profile the requester should ask for access",
      "Explique porque são necessários os dados complementares": "Explain why additional data is needed",
      "Explique porque são necessários os dados pessoais": "Explain why personal data is needed",
      "Defina qual será o separador de palavras nas nomenclaturas para download e para a distribuição.": "Define what the word separator will be in the nomenclature for download and distribution.",
      "Para adicionar aperte ENTER ou TAB. Para colar de uma lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar.": "To add press ENTER or TAB. To paste from a list of words, use a comma (;) or a semicolon (;) to separate.",
      "Separador de palavras": "Word separator",
      "Expressão de busca": "Search expression",
      "Dicionário de substituição": "Replacement dictionary",
      "_ (Underline)": "_ (Underline)",
      "- (Hífen)": "- (Hyphen)",
      "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar": "To add press ENTER or TAB. To paste from a list of words, use a comma (;) or semicolon (;) to separate",
      "Defina como aparecerá o nome do ativo ao baixar e/ou nas integrações do item.": "Define how the asset name will appear when downloading and/or in item integrations.",
      "sobre expressão regular.": "about regular expression.",
      "Convidar": "Invite",
      "Exclusão de usuário": "User exclusion",
      "Ops, algo deu errado": "Oops, something went wrong",
      "Limite de 20 e-mails inseridos alcançado. Revise a lista e tente novamente.": "Limit of 20 emails entered reached. Review the list and try again.",
      "Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de e-mails, utilize vírgula (,) ou ponto e vírgula (;) para separar": "Enter the email and press ENTER or TAB to add. For mailing list, use comma (,) or semicolon (;) to separate",
      "Convidar usuários": "Invite users",
      "Convide colaboradores para esse Perfil que ainda não possuem acesso a plataforma. Acompanhe o status do convite na página de usuários.": "Invite employees to this Profile who do not yet have access to the platform. Track the status of the invite on the users page.",
      "Insira o e-mail de seus convidados": "Enter your guests email",
      "Enviar convite": "Send invitation",
      "Convite enviado com sucesso.": "Invitation sent successfully.",
      "Acesse a Página de usuário para acompanhar os convites.": "Go to the User Page to track invitations.",
      "ok, entendi": "ok, I got it",
      "Manter original": "Maintain original",
      "Defina qual será o nome que representará esse nome alternativo ao baixar o item": "Define what will be the name that will represent this alternative name when downloading the item",
      "Será aplicado somente em itens da mesma região.": "It will only be applied to items from the same region.",
      "Nomes alternativos": "Alternate names",
      "Adicione as opções de nomes alternativos para baixar, compartilhar e para CDN.": "Add alternative name options for download, share, and CDN.",
      "The alternatives names may not have more than 10 items.": "The alternatives names may not have more than 10 items.",
      "Itens pertencentes a região ": "Items belonging to region ",
      "Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos download e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com": "Create an alias for the taxonomy of your items so that they appear as an option in download and share. After creation, link in the desired category. If you have any queries, please contact support@yapoli.com",
      "Especificação de imagem": "Image specification",
      "Nome alternativo": "Alternative name",
      "Busque quais metadados serão adicionados ao nome alternativo": "Search which metadata will be added to the alias",
      "Defina qual será o nome que representará essa taxonomia alternativa ao baixar o item": "Define which name will represent this alternative taxonomy when you download the item",
      "Para dúvidas sobre o conteúdo cadastrado nessa região, quem deve receber o e-mail de suporte?": "For questions about the content registered in this region, who should receive the support email?",
      "E-mail para suporte de conteúdo": "Email for content support",
      "Este metadado não está associado a nenhuma categoria": "This metadata is not associated with any category",
      "Este metadado não está associado a nenhum perfil": "This metadata is not associated with any profile",
      "Este metadado não está associado a nenhuma ferramenta de solicitação": "This metadata is not associated with any request tool",
      "Detalhes do metadado": "Metadata details",
      "está sendo utilizado em": "{{name}} is being used in",
      "está sendo utilizada em": "{{name}} is being used in",
      "Busque os metadados que complementam a solicitação dessa ferramenta.": "Fetch the metadata that complements the request for this tool",
      "Escreva a especificação": "Write the specification",
      "Especificações da imagem": "Image specifications",
      "Adicione as especificações de formatos da imagem para baixar e compartilhar.": "Add image format specifications for downloads and sharings.",
      "Extensão": "Extension",
      "Taxonomia do item": "Item's taxonomy",
      "Modo de cor": "Color mode",
      "Largura": "Width",
      "Altura": "Height",
      "Profundidade": "Depth",
      "Resolução": "Resolution",
      "Compressão": "Compression",
      "Qualidade (de 0 a 100)": "Quality (0 of 100)",
      "Plano de fundo": "Background",
      "Transparente": "Transparent",
      "Processo de pngquant que utiliza algoritmo de quantização para compactar pngs": "pngquant process that uses quantization algorithm to compress pngs",
      "Exclusão de metadados da imagem": "Deleting image metadata",
      "Os ativos em 3D seguirão as especificações do arquivo original.": "3D assets will follow the original file’s specifications.",
      "Os ativos em PDF seguirão as especificações do arquivo original.": "PDF assets will follow the original file’s specifications.",
      "Nome em português": "Name in Portuguese",
      "Nome em inglês": "Name in English",
      "Nome em espanhol": "Name in Spanish",
      "Nome alternativo em português": "Alternative name in Portuguese",
      "Nome alternativo em inglês": "Alternative name in English",
      "Nome alternativo em espanhol": "Alternative name in Spanish",
      "Imagem": "Image",
      "Crie a sua própria especificação de imagens para distribuição CDN, para downloads e compartilhamentos em categorias. Dúvidas entre em contato com suporte@yapoli.com.": "Create your own image specification for CDN distribution, downloads and sharing in categories. If you have any questions, please contact support@yapoli.com.",
      "Especificações de imagem": "Image specifications",
      "Habilitar": "Enable",
      "Desabilitar": "Disable ",
      "Os Perfis permitidos terão acesso aos itens, mas apenas perfis com permissão de Gerenciar terão visualização na Categoria pela navegação no DAM.": "Allowed profiles will have access to the items, but only profiles with the Manage Permission will be able to view the Category during the DAM navigation.",
      "A Categoria ficará visível para todos durante a navegação no DAM.": "The Category will be visible to everyone during the DAM navigation.",
      "A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma principal preenchido as informações serão utilizadas como padrão em todos os idiomas.": "The platform can contain name and description according to the desired language. If you only have the main language filled in, the information will be used by default in all languages.",
      "Esse arquivo será mostrado a todos os usários que não possuirem um idioma definido ao entrar na plataforma.": "This file will be shown to all users who do not have a defined language when entering the platform.",
      "Esse arquivo será mostrado a todos os usários que possuirem o inglês como idioma da plataforma.": "This file will be shown to all users who have English as the platform language.",
      "Esse arquivo será mostrado a todos os usários que possuirem o espanhol como idioma da plataforma.": "This file will be shown to all users who have Spanish as the platform language.",
      "Personalize o nome da plataforma e de uma descrição para que outros usuários conheçam sua funcionalidade.": "Customize the platform name and a description so that other users know its functionality.",
      "Adicione domínios e links externos como sites, redes sociais e outros web sites utilizados por você, clientes e fornecedores.": "Add external domains and links like websites, social networks and other websites used by you/customers/suppliers.",
      "A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma obrigatório preenchido as informações serão utilizadas como padrão em todos os idiomas.": "The platform can contain name and description according to the desired language. If you only have the mandatory language filled in, the information will be used by default in all languages.",
      "Personalize a plataforma com a sua identidade visual.": "Customize the platform with your visual identity.",
      "Miniatura": "Thumbnail",
      "Defina quais metadados serão adicionados na taxonomia do item.": "Define which metadata will be added to the item’s taxonomy.",
      "Digite aqui os valores para o campo tipo Lista": "Enter the values for the List type field here",
      "Selecione a miniatura correspondente ao idioma desejado.": "Select the thumbnail corresponding to the desired language.",
      "Adicionar miniatura": "Add Thumbnail",
      "A inatividade em Peril foi desativada.": "Profile inactivity has been disabled.",
      "Data": "Date",
      "Lista": "List",
      "Número": "Number",
      "Texto": "Text",
      "Nenhuma opção": "No options",
      "Digite um domínio valido": "Enter a valid domain",
      "Digite o domínio sem o @": "Enter the domain without the @",
      "A alteração do prazo deste perfil impactará nos usuários ativos listado abaixo. Deseja continuar?": "A change in the term of this profile will impact our active users listed below. Do you want to continue?",
      "Criação de divisão": "Division creation",
      "Edição de divisão": "Division editing",
      "Itens da divisão serão alterados.": "Division items will be changed.",
      "Serão alterados.": "Will be changed.",
      "O formulário tem erros, para continuar corrija-os.": "The form has errors, correct them to continue.",
      "Erro ao deletar os dados, contate o administrador.": "Error deleting data, contact administrator.",
      "Deve haver ao menos um link": "There must be at least one link",
      "Item não pode ser desativado pois está na taxonomia.": "Item cannot be disabled as it is in the taxonomy.",
      "Item não pode ser removido pois está na taxonomia.": "Item cannot be removed as it is in the taxonomy.",
      "Digite aqui os valores para o campo tipo lista": "Enter the values for the list type field here",
      "Alterando Divisão": "Changing Division",
      "Erro ao carregar os dados, contate o suporte": "Error loading data, contact support",
      "Concluído": "Concluded",
      "Alterando": "Changing",
      "Erro ao processar, contate o administrador.": "Error processing, contact administrator.",
      "Criação de": "Creating of",
      "Edição de": "Edition of",
      "Buscar pelo nome": "Search by name",
      "Nenhum dado encontrado.": "No data found.",
      "Nome obrigatório.": "Required name.",
      "Defina qual ferramenta de solicitação está associada a essa categoria.": "Define which request tool is associated with this category.",
      "Defina quais metadados serão considerados atributos desta categoria.": "Define which metadata will be considered attributes of this category.",
      "Adicione o nome alternativo para visualização em downloads e compartilhamentos.": "Add alternative name for display on downloads and shares.",
      "Permissível": "Allowable",
      "Chave Secundária": "Secondary Key",
      "Editável": "Editable",
      "Erro inesperado. Contate o suporte": "Unexpected error. Contact support",
      "carregando": "Loading",
      "Não é possível permissionar os  metadados abaixo pelos seguintes erros": "Unable to permission the metadata below due to the following error",
      "Impossibilidade de permissionamento": "Impossibility of permission",
      "Criação de metadado": "Metadata creation",
      "Edição de metadado": "Metadata editing",
      "A aplicação de um novo tempo para perfil temporário impactará em todos os usuários atrelados ao Perfil. Deseja continuar?": "The application of a new time for temporary profile will impact all users linked to the Profile. Do you wish to continue?",
      "Alteração de perfil temporário": "Temporary profile change",
      "Alteração de período de inatividade": "Change of downtime",
      "A aplicação de um novo tempo para Inatividade de perfis impactará em todos os usuários atrelados ao Perfil. Deseja continuar?": "The application of a new Profile Idle time will impact all users linked to the Profile. Do you wish to continue?",
      "Nome da ferramenta de solicitação": "Request tool name",
      "Nome da ferramenta do workflow": "Workflow tool name",
      "Legenda do botão": "Button caption",
      "Personalize a legenda do botão.": "Customize the button caption",
      "Ao excluir uma ferramenta você estará excluindo todo fluxo atrelado a ela. Os dados da ferramenta serão perdidos e ela não aparecerá para nenhum perfil. Você deseja continuar?": "When deleting a tool you will be deleting all the flow linked to it. The tool data will be lost and it will not appear for any profile. Do you want to continue?",
      "Desbloquear usuário": "Unblock User",
      "Máximo caracteres": "Maximum {{value}} characters",
      "Ferramentas de solicitação": "Request tool",
      "Ferramenta de solicitação": "Request tool",
      "ferramentas de solicitação": "request tool",
      "ferramenta de solicitação": "request tool",
      "Texto de apoio.": "Support Text.",
      "Tem certeza que deseja deletar a": "Are you sure you want to delete the",
      "Idioma": "Language",
      "Moeda": "Currency",
      "Timezone": "Timezone",
      "Editar": "Edit",
      "Excluir": "Delete",
      "Erro ao enviar imagem": "Error sending image",
      "Nenhum resultado encontrado": "No result found",
      "Clique aqui ou arraste uma imagem para enviar":
        "Click here or drag an image to send.",
      "Remover Imagem": "Delete image",
      "Selecione o arquivo para upload": "Select file to upload",
      "Arquivo inválido. Arquivo até 2MB em JPG ou PNG":
        "Invalid file. Max size 2MB in JPG or PNG.",
      "Arquivo inválido. Arquivo até 2MB em ICO ou PNG":
        "Invalid file. Max size 2MB in ICO or PNG.",
      "Arquivo inválido. Arquivo até 5MB em JPG ou PNG":
        "Invalid file. Max size 5MB in JPG or PNG.",
      "Arquivo inválido. Arquivo até 5MB em ICO ou PNG":
        "Invalid file. Max size 5MB in ICO or PNG.",
      "Domínio inválido": "Invalid domain",
      "Idioma inválido": "Invalid language",
      "Moeda inválido": "Invalid currency",
      "Timezone inválido": "Invalid Timezone",
      "Adicionar região": "Add Region",
      "Editar egião": "Edit region",
      "Nome da região": "Region name",
      "Domínio padrão": "Default domain",
      "Confirmação de exclusão": "Delete confirmation",
      "Tem certeza que deseja deletar a região":
        "Are you sure you want to delete the region",
      "Incluir Divisão": "Include Division",
      "Adicionar Divisão": "Add Division",
      "Editar Divisão": "Edit division",
      "Digite o nome da região": "Enter region name",
      "Alterando segmentos e itens": "Modifying segments and items",
      "Alterando itens": "Modifying items",
      "Itens da divisão serão alterados": "Division items will be modified.",
      "Itens do segmento serão alterados": "Segment items will be modified.",
      "Nome da divisão": "Division name",
      "Tem certeza que deseja deletar a divisão":
        "Are you sure you want to delete the division?",
      "Imagem enviada com sucesso": "The image was submitted successfully",
      "Incluir Segmento": "Include Segment",
      "Adicionar Segmento": "Add Segment",
      "Editar Segmento": "Edit Segment",
      "Tem certeza que deseja deletar o segmento":
        "Are you sure you want to delete the segment?",
      "Favor selecionar um tipo de campo": "Please select a field type",
      "Incluir metadado": "Include metadata",
      "Editar metadado": "Edit metadata",
      "Nome do metadado": "Metadata name",
      "Digite o valor e aperte ENTER ou TAB para salvar e digitar outro":
        "Please enter the value, press ENTER or TAB to save and insert another.",
      "Tem certeza que deseja deletar o metadado":
        "Are you sure you want to delete the metadata?",
      "Incluir categoria": "Include category",
      "Adicionar metadado": "Add category",
      "Nome da Categoria": "Category name",
      "Taxonomia do Item": "Item taxonomy",
      "Descrição da categoria": "Category description",
      "Metadado não pode ser excluído, pois faz parte da taxonomia":
        "Metadata cannot be deleted as it's part of taxonomy.",
      "Criar perfil": "Create profile",
      "Adicionar perfil": "Add profile",
      "Editar perfil": "Edit profile",
      "Nome do perfil": "Profile name",
      "Descrição do perfil": "Profile description",
      "Tipo de Moderação": "Moderation type",
      "Escopo de acesso": "Access scope",
      "Busque uma permissão (mínimo 3 caracteres)":
        "Search permission (minimum 3 characters)",
      "Editar dados da plataforma": "Edit platform data",
      "Nome do cliente": "Customer name",
      "Nome da plataforma": "Platform name",
      "Cor primária": "Primary color",
      "Cor secundária": "Secondary color",
      "Cor ternária": "Ternary color",
      "Cor da fonte": "Font color",
      "Cor da fonte flutuante": "Floating font color",
      "Cor da fonte ativa": "Active font color",
      "Digite o hexadecimal correspondente à cor":
        "Type the hexadecimal corresponding to the color",
      "Legenda": "Subtitle",
      "Salvar alterações": "Save changes",
      "Alterar imagens da plataforma": "Modify platform images",
      "Plano de fundo do login": "Login background",
      "Logo da pagina inicial": "Home Page logo",
      "Logo para menu superior": "Top Menu logo",
      "Logo para compartilhamento": "Sharing logo (thumb whatsapp)",
      "Logo interno": "Internal logo",
      "Logo do e-mail": "Email logo",
      "Favicon": "Favicon",
      "Clique para ativar": "Click to enable",
      "Clique para desativar": "Click to disable",
      "Ao proceder com esta alteração, todos os itens na":
        "By proceeding with this change, all items of the",
      "serão alterados. As URLs dos respectivos itens poderão ser reescritas e a operação levará algum tempo. Ao final do processo você será notificado":
        "will be changed. The URLs of the respective items can be rewritten. This operation will take some time. At the end of the process you will be notified.",
      "texto-alteracao-entity-1":
        "By proceeding with this change, all items of the",
      "texto-alteracao-entity-2":
        "will be changed. The URLs of the respective items can be rewritten. This operation will take some time. At the end of the process you will be notified.",
      "Está seguro de que deseja proceder com a alteração agora?":
        "Are you sure you want to proceed with the change now?",
      "Fechar, o processamento continuará": "Close, processing will continue",
      "Esta tela pode ser fechada": "This screen can be closed.",
      "Único": "Unique",
      "Múltiplo": "Multiple",
      "Em atualização": "Updating",
      "Arquivo inválido. Somente um arquivo em formato CSV permitido.":
        "Invalid file. Only a single CSV formatted file allowed",
      "Clique aqui ou arraste um arquivo para enviar":
        "Click here or drag a file to send",
      "Criar itens": "Create items",
      "aprovadores": "approvers",
      "executores": "executors",
      "validadores": "validators",
    },
  },
}


export const MONTHS = {
  pt: {
    "january": "Janeiro",
    "february": "Fevereiro",
    "march": "Março",
    "april": "Abril",
    "may": "Maio",
    "june": "Junho",
    "july": "Julho",
    "august": "Agosto",
    "september": "Setembro",
    "october": "Outubro",
    "november": "Novembro",
    "december": "Dezembro"
  },
  es: {
    "january": "Enero",
    "february": "Febrero",
    "march": "Marcha",
    "april": "Abril",
    "may": "Mayo",
    "june": "Junio",
    "july": "Mes de julio",
    "august": "Agosto",
    "september": "Septiembre",
    "october": "Octubre",
    "november": "Noviembre",
    "december": "Diciembre"
  }
};

const options = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: [],
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next).init({
    detection: options,
    resources,
    debug: false,
    fallbackLng: 'pt',
    keySeparator: false,
    react: {
      wait: true,
    },
    interpolation: {
      escapeValue: false
    },
  })

if (i18n.language) {
  const index = i18n.language.indexOf('-')
  const lng = index !== -1 ? i18n.language.slice(0, index) : i18n.language

  if (Settings.DATA_SUPPORTED_LNG.findIndex(i => i.lng === lng) === -1) {
    i18n.changeLanguage('en')
  }
}

export default i18n
