export const settings = {
  area: 2,
  route: "segments",
  entity: "segment",
  title: {
    singular: "Segmento",
    plural: "Segmentos"
  },
  hasPusher: false,
};
