import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  main: {
    width: "100vw",
    height: "calc(100vh - 100px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  logo: {
    width: "100%",
    maxWidth: "200px",
    height: "100%",
    maxHeight: "100px",
    marginBottom: "10px",
    backgroundImage: `url(${localStorage.getItem("logoInsideJPG")})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    "@media (max-height: 600px)": {
      display: "none",
    },
  },
  content: {
    width: "40%",
    maxWidth: "355px",
    height: "100%",
    maxHeight: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 22.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      margin: 0,
      borderRadius: 0,
      height: "calc(100vh - 60px)",
      maxHeight: "none",
      padding: "0",
    },
    "& .containerText": {
      maxWidth: "100%",
      maxHeight: "100%",
      padding: "40px 20px",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
      },
      "& p": {
        fontWeight: "300",
        fontSize: "2.57em",
        lineHeight: "1.17em",
        textAlign: "center",
        color: "#818080",
      },
    },
  },
}))
