import React, { useState, useCallback, useMemo } from "react"
import _ from 'lodash'
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core"

import { useStyles } from "./styles"

import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded"
import { Creators as ItemsActions } from "flux/ducks/Items"

const DialogConfirmValidation = ({
  title,
  disabled,
  description,
  children,
  icon,
  total = 0,
  bucket,
  phraseInput,
  handleDelete,
  openDefault,
  handleOpenDialogDelete,
  handleSetOpen
}) => {
  const [state, setState] = useState({ open: openDefault, phrase: "" })
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const show = useCallback(() => () => {
    setState(prev => ({ ...prev, open: true }), handleOpenDialogDelete())
    if(handleSetOpen) {
      handleSetOpen(true)
    }
  }, [handleOpenDialogDelete, handleSetOpen])

  const hide = useCallback(() => {
    dispatch(ItemsActions.setOpenDialogItemsMarked({open: ''}))
    setState({ open: false, phrase: "" }, handleOpenDialogDelete())
    if(handleSetOpen) {
      handleSetOpen(false)
    }
  }, [handleOpenDialogDelete, handleSetOpen, dispatch])

  const confirm = useCallback(() => {
    if (handleDelete) {
      handleDelete(state.phrase)
      hide()
    } else {
      hide()
    }
  }, [handleDelete, hide, state.phrase])

  const onChangePhrase = useCallback((evt) => {
    const value = _.get(evt, 'target.value', '')
    setState(prev => ({ ...prev, phrase: value }))
  }, [])

  const constants = useMemo(() => ({
    checkPhrase: bucket
      ? total === 1
        ? t(`common:Excluir arquivo`, { total: total }).toUpperCase()
        : t(`common:Excluir arquivos`, { total: total }).toUpperCase()
      : total === 1
        ? t(`common:Excluir item`, { total: total }).toUpperCase()
        : t(`common:Excluir itens`, { total: total }).toUpperCase()
  }), [bucket, t, total])


  return (
    <React.Fragment>
      {children(show)}
      {state.open && (
        <Dialog
          open={state.open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            <div className="info">
              {icon ? icon : <AccountCircleRoundedIcon id="main" />}
              <div>{title}</div>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: "0", overflowY: "hidden" }}>
            <DialogContentText id="alert-dialog-description">
              {description}
              <br />
              <span style={{ fontWeight: 700 }}>
                {t("DAM:(essa ação é irreversível)")}
              </span>
            </DialogContentText>

            <TextField
              id="outlined-basic"
              label={`${t(`DAM:Escreva aqui`)} "${phraseInput.toUpperCase()}"`}
              variant="outlined"
              style={{ width: "100%", marginTop: "10px" }}
              onChange={onChangePhrase}
            />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              variant="outlined"
              color="primary"
              onClick={hide}
              disabled={disabled}
              style={{ marginRight: 20 }}
            >
              {t(`common:Cancelar`)}
            </Button>
            <Button
              disabled={!(constants.checkPhrase === state.phrase)}
              onClick={confirm}
              variant="contained"
              className={`delete ${constants.checkPhrase === state.phrase ? "active" : null
                }`}
            >
              {bucket
                ? total === 1
                  ? t(`common:Excluir arquivo selecionado`, { total: total })
                  : t(`common:Excluir arquivos selecionados`, { total: total })
                : total === 1
                  ? t(`common:Excluir item selecionado`, { total: total })
                  : t(`common:Excluir itens selecionados`, { total: total })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

DialogConfirmValidation.defaultProps = {
  title: "",
  description: "",
  disabled: false,
  icon: null,
  total: 0,
  bucket: false,
  phraseInput: "",
  handleDelete: () => { },
  handleOpenDialogDelete: () => {},
  openDefault: false
}

export default DialogConfirmValidation
