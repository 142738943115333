import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import axios from 'axios'
import _ from 'lodash'
import filesize from "filesize"
import {
  Paper,
  ListItem,
  Chip,
  ListItemText,
  Collapse,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LoupeRoundedIcon from '@material-ui/icons/LoupeRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { useDataAssetIntegra } from "../../context"
import YapoliImage from "components/Filters/YapoliImage"

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    zIndex: 1300,
    [theme.breakpoints.down("sm")]: {
      height: 'auto',
      top: 0
    }
  },
  paper: {
    width: '85%',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      minHeight: '100vh',
      borderRadius: 0,
    }
  },
  paperHeader: {
    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20,
      color: '#666',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
      '& svg': {
        color: '#666',
      },
      '& h6': {
        marginLeft: 10
      }
    }
  },
  paperContent: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',    
    '& .image': {
      width: '100%',
      minWidth: 100,
      minHeight: 400,
      marginRight: 20
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      paddingBottom: 80,
      '& .image': {    
        minWidth: '100%',   
        height: 170,
        minHeight: 100,
        marginRight: 0,
      },
    }
  },
  rootTags: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 10px',
    overflow: 'auto',
    maxHeight: 400,
    '& > *': {
      margin: 5,
    },
  },
  paperWrapper: {
    display: "flex",
    flexDirection: "column", 
    padding: 20,
    paddingTop: 0,
    wordWrap: "break-word",  
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#666",
    marginTop: 20,
    "& #title": {
      fontWeight: 500,
    },
    "& #info": {
      marginTop: 10,
      fontWeight: 300,          
    },
  },
}))


const GroupTags = ({
  title,
  data,
  tags = false,
  error = false
}) => {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = () => {
    setOpen(!open);
  };

  const props = tags ? { color: "primary" } : {}

  return (
    <div style={{width: '100%'}}>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.rootTags}>
          {
            !error && data.length > 0 &&
            data.map((i, k) => <Chip key={i + k} size="small" label={i} {...props} />)
          }
          {
            error && (
              <Chip label={t('Erro ao carregar os dados')} />
            )
          }
        </div>
      </Collapse>
    </div>
  )
}

export default function DetailsAsset({ dataAsset }) {
  const [dataTags, setDataTags] = useState({
    tags: [], labels: []
  })
  const [error, setError] = useState(false)
  const { supportsWebp } = useSelector((state) => state.uploadReducer)
  const { requestHeaders, pathServer } = useSelector(
    (state) => state.appReducer
  )
  const classes = useStyles()
  const { t } = useTranslation()

  const { handleOpenDetailsAsset } = useDataAssetIntegra()

  const type = supportsWebp ? "webp" : "original"

  const getData = useCallback(() => {
    setError(false)
    const ITEM_ID = _.get(dataAsset, 'props.data.item_id', '')
    const ASSET_ID = _.get(dataAsset, 'props.data.asset.id', '')
    
    axios.get(`${pathServer}/item/${ITEM_ID}/asset/${ASSET_ID}/tags`, requestHeaders)
      .then(function ({ data }) {
        setDataTags({
          tags: Array.isArray(data.tags) ? data.tags : [],
          labels: Array.isArray(data.labels) ? data.labels : [],
        })
      })
      .catch((err) => {
        setError(true)
        console.log(err)
      });
  }, [pathServer, requestHeaders, dataAsset])

  useEffect(() => {
    let isMounted = true
    if(isMounted) getData()
    return () => { isMounted = false }
  }, [getData])

  const constants = useMemo(() => ({
    dataInfo: [
      { title: 'common:Nome', value: _.get(dataAsset, 'props.data.asset.client_name', '') },
      { title: 'manager:Extensão', value: _.get(dataAsset, 'props.data.asset.extension', '') },
      { title: 'common:Tamanho', value: filesize(_.get(dataAsset, 'props.data.asset.bytes', 0)) },
    ]
  }), [dataAsset])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <div className='title'>
            <div>
              <LoupeRoundedIcon />
              <Typography variant='h6'>
                {t('DAM:Detalhes do ativo')}
              </Typography>
            </div>
            <IconButton onClick={() => handleOpenDetailsAsset()}>
              <CloseRoundedIcon />
            </IconButton>
          </div>

          <Divider />
        </div>       

        <div className={classes.paperContent}>
          <div className='image'>
            <YapoliImage
              extension={_.get(dataAsset, 'props.data.asset.extension', '')}
              asset={_.get(dataAsset, 'props.data.asset.id', {})}
              file={_.get(dataAsset, `props.data.asset.thumbnail_card_${type}`, '')}
              type={type}
              alt={_.get(dataAsset, 'props.data.asset.client_name', 'image')}
              width={"100%"}
              height={"100%"}
            />
          </div>

          <div className={classes.paperWrapper}>
            {constants.dataInfo.length > 0 &&
              constants.dataInfo.map((item, index) => (
                <div className={classes.rowWrapper} key={index}>
                  <Typography component="div" variant="body2" id="title">
                    {t(item.title)}
                  </Typography>
                  <Typography component="div" variant="body2" id="info">
                    {item.value}
                  </Typography>
                </div>
              ))}
          </div>
      
          {
            dataTags.tags.length > 0 &&
            <GroupTags title={t('common:Marcador')} data={dataTags.tags} tags error={error} />
          }
          {
            dataTags.labels.length > 0 &&
            <GroupTags title={t('common:TAG automatizada')} data={dataTags.labels} error={error} />
          }

        </div>
      </Paper>
    </div>
  );
}