import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: 30,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {  
        width: '100%',     
        height: 'calc(100vh - 170px)',
        display: 'flex',      
        justifyContent: 'center', 
        backgroundColor: '#eee',
        borderRadius: 4,
        position: 'relative',
        '& .action_close': {
            position: 'absolute',
            right: 15,
            top: 15,
            padding: 5
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },    
    wrapper: {
        backgroundColor: '#fff',        
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 30,
        width: '100%', 
    },
    footer: {      
        display: 'flex',        
        justifyContent: 'space-between',
        color: '#666',
        width: '100%',      
        '& .texts': {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 15,
            paddingRight: 15,
        },
        "& .actions": {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',    
            "& button": {
                height: 40
            }
        }
    },
}))