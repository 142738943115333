import React from "react"

import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"
import Utils from "utils/Utils"

import _ from "lodash"

const HeaderPage = ({ description, match, workflow, title }) => { 
  const history = useHistory()
  const { t } = useTranslation()
  
  return (
    <>
      <Helmet>
        <title>{Utils.getTitleFromUrl(t)}</title>
        <meta name="description" content={description} />       
      </Helmet>

      <HeaderMaterialDesign 
        title={{
          label: title, url: `/${_.get(match, 'params.region', '')}/${workflow ? "workflow" : "request"}/${_.get(match, 'params.slug', '')}`
        }}       
        localHistory={history} 
      />
    </>
  )
}

export default HeaderPage
