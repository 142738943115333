import React from "react";
import axios from "axios/index";
import Dropzone from 'react-dropzone'

import Template from "../Template";
import imgUpload from "../../../imagens/ui/ico-upload.svg";
import { withSnackbar } from 'notistack';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,   
  Divider
} from "@material-ui/core";
import { compose } from "recompose"
import { Content } from '../Clients/partials/Content'
import UploadDialog from '../Core/UploadDialog'
import ImageLazyLoad from "components/ImageLazyLoad"
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';

import { styles } from '../Core/UploadLanguage/styles'

import Settings from 'utils/Settings'
class Upload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      default_domain: "",
      email_domain: "",
      default_language: "",
      currency: "",
      timezone: "",
      picturesPreview: [],
      loading: true,
      data: {},
      id: props.match.params.id
    };
    this.handleRemovePicture = this.handleRemovePicture.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    
  }

  componentDidMount() {
    const ID = this.props.match.params.id;
    this.setState({
      ...this.state,
      id_token: ID,
    });
    if (ID) this.getData(ID);
  }

  getData(_id) {
    const SELF = this;
    const { t, enqueueSnackbar} = SELF.props;
    axios.get(
      localStorage.getItem("path") + "/user/" + _id,
      {
        headers: {
          "Authorization": localStorage.getItem("user_token"),
          "device": "desktop"
        }
      })
      .then(function (resp) {
        const DATA = resp.data.user;
        
        let arrFilesPreview = [];
       
        if (DATA.urls.thumbnail.original) {
          arrFilesPreview.push({path: DATA.urls.thumbnail.original});
        }
        SELF.setState({
          ...SELF.state,
          loading: false,
          id: DATA.id,
          name: DATA.name,
          picturesPreview: arrFilesPreview
        });
      }, function (resp) {
        SELF.setState({
          ...SELF.state,
          loading: false,
        });
        const message = resp.headers['x-message'] || t("Erro ao carregar os dados, contate o suporte")
      
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
      .catch(function (err) {
        console.log(err);
      
        enqueueSnackbar(t("Erro ao carregar os dados, contate o suporte"), {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      });
  }

  onDropUpload(files) {
    let arrFilesPreview = [];
    const SELF = this;
    const {t} = SELF.props;
    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append("thumbnail", file);

      SELF.setState({
        ...SELF.state,
        loading: true
      });

      let url = localStorage.getItem("path") + "/user/import/" + SELF.state.id;
      return axios.post(url, formData,
        {
          headers: {
            "Authorization": localStorage.getItem("user_token"),
            "device": "desktop"
          },
          onUploadProgress: progressEvent => {
            let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            console.log("percentCompleted", percentCompleted);
          }
        }).then(response => {
        arrFilesPreview.push({path: response.data.thumbnail + "?=timestamp=" + Math.floor(Date.now() / 1000)});
       
        this.props.enqueueSnackbar(t('Imagem enviada com sucesso'), {
          ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
        });
      }, () => {
        this.props.enqueueSnackbar(this.props.t("Erro ao carregar os dados, contate o suporte"), {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
    });

    axios.all(uploaders).then(() => {
      this.setState({
        ...this.state,
        loading: false,
        picturesPreview: arrFilesPreview
      }, () => {
        console.log(this.state.picturesPreview)
      });
    });
  }

  onDropRejected() {
    const {t} = this.props;
   
    this.props.enqueueSnackbar(t('Arquivo inválido. Arquivo até 2MB em JPG ou PNG'), {
      variant: 'error',
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    })
  }

  handleRemovePicture(event) {
    event.preventDefault();
    const SELF = this;
    const { t , enqueueSnackbar } = SELF.props
    this.setState({
      ...this.state,
      loading: true,
    });

    axios.post(
      localStorage.getItem("path") + "/user/remove/" + this.state.id + "/thumbnail",
      {},
      {
        headers: {
          "Authorization": localStorage.getItem("user_token"),
          "device": "desktop"
        }
      })
      .then(function (resp) {
        console.log("/user/delete-thumbnail/", resp);
        SELF.setState({
          ...SELF.state,
          loading: false,
          picturesPreview: [],
        });   
        enqueueSnackbar(t('Miniatura deletada com sucesso.'), {
          ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
        });
      }, function (resp) {
        SELF.setState({
          ...SELF.state,
          loading: false,
        });      
        enqueueSnackbar(t('Erro ao deletar, tente novamente.'), {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
      .catch(function (err) {
        console.log(err);      
        enqueueSnackbar(t('Erro ao deletar, contate o administrador.'), {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      });
  }

  render() {
    const { t, history, classes } = this.props;

    const handleClickBackRoute = (evt) => {
      evt.preventDefault()
      history.goBack()
    }

    return (
      <Template area={5} localHistory={history} customClassName={"container-center"}>
        
        <Content title={"manager:Adicionar miniatura"}>
          <Divider style={{ marginBottom: 20 }} />
          <Typography variant='body2' style={{ marginBottom: 0 }}>
            {t("manager:Selecione a miniatura correspondente ao usuário desejado.")}
          </Typography>
          <Typography variant='body2' style={{ marginBottom: 20 }}>
            {`${t('common:Especificações')}: PNG, JPG; 600px x 600px; ${t('common:tamanho máximo')} 2MB.`}
          </Typography>

          <Dropzone
            onDrop={this.onDropUpload.bind(this)}
            accept="image/jpeg, image/png"
            maxSize={2000000}
            onDropRejected={this.onDropRejected}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={classes.rootDropzone}>
                <div className='content'>
                  <input {...getInputProps()} />
                  {this.state.loading ? (
                    <div>
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {
                        this.state.picturesPreview.length > 0 ? (
                          <>
                            {
                              this.state.picturesPreview.map(file =>
                                <div key={file.path} className="image">
                                  <ImageLazyLoad
                                    images={{
                                      imgJPG: file.path,
                                      imgWEBP: file.path
                                    }}                                  
                                  />
                                </div>
                              )
                            }

                          </>
                        ) : (
                          <>
                            <img
                              src={imgUpload}
                              width={50}
                              alt={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                            />
                            <br />
                            <Typography variant="body2">
                              {t("manager:Clique aqui ou arraste uma imagem para enviar")}
                            </Typography>
                          </>
                        )
                      }
                    </>
                  )}
                </div>
              </div>

            )}
          </Dropzone>

          <Grid item xs={12} style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '20px 0',
          }}>
            {
              this.state.picturesPreview &&
              <>
                <Button
                  style={{ height: 40, width: 115, marginRight: 20 }}
                  variant="outlined"
                  color="primary"
                  disabled={!this.state.picturesPreview}
                  onClick={this.handleRemovePicture}
                >
                  {t("common:Excluir")}
                </Button>
                <UploadDialog
                  t={t}
                  title={t("manager:Adicionar miniatura")}
                  descriptionUpload={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                  onDropUpload={this.onDropUpload}
                  onDropRejected={this.onDropRejected}
                  loading={this.state.loading}
                  acceptFiles="image/jpeg, image/png"
                >
                  {(confirm) => (
                    <Button
                      variant={"contained"}
                      color="primary"
                      style={{ height: 40 }}
                      onClick={confirm()}
                    >
                      {t("manager:Adicionar miniatura")}
                    </Button>
                  )}
                </UploadDialog>
              </>
            }
          </Grid>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              style={{ height: 40, width: 115 }}
              variant="contained"
              color="primary"
              onClick={evt => handleClickBackRoute(evt)}>
              {t("common:Voltar")}
            </Button>
          </div>

        </Content>

      </Template>
    );

  }

}

export default compose(  
  withStyles(styles)  
)(withTranslation(["manager", "common"])(withSnackbar(Upload)))
