import React from 'react'
import _ from 'lodash'
import ListDefault from "../Core/List/DataGridCustom"
import ListWrapper from '../Core/List/ListWrapper'
import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "../../../utils/Flux"
import { withTranslation } from "react-i18next"

import { getColumnDelete, getColumnEdit } from "../Core/utils"

class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      messageToDelete:
        props.t("manager:Ao excluir uma ferramenta você estará excluindo todo fluxo atrelado a ela. Os dados da ferramenta serão perdidos e ela não aparecerá para nenhum perfil. Você deseja continuar?"),
    }
    this.superRender = super.render
  }

  getUrl() {
    const { pathClient, region } = this.props
    return `${pathClient}/${region}/${this.state.entity}`
  }

  getLine(item) {
    let line = {
      id: item.id,
      name: _.get(item, "title", _.get(item, "name", "")),
    }
    return line
  }

  getColumns() {
    const { t } = this.props

    let columns = [
      {
        field: "name",
        headerName: t("common:Nome"),
        flex: 1,
        sortable: false,
      }
    ]

    columns.push(
      getColumnEdit((id) => {
        this.props.history.push(`/manager/schema/edit/${id}`)
      }, t)
    )

    columns.push(
      getColumnDelete((id, name) => {
        this.setState({
          ...this.state,
          deleteDialogOpen: true,
          idToDelete: id,
          nameToDelete: name,
        })
      }, t)
    )

    return columns
  }

  render() {
    const { hasFab, area, title, route } = this.state

    return (
      <ListWrapper
        area={area}
        title={title.singular}
        route={route}
        hasFab={hasFab}
      >
        {this.superRender()}
      </ListWrapper>
    )
  }
}

export default connect(
  mapStateToPropsToSettings,
  null
)(withTranslation(["manager", "common"])(List))
