import React from 'react'
import AccessDenied from "components/AccessDenied"

import { useDataItems } from './context'

const AccessDeniedAndRegion = ({ children }) => {
    const { permission } = useDataItems()
    
    if (!permission.status) {        
        return <AccessDenied msg={permission.msg} />
    } else {
        return children
    }
}

export default AccessDeniedAndRegion