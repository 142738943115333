import React from 'react';
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import { useStyles } from './styles';
import {
    useMediaQuery,
    Toolbar,
    AppBar,
    Typography,
} from "@material-ui/core"

import { useTranslation } from "react-i18next"

import { LanguageButton } from 'components/LanguageButton'

const Header = ({ type }) => {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()
    const data = useSelector((state) => state.appReducer.data)
    const isMobile = useMediaQuery('(max-width:600px)');

    const isAccessRequest = type === "accessRequest"

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <div className='client'>
                    <div style={{ marginRight: 10, cursor: 'pointer' }}
                        onClick={() => history.push('/login')}
                    >
                        <img
                            src={data.logoInsideJPG}
                            alt={data.name}
                            width='100'
                            height='100'
                        />
                    </div>
                    <Typography variant='h5' id='platform_name'>
                        {data.platform_name}
                    </Typography>
                </div>
                {
                    !isMobile &&
                    <div className="logoAppbar">
                        <img src={data.logoTopMenuWEBP} alt={data.name} />
                    </div>
                }
                <div className='title'>
                    <Typography variant='h5' id='title'>
                        {
                            isAccessRequest
                                ? t('common:Solicitação de acesso')
                                : t('solicitacaoAcesso:Finalizar cadastro')
                        }
                    </Typography>
                    <div id='btn-translate'>
                        <LanguageButton />
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default Header