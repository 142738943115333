/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable  no-unused-vars*/
import _ from 'lodash'
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded"
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { AddCircleRounded } from '@material-ui/icons'
import EditIcon from "@material-ui/icons/Edit"

import InputAddSchema from './InputAddSchema';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  }
});

const DragHandle = SortableHandle(() => {
  return (
    <IconButton>
      <DragIndicatorIcon />
    </IconButton>
  )
});

const SortableItem = SortableElement(({ value, switchCells = [], disableRemove = false, disableSort = false }) => {
  return (
    <TableRow>
      {disableSort === false && <TableCell padding="checkbox">
        <DragHandle />
      </TableCell>}
      <TableCell align={disableRemove ? 'start' : 'center'}> {value.name}</TableCell>
      {switchCells.some(i => i === "required") && <TableCell align='center'>
        <Switch
          color='primary'
          checked={!!value.required}
          onChange={() => {
            value.handleCheckRequired(value.id)
          }}
        />
      </TableCell>}
      {switchCells.some(i => i === "blocked") && <TableCell>
        <Switch
          color='primary'
          checked={!Boolean(value.blocked)}
          onChange={() => {
            value.handleCheckBlockedEdit(value.id)
          }}
        />
      </TableCell>}

      {switchCells.some(i => i === "grantable") && <TableCell align={disableRemove ? 'right' : 'center'}>
        <Switch
          color='primary'
          checked={!!value.grantable}
          onChange={() => {
            value.handleCheckGrantable(value.id)
          }}
        />
      </TableCell>}

      {switchCells.some(i => i === "schema") && <TableCell>
        <div>
          { value.can_be_master === true &&
          <InputAddSchema
            onChangeSchemas={data => value.onChangeSchemas(value.id, data)}
            schemaSlected={_.get(value, "schema", {})}
          >
            {(confirm) => (
              <>
                {
                  Boolean(value.schema) ? (
                    <div>
                      <Typography component="span" variant='body1' style={{ marginRight: 8 }}>
                        {_.get(value, "schema.label", _.get(value, "schema.name", ""))}
                      </Typography>
                      <IconButton onClick={confirm()}>
                        <EditIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton onClick={confirm()}>
                      <AddCircleRounded />
                    </IconButton>
                  )
                }
              </>
            )}
          </InputAddSchema>
          }
        </div>
      </TableCell>}

      {disableRemove === false &&
        <TableCell align='center'>
          <IconButton onClick={() => {
            value.handleRemoveAttribute(value.id)
          }}>
            <DeleteRoundedIcon />
          </IconButton>
        </TableCell>}
    </TableRow>
  );
});

const SortableList = SortableContainer(({ items, switchCells, disableRemove = false, disableSort = false }) => {
  return (
    <TableBody>
      {items.map((value, index) => {
        value.handleRemoveAttribute = items.handleRemoveAttribute;
        value.handleCheckRequired = items.handleCheckRequired;
        value.handleCheckBlockedEdit = items.handleCheckBlockedEdit;
        value.handleCheckGrantable = items.handleCheckGrantable;
        value.onChangeSchemas = items.onChangeSchemas;

        return (
          <SortableItem
            switchCells={switchCells}
            disableRemove={disableRemove}
            disableSort={disableSort}
            key={`item-${index}`}
            index={index}
            value={value}
          />
        )
      })}
    </TableBody>
  );
});

class SimpleTable extends React.Component {
  render() {
    const SELF = this;
    const {
      classes,
      taxonomy = [],
      secondary_key,
      items,
      handleRemoveAttribute,
      handleCheckAttribute,
      handleMoveAttribute,
      t,
      switchCells = ["required", "blocked", "grantable"],
      disableRemove = false,
      disableSort = false
    } = SELF.props;

    if (items && items.length) {
      items.handleRemoveAttribute = (id) => {
        let newItems
        try {
          if (taxonomy && taxonomy.find((elem) => elem.column === id)) {
            throw new Error(t("manager:Item não pode ser removido pois está na taxonomia."));
          }
          if (secondary_key && secondary_key.find((elem) => elem.column === id)) {
            throw new Error(t("manager:Item não pode ser removido pois está na chave secundária."));
          }
          newItems = items.filter((item) => item.id !== id);
        } catch (error) {
          toast.warn(error.message);
          return
        }

        SELF.setState({ ...SELF, items: newItems });
        return handleRemoveAttribute(id);
      };

      items.handleCheckRequired = (id) => {
        let newItems;
        try {
          newItems = items.map((item) => {
            if (item.id === id) {
              if (taxonomy.find((elem) => elem.column === item.id)) {
                throw new Error(t("manager:Item não pode ser desativado pois está na taxonomia."));
              }
              if (secondary_key && secondary_key.find((elem) => elem.column === item.id)) {
                throw new Error(t("manager:Item não pode ser desativado pois está na chave secundária."));
              }
              item.required = (!!item.required) ? 0 : 1;
            }
            return item;
          });
        } catch (error) {
          console.log(error)
          toast.warn(error.message);
          return;
        }
        SELF.setState({ ...SELF, items: newItems });

        return handleCheckAttribute(id);
      };

      items.handleCheckBlockedEdit = (id) => {
        let newItems;
        try {
          newItems = items.map((item) => {
            if (item.id === id) {
              item.blocked = !item.blocked
            }
            return item;
          });
        } catch (error) {
          toast.warn(error.message);
          return;
        }

        SELF.setState({ ...SELF, items: newItems });

        return handleCheckAttribute(id);
      };

      items.handleCheckGrantable = (id) => {
        let newItems;
        try {
          newItems = items.map((item) => {
            if (item.id === id) {
              item.grantable = !item.grantable
            }
            return item;
          });

        } catch (error) {
          toast.warn(error.message);
          return;
        }
        SELF.setState({ ...SELF, items: newItems });

        return handleCheckAttribute(id);
      };

      items.onChangeSchemas = (id, data) => {
        let newItems;
        try {
          newItems = items.map((item) => {
            if (item.id === id) {
              item.schema = data
            }
            return item
          });

        } catch (error) {
          toast.warn(error.message);
          return;
        }
        SELF.setState(prev => ({ ...prev, items: newItems }))
      };
    }

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {disableSort === false && <TableCell>{t("common:Ordenar")}</TableCell>}
              <TableCell align={disableRemove ? 'start' : 'center'}>{t("common:Nome")}</TableCell>
              {switchCells.some(i => i === "required") && <TableCell align='center'>{t("common:Obrigatório")}</TableCell>}
              {switchCells.some(i => i === "blocked") && <TableCell>{t("manager:Editável")}</TableCell>}
              {switchCells.some(i => i === "grantable") && <TableCell align={disableRemove ? 'right' : 'center'}>{t("manager:Permissível")}</TableCell>}
              {switchCells.some(i => i === "schema") && <TableCell>{t("common:Esquema")}</TableCell>}
              {disableRemove === false && <TableCell align='center'>{t("Excluir")}</TableCell>}
            </TableRow>
          </TableHead>
          <SortableList
            switchCells={switchCells}
            items={items}
            onSortEnd={handleMoveAttribute}
            useDragHandle={true}
            helperClass='sortable'
            disableRemove={disableRemove}
            disableSort={disableSort}
          />
        </Table>
      </Paper>
    )
  }
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation(['manager', 'common'])(SimpleTable));
