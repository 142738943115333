import React, { useCallback, useEffect } from 'react';
import _ from 'lodash'
import axios from "axios"
import { useParams, useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { createEditForm } from 'components/CategoryAttributes'
import Sections from './sections'
import LoadingSkeleton from "./LoadingSkeleton"
import Utils from 'utils/Utils'
import { useDataFormItem } from "./context"
import { removeObsoleteUrlSessionStorage } from 'utils/Helpers'
import { useDataAssetIntegra } from "pages/DAM/Item/context"

export default function EditItem({ slugItem = "", handleClose }) {
    const { setInitialState, loading, changeLoading } = useDataFormItem()
    const { getDataItem } = useDataAssetIntegra()
    const { pathServer, requestHeaders, supportsWebp } = useSelector(state => state.appReducer)
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const paramsUrl = useParams()
    const history = useHistory()

    const updatePage = useCallback((data) => {
        try {
            const url = `/dam/${paramsUrl.region}/${_.get(data, 'division', paramsUrl.division)}/${_.get(data, 'segment', paramsUrl.segment)}/${_.get(data, 'category', paramsUrl.category)}/${_.get(data, 'taxonomy', paramsUrl.item)}`

            if (_.get(history, 'location.pathname', '') !== url) {
                history.push(url)
                removeObsoleteUrlSessionStorage()
                return
            } else {
                getDataItem()
                handleClose()
            }
        } catch (err) {
            console.log(err)
        }
    }, [paramsUrl, handleClose, getDataItem, history])

    const getData = useCallback(async () => {
        if (Boolean(slugItem)) {
            try {
                const slug = slugItem.split('-').join('_')
                const { data } = await axios.post(`${pathServer}/item/by/taxonomy/${slug}`, { "send_notpermitted": true }, requestHeaders)
                const linksResult = await axios.get(`${pathServer}/item/${_.get(data, 'item.id', '')}/links`, requestHeaders)
                const categoryResult = await axios.get(`${pathServer}/attributesByCategory/${_.get(data, 'item.category.id', '')}`, requestHeaders)

                let newData = {
                    ...data.item,
                    canPublish: { value: _.get(data, 'item.canPublish', false), id: 'division' },
                }

                if (data.item.validity_date) {
                    newData['validity_date'] = Utils.formatDate(data.item.validity_date, 'YYYY-MM-DD')
                }

                if (data.item.start_date) {
                    newData['start_date'] = Utils.formatDate(data.item.start_date, 'YYYY-MM-DD')
                }

                if (_.get(data, 'item.publishPermisions', false)) {
                    if (_.get(data, 'item.publishPermisions.canPublishCategory', false)) {
                        newData['canPublish'] = { value: true, id: 'category' }
                    }
                    if (_.get(data, 'item.publishPermisions.canPublishSegment', false)) {
                        newData['canPublish'] = { value: true, id: 'segment' }
                    }
                    if (_.get(data, 'item.publishPermisions.canPublishDivision', false)) {
                        newData['canPublish'] = { value: true, id: 'division' }
                    }
                }
                newData['initial_publish'] = `${_.get(data, 'item.published', true)}_${newData.validity_date || ''}_${newData.start_date || ''}`

                const dataLinks = _.get(linksResult, 'data.item.links', []).map((i) => {
                    const { id, taxonomy, thumbnail, name } = i;
                    const notPermitted = !_.isEmpty(taxonomy) && !_.isEmpty(thumbnail) && !name;

                    return {
                        value: id,
                        label: notPermitted ? t("common:Não Visível") : _.get(taxonomy, 'name', name),
                        thumbnail: Utils.getThumb(thumbnail, supportsWebp),
                        notPermitted
                    };
                })

                const dataAttributes = _.get(categoryResult, 'data.items', []).map((dataAttribute) => {
                    dataAttribute.mask_value = dataAttribute.mask_value ? dataAttribute.mask_value.trim() : '';
                    return dataAttribute
                })

                newData['dataFields'] = createEditForm(_.get(newData, 'attribute', []), dataAttributes)
                newData['relatedItems'] = dataLinks
                newData['attributes'] = dataAttributes
                setInitialState(newData, null, updatePage)
            } catch (err) {
                let message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
                changeLoading({ loading: false, checkedData: true })
            }
        } else {
            changeLoading({ loading: false, checkedData: true })
        }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, slugItem, supportsWebp, setInitialState, changeLoading, updatePage])

    useEffect(() => {
        let isMounted = true;
        if (isMounted && !loading.checkedData) getData()
        return () => { isMounted = false }
    }, [getData, loading.checkedData])

    if (loading.loading) {
        return <LoadingSkeleton />
    }

    return (
        <Sections isEdit />
    )
}
