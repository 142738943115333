import React, { useState, useEffect, useCallback } from "react"
import axios from 'axios'
import _ from "lodash"
import MUIRichTextEditor from "mui-rte"

import { useSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Typography, AccordionDetails, makeStyles, Box } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"

import Utils from "utils/Utils"

const useStyles = makeStyles(() => ({
    rowWrapper: {
        display: "flex",
        flexDirection: "column",
        color: "#666",
        width: '100%',
        "& #title": {
            fontWeight: 500,
        },
        "& #info": {
            marginTop: "10px",
            fontWeight: 300,
            "& span": {
                fontSize: "0.875rem",
            },
            "& #mui-rte-container": {
                margin: 0,
            },
            "& #mui-rte-editor-container": {
                margin: 0,
                padding: 0,
            },
        },
    },
}));

const RowWrapper = ({ title, info, children, customStyles }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const validInfo = info ? info : t("DAM:Sem nome")

    const getInfo = () => {
        if (Array.isArray(validInfo)) {
            return validInfo.join(", ")
        } else {
            return validInfo
        }
    }

    return (
        <div className={classes.rowWrapper} style={customStyles}>
            <Typography component="div" variant="body2" id="title">
                {title}
            </Typography>
            <Typography component="div" variant="body2" id="info">
                {children ? children : getInfo()}
            </Typography>
        </div>
    )
}

export default function DetailItemAccordion({ idItem = '' }) {
    const [state, setState] = useState({ data: [], loading: true })
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)

    const { t } = useTranslation()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const getData = useCallback(() => {
        axios
            .get(`${pathServer}/item/${idItem}/information`, requestHeaders)
            .then(({ data }) => {
                setState({
                    data: {
                        name: _.get(data, 'name', ''),
                        description: _.get(data, 'description', {}),
                        metadata: _.get(data, 'metadata', []),
                        ...(data.start_date && { start_date: data.start_date }),
                        ...(data.validity_date && { validity_date: data.validity_date }),
                    },
                    loading: false
                })
            })
            .catch((err) => {
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("manager:Erro inesperado. Contate o suporte")
                setState(({ data: [], loading: false }),
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    })
                )
            })
    }, [pathServer, requestHeaders, enqueueSnackbar, t, idItem])

    useEffect(() => {
        let isMounted = true
        if (isMounted) getData()
        return () => { isMounted = false }
    }, [getData])

    const getDateInitialOrFinal = useCallback(() => {
        if (state.data.start_date && state.data.validity_date) {
            return `${Utils.formatDate(state.data.start_date)} ${t('common:até')} ${Utils.formatDate(state.data.validity_date)}`
        }
        if (state.data.start_date && !state.data.validity_date) {
            return Utils.formatDate(state.data.start_date)
        }
        if (!state.data.start_date && state.data.validity_date) {
            return Utils.formatDate(state.data.validity_date)
        }
    }, [state.data.start_date, state.data.validity_date, t])

    return (
        <AccordionDetails>
            <Box className={classes.rowWrapper}>
                {
                    state.loading ? (
                        <Box style={{ width: '100%' }}>
                            <Skeleton variant="text" width="30%" />
                            <Skeleton variant="text" width="90%" />
                            <Skeleton variant="text" width="30%" />
                            <Skeleton variant="text" width="90%" />
                        </Box>
                    ) : (
                        <>
                            <RowWrapper title={t("common:Nome")} info={_.get(state, 'data.name', '')} />
                            {_.get(state, 'data.description') && (
                                <RowWrapper title={t("common:Descrição")} customStyles={{ marginTop: 12 }}>
                                    <MUIRichTextEditor
                                        defaultValue={Utils.getFormattedDescription(_.get(state, 'data.description', ''), t)}
                                        inlineToolbar={false}
                                        toolbar={false}
                                        readOnly={true}
                                        style={{ fontSize: 12 }}
                                    />
                                </RowWrapper>
                            )}

                            {
                                (_.get(state, 'data.start_date') || _.get(state, 'data.validity_date')) &&
                                <RowWrapper
                                    title={t("common:Vigência de uso do item")}
                                    info={getDateInitialOrFinal()}
                                    customStyles={{ marginTop: 12 }}
                                />
                            }

                            {_.get(state, 'data.metadata') &&
                                _.get(state, 'data.metadata', []).map((item, index) => (
                                    <RowWrapper
                                        title={_.get(item, "label", _.get(item, "name", "no-label"))}
                                        info={_.get(item, "type", "") === 'date' ? Utils.formatDate(_.get(item, "value", "")) : _.get(item, "value", "no-value")}
                                        key={index}
                                        customStyles={{ marginTop: 12 }}
                                    />
                                ))}
                        </>
                    )
                }
            </Box>
        </AccordionDetails>
    );
}