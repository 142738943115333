import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useSnackbar } from 'notistack'
import Settings from "../../utils/Settings";

import AccessModerate from "../../components/AccessModerate";

import Loading from "../../components/Loading";

const AccessModerateContainer = ({ t }) => {
  let history = useHistory();

  const { region } = useSelector((state) => state.appReducer.userData);
  const { client_slug } = useSelector((state) => state.appReducer.data);
  const { requestHeaders } = useSelector((state) => state.appReducer);
  const [loading, setLoading] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [token, setToken] = useState("");
  const [regionLogged, setRegionLogged] = useState("");
  const [loggedStatus, setLoggedStatus] = useState(
    !!localStorage.getItem("user_auth")
  );
  const params = new URLSearchParams(history.location.search);
  const action = params.get("action");
  const user = params.get("user");
  const { enqueueSnackbar } = useSnackbar()

  const moderateAccess = useCallback(async (source) => {
    let requestHeadersToken;
    requestHeaders.cancelToken = source.token

    if (token) {
      requestHeadersToken ={
        headers: {
          ...requestHeaders.headers,
          Authorization: `Bearer ${token}`,
        }
      };
    }
    setLoading(true);
    try {
      const response = await axios
      .post(
        `${Settings.URL_API}/api/${client_slug}/${region ? region : regionLogged}/moderate/${action}/${user}`,
        {},
        token ? requestHeadersToken : requestHeaders
      )
      setResponseMsg(response.headers["x-message"]);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error)
        return
      }

      if (error.response.status === 401) {
        setLoggedStatus(false)
      }
      setResponseMsg(error.response.data.message || error.response.headers["x-message"]);
      setLoading(false);
      throw error

    }
  }, [action, client_slug, region, regionLogged, requestHeaders, token, user])

  useEffect(() => {
    if (loggedStatus) {
      const source = axios.CancelToken.source()
      moderateAccess(source);
      return () => {
        source.cancel()
      }
    }
  }, [loggedStatus, moderateAccess]);

  const submitLogin = ({ email, password, csrf_token }) => {
    setLoading(true);

    axios
      .post(
        `${Settings.URL_API}/api/${client_slug}/login`,
        {},
        {
          auth: {
            username: email,
            password: password
          },
          headers: { 'X-XSRF-TOKEN': csrf_token}
        }
      )
      .then((resp) => {
        setRegionLogged(resp.data.region.slug)
        setToken(resp.data.token);
        setLoggedStatus(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoggedStatus(false);
        setLoading(false);
        enqueueSnackbar(err.response.data.message || err.response.headers["x-message"], {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('common:Solicitação de acesso')}</title>
      </Helmet>
      <Loading showLoading={loading} />
      <AccessModerate
        handleSubmit={submitLogin}
        loggedStatus={loggedStatus}
        loading={loading}
        message={responseMsg}
        history={history}
      />
    </React.Fragment>
  );
};

export default withTranslation(["login", "common"])(AccessModerateContainer);
