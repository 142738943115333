import React from "react";
import axios from "axios/index";
import 'react-toastify/dist/ReactToastify.css';
import Template from "../Template";
import Loading from "components/Loading";
import { WrapperPaperForms } from 'components/WrapperForms'
import { 
  Grid, 
  Button, 
  TextField, 
 } from "@material-ui/core";
import {pick} from 'ramda';

import connect from "react-redux/es/connect/connect";
import {mapStateToPropsToSettings, mapDispatchToPropsToSettings} from "utils/Flux";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import Settings from 'utils/Settings'
import Utils from "utils/Utils"

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      dataForm: {},
      values: [],
      formValid: true,
      id: props.userId,
      description: "",
      formErrors: {
        name: "",
        email: "",        
      },
      email: "",
      emailValid: true,
      name: "",
      nameValid: true,     
      expiry_date: ''
    };
    this.formSubmit = this.formSubmit.bind(this);
    this.validateField = this.validateField.bind(this);  
  }

  componentDidMount() {
    this.getData();
  }

  ajaxFail = function (error, SELF) {
    SELF.setState({ ...SELF.state, loading: false });

    this.props.enqueueSnackbar(error.response.headers['x-message'], {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    })
  };

  ajaxSuccess = function (resp, SELF) {
    SELF.setState({ ...SELF.state, loading: false });

    this.props.enqueueSnackbar(resp.headers['x-message'], {
      ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
    })
  };

  getData() {
    const SELF = this;
    const {requestHeaders, pathServer, userId} = SELF.props;
    const fields = ['name', 'email', 'id', 'expiry_date'];
    axios.get(`${pathServer}/user/${userId}`, requestHeaders)
      .then(function (resp) {
        const DATA = resp.data.user || [];

        SELF.setState({ ...SELF.state, ...pick(fields, DATA), loading: false });
      }, (resp) => SELF.ajaxFail(resp, SELF))
      .catch((err) => SELF.props.enqueueSnackbar(err.response.headers['x-message'], {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }));
  }

  formSubmit(e) {
    e.preventDefault();
    const SELF = this;
    const {t} = SELF.props;
    if (!SELF.state.formValid) {
      SELF.props.enqueueSnackbar(t("common:O formulário tem erros"), {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      })
      return false;
    }

    const {requestHeaders, pathServer, userId} = SELF.props;
    setTimeout(function () {
      SELF.setState({ ...SELF.state, loading: true });
      const fields = ['name', 'id'];
      const data = pick(fields, SELF.state);
      axios.put(pathServer + '/account/' + userId, data, requestHeaders)
        .then((resp) => SELF.ajaxSuccess(resp, SELF), (resp) => SELF.ajaxFail(resp, SELF))
        .catch((err) => SELF.props.enqueueSnackbar(err.response.headers['x-message'], {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }));
    }, 500);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => {
      this.validateField(name, value)
    });
  }

  handleBlurField(e) {
    const name = e.target.name;
    let value = e.target.value;
    this.validateField(name, value);
  }

 

  validateField(fieldName, value) {
    const {t} = this.props;
    let {formValid, nameValid, emailValid} = this.state;
    let fieldValidationErrors = this.state.formErrors;
    switch (fieldName) {
      case 'name':
        nameValid = value.length >= 3;
        fieldValidationErrors.name = nameValid ? '' : '(*) ' + t('common:Nome inválido.');
        formValid = false;
        break;
      case 'email':
        emailValid = value.length >= 3;
        fieldValidationErrors.email = emailValid ? '' : '(*) ' + t('common:E-mail inválido');
        formValid = false;
        break;
      default:
        break;
    }
    this.setState({
      ...this.state, formValid, nameValid, emailValid, formErrors: fieldValidationErrors
    }, () => this.validateForm());
  }

  validateForm() {
    this.setState({
      formValid: this.state.nameValid && this.state.emailValid
    });
  }

  classError(error) {
    return (error.length === 0 ? 'form-control' : 'form-control has-error');
  }

  render() {
    const {t} = this.props;

    return (      
      <Template area={10} localHistory={this.props.history} customClassName={"container-center"}>
        <Loading showLoading={this.state.loading}/>
        
        <WrapperPaperForms title={t("common:Edição de dados da conta")}>
            <Grid item xs={12} style={{marginBottom: 8}}>
              <TextField                
                name="name"
                label={`${t("common:Nome")}*`}
                variant="outlined"
                value={this.state.name}
                onChange={(event) => this.handleUserInput(event)}
                onBlur={(event) => this.handleBlurField(event)}
                error={Boolean(this.state.formErrors.name)}
                helperText={this.state.formErrors.name ? this.state.formErrors.name : ''}
              />
            </Grid>

            <Grid item xs={12} style={{marginBottom: 8}}>
              <TextField
                disabled
                name="email"
                label={`${t("common:E-mail")}*`}
                variant="outlined"
                value={this.state.email}                
                error={Boolean(this.state.formErrors.email)}
                helperText={this.state.formErrors.email ? this.state.formErrors.email : ''}
              />
            </Grid>
           
            <Grid item xs={12}>
              <TextField
                disabled
                id="expiry_date"
                label={t("common:Expiração do login")}
                variant="outlined"
                value={
                  this.state.expiry_date
                    ? Utils.formatDate(this.state.expiry_date)
                    : t("common:Sem expiração")
                }
              />
            </Grid>
            <Grid item xs={12} className='paper-actions'>
              <Button             
                variant="contained"
                color="primary"
                onClick={this.formSubmit}
              >
                {t("common:Salvar")}
              </Button>          
            </Grid>
        </WrapperPaperForms>
        
          
     
      </Template>   
    );
  }
}

export default connect(mapStateToPropsToSettings, mapDispatchToPropsToSettings)(withTranslation(['login', 'common'])(withSnackbar(Account)))