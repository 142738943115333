import React from 'react'
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDataEditFiles } from './context'
import CurrentFiles from './CurrentFiles'
import ContentBucket from 'pages/DAM/Bucket/Content'
import ShowSelectedAssets from './SelectedAssets/ShowSelectedAssets'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  overrideBucket: {
    "& #dividerFiltersBucket": {
      height: 0
    },
    "& .areaBoundaries": {
      overflow: "auto",
      height: "calc(100vh - 164px)"
    }
  }
}))

const ComponentsView = ({ slugItem, policies }) => {
  const { componentStatus } = useDataEditFiles()
  const history = useHistory()
  const classes = useStyles()

  switch (componentStatus.current) {
    case 'search_bucket':
      return (
        <>
          <Grid className={classes.overrideBucket}>
            <ContentBucket
              policies={policies}
              history={history}
              notUplaodField
              simpleBucket
              isDialog
            />
          </Grid>
        </>
      )
    case 'show_selected':
      return <ShowSelectedAssets />
    default:
      return <CurrentFiles slugItem={slugItem} />
  }
}

ComponentsView.defaultProps = {
  slugItem: ""
}

ComponentsView.propTypes = {
  slugItem: PropTypes.string,
  policies: PropTypes.array,
}

export default ComponentsView