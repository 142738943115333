import React, { useState, useCallback } from "react";
import axios from "axios"
import _ from 'lodash'
import Icon from "@mdi/react"
import { useDropzone } from 'react-dropzone'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";

import {
  Button,
  Typography,
  Grid,
  DialogTitle,
  Box,
  Dialog,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress"

import { mdiTextBox } from "@mdi/js"
import { SelectPublished } from 'components/ComboBox/SelectPublished'
import Utils from 'utils/Utils'

import { useStyles } from "./styles"

const CsvDropzone = ({
  onSuccess,
  handleClose,
  idToUpload = '',
  data,
  handleChangeSelectPublished
}) => {
  const [state, setState] = useState({
    data: null, loading: false
  })
  const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { t } = useTranslation()

  const showMessage = useCallback(message =>
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    }), [enqueueSnackbar])

  const onDropAccepted = useCallback(files => {
    const formData = new FormData();
    formData.append("csv_file", files[0])
    setState(prev => ({ ...prev, data: formData, path: files[0].path }))
  }, [])

  const handleSubmit = useCallback(() => {
    const formData = state.data
    if (_.get(data, 'hasDate', false)) {
      if (data.validityDate) {
        formData.append("validity_date", _.get(data, 'validityDate', ''))
      }
      if (data.startDate) {
        formData.append("start_date", _.get(data, 'startDate', ''))
      }
    } else {
      if (!_.get(data, 'validityDate', '') && _.get(data, 'startDate', '')) {
        formData.append("published", true)
      }
      formData.append("published", _.get(data, 'isPublished', false))
    }

    const options = {
      headers: (requestHeaders)["headers"],
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        console.log("percentCompleted", percentCompleted);
      }
    }
    const callback = () => {
      axios.post(`${pathServer}/category/${idToUpload}/upload`, formData, options)
        .then(() => setState({ loading: false, data: null }, onSuccess()))
        .catch(err => {
          const callback = () => {
            console.log(err)
            showMessage(t('Erro ao carregar os dados, contate o suporte'))
            handleClose()
          }
          setState({ loading: false, data: null }, callback())
        })
    }
    setState(prev => ({ ...prev, loading: true }), callback())
  }, [data, state.data, handleClose, idToUpload, onSuccess, pathServer, requestHeaders, showMessage, t])

  const formValidationAndSubmit = useCallback(() => {
    let error = ""
    const startDate = _.get(data, 'startDate', "")
    const validityDate = _.get(data, 'validityDate', "")

    if (_.get(data, 'hasDate', false)) {
      if (!Boolean(startDate) && !Boolean(validityDate)) {
        error = t('common:Insira uma data')
      }
      if (Boolean(startDate) && Boolean(validityDate)) {
        if (Utils.checkIfDateIsAfter(startDate, validityDate)) {
          error = t('common:Data final deve ser maior')
        }
      }
    }

    if (Boolean(error)) {
      handleChangeSelectPublished({ error })
    } else {
      handleChangeSelectPublished({ error: "" }, handleSubmit)
    }
  }, [handleSubmit, data, handleChangeSelectPublished, t])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"]
    },
    multiple: false,
    onDropAccepted,
    onDropRejected: () => showMessage(t('Arquivo inválido. Somente um arquivo em formato CSV permitido.'))
  })

  return (
    <>
      {
        state.loading ? (
          <Grid item xs={12}>
            <Box className={classes.contentFile}>
              <Box className="content">
                <CircularProgress />
              </Box>
            </Box>
          </Grid>
        ) : (
          <>
            {
              !_.get(state, 'path', false) ? (
                <Grid item xs={12} {...getRootProps()}>
                  <Box className={classes.content}>
                    <input {...getInputProps()} />
                    <Typography variant='subtitle1' style={{ fontWeight: 500, color: "#666" }}>
                      {t("manager:Clique aqui ou arraste um arquivo para enviar")}
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box className={classes.contentFile}>
                    <Box className="content">
                      <Icon path={mdiTextBox} color="#666" width={32} height={32} className="icon" />
                      <Typography variant='subtitle1'>{_.get(state, 'path', '')}</Typography>
                    </Box>
                  </Box>
                </Grid>
              )
            }
          </>
        )
      }

      <Grid item xs={12} className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
          style={{ height: 40, width: 115, marginRight: 20 }}
        >
          {t(`common:Cancelar`)}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={formValidationAndSubmit}
          style={{ height: 40, width: 115 }}
          disabled={!_.get(state, 'path', false) || state.loading}
        >
          {t(`common:Enviar`)}
        </Button>
      </Grid>
    </>
  )
}

const DialogUploadCsv = ({
  open,
  idToUpload,
  onSuccess,
  handleClose
}) => {
  const [state, setState] = useState({
    startDate: "",
    validityDate: "",
    hasDate: false,
    isPublished: true
  })

  const classes = useStyles()
  const { t } = useTranslation()

  const handleChangeSelectPublished = useCallback((newState, callback = () => { }) => {
    setState(prev => ({ ...prev, ...newState }), callback())
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.root}
      maxWidth='md'
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Box className="info">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 548.176 548.176">
            <path d="M524.326 297.352c-15.896-19.89-36.21-32.782-60.959-38.684 7.81-11.8 11.704-24.934 11.704-39.399 0-20.177-7.139-37.401-21.409-51.678-14.273-14.272-31.498-21.411-51.675-21.411-18.083 0-33.879 5.901-47.39 17.703-11.225-27.41-29.171-49.393-53.817-65.95-24.646-16.562-51.818-24.842-81.514-24.842-40.349 0-74.802 14.279-103.353 42.83-28.553 28.544-42.825 62.999-42.825 103.351 0 2.474.191 6.567.571 12.275-22.459 10.469-40.349 26.171-53.676 47.106C6.661 299.594 0 322.43 0 347.179c0 35.214 12.517 65.329 37.544 90.358 25.028 25.037 55.15 37.548 90.362 37.548h310.636c30.259 0 56.096-10.711 77.512-32.12 21.413-21.409 32.121-47.246 32.121-77.516-.003-25.505-7.952-48.201-23.849-68.097zm-161.597-7.704c-1.813 1.804-3.949 2.707-6.42 2.707h-63.953v100.502c0 2.471-.903 4.613-2.711 6.42-1.813 1.813-3.949 2.711-6.42 2.711h-54.826c-2.474 0-4.615-.897-6.423-2.711-1.804-1.807-2.712-3.949-2.712-6.42V292.355H155.31c-2.662 0-4.853-.855-6.563-2.563-1.713-1.714-2.568-3.904-2.568-6.566 0-2.286.95-4.572 2.852-6.855l100.213-100.21c1.713-1.714 3.903-2.57 6.567-2.57 2.666 0 4.856.856 6.567 2.57l100.499 100.495c1.714 1.712 2.562 3.901 2.562 6.571-.001 2.469-.904 4.618-2.71 6.421z" fill="#fff" />
          </svg>
          <Typography variant="body" component="span">
            {t("DAM:Importar arquivo CSV")}
          </Typography>
        </Box>
      </DialogTitle>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body" component="span" className='label'>
            {t("common:Em relação a publicação, o item deverá ser criado como")}
          </Typography>
        </Grid>
        <SelectPublished
          canPublish
          handleChange={handleChangeSelectPublished}
          valueDateDefault={state}
        />
        <CsvDropzone
          onSuccess={onSuccess}
          handleClose={handleClose}
          idToUpload={idToUpload}
          data={state}
          handleChangeSelectPublished={handleChangeSelectPublished}
        />
      </Grid>
    </Dialog>
  );
};

export default DialogUploadCsv
