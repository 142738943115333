export const settings = {
  area: 9,
  route: "advanced-settings",
  entity: "recipe",
  title: {
    singular: "Especificações de imagem",
  },
  page: 0,
  initial_page: 0,
  isNotPageOne: true
}