import React from "react";
import axios from "axios/index";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Template from "../Template";
import Loading from "../../../components/Loading";

import ProgressDialog from "../../../components/Dialogs/ProgressDialog";
import { withTranslation } from 'react-i18next';
import connectHOC from '../Core/ConnectContainer';

import DialogDeleteValidation from '../../../components/Dialogs/DeleteValidationPhrase'

import { 
  Grid, 
  Button, 
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment
 } from "@material-ui/core";

import { WrapperPaperForms } from '../../../components/WrapperForms'
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';

class EditDivisao extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",  
      cod_external_segment: "",      
      loading: true,
      data: {},
      dataForm: {},
      values: [],
      formErrors: {},
      formValid: true,
      id: "",
      nameToDeleteUpdate: "",
      selectedOption: null,
      progress: {
        dialogOpen: false,
        confirm: true,
        value: 0,
        total: 0,
        message: '',
        loading: false,
      },
      pusher_connected: false
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.validateField = this.validateField.bind(this);

    this.close = this.close.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const ID = this.props.match.params.id;
    this.setState({
      ...this.state,
      id: ID,
    });
    if (ID) this.getData(ID);
   
    this.setMaintenance();
  }

  componentWillUmmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    this.checkPusherConnected();
  }

  setMaintenance() {
    const SELF = this;
    const {bindToChannel} = SELF.props;
    const channel = "private-maintenance";

    bindToChannel(channel, "started", (msg) => {
      if(msg.data.id === SELF.state.id && SELF.state.progress.total > 0) {
        SELF._isMounted && SELF.setState({...SELF.state, progress: { dialogOpen: true, loading: true, message: this.state.progress.message, total: this.state.progress.total, value: this.state.progress.value}});
      }
    });

    bindToChannel(channel, "updated", (msg) => {
      if(msg.data.id === SELF.state.id && SELF.state.progress.total > 0) {
        SELF._isMounted && SELF.setState({...SELF.state, progress: { dialogOpen: true, value: msg.data.counter, total: this.state.progress.total, loading: true, message: `Alterando items: ${msg.data.counter} de ${this.state.progress.total}`}});
      }
    });

    bindToChannel(channel, "ended", (msg) => {
      if(msg.data.id === SELF.state.id && SELF.state.progress.total > 0) {
        SELF._isMounted && SELF.setState({...SELF.state, progress: {dialogOpen: true, value: 0, loading: false, total: 0, message: "Operação concluída."}});
        SELF.props.channelUnsubscribe(channel);
      }
    });

    this.checkPusherConnected();
  }

  checkPusherConnected() {
    const SELF = this;
    const {channels_connected} = SELF.props;
    const {pusher_connected} = SELF.state;
    const channel = "private-maintenance";

    if(channels_connected.includes(channel) && !pusher_connected) {
      SELF.setState({
        ...SELF.state,
        pusher_connected: true
      });
    }
  }

  getData(_id) {
    const SELF = this;
    const {t} = SELF.props;

    axios.get(
      localStorage.getItem("path") + "/division/" + _id,
      {
        headers: {
          "Authorization": localStorage.getItem("user_token"),
          "device": "desktop"
        }
      })
      .then(function (resp) {
        const DATA = resp.data.item || [];
        let arrFilesPreview = [];
        if (DATA.urls.thumbnail.original) {
          arrFilesPreview.push({path: DATA.urls.thumbnail.original});
        }
        SELF._isMounted && SELF.setState({
          ...SELF.state,
          loading: false,
          name: DATA.name,
          nameToDeleteUpdate: DATA.name,
          cod_external_segment: DATA.cod_external_segment,
          id: DATA.id,         
          picturesPreview: arrFilesPreview
        });
      }, function (resp) {
        SELF._isMounted && SELF.setState({
          ...SELF.state,
          loading: false,
        });
        toast.error(resp.headers['x-message']);
      })
      .catch(function (err) {
        console.log(err);
        toast.error(t('Erro ao carregar os dados, contate o suporte'));
      });
  }

  close() {
    this.props.history.push(`/manager/divisions`);
  }

  handleUpdate() {
    const SELF = this;
    const {t} = SELF.props;
    const {pusher_connected} = SELF.state;

    setTimeout(function () {     
        SELF._isMounted && SELF.setState({
          ...SELF.state,
          loading: true,
          progress: {
            dialogOpen: pusher_connected,
            confirm: false,            
            message: SELF.props.t('manager:Alterando Divisão'),
            loading: true
          }
        });
        let data = {
          "name": SELF.state.name,
          "cod_external_segment": SELF.state.cod_external_segment
        };
        axios.put(
          localStorage.getItem("path") + '/division/' + SELF.state.id, data,
          {
            headers: {
              "Authorization": localStorage.getItem("user_token"),
              "device": "desktop"
            }
          })
          .then(function (resp) {
            if(resp.data) {
              if(resp.data.items && pusher_connected) {
                SELF._isMounted && SELF.setState(
                  {...SELF.state
                    , progress : {
                        dialogOpen: true
                      , message: `${t('Alterando segmentos e itens')}: 0 ${t('common:de')} ${resp.data.items}`
                      , total: resp.data.items, loading: true
                    }
                  });
              } else {
                SELF._isMounted && SELF.setState(
                  {...SELF.state
                    , progress: {
                        dialogOpen: true
                      , message: t("manager:Itens da divisão serão alterados.")
                      , loading: false
                    }
                  });
              }
            } else {
              toast.success(
                resp.headers['x-message'],
                {
                  autoClose: 1500,
                  onClose: () => {
                    SELF.props.history.push("/manager/divisions/list");
                  }, pauseOnHover: false
                });
            }
          }, function (resp) {
            SELF._isMounted && SELF.setState({
              ...SELF.state,
                progress: {
                    dialogOpen: true
                  , message: ""
                  , loading: false
                },
              loading: false
            });
            toast.error(resp.headers['x-message']);
          })
          .catch(function (error) {
            SELF._isMounted && SELF.setState({
              ...SELF.state,
              progress: {
                  dialogOpen: true
                , message: ""
                , loading: false
              },
              loading: false
            });
            toast.error(t('common:Erro ao processar, contate o suporte'));
          });     
    }, 500);
  }

  handleCancel() {
    this._isMounted && this.setState(
      {...this.state
        , progress : {
            dialogOpen: false
          , confirm: false
          , message: ""
          , total: 0
          , loading: false
        }
      });
  }

 formSubmit(e) {
    const {t} = this.props;
    e.preventDefault();

    const data = {
      "name": this.state.name,
      "cod_external_segment": this.state.cod_external_segment
    };
    
    const formValid = this.validateField(data)
    
    if (!formValid) {     
      return false;
    }

    this._isMounted && this.setState(
      {...this.state
        , progress : {
            dialogOpen: true
          , confirm: true
          , message: t("Confirmação")
        }
      });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }  
 

  validateField(parameters) {
    const { t } = this.props;
    let fieldValidationErrors = this.state.formErrors;

    Object.entries(parameters).forEach(([key, value]) => {
      switch (key) {
        case 'name':
          fieldValidationErrors.name = value.length >= 3 ? '' : '* ' + t('common:Este campo deve conter mais que 3 caracteres');
          break;
        default:
          break;
      }
    })

    const formErrors = {
      ...this.state.formErrors,
      ...fieldValidationErrors
    }
    
    const getFormValid = (() => {
      let formValid = true

      for (var value in formErrors){      
        if(formErrors[value] && formErrors[value].trim().length > 0) {
          formValid = false
        }
      }
      return formValid
    })()  

    this.setState({
      ...this.state,
      formErrors,
      formValid: getFormValid,
    });

    return getFormValid
  }

  errorClass(error) {
    return (error.length === 0 ? 'form-control' : 'form-control has-error');
  }


  handleDelete = (e) => {
    const SELF = this;
    SELF._isMounted && SELF.setState({
      ...SELF.state,
      loading: true
    });
    axios.delete(
      localStorage.getItem("path") + "/division/" + SELF.state.id,
      {
        headers: {
          "Authorization": localStorage.getItem("user_token"),
          "device": "desktop"
        }
      })
      .then(function (resp) {
        SELF._isMounted && SELF.setState({
          ...SELF.state,
          loading: false,
        }, () => SELF.props.history.push("/manager/divisions/list"));
        toast.success(resp.headers['x-message']);
      }, function (err) {
        if (err.response.data.hasOwnProperty("errors")) {
          Object.values(err.response.data.errors).forEach((item) => {
            item.forEach((message) => {
              toast.error(message)
            })
          })
        } else {
          Object.values(err.response.data).forEach((item) => {
            toast.error(item)
          })
        }

        SELF._isMounted && SELF.setState({
          ...SELF.state,
          loading: false,
        });
      })
      .catch(function (err) {
        console.log(err);
        toast.error("Erro ao deletar os dados, contate o administrador.");
      });

  };

  render() {
    const {t} = this.props;
   
    return (
      <Template area={1} localHistory={this.props.history} customClassName={"container-center"}>
        <Loading showLoading={this.state.loading}/>
        <WrapperPaperForms title={t("manager:Edição de divisão")}>
          <Grid item xs={12}>
            <TextField              
              name="name"
              label={`${t("Nome da divisão")}*`}
              variant="outlined"
              value={this.state.name}
              onChange={(event) => this.handleUserInput(event)}                       
              error={Boolean(this.state.formErrors.name)}
              helperText={this.state.formErrors.name ? this.state.formErrors.name : ''}
            />
          </Grid>
         
          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-cod_external_segment">{t("common:Código externo segmento")}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cod_external_segment"
                type="text"
                name="cod_external_segment"
                value={this.state.cod_external_segment}
                onChange={(event) => this.handleUserInput(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <VpnKeyRoundedIcon className='color-666' />
                  </InputAdornment>
                }
                labelWidth={187}
              />
            </FormControl>
          </Grid>
          <div className='paper-actions'>
                <DialogDeleteValidation entity={t("common:Divisão")} name={this.state.name}>
                  {(confirm) => (
                       <Button
                          style={{
                            height: 40,
                            width: 115,
                            marginRight: 20,
                            color: '#F4511E',
                            border: '1px solid #F4511E'
                          }}
                          variant="outlined"
                          onClick={confirm(this.handleDelete)}
                          disabled={this.state.loading}
                        >
                       {t("common:Excluir")}
                     </Button>
                  )}
                </DialogDeleteValidation>
                <Button
                  style={{height: 40, width: 115,  marginRight: 20}}
                  variant="outlined"
                  color="primary"
                  onClick={() => this.props.history.push("/manager/divisions/list")}>
                  {t("common:Voltar")}
                </Button>
                <Button
                  style={{height: 40, width: 115,}}
                  variant="contained"
                  color="primary"
                  onClick={this.formSubmit}
                  disabled={this.state.loading}
                >
                  {t("common:Salvar")}
                </Button>
            </div>
        </WrapperPaperForms>      
        <ProgressDialog
            handleClose={this.close}
            loading={this.state.progress.loading}
            confirm={this.state.progress.confirm}
            handleConfirm={this.handleUpdate}
            handleCancel={this.handleCancel}
            open={this.state.progress.dialogOpen}
            progress={this.state.progress.value}
            totalProgress={this.state.progress.total}
            title={this.state.progress.message}
            entity={t("common:Divisão")}
            entityName={this.state.nameToDeleteUpdate}
          />
      </Template>
    );

  }

}

export default connectHOC(withTranslation(['manager', 'common'])(EditDivisao))
