import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"

import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"

import {
    IconButton,
    MenuItem,
    Menu,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { useDataToolDetails } from '../context'

const MenuRow = ({ commentId }) => {
    const [anchorEl, setAnchorEL] = useState(null)
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
    const { getDataAllComments } = useDataToolDetails()
    const match = useRouteMatch()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const handleClickDeleteMenu = (evt) => {
        setAnchorEL(evt.currentTarget)
    }

    const handleCloseDeleteMenu = () => {
        setAnchorEL(null)
    }

    const handleDeleteComment = useCallback((evt) => {
        evt.preventDefault()
        axios
            .delete(`${pathServer}/tool/${match.params.slug}/record/${match.params.id}/comment/${commentId}/deleteComment`, requestHeaders)
            .then(() => {
                setAnchorEL(null)
                getDataAllComments()
            })
            .catch((err) => {
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("manager:Erro inesperado. Contate o suporte")
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
            })
    }, [pathServer, match.params.slug, match.params.id, enqueueSnackbar, requestHeaders, t, commentId, getDataAllComments])


    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '0 0 10px 15px' }}>
            <IconButton style={{ padding: 3, marginBottom: 5 }} onClick={handleClickDeleteMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseDeleteMenu}
            >
                <MenuItem onClick={handleDeleteComment}>
                    <DeleteIcon style={{ color: '#666', marginRight: 5 }} />
                    {t('common:Excluir')}
                </MenuItem>
            </Menu>
        </div>
    )
}

export default MenuRow

