import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"
import DialogConfirm from "../../../../components/Alert/DialogConfirm"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import ZoomCheck from "components/Dialogs/ZoomCheck"
import ZoomIn from "@material-ui/icons/ZoomIn"
import Settings from "utils/Settings"

import SettingsBackupRestoreRoundedIcon from "@material-ui/icons/SettingsBackupRestoreRounded"

import {
  IconButton,
  CardContent,
  Typography,
  CardActions,
  Button,
  Tooltip,
} from "@material-ui/core"

import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded"
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded"
import _ from "lodash"
import DeleteValidationPhrase from '../../../../components/Dialogs/DeleteValidationPhrase'

const CardErrorDetails = ({
  handlerReprocess,
  isManager,
  handleConfirmDelete,
  file,
  handleMouseLeave,
  mobile,
  bucketVersion
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
 
  const hasStatusCodeOrMessage = useCallback(() => {
    const code = file.status_integration_code || null
    const message = file.status_integration_message || t("DAM:Erro geral na automação via API.")

    switch (code) {
      case 500:
        return t("DAM:O item não pode ser criado pois há uma falha na comunicação entre os servidores. Favor tente novamente mais tarde.")

      case 401:
        return t("DAM:O item não pode ser verificado pois há uma falha na autenticação entre os servidores. Favor tente novamente mais tarde.")

      case 404:
        return t("DAM:O item não foi encontrado no processo de integração e, portanto, não pode ser criado ou associado. Favor entrar em contato com sua equipe de dados.")

      default:
        return message
    }
  }, [file.status_integration_code, file.status_integration_message, t])

  return (
    <div
      className={classes.rootCardHoverError}
      data-testid='root-info-card-error'
    >
      <div className={classes.icon}>
        <ErrorRoundedIcon />
      </div>
      <CardContent className={classes.cardContent}>
        <Typography align="center" variant="body2">
          {hasStatusCodeOrMessage()}
        </Typography>
        {
          !bucketVersion && (_.get(file, 'status_integration_code', 0) !== -1) &&
          <DialogConfirm
            t={t}
            title={t("common:Reprocessar arquivo")}
            description={t(
              "common:Você tem certeza que deseja reprocessar a integração deste arquivo?"
            )}
            icon={<SettingsBackupRestoreRoundedIcon id="main" />}
            hideCallback={() => handleMouseLeave(false)}
          >
            {(confirm) => (
              <Button
                color="inherit"
                onClick={confirm(() => handlerReprocess(file))}
                style={{
                  width: '100%',
                  maxWidth: 200,
                  textAlign: "center",
                  backgroundColor: "#fff",
                  color: "#E35C33",
                }}
                data-testid='info-card-btn-reprocess'
              >
                {t("common:Tentar novamente")}
              </Button>
            )}
          </DialogConfirm>
        }
      </CardContent>
      <CardActions className={mobile ? "" : classes.actions}>
        {isManager && !mobile && !bucketVersion && (
          <DeleteValidationPhrase
            entity={t("common:Arquivo")}
            name={_.get(file, "client_name", "")}
            hideCallback={() => handleMouseLeave(false)}
          >
            {(confirm) => (
              <Tooltip title={t("common:Excluir arquivo")} placement="bottom">
                <IconButton
                  onClick={confirm(() => handleConfirmDelete(file))}
                  style={{
                    padding: "5px",
                  }}
                >
                  <DeleteRoundedIcon style={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            )}
          </DeleteValidationPhrase>
        )}
        {mobile && (
          <Tooltip title={t("common:Voltar")} placement="bottom">
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                handleMouseLeave(false)
              }}
            >
              <CancelRoundedIcon style={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
        )}

        {Settings.THUMB_EXTENSIONS.includes(file.extension.toUpperCase()) && (
          <ZoomCheck
            image={_.get(file, "thumbnail_zoom_webp", _.get(file, "thumbnail_zoom_original", ""))}
            name={_.get(file, "client_name", "")}
            hideCallback={() => handleMouseLeave(false)}
          >
            {(confirm) => (
              <Tooltip
                title={t("common:Zoom")}
                placement="bottom"
              >
                <IconButton
                  onClick={confirm()}
                  style={{
                    padding: "5px",
                  }}
                >
                  <ZoomIn style={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            )}
          </ZoomCheck>
        )}
      </CardActions>
    </div>
  )
}

export default CardErrorDetails
