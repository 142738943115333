import React, { useState } from "react"
import MUIRichTextEditor from "mui-rte";

import { useTranslation } from "react-i18next"
import { EditorState, convertToRaw } from 'draft-js'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { Button, Typography } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"

import { mdiTextBoxCheck } from '@mdi/js'
import Icon from "@mdi/react"
import { useStyles } from "./styles"

const CommentReprovedDialog = ({ children, transition }) => {
  const [open, setOpen] = useState(false)
  const [callbackState, setCallbackState] = useState(null)
  const [comment, setComment] = useState({ map: EditorState.createEmpty(), text: '' })
  const { t } = useTranslation()
  const classes = useStyles()
  const createTheme = createMuiTheme()

  const handleChange = (editorState) => {
    const dataDescription = convertToRaw(editorState.getCurrentContent())
    let textPerLine = dataDescription.blocks.map(el => el.text)

    setComment({ map: editorState, text: textPerLine.join(' ') })
  }

  const show = (callback) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    }

    setOpen(true)
    setCallbackState(() => (comment) => callback(event, comment))
  }

  const hide = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setOpen(false)
    setCallbackState(null)
  }

  const confirm = (event) => {
    const dataDescription = convertToRaw(comment.map.getCurrentContent())
    let textPerLine = dataDescription.blocks.map(el => el.text)

    const description = {
      text: textPerLine.join(' '),
      formatter: {
        name: "MUI-RTE/DraftJs",
        version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
        format: JSON.stringify(dataDescription)
      },
    }
    callbackState(description)
    hide(event)
  }

  const defaultTheme = Object.assign(createTheme, {
    overrides: {
      MUIRichTextEditor: {
        container: {
          margin: 0
        },
        toolbar: {
          borderBottom: '1px solid #eee',
          padding: '8px 0',
        },
        placeHolder: {
          margin: 0,
          padding: '19px 16px',
        },
        editorContainer: {
          margin: 0,
          padding: '19px 16px',
        }
      }
    }
  })

  return (
    <React.Fragment>
      {children(show)}
      {open && (
        <Dialog
          open={open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.title}>
            <Icon
              path={mdiTextBoxCheck}
              size={2}
              color="#FFF"
              id='main'
            />
              <Typography variant="h6">
                {transition.comments.title}
              </Typography>
            </div>
          </div>
          <DialogContent style={{ padding: "0" }}>
            <Typography variant="body2" style={{ marginBottom: 20 }}>
              {transition.comments.message}
              {
                transition.comments.sub_message && (
                  <>
                  <br /> {transition.comments.sub_message}
                  </>
                )
              }
            </Typography>

            <div style={{ height: "auto" }}>
              <div className={classes.wrapperRichText}>
                <MuiThemeProvider theme={defaultTheme}>
                  <MUIRichTextEditor
                    maxLength={1000}
                    controls={["bold", "italic", "underline", "link"]}
                    label={`${t("DAM:Os materiais enviados")}...`}
                    draftEditorProps={comment.map}
                    onChange={handleChange}
                  />
                </MuiThemeProvider>
              </div>
            </div>
          </DialogContent>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={hide}
              style={{ marginRight: 20 }}
            >
              {t(`common:Cancelar`)}
            </Button>

            <Button
              onClick={confirm}
              variant="contained"
              color="primary"
              disabled={!comment.text}
              data-testid='dialog-confirm-btn-confirm'
            >
              {t(`common:Enviar`)}
            </Button>

          </div>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default CommentReprovedDialog
