import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack"
import axios from 'axios'

import {
    Divider,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Typography,
    Switch
} from '@material-ui/core';

import StepsWrapper from './Steps'
import _ from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'
import Utils from "utils/Utils";

const Shared = ({
    id_item,
    category_id,
    disabled,
    versionIndex,
    data,
    includeFieldMetadata = true
}) => {
    const [state, setState] = useState({
        alternativeName: 'taxonomy-item',
        listrecipe: "download",
        loading: false,
        dataAlternativeNames: [],
        dataListrecipes: [],
        wayToShare: "",
        shareExpirationTime: "24",
        copyLink: data.copyLink,
        include_metadata: false
    })

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation();
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)

    const getData = useCallback(async (categoryId, idItem) => {
        try {
            const resultAlternativeName = await axios.get(`${pathServer}/alternative-name/listByCategory/${categoryId}`, requestHeaders)
            const resultListRecipes = await axios.get(`${pathServer}/item/${idItem}/listrecipes`, requestHeaders)

            setState(prev => ({
                ...prev,
                dataAlternativeNames: _.get(resultAlternativeName, 'data.items', []),
                dataListrecipes: [
                    { id: 'download', title: "common:Ativos originais", hasHighLow: true },
                    { id: 'path_clone', title: "common:Imagens da web em alta", hasHighLow: data.hasHighLow },
                    { id: 'path_low', title: "common:Imagens da web em baixa", hasHighLow: data.hasHighLow },
                    ..._.get(resultListRecipes, 'data.recipes', []).map(i => ({ title: i.name, id: i.slug, hasHighLow: data.hasHighLow }))
                ],
                loading: false,
            }))
        } catch (err) {
            const message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
            setState(prev => ({ ...prev, loading: false }),
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                }))
        }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, data.hasHighLow])

    useEffect(() => {
        let isMounted = true
        if (Boolean(category_id) && Boolean(id_item)) {
            if (isMounted) {
                const callback = () => {
                    getData(category_id, id_item)
                }
                setState(prev => ({ ...prev, loading: true }), callback())
            }
        } else {
            setState(prev => ({ ...prev, loading: false }))
        }
        return () => { isMounted = false }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, getData, category_id, id_item])

    const handleCheck = useCallback((event) => {
        const value = _.get(event, 'target.value', '')
        const name = _.get(event, 'target.name', '')
        setState(prev => ({ ...prev, [name]: value }))
    }, [])
    const handleCheckListrecipe = useCallback((event) => {
        const value = _.get(event, 'target.value', '')
        const name = _.get(event, 'target.name', '')
        setState(prev => ({
            ...prev,
            [name]: value,
            copyLink: (['download', 'path_clone', 'path_low'].includes(value) && data.copyLink)
        }))
    }, [data.copyLink])
    const onChangeIncludeMetadata = useCallback((value) => {
        setState(prev => ({ ...prev, include_metadata: value.target.checked }))
    }, [])

    const componentStep3 = () => {
        return (
            <>
                <FormControl style={{ marginBottom: '15px', width: "100%" }}>
                    <RadioGroup
                        aria-label="wayToShare"
                        name="wayToShare"
                        onChange={(event) => handleCheck(event)}
                        value={state.wayToShare}
                    >
                        <Divider />
                        <FormControlLabel
                            value='link'
                            control={<Radio color="primary" />}
                            label={t('common:Compartilhar por link')}
                            style={{ color: "#666", margin: 0 }}
                        />
                        <Divider />
                        <FormControlLabel
                            value='email'
                            control={<Radio color="primary" />}
                            label={t('common:Compartilhar por e-mail')}
                            style={{ color: "#666", margin: 0 }}
                        />
                        <Divider />
                    </RadioGroup>
                </FormControl>
                <Box style={{ padding: "0 20px 20px", color: "#666" }}>
                    <Typography variant="body2">
                        {t('common:Tempo de expiração')}:
                    </Typography>
                </Box>
                <FormControl style={{ width: "100%" }}>
                    <RadioGroup
                        aria-label="shareExpirationTime"
                        name="shareExpirationTime"
                        onChange={(event) => handleCheck(event)}
                        value={state.shareExpirationTime}
                    >
                        <Divider />
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label={t('common:24 horas')}
                            style={{ color: "#666", margin: 0 }}
                            value="24"
                        />
                        <Divider />
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label={t('common:48 horas')}
                            style={{ color: "#666", margin: 0 }}
                            value="48"
                        />
                        <Divider />
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            label={t('common:72 horas')}
                            style={{ color: "#666", margin: 0 }}
                            value="72"
                        />
                        <Divider />
                    </RadioGroup>
                </FormControl>

            </>
        )
    }

    return (
        <>
            {
                state.loading ? (
                    <>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton variant="rect" width={288} height={40} />
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton variant="rect" width={288} height={40} />
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton variant="rect" width={288} height={40} />
                        </div>
                        <Divider />
                    </>
                ) : (
                    <StepsWrapper
                        id_item={id_item}
                        alternativeNameId={state.alternativeName === "taxonomy-item" ? "" : state.alternativeName}
                        typeListrecipe={state.listrecipe}
                        isLinkShare={(state.wayToShare === "link")}
                        disabled={disabled || !state.wayToShare}
                        versionIndex={versionIndex}
                        data={data}
                        copyLink={state.copyLink}
                        shareExpirationTime={state.shareExpirationTime}
                        includeMetadata={state.include_metadata}
                        componentStep1={
                            <>
                                {
                                    includeFieldMetadata && (
                                        <Box style={{ padding: "0 20px 20px" }}>
                                            <Typography variant='body1' style={{ margin: 0 }}>
                                                {t("common:Informações de metadados")}:
                                            </Typography>
                                            <Box style={{ width: '100%' }}>
                                                <FormControl component="fieldset">
                                                    <Switch
                                                        checked={state.include_metadata}
                                                        onChange={onChangeIncludeMetadata}
                                                        color="primary"
                                                        name="include_metadata"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    )
                                }
                                <FormControl component="fieldset" style={{ overflow: 'auto', width: "100%" }}>
                                    <RadioGroup
                                        aria-label="alternativeName"
                                        name="alternativeName"
                                        value={state.alternativeName}
                                        onChange={(event) => handleCheck(event)}
                                    >
                                        <Divider />
                                        <FormControlLabel value="taxonomy-item" control={<Radio color='primary' />} label={t('manager:Taxonomia do item')} style={{ color: "#666", margin: 0 }} />
                                        <Divider />
                                        {state.dataAlternativeNames.length > 0 &&
                                            state.dataAlternativeNames.map((i) =>
                                                < >
                                                    <FormControlLabel
                                                        key={i.id}
                                                        value={i.id}
                                                        control={<Radio color='primary' />}
                                                        label={i.title}
                                                        style={{ color: "#666", margin: 0 }}
                                                    />
                                                    <Divider />
                                                </>
                                            )}
                                    </RadioGroup>
                                </FormControl>
                            </>
                        }
                        componentStep2={
                            <FormControl component="fieldset" style={{ overflow: 'auto', width: "100%" }}>
                                <RadioGroup
                                    aria-label="listrecipe"
                                    name="listrecipe"
                                    value={state.listrecipe}
                                    onChange={(event) => handleCheckListrecipe(event)}
                                >
                                    {state.dataListrecipes.length > 0 &&
                                        state.dataListrecipes.map((option) => {
                                            if (option.hasHighLow) {
                                                return (
                                                    <>
                                                        <FormControlLabel
                                                            key={option.id}
                                                            value={option.id}
                                                            control={<Radio color='primary' />}
                                                            label={t(`${option.title}`)}
                                                            style={{ color: "#666", margin: 0 }}
                                                        />
                                                        <Divider />
                                                    </>
                                                )
                                            }
                                            return null
                                        })}
                                </RadioGroup>
                            </FormControl>
                        }
                        componentStep3={componentStep3()}
                    />
                )
            }
        </>
    );
}

export default Shared
