import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
    appBar: {        
        padding: "18px 20px",
        boxShadow: 'none',
        minHeight: 100,
        zIndex: 3000
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const HeaderDialog = ({ handleClose, children }) => {
    const classes = useStyles();
    
    return (
        <AppBar className={classes.appBar} position="fixed">
            <Toolbar variant="dense">
                <Avatar
                    alt="Logo Cliente"
                    src={localStorage.getItem("logoInsideJPG")}
                    onClick={handleClose}
                    style={{
                        cursor: "pointer",
                        marginRight: 20,
                        width: 60,
                        height: 60,
                    }}
                />
                <div className={classes.title}>                    
                        {children}                    
                </div>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

HeaderDialog.propTypes = {
    handleClose: PropTypes.func,
}

export default HeaderDialog