import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body, .MuiTypography-body2, .MuiTypography-caption": {
      color: theme.palette.grey700,
    },
    "& .p-h5": {
      color: theme.palette.grey700,
      fontWeight: 500
    },
    "& .name-subtitle": {
      color: theme.palette.grey700,
      fontWeight: 700,
      marginBottom: 16
    },
    "& .wrapperTitles": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: 'center',
      color: "#FFFFFF",
      "& p.title1": {
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: "80px",
        fontSize: "4rem",
        textShadow: "3px 4px 6px rgba(0, 0, 0, 0.67)",
        marginBottom: "10px",
      },
      "& p.title2": {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "1.6em",
        textShadow: "3px 4px 6px rgba(0, 0, 0, 0.25)",
        padding: "0 10px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "@media (max-height: 768px)": {
        "& p.title1": {
          fontSize: "2rem",
          lineHeight: "20px",
        },
        "& p.title2": {
          fontSize: "1rem",
        },
      },
    },
    "& .rootPaper": {
      padding: 40,
      width: 391,
      height: "fit-content",
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        height: '100vh',
        borderRadius: 0,
        boxShadow: 'none'
      }
    },
    "& .wrapperPage": {
      backgroundImage: (props) => `url(${props.originalBg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: '100%',
      minHeight: 'calc(100vh - 64px)',
      padding: '2% 0',
      "& .MuiFormLabel-root.Mui-error, .MuiFormHelperText-root.Mui-error ": {
        color: "#FF0000",
        marginLeft: 0,
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FF0000",
      },
      "& .logoImageLogin": {
        width: "100%",
        maxWidth: 200,
        maxHeight: 200,
        paddingTop: '50%',
        marginBottom: 10,
        backgroundImage: (props) => `url(${props.logoLoginJPG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        "@media (max-height: 650px)": {
          display: "none",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      }
    },
  },
  rootDialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 1130
    },
    "& .MuiTypography-body, .MuiTypography-body2, .MuiTypography-caption": {
      color: theme.palette.grey700,
    },
    "& .header": {
      width: "100%",
      height: 90,
      backgroundColor: "#EEEEEE",
      marginBottom: 2,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: "0 24px",
      "& #header-divider": {
        width: "25%"
      },
      "& #header-main": {
        width: "50%",
        display: 'flex',
        flexDirection: "column",
        alignItems: "center"
      },
      "& #header-action": {
        width: "25%",
        display: 'flex',
        justifyContent: "flex-end"
      },
      [theme.breakpoints.down("sm")]: {
        "& #header-divider": {
          display: "none"
        },
        "& #header-main": {
          width: "100%",
        }
      }
    },
    "& .sub-header": {
      height: 90,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: "0 24px",
      "& #header-main": {
        width: "75%",
        lineHeight: 0
      },
      "& #header-action": {
        width: "25%",
        display: 'flex',
        justifyContent: "center"
      },
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        padding: "16px 24px",
        flexDirection: 'column',
        "& h6": {
          fontSize: "1rem"
        },
        "& #header-main": {
          width: "100%",
        },
        "& #header-action": {
          width: "100%",
        },
        "& button": {
          marginTop: 16

        }
      }
    },
    "& .content-cards": {
      width: "100%",
      backgroundColor: "#EEEEEE",
      padding: "0 24px 24px 24px",
      "& .content-cards-title": {
        height: 60,
        padding: 0,
        [theme.breakpoints.down("sm")]: {
          padding: "16px 24px",
          height: "auto",
        }
      }
    },
    "& .p-h6": {
      color: theme.palette.grey700,
      fontWeight: 500
    },
    "& .image-card": {
      "&:hover": {
        cursor: 'zoom-in'
      }
    },
    " & .p-link-footer": {
      textDecoration: `underline ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      display: "inline-block",
    },
    "& .footer-card": {
      padding: "8px",
      lineHeight: 0,
      height: 60,
      display: "flex",
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  card: {
    width: 250,
    margin: theme.spacing(1),
  }
}));

