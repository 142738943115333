import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Grid,
  Divider,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from 'react-redux'
import _ from 'lodash'

import PaperTitle from "../PaperTitle";
import PublishIcon from "../../../../imagens/publish.svg"
import CommentIcon from '@material-ui/icons/Comment';
import { useSnackbar } from "notistack";

import { withStyles } from "@material-ui/core/styles"
import DialogPublish from "components/Alert/DialogPublish";
import Utils from 'utils/Utils'
import { useDataAssetIntegra } from "../context"

const ContentSVG = withStyles({
  svg: {
    marginRight: 20,
    filter:
      "invert(39%) sepia(5%) saturate(2%) hue-rotate(8deg) brightness(99%) contrast(89%)",
  },
})(({ classes, thumbnail, title }) => (
  <img
    src={thumbnail}
    alt={title}
    width="48"
    height="48"
    className={classes.svg}
  />
))

const Publish = () => {
  const [state, setState] = useState({ items: [], loading: true })
  const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
  const { stateItem } = useDataAssetIntegra()
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()

  const getData = useCallback(() => {
    axios.get(`${pathServer}/item/publishhistory/${_.get(stateItem, 'data.id', '')}`, requestHeaders)
      .then((resp) => {
        setState({ items: _.get(resp.data, 'history', []), loading: false })
      })
      .catch((err) => {
        setState({ items: [], loading: false })
        const message = err.response
          ? t(err.response.data.slug) || err.response.data.message || err.response.headers["x-message"]
          : t("Falha ao carregar dados")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
        console.log(err)
      });
  }, [pathServer, stateItem, requestHeaders, t, enqueueSnackbar])


  useEffect(() => {
    let isMounted = true
    if (isMounted) getData()
    return () => {
      isMounted = false
    }
  }, [getData])

  return (
    <>
      <PaperTitle
        title={t("common:Publicação do item")}
        iconSVG_IMG={
          <ContentSVG
            thumbnail={PublishIcon}
            title={t("common:Publicação do item")}
          />}
      />

      {state.loading && (
        <>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px 25px"
          }}>
            <CircularProgress />
          </div>
        </>
      )}
      {
        !state.loading && (
          <Grid style={{ padding: 10, maxHeight: 'calc(100vh - 215px)', overflow: 'auto' }}>
            <Typography variant='body1' style={{ fontWeight: 500 }}>
              {t("common:Acompanhe o histórico de publicação do item")}:
            </Typography>
            {
              _.get(state, 'items', []).map((i, k) => {
                return (
                  <Grid style={{ marginTop: 10 }} key={`${k}-${i.event}-${i.created_at}`}>
                    <Grid style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
                      <DialogPublish
                        currentComment={i.comment}
                        t={t}
                        title={t("common:Comentário da publicação")}
                        description={t('common:o item em', { user: i.user, operation: i.event === 'published' ? 'publicou' : 'despublicou', date: Utils.formatDate(i.created_at) })}
                      >
                        {(confirm) => (
                          <IconButton
                            onClick={confirm()}
                            disabled={i.comment ? false : true}
                          >
                            <CommentIcon />
                          </IconButton>
                        )}
                      </DialogPublish>
                      <Typography variant='body2' style={{ marginTop: 7 }}>
                        {t('common:por no dia', {
                          reason: i.reason === 1 ? t('common:pelo-usuario') : t('common:pela-vigencia'),
                          operation: i.event === 'published' ? t('common:Publicado') : t('common:Despublicado'),
                          user: i.user,
                          date: Utils.formatDateFull(i.created_at)
                        })}
                      </Typography>
                    </Grid>
                    <Divider />
                  </Grid>
                )
              })
            }
          </Grid>
        )
      }
    </>
  );
};

export default Publish;
