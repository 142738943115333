import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import arrayMove from "array-move"

import AppActions from "flux/actions/AppActions"
import { useStyles } from "./styles";

import SortableList from 'components/SortableList'

const AssetsdndContainer = ({ handleAddDataTags }) => {
  const dispatch = useDispatch()
  const { markedAssets } = useSelector((state) => state.appReducer)
  const [data, setData] = useState([])
  const [dataTags, setDataTags] = useState([])
  const [hasDataTags, setHasDataTags] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setData(markedAssets)

      if (getDataTags(markedAssets).length > 0 && !hasDataTags) {
        setDataTags(getDataTags(markedAssets))
      }
    }
    return () => { isMounted = false }
  }, [markedAssets, hasDataTags])

  const getDataTags = (data) => {
    let newData = []

    for (var index in data) {
      const id = data[index]['id']
      newData = [
        ...newData,
        { id: id, value: [] }
      ]
    }

    for (var item in data) {
      const id = data[item]['id']
      if (data[item].tags && data[item].tags.length > 0) {
        for (var key in data[item].tags) {
          const find = newData.find(i => i.id === id)
          find.value = [
            ...find.value,
            { label: data[item].tags[key], value: data[item].tags[key], id: id }
          ]
        }
      }
    }
    setHasDataTags(true)
    return newData
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const list = arrayMove(data, oldIndex, newIndex)
    setData(list)
    dispatch(AppActions.markListAssets(list))
  }

  const shouldCancelStart = (e) => {
    e.preventDefault()
    var targetEle = e;
    if (!targetEle.id) {
      targetEle = e.target;
    }
    if (targetEle.id === 'MultiValueRemove') {
      const { attributes: { value: { value } } } = targetEle

      handleChangeRemoveTags(value)
    }
  }

  const handleChangeTags = (value, id) => {
    const data = dataTags.map(i => {
      if (i.id === id && !(i.value.some(i => i.value === value))) {
        i.value = [...i.value, { label: value, value: value, id: id }]
      }
      return i
    })
    handleAddDataTags(data)
    setDataTags(data)
  }


  const handleChangeRemoveTags = (tag) => {
    const { value, id } = JSON.parse(tag)

    const data = dataTags.map(i => {
      if (i.id === id) {
        i.value = i.value.filter(i => i.value !== value)

      }
      return i
    })
    handleAddDataTags(data)
    setDataTags(data)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: 8 }}>
      <SortableList
        helperClass={classes.SortableHelperWithOverride}
        items={data}
        onSortEnd={onSortEnd}
        handleChangeTags={handleChangeTags}
        isTagsComponent
        shouldCancelStart={shouldCancelStart}
        dataValues={dataTags}
      />
    </div>
  )
}

export default AssetsdndContainer
