import React, { useCallback, useMemo } from "react"

import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom'

import { Button } from '@material-ui/core'

import { mdiDownload } from '@mdi/js';
import Icon from "@mdi/react";

import BulkDownload from "components/Dialogs/BulkDownload"

import { useDataItems } from 'pages/DAM/Items/context'


function MenuAllDownloads({ totalMarkedItems = 0, totalResultItems = 0 }) {
    const { params_filter_items } = useSelector(state => state.appBarReducer)
    const { getParamsToItems, isShortcut } = useDataItems()

    const { t } = useTranslation()
    const { shortcut_slug } = useParams()

    const getParamsAllDownload = useCallback(() => {
        const paramsToItems = getParamsToItems()
        delete paramsToItems.limit
        delete paramsToItems.only
        delete paramsToItems.page
        delete paramsToItems.search_after

        return { ...paramsToItems, ...params_filter_items }
    }, [getParamsToItems, params_filter_items])

    const constants = useMemo(
        () => ({
            paramsAllDownalod: getParamsAllDownload(),
            disableBtnDownload: ((totalResultItems === 0) || (totalMarkedItems > 0) || (totalResultItems > 1000))
        }), [totalMarkedItems, totalResultItems, getParamsAllDownload])

    return (
            <BulkDownload
                disabled={constants.disableBtnDownload}
                pathDefault={!isShortcut ? 'item/find/download' : `item/download/shortcut/${shortcut_slug}`}
                paramsToItems={constants.paramsAllDownalod}
            >
                {(confirm) => {
                    return (
                        <div className='buttonAllDownload'>
                            <Button
                                style={{ height: 32 }}
                                startIcon={<Icon path={mdiDownload} size={.8} />}
                                variant="contained"
                                color='primary'
                                disabled={constants.disableBtnDownload}
                                onClick={confirm()}
                            >
                                {t('DAM:Baixar tudo')}
                            </Button>
                        </div>
                    );
                }}
            </BulkDownload>
    )
}


export default MenuAllDownloads
