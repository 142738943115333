import React, { useEffect, useState, useCallback, useMemo } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import {
    IconButton,
    useMediaQuery,
    Menu,
    MenuItem,
    Typography,
    useTheme
} from '@material-ui/core'
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined'
import ViewWeekOutlinedIcon from '@material-ui/icons/ViewWeekOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { Creators as ItemsActions } from "flux/ducks/Items"

const SetModeView = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { visualization_mode } = useSelector((state) => state.itemsReducer)
    const { requestHeaders, pathClient } = useSelector((state) => state.appReducer)
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery('(max-width:799px)')

    useEffect(() => {
        let isMounted = true
        if (isMounted && isMobile && visualization_mode !== 'card-mode') {
            dispatch(ItemsActions.setVisualizationMode('card-mode'))
        }
        return () => { isMounted = false }
    }, [isMobile, visualization_mode, dispatch])

    const submitUserListModeView = useCallback((option) => {
        axios.put(`${pathClient}/itemsViewMode`, { itemsViewMode: option }, requestHeaders)
            .then(resp => console.log(`Modo de visualização da lista ${option}, salvo com sucesso.`))
            .catch(err => console.log(err))
    }, [pathClient, requestHeaders])

    const handleViewNavigation = useCallback((option) => {
        const callback = () => {            
            dispatch(ItemsActions.setVisualizationMode(option))
            submitUserListModeView(option)
        }
        setAnchorEl(null, callback())
    }, [dispatch, submitUserListModeView])

    const handleClickMenu = useCallback((evt) => {
        setAnchorEl(evt.currentTarget)
    }, [])

    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, [])

    const constants = useMemo(() => ({
        isModeItemsView: visualization_mode === 'card-mode'
    }), [visualization_mode])

    return (
        <>
            {
                !isMobile && (
                    <>
                        <IconButton style={{ background: 'transparent' }} onClick={handleClickMenu}>
                            {constants.isModeItemsView ? <ViewWeekOutlinedIcon /> : <ViewListOutlinedIcon />}
                            {anchorEl ? <ExpandLess /> : <ExpandMoreIcon />}
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <Typography
                                variation='body1'
                                style={{ fontWeight: 600, padding: '10px 10px', color: '#666' }}
                            >
                                {t('DAM:Visualização')}
                            </Typography>
                            <MenuItem
                                onClick={() => handleViewNavigation('card-mode')}
                                style={{ padding: '5px 10px', opacity: 1 }}
                                disabled={constants.isModeItemsView}
                            >
                                <ViewWeekOutlinedIcon style={{ color: constants.isModeItemsView ? theme.palette.primary.main : '#666', marginRight: 5 }} />
                                <Typography style={{ color: constants.isModeItemsView ? theme.palette.primary.main : '#666' }}>
                                    {t('DAM:Em card')}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleViewNavigation('list-mode')}
                                style={{ padding: '5px 10px', opacity: 1 }}
                                disabled={!constants.isModeItemsView}
                            >
                                <ViewListOutlinedIcon style={{ color: !constants.isModeItemsView ? theme.palette.primary.main : '#666', marginRight: 5 }} />
                                <Typography style={{ color: !constants.isModeItemsView ? theme.palette.primary.main : '#666' }}>
                                    {t('DAM:Em lista')}
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </>
                )
            }
        </>
    )
}

export default SetModeView
