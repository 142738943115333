/* eslint-disable no-use-before-define */
import React, { useState, useCallback } from 'react';
import _ from 'lodash'
import axios from 'axios'
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"

import { TextField, CircularProgress, InputAdornment } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Utils from 'utils/Utils'

export default function InputAutocomplete({
    label = "",
    modelType,
    disabled,
    handleChange
}) {
    const [state, setState] = useState({
        options: [], loading: false
    })
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const loadOptions = useCallback(_.debounce((inputValue = '') => {
        if (inputValue.length >= 2) {
            const callback = () => {
                axios
                    .get(`${pathServer}/item/asset/dataannotation/${modelType}/listByName/${inputValue}`, requestHeaders)
                    .then(({ data }) => setState({ options: data, loading: false }))
                    .catch((err) => {
                        const message = Utils.ajaxErrorGetMessage(err, t)
                        setState({ options: [], loading: false },
                            enqueueSnackbar(message, {
                                variant: "error",
                                autoHideDuration: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center",
                                },
                            }))
                    })
            }
            setState(prev => ({ ...prev, loading: true }), callback())
        }
    }, 1000), [])

    return (
        <div style={{ width: 400 }}>
            <Autocomplete
                freeSolo
                id={label.toLocaleLowerCase()}
                disableClearable
                options={state.options.map((option) => option.name)}
                onInputChange={(evt, value) => {
                    const find = state.options.find(i => i.name === value)
                    if (find) handleChange(find)
                }}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            ...(state.loading && {
                                startAdornment: <InputAdornment position="start">
                                    <CircularProgress style={{ width: 15, height: 15 }} color="primary" />
                                </InputAdornment>
                            })
                        }}
                        onChange={evt => {
                            handleChange({ name: _.get(evt, 'target.value', '') })
                            loadOptions(_.get(evt, 'target.value', ''))
                        }}
                        endAdornment={<CircularProgress />}
                    />
                )}
            />
        </div>
    );
}