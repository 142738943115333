import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    width: "40%",
    maxWidth: "355px",
    height: "100%",
    maxHeight: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 22.5px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      margin: 0,
      borderRadius: 0,
      height: "calc(100vh - 56px)",
      maxHeight: "none",
      padding: "0",
    },    
    "& .containerText": {
      maxWidth: "80%",
      maxHeight: "60%",
      marginBottom: "20px",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& p": {
        textAlign: "center",
        color: "#818080", 
        lineHeight: '1.2em'       
      },
      "& svg, img": {
        marginTop: '30px',
        fontSize: "7.2em",
      },   
      "@media (max-height: 650px)": {
        margin: "20px 0",
        maxHeight: "100%",
        "& svg, img": {
          marginTop: '10px',
          fontSize: "4em",
        }  
      },
    },    
    "& .formLogin": {
      width: "80%",

      "& input": {
        fontSize: "1em",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& button": {
        width: "100%",
        minHeight: "50px",
        borderRadius: "4px",
        marginBottom: '12px',               
      },
    },
  },
}));
