import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: "80px 1fr", 
    fontStyle: "normal",
    color: "#fff",
    fontSize: "10px",
    height: "50vh",
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 80,
  },
  appBar: {
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    height: 117,
    [theme.breakpoints.down("sm")]: {
      height: 70,
      boxShadow: "none",
      '& h5#platform_name': {
        display: 'none'
      },
      '& h5#title': {
        fontSize: '1.3rem'
      }
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: '0 21px'
    },
    '& .client': {
      width: "40%",
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down("sm")]: {
        "& img": {
          width: 50,
          height: 50
        }
      }
    },
    '& .logoAppbar': {
      width: "20%",
      display: 'flex',
      justifyContent: 'center',
    },
    '& .title': {
      width: "40%",
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& #btn-translate ": {
        marginLeft: 20,
        '& button, svg': {
          color: '#fff !important',
        },
        [theme.breakpoints.down("sm")]: {
          display: 'flex',
          alignItems: 'center'
        }
      }
    },
    "& .logoAppbar img": {
      maxHeight: 61,
      maxWidth: 300,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  rootList: {
    border: '1px solid #dadada',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: '#fff'
    },
    '& .profile-description': {
      marginLeft: 20,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& p': {
          width: '100% !important'
        }
      },
    },
    '& .MuiButtonBase-root': {
      borderBottom: '1px solid #dadada',
    }
  },
  maskedInput: {
    position: 'relative',
    '& input': {
      height: '1.1876em',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      padding: '26.5px 14px',
    },
    '& label': {
      position: 'absolute',
      top: '-5px',
      left: 10,
      backgroundColor: '#fff',
      fontSize: '0.75em',
      padding: '0 5px',
      color: 'inherit',
    },
    '& :hover': {
      border: '1px solid rgba(0, 0, 0, 0.54)',
    },
    '& :focus': {
      border: `1px solid ${theme.palette.primary.main}`,
    }
  },
  phoneInput: {
    '& div': {
      '& input': {
        width: '100% !important',
        height: '1.1876em',
        border: '1px solid rgba(0, 0, 0, 0.23) !important',
        borderRadius: 4,
        padding: '26.5px 14px',
      },
      '& :hover': {
        border: '1px solid rgba(0, 0, 0, 0.54)',
      },
      '& :focus': {
        border: `1px solid ${theme.palette.primary.main}`,
      }
    },
  },
  btnForgot: {
    cursor: 'pointer',
    fontSize: '1em',
    color: theme.palette.primary.main,
    marginLeft: 4,
    "&:hover": {
      color: (props) => darken(0.12, theme.palette.primary.main),
    },
  },
  btnActions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    }
  },
  borderMobile: {
    border: '1px solid #dadada',
    borderRadius: 4,
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: '#fff'
    },
  },
  wrapper: {
    display: 'flex', 
    justifyContent:'space-between',
    paddingBottom: 70,
    '& .spacing': {
      width: 205.7
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      justifyContent: 'center'
    },
  },
  expired_root: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'start',
    [theme.breakpoints.down("sm")]: {
      padding: 10
    }
  },
  expired_paper: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column', 
    height: 331, 
    width: 618, 
    background: '#fff', 
    marginTop: 137,
    [theme.breakpoints.down("sm")]: {
      height: 'calc(100vh - 76px)', 
      width: '100vw', 
      marginTop: 0,
    }
  }
}));
