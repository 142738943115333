import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import _ from 'lodash'
import { uuid } from 'uuidv4'

import DnsLine from "./DnsLine";

import {
    Button,
    Grid,
    Typography,
    Divider
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useSnackbar } from 'notistack'
import Settings from 'utils/Settings'
import { genereteHash } from 'utils/Helpers'

const DnsContent = () => {
    const [dnsData, setDnsData] = useState([])
    const [hasChangedDelete, setHasChangedDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(true)    
    const { requestHeaders, pathServer } = useSelector(
        (state) => state.appReducer
    )
    const ID_PLATFORM = useSelector(state =>
        _.get(state, 'appReducer.data.client_id', '')
    )
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const getData = useCallback(() => {
        axios.get(pathServer + "/client/urls", requestHeaders)
            .then(function (resp) {
                const { dns = [] } = resp.data;
                
                const newDns = dns.map(i => ({
                    ...i,
                    id: uuid(),
                    hash_value: genereteHash(i.value),
                    hash_platform_name: genereteHash(i.platform_name),
                    hash_description: genereteHash(i.description),
                    hasChanged_value: false,
                    hasChanged_platform_name: false,
                    hasChanged_description: false,
                }))

                setDnsData(newDns)
                setLoadingData(false)
            })
            .catch((err) => {
                setLoadingData(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            });
    }, [pathServer, requestHeaders, enqueueSnackbar, t])

    useEffect(() => {
        getData()
        return () => { }
    }, [getData])

    const handleNewDns = () => {
        setDnsData(prev => [...prev, { value: "", platform_name: "", description: "", id: uuid() }])
    }

    const handleDeleteDns = (key) => {
        const newData = [...dnsData]        
        const newDataFilter = newData.filter((item, id) => {
            if (id === key) {
                if (item.platform_name && item.description) {
                    setHasChangedDelete(true)
                }
            }

            return id !== key
        })

        setDnsData(newDataFilter)
    }

    const handleChangeDns = (type, key, input) => {
        const newData = [...dnsData]

        const fieldHash = genereteHash(input.value)
        const nameChange = `hasChanged_${type}`
        const nameHash = `hash_${type}`

        if (newData[key][nameHash] !== fieldHash) {
            newData[key][nameChange] = true;
        } else {
            newData[key][nameChange] = false;
        }

        newData[key][type] = input.value;

        setDnsData(newData)
    }

    const validateFields = () => {
        const newData = [...dnsData]

        const newDataError = newData.map(i => {
            let newItem = { ...i }
            Object.entries(i).forEach(([key, value]) => {
                switch (key) {
                    case 'value':
                        newItem['error_value'] = value.trim().length === 0 ? `* ${t("common:Este campo é obrigatório")}` : false
                        break;
                    case 'platform_name':
                        newItem['error_platform_name'] = value.trim().length === 0 ? `* ${t("common:Este campo é obrigatório")}` : false
                        break;
                    case 'description':
                        newItem['error_description'] = value.trim().length === 0 ? `* ${t("common:Este campo é obrigatório")}` : false
                        break;
                    default:
                        break;
                }
            })
            return newItem
        })

        const invalidForm = newDataError.some(i =>
            i.error_platform_name ||
            i.error_value ||
            i.error_description
        )

        setDnsData(newDataError)
        return invalidForm
    }

    const submitForm = (e) => {
        e.preventDefault();

        if (validateFields()) {
            return
        }

        const parameters = dnsData.map(i => ({ value: i.value, platform_name: i.platform_name, description: i.description }))

        setLoading(true)
        axios.put(`${pathServer}/client/${ID_PLATFORM}`, { dns: parameters }, requestHeaders)
            .then((resp) => {
                setLoading(false)
                enqueueSnackbar(resp.headers['x-message'], {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                })
            })
            .catch((err) => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            });
    }

    const getSkeleton = () => {
        const style = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
        return (
            <div style={{ marginBottom: 20 }}>
                <div style={style}>
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={40} height={40} />                    
                </div>
                <Skeleton variant="text" width={'100%'} height={170} />
                <div style={style}>
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={40} height={40} />
                </div>
                <Skeleton variant="text" width={'100%'} height={170} />                
            </div>
        )
    }

    const DISABLED_SUBMIT = dnsData.some(i => _.get(i, 'hasChanged_platform_name', false) ||
        _.get(i, 'hasChanged_value', false) || _.get(i, 'hasChanged_description', false)) || hasChangedDelete

    return (
        <div style={{ marginTop: 30 }}>
            <Grid item xs={12} style={{ margin: '20px 0' }}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" style={{ color: '#666' }}>
                    {t('DNS')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                {
                    loadingData && getSkeleton()
                }
                {
                    !loadingData && dnsData.length > 0 &&
                    dnsData.map((item, key) => {
                        return <DnsLine
                            item={item}
                            key={key}
                            index={key}
                            loading={loading}
                            handleChangeDns={(type, key, input) => handleChangeDns(type, key, input)}
                            handleDeleteDns={() => handleDeleteDns(key)}
                        />
                    })
                }
            </Grid>
            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 20 }}>
                <Button
                    color="primary"
                    variant="outlined"
                    style={{ height: 40, marginRight: 25 }}
                    onClick={() => handleNewDns()}
                    disabled={loading}
                >
                    {t("common:Adicionar dns")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                    onClick={(evt) => submitForm(evt)}
                    disabled={!DISABLED_SUBMIT || loading}
                >
                    {t("common:Salvar")}
                </Button>
            </Grid>
        </div>
    )
}

export default DnsContent