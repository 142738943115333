import React from 'react'

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({    
    root: {       
        position: 'relative',      
        backgroundSize: '400% 400%',   
        paddingTop: '100%',    
        width: props => props.width ? props.width : '100%',
        height: props => props.height ? props.height : '100%', 
        background: 'linear-gradient(90deg, #333, rgba(255,255,255,0.2), #e2e2e2)',
        animation: '$loading 3.5s ease-in-out infinite',           
    },   
    [`@keyframes loading`]: { 
        '0': {
            backgroundPosition: `0 50%`,
        }, 
        '50%': {
            backgroundPosition: `100% 50%`,
        },       
        '100%': {
            backgroundPosition: `0 50%`,
        }, 
    },
}))

const SkeletonResponsive = ({
    width,
    height
}) => {
    const classes = useStyles({
        width,
        height
    })
    return <div className={classes.root} id='skeletonResponsive'/>
}
export default SkeletonResponsive