import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios"
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useTranslation } from "react-i18next";

import { Grid } from "@material-ui/core"

import FormCreateOrEditItem from 'components/FormCreateOrEditItem'
import DialogConfirm from "../DialogConfirm"
import Loading from 'components/Loading'

import Utils from 'utils/Utils'
import Settings from 'utils/Settings'
import { slugify } from 'utils/Helpers'

import { createEditForm } from 'components/CategoryAttributes'

import { useStyles } from "../../styles";

const ItemEdit = ({ handleSubmitEdit, dataItem, closeDialog, canPublish }) => {
  const [state, setState] = useState({
    alertInfo: false,
    loading: true,
    checkData: false,
    defaultData: {},
    parameters: {}
  })
  const { requestHeaders, pathServer, supportsWebp } = useSelector(state => state.appReducer)

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const setDataState = useCallback(async () => {
    try {
      const linksResult = await axios.get(`${pathServer}/item/${_.get(dataItem, 'id', '')}/links`, requestHeaders)
      const dataLinks = _.get(linksResult, 'data.item.links', []).map((i) => {
        const { id, taxonomy, thumbnail, name } = i;
        const notPermitted = !_.isEmpty(taxonomy) && !_.isEmpty(thumbnail) && !name;

        return {
          value: id,
          label: notPermitted ? t("common:Não Visível") : _.get(taxonomy, 'name', name),
          thumbnail: Utils.getThumb(thumbnail, supportsWebp),
          notPermitted
        };
      })

      const categoryResult = await axios.get(`${pathServer}/attributesByCategory/${_.get(dataItem, 'category.id', '')}`, requestHeaders)
      const dataAttributes = _.get(categoryResult, 'data.items', []).map((dataAttribute) => {
        dataAttribute.mask_value = dataAttribute.mask_value ? dataAttribute.mask_value.trim() : '';
        return dataAttribute
      })

      const dataFields = createEditForm(
        _.get(dataItem, 'attributes', _.get(dataItem, 'attribute', []))
        , dataAttributes
      )

      setState(prev => ({
        ...prev,
        loading: false,
        defaultData: {
          itemId: dataItem.id,
          name: _.get(dataItem, 'name', ''),
          editorState: {
            text: _.get(dataItem, 'description.text', ''),
            data: Utils.getFormattedDescription(dataItem.description, t)
          },
          segmentIem: {
            label: _.get(dataItem, 'division.name', '') + ' > ' + _.get(dataItem, 'segment.name', ''),
            name: _.get(dataItem, 'segment.name', ''),
            value: _.get(dataItem, 'segment.id', ''),
          },
          categoryData: {
            label: _.get(dataItem, 'category.name', ''),
            value: _.get(dataItem, 'category.id', ''),
            canLink: _.get(dataItem, 'category.canLink', false),
          },
          isGalleryType: _.get(dataItem, 'isGalleryType', false),
          isPublished: dataItem.published && !dataItem.validity_date,
          isCanPublish: canPublish,
          startDate: _.get(dataItem, 'start_date', ''),
          validityDate: _.get(dataItem, 'validity_date', ''),
          hasDate: Boolean(dataItem.validity_date) || Boolean(dataItem.start_date),
          relatedItems: dataLinks,
          dataFields,
          attributes: dataAttributes,
          segment_id: _.get(dataItem, 'segment_id', ""),
          category_id: _.get(dataItem, 'category_id', ""),
          canLink: _.get(categoryResult, 'data.can_link', false),
          initalAttribute: _.get(dataItem, 'attributes', [])
        },
        checkData: true
      }))
    } catch (err) {
      const message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao buscar os dados de item, tente novamente.`))
      setState(prev => ({ ...prev, loading: false, error: true }),
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }))
    }
  }, [
    enqueueSnackbar,
    pathServer,
    requestHeaders,
    supportsWebp,
    t,
    dataItem,
    canPublish,
  ])

  useEffect(() => {
    let isMounted = true
    if (isMounted && !state.checkData) setDataState()
    return () => { isMounted = false }
  }, [setDataState, state.checkData])

  const handleClickDialogClose = useCallback(() => {
    setState(prev => ({ ...prev, alertInfo: false }))
  }, [])

  const checkRemovedNotVisible = useCallback((relatedItems) => {
    const deletedDisabledItems
      = relatedItems.filter(l => l.notPermitted && !relatedItems.some(r => r.value === l.value))

    if (deletedDisabledItems.length > 0) {
      enqueueSnackbar(t("Itens não visíveis não podem ser removidos dos relacionamentos"), {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      })
      return true
    }

    return false
  }, [enqueueSnackbar, t])

  const submitForm = useCallback((evt, comment) => {
    const callback = () => {
      let data = { comment, ...state.parameters }
      delete data.segmentIem
      delete data.categoryData

      if (dataItem.assets && dataItem.assets.length > 0) {
        data['assets'] = dataItem.assets.map((asset) => _.get(asset, 'id', asset))
      }

      const pathItem = {
        ...(_.get(state.parameters, 'segmentIem.division', false)
          && { division: slugify(_.get(state.parameters, 'segmentIem.division', '')) }),
        ...(_.get(state.parameters, 'segmentIem.name', false)
          && { segment: slugify(_.get(state.parameters, 'segmentIem.name', '')) }),
        ...(_.get(state.parameters, 'categoryData.label', false)
          && { category: slugify(_.get(state.parameters, 'categoryData.label', '')) })
      }

      handleSubmitEdit(evt, data, pathItem)
    }
    if (!checkRemovedNotVisible(_.get(state.parameters, 'relatedItems', []))) {
      setState(prev => ({ ...prev, alertInfo: false }), callback())
    }
  }, [
    state.parameters,
    checkRemovedNotVisible,
    dataItem.assets,
    handleSubmitEdit,
  ])

  const handleClickDialogOpen = useCallback((params) => {
    setState(prev => ({ ...prev, alertInfo: true, parameters: params }))
  }, [])

  return (
    <Fragment>
      {state.loading && <Loading showLoading />}
      <Grid container className={classes.rootGrid}>
        <Grid item xs={12} sm={7} className={classes.rootForm}>
          {
            !_.isEmpty(state.defaultData) &&
            <FormCreateOrEditItem
              isEdit
              goback={closeDialog}
              submit={handleClickDialogOpen}
              defaultData={state.defaultData}
            />
          }
        </Grid>
      </Grid>

      {state.alertInfo && (
        <DialogConfirm
          open
          handleClose={handleClickDialogClose}
          handleConfirm={(evt, comment) => submitForm(evt, comment)}
          hasAlterDate={!!dataItem.validity_date && dataItem.validity_date !== _.get(state, 'parameters.validity_date')}
          isCommentPublish={dataItem.initial_publish !== `${_.get(state, 'parameters.published')}_${_.get(state, 'parameters.start_date')}_${_.get(state, 'parameters.validity_date')}`}
        />
      )}
    </Fragment>
  );
};

export default ItemEdit;
