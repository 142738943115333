import React from "react";
import axios from "axios/index";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "notistack"
import { compose } from "recompose"

import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText
} from "@material-ui/core";

import { pick } from 'ramda';
import connect from "react-redux/es/connect/connect";
import { mapStateToPropsToSettings, mapDispatchToPropsToSettings } from "utils/Flux";

import { WrapperPaperForms } from 'components/WrapperForms'
import Template from "../Template";
import Loading from "components/Loading";
import PopoverPassword from 'components/PopoverPassword'

import { contentSnackbar } from 'utils/Helpers'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      formValid: false,
      id: props.userId,
      formErrors: {
        password: "",
        confirmPassword: "",
        oldPassword: "",
      },
      password: "",
      confirmPassword: "",
      passwordValid: false,
      confirmPasswordValid: false,
      oldPassword: "",
      oldPasswordValid: false,
      ShowPassword: false,
      ShowOldPassword: false,
      ShowConfirmPassword: false,
    };
    this.formSubmit = this.formSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  formSubmit(e) {
    e.preventDefault();
    const SELF = this;
    const { t, enqueueSnackbar } = SELF.props;

    if (!this.validateField('confirmPassword')) {
      SELF.props.enqueueSnackbar(t("common:O formulário tem erros"), {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      })
      return false;
    }
    const { requestHeaders, pathServer, userId } = SELF.props;

    SELF.setState({ ...SELF.state, loading: true });
    const fields = ['password', 'confirmPassword', 'oldPassword'];
    const data = pick(fields, SELF.state);
    axios.put(pathServer + '/account/password/' + userId, data, requestHeaders)
      .then((resp) => {
        SELF.setState({ ...SELF.state, loading: false });
        enqueueSnackbar(resp.headers['x-message'], {
          variant: "success",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          }
        })
      },
        (err) => {
          if (err.response.data.hasOwnProperty('error')) {
            enqueueSnackbar(err.response.data.error, {
              content: (snackbarId, message) => contentSnackbar(snackbarId, message, this.props.t, this.props.closeSnackbar),
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })            
          }
          
          if (err.response.data.hasOwnProperty('errors')) {
            if(err.response.data.errors.hasOwnProperty('oldPassword')) {              
              enqueueSnackbar(err.response.data.errors.oldPassword[0], {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              })              
            }         
          } 

          SELF.setState({ ...SELF.state, loading: false })            
        })
      .catch((err) => {
        SELF.setState({ ...SELF.state, loading: false })
        enqueueSnackbar(t("Erro ao processar, contate o administrador"), {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })

      });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  validateField(fieldName) {
    const { t } = this.props;
    let { password, confirmPassword } = this.state;
   
    if (fieldName === 'confirmPassword') {
      if (confirmPassword !== password) {
        this.setState(prev => ({
          ...prev,
          formErrors: {
            confirmPassword: t('A senha e a confirmação não correspondem')
          }
        }));
        return false
      }
    }

    this.setState(prev => ({
      ...prev,
      formErrors: {}
    }));

    return true
  }

  classError(error) {
    return (error.length === 0 ? 'form-control' : 'form-control has-error');
  }

  handleClickShowPassword(name) {
    const newState = { ...this.state }

    this.setState({
      ...newState,
      [name]: !newState[name]
    })
  }

  render() {
    const { t } = this.props;

    return (
      <Template area={10} localHistory={this.props.history} customClassName={"container-center"}>
        <Loading showLoading={this.state.loading} />

        <WrapperPaperForms title={t("common:Alteração de senha")}>
          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="adornment-oldPassword">
                {`${t("login:Senha atual")}*`}
              </InputLabel>
              <OutlinedInput
                name="oldPassword"
                id="adornment-oldPassword"
                type={this.state.ShowOldPassword ? 'text' : 'password'}
                value={this.state.oldPassword}
                onChange={(event) => this.handleUserInput(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.handleClickShowPassword('ShowOldPassword')}
                      edge="end"
                    >
                      {this.state.ShowOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={92}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="adornment-oldPassword">
                {t("login:Nova senha")}
              </InputLabel>
              <OutlinedInput
                name="password"
                id="adornment-password"
                type={this.state.ShowPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={(event) => this.handleUserInput(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.handleClickShowPassword('ShowPassword')}
                      edge="end"
                    >
                      {this.state.ShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={110}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="adornment-oldPassword">
                {t("Confirme a nova senha")}
              </InputLabel>
              <OutlinedInput
                name="confirmPassword"
                id="adornment-password"
                type={this.state.ShowConfirmPassword ? 'text' : 'password'}
                value={this.state.confirmPassword}
                onChange={(event) => this.handleUserInput(event)}
                error={Boolean(this.state.formErrors.confirmPassword)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.handleClickShowPassword('ShowConfirmPassword')}
                      edge="end"
                    >
                      {this.state.ShowConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={170}
              />
              {
                Boolean(this.state.formErrors.confirmPassword) &&
                <FormHelperText id="component-error-text">
                  {this.state.formErrors.confirmPassword}
                </FormHelperText>
              }
            </FormControl>
            <PopoverPassword />
          </Grid>

          <Grid item xs={12} className='paper-actions'>
            <Button
              variant="contained"
              color="primary"
              onClick={this.formSubmit}
            >
              {t("common:Salvar")}
            </Button>
          </Grid>

        </WrapperPaperForms>
      </Template>
    );
  }
}

export default compose(
  connect(mapStateToPropsToSettings, mapDispatchToPropsToSettings)
)(withTranslation(['common', 'recoverPassword'])(withSnackbar(Account)))
