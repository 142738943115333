import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Menu, Typography, MenuItem, makeStyles, Tooltip, Button } from '@material-ui/core';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';


const useStyles = makeStyles((theme) => ({
    rootButton: {        
        '& span.MuiButton-startIcon': {
            margin: 0
        }
    },
    rootTitle: {
        padding: '6px 16px',
        color: '#666',
        '& p': {
            fontWeight: 500
        }
    },
    rootMenu: {
        '& .Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            opacity: 1
        }
    }
}));

const MenuSortingView = ({
    title,
    options,
    data,
    handleMenuItemClick,   
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()
    const { t } = useTranslation()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (index) => {
        handleMenuItemClick(index)
        setAnchorEl(null);
    }
    
    return (
        <>
            <Tooltip title={title} placement="top">
                <div className={classes.rootButton}>
                    <Button
                        style={!_.isEmpty(data)  ? {} : {color: '#666'}}
                        color={!_.isEmpty(data) ? "primary" : "default"}
                        endIcon={
                            Boolean(anchorEl)
                                ? <KeyboardArrowUpRoundedIcon fontSize='small' />
                                : <KeyboardArrowDownRoundedIcon fontSize='small' />
                        }                        
                        onClick={handleClick}
                    >
                     {t("DAM:Visualizar")}
                     </Button>

                </div>
            </Tooltip>

            <Menu
                id="menu-ordination"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.rootMenu}
            >
                <div className={classes.rootTitle}>
                    <Typography variant='body1' noWrap>
                        {title}
                    </Typography>
                </div>
                {options.map((option, index) => {
                    if(!option.value) {
                        return null
                    }
                    const disabledAll = _.isEmpty(data) && option.id === 0
                    
                    return (
                        <MenuItem
                            key={option.id}    
                            disabled={data.hasOwnProperty(option.field) || disabledAll}                    
                            selected={data.hasOwnProperty(option.field) || disabledAll}
                            onClick={() => handleClickMenu(index)}                                  
                        >
                            {option.title}
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

MenuSortingView.defaultProps = {   
    title: '',
    options: [],
    data: {},
    handleMenuItemClick: () => { }
}

MenuSortingView.propTypes = {  
    title: PropTypes.string,
    options: PropTypes.array,
    data: PropTypes.object,
    handleMenuItemClick: PropTypes.func,

}

export default MenuSortingView