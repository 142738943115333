export const Types = {
  SET_COUNT_NOTIFICATIONS: "header_app/SET_COUNT_NOTIFICATIONS",
  SET_DOWNLOAD_COUNT: "header_app/SET_DOWNLOAD_COUNT",
}

const INITIAL_STATE = {
  count: 0,
  download_count: 0
}

export default function headerAppReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_COUNT_NOTIFICATIONS:
      return { ...state, count: action.payload.data }

    case Types.SET_DOWNLOAD_COUNT:
      return { ...state, download_count: action.payload.data }

    default:
      return state
  }

}

export const Creators = {
  setCountNotifications: (payload) => ({
    type: Types.SET_COUNT_NOTIFICATIONS,
    payload,
  }),
  setDownloadCount: (payload) => ({
    type: Types.SET_DOWNLOAD_COUNT,
    payload,
  }),
}