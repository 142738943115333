import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export const useStyles = makeStyles((theme) => ({
  content: {
    width: "40%",
    maxWidth: "355px",
    height: "100%",
    maxHeight: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 22.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      margin: 0,
      borderRadius: 0,
      height: "calc(100vh - 56px)",
      maxHeight: "none",
      padding: "0",
    },
    "& p.titleMobile": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "1.5em",
        marginBottom: "5px",
        padding: "0 20px",
        textAlign: "center",
      },
    },
    "& > p": {
      color: "#818080",
      padding: "2px 15px 10px 15px",
      textAlign: "center",
    },
    "& .formLogin": {
      width: "100%",
      padding: "0 52px",

      "& input": {
        fontSize: "1em",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& button": {
        width: "100%",
        minHeight: "50px",
        borderRadius: "4px",
        margin: "20px 0",
      },
    },
    "& .containerText": {
      maxWidth: "80%",
      maxHeight: "60%",      
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& p": {
        textAlign: "center",
        color: "#818080",
        marginBottom: "20px",
      },
      "& svg": {
        fontSize: "7.2em",
      },
    },
  },
  btnForgot: {
    margin: '20px 0',
    fontSize: "1em",
    cursor: "pointer",
    color: (props) => props.primaryColor || "#000000",
    display: "none",   
    "&:hover": {
      color: (props) => darken(0.12, props.primaryColor || "#000000"),
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
