export const Types = {
  SET_PARAMS_FILTER_ITEMS: "appbar_app/SET_PARAMS_FILTER_ITEMS",
  SET_DATA_CATEGORY: "appbar_app/SET_DATA_CATEGORY",
}

const INITIAL_STATE = {
  params_filter_items: {
    category_slug: []
  },
}

export default function appBarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PARAMS_FILTER_ITEMS:
      return { ...state, params_filter_items: action.payload }

    case Types.SET_DATA_CATEGORY:    
      return {
        ...state, params_filter_items: {
          category_slug: Object.entries(action.payload).map(([key, item]) => item.value)
        }
      }

    default:
      return state
  }

}

export const Creators = {
  setParamsFilterItems: (payload) => ({
    type: Types.SET_PARAMS_FILTER_ITEMS,
    payload,
  }),
  setDataCategory: (payload) => ({
    type: Types.SET_DATA_CATEGORY,
    payload,
  })
}