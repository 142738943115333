import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import _ from 'lodash'
import { uuid } from 'uuidv4'

import LinkLine from "./LinkLine";

import {
    Button,
    Grid,
    Typography,
    Divider
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useSnackbar } from 'notistack'
import Settings from 'utils/Settings'
import { genereteHash } from 'utils/Helpers'

const LinkContent = () => {
    const [linksData, setLinksData] = useState([])
    const [hasChangedDelete, setHasChangedDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(true)
    const { requestHeaders, pathServer } = useSelector(
        (state) => state.appReducer
    )
    const ID_PLATFORM = useSelector(state =>
        _.get(state, 'appReducer.data.client_id', '')
    )
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const getData = useCallback(() => {
        axios.get(pathServer + "/client/urls", requestHeaders)
            .then(function (resp) {
                const { links = [] } = resp.data;
                let newLinks = []
                if (Array.isArray(links)) {
                    newLinks = links.map(i => ({
                        ...i,
                        id: uuid(),
                        hash_value: genereteHash(i.value),
                        hash_label: genereteHash(i.label),
                        hasChanged_value: false,
                        hasChanged_label: false,
                    }))
                }
                
                setLinksData(newLinks)
                setLoadingData(false)
            })
            .catch((err) => {
                setLoadingData(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            });
    }, [pathServer, requestHeaders, enqueueSnackbar, t])

    useEffect(() => {
        getData()
        return () => { }
    }, [getData])

    const handleNewLink = () => {
        setLinksData(prev => [...prev, { value: "", label: "", id: uuid() }])
    }

    const handleDeleteLink = (key) => {
        const newData = [...linksData]
        const newDataFilter = newData.filter((item, id) => {
            if (id === key) {
                if (item.value && item.label) {
                    setHasChangedDelete(true)
                }
            }

            return id !== key
        })

        setLinksData(newDataFilter)
    }

    const handleChangeLink = (type, key, input) => {
        const newData = [...linksData]

        const fieldHash = genereteHash(input.value)
        const nameChange = `hasChanged_${type}`
        const nameHash = `hash_${type}`

        if (newData[key][nameHash] !== fieldHash) {
            newData[key][nameChange] = true;
        } else {
            newData[key][nameChange] = false;
        }

        newData[key][type] = input.value;

        setLinksData(newData)
    }

    const validateFields = () => {
        const newData = [...linksData]

        const newDataError = newData.map(i => {
            let newItem = { ...i }
            Object.entries(i).forEach(([key, value]) => {
                switch (key) {
                    case 'label':
                        newItem['error_label'] = value.trim().length === 0 ? `* ${t("common:Este campo é obrigatório")}` : false
                        break;
                    case 'value':
                        // eslint-disable-next-line
                        const regexUrl = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

                        if (value.trim().length === 0) {
                            newItem['error_value'] = `* ${t("common:Este campo é obrigatório")}`
                            break;
                        }
                        if (!regexUrl.test(value)) {
                            newItem['error_value'] = 'Deve ser um link valido!'
                            break;
                        } else {
                            newItem['error_value'] = false
                            break;
                        }
                    default:
                        break;
                }
            })
            return newItem
        })

        const invalidForm = newDataError.some(i => i.error_label || i.error_value)

        setLinksData(newDataError)
        return invalidForm
    }

    const submitForm = (e) => {
        e.preventDefault();

        if (validateFields()) {
            return
        }

        const parameters = linksData.map(i => ({ value: i.value, label: i.label }))

        setLoading(true)
        axios.put(`${pathServer}/client/${ID_PLATFORM}`, { links: parameters }, requestHeaders)
            .then((resp) => {
                setLoading(false)
                enqueueSnackbar(resp.headers['x-message'], {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                })
            })
            .catch((err) => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            });
    }

    const getSkeleton = () => {
        const style = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
        }
        return (
            <div style={{ marginBottom: 20 }}>
                <div style={style}>
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={40} height={40} />
                </div>
                <div style={style}>
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={40} height={40} />
                </div>
                <div style={style}>
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={'45%'} height={40} />
                    <Skeleton variant="text" width={40} height={40} />
                </div>
            </div>
        )
    }
    
    const DISABLED_SUBMIT = linksData.some(i => _.get(i, 'hasChanged_label', false) ||
        _.get(i, 'hasChanged_value', false)) || hasChangedDelete
    
    
    return (
        <>
            <Grid item xs={12} style={{ margin: '30px 0' }}>
                <Typography variant="body1">
                    {t('manager:Adicione domínios e links externos como sites, redes sociais e outros web sites utilizados por você, clientes e fornecedores.')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6" style={{ color: '#666' }}>
                    {t('common:Links externos')}
                </Typography>
            </Grid>

            <Grid item xs={12} style={{ margin: '20px 0' }}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                {
                    loadingData && getSkeleton()
                }
                {
                    !loadingData && linksData.length > 0 &&
                    linksData.map((item, key) => {
                        return <LinkLine
                            item={item}
                            key={key}
                            index={key}
                            loading={loading}
                            handleChangeLink={(type, key, input) => handleChangeLink(type, key, input)}
                            handleDeleteLink={(evt) => handleDeleteLink(key)}
                        />
                    })
                }
            </Grid>

            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                    color="primary"
                    variant="outlined"
                    style={{ height: 40, marginRight: 25 }}
                    onClick={() => handleNewLink()}
                    disabled={loading}
                >
                    {t("common:Adicionar Link")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                    onClick={(evt) => submitForm(evt)}
                    disabled={!DISABLED_SUBMIT || loading}
                >
                    {t("common:Salvar")}
                </Button>
            </Grid>
        </>
    )
}

export default LinkContent