import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export const useStyles = makeStyles((theme) => ({
  menuMobile: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiDrawer-paperAnchorLeft": {
        left: "18px",
        right: "5%",
      },
      "& .MuiDrawer-paper": {
        height: "auto",
        top: "20px",
        borderRadius: "4px",
      },
      "& .MuiPaper-elevation16": {
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      },
      "& a": {
        padding: "10px 0 10px 40px",
        fontSize: "1.3em",
        color: theme.palette.primary.main,
        "&:hover": {
          color: darken(0.12, theme.palette.primary.main),
        },
      },
    },
  },
  appBar: {
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      boxShadow: "none",
      color: theme.palette.primary.main,
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .actionsAppBarLeft": {
      width: 'calc(40vw - 24px)'
    },
    "& .logoAppbar": {
      width: '20vw',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down("sm")]: {
        width: '1vw',
      },
      "& img": {
        maxHeight: 60,
        maxWidth: 300,
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      }
    },
    "& button.btnMenuLogin": {
      marginRight: "20px",
      color: "white",
      textTransform: "uppercase",
      cursor: "pointer",      
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& button.btnMenuRequestAcs": {
      width: "200px",
      height: "40px",
      color: "white",      
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  list: {
    width: 250,
    marginTop: "38px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
      width: 'auto'
    },
    "& .MuiListItem-gutters": {
      padding: "10px 0 10px 40px",
    },
    "& a": {
      color: theme.palette.primary.main,
      '&:hover': {
        color: darken(0.12, theme.palette.primary.main),
      }
    },
  },
  actionsAppBar: {
    width: 'calc(40vw - 24px)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .btn-translate': {
      marginRight: 15,
      '& button, svg': {
        color: '#fff'
      }
    },
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      justifyContent: 'flex-end',
      '& .btn-translate': {
        marginRight: 0,
        '& button, svg': {
          color: '#666 !important'
        }
      }
    }
  },
  brandWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& .brand': {
      color: theme.palette.primary.main,
      padding: 7
    }
  }
}));
