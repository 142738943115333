import React from 'react'
import { useHistory } from 'react-router-dom'
import { HeaderPublicRoute } from '../../Header/PublicRoutes'

const PublicLayout = ({ children, showHeader = true }) => {
    const history = useHistory()

    const handleAccessRequest = () => {
        history.push("/access-request")
    }

    return (
        <>           
            {
                showHeader && 
                <HeaderPublicRoute handleAccessRequest={handleAccessRequest} />  
            }     
              
            {children}
        </>
    )
}

export default PublicLayout