import axios from "axios/index";

export const simpleGet = (url, params, userData) => 
    new Promise((resolve, reject) => {
        const { user_token } = userData
        params["bearer_token"] = user_token.replace("Bearer ", "");
        const instance = axios.create({
            transformRequest: (data, headers) => {
                delete headers.common['X-Localization'];
                return data;
            }
        })
        instance.get(url, {params})
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })