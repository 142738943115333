import React, { useState, useEffect } from "react";
import {
  Grid,  
  Typography,
  CircularProgress,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Dropzone from "react-dropzone";
import { useSnackbar } from "notistack";
import imgUpload from "../../../../imagens/ui/ico-upload.svg";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import Utils from "../../../../utils/Utils";
import _ from "lodash";
import Settings from 'utils/Settings'
import UploadDialog from '../../Core/UploadDialog'
import { useStyles } from "./styles"

import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const PolicyUpload = ({ policie = {}, _loading, language = 'pt', setShouldGetData  }) => {
  const [loading, setLoading] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState(policie);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { requestHeaders, pathServer } = useSelector(
    (state) => state.appReducer
  );
  const classes = useStyles()

  useEffect(() => {
    setCurrentPolicy(policie);
  }, [policie]);

  const onDropUpload = (file) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("privacy_policy", file[0]);
    formData.append('language', language)
    
    axios
      .post(`${pathServer}/client/privacy_policy`, formData, requestHeaders)
      .then((resp) => {        
        const { current_policy } = resp.data;
        setCurrentPolicy({
          file: current_policy.file[language],
          reference: current_policy.reference,
        });
        setLoading(false);
        enqueueSnackbar(t("common:Arquivo enviado com sucesso."), {
          ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
        });

        if(language === 'pt'){
          setShouldGetData(true)
        }
      })
      .catch(function (err) {
        console.log("Error: ", err);
        setLoading(false);
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("common:Erro inesperado ao enviar o PDF. Contate o suporte");

        if (message !== "Arquivo de upload não informado.") {
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      });
  };

  const onDropRejected = () => {
    console.log("Arquivo inválido. Deve ter até 6mb e ser em formato PDF");
    enqueueSnackbar(
      t("common:Arquivo inválido. Arquivo de até 6mb e em formato PDF"),
      {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }
    );
  };

  const makeDownload = (evt, linkDownload) => {
    evt.preventDefault();
    Utils.makeDownload(linkDownload);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Dropzone
          onDrop={onDropUpload}
          accept="application/pdf"
          maxSize={6000000}
          onDropRejected={onDropRejected}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.rootDropzone}>
              <div className='content'>
                <input {...getInputProps()} />
                {(loading || _loading) ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {_.get(currentPolicy, "file", false) &&
                      _.get(currentPolicy, "reference", false) ? (
                      <div className='pdf'>
                        <Document
                          error={t("common:Erro ao carregar o PDF.")}
                          noData={t("common:Nenhum PDF encontrado.")}
                          file={currentPolicy.file}
                        >
                          <Page pageNumber={1} width={320} scale={1.2} />
                        </Document>
                      </div>
                    ) : (
                      <>
                        <img
                          src={imgUpload}
                          width={50}
                          alt={t("common:Clique aqui ou arraste o PDF para enviar")}
                        />
                        <br />
                        <Typography variant="body2">
                          {t("common:Clique aqui ou arraste o PDF para enviar")}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </Grid>
      {_.get(currentPolicy, "file", false) && (
        <Grid item xs={12} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="body2" display="block">
              {t("common:Última atualização")}:{" "}
              {Utils.formatDateFull(currentPolicy.reference)}
            </Typography>
          </div>
          <div>
            <div
              style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
            >
              <UploadDialog
                t={t}
                title={t("common:Adicionar nova versão")}
                descriptionUpload={t("common:Clique aqui ou arraste o PDF para enviar")}
                onDropUpload={onDropUpload}
                onDropRejected={onDropRejected}
                loading={loading}
                acceptFiles="application/pdf"
              >
                {(confirm) => (
                  <Tooltip title={t("common:Nova versão")} placement='top'>
                    <div>
                    <IconButton
                      aria-label="update-file"
                      onClick={confirm()}
                      disabled={loading}
                    >
                      <UpdateRoundedIcon />
                    </IconButton>
                    </div>
                  </Tooltip>
                )}
              </UploadDialog>

              <Tooltip title={t("common:Baixar")} placement='top'>
                <div>
                <IconButton
                  aria-label="download"
                  onClick={(evt) => makeDownload(evt, currentPolicy.file)}
                  disabled={loading}
                >
                  <GetAppRoundedIcon />
                </IconButton>
                </div>
              </Tooltip>
            </div>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default PolicyUpload;
