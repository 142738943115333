import React, { useState, useCallback, useEffect } from 'react'

import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import _ from 'lodash'
import { useSelector } from "react-redux"

import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"
import Utils from "utils/Utils"

import { useStyles } from "./styles"

const INITIAL_STATE = {
    data: [],
    shortcutBreadcrumbs: [],
    galleryBreadcrumbs: [],
    checkedData: false
}

const Header = ({ inheritedBreadcrumbs = [] }) => {
    const [state, setState] = useState({ ...INITIAL_STATE })
    const { searchText, region, division, segment, category } = useParams()
    const match = useRouteMatch()
    const history = useHistory()
    const { t } = useTranslation()
    const { requestHeaders, pathServer, data: { description } } = useSelector(
        (state) => state.appReducer
    )
    const classes = useStyles()
    const isPathShortcut = match.path === '/shortcut/:region/:shortcut_slug'
    const isPathGrouped = match.path === "/gallery/:region/:shortcut_slug/:grouped*"

    const getBreadcrumbs = useCallback(() => {
        let breadcrumbs = []
        if (inheritedBreadcrumbs.length > 0) {
            breadcrumbs = inheritedBreadcrumbs;
        }
        if (_.get(match.params, 'grouped', false)) {
            breadcrumbs = Utils.getBreadcrumbsGallery(_.get(match.params, 'grouped', ''), region, _.get(match.params, 'shortcut_slug', ''))
        }
        setState(prev => ({
            ...prev,
            checkedData: true,
            shortcutBreadcrumbs: [
                {
                    label: t('common:Atalho'),
                    url: `/dam/${region}`
                },
                {
                    label: _.get(match.params, 'shortcut_slug', ''),
                    url: "#"
                }
            ],
            galleryBreadcrumbs: [
                {
                    label: t('common:Galeria'),
                    url: `/dam/${region}`
                },
                ...breadcrumbs
            ]
        }))
    }, [match.params, region, t, inheritedBreadcrumbs])

    const getData = useCallback((region, division, segment, category) => {
        axios.get(`${pathServer}/breadcrumbs/${division}/${segment}/${category}`, requestHeaders)
            .then(function ({ data }) {
                const item_division = _.get(data, 'division.slug', '')
                const item_segment = _.get(data, 'segment.slug', '')
                const item_slug = _.get(data, 'slug', '')

                setState(prev => ({
                    ...prev,
                    checkedData: true,
                    data: [
                        {
                            label: _.get(data, 'division.name', ''),
                            url: `/dam/${region}/${item_division}`,
                        },
                        {
                            label: _.get(data, 'segment.name', ''),
                            url: `/dam/${region}/${item_division}/${item_segment}`,
                        },
                        {
                            label: _.get(data, 'name', ''),
                            url: `/dam/${region}/${item_division}/${item_segment}/${item_slug}`,
                        },
                    ]
                }))
            })
            .catch((err) => {
                console.log(err)
                setState({ ...INITIAL_STATE, checkedData: true })
            });
    }, [pathServer, requestHeaders])

    useEffect(() => {
        let isMounted = true
        if (isMounted && !state.checkedData) {
            if (region && division && segment && category) {
                getData(region, division, segment, category)
            } else {
                getBreadcrumbs()
            }
        }
        return () => { isMounted = false }
    }, [getBreadcrumbs, getData, region, division, segment, category, state.checkedData])

    const getDataBreadcrumbs = useCallback(() => {
        if (searchText) {
            return [{ label: searchText, url: "#" }]
        }
        if (isPathShortcut) {
            return state.shortcutBreadcrumbs
        }
        if (isPathGrouped) {
            return state.galleryBreadcrumbs
        }

        return state.data
    }, [
        searchText,
        isPathShortcut,
        isPathGrouped,
        state.data,
        state.shortcutBreadcrumbs,
        state.galleryBreadcrumbs
    ])

    return (
        <div className={classes.header_root}>
            <Helmet>
                <title>{Utils.getTitleFromUrl()}</title>
                <meta
                    name="description"
                    content={description}
                />
            </Helmet>

            <HeaderMaterialDesign
                title={searchText ? t("Busca") : t("DAM")}
                breadcrumbs={getDataBreadcrumbs()}
                localHistory={history}
                searchText={searchText}
            />
        </div>
    )
}

export default Header
