import React, { useState, useEffect, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux"
import {
    Container,
    Paper,
    Grid,
    Typography,
    Divider,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ListItemCustom from './ListItem'
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"
import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"

import { Helmet } from 'react-helmet'
import Skeleton from "@material-ui/lab/Skeleton"

import useDownloadCentral from './useDownloadCentral'

import { useStyles } from "./styles"

const DownloadCentral = () => {
    const [loadingMoreItens, setLoadingMoreItens] = useState(false)
    const {
        checkedData,
        loading,
        highlightItem,
        hasData,
        items,
        items_total,
        pageLast,
    } = useSelector((state) => state.downloadCentralReducer)

    const { getData, onClickGoBack } = useDownloadCentral()
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()

    useEffect(() => {
        let isMounted = true
        if (isMounted && !checkedData) {
            getData(isMounted)
        }
        return () => {
            isMounted = false
        }
    }, [checkedData, getData])

    const loadMoreItems = useCallback(() => {
        const last = { last: pageLast }
        setLoadingMoreItens(true, getData(true, last))
    }, [getData, pageLast])

    const constants = useMemo(() => ({
        disableButtonMoreItens: ((items.length >= items_total) || loading || loadingMoreItens),
        inProgressData: items.filter(i => (i.state === "in_progress") || (i.state === "created")),
    }), [items, items_total, loading, loadingMoreItens])

    return (
        <div className={classes.header_root}>
            <Helmet>
                <title>{t("DAM:Central de downloads")}</title>
            </Helmet>
            <HeaderMaterialDesign title={t("DAM:Central de downloads")} localHistory={history} />
            <Container maxWidth="md" className={classes.rootContainer}>
                <Paper elevation={2} style={{ padding: 20, marginBottom: 60 }}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            {t("DAM:Central de downloads")}
                        </Typography>
                        <Typography variant='body2'>
                            {t("DAM:Confira os downloads que você solicitou.")}
                        </Typography>
                    </Grid>
                    <Grid item xs style={{ marginBottom: 20, marginTop: 10 }}>
                        <Divider />
                    </Grid>
                    {loading ?
                        <div>
                            <div style={{ padding: '0 20px' }}>
                                <Skeleton variant="rect" width={'100%'} height={110} />
                            </div>
                            <div style={{ padding: '0 20px', margin: '10px 0' }}>
                                <Skeleton variant="rect" width={'100%'} height={110} />
                            </div>
                            <div style={{ padding: '0 20px' }}>
                                <Skeleton variant="rect" width={'100%'} height={110} />
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <Grid item xs={12}>
                                {
                                    !_.isEmpty(highlightItem) ? (
                                        <>
                                            {!_.isEmpty(highlightItem) && <ListItemCustom data={highlightItem} />}

                                            <Accordion className={classes.accordion}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography className={classes.heading}>
                                                        {t("Ver todos")}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs component='ul' style={{ listStyleType: 'none', padding: 0 }}>
                                                        {
                                                            !hasData ? (
                                                                <div style={{ marginBottom: 20 }}>
                                                                    <SimpleAlertMessage severity="info" alertTitle="Não há downloads" />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        items.map(i => <ListItemCustom key={i.id} data={i} />)
                                                                    }
                                                                </>
                                                            )

                                                        }
                                                        <Grid xs={12} item component='li' style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color='primary'
                                                                onClick={loadMoreItems}
                                                                disabled={constants.disableButtonMoreItens}
                                                            >
                                                                {t('common:mostrar mais')}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                    ) : (
                                        <Grid item xs component='ul' style={{ listStyleType: 'none', padding: 0 }}>
                                            {
                                                !hasData ? (
                                                    <div style={{ marginBottom: 20 }}>
                                                        <SimpleAlertMessage severity="info" alertTitle="Não há downloads" />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {
                                                            items.map(i => <ListItemCustom key={i.id} data={i} />)
                                                        }
                                                        <Grid xs={12} item style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color='primary'
                                                                onClick={loadMoreItems}
                                                                disabled={constants.disableButtonMoreItens}
                                                            >
                                                                {t('common:mostrar mais')}
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                )

                                            }
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <Grid xs={12} item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={onClickGoBack}
                                >
                                    {t("common:Voltar")}
                                </Button>
                            </Grid>
                        </React.Fragment>
                    }
                </Paper>
            </Container>
        </div >
    )
}

export default DownloadCentral