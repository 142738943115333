import React, { useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import { useIntersect } from 'components/Intersect'
import { CircularProgress, Typography } from "@material-ui/core"
import useContent from './hook/Content'
import { Creators as pageBucketActions } from "flux/ducks/page_bucket"

const CardWrapperFooter = () => {
    const { checkedData, loadingData, totals, files, loading } = useSelector(state => state.pageBucketReducer)
    const { getPage, getParatmeters, getData } = useContent()
    const [isIntersecting, ref] = useIntersect()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleLoadMore = useCallback(() => {
        if (totals.current > files.length) {
            const page = getPage()
            const parameters = getParatmeters(page)
            getData(parameters)
        }
    }, [totals, files, getData, getParatmeters, getPage])

    useEffect(() => {
        let isMounted = true
        if (isMounted && isIntersecting && checkedData && !loadingData) {
            dispatch(pageBucketActions.setStateByField({ field: "loadingData", value: true }))
            setTimeout(() => handleLoadMore(), 500)
        }
        return () => isMounted = false
    }, [isIntersecting, dispatch, handleLoadMore, checkedData, loadingData])

    return !loading && (
        <>
            {loadingData ? (
                <div style={{ width: '100%', height: 81 }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 20,
                        }}
                    >
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <>
                    {
                        (totals.current === files.length) ?
                            (
                                <div style={{ width: '100%', height: 81 }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: 20,
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            {t("common:Parece que você chegou ao fim")}
                                        </Typography>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {checkedData && <div ref={ref} />}
                                </>
                            )
                    }
                </>
            )}
        </>
    )
}

export default CardWrapperFooter