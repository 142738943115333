import { useEffect, useState, useRef } from 'react';

export function useIntersect() {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [ref, setRef] = useState(null)
  const observerRef = useRef(null);

  useEffect(() => {
    const callback = ([entry]) => setIsOnScreen(entry.isIntersecting)
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1
    }
    observerRef.current = new IntersectionObserver(callback, options);
  }, []);

  useEffect(() => {
    if(ref) {
      observerRef.current.observe(ref);
    }
    return () => {
      observerRef.current.disconnect()
    }
  }, [ref]);

  return [isOnScreen, setRef];
}
