import React, { useState } from 'react'

import { useSelector } from "react-redux";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  Button,
  Typography
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import { LanguageButton } from '../../LanguageButton'

import { useStyles } from "./styles";

export const HeaderPublicRoute = ({
  handleAccessRequest,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const { links, logoTopMenuWEBP, name } = useSelector((state) => state.appReducer.data)
  const classes = useStyles();
  const history = useHistory()
  const { t } = useTranslation()
  const userAuth = localStorage.getItem('user_auth')
  let dataLinks = JSON.parse(links);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const setLink = (link) => {
    if (!link.includes('http')) {
      link = `http://${link}`
      return link
    }
    return link
  }

  const list = () => (
    <div role="presentation" onClick={handleShowMenu} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
      <List className={classes.list}>
        <ListItem button component="a" href="/login">
          Login
        </ListItem>
        <ListItem
          button
          component="a"
          onClick={() => handleAccessRequest("Menu")}
        >
          {t("common:Solicitar acesso")}
        </ListItem>

        {Array.isArray(dataLinks)
          ? dataLinks.map((link, index) => (
            <ListItem
              key={index}
              button
              component="a"
              href={setLink(link.value)}
              target="_blank"
            >
              {link.label}
            </ListItem>
          ))
          : null}
      </List>
      <div className={classes.brandWrapper}>
        <Typography className='brand' variant='caption' align='center'>Powered by Yapoli</Typography>
      </div>
    </div>
  )

  return (
    <>
      <Drawer
        anchor="left"
        open={showMenu}
        onClose={handleShowMenu}
        className={classes.menuMobile}
      >
        {list()}
      </Drawer>

      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className="actionsAppBarLeft">
            {!userAuth && (
              <IconButton
                onClick={handleShowMenu}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>
          <div className="logoAppbar">
            <img src={logoTopMenuWEBP} alt={name} />
          </div>
          <div className={classes.actionsAppBar}>
            <div className='btn-translate'>
              <LanguageButton />
            </div>
            {userAuth ? (
              <Button
                id="btnMenuSup"
                className="btnMenuRequestAcs"
                variant="outlined"
                color="inherit"
                onClick={() => history.push("/")}
              >
                {t("common:DAM")}
              </Button>
            ) : (
              <>
                <button
                  id="btnMenuSup"
                  onClick={() => history.push("/login")}
                  className="btnMenuLogin"
                  style={{
                    border: 'none',
                    background: 'transparent',
                    textAlign: 'left',
                    padding: 0,
                    fontFamily: 'inherit',
                  }}
                >
                  Login
                </button>
                <Button
                  id="btnMenuSup"
                  className="btnMenuRequestAcs"
                  variant="outlined"
                  color="inherit"
                  onClick={() => handleAccessRequest("Button")}
                >
                  {t("common:Solicitar acesso")}
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}