import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)',
      padding: '0 8px',
      '& span svg.MuiSvgIcon-root': {
        fontSize: '35px'
      }
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(180deg)',
      padding: '0 8px',
      '& span svg.MuiSvgIcon-root': {
        fontSize: '35px'
      }
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderRadius: '5px 5px 0 0',
      maxHeight: '50px',
      minHeight:'50px',
    },
    '&. .MuiAccordion-rounded:last-child': {
      borderRadius: '5px'
    },
    '&.MuiAccordion-rounded:first-child': {
      borderRadius: '5px'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '25px'
    }
  },
  summary: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
    maxHeight: '50px',
    minHeight:'50px',
    "& div": {
      display: "flex",
      alignItems: "center",
    },
  },
  heading: {
    color: "#fff",
    marginLeft: "15px",
    fontSize: theme.typography.pxToRem(15),
  },
  details: {
    flexDirection: "column",
    alignItems: "center",
    padding: "0",
    maxHeight: "352px",
    overflow: "auto",
    '& div.content': {
      width: '100%',
      position: "relative",

      '& div.mouseHover': {
        width: "calc(100% - 18px)",
        height: "81px",
        position: "absolute"

      }
    },
    "& .clear": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      height: 14,
      padding: 0,
      fontSize: '0.875rem',
      boxShadow: "none",
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
  },
  error: {
    color: "#F4511E !important"
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: '16px',
    '& .iconsActions': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        color: '#757575',
        cursor: 'pointer',
      },
      '& svg.desabled': {
        color: '#F0F0F0',
        pointerEvents: 'none',
        cursor: "default !important"
      }
    },
    "& .clear": {
      height: '50px',
      color: theme.palette.primary.main,
      backgroundColor: "#fff",
      borderRadius: "0 0 5px 0",
      boxShadow: "none",
      '&:disabled':{
        color: 'rgba(0, 0, 0, 0.3)'
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    },
  },
  row: {
    width: "100%",
    '& .wrapper': {
      display: "flex",
      justifyContent: 'space-between',
      width: "100%",
      borderTop: "1px solid #DEDEDE",
      padding: "5px 18px 5px 16px ",
      height: '81px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      },
    },
    '&:first-child': {
      '& .wrapper': {
        borderTop: 'transparent'
      }
    },
    '& div.wrapperInfo':{
      display: 'flex',
      "& .iconType": {
        width: "96px",
        height: "70px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          maxHeight: '70px'
        }
      }
    },
    '& div.info': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: "100%",
      marginLeft: '15px',
      color: "#808080",
      '& span': {
        display: "flex",
        textTransform: "none",
        fontSize: '0.9375rem',
        fontWeight: 300
      },
      '& span.name': {
        width: '200px',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '& span.size': {
        marginTop: '4px',
        fontSize: '0.75em'
      }
    },
    '& div.info-status': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#757575',
      fontSize: '0.9375rem',
      maxWidth: '170px',
      '& svg.sent': {
        color: '#00B207'
      },
      '& svg.sentError': {
        color: '#F4511E'
      },
      '& .typeFont': {
        lineHeight: "15px",
        fontSize: "0.9375rem",
        fontWeight: 300,
        minWidth: '150px',
        textAlign: 'center'
      }
    },
    '& svg.active': {
      cursor: "pointer",
    },
    '& svg.inactive': {
      color: '#F0F0F0',
      pointerEvents: 'none',
      cursor: "default",
    },
  },
  viewItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      marginBottom: 10
    }
  }
}));
