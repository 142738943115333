import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import _ from "lodash"
import { useSnackbar } from "notistack"
import axios from 'axios'
import { useHistory } from "react-router"

import { 
  Typography,
  Box,
  Button, 
  Tooltip
} from '@material-ui/core';

import ThreeDRotationRoundedIcon from '@material-ui/icons/ThreeDRotationRounded';

import ImageLazyLoad from "components/ImageLazyLoad"

import SearchDialog from './SearchDialog'
import PaperTitle from "../PaperTitle"
import { useStyles } from "./styles"

import MenuCustom from './Menu'

const FilesTextures = ({ idItem }) => {    
  const [openSearch, setOpenSearch] = useState(false)
  const [dataLinks, setDataLinks] = useState([])
  const [loading, setLoading] = useState(true)
  
  const history = useHistory()
  const { t } = useTranslation()
  const { pathServer, requestHeaders } = useSelector(
    (state) => state.appReducer
  )
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const getLinkItem = useCallback((idDestination, id) => {
    const parameters = {
      id: idDestination,
      limit: 30,
      only: ['region.slug', 'division.slug', "segment.slug", "category.slug", "taxonomy.slug"]
    }
    
    axios
      .post(`${pathServer}/item/find`, parameters, requestHeaders)
      .then(({ data }) => {   
        setDataLinks(prev =>
          prev.map(i => {
            if (i.id === id) {                          
              i.destination.url = `/dam/${_.get(data, 'items[0].region.slug', '')}/${_.get(data, 'items[0].division.slug', '')}/${_.get(data, 'items[0].segment.slug', '')}/${_.get(data, 'items[0].category.slug', '')}/${_.get(data, 'items[0].taxonomy.slug', '')}`
            }
            return i
          })
        )
      })
      .catch((err) => {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("manager:Erro inesperado. Contate o suporte")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
  }, [pathServer, requestHeaders, enqueueSnackbar, t])

  const getData = useCallback((id_item) => {   
    axios
        .get(`${pathServer}/item/${id_item}/links/texture`, requestHeaders)
        .then((result) => {    
            const DATA = result.data.map(i => {
              i.isDisabled = true              
              return i
            })
            setDataLinks(DATA || [])   
            
            DATA.map(i => getLinkItem(i.destination.id, i.id)) 
            setLoading(false)      
        })
        .catch((err) => {    
            setLoading(false)   
            const message = err.response
              ? err.response.data.message || err.response.headers["x-message"]
              : t("manager:Erro inesperado. Contate o suporte")
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })          
        })
  }, [pathServer, requestHeaders, enqueueSnackbar, t, getLinkItem])

  useEffect(() => {     
    getData(idItem)
    return () => {}
  }, [pathServer, requestHeaders, enqueueSnackbar, t, getData, idItem])

  const handleClickDirection = (link) => {    
    history.push(link)
  }

  const handleCloseDialog = (hasData) => {    
    if(hasData) {
      getData(idItem)      
    }
    
    setOpenSearch(false)   
  }

  return (
    <>    
      <PaperTitle
        title={t("common:Itens 3D")}
        subTitle={   
          dataLinks.length === 1
            ? t("common:item relacionado", { qtd: dataLinks.length })
            : t("common:itens relacionados", { qtd: dataLinks.length })
        }
        icon={ThreeDRotationRoundedIcon}
      />   

      <div style={{ padding: "30px", borderBottom: "1px solid #DDDDDD", }}>
        <Button
          style={{ height: "40px", width: "100%" }}          
          variant="contained"
          color="primary"          
          onClick={() => setOpenSearch(true)}
          disabled={loading}
        >
          {t("common:Buscar texturas")}
        </Button>
      </div>  

      <div className={classes.root}>            
        {
          dataLinks.length > 0 && (
            <>
              {
                dataLinks.map(link => (
                  <div key={link.id} className={classes.row}>
                   
                  <button
                    className={classes.button} 
                    style={{
                      border: 'none',
                      background: 'transparent',
                      textAlign: 'left',                      
                      fontFamily: 'inherit',                      
                    }}                   
                    onClick={() => handleClickDirection(_.get(link, 'destination.url', '#'))}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "10px", width: 50, height: 50 }}>
                        <ImageLazyLoad
                          images={{
                            imgWEBP: _.get(link, 'destination.thumbnail.thumbnail_card_webp', ''), 
                            imgJPG: _.get(link, 'destination.thumbnail.thumbnail_card_original', '')
                          }}
                          styles={{width: 50, height: 50, maxWidth: 50}}
                          sizeIcon={2}
                        />
                      </div>
                      <Tooltip title={_.get(link, 'destination.name', '')} placement="top"> 
                      <div className={classes.info}>                                        
                        <Typography
                          component="div"
                          variant="body2"
                          style={{ lineHeight: 1.2 }}                         
                        >
                          <Box fontWeight={500} className='MuiTypography-noWrap'>{_.get(link, 'destination.name', '')}</Box>
                          <Box fontWeight={300} fontSize="0.75rem" className='MuiTypography-noWrap'>
                            {_.get(link, 'destination.taxonomy.name', '')}
                          </Box>
                        </Typography>                        
                      </div>
                      </Tooltip> 
                    </div>
                  </button>  
                 
                  <MenuCustom 
                    idItem={idItem}
                    slug={_.get(link, 'destination.taxonomy.slug', '')} 
                    handleGetData={() => getData(idItem)}
                  />

                  </div>                
                ))
              }
            </>

          )
        }

      </div>  
      
      {!loading && (
        <SearchDialog
          open={openSearch}
          handleClose={handleCloseDialog} 
          idItem={idItem} 
          dataItemsSelected={dataLinks}       
        />
      )}
    </>
  )
}

export default FilesTextures