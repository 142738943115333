import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import i18n from "i18next"
import { useTranslation } from 'react-i18next'
import {
    Button,
    Menu,
    MenuItem,
    Tooltip
} from "@material-ui/core"
import { ZendeskAPI } from "react-zendesk"
import TranslateIcon from '@material-ui/icons/Translate';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import AppActions from 'flux/actions/AppActions'
import { getLanguageFromLocalization, getDomain, validHexColor } from 'utils/Helpers'
import Settings from 'utils/Settings'
import _ from 'lodash'

export const LanguageButton = ({ isLogged }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { requestHeaders, pathClient } = useSelector(
        (state) => state.appReducer
    )
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleLngUser = (lng) => {
        axios.put(`${pathClient}/local`, { local: lng }, requestHeaders)
            .then(resp => {
                localStorage.setItem('i18nextLng', lng)
                console.log(`Idioma alterado para ${lng}, salvo com sucesso.`)
            })
            .catch(err => console.log(err))
    }

    const handleUpdateVersionPlatform = (lng) => {
        const domain = getDomain();
        const headers = { ...requestHeaders }
        headers['headers']['X-Localization'] = lng

        axios.post(`${Settings.URL_API}/client`, { dns: domain }, headers)
            .then(resp => {
                const DATA = {
                    ...resp.data,
                    primary_color: validHexColor(_.get(resp, 'data.primary_color', ''))
                }

                dispatch(AppActions.updateVersion(DATA))
            })
            .catch(err => console.log(err))
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng, () => {
            ZendeskAPI("webWidget", "setLocale", lng)
            dispatch(AppActions.changeLanguage(lng))
            if (isLogged) handleLngUser(lng)
        })
        handleUpdateVersionPlatform(lng)
        handleClose()
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getNameBtn = () => {
        const lng = getLanguageFromLocalization(i18n.language)
        const item = Settings.DATA_SUPPORTED_LNG.find(i => i.lng === lng)

        return _.get(item, 'label', lng || '')
    }

    return (
        <>
            <Tooltip title={t("DAM:Idioma")}>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    startIcon={<TranslateIcon />}
                    endIcon={<ExpandMoreRoundedIcon />}
                >
                    {getNameBtn()}
                </Button>
            </Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                elevation={2}
            >
                {
                    Settings.DATA_SUPPORTED_LNG.map((i, key) =>
                        <MenuItem onClick={() => changeLanguage(i.lng)} key={key}>
                            {i.name}
                        </MenuItem>
                    )
                }
            </Menu>
        </>
    )
}