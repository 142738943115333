import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root:{
    '& .MuiDialog-paperWidthSm': {      
     margin: '0 !important',
     padding: '40px',
     minWidth: '550px'
    }
  },  
  title: {  
    padding: '0 0 16px 0',  
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div.info':{
        display: "flex",
        alignItems: 'center',        
      },
      "& svg#main": {
        width: "40px",
        height: "40px",
        backgroundColor: props => props.palette.primary.main,
        borderRadius: "50%",
        padding: "4px",
        marginRight: "10px",
        color: "#fff",
      },      
    },
  },
  desc: {
    padding: "0",
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    color: '#808080',
    fontSize: '1rem',  
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: '0',
    marginTop: '30px',       
    "& button": {
      padding: "10px 16px",      
      minWidth: "140px",
      height: "40px",
      maxHeight: "40px",
      fontWeight: 500,    
    },
    "& button.delete": {
      "& svg": {
        color: "#757575",
      },
      "&:hover": {
        backgroundColor: "#F4511E",
        color: "#fff",
        "& svg": {
          color: "#fff",
        },
      },
    },
  },
}));
