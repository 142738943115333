import React from "react"
import axios from "axios"
import _ from "lodash"
import { withSnackbar } from "notistack"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { compose } from "recompose"
import { arrayMove } from "react-sortable-hoc";

import CreateDefault from "../Core/CreateDefault"
import AttributeComboBox from "components/ComboBox/AttributeComboBox";
import TableAttribute from "../Categories/partials/TableAttributes"

import Utils from "utils/Utils"
import { settings } from "./_settings.js"


import {
  Grid,
  Typography,
  TextField,
  Box
} from "@material-ui/core";

function setAttributesOrder(attributes) {
  attributes = attributes.map((attribute, index) => {
    attribute.order = index
    return attribute
  })
  return attributes
}

class Form extends CreateDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      metadata: [],
      id: _.get(props, 'match.params.id', ''),
      hasData: true,
      name: { pt: "", en: "", es: "" }
    }
    this.IS_CREATE = props.typeForm === "create"
    this.superInsert = super.insert
  }

  componentDidMount() {
    const ID = this.props?.match?.params?.id
    this.setState((prev) => ({
      ...prev,
      id: ID,
    }))
    if (ID) this.getData(ID)
  }

  getData(id) {
    const SELF = this
    const { requestHeaders, pathClient, t, region } = SELF.props

    axios
      .get(`${pathClient}/${region}/${SELF.state.entity}/${id}`, requestHeaders)
      .then(
        function ({ data }) {
          SELF.setState(prev => ({
            ...prev,
            loading: false,
            metadata: _.get(data, 'item.attributes', _.get(data, 'item.metadata', [])),
            name: _.get(data, 'item.name', { pt: "", en: "", es: "" }),
          }))
        })
      .catch((err) => {
        const message = Utils.ajaxErrorGetMessage(err, t)
        SELF.setState(prev => ({ ...prev, loading: false }))
        SELF.showMessage(message, 'error')
      })
  }


  validateForm(parameters) {
    const { t } = this.props
    let formValid = true
    let formErrors = {}

    if (_.get(parameters, "name.pt", '').length === 0 && this.IS_CREATE) {
      formErrors.name_pt = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (_.get(parameters, "name.en", '').length === 0 && this.IS_CREATE) {
      formErrors.name_en = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (_.get(parameters, "name.es", '').length === 0 && this.IS_CREATE) {
      formErrors.name_es = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (parameters.metadata) {
      if (_.get(parameters, "metadata", []).length === 0) {
        formErrors.metadata = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      }
    }

    this.setState(prev => ({ ...prev, formErrors, formValid }))

    return formValid
  }

  getParameters() {
    const SELF = this
    let { name, metadata } = SELF.state

    const dataAttributes = metadata.map(i => ({
      id: i.id,
      order: i.order,
      name: i.name,
      required: Boolean(i.required || false),
      blocked: i.blocked || false,
      ...(i.schema && { schema_id: _.get(i, "schema.value", "") })
    }))

    let params = {
      metadata: dataAttributes,
    }

    if (this.IS_CREATE) {
      params['name'] = name
    }

    return params
  }

  handleChangeName(evt, field) {
    const value = _.get(evt, 'target.value', '')

    this.setState((prev) => ({
      ...prev,
      name: {
        ...prev.name,
        [field]: value
      }
    }))
  }

  handleAddAttribute(attribute) {
    const SELF = this
    const { t } = SELF.props
    let newAttributes = [...SELF.state.metadata]

    let newRegister = {
      required: 0,
      id: attribute.value,
      name: attribute.label,
      can_be_master: attribute.can_be_master,
      order: newAttributes.length,
    };

    if (newAttributes.length <= 999) {
      newAttributes.push(newRegister)
      newAttributes = setAttributesOrder(newAttributes)

      SELF.setState(prev => ({ ...prev, metadata: newAttributes }))
    } else {
      SELF.showMessage(t("Máximo de 1000 metadados permitidos"), "warning")
      return
    }
  }

  handleRemoveAttribute(id) {
    const SELF = this
    let newAttributes = [...SELF.state.metadata]
    newAttributes = newAttributes.filter((item) => item.id !== id)
    newAttributes = setAttributesOrder(newAttributes)
    SELF.setState(prev => ({ ...prev, metadata: newAttributes }))
  }

  handleMoveAttribute = ({ oldIndex, newIndex }) => {
    const SELF = this
    let newAttributes = [...SELF.state.metadata]
    newAttributes = arrayMove(newAttributes, oldIndex, newIndex);
    newAttributes = setAttributesOrder(newAttributes)
    SELF.setState(prev => ({ ...prev, metadata: newAttributes }))
  }

  getParamsAxios() {
    const { pathClient, region, typeForm } = this.props
    const IS_CREATE = typeForm === "create"

    return {
      method: IS_CREATE ? "post" : "put",
      url: IS_CREATE
        ? `${pathClient}/${region}/${this.state.entity}`
        : `${pathClient}/${region}/${this.state.entity}/${this.state.id}`,
    }
  }

  delete = () => {
    const SELF = this
    const { pathClient, requestHeaders, t, region } = SELF.props
    const callback = () => {
      axios
        .delete(`${pathClient}/${region}/${SELF.state.entity}/${SELF.state.id}`, requestHeaders)
        .then(
          function (resp) {
            SELF.showMessage(resp.headers["x-message"], 'success')
            SELF.setState(prev => ({ ...prev, loading: false }),
              () => SELF.props.history.push(`/manager/${SELF.state.route}`)
            )
          })
        .catch((err) => {
          const message = Utils.ajaxErrorGetMessage(err, t)
          SELF.showMessage(message, 'error')
          SELF.setState(prev => ({ ...prev, loading: false }))
        })
    }
    SELF.setState(prev => ({ ...prev, loading: true }), () => callback())
  }

  getForm() {
    const SELF = this
    const { t } = SELF.props
    const { metadata, name } = SELF.state

    let attributesSelecteds = []

    if (metadata.length > 0) {
      metadata.forEach((item) => {
        attributesSelecteds.push(item.id)
      })
    }

    return (
      <Grid container spacing={2} style={{ marginBottom: 24 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name-pt"
            variant="outlined"
            fullWidth
            id="name-pt"
            label={`${t("manager:Nome em português")} *`}
            value={_.get(name, 'pt', '')}
            onChange={evt => this.handleChangeName(evt, 'pt')}
            disabled={this.props.typeForm === "edit"}
            error={Boolean(this.state.formErrors.name_pt)}
            helperText={this.state.formErrors.name_pt ? this.state.formErrors.name_pt : ''}
            style={{ margin: 0 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name-pt"
            variant="outlined"
            fullWidth
            id="name-pt"
            label={`${t("manager:Nome em inglês")} *`}
            value={_.get(name, 'en', '')}
            onChange={evt => this.handleChangeName(evt, 'en')}
            disabled={this.props.typeForm === "edit"}
            error={Boolean(this.state.formErrors.name_en)}
            helperText={this.state.formErrors.name_en ? this.state.formErrors.name_en : ''}
            style={{ margin: 0 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name-pt"
            variant="outlined"
            fullWidth
            id="name-pt"
            label={`${t("manager:Nome em espanhol")} *`}
            value={_.get(name, 'es', '')}
            onChange={evt => this.handleChangeName(evt, 'es')}
            disabled={this.props.typeForm === "edit"}
            error={Boolean(this.state.formErrors.name_es)}
            helperText={this.state.formErrors.name_es ? this.state.formErrors.name_es : ''}
            style={{ margin: 0 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Typography variant='body1' style={{ fontWeight: 500 }}>
              {t('common:Atributos')}
            </Typography>
            <Typography variant='body2' style={{ marginBottom: 20 }}>
              {t('manager:Defina quais metadados serão considerados atributos deste esquema.')}
            </Typography>
          </Box>
          <AttributeComboBox
            disabledItems={attributesSelecteds}
            handleChange={(selectedItem) => this.handleAddAttribute(selectedItem)}
            error={_.get(this.state, 'formErrors.metadata', "")}
            field_required
          />
        </Grid>

        {
          metadata.length > 0 &&
          <Grid item xs={12}>
            <TableAttribute
              items={metadata}
              style={{ marginTop: 0 }}
              switchCells={["required", "blocked", "schema"]}
              handleRemoveAttribute={(id, evt) => this.handleRemoveAttribute(id, evt)}
              handleCheckAttribute={(id) => { }}
              handleMoveAttribute={(obj) => this.handleMoveAttribute(obj)}
            />
          </Grid>
        }
      </Grid>
    )
  }
}

export const mapStateToProps = ({ appReducer }) => {
  return {
    region_id: appReducer.userData.region_id,
    region: appReducer.userData.region,
    region_name: appReducer.userData.region_name,
    region_thumbnail: appReducer.userData.region_thumbnail,
    requestHeaders: appReducer.requestHeaders,
    pathClient: appReducer.pathClient,
    pathServer: appReducer.pathServer,
  }
}

export default compose(
  connect(mapStateToProps)
)(withTranslation(["manager", "common"])(withSnackbar(Form)))
