import React, { Fragment, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Paper,
  Typography,
  Fade,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import axios from "axios";

import { useStyles } from "./styles";

import SvgFailed from "../../../imagens/login/failed-forgot-password.svg";
import Settings from 'utils/Settings'
import PopoverPassword from 'components/PopoverPassword'

const NewPassword = ({ data, t, handleSubmit, loading, responseStatus }) => {
  const classes = useStyles(data);
  let history = useHistory();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [invalidRememberToken, setInvalidRememberToken] = useState(null)
  const [loadingRemeberToken, setloadingRemeberToken] = useState(true)
  const params = new URLSearchParams(history.location.search)
  const token = params.get('token')
  
  useEffect(() => {
    let isMounted = true;
    if (invalidRememberToken === null) {
      const validateRememberToken = async () => {
        await axios
          .post(Settings.URL_API + "/validate-remember-token/" + token)
          .then((result) => {
            if (isMounted) {
              setInvalidRememberToken(false);
              setloadingRemeberToken(false);
            }
          })
          .catch((err) => {
            if (isMounted) {
              setInvalidRememberToken(true);
              setloadingRemeberToken(false);
            }
          });
      };
      validateRememberToken();
      return () => {
        isMounted = false;
      };
    }

  }, [invalidRememberToken, token]);


  const validation = (evt) => {
    evt.preventDefault();

    if (password.length === 0) {
      setPasswordError(t("common:Campo obrigatório"))
      return
    }

    setPasswordError('')

    if (confirmPassword.length === 0) {
      setConfirmPasswordError(t("common:Campo obrigatório"))
      return
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(t("login:Senhas não conferem."))
      return;
    }

    setConfirmPasswordError('')

    if (!(confirmPasswordError || passwordError))
      handleSubmit(evt, { password, token });
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter" && !(confirmPasswordError || passwordError))
      handleSubmit(evt, { password, token });
  };


  const DISABLED_SUBMIT = (Boolean(confirmPassword) && Boolean(password) && (confirmPassword === password))

  return (
    <Fragment>
      <Paper
        elevation={2}
        className={classes.content}
        onKeyDown={(evt) => handleKeyDown(evt)}
        style={{ maxHeight: '100%' }}
      >
        <div className="logoImageLogin" />

        <Typography className="titleMobile">
          {t("login:Redefinição de senha")}
        </Typography>
        {loadingRemeberToken && <CircularProgress color="inherit" />}
        {responseStatus === Settings.RESPONSE_STATUS.NOT_SENT &&
          invalidRememberToken === false && (
            <Fragment>
              <Typography variant='body1'>{t("login:Digite a sua nova senha")}</Typography>

              <form className="formLogin">
                <div style={{ width: "100%", marginTop: 10 }}>
                  <TextField
                    error={Boolean(passwordError)}
                    helperText={
                      Boolean(passwordError) ? passwordError : null
                    }
                    variant="outlined"
                    name="password"
                    label={t("login:Nova senha")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(evt) => setPassword(evt.target.value)}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(prev => !prev)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div style={{ width: "100%", marginTop: 20 }}>
                  <TextField
                    error={Boolean(confirmPasswordError)}
                    helperText={
                      Boolean(confirmPasswordError)
                        ? confirmPasswordError
                        : null
                    }
                    variant="outlined"
                    name="confirmPassword"
                    label={t("login:Repita a nova senha")}
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(evt) => setConfirmPassword(evt.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={() => setShowConfirmPassword(prev => !prev)}
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <PopoverPassword />

                <Button
                  disabled={!DISABLED_SUBMIT || loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={validation}
                  style={{margin: '5px 0'}}
                >
                  {loading ? (
                    <Fade
                      in={loading}
                      style={{
                        transitionDelay: loading ? "800ms" : "0ms",
                      }}
                      unmountOnExit
                    >
                      <CircularProgress color="inherit" />
                    </Fade>
                  ) : (
                    t("login:Redefinir a senha")
                  )}
                </Button>
              </form>
            </Fragment>
          )}

        {responseStatus === Settings.RESPONSE_STATUS.SUCCEEDED && (
          <Fragment>
            <div className="containerText">
              <Typography>
                {t("login:Senha atualizada com sucesso.")}
              </Typography>

              <CheckCircleRoundedIcon color="primary" />
            </div>

            <button
              className={classes.btnForgot}
              onClick={() => history.push("/login")}
              style={{
                border: 'none',
                background: 'transparent',
                textAlign: 'left',
                padding: 0,
                fontFamily: 'inherit',
              }}
            >
              {t("login:Ir para o login")}
            </button>
          </Fragment>
        )}
        {(responseStatus === Settings.RESPONSE_STATUS.FAILED ||
          invalidRememberToken === true) && (
            <Fragment>
              <div className="containerText">
                <Typography>
                  {t("login:Solicitação de redefinição de senha não encontrada.")}
                </Typography>

                <img src={SvgFailed} alt="Usuário não encontrado." />
              </div>

              <button
                className={classes.btnForgot}
                onClick={() => history.push("/login")}
                style={{
                  border: 'none',
                  background: 'transparent',
                  textAlign: 'left',
                  padding: 0,
                  fontFamily: 'inherit',                  
                }}
              >
                {t("login:Ir para o login")}
              </button>
            </Fragment>
          )}
      </Paper>
    </Fragment>
  );
};

export default withTranslation(["common", "login"])(NewPassword);
