import React, { useCallback } from 'react'
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IconButton, Tooltip } from "@material-ui/core"

import { mdiWallpaper } from "@mdi/js"
import Icon from "@mdi/react"
import AppActions from "flux/actions/AppActions"

export default function Menu() {
    const current_region = useSelector((state) => _.get(state, 'appReducer.userData.region', ""))
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleClick = useCallback((evt) => {
        evt.preventDefault()
        dispatch(AppActions.clearMarkedItems())
        history.push(`/search_template/${current_region}/draft`)
    }, [dispatch, history, current_region])

    return (
        <Tooltip title={t("DAM:Rascunhos")}>
            <IconButton
                color="inherit"
                disableRipple
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Icon path={mdiWallpaper} size={1} />
            </IconButton>
        </Tooltip>
    );
}