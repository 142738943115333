import React from 'react'
import ListDefault from "../Core/List/DataGridCustom"
import ListWrapper from '../Core/List/ListWrapper'

import { Tooltip, IconButton} from "@material-ui/core"

import {
  getColumnDelete,
  getColumnEdit,
  getColumnThumbnail,
  getColumnDetails,
  capitalize
} from "../Core/utils"
import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "utils/Flux"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import { withStyles } from "@material-ui/core/styles"
import { compose } from "recompose"

import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded"
import noImage from "imagens/ui/ico_users.svg"

import DialogInviteUsers from './DialogInviteUsers'

import { ReactComponent as OutgoingMailSVG } from "imagens/ui/email_invite.svg"
import { ReactComponent as OutgoingMailDisabledSVG } from "imagens/ui/email_invite_disabled.svg"

const styles = (theme) => ({
  rootDataGrid: {
    "& .not-padding-cell": {
      padding: 0,
      flexWrap: "wrap",
    },
    "& .center": {
      justifyContent: 'center'
    },
  },
})

class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
    }
    this.getUrl = this.getUrl.bind(this)
    this.superRender = super.render
  }

  getUrl() {
    const { pathClient } = this.props
    return `${pathClient}/${this.state.entity}`
  }

  getLine(item) {
    let thumbnail

    if(_.get(item, "thumbnail", "").trim()) {
      thumbnail = _.get(item, "thumbnail", "")
    } else {
      thumbnail = noImage
    }

    let regions = []

    if (!Array.isArray(_.get(item, "regions", false))) {
      Object.entries(_.get(item, "regions", {})).forEach(([key, value]) => {
        if (!value) {
          regions.push({ name: key, thumbnail: {noImage: true} })
        } else {
          regions.push({ name: key, thumbnail: value })
        }
      })
    }

    let line = {
      id: _.get(item, "id", ""),
      thumbnail: { id: _.get(item, "id", ""), thumbnail: thumbnail },
      name: item.name,
      type_approval: _.get(item, "type_approval", ""),
      type_auth: _.get(item, "type_auth", ""),
      domain_email: _.get(item, "domain_email", ""),
      region: regions,
      updating: _.get(item, "updating", false),
    }
    return line
  }

  getColumns() {
    const { t } = this.props

    const thumb = getColumnThumbnail((id) => {
      this.props.history.push(`/manager/${this.state.route}/upload/${id}`)
    }, t, noImage)

    let columns = [
      getColumnDetails("name", t("common:Nome")),
      getColumnDetails(
        "type_approval",
        t("common:Moderação"),
        150,
        (params) => {
          const domain = _.get(params, 'row.domain_email', '')
          let title = "Group"

          if(_.get(params, 'row.type_approval', '') === "yourself") {
            if(_.get(params, 'row.type_auth', '') === "sso") {
              title = t("manager:Por autenticação")
            } else {
              title = "Yourself"
            }
          }

          return domain.length > 1 ? `${title} (${domain})` : `${title}`
        }
      ),
      {
        ...getColumnDetails("region", t("common:Regiões"), 200, params => {
          const value = _.get(params, 'value', [])

          if(_.get(params, 'row.updating', false)) {
            return (
              <Tooltip title={t("manager:Em atualização")} placement="bottom">
                <p
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >{t("manager:Em atualização")}...</p>
              </Tooltip>
            )
          }
          if (value.length > 6) {
            return (
              <>
                {value.slice(0, 6).map((t, i) => (
                  <Tooltip key={i} title={capitalize(t.name)} placement="bottom">
                    <img
                      src={t.thumbnail}
                      alt={t.name}
                      width="20"
                      height="20"
                      style={{ borderRadius: "50%", margin: "2px" }}
                    />
                  </Tooltip>
                ))}
                <Tooltip
                  title={value.map((i) => ` ${capitalize(i.name)} `).toString()}
                  placement="bottom"
                >
                  <MoreHorizRoundedIcon />
                </Tooltip>
              </>
            )
          } else {
            return value.map((t, i) => (
              <Tooltip key={i} title={capitalize(t.name)} placement="bottom">
                <img
                  src={t.thumbnail}
                  alt={t.name}
                  width="20"
                  height="20"
                  style={{ borderRadius: "50%", margin: "2px" }}
                />
              </Tooltip>
            ))
          }
        }),
        headerAlign: 'center',
        cellClassName: "not-padding-cell center",
      },
      {
        field: "invite",
        headerName: t("manager:Convidar"),
        width: 80,
        sortable: false,
        headerAlign: "center",
        cellClassName: "centerCell",
        renderCell: (params) => {
          const id = _.get(params, 'row.id', '')
          const mustBeDisabled = _.get(params, 'row.type_auth', '') === "sso"

          return mustBeDisabled ? (
            <Tooltip
              title={t('manager:Convidar')}
              placement="right"
            >
              <div>
                <IconButton
                  disabled={mustBeDisabled}
                  color='primary'
                  onClick={() => this.setState(prev => ({
                    ...prev,
                    inviteDialogOpen: true,
                    id_profile: id
                  }))}>
                  <OutgoingMailDisabledSVG />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              title={t('manager:Convidar')}
              placement="right"
            >
              <IconButton
                color='primary'
                onClick={() => this.setState(prev => ({
                  ...prev,
                  inviteDialogOpen: true,
                  id_profile: id
                }))}>
                <OutgoingMailSVG />
              </IconButton>
            </Tooltip>
          )
        },
      }
    ]

    columns.unshift(thumb)

    columns.push(
      getColumnEdit((id) => {
        this.props.history.push(`/manager/${this.state.route}/edit/${id}`)
      }, t)
    )

    columns.push(
      getColumnDelete((id, name) => {
        this.setState({
          ...this.state,
          deleteDialogOpen: true,
          idToDelete: id,
          nameToDelete: name,
        })
      }, t)
    )

    return columns
  }

  render(){
    const { hasFab, area, title, route } = this.state

    return (
      <ListWrapper
        area={area}
        title={title.singular}
        route={route}
        hasFab={hasFab}
      >
        {this.superRender()}

        {
          _.get(this.state, 'inviteDialogOpen', false) && (
            <DialogInviteUsers
              open
              id_profile={_.get(this.state, 'id_profile', '')}
              handleClose={() => this.setState(prev => ({
                ...prev,
                inviteDialogOpen: false,
              }))}
            />
          )
        }
      </ListWrapper>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation(["manager", "common"]),
  connect(mapStateToPropsToSettings, null)
)(List)
