import React from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useStyles } from "../styles"
import { useSelector, useDispatch } from "react-redux"
import { useSnackbar } from 'notistack'
import {
    Toolbar,
    Typography,
    Tooltip,
    Button,
    IconButton,
    Badge,
    FormControlLabel,
    Checkbox,
    ListItemIcon
} from '@material-ui/core'

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import NoteAddRoundedIcon from '@material-ui/icons/NoteAddRounded';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded"
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CommentDialog from './CommentDialog'
import AppActions from "flux/actions/AppActions"

import { useDataAssetIntegra } from "../../context"
import { useDataEditFiles } from './context'
import FiltersBucket from 'pages/DAM/Bucket/Filters'
import ActionsBucketManagerAssets from './ActionsBucketManagerAssets'
import Settings from 'utils/Settings'
import Utils from 'utils/Utils'

const ToolsBar = ({ itemID, handleClose, isGallery }) => {
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
    const { getDataItem } = useDataAssetIntegra()
    const {
        dataSelected,
        dataAssets,
        dataTags,
        setDataAssets,
        setDataSelected,
        componentStatus,
        handleComponentStatus,
        loading,
        setLoading,
        dataComment,
        totalAssets,
        actionsHistory,
        setActionsHistory,
        setContextAction,
        contextAction,
        setDataAssetsPrev,
        setDataComment,
    } = useDataEditFiles()

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const classes = useStyles()
    const handleClearBucketAssets = () => {
        dispatch(AppActions.clearMarkedAssets())
    }

    const handleClearItemList = () => {
        setDataSelected([])
    }

    const handleStatusComponent = (status) => {
        handleComponentStatus(status)
    }

    const handleShowSelected = (evt) => {
        if (evt.target.checked) {
            handleStatusComponent('show_selected')
        } else {
            handleStatusComponent('initial')
        }
    }

    const handleNewVersion = () => {
        if (contextAction !== 'version-new') {
            handleClearBucketAssets()
            setActionsHistory([])
            setDataAssets([])
            handleClearItemList()
            setDataAssetsPrev(dataAssets)
            setContextAction('version-new')
        }
        setDataComment('')
        handleStatusComponent('search_bucket')
    }

    const getParameters = () => {
        let parameters = {}

        if (Boolean(dataComment)) {
            parameters['comment'] = dataComment
        }

        if (dataAssets.length > 0) {
            parameters['assets'] = dataAssets.map(i => i.id)
        }

        let tags = {}

        if (dataTags.length > 0) {
            for (let index in dataTags) {
                if (parameters['assets'].some(id => id === dataTags[index].id)) {
                    if (dataTags[index].value.map(i => i.value).length > 0) {
                        tags[dataTags[index].id] = dataTags[index].value.map(i => i.value)
                    }
                }
            }
        }

        if (!_.isEmpty(tags)) {
            parameters['tags'] = tags
        }

        return parameters
    }

    const handleSubmit = () => {
        const callback = () => {
            const parameters = getParameters()

            let axiosParams = {
                method: 'POST',
                url: `${pathServer}/item/${itemID}/version`,
                data: parameters,
                headers: { ...requestHeaders.headers },
            }

            if (!actionsHistory.some(i => (i === 'asset-remove' || i === 'asset-add'))) {
                axiosParams['method'] = 'PUT'
                axiosParams['url'] = `${pathServer}/item/${itemID}/version/0`
            }

            axios(axiosParams)
                .then(function (resp) {
                    const callback = () => {
                        getDataItem()
                        handleClose()
                    }
                    enqueueSnackbar(resp.headers["x-message"], {
                        ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                    })
                    setLoading(false, callback())
                })
                .catch(function (err) {
                    const message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro ao carregar os dados, contate o suporte"))
                    setLoading(false,
                        enqueueSnackbar(message, {
                            variant: "error",
                            autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                        }))
                })
        }
        setLoading(true, callback())
    }

    const getMessageRemoveAssets = () => {
        const dataFiltered = dataAssets.filter(value => {
            const find = dataSelected.find(i => i.id === value.id)
            return find
        })
        let msg = ''
        let data = {
            bucket: false,
            current: false
        }

        for (let index in dataFiltered) {
            if (dataFiltered[index].currentBucket) {
                data['bucket'] = true
            } else {
                data['current'] = true
            }
        }

        if (data.bucket && !data.current) {
            msg = t("DAM:Os ativos removidos permanecem no bucket.")
        }

        if (!data.bucket && data.current) {
            msg = t("DAM:Os ativos removidos permanecem na versão anterior.")
        }

        if (data.bucket && data.current) {
            msg = t("DAM:Os ativos removidos permaneceram no local de origem, na versão anterior ou, se adicionado, no bucket.")
        }

        enqueueSnackbar(msg,
            {
                variant: "info",
                autoHideDuration: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
            }
        )
    }

    const handleRemoveAssets = () => {
        const dataFilter = dataAssets.filter(value => {
            const find = dataSelected.find(i => i.id === value.id)
            return !find
        })
        handleClearItemList()
        setActionsHistory(prev => ([...prev, 'asset-remove']))
        setDataAssets(dataFilter)
        getMessageRemoveAssets()
    }

    const disabledSubmit = loading || actionsHistory.length === 0 || dataAssets.length === 0

    const hasComment = (contextAction === 'version-new') || actionsHistory.some(i => (i === 'asset-remove' || i === 'asset-add'))

    const total = componentStatus.current === 'initial' ? dataAssets.length : totalAssets

    const isBucket = componentStatus.current === 'search_bucket'

    return (
        <div className={classes.rootBar}>
            {
                isBucket ? <FiltersBucket simpleBucket toolbarManagerAssets={<ActionsBucketManagerAssets />} /> : (
                    <Toolbar>
                        <div className={classes.barFilter}>
                            <Typography variant='body2'>
                                {total > 1 ? t(`DAM:total_ativos`, { total: total }) : t(`DAM:total_ativo`, { total: total })}
                            </Typography>

                            {
                                (componentStatus.current === 'initial') &&
                                <>
                                    <div id="line-divider" style={{ marginLeft: 14 }} />

                                    <Tooltip title={t("DAM:Adicionar nova versão")} placement="top">
                                        <div>
                                            <Button
                                                variant="outlined"
                                                color={(contextAction !== 'version-edit') ? "primary" : "inherit"}
                                                startIcon={
                                                    <ListItemIcon style={{ minWidth: 10 }}>
                                                        <UpdateRoundedIcon color={(contextAction !== 'version-edit') ? "primary" : "inherit"} />
                                                    </ListItemIcon>
                                                }
                                                onClick={evt => handleNewVersion()}
                                                disabled={loading || (contextAction !== 'version-edit')}
                                                style={{ border: 'none', padding: 10 }}
                                            >
                                                {t("DAM:Versão em branco")}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={t("DAM:Adicionar ativo")} placement="top">
                                        <div>
                                            <Button
                                                variant="outlined"
                                                color={(componentStatus.current === 'search_bucket') ? "primary" : "inherit"}
                                                startIcon={
                                                    <ListItemIcon style={{ minWidth: 10 }}>
                                                        <NoteAddRoundedIcon color={(componentStatus.current === 'search_bucket') ? "primary" : "inherit"} />
                                                    </ListItemIcon>
                                                }
                                                onClick={() => {
                                                    handleStatusComponent('search_bucket')
                                                }}
                                                disabled={loading || dataAssets.length >= 150 || isGallery}
                                                style={{ border: 'none', padding: 10 }}
                                            >
                                                {t("DAM:Adicionar ativo")}
                                            </Button>
                                        </div>
                                    </Tooltip>

                                    {
                                        hasComment && (
                                            <>
                                                <div id="line-divider" style={{ marginRight: 14 }} />

                                                <CommentDialog>
                                                    {(confirm) => (
                                                        <Tooltip title={t("DAM:Comentários")} placement="top">
                                                            <div>
                                                                <IconButton onClick={confirm()} disabled={loading}>
                                                                    <CommentRoundedIcon color={Boolean(dataComment) ? 'primary' : 'inherit'} />
                                                                </IconButton>
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </CommentDialog>
                                            </>
                                        )
                                    }
                                </>
                            }


                        </div>

                        <div className={classes.barInputSearch} />

                        <div className={classes.barActionsItens}>
                            {
                                dataSelected.length > 0 && (
                                    <>
                                        <Tooltip title={t("DAM:Mostrar selecionados")} placement="top">
                                            <FormControlLabel
                                                control={<Checkbox icon={
                                                    <Badge badgeContent={dataSelected.length} color="primary">
                                                        <CheckCircleOutlineRoundedIcon />
                                                    </Badge>
                                                } checkedIcon={
                                                    <Badge badgeContent={dataSelected.length} color="primary">
                                                        <CheckCircleRoundedIcon color="primary" />
                                                    </Badge>
                                                }
                                                    name="show_selected"
                                                    style={{ margin: 0 }}
                                                    checked={componentStatus.current === 'show_selected'}
                                                    onClick={evt => handleShowSelected(evt)}
                                                    disabled={loading}
                                                />}
                                            />
                                        </Tooltip>

                                        <Tooltip title={t("DAM:Remover ativo do item")} placement="top">
                                            <div>
                                                <IconButton onClick={() => handleRemoveAssets()} disabled={loading}>
                                                    <DeleteRoundedIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>

                                        <Tooltip title={t("DAM:Limpar lista")} placement="top">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button
                                                    component='a'
                                                    onClick={handleClearItemList}
                                                    style={{ height: 40 }}
                                                    disabled={loading}
                                                >
                                                    {t("DAM:Limpar")}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    </>
                                )
                            }

                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{ height: 40 }}
                                    onClick={handleSubmit}
                                    disabled={disabledSubmit}
                                >
                                    {t("common:Salvar")}
                                </Button>
                            </div>
                        </div>
                    </Toolbar>
                )
            }
        </div >
    )
}

ToolsBar.propTypes = {
    itemID: PropTypes.string,
    handleClose: PropTypes.func,
}

export default ToolsBar
