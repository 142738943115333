import React from 'react'
import {
    Grid,
    Dialog,
    Slide,
    Container,
    makeStyles,
} from '@material-ui/core'

import Header from "./Header"
import CreateItem from './Create'
import EditItem from './Edit'
import Aside from './Aside'
import FormItemProvider from './context'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    }
}));

export default function CreateItemDialog({
    open = false,
    handleClose,
    isEdit = false,
    slugItem = ""
}) {
    const classes = useStyles()

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <FormItemProvider isEdit={isEdit}>
                <div className={classes.root}>
                    <Header handleClose={handleClose} />
                    <Grid container style={{ backgroundColor: "#eee" }}>
                        <Grid item xs={9}>
                            <Container style={{ padding: "32px 24px" }}>
                                {isEdit ? <EditItem slugItem={slugItem} handleClose={handleClose} /> : <CreateItem />}
                            </Container>
                        </Grid>
                        <Grid item xs={3}>
                            <Aside />
                        </Grid>
                    </Grid>
                </div>
            </FormItemProvider>
        </Dialog>
    );
}
