import React, { memo } from 'react'
import _ from 'lodash'

import { useDataItems } from '../context'
import { useState } from 'react'
import useItems from '../hook/useItems'

import Card from "components/Layout/Card/CardItem"

const ContentCard = ({ data }) => {
    const { userData: { canManage, canSeePublicationFilter } } = useDataItems()
    const { handleConfirmPublish, handleConfirmDelete } = useItems()
    const [loading, setLoading] = useState(-1)

    const runAction = (index, callback) => {
      setLoading(index)
      callback()
    }

    const endLoading = () => setLoading(-1)

    return (
        <>
            {
                data.length > 0 && data.map((item, index) => {
                    const published = item.hasOwnProperty("published")
                        ? item.published
                        : true
                    return (
                        <Card
                          loading={loading === index}
                          key={`${item.id}-${index}`}
                          dataItem={item}
                          deleteItem={() => runAction(
                            index,
                            () => handleConfirmDelete(item.id, () => endLoading())
                          )}
                          publishItem={(evt, comment) =>
                              runAction(index, () =>
                                handleConfirmPublish(
                                    item.id,
                                    published ? "unpublish" : "publish",
                                    comment,
                                    () => endLoading()
                                )
                              )
                          }
                          canManage={canManage || _.get(item, "showManager", false)}
                          canPublish={canSeePublicationFilter || _.get(item, "canPublish", false)}
                          published={published}
                      />
                    )
                })
            }
        </>

    )
}
export default memo(ContentCard)
