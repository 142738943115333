import { makeStyles } from "@material-ui/core/styles"

const BORDER_COLOR = '#EEE'

export const useStyles = makeStyles((theme) => ({
    rootContainer: {
        paddingTop: 84,
        marginBottom: 70,
        "& .MuiTypography-root": {
            color: '#666'
        },
        "& h5.MuiTypography-root": {
            fontWeight: 500
        }
    },
    rootLi: {
        display: 'flex',
        border: `1px solid ${BORDER_COLOR}`,
        borderRadius: 4,
        marginBottom: 20,
        "& .icon": {
            borderRight: `1px solid ${BORDER_COLOR}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            [theme.breakpoints.down("sm")]: {  
                padding: 0,
            }
        },
        "& .content": {
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            maxWidth: '100%',
            wordBreak: 'break-all'
        }
    },
    alignItems: {
        display: 'flex',
        alignItems: 'center',
    },
    footerLi: {
        display: 'flex',
        alignItems: 'center',
        height: 26,
    },
    accordion: {
        boxShadow: 'none',
        '&:before': {
            opacity: 0
        },
        "& .MuiAccordionSummary-content": {
            flexGrow: 0
        },
        "& .MuiAccordionSummary-root": {
            justifyContent: 'flex-start'
        }
    },
    header_root: {
        '& #header-root-page': {
            position: 'fixed',
            zIndex: '2 !important'
        },
        [theme.breakpoints.down("sm")]: {
            '& #margin-top-mobile': {
                marginTop: 200,
            }
        }
    },
    boxContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down("sm")]: {
            overflow: 'hidden'
        }
    }
}))