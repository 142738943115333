import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Upload from "../Upload";
import { useStyles } from "./styles"

const ClientImage = ({id, title, description, specifications, name, type}) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Grid item xs={12} style={{marginTop: 20}}>
        <Divider/>
      </Grid>

      <Grid item xs={12} style={{margin: '20px 0'}}>
        <Typography variant="body1" gutterBottom>
          {title}
        </Typography> 
        <Typography variant="body2" display="block">
          {description}         
        </Typography>
        <Typography variant="body2" display="block">
          {specifications}         
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {id && <Upload id={id} name={name} classes={classes} type={type} />}
      </Grid>
    </React.Fragment>
  );
};

ClientImage.defaultProps = {
  id: '', 
  title: '',
  description: '',
  specifications: '',
  name: '',
};

ClientImage.propTypes = {  
  id: PropTypes.string, 
  title: PropTypes.string,
  description: PropTypes.string,
  specifications: PropTypes.string,
  name: PropTypes.string,
};

export default ClientImage;
