import React from "react";
import axios from "axios";
import {toast} from 'react-toastify';
import connect from "react-redux/es/connect/connect";
import {mapDispatchToPropsToDam, mapStateToPropsToDam} from "../../utils/Flux";
import { withTranslation } from 'react-i18next';
import { Wrapper } from './Template'

import _ from 'lodash'

class Categories extends React.Component {
  constructor(props) {
    super(props);
    const {t} = props;

    this.state = {
      loading: false,
      data: [],
      breadcrumbs: [],
      message: t("manager:carregando") + '...',
      slugDivision: props.match.params.division,
      slugSegment: props.match.params.segment,
      title: '',
    };
    this.region = props.match.params.region || '';
    this._isMounted = false;
  }

  componentDidMount() {
    const { hasInvalidRegion, invalidateRegion } = this.props
    this._isMounted = true

    if (hasInvalidRegion) {
      invalidateRegion()
    } else {
      this.getData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    const headerPrev = prevProps.requestHeaders.headers
    const headerNext = this.props.requestHeaders.headers   
    
    if(headerPrev['X-Localization'] !== headerNext['X-Localization']){
      this.getData()
    }   
  }

  getData() {
    const SELF = this;
    const {requestHeaders, pathServer, triggerError, t} = this.props;
    const {slugSegment, slugDivision} = this.state;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = {...requestHeaders, cancelToken: source.token}

    axios.post(pathServer + "/category/item", {"segment.slug": slugSegment, "division.slug": slugDivision}, options)
      .then(function (resp) {
        const message = resp.data.items.length ? t("common:Dados retornados com sucesso") : t('common:Nenhum resultado encontrado');
        const title = resp.data.items.length <= 1 ? 'Categoria' : 'Categorias'
        SELF._isMounted && SELF.setState({
          ...SELF.state,
          loading: false,
          data: resp.data.items,
          message,
          title
        }, SELF.getBreadcumb);
      }, function (err) {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          SELF._isMounted && SELF.setState({...SELF.state, loading: false});
          toast.error(err.response.headers['x-message']);
        }
      })
      .catch((err) => triggerError(err));
  }

  getBreadcumb() {
    const SELF = this;
    const {requestHeaders, pathServer, triggerError} = this.props;
    const {slugSegment, slugDivision} = this.state;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = {...requestHeaders, cancelToken: source.token}

    axios.post(pathServer + "/segment/find", {slug: slugSegment, "division.slug": slugDivision}, options)
      .then(function (resp) {
          let newState = {...SELF.state};
          let item = resp.data.items[0];
          newState.breadcrumbs = [
            {label: item.division.name, url: `/dam/${SELF.region}/${item.division.slug}`} ,
            {label: item.name, url: `/dam/${SELF.region}/${item.division.slug}/${item.slug}`}
          ];
          SELF._isMounted && SELF.setState(newState);
        }, err => SELF.responseError(err)
      ).catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          triggerError(err)
        }
      });
  }

  render() {  
    
    const dataPropsCard = (item) => ({
      slug: _.get(this.state, 'slugDivision', ''),
      slugSegment: _.get(this.state, 'slugSegment', ''),
      slugCategory: item.slug || ''
    })   
    
    return (   
        <Wrapper          
          pageDescription={_.get(this.props, 'data.description', '')}
          loading={this.state.loading}
          data={_.get(this.state, 'data', [])}
          region={_.get(this.props, 'region', '')}
          breadcrumbs={this.state.breadcrumbs}
          dataPropsCard={dataPropsCard} 
          title={this.state.title} 
        />
    );
  }
}
export default connect(mapStateToPropsToDam, mapDispatchToPropsToDam)(withTranslation('common')(Categories));
