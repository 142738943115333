import _ from 'lodash'
import Tooltip from "@material-ui/core/Tooltip/Tooltip"
import Zoom from "@material-ui/core/Zoom/Zoom"
import React from "react"
import IconButton from "@material-ui/core/IconButton/IconButton"
import EditIcon from "@material-ui/icons/Edit"
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded"
import Avatar from "@material-ui/core/Avatar"
import previewIcon from "imagens/preview-icon.svg"

import noImage from "../../../imagens/ui/wallpaper_white_24dp.svg"

export function capitalize(text) {
  const newText = text.charAt(0).toUpperCase() + text.slice(1)
  return newText.replace('-', ' ')
}

export function getColumnDetails(
  field,
  headerName,
  width,
  renderCell,
  valueGetter
) {
  const details = {
    field: field,
    headerName: headerName,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const value = _.get(params, 'value', '')
      return (
        <Tooltip title={value} placement="bottom">
          <p
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {value}
          </p>
        </Tooltip>
      )
    },
  }

  if (width) {
    delete details.flex
    details["width"] = width
  }

  if (renderCell) {
    details.renderCell = renderCell
  }

  if (valueGetter) {
    details["valueGetter"] = valueGetter
  }

  return details
}

export function getColumnThumbnail(callback, t) {
  return {
    field: "thumbnail",
    headerName: t("manager:Miniatura"),
    sortable: false,
    width: 110,
    cellClassName: "centerCell",
    headerAlign: "center",
    renderCell: (params) => {
      const id = _.get(params, 'value.id', '')
      const thumbnail = _.get(params, 'value.thumbnail') || noImage
      const workflow = _.get(params, 'row.type', '')  === "WORKFLOW"

      return (
        <Tooltip
          title={t('DAM:Alterar Imagem')}
          placement="right"
          TransitionComponent={Zoom}
        >
          <button
            style={{
              border: 'none',
              background: 'transparent',
              textAlign: 'left',
              padding: 0,
              fontFamily: 'inherit',
              cursor: "pointer",
              zIndex: 200
            }}
            onClick={(evt) => {
              callback(id, workflow)
            }}
          >
              <Avatar
                alt="Thumbnail"
                width="46px"
                heigth="46px"
                src={
                  thumbnail +
                  "?=timestamp=" +
                  Math.floor(Date.now() / 1000)
                }
              />
          </button>
        </Tooltip>
      )
    },
  }
}

export function getColumnEdit(callback, t) {
  return {
    field: "edit",
    headerName: t("common:Editar"),
    width: 70,
    sortable: false,
    headerAlign: "center",
    cellClassName: "centerCell",
    renderCell: (params) => {
      const id = _.get(params, 'row.id', '')
      const name = _.get(params, 'row.name', '')
      const title = _.get(params, 'row.title', '')
      const workflow = _.get(params, 'row.type', '')  === "WORKFLOW"
      const updating = _.get(params, 'row.updating', false)

      if (updating) {
        return;
      }

      return (
        <Tooltip
          title={`${t('common:Editar')} ${Boolean(name) ? name : title}`}
          placement="right"
          TransitionComponent={Zoom}
        >
          <IconButton onClick={() => callback(id, workflow)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )
    },
  }
}

export function getColumnAttributeUsage(callback, t) {
  return {
    field: "usage",
    headerName: t("common:Utilizado em"),
    width: 230,
    sortable: false,
    headerAlign: "center",
    cellClassName: "centerCell",
    renderCell: (params) => {
      const id = _.get(params, 'row.id', '')
      const updating = _.get(params, 'row.updating', false)
      if (updating) {
        return;
      }
      return (
        <Tooltip title={t("common:Visualizar")}>
          <IconButton onClick={() => callback(id)}>
            <img src={previewIcon} alt="Preview icon" />
          </IconButton>
        </Tooltip>

      )
    },
  }
}

export function getColumnUsage(callback, t) {
  return {
    field: "usage",
    headerName: t("common:Utilizado em"),
    width: 230,
    sortable: false,
    headerAlign: "center",
    cellClassName: "centerCell",
    renderCell: (params) => {
      const id = _.get(params, 'row.id', '')
      const updating = _.get(params, 'row.updating', false)
      if (updating) {
        return;
      }
      return (
        <Tooltip title={t("common:Visualizar")}>
          <IconButton onClick={() => callback(id)}>
            <img src={previewIcon} alt="Preview icon" />
          </IconButton>
        </Tooltip>

      )
    },
  }
}

export function getColumnDelete(callback, t) {
  return {
    field: "delete",
    headerName: t("common:Excluir"),
    width: 80,
    sortable: false,
    headerAlign: "center",
    cellClassName: "centerCell",
    renderCell: (params) => {
      const name = _.get(params, 'row.name', '')
      const title = _.get(params, 'row.title', '')
      const updating = _.get(params, 'row.updating', false)

      if (updating) {
        return;
      }

      return (
        <Tooltip
          title={`${t('common:Excluir')} ${Boolean(name) ? name : title}`}
          placement="right"
          TransitionComponent={Zoom}
        >
          <IconButton
            onClick={() =>
              callback(params.getValue("id"), Boolean(name) ? name : title)
            }
          >
            <DeleteRoundedIcon />
          </IconButton>
        </Tooltip>
      )
    },
  }
}

export const DEFAULT_LOCALE_TEXT = {
  // Root
  noRowsLabel: "Nenhum dado encontrado.",
  errorOverlayDefaultLabel: "Erro ao carregar os dados.",
}
