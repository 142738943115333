/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable  no-unused-vars*/
import connect from "react-redux/es/connect/connect";
import { mapDispatchToPropsToSettings, mapStateToPropsToSettings } from "../../utils/Flux";
import CustomComboBox from "./CustomComboBox";
import { withTranslation } from "react-i18next"

class ProfileComboBox extends CustomComboBox {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      route: `${props.pathServer}/user/manageable_profiles`,
      method: 'GET',
      title: props.title ? props.title : `${props.t("manager:Lista")}*`,
    }

  }
  componentDidMount() {
    super.componentDidMount.apply(this);
  }
}

export default connect(
  mapStateToPropsToSettings,
  mapDispatchToPropsToSettings
)(withTranslation(["manager", "common"])(ProfileComboBox))