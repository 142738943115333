import React, { useState } from "react";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import axios from 'axios'
import { useSnackbar } from "notistack"

import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core"
import _ from 'lodash'

import CreatableUIMult from 'components/ComboBox/CreatableUIMult'

import icoSuccess from "imagens/ui/check-white.svg"
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'

import { useStyles } from "./styles"

const DialogInviteUsers = ({ open, handleClose, id_profile }) => {
  const [dataValues, setDataValues] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [submittedErros, setSubmittedErros] = useState({
    submitted: false, success: false, errors: []
  })
  const [inputError, setInputError] = useState('')
  const { requestHeaders, pathServer } = useSelector(
    (state) => state.appReducer
  )

  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles();
  const { t } = useTranslation()

  const validateEmail = (value) => {
    return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || value === "";
  }

  const createOption = (label) => ({
    label: label.trim(),
    value: label.trim(),
    __isNew__: true
  })

  const handleChangeCreatable = (values) => {
    if (Boolean(values) && values.length > 20) {
      setInputError(t("manager:Limite de 20 e-mails inseridos alcançado. Revise a lista e tente novamente."))
    } else {
      setInputError('')
    }

    if (!Boolean(values)) {
      setDataValues([])
      return
    }

    let newValue = []

    values.forEach((item) => {
      if (item.value.includes(',') || item.value.includes(';')) {
        item.value.split(',').forEach((i) => {
          if (Boolean(i)) {
            if (i.includes(';')) {
              i.split(';').forEach((e) => {
                if (Boolean(e)) newValue.push(createOption(e))
              })
            } else {
              newValue.push(createOption(i))
            }
          }
        })
      } else {
        newValue.push(item)
      }
    })

    const data = newValue.map(i => {
      if (!!validateEmail(i.value.trim())) {
        return i
      }

      return { ...i, duplicate: true }
    })


    setDataValues(data)
  }

  const handleInputChangeCreatable = (inputValue) => {
    setInputValue(inputValue)
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        let newValue = Array.isArray(dataValues) ? [...dataValues] : []

        if (inputValue.includes(',') || inputValue.includes(';')) {
          inputValue.split(',').forEach((i) => {
            if (Boolean(i)) {
              if (i.includes(';')) {
                i.split(';').forEach((e) => {
                  if (Boolean(e)) newValue.push(createOption(e))
                })
              } else {
                newValue.push(createOption(i))
              }
            }
          })
        } else {
          newValue = Array.isArray(dataValues) ? [...dataValues, createOption(inputValue)] : [createOption(inputValue)]
        }

        setInputValue('')
        handleChangeCreatable(newValue)
        event.preventDefault()
        break;
      default:
        break;
    }
  }

  const submitForm = () => {
    if (dataValues.some(i => i.duplicate)) {
      return
    }

    setLoading(true)

    const parameters = {
      profile: id_profile,
      invites: dataValues.map(i => ({
        guest_name: i.value,
        guest_mail: i.value,
      }))
    }

    axios.post(`${pathServer}/guest/sendInvite`, parameters, requestHeaders)
      .then((resp) => {
        setLoading(false)
        const DATA = { ...resp.data }

        let success = true
        let daraErrors = []

        Object.keys(DATA).forEach((key) => {
          if (key === 'errors') {
            DATA[key].forEach((value) => {
              daraErrors.push(value)
              success = false
            })
          }
        })

        setSubmittedErros({ submitted: true, success, ...(daraErrors.length > 0 && { errors: daraErrors }) })
      })
      .catch((err) => {
        setLoading(false)
        const message = err.response
          ? t(err.response.data.slug) || err.response.data.message || err.response.headers["x-message"]
          : t("Erro inesperado. Contate o suporte")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
        console.log(err)
      });
  }

  const isInvalidSubmit = dataValues.length === 0 || loading || dataValues.some(i => i.duplicate) || Boolean(inputError)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >

      {
        !_.get(submittedErros, 'submitted', false) ? (
          <>
            <DialogTitle id="alert-dialog-title" className={classes.title}>
              {t("manager:Convidar usuários")}
            </DialogTitle>

            <div>
              <Typography>
                {t("manager:Convide colaboradores para esse Perfil que ainda não possuem acesso a plataforma. Acompanhe o status do convite na página de usuários.")}
              </Typography>

              <Grid item xs={12} style={{ margin: '20px 0' }}>
                <CreatableUIMult
                  title={t("manager:Insira o e-mail de seus convidados")}
                  values={dataValues}
                  handleChangeCreatable={handleChangeCreatable}
                  inputValue={inputValue}
                  handleInputChangeCreatable={handleInputChangeCreatable}
                  handleKeyDown={handleKeyDown}
                  isDisabled={loading}
                  error={inputError}
                />
              </Grid>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                style={{ height: 40, width: 115, marginRight: 20 }}
                variant="outlined"
                color="primary"
                onClick={handleClose}
                disabled={loading}
              >
                {t("common:Cancelar")}
              </Button>
              <Button
                style={{ height: 40, width: 200 }}
                variant="contained"
                color="primary"
                onClick={submitForm}
                disabled={isInvalidSubmit}
              >
                {t("manager:Enviar convite")}
              </Button>
            </div>
          </>
        ) : (
          <>
            {
              _.get(submittedErros, 'errors', []).length > 0 && (
                <DialogTitle id="alert-dialog-title" className={classes.title}>
                  <div className="info">
                    <ErrorRoundedIcon id="main" />
                    <Typography variant='body1' component='span'>
                      {t("manager:Ops, algo deu errado")}
                    </Typography>
                  </div>
                </DialogTitle>
              )
            }
            <div className={classes.root_success}>
              {
                _.get(submittedErros, 'errors', []).length > 0 ? (
                  <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {
                        submittedErros.errors.map((item, key) => (
                          <ListItem key={`${item.email}-${key}`} style={{ width: 228 }}>
                            <ListItemText primary={item.email} secondary={item.error} />
                          </ListItem>
                        ))
                      }
                    </List>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <img src={icoSuccess} alt="Sucesso" />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '20px 0' }}>
                      <Typography variant='body1' style={{ marginBottom: 20 }} align='center'>
                        {t("manager:Convite enviado com sucesso.")}
                      </Typography>
                      <Typography variant='body2' align='center'>
                        {t("manager:Acesse a Página de usuário para acompanhar os convites.")}
                      </Typography>
                    </Grid>
                  </>
                )
              }

              <Grid item xs={12}>
                <Button
                  style={{ height: 40 }}
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  disabled={isInvalidSubmit}
                >
                  {t("manager:ok, entendi")}
                </Button>
              </Grid>
            </div>
          </>
        )
      }

    </Dialog >
  );
};

export default DialogInviteUsers
