import React from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash'
import {
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  Box,
  Hidden,
  CircularProgress
} from "@material-ui/core";

import GetAppIcon from '@material-ui/icons/GetApp';
import SvgFailed from "imagens/login/failed-forgot-password.svg";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import Settings from "utils/Settings";

const SharedComponent = ({
  downloadData,
  responseStatus,
  handleDownload,
  handleOpenDialog,
  showLoading = false,
  platform_name = ""
}) => {
  const { t } = useTranslation()

  return (
    <Grid container className="wrapperPage">
      <Grid item xs={12} className="wrapperTitles">
        <Typography variant="body1" className="title1">
          {platform_name}
        </Typography>
        <Typography variant="body1" className="title2">
          {t("login:Compartilhamento de arquivos")}
        </Typography>
      </Grid>

      <Hidden smDown>
        <Grid item md={4} />
      </Hidden>
      <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center" }}>
        <Paper elevation={2} className="rootPaper">
          {showLoading ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
            >
              <Box className="logoImageLogin" />
              <Grid item xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 32
                }}>
                <CircularProgress color="primary" />
              </Grid>
            </Grid>
          ) : (
            <>
              {responseStatus === Settings.RESPONSE_STATUS.NOT_SENT && (
                <Grid
                  container
                  direction="column"

                  alignItems="center"
                  spacing={2}
                >
                  <Box className="logoImageLogin" />

                  <Grid item xs={12}>
                    <Typography variant="h5" className="p-h5">
                      {t("login:Download de arquivos")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ width: "100%" }}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      flexDirection: "column",
                      width: "100%"
                    }}
                  >
                    <Typography className="name-subtitle" variant="subtitle1">
                      {downloadData.name}
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: "100%"
                      }}
                    >
                      <Typography variant="body2">
                        {
                          _.get(downloadData, 'quantityAssets', 0) === 1
                            ? `${_.get(downloadData, 'quantityAssets', 0)} ${t('DAM:arquivo')}`
                            : `${_.get(downloadData, 'quantityAssets', 0)} ${t('DAM:arquivos')}`
                        } - {_.get(downloadData, 'size', 0)}
                      </Typography>

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenDialog()}
                      >
                        {t("DAM:detalhes")}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ width: "100%" }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} style={{ width: "100%" }}>
                    <Button
                      disabled={!downloadData.available}
                      variant="contained"
                      color="primary"
                      onClick={() => handleDownload(downloadData.name)}
                      startIcon={<GetAppIcon />}
                      style={{ width: "100%" }}
                      size="large"
                    >
                      {t("DAM:Baixar tudo")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{
                    width: "100%",
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: "center"
                  }}>
                    <Typography variant="caption">
                      {t("DAM:Este link será desativado em")} {t("common:x horas e y minutos", { hours: _.get(downloadData, 'ttl.hours', 0), minutes: _.get(downloadData, 'ttl.minutes', 0) })}
                    </Typography>
                    <Typography variant="caption">
                      {`${t("DAM:Compartilhado por")} ${_.get(downloadData, "sender.name", t('DAM:Remetente desconhecido'))}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {responseStatus === Settings.RESPONSE_STATUS.FAILED && (
                <Grid
                  container
                  direction="column"

                  alignItems="center"
                  spacing={2}
                >
                  <Box className="logoImageLogin" />

                  <Grid item xs={12}>
                    <Typography variant="h6" className="p-h5" style={{ textAlign: "center" }}>
                      {t("DAM:Este link foi desativado ou não existe.")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "32px 0" }}>
                    <img src={SvgFailed} width={100} height={100} alt={t('DAM:Link não encontrado.')} />
                  </Grid>
                </Grid>
              )}
              {responseStatus === Settings.RESPONSE_STATUS.SUCCEEDED && (
                <Grid
                  container
                  direction="column"

                  alignItems="center"
                  spacing={2}
                >
                  <Box className="logoImageLogin" />

                  <Grid item xs={12}>
                    <Typography variant="h6" className="p-h5" style={{ textAlign: "center" }}>
                      {t("DAM:Download realizado com sucesso.")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "32px 0" }}>
                    <CheckCircleRoundedIcon color="primary" style={{ fontSize: "6.3rem" }} />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Paper>
      </Grid >
      <Hidden smDown>
        <Grid item md={4} />
      </Hidden>
    </Grid >
  );
};
export default SharedComponent
