import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import Settings from "utils/Settings"
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import DialogConfirm from "components/Alert/DialogConfirm"
import CardErrorDetails from "components/Layout/Card/CardErrorDetails"
import ImageLazyLoad from 'components/ImageLazyLoad'
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded"
import SettingsBackupRestoreRoundedIcon from "@material-ui/icons/SettingsBackupRestoreRounded"
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded"
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded"
import ZoomIn from "@material-ui/icons/ZoomIn"

import DeleteValidationPhrase from 'components/Dialogs/DeleteValidationPhrase'
import ZoomCheck from 'components/Dialogs/ZoomCheck'

import _ from 'lodash'

import Utils from "utils/Utils"

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  gridCard: {
    "& .selectable": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    }
  },
  cardContent: {
    flexGrow: 1,
    color: "#666",
  },
  infoCard: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#fff",
    opacity: 0.9,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    cursor: "pointer",
    zIndex: 2,
  },
  actions: {
    padding: 10,
  },
  markedErrorCard: {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: 4,
    border: `1px solid #E35C33`,
    zIndex: 2,
    "& .content": {
      width: "100%",
      height: "100%",
      backgroundColor: "#E35C33",
      opacity: 0.08,
    },
    "& div.icon-error": {
      position: "absolute",
      padding: 10,
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      "& svg": {
        color: "#E35C33",
        margin: 5,
      },
    },
  },
  iconErrorMobile: {
    padding: 5,
    "& svg": {
      color: "#E35C33",
      margin: 5,
    },
  },
  cardNameHover: {
    height: "100%",
    width: "100%",
    padding: 0,
    display: "flex",
    alignItems: "center",
    "& p": {
      color: "#666",
      fontWeight: 500,
    },
  },
  markedCard: {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    zIndex: 2,
    "& .content": {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.primary.main,
      opacity: 0.08,
    },
    "& div.icon": {
      position: "absolute",
      padding: 10,
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      "& svg": {
        color: theme.palette.primary.main,
        margin: 5,
      },
    },
  },
  cardMedia: {
    paddingTop: '100%'
  },
}))

const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const CardFile = ({
  file,
  isManager,
  handlerReprocess,
  handleConfirmDelete,
  children,
  setZindex = false,
  simpleBucket = false,
  hasIntegration,
  handleClickSelected = () => { },
  markedAssets = []
}) => {
  const [openInfoCard, setOpenInfoCard] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()

  const handleOpenInfoCard = useCallback((chave) => {
    setOpenInfoCard(chave)
  }, [])

  const infoCard = openInfoCard && !(file.status_integration === "ERROR") && !IS_MOBILE
  const isDataMarked = markedAssets.some((f) => f.id === file.id)
  const responsiveProps = { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 }
  const hasIntegrationErrors = (file.status_integration === "ERROR")
  const canSelectAsset = (file.status_pipeline !== "PROCESSING_ERROR" && file.status_pipeline !== "PENDING" && file.status_pipeline !== "WAITING")

  return (
    <Grid
      item
      {...responsiveProps}
      style={{ zIndex: setZindex ? 1350 : '' }}
      onMouseEnter={(evt) =>
        !IS_MOBILE ? handleOpenInfoCard(true) : evt.preventDefault()
      }
      onMouseLeave={(evt) =>
        !IS_MOBILE ? handleOpenInfoCard(false) : evt.preventDefault()
      }
      data-testid='root-card'
      file-id={file.id}
      className={classes.gridCard}
      onClick={() => Boolean(handleClickSelected) ? handleClickSelected(file) : null}
    >
      <Card className="selectable" data-key={file.id}>
        {isDataMarked && (
          <div
            className={
              hasIntegrationErrors
                ? classes.markedErrorCard
                : classes.markedCard
            }
            data-testid='root-card-selected'
          >
            <div className={hasIntegrationErrors ? "icon-error" : "icon"}>
              {hasIntegrationErrors ? (
                <ErrorRoundedIcon />
              ) : (
                <CheckCircleRoundedIcon />
              )}
            </div>
            <div className="content" />
          </div>
        )}

        {infoCard && (
          <div className={classes.infoCard} data-testid='root-info-card'>
            <div className={classes.cardNameHover}>
              {children}
              <div style={{ whiteSpace: 'normal' }}>
                {
                  file.item_name ? (
                    <Typography variant="body1">
                      {`${t('DAM:Ativo pertence ao item')}: ${file.item_name}`}
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      {file.client_name}
                    </Typography>
                  )
                }

              </div>
            </div>
            <div>
              {file.reprocess_integration && hasIntegration && !simpleBucket && (
                <DialogConfirm
                  t={t}
                  title={t("common:Reprocessar arquivo")}
                  description={t(
                    "common:Você tem certeza que deseja reprocessar a integração deste arquivo?"
                  )}
                  icon={<SettingsBackupRestoreRoundedIcon id="main" />}
                  hideCallback={handleOpenInfoCard}
                >
                  {(confirm) => (
                    <Tooltip
                      title={t(
                        "common:Arquivo excluído do DAM. Reenviar para criação do item."
                      )}
                      placement="bottom"
                    >
                      <IconButton
                        onClick={confirm(() => handlerReprocess(file))}
                        style={{
                          padding: "5px",
                        }}
                        data-testid='info-card-btn-reprocess'
                      >
                        <SettingsBackupRestoreRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </DialogConfirm>
              )}

              {isManager && !simpleBucket && (
                <DeleteValidationPhrase
                  entity={t("common:Arquivo")}
                  name={_.get(file, "client_name", "")}
                  hideCallback={handleOpenInfoCard}
                >
                  {(confirm) => (
                    <Tooltip
                      title={t("common:Excluir arquivo")}
                      placement="bottom"
                    >
                      <IconButton
                        onClick={confirm(() => handleConfirmDelete(file))}
                        style={{
                          padding: "5px",
                        }}
                        data-testid='info-card-btn-delete'
                      >
                        <DeleteRoundedIcon style={{ color: "#666" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </DeleteValidationPhrase>
              )}
              {Settings.THUMB_EXTENSIONS.includes(file.extension.toUpperCase()) && (
                <ZoomCheck
                  image={_.get(file, "thumbnail_zoom_webp", _.get(file, "thumbnail_zoom_original", ""))}
                  name={_.get(file, "client_name", "")}
                  hideCallback={handleOpenInfoCard}
                >
                  {(confirm) => (
                    <Tooltip
                      title={t("common:Zoom")}
                      placement="bottom"
                    >
                      <IconButton
                        onClick={confirm()}
                        style={{
                          padding: "5px",
                        }}
                      >
                        <ZoomIn style={{ color: "#666" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </ZoomCheck>
              )}
            </div>
          </div>
        )}

        {openInfoCard && hasIntegrationErrors && (
          <CardErrorDetails
            isManager={isManager}
            handleConfirmDelete={handleConfirmDelete}
            handlerReprocess={handlerReprocess}
            canSelectAsset={canSelectAsset}
            marked={isDataMarked}
            file={file}
            handleMouseLeave={handleOpenInfoCard}
            mobile={IS_MOBILE}
            bucketVersion={simpleBucket}
          />
        )}
        {
          (file.thumbnail_card_original || file.thumbnail_card_webp) &&
          <ImageLazyLoad
            images={{ imgJPG: file.thumbnail_card_original, imgWEBP: file.thumbnail_card_webp }}
            isBucket
          />
        }       
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="body1"
            style={{ fontWeight: 500 }}
            noWrap
          >
            {file.client_name}
          </Typography>
          <br />
          <Typography variant="body2">{`${t('DAM:Criado em')} ${Utils.formatDateForVersions(
            file.created_at, t
          )}`}</Typography>
          <Typography variant="body2">
            {`${t('DAM:Formato')} .`}
            <strong>{file.extension.toUpperCase()}</strong>
          </Typography>
        </CardContent>
        {IS_MOBILE && (
          <div className={classes.actions}>
            {file.reprocess_integration && hasIntegration && !simpleBucket && (
              <DialogConfirm
                t={t}
                title={t("common:Reprocessar arquivo")}
                description={t(
                  "common:Você tem certeza que deseja reprocessar a integração deste arquivo?"
                )}
                icon={<SettingsBackupRestoreRoundedIcon id="main" />}
                hideCallback={handleOpenInfoCard}
              >
                {(confirm) => (
                  <Tooltip
                    title={t(
                      "common:Arquivo excluído do DAM. Reenviar para criação do item."
                    )}
                    placement="bottom"
                  >
                    <IconButton
                      onClick={confirm(() => handlerReprocess(file))}
                      style={{
                        padding: "5px",
                      }}
                    >
                      <SettingsBackupRestoreRoundedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </DialogConfirm>
            )}

            {Settings.THUMB_EXTENSIONS.includes(file.extension.toUpperCase()) && (
              <ZoomCheck
                image={_.get(file, "thumbnail_zoom_webp", _.get(file, "thumbnail_zoom_original", ""))}
                name={_.get(file, "client_name", "")}
                hideCallback={handleOpenInfoCard}
              >
                {(confirm) => (
                  <Tooltip
                    title={t("common:Zoom")}
                    placement="bottom"
                  >
                    <IconButton
                      onClick={confirm()}
                      style={{
                        padding: "5px",
                      }}
                    >
                      <ZoomIn style={{ color: "#666" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </ZoomCheck>
            )}

            {isManager && !simpleBucket && (
              <DeleteValidationPhrase
                entity={t("common:Arquivo")}
                name={_.get(file, "client_name", "")}
                hideCallback={handleOpenInfoCard}
              >
                {(confirm) => (
                  <Tooltip title={t("common:Excluir arquivo")} placement="bottom">
                    <IconButton
                      onClick={confirm(() => handleConfirmDelete(file))}
                      style={{
                        padding: "5px",
                      }}
                      data-testid='info-card-btn-delete'
                    >
                      <DeleteRoundedIcon style={{ color: "#666" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </DeleteValidationPhrase>
            )}
            {hasIntegrationErrors && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  handleOpenInfoCard(true)
                }}
                className={classes.iconErrorMobile}
              >
                <ErrorRoundedIcon />
              </IconButton>
            )}
          </div>
        )}
      </Card>
    </Grid>
  )
}

export default CardFile