import React from 'react'

import {
    Dialog,
    Box,
} from '@material-ui/core'

import RootBoundingbox from "./RootBoundingbox"
import PersonsInAssetSidebar from "./Sidebar"

import { useStyles } from "./styles"
import RecognitionProvider from "./context"

export default function DialogRoot({ open = false, DATA_ASSET, modelType, handleClose }) {
    const classes = useStyles()

    return (
        <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="recognition-and-demarcation" open={open}>
            <RecognitionProvider>
                <Box className={classes.rootDialog}>
                    <RootBoundingbox
                        DATA_ASSET={DATA_ASSET}
                        modelType={modelType}
                    />
                    <PersonsInAssetSidebar
                        handleClose={handleClose}
                        isModelPerson={modelType === "person"}
                    />
                </Box>
            </RecognitionProvider>
        </Dialog>
    );
}
