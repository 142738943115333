import React, {useState} from 'react';

import { MenuItem, Menu, ListItemIcon, Typography, Avatar, ListItemText, Collapse, List, ListItem, useMediaQuery } from "@material-ui/core";
import { useSelector } from 'react-redux';

import passIcon from 'imagens/ui/password_black_24dp.svg';
import poliIcon from "imagens/ui/privacy_tip_black_24dp.svg";
import InputRoundedIcon from '@material-ui/icons/InputRounded';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { OpenInBrowser } from '@material-ui/icons';
import blankAvatar from 'imagens/blank-avatar.svg'

import _ from 'lodash';

import { makeStyles } from "@material-ui/core/styles";

import Utils from 'utils/Utils';
import Settings from 'utils/Settings'

const useStyles = makeStyles((theme) => ({
  menu: {
    top: '32px !important',
    right: "0 !important",
    left: "15px !important",
   
    '& .MuiListItemIcon-root': {
      minWidth: 25,
      marginRight: 10
    },
  },
  menuMobile: {
    "& .MuiPaper-root": {
      top: '15px !important'
    },
  },
  icon: {
    display: 'flex',
    "& img": {
      filter: 'invert(46%) sepia(2%) saturate(13%) hue-rotate(10deg) brightness(84%) contrast(95%)'
    }
  },
  avatar: {
    width: 23,
    height: 23
  }
}))

const UserMenu = ({ anchorEl, user, handleMenuClose, t }) => {
  const { currentPolicy, name } = useSelector(state => state.appReducer.data)
  const reducerData = useSelector((state) => state.appReducer.data);
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)')

  let dataLinks = JSON.parse(reducerData.links);

  const setLink = (link) => {
      if (!link.includes('http')) {
          link = `http://${link}`
          return link
      }
      return link
  }

  const handleClick = () => {
      setOpen(!open)
  }

  const getLinkByLanguage = () => {    
      return Settings.DATA_POLICY.pt // Por hora so temos em portugues 
  }

  const getLinkLength = (data) => {
    if (typeof data === 'string') {
      return data.trim().length
    }
    else return data.length
  }

  const paramsPropsMenu = !isMobile ? {
    className: classes.menu,
    anchorEl: anchorEl.user
  } : {
    className: classes.menuMobile,
    anchorEl: anchorEl.user
  }

  return (
    <Menu      
      open={user.isMenuOpen}
      onClose={() => handleMenuClose('user')}
      {...paramsPropsMenu}
    >
      <MenuItem onClick={() => handleMenuClose('user', '/account')}>
        <ListItemIcon>
          <Avatar
            alt={t("common:Conta")}
            src={blankAvatar}
            className={classes.avatar}
          />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("common:Conta")}
        </Typography>
      </MenuItem>

      <MenuItem onClick={() => handleMenuClose('user', '/account/password')}>
        <ListItemIcon>
          <div className={classes.icon}>
            <img src={passIcon} alt={t("common:Alterar Senha")} />
          </div>
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("common:Alterar Senha")}
        </Typography>
      </MenuItem>

      {
        _.get(currentPolicy, "file", false) && (
          <MenuItem onClick={() => Utils.makeDownload(_.get(currentPolicy, "file", ""))}>
            <ListItemIcon>
              <div className={classes.icon}>
                <img src={poliIcon} alt={t("common:Política de privacidade")} />
              </div>
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t("common:Política de privacidade da", { name: name })}
            </Typography>
          </MenuItem>
        )
      }

      <MenuItem onClick={() => Utils.makeDownload(getLinkByLanguage())}>
        <ListItemIcon>
          <div className={classes.icon}>
            <img src={poliIcon} alt={t("common:Política de privacidade da Yapoli")} />
          </div>
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("common:Política de privacidade da Yapoli")}
        </Typography>
      </MenuItem>

      <MenuItem disabled={getLinkLength(dataLinks) === 0} onClick={handleClick}>
        <ListItemIcon>
          <div className={classes.icon}>
              <OpenInBrowser />
          </div>
        </ListItemIcon>
        <ListItemText>{t('common:Links externos')}</ListItemText>
        {getLinkLength(dataLinks) === 0 ? null : open ? <ExpandLess color='primary' /> : <ExpandMore />}
      </MenuItem>

      <Collapse in={open} unmountOnExit component='li'>
        <List>
          {
            Array.isArray(dataLinks)
              ? dataLinks.map((link) => (
                <ListItem button
                  component="a"
                  href={setLink(link.value)}
                  target="_blank"
                  key={link.value}
                >
                  <ListItemText>{link.label}</ListItemText>
                </ListItem>
              ))
              : null
          }
        </List>
      </Collapse>
     

      <MenuItem onClick={() => handleMenuClose('user', null, 'logout')}>
        <ListItemIcon>
          <InputRoundedIcon />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t("common:Sair")}
        </Typography>
      </MenuItem>

      <div style={{display:'flex', justifyContent: 'center'}}>
        <MenuItem button={false}>
          <Typography style={{ color: '#666666' }} variant="caption" noWrap>
            {t("common:Powered by Yapoli")}
          </Typography>
        </MenuItem>
      </div>
    </Menu> 

  );
};

export default UserMenu;
