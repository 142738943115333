import React, { useMemo, useCallback, useState } from 'react'
import _ from 'lodash'
import filesize from 'filesize'

import { useTranslation } from "react-i18next"

import { Grid, Typography, Tooltip, Avatar, CircularProgress, IconButton, Box } from '@material-ui/core'

import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import { useStyles } from "./styles"

import Utils from 'utils/Utils'
import useDownloadCentral from './useDownloadCentral'

const ListItemCustom = ({ data = {} }) => {
    const [read, setRead] = useState(data.state === 'consumed')
    const classes = useStyles()
    const { t } = useTranslation("DAM")

    const { downloadFile } = useDownloadCentral()

    const download = _.debounce(data => downloadFile(data, "manual"), 1000)
    const handleDownloadFile = useCallback(() => {
        setRead(true, download(data))
    }, [data, download])

    const constants = useMemo(() => ({
        AMOUNT_ITENS: data.items_quantity === 1 ? `1 ${t("common:item")}` : `${data.items_quantity} ${t("common:itens")}`,
        ICON: ((data.state === 'in_progress') || (data.state === 'created'))
            ? (
                <Tooltip title={t("common:Processando")} placement="top">
                    <CircularProgress style={{ width: 24, height: 24, margin: 12 }} />
                </Tooltip>
            ) : (
                <Tooltip title={data.state === 'expired' ? t("common:Expirado") : t("common:Baixar")} placement="top">
                    <div>
                        <IconButton disabled={((data.state === 'expired') || (data.state === 'invalid'))} onClick={handleDownloadFile}>
                            <GetAppRoundedIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            ),
        ERROR: (data.state === 'invalid'),
        NAME: (data.name && data.name.length > 186) ? `${data.name.slice(0, 180)}...` : data.name,
        IS_MORE_ITEMS_QUANTITY: data.items_quantity > 0,
        IS_MORE_SIZE: data.size > 0,
    }), [data.items_quantity, data.state, data.name, data.size, t, handleDownloadFile])

    return (
        <Grid item xs={12} component='li' className={classes.rootLi} id="ListItemCustom-li">
            {
                !constants.ERROR ? (
                    <>
                        <Grid className='icon'>
                            {constants.ICON}
                        </Grid>
                        <Box className={classes.boxContent}>
                            <Grid className='content'>
                                <Tooltip title={_.get(data, 'name', '')} placement="top">
                                    <Typography variant={'body1'}>
                                        {constants.NAME}
                                    </Typography>
                                </Tooltip>
                                {
                                    (constants.IS_MORE_ITEMS_QUANTITY || constants.IS_MORE_SIZE) && (
                                        <Typography variant='caption'>
                                            {constants.IS_MORE_ITEMS_QUANTITY && `${constants.AMOUNT_ITENS}`}
                                            {constants.IS_MORE_ITEMS_QUANTITY && constants.IS_MORE_SIZE && ` - `}
                                            {constants.IS_MORE_SIZE && filesize(_.get(data, 'size', 0))}
                                        </Typography>
                                    )
                                }
                                <Grid className={classes.footerLi} id="ListItemCustom-date-info">
                                    <Tooltip title={_.get(data, 'region.name', '')} placement="top">
                                        <Avatar
                                            alt={_.get(data, 'region.name', '')}
                                            style={{ width: 20, height: 20, marginRight: 5 }}
                                            src={_.get(data, 'region.thumbnail', '')}
                                        />
                                    </Tooltip>
                                    <Typography variant={'body2'}>
                                        {Utils.formatDateFull(_.get(data, 'created_at', ''))}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ padding: '0 20px' }}>
                                {
                                    !read && <Grid style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: '#43a048' }} />
                                }
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <>
                        <Grid className='icon'>
                            <Tooltip title={t("manager:Erro ao processar, contate o administrador.")} placement="top">
                                <ErrorRoundedIcon style={{ margin: 12, color: '#F4511E' }} />
                            </Tooltip>
                        </Grid>
                        <Grid className='content'>
                            <Typography component='span' variant='body2'>
                                {t("DAM:Não foi possível concluir a solicitação do download.")}
                            </Typography>
                            <Typography component='span' variant='caption'>
                                {t("DAM:Tente novamente ou entre em contato com o suporte.")}
                            </Typography>

                            <Grid className={classes.footerLi}>
                                <Tooltip title={_.get(data, 'region.name', '')} placement="top">
                                    <Avatar
                                        alt={_.get(data, 'region.name', '')}
                                        style={{ width: 20, height: 20, marginRight: 5 }}
                                        src={_.get(data, 'region.thumbnail', '')}
                                    />
                                </Tooltip>
                                <Typography variant={'body2'}>
                                    {Utils.formatDateFull(_.get(data, 'created_at', ''))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </Grid >
    )
}

export default ListItemCustom