import React, { useState, useEffect, useCallback } from 'react'
import { convertToRaw } from 'draft-js';
import axios from 'axios'
import { useSnackbar } from 'notistack'
import {
    Typography,
    Grid,
    Button,
    ButtonGroup
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { useStyles } from './styles'
import MUIRichTextEditor from "mui-rte"

import Settings from "utils/Settings"
import { useSelector } from "react-redux";
import _ from 'lodash'
import { getRequiredLngCombo } from 'utils/Helpers'

const RequestPersonalization = () => {
    const [entity, setEntity] = useState('support_text_region')
    const [dataText, setDataText] = useState({ pt: { initial_value: '', data: {} }, es: { initial_value: '', data: {} }, en: { initial_value: '', data: {} } })
    const { requestHeaders, data: { client_id, primaryColor }, pathServer } = useSelector((state) => state.appReducer)
    const [loading, setLoading] = useState(true)

    const { t } = useTranslation()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const TEXTS_SUPPORT_ENTITY = {
        support_text_region: 'Explique como identificar qual Região o solicitante deve pedir acesso',
        support_text_profile: 'Explique como identificar qual Perfil o solicitante deve pedir acesso',
        support_text_complementary: 'Explique porque são necessários os dados complementares',
        support_text_personal: 'Explique porque são necessários os dados pessoais'
    }

    const defaultTheme = createMuiTheme({
        palette: {
            primary: {
                main: primaryColor
            }
        },
        overrides: {
            MUIRichTextEditor: {
                container: {
                    margin: 0
                },
                toolbar: {
                    borderBottom: '1px solid #eee',
                    padding: '8px 0',
                },
                placeHolder: {
                    margin: 0,
                    padding: '19px 16px',
                },
                editorContainer: {
                    margin: 0,
                    padding: '19px 16px',
                }
            }
        }
    }
    )

    const getData = useCallback((field) => {
        setLoading(true)
        axios
            .get(`${pathServer}/client/form/${client_id}/supportText`, requestHeaders)
            .then(
                function ({ data }) {
                    if (data.hasOwnProperty(field) && !_.isEmpty(data[field])) {
                        let newDataInitialValues = {}

                        Object.entries(data[field]).forEach(([key, value]) => {
                            newDataInitialValues = {
                                ...newDataInitialValues,
                                [key]: _.get(value, 'formatter.format', '')
                            }
                        })

                        setDataText(prev => {
                            let newState = {}

                            Object.entries(prev).forEach(([key]) => {
                                newState[key] = { ...newState[key], initial_value: newDataInitialValues[key] }
                            })

                            return newState
                        })
                    } else {
                        setDataText(prev => {
                            let newState = {}

                            Object.entries(prev).forEach(([key]) => {
                                newState[key] = { ...newState[key], initial_value: '' }
                            })

                            return newState
                        })
                    }
                    setLoading(false)
                }
            )
            .catch(err => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
            })
    }, [client_id, requestHeaders, enqueueSnackbar, pathServer, t])

    useEffect(() => {
        getData(entity)
    }, [entity, getData])

    const handleChangeEditorDraft = (editorState, lng) => {
        if (Boolean(dataText[lng])) {
            const editorConverted = convertToRaw(editorState.getCurrentContent())
            const textPerLine = editorConverted.blocks.map((el) => el.text)

            const support_text = {
                text: Boolean(textPerLine) ? textPerLine.join(" ") : '',
                formatter: {
                    name: "MUI-RTE/DraftJs",
                    version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
                    format: JSON.stringify(editorConverted),
                },
            }
            setDataText((prev) => ({ ...prev, [lng]: { ...prev[lng], data: support_text } }))
        }
    }

    const handleSetStateData = () => {
        let newData = {}
        Object.entries(dataText).forEach(([key, value]) => {
            newData[entity] = { ...newData[entity], [key]: value.data }
        })

        return newData
    }

    const handleSubmit = () => {
        const params = handleSetStateData()
        setLoading(true)
        axios
            .put(`${pathServer}/client/updateSupportText/${client_id}`, params, requestHeaders)
            .then(function (resp) {
                enqueueSnackbar(resp.headers['x-message'], {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                })
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
            })
    }

    const handleClickEntity = (evt, value) => {
        evt.preventDefault()
        if (entity !== value) {
            setEntity(value)
        }
    }

    return (
        <>
            <Grid item xs={12} style={{ margin: '30px 0 50px 0' }}>
                <Typography>{t('solicitacaoAcesso:Ajude o novo usuário da plataforma a saber qual opção ele deve escolher em cada passo da solicitação de acesso.')}</Typography>
            </Grid>

            <Grid item xs={12} className={classes.groupButton}>
                <ButtonGroup variant="text" aria-label="text primary button group">
                    <Button
                        onClick={evt => handleClickEntity(evt, 'support_text_region')}
                        color={entity === 'support_text_region' ? 'primary' : 'default'}
                    >
                        {t("common:Região de acesso")}
                    </Button>
                    <Button
                        onClick={(evt) => handleClickEntity(evt, 'support_text_profile')}
                        color={entity === 'support_text_profile' ? 'primary' : 'default'}
                    >
                        {t("common:Tipo de perfil")}
                    </Button>
                    <Button
                        onClick={(evt) => handleClickEntity(evt, 'support_text_complementary')}
                        color={entity === 'support_text_complementary' ? 'primary' : 'default'}
                    >
                        {t("common:Dados complementares")}
                    </Button>
                    <Button
                        onClick={(evt) => handleClickEntity(evt, 'support_text_personal')}
                        color={entity === 'support_text_personal' ? 'primary' : 'default'}
                    >
                        {t("common:Dados pessoais")}
                    </Button>
                </ButtonGroup>
            </Grid>

            <Grid item xs={12} style={{ margin: '30px 0' }}>
                <Typography>{t(`manager:${TEXTS_SUPPORT_ENTITY[entity]}`)}</Typography>
            </Grid>

            <Grid>
                {getRequiredLngCombo().map(dataLng => (
                    <div key={dataLng.lng}>
                        <div
                            className={classes.formField}
                            style={{ height: "auto" }}
                        >
                            <label className={classes.labelDescription}>
                                {t(`manager:${_.get(dataLng, 'short_name', '')}`)}
                            </label>
                            <div className={classes.wrapperRichText}>
                                <MuiThemeProvider theme={defaultTheme}>
                                    <MUIRichTextEditor
                                        readOnly={loading}
                                        maxLength={500}
                                        controls={["bold", "italic", "underline", "link"]}
                                        label={t("common:Descrição")}
                                        value={_.get(dataText, `${dataLng.lng}.initial_value`, '')}
                                        onChange={(evt) => handleChangeEditorDraft(evt, dataLng.lng)}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                        <div className={classes.infoLength}>
                            <p>
                                {`${_.get(dataText, `${dataLng.lng}.data.text`, '').length}/500`}
                            </p>
                        </div>
                    </div>
                ))}
            </Grid>

            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: 20
            }}>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                >
                    {t("common:Salvar")}
                </Button>
            </div>
        </>
    )
}

export default RequestPersonalization