import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux"

import { withTranslation } from "react-i18next";
import Settings from "../../utils/Settings";

import ConfirmEmail from "../../components/ConfirmEmail";

import Loading from "../../components/Loading";

const ConfirmEmailContainer = ({ tela, t }) => {  
  const [loading, setLoading] = useState(false);  
  const [responseMsg, setResponseMsg] = useState("");   
  const { requestHeaders } = useSelector((state) => state.appReducer)

  let history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const action = params.get("action");
  const user = params.get("user");  
  
  useEffect(() => {    
      let isMounted = true; 
      const confirmEmail = () => {
        setLoading(true);        
        axios
          .post(`${Settings.URL_API}/moderate/yourself/${action}/${user}`,{}, requestHeaders)
          .then(function (response) {
            if (isMounted) {              
              setResponseMsg(response.headers["x-message"]);
              setLoading(false);              
            }
          })
          .catch(function (err) {
            if (isMounted) {          
              setResponseMsg(
                err.response.data.message || err.response.headers["x-message"]
              );
              setLoading(false);
            }
          });
      };
      confirmEmail();
      return () => {
        isMounted = false;
      };
    
  }, [action, user, requestHeaders]);

 
  return (
    <React.Fragment>      
      <Helmet>
        <title>{t('common:Confirmação de Email')}</title>
      </Helmet>
      <Loading showLoading={loading} />
      <ConfirmEmail      
        loading={loading}
        message={responseMsg}
        history={history}        
      />
    </React.Fragment>
  );
};

export default withTranslation(["login", "common"])(ConfirmEmailContainer);
