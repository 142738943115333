import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root_dialog: {
        [theme.breakpoints.down("sm")]: {
            zIndex: '3333300 !important',
            '& .MuiDialog-paper': {
                margin: 0,
                maxWidth: '100%',
                width: '100%',
                height: '100%',
                maxHeight: '100%'
            }
        }
    },
    root_title: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
        padding: '0 20px',
        marginBottom: 20,
        '& .center': {
            display: 'flex',
            alignItems: 'center',
            '& p': {
                marginLeft: 10,
                fontWeight: 500
            }
        }
    },
    content: {
        '& p.MuiFormHelperText-root': {
            textAlign: 'right'
        },
        '& p.MuiTypography-root': {
            color: "#666"
        }
    },
    button_support: {
        bottom: 0,
        right: 0,
        marginBottom: 14,
        marginRight: 146,
        position: 'fixed',
        backgroundColor: theme.palette.primary.main,
        padding: '.76857rem 1.57143rem',
        borderRadius: '999rem',
        border: 'none',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 46,
        zIndex: 999998,
        '& p': {
            marginLeft: 8,
            fontWeight: 700,
            fontFamily: 'inherit',
            letterSpacing: 0.6
        },
        [theme.breakpoints.down("sm")]: {
            padding: 16,
            marginRight: 77,
            marginBottom: 11,
            height: 50,
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end', 
        padding: '20px 0 8px 0',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            '& button': {
                margin: '0 !important',
                width: '100% !important'
            },
            '& button:last-child': {
                marginBottom: '20px !important',
            }
        }

    },
    content_send: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 382,
        minWidth: 550,
        '& .center': {
            margin: '20px 0 60px 0'
        },
        "& svg": {
            color: '#4CAF50'
        }
    }
}));
