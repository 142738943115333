import React from "react";
import Template from "../Template";
import axios from "axios/index";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../../components/Loading";
import {connect} from "react-redux";
import {mapDispatchToPropsToSettings, mapStateToPropsToSettings} from "../../../utils/Flux";
import { withTranslation } from 'react-i18next';

import { 
  Grid, 
  Button, 
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment
 } from "@material-ui/core";

import { WrapperPaperForms } from '../../../components/WrapperForms'
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';

class DivisionNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",    
      cod_external_segment: '',     
      loading: false,
      data: {},
      dataForm: {},
      values: [],
      formErrors: {},
      formValid: false,
      selectedOption: null,
      region_id: props.region_id
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
  
  }

  formSubmit(e) {
    e.preventDefault();
    const SELF = this;
    const {requestHeaders, pathServer, triggerError} = SELF.props;

    const data = {
      "region_id": SELF.state.region_id,
      "name": SELF.state.name,
      "cod_external_segment": SELF.state.cod_external_segment
    };

    const formValid = this.validateField(data)
    
    if (!formValid) {     
      return false;
    }

    SELF.setState({...SELF.state, loading: true});

    setTimeout(function () {    
      axios.post(`${pathServer}/division`, data, requestHeaders)
        .then(function (resp) {
          toast.success(
            resp.headers['x-message'],
            {
              autoClose: 1500, onClose: () => {
                SELF.props.history.push("/manager/divisions/list");
              }, pauseOnHover: false
            }
          );
        }, function (err) {
          SELF.setState({...SELF.state, loading: false});
          triggerError(err);
        })
        .catch((err) => triggerError(err));
    }, 250);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  validateField(parameters) {
    const { t } = this.props;
    let fieldValidationErrors = this.state.formErrors;

    Object.entries(parameters).forEach(([key, value]) => {
      switch (key) {
        case 'name':
          fieldValidationErrors.name = value.length >= 3 ? '' : '* ' + t('common:Este campo deve conter mais que 3 caracteres');
          break;
        default:
          break;
      }
    })

    const formErrors = {
      ...this.state.formErrors,
      ...fieldValidationErrors
    }

    const getFormValid = (() => {
      let formValid = true

      for (var value in formErrors){      
        if(formErrors[value] && formErrors[value].trim().length > 0) {
          formValid = false
        }
      }
      return formValid
    })()    

    this.setState({
      ...this.state,
      formErrors,     
      formValid: getFormValid,
    });

    return getFormValid
  }
  
  errorClass(error) {
    return (error.length === 0 ? 'form-control' : 'form-control has-error');
  }

  render() {
    const {t} = this.props;
    
    return (
      <Template area={1} localHistory={this.props.history} customClassName={"container-center"}>
        <Loading showLoading={this.state.loading}/>

        <WrapperPaperForms title={t("manager:Criação de divisão")}>
          <Grid item xs={12}>
            <TextField            
              name="name"
              label={`${t("Nome da divisão")}*`}
              variant="outlined"
              value={this.state.name}
              onChange={(event) => this.handleUserInput(event)}         
              error={Boolean(this.state.formErrors.name)}
              helperText={this.state.formErrors.name ? this.state.formErrors.name : ''}
            />
          </Grid>
        

          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-cod_external_segment">{t("common:Código externo segmento")}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cod_external_segment"
                type="text"
                name="cod_external_segment"
                value={this.state.cod_external_segment}
                onChange={(event) => this.handleUserInput(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <VpnKeyRoundedIcon className='color-666' />
                  </InputAdornment>
                }
                labelWidth={187}
              />
            </FormControl>
          </Grid>

          <div className='paper-actions'>
            <Button
              style={{ height: 40, width: 115, marginRight: 20 }}
              variant="outlined"
              color="primary"
              onClick={() => this.props.history.push("/manager/divisions/list")}
            >
              {t("common:Voltar")}
            </Button>
            <Button
              style={{ height: 40, width: 115, }}
              variant="contained"
              color="primary"
              onClick={this.formSubmit}
              disabled={this.state.loading}
            >
              {t("common:Salvar")}
            </Button>
          </div>
        </WrapperPaperForms>
      </Template>
    );
  }
}

export default connect(mapStateToPropsToSettings, mapDispatchToPropsToSettings)(withTranslation(['manager', 'common'])(DivisionNew));
