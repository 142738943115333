import React from 'react';
import { Skeleton as SkeletonMUI } from '@material-ui/lab'
import { Paper } from '@material-ui/core';

const ListSkeleton = () => {
    const countRows = []
    for (let i = 0; i < 30; i++) {
        countRows.push(i)
    }

    return (
        <>
            <Paper style={{margin: '10px 20px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems:'center'}}>
                {
                    countRows.map((i) => {
                        return (
                            <SkeletonMUI key={i} variant='text' width='95%' height={100} style={{marginTop: 0}} />
                        )

                    })
                }
            </Paper>
        </>
    )
}

export default ListSkeleton