import { makeStyles } from "@material-ui/core/styles"

import playButton from "../../../../../imagens/ui/ico-font.svg"

export const useStyles = makeStyles((theme) => ({
  rootTags: {
    position: "absolute",
    width: "100%",
    height: "100%",
    "& div.content": {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      padding: 15,
      "& .MuiChip-label": {
        padding: 12,
      },
    },
  },
  rootActions: {
    position: "absolute",
    backgroundColor: "rgba(30, 22, 22, 0.9)",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiDivider-root": {
      height: 2,
      backgroundColor: "rgba(30, 22, 22, .5)",
    },
  },
  mediaRoot: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  mediaContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    "& .actionHover": {
      background: `url(${playButton})`,
      backgroundPosition: "top left",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      width: "80%",
      height: "80%",
      cursor: "pointer",
    },

    [theme.breakpoints.down("sm")]: {
      "& div#imageHero": {
        width: "100vw !important",
      },
      "& video": {
        width: "100vw !important",
      },
    },
  },
}))
