import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  itemsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 10,
    maxWidth: 480,
    boxSizing: 'border-box',
    '& #MultiValueRemove': {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.3)'
      }
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: '1fr'
    },
  },
  contentItem: {
    padding: 15,
    border: '1px solid #efefef',
    cursor: 'grab !important',
    '& #imageHero': {
      borderRadius: 4
    }
  },
  labelDescription: {
    top: 9,
    left: 11,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-block',
    padding: '0 3px',
    zIndex: 1,
    position: 'relative',
    fontSize: 14,
    background: '#fff',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  SortableHelperWithOverride: {
    pointerEvents: 'auto !important',
    cursor: 'grabbing !important',
    zIndex: '10000000 !important',
  }
}))
