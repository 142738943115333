import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      margin: '0 !important',
      padding: '40px',
      minWidth: '550px',
      color: '#666',
    },   
    [theme.breakpoints.down("sm")]: {
      '& .MuiDialog-paperWidthSm': {
        minWidth: '100%',
      },
    },
  }
}))
