import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    rootBar: {
        width: '100%',
        backgroundColor: '#fff',
        color: '#666',
        position: 'fixed',
        marginTop: 64,
        zIndex: 1100,
        '& a': {
            color: '#666'
        },
        '& #content-divider': {
            display: 'flex',
            alignItems: 'center',
        },
        '& #line-divider': {
            height: 35,
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            margin: '0 5px',
        },
        '& #svg-primary': {
            color: `${theme.palette.primary.main} !important`,
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 60,
            '& div.MuiToolbar-root': {
                flexWrap: 'wrap'
            }
        }
    },
    barFilter: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '90%',
        '& p#title': {
            marginLeft: 9,
        },
        "& .buttonAllDownload": {
            marginLeft: 10
        },
        "& .buttonAllShared": {
            marginLeft: 10
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            flexWrap: "wrap",
            justifyContent: "center",
            '& p': {
                width: '40%'
            },
            '& #input_search': {
                width: '100%',
                backgroundColor: '#eee',
                '& .MuiInputBase-root': {
                    width: '100%',
                    '& input': {
                        color: '#666 !important'
                    }
                }
            },
            "& .buttonAllDownload": {
                margin: "5px 10px 10px 0",
                "& .MuiButton-startIcon": {
                    display: 'none'
                },
            },
            "& .buttonAllShared": {
                marginBottom: 10,
                "& .MuiButton-startIcon": {
                    display: 'none'
                },
            }
        }
    },
    barInputSearch: {
        width: '100%',
        justifyContent: 'center',
        '& #input_search': {
            backgroundColor: '#eee',
            '& .MuiInputBase-root': {
                width: '100%',
                '& input': {
                    color: '#666 !important'
                }
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            padding: '10px 0'
        }
    },
    barActionsItens: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: "center",
        width: '30%',
        '& .MuiFormControlLabel-root': {
            margin: 0
        },
        '& .MuiIconButton-root': {
            padding: 5
        },
        '& .mobile': {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            paddingBottom: 10,
            width: '100%',
        }
    },
}))