import React from 'react';
import PropTypes from 'prop-types';

import ItemFilesProvider from './context'

import FullScreenDialogCustom from 'components/FullScreenDialogCustom'
import ToolsBar from './ToolsBar'
import ComponentsView from './ComponentsView'
import Header from './Header'

const FullScreenDialog = ({ open, handleClose, policies }) => {
  return open && (
    <ItemFilesProvider>
      <Header handleClose={handleClose} />
      <ToolsBar handleClose={handleClose} />
      <FullScreenDialogCustom open>
        <ComponentsView policies={policies} />
      </FullScreenDialogCustom>
    </ItemFilesProvider>
  );
}

FullScreenDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  policies: PropTypes.array,
}

export default FullScreenDialog