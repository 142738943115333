import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperWidthLg': {
      margin: '0 !important',
      padding: '40px',
      width: "1300.63px",
      color: '#666',
    },
    '& .MuiDialog-paperWidthSm': {
      margin: '0 !important',
      padding: '40px',
      minWidth: '550px',
      color: '#666'
    },
    "& .MuiFormGroup-root": {
      overflow: 'auto',
      maxHeight: 220
    },
    "& .MuiGrid-spacing-xs-3": {
      marginTop: 0
    },
    "& .MuiFormControlLabel-root": {
      margin: 0
    },
    [theme.breakpoints.down("sm")]: {
      '& .MuiDialog-paperWidthLg': {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        padding: '60px 60px 100px 60px'
      },
      '& .MuiDialog-paperWidthSm': {
        minWidth: '100%',
      },
    },
  },
  title: {
    padding: '0 0 16px 0',
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div.info': {
        display: "flex",
        alignItems: 'center',
      },
      "& svg#main": {
        width: "40px",
        height: "40px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        padding: "7px",
        marginRight: "10px",
        color: "#fff",
      },
    },
  },
  details: {
    padding: 0,
    '& p': {
      marginBottom: '20px',
      fontWeight: 500
    },
    '& .MuiFormControl-root': {
      width: '100%',
    }
  },
  list: {
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    marginTop: 20,
    '& div.button': {
      height: 40,
      color: "#666",
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& .info': {
        marginLeft: 15
      },
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    padding: 0
  },
  divider: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .text': {
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      margin: '0 5px',
      color: '#666'
    },
    '& .divider': {
      width: '100%'
    }
  },
  flexWrapper: {
    '& .MuiGrid-container': {
      display: 'flex',
      flexWrap: 'wrap',
      maxHeight: 220,
      overflow: 'hidden'
    }
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#fff",
  },
  row: {
    display: "flex",
    width: "100%",
    marginTop: '20px',
    marginBottom: "5px",
    borderBottom: "1px solid #DEDEDE",
    padding: "0 20px 8px 20px ",
    "& span": {
      display: "flex",
      alignItems: "center",
      width: "85%",
      marginLeft: "15px",
    },
    "& div ": {
      display: "flex",
      alignItems: "center",
      width: "5%",
    },
  }
}));
