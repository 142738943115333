import React, { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import {
    Typography,
    Grid,
    Button,
} from '@material-ui/core';

export default function DownloadStepsWrapper({ componentStep1, componentStep2 }) {
    const [step, setStep] = useState(1)
    const { t } = useTranslation()

    const handleStep = useCallback((action) => {
        if (action) {
            setStep(2)
        } else {
            setStep(1)
        }
    }, [])

    return (
        <>
            <Grid style={{ padding: '0 20px 20px 20px', color: "#666" }}>
                <Typography style={{ fontWeight: 500 }} variant="body1">
                    {step === 1 ? t("common:Etapa 01 de 02.") : t("common:Etapa 02 de 02.")}
                </Typography>
                <Typography variant="body2">
                    {t("common:Escolha o nome para download.")}
                </Typography>
            </Grid>

            {step === 1 ? componentStep1 : componentStep2}

            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                padding: 20
            }}>
                <Button
                    variant={step === 1 ? "contained" : "outlined"}
                    color="primary"
                    style={{ minWidth: 80 }}
                    onClick={() => handleStep(step === 1)}
                >
                    {step === 1 ? t("common:Avançar") : t("common:Voltar")}
                </Button>
            </div>

        </>
    );
}