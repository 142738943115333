import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Helmet } from "react-helmet"
import ItensFilterBar from 'components/ItensFilterBar'
import { Grid, Box, Typography } from "@material-ui/core"

import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"
import Card from "components/Layout/Card/Card"

import Utils from "utils/Utils"

import _ from 'lodash'

import { makeStyles } from "@material-ui/core/styles";

import ItemsProvider from 'pages/DAM/Items/context'

const useStyles = makeStyles((theme) => ({
  root: {
    '& #header-root-page': {
      position: 'fixed',
      zIndex: '2 !important'
    },
    "& #barActionsItens": {
      width: '100%',
    }
  },
  container: {
    width: '100%',
    height: 'calc(100vh - 128px)',
    padding: '20px',   
    marginTop: 128, 
    '& h2': {     
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '2em',
      padding: '5px 50px',
      marginBottom: 10,
      color: '#757575',
    },    
  },
  items: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',
    }
  }
}))

export const Wrapper = ({  
  pageDescription = '',
  loading,
  data = [],
  region,
  shortcuts = false,
  loadingShort,
  dataShortcuts = [],
  breadcrumbs = [],
  dataPropsCard,
  title
}) => {
  const { t } = useTranslation()
  const history = useHistory()  
  const classes = useStyles()
  const { markedItems } = useSelector((state) => state.appReducer)

  const listItems = data.map((item, index) => (
    <Card
      key={index}
      imgJPG={_.get(item, 'urls.thumbnail.original', '')}
      imgWEBP={_.get(item, 'urls.thumbnail.webp', '')}      
      title={_.get(item, 'name', '')}  
      region={region}     
      {...dataPropsCard(item)}   
    />
  ))

  const listShortcuts = dataShortcuts.map((item, index) => (
    <Card
      key={index}
      imgJPG={_.get(item, 'thumbnail', '')}
      imgWEBP={_.get(item, 'thumbnail', '')}
      slug={_.get(item, 'slug', '')}
      title={_.get(item, 'name', '')}
      regionShortcut={_.get(item, 'region', region)}
      region={region}
      grouping={_.get(item, 'grouping', false)}     
    />
  ))

  return (
    <Grid container className={classes.root}>
      <Helmet>
        <title>{Utils.getTitleFromUrl(t)}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

        <HeaderMaterialDesign
          title="DAM"          
          breadcrumbs={breadcrumbs}
          localHistory={history}
        /> 

       <ItemsProvider>
          <ItensFilterBar          
            hideInfoItems={markedItems.length <= 0}
            data={{
              title: title,
              defaultOpen: ''          
            }}
          />       
      </ItemsProvider>

      <Box component="section" className={classes.container}>
        <ul className={classes.items}>
          {loading ? Utils.getSekeletonLi() : listItems}
        </ul>

        {
          shortcuts && (
            <>
              <Typography variant="h2" color="inherit" noWrap>
                {dataShortcuts.length > 0 && t("common:Atalhos")}
              </Typography>
              <ul className={classes.items}>
                {
                  dataShortcuts.length <= 0 &&
                    loadingShort ?
                    Utils.getSekeletonLi()
                    : listShortcuts
                }
              </ul>
            </>
          )
        }
      </Box>
      

    </Grid>
  )

}
