import React, {
  useState,
  useEffect,  
  useCallback,
} from "react"
import { useSnackbar } from "notistack"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import axios from "axios"
import _ from 'lodash'

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"

import SearchInput from "components/Input/SearchInput"
import Loading from "components/Loading"
import InfiniteScrollSimple from 'components/InfiniteScrollSimple'
import Cards from './Cards'

import { useStyles } from "../styles"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SearchDialog = ({
  open,
  handleClose,
  t,
  pathServer,
  requestHeaders,
  idItem,
  dataItemsSelected
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [textsearch, setTextsearch] = useState('tag:3D')
  const [markedItems, setMarkedItems] = useState(dataItemsSelected)
  const [pagingContext, setPagingContext] = useState({    
    totalItems: 0,    
  })  
  const limitAssets = 30
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (dataItemsSelected.length > markedItems.length) {
      setMarkedItems(dataItemsSelected)
    }
  }, [dataItemsSelected, markedItems])

  const requestData = useCallback(
    (page) => {
      setLoading(true)
      const only = [
        "id",
        "name",
        "taxonomy.slug",
        "taxonomy.name",
        "assets.id",
        "assets.thumbnail_card_original",
        "assets.thumbnail_card_webp",
        "thumbnail",
      ]

      const parameters = {
        limit: limitAssets,
        page: page,
        only,
        search: textsearch,
        order: { field: "name.keyword", direction: "asc" }
      }

      axios
        .post(`${pathServer}/item/find`, parameters, requestHeaders)
        .then(
          function (resp) {           
            let DATA = resp.data.items || []
            setPagingContext({              
              totalItems: resp.data.total              
            })
            setLoading(false)
            if (page === 1) {
              setFiles(DATA)
            } else {
              setFiles((files) => files.concat(DATA))
            }
          },
          function (resp) {
            setLoading(false)
            enqueueSnackbar(resp.headers["x-message"], {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })
          }
        )
        .catch(function (err) {
          setLoading(false)
          enqueueSnackbar(
            t("manager:Erro ao carregar os dados, contate o suporte"),
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }
          )
        })
    },
    [
      textsearch,      
      pathServer,
      requestHeaders,
      t,
      enqueueSnackbar,
    ]
  )

  const handleSubmitSearch = (value) => {    
    if (Boolean(value)) {
      setTextsearch(`${value} AND tag:3D`)
    } else {
      setTextsearch('tag:3D')
    }
  }

  const handleChangeSelected = (evt, value) => {
    const slug = _.get(value, "taxonomy.slug", '')
    const id = _.get(value, "id", '')

    if (evt.target.checked) {
      setMarkedItems(prev => [...prev, { destination: { id }, slug }])
    } else {
      setMarkedItems(prev => prev.filter(i => _.get(i, 'destination.id', '') !== id))
    }
  }

  const handleSubmit = () => {
    const filterNewItems = markedItems.filter(i => !i.isDisabled)

    if (filterNewItems.length <= 0) {
      return
    }
    
    const filterNewItemsPromises = filterNewItems.map(item => axios.put(`${pathServer}/item/${idItem}/link/texture/${item.slug}`, {}, requestHeaders))
    setLoading(true)
    Promise.allSettled(filterNewItemsPromises)
      .then((list) => {
        setLoading(false)
        
        for (var key in list) {
          if (_.get(list[key], 'status', '') === 'rejected') {           
            let message =
              _.get(list[key], 'reason.response.status', '') === 422 
              ? t('DAM:Associação inválida ou não permitida')
              : t('DAM:Erro ao associar item.')
             
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })
          }
        }

        setMarkedItems([])
        handleClose(true)
      })
      .catch((err) => {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("manager:Erro inesperado. Contate o suporte")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      });

  }

  return (
    <div>
      <Dialog
        fullScreen
        disableScrollLock={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ zIndex: 1300 }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('common:Selecione a textura para o objeto 3D')}
            </Typography>

            <SearchInput
              handleSubmitSearch={handleSubmitSearch}
              showClearButton={true}
            />


          </Toolbar>
          <Toolbar className={classes.titleMobile}>
            <Typography variant="h6">
              {t('common:Selecione a textura para o objeto 3D')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: 0, backgroundColor: '#eee' }}>
          <Loading showLoading={loading} />

        <InfiniteScrollSimple
          loading={loading}
          getData={requestData}
          textsearch={textsearch} 
          totalCurrent={files.length}  
          totalItens={pagingContext.totalItems}       
        >
          <Cards
            dataItems={files}
            handleChangeSelected={handleChangeSelected}
            markedItems={markedItems}
            loading={loading}
          />

          {
            markedItems.filter(i => !i.isDisabled).length > 0 && (
              <div className={classes.wrapperAccordion}>
                <Button
                  color='primary'
                  variant="contained"
                  style={{ borderRadius: 30 }}
                  onClick={handleSubmit}
                >
                  {t("common:Salvar")}
                </Button>
              </div>
            )
          }
          </InfiniteScrollSimple>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = ({ appReducer }) => {
  return {
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(["DAM", "common"])(SearchDialog))
