import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';

import {
  Tooltip,
  IconButton,
} from "@material-ui/core";

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"

import ShareRoundedIcon from "@material-ui/icons/ShareRounded";
import { mdiDownload } from '@mdi/js';
import Icon from "@mdi/react";

import DialogSharedItems from "components/Dialogs/DialogSharedItems";
import BulkDownload from "components/Dialogs/BulkDownload";
import DeleteItems from "components/Dialogs/BulkDeleteItems"

import Settings from 'utils/Settings'

import { useDataItems } from 'pages/DAM/Items/context'
import { Creators as ItemsActions } from "flux/ducks/Items"

const MarkedItems = ({ defaultOpen = '' }) => {
  const { markedItems, userData: { region } } = useSelector((state) => state.appReducer);
  const { name_dialog_open } = useSelector((state) => state.itemsReducer);

  const { showItemsSelected } = useDataItems()

  const dispatch = useDispatch();
  const { t } = useTranslation()
  const history = useHistory()

  const handleClickDialog = useCallback((field = '', value) => {
    if (value && !showItemsSelected) {
      dispatch(ItemsActions.setOpenDialogItemsMarked({ open: value ? field : '' }))
      history.push(`/items/${region}`)
    }
  }, [showItemsSelected, history, region, dispatch])

  const constants = useMemo(
    () => ({
      totalMarkedItems: markedItems.length,
      limitExceeded: markedItems.length > Settings.ITEMS_PAGING_LIMIT,
      downloadlimitExceeded: markedItems.length > Settings.MAX_ITEMS_DOWNLOAD,
      shareLimitExceede: markedItems.length > Settings.MAX_ITEMS_SHARED,
      dataAssets: {
        items: markedItems.map(marked => ({
          id: marked.id,
          assets: _.get(marked, 'assets', []).map(i => i.id),
          version: 0,
          category_slug: _.get(marked, 'category.slug', '')
        }))
      }
    }), [markedItems])

  return (
    <>
      <BulkDownload
        openDefault={((name_dialog_open === 'openDownload') || defaultOpen)}
        disabled={constants.downloadlimitExceeded}
        handleSetOpen={(value) => handleClickDialog('openDownload', value)}
        dataItems={{
          items: constants.dataAssets.items
        }}
      >
        {(confirm) => {
          return (
            <Tooltip title={constants.downloadlimitExceeded ? t("common:Limite de itens selecionados", { value: constants.downloadlimitExceeded }) : t("manager:Download")} placement="top">
              <div>
                <IconButton
                  disableRipple
                  aria-haspopup="true"
                  color="inherit"
                  style={{ padding: 10 }}
                  onClick={confirm()}
                  disabled={constants.downloadlimitExceeded}
                >
                  <Icon path={mdiDownload} size={1.2} id={((name_dialog_open === 'openDownload') || defaultOpen) ? 'svg-primary' : ''} />
                </IconButton>
              </div>
            </Tooltip>
          );
        }}
      </BulkDownload>

      <DialogSharedItems
        openDefault={((name_dialog_open === 'openShared') || defaultOpen)}
        handleSetOpen={(value) => handleClickDialog('openShared', value)}
        dataItems={{
          items: constants.dataAssets.items
        }}
      >
        {(confirm) => {
          return (
            <Tooltip title={constants.shareLimitExceede ? t("common:Limite de itens selecionados", { value: constants.shareLimitExceede }) : t("common:Compartilhar")} placement="top">
              <div>
                <IconButton
                  disableRipple
                  aria-haspopup="true"
                  color="inherit"
                  style={{ padding: 10 }}
                  onClick={confirm()}
                  disabled={constants.shareLimitExceede}
                >
                  <ShareRoundedIcon id={((name_dialog_open === 'openShared') || defaultOpen) ? 'svg-primary' : ''} />
                </IconButton>
              </div>
            </Tooltip>
          );
        }}
      </DialogSharedItems>

      <DeleteItems
        defaultOpen={defaultOpen}
        totalMarkedItems={constants.totalMarkedItems}
        limitExceeded={constants.limitExceeded}
      />
    </>
  );
};

MarkedItems.defaultProps = {
  defaultOpen: "",
}

MarkedItems.propTypes = {
  defaultOpen: PropTypes.string,
}

export default MarkedItems

