
import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function VersionComment({
    open,
    title,
    comment,
    handleCloseComment,
    t
}) {
  const [openComment, setOpenComment] = useState(false);

  useEffect(() => {
    setOpenComment(open)
  }, [open])

  return (
    <Dialog
      open={openComment}
      onClose={handleCloseComment}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{comment}</DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleCloseComment} color="primary">{t('common:Fechar')}</Button>
      </DialogActions>
    </Dialog>
  )
}
