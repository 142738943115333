import React, { useState, useCallback, useMemo } from "react";
import axios from 'axios'
import _ from 'lodash'

import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useSnackbar } from "notistack"

import { IconButton, useTheme, Tooltip } from "@material-ui/core";

import { mdiProgressDownload } from "@mdi/js"
import Icon from "@mdi/react"

import AlertDialogSimple from 'components/Alert/AlertDialogSimple'

import { Creators as DownloadCentralActions } from "flux/ducks/Download_central"
import { useDataItems } from 'pages/DAM/Items/context'
import { ReactComponent as SvgDownInfo } from "imagens/DAM/download_info.svg"

const INITIAL_STATE = {
    openDialogAlert: false,
}

const BulkDownload = () => {
    const [state, setState] = useState({ ...INITIAL_STATE })
    const {
        requestHeaders,
        pathServer,
        markedItems
    } = useSelector((state) => _.get(state, "appReducer", null))
    const { params_filter_items } = useSelector(state => state.appBarReducer)
    const { getParamsToItems } = useDataItems()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const theme = useTheme()

    const getParameters = useCallback(() => {
        const paramsToItems = getParamsToItems()
        const paramsToFilter = { ...paramsToItems, ...params_filter_items, bulk: true }
        delete paramsToFilter.limit
        delete paramsToFilter.only
        delete paramsToFilter.page
        delete paramsToFilter.search_after
        delete paramsToFilter.category_slug

        if (markedItems.length > 0) {
            let params = {}
            params = {
                items: markedItems.map(marked => ({
                    id: marked.id,
                    version: 0,
                    category_slug: _.get(marked, 'category.slug', '')
                })),
                bulk: true
            }
            return params
        } else {
            return paramsToFilter
        }
    }, [markedItems, getParamsToItems, params_filter_items])

    const onClickDownloadInfos = useCallback(() => {
        let payload = getParameters()

        axios.post(`${pathServer}/attribute/export`, payload, requestHeaders)
            .then((resp) => {
                setState(prev => ({ ...prev, openDialogAlert: true }),
                    dispatch(DownloadCentralActions.setReloadDownloadData())
                )
            })
            .catch((err) => {
                const message = _.get(err, 'response.data.message', _.get(err, 'response.headers["x-message"]', t("manager:Erro inesperado. Contate o suporte")))
                setState(prev => ({ ...prev, openDialogAlert: false }), enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                }))
            })
    }, [enqueueSnackbar, pathServer, requestHeaders, t, dispatch, getParameters])

    const constants = useMemo(() => ({
        hasError: (markedItems.filter((item) => item.downloadable === false).length > 0)
    }), [markedItems])

    return (
        <>
            <Tooltip title={t("DAM:Gerar relatório de infomações")} placement="top">
                <IconButton
                    style={{ margin: "0 8px", backgroundColor: _.get(theme, 'palette.primary.main', ""), height: 32 }}
                    onClick={onClickDownloadInfos}
                    disabled={_.get(state, "loading", null) || constants.hasError}
                >
                    <SvgDownInfo width={24} height={24} />
                </IconButton>
            </Tooltip>
            <AlertDialogSimple
                open={_.get(state, "openDialogAlert", null)}
                icon={<Icon path={mdiProgressDownload} id="main" />}
                handleClose={() => {
                    setState(prev => ({ ...prev, openDialogAlert: false }))
                }}
                title={t(`common:Download em andamento`)}
                description={t(`common:Acompanhe na Central de downloads a sua solicitação. Uma notificação será enviada quando os Itens estiverem prontos.`)}
            />
        </>
    );
}

export default BulkDownload
