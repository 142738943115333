import React from "react"
import Loading from "../../components/Loading"
import HeaderMaterialDesign from "../../components/Header/HeaderMaterialDesign/HeaderMaterialDesign"
import { withStyles } from "@material-ui/core/styles"
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  rootContainer: {    
    paddingTop: 20,
    marginBottom: 70,
    '& h5': {      
      fontWeight: 500, 
      color: '#666'     
    },
    '& p': {
      color: '#666' 
    },    
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 90
    },
    "& .MuiDataGrid-main": {
      "& div": {
        outline: "none !important",
      },
      "& .hasClick": {
        cursor: "pointer",
      },
      "& .centerCell": {
        justifyContent: 'center !important'
      },
      '& .MuiDataGrid-colCellTitleContainer': {
        lineHeight: 1.3,
        overflow: 'visible',
        whiteSpace: 'normal',
        '& .MuiDataGrid-colCellTitle': {
          whiteSpace: 'normal',
        }
      },
      '& .MuiDataGrid-cell': {
        lineHeight: '1.3 !important',
        overflow: 'visible',
        whiteSpace: 'normal',
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiDataGrid-window': {
        overflow: 'hidden !important',
        overflowY: 'auto !important',
        [theme.breakpoints.down("sm")]: {
          overflow: 'auto !important',
        },
      }
    },
  },

})

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }

    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    const SELF = this
    setTimeout(function () {
      if (this._isMounted) {
        SELF.setState({ loading: false })
      }
    }, 500)
  }

  componentWillUnmount() {
    this._isMounted = false
  }


  render() {
    if (this.state.loading) {
      return <Loading showLoading={this.state.loading} />
    }
    const {
      area,
      localHistory,
      children,
      classes,
    } = this.props
    return (
      <>        
        <HeaderMaterialDesign
          title="Configurações"
          index={area}
          localHistory={localHistory}
          pattern="settings"
        />
        <Container maxWidth="md" className={classes.rootContainer}>
          {children}
        </Container>
      </>
    )
  }
}

export default withStyles(styles)(Template)
