import React, { useState, useCallback, useMemo } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import {
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useTheme
}
    from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import { ReactComponent as IconSVGUp } from 'imagens/icons/view_module_black_24dp.svg'
import { ReactComponent as IconSVGDown } from 'imagens/icons/calendar_view_month_black_24dp.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import AppActions from "flux/actions/AppActions"

export const useStyles = makeStyles((theme) => ({
    root_svg: {
        '& svg': {
            fill: theme.palette.primary.main
        }
    }
}))

const SetModeView = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { navigation_view_mode, requestHeaders, pathClient } = useSelector(
        (state) => state.appReducer
    )
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const theme = useTheme()

    const submitUserModeView = useCallback((option) => {
        axios.put(`${pathClient}/viewMode`, { viewMode: option }, requestHeaders)
            .then(resp => console.log(`Modo de visualização ${option}, salvo com sucesso.`))
            .catch(err => console.log(err))
    }, [pathClient, requestHeaders])

    const handleViewNavigation = useCallback((option) => {
        const callback = () => {                  
            dispatch(AppActions.setUserModeView(option))
            submitUserModeView(option)
            history.push('/dam')
        }
        setAnchorEl(null, callback())
    }, [dispatch, history, submitUserModeView])

    const handleClickMenu = useCallback((evt) => {
        setAnchorEl(evt.currentTarget)
    }, [])

    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, [])

    const constants = useMemo(() => ({
        isModeItemsView: navigation_view_mode === 'view-items'
    }), [navigation_view_mode])

    return (
        <>
            <IconButton style={{ background: 'transparent' }} onClick={handleClickMenu}>
                {constants.isModeItemsView ? <IconSVGUp /> : <IconSVGDown />}
                {anchorEl ? <ExpandLess /> : <ExpandMoreIcon />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Typography
                    variation='body1'
                    style={{ fontWeight: 600, padding: '5px 17px', color: '#666' }}
                >
                    {t('DAM:Navegação')}
                </Typography>
                <MenuItem
                    onClick={() => handleViewNavigation('view-navigation')}
                    style={{ padding: '3px 10px', opacity: 1 }}
                    disabled={!constants.isModeItemsView}
                >
                    <IconSVGDown fill={!constants.isModeItemsView ? theme.palette.primary.main : '#666'} style={{ margin: 5 }} />
                    <Typography style={{ color: !constants.isModeItemsView ? theme.palette.primary.main : '#666' }}>
                        {t('DAM:Por hierarquia')}
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => handleViewNavigation('view-items')}
                    style={{ padding: '3px 10px', opacity: 1 }}
                    disabled={constants.isModeItemsView}
                >
                    <IconSVGUp fill={constants.isModeItemsView ? theme.palette.primary.main : '#666'} style={{ margin: 5 }} />
                    <Typography style={{ color: constants.isModeItemsView ? theme.palette.primary.main : '#666' }}>
                        {t('DAM:Todos os itens')}
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default SetModeView
