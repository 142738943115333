import React from 'react';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import _ from "lodash";

const RegionMenu = ({anchorEl, region, otherRegions, handleMenuClose, changeRegion}) => {
  return (
    <Menu
      anchorEl={anchorEl.region}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={region.isMenuOpen}
      onClose={() => handleMenuClose('region')}
    >
      {otherRegions.map((item, key) => (
        <MenuItem key={key} onClick={() => changeRegion(_.get(item, "slug", ""))}>
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default RegionMenu;
