import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux"
import axios from 'axios'
import { useSnackbar } from "notistack"

import {
    MenuItem,    
    ListItemText,
    CircularProgress
} from '@material-ui/core';

import MoreVertIcon from "@material-ui/icons/MoreVert"

import { StyledMenu } from '../utils'


const MenuCustom = ({ idItem, slug, handleGetData }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { pathServer, requestHeaders } = useSelector(
        (state) => state.appReducer
    )
        
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClickMenuClose = () => {
        setAnchorEl(null)
    }

    const handleDelete = () => {
        setLoading(true)
        axios
            .delete(`${pathServer}/item/${idItem}/link/texture/${slug}`, requestHeaders)
            .then((result) => {
                setLoading(false)
                setAnchorEl(null)
                handleGetData()
            })
            .catch((err) => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("manager:Erro inesperado. Contate o suporte")
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
            })
    }

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <button
                style={{
                    border: 'none',
                    background: 'transparent',
                    textAlign: 'left',
                    padding: 0,
                    fontFamily: 'inherit',                      
                }}   
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(event) => handleClickMenu(event)}>
                <MoreVertIcon fontSize="large" />
            </button>
            </div>
            <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClickMenuClose}               
            >
                <MenuItem
                    onClick={() => handleDelete()}
                    disabled={loading}
                    style={{ width: 100 }}
                >
                    {
                        loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <CircularProgress
                                    style={{ width: 32, height: 32 }}
                                />
                            </div> : <ListItemText primary={t("Excluir")} />
                    }
                </MenuItem>
            </StyledMenu>
        </>
    )
}

export default MenuCustom