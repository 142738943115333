import connect from "react-redux/es/connect/connect";
import AppActions from "../../../flux/actions/AppActions";

const mapStateToProps = ({appReducer}) => {
    return {
      userId: appReducer.userData.user_id,
      channels_connected: appReducer.pusher.channels_connected,
      pathServer: appReducer.pathServer,
      requestHeaders: appReducer.requestHeaders
    }
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      bindToChannel : (channel, message, callback) => dispatch(AppActions.bindToChannel(channel, message, callback)),
      channelUnsubscribe : (channel) => dispatch(AppActions.channelUnsubscribe(channel)),
    }
  };
  

export default connect(mapStateToProps, mapDispatchToProps);