import React from "react"

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import SwipeableViews from "react-swipeable-views"
import classNames from "classnames"
import { useStyles } from "./styles"

const AutoRotatingCarousel = ({ children, dataLength, containerStyle, mobile,
  slideIndex, increaseIndex, decreaseIndex
}) => {
  const classes = useStyles()

  const hasOneChildren = dataLength > 1

  const handleChangeIncreaseIndex = (index, indexLatest) => {
    if(index > indexLatest) {
      return increaseIndex()
    } else {
      return decreaseIndex()
    }
  }

  const carousel = (
    <SwipeableViews
      className={classes.carousel}
      containerStyle={{ height: "100%", ...containerStyle }}
      index={slideIndex}
      onChangeIndex={(index, indexLatest) => handleChangeIncreaseIndex(index, indexLatest)}
      slideClassName={classes.slide}
    >
      {children}
    </SwipeableViews>
  )

  return (
      <div className={classes.rootWrapper}>
        {hasOneChildren && (
          <>
          <button
            className={classNames(classes.arrow, classes.arrowLeft)}
            onClick={decreaseIndex}
            disabled={slideIndex === 0}
            style={{
              border: 'none',
              background: 'transparent',
              textAlign: 'left',
              padding: 0,
              fontFamily: 'inherit',
            }}
          >
            <ArrowBackIosRoundedIcon className={classes.arrowIcon} />
          </button>
          <button
          className={classNames(classes.arrow, classes.arrowRight)}
          onClick={increaseIndex}
          disabled={slideIndex === dataLength - 1}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',
          }}
        >
          <ArrowForwardIosRoundedIcon className={classes.arrowIcon} />
        </button>
        </>
        )}

        <div elevation={mobile ? 0 : 1} className={classes.carouselWrapper}>
          {carousel}
        </div>

      </div>

  )
}

AutoRotatingCarousel.defaultProps = {
  mobile: false,
}

export default AutoRotatingCarousel
