import React, { createContext, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import AppActions from "flux/actions/AppActions"
import Utils from "utils/Utils"
const EditFilesContext = createContext()

export default function EditFilesProvider({ children, isDraft, isGallery }) {
    const [loading, setLoading] = useState(false)
    const [dataAssets, setDataAssets] = useState([])
    const [dataAssetsPrev, setDataAssetsPrev] = useState([])
    const [dataTags, setDataTags] = useState([])
    const [dataSelected, setDataSelected] = useState([])
    const [componentStatus, setComponentStatus] = useState({
        current: isDraft ? 'search_bucket' : 'initial', last: 'initial'
    })
    const [actionsHistory, setActionsHistory] = useState([])  
    const [hasData, setHasData] = useState(false)
    const [initialAssetsLength, setInitialAssetsLength] = useState(0)
    const [dataComment, setDataComment] = useState('')
    const [totalAssets, setTotalAssets] = useState(0)
    const [contextAction, setContextAction] = useState('version-edit')
    
    const { markedAssets } = useSelector((state) => state.appReducer)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const handleComponentStatus = (status) => {
        setComponentStatus(prev => ({
            current: status,
            last: prev.current
        }))
    }

    const handleGoBackShowBucket = () => {
        if (contextAction === 'version-new' && !actionsHistory.some(i => i === 'asset-add')) {
            dispatch(AppActions.clearMarkedAssets())
            setDataAssets(dataAssetsPrev)
            setDataAssetsPrev([])
            setContextAction('version-edit')
        }       
        handleComponentStatus('initial')
    }

    const handleAddAssets = () => {
        if (isGallery && contextAction === 'version-new' && markedAssets.length > 1) {            
            enqueueSnackbar(
                t("manager:Para galeria somente um ativo e permitido."),
                {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                }
            )            
        } else {
            setDataAssets(prev => Utils.removeDuplicateDataForIDs(prev, markedAssets))  
            setActionsHistory(prev => ([...prev, 'asset-add']))
            setDataComment('')
            handleComponentStatus('initial')
        }
    }

    const itemIsDraft = isDraft

    const isDisabledAddAssets = loading
                                || (markedAssets.length === 0)
                                || (dataAssets.length + markedAssets.length) >= 151

    return (
        <EditFilesContext.Provider
            value={{
                loading,
                setLoading,
                dataAssets,
                setDataAssets,
                dataTags,
                setDataTags,
                dataSelected,
                setDataSelected,
                componentStatus,
                handleComponentStatus,                
                hasData,
                setHasData,
                initialAssetsLength,
                setInitialAssetsLength,
                dataComment,
                setDataComment,
                itemIsDraft,
                totalAssets,
                setTotalAssets,
                actionsHistory,
                setActionsHistory,
                contextAction,
                setContextAction,
                dataAssetsPrev,
                setDataAssetsPrev,
                handleGoBackShowBucket,
                handleAddAssets,
                isDisabledAddAssets
            }}
        >
            {children}
        </EditFilesContext.Provider>
    )
}

export function useDataEditFiles() {
    const {
        loading,
        setLoading,
        dataAssets,
        setDataAssets,
        dataTags,
        setDataTags,
        dataSelected,
        setDataSelected,
        componentStatus,
        handleComponentStatus,        
        hasData,
        setHasData,
        initialAssetsLength,
        setInitialAssetsLength,
        dataComment,
        setDataComment,
        itemIsDraft,
        totalAssets,
        setTotalAssets,
        actionsHistory,
        setActionsHistory,
        contextAction,
        setContextAction,
        dataAssetsPrev,
        setDataAssetsPrev,
        handleGoBackShowBucket,
        handleAddAssets,
        isDisabledAddAssets
    } = useContext(EditFilesContext)

    return {
        loading,
        setLoading,
        dataAssets,
        setDataAssets,
        dataTags,
        setDataTags,
        dataSelected,
        setDataSelected,
        componentStatus,
        handleComponentStatus,       
        hasData,
        setHasData,
        initialAssetsLength,
        setInitialAssetsLength,
        dataComment,
        setDataComment,
        itemIsDraft,
        totalAssets,
        setTotalAssets,
        actionsHistory,
        setActionsHistory,
        contextAction,
        setContextAction,
        dataAssetsPrev,
        setDataAssetsPrev,
        handleGoBackShowBucket,
        handleAddAssets,
        isDisabledAddAssets
    }
}
