import React, { useState } from "react"
import { Typography, Button } from "@material-ui/core"

import { useStyles } from "./styles"
import { useTranslation } from "react-i18next";

const ACCEPTED = localStorage.getItem("yapoli-dialog-consent") === "accepted"

const ReactCookie = () => {
  const [accepted, setAccepted] = useState(ACCEPTED)
  const classes = useStyles()
  const { t } = useTranslation()

  function setCookie(name, exdays) {
    let expires, date, value;
    date = new Date()
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = date.toUTCString();
    value = "TESTE123";
    document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
  }

  function setLocalStorage() {
    setCookie("yapoli-dialog-consent", -1)
    setCookie("yapoli.com", -1)
    localStorage.setItem("yapoli-dialog-consent", "accepted")
    setAccepted(true)
  }

  return !accepted && (
    <div className={classes.wrapperRoot}>
      <div className={classes.container}>
        <div className="title">
          <Typography variant="body" component="p">
            {t("common:Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência. Ao usar nosso serviço, você concorda com nossos termos e condições de uso.")}
          </Typography>
        </div>
        <div className="button">
          <Button variant="outlined" className={classes.confirmButton} onClick={() => setLocalStorage()}>
            {t("common:ACEITAR E FECHAR")}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ReactCookie
