import React, { createContext, useState, useContext, useCallback, useEffect } from "react"
import _ from 'lodash'
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Utils from "utils/Utils"
import AppActions from "flux/actions/AppActions"
import UploadActions from "flux/actions/UploadActions"
import {useHistory} from "react-router-dom";
import { removeObsoleteUrlSessionStorage } from 'utils/Helpers'

const AssetIntegraContext = createContext()

export default function AssetIntegraProvider({ children, slugItem }) {
  const [slideIndex, setSlideIndex] = useState(0)
  const [openDetailsAsset, setOpenDetailsAsset] = useState(false)
  const [openDownloadButton, setOpenDownloadButton] = useState(false)
  const [refreshedItem, setRefreshedItem] = useState(null)
  const [stateItem, setStateItem] = useState({
    dataHeader: [],
    versionIndex: 0,
    loading: true,
    menuComponentName: { name: 'details', id: '' },
    areaComponentName: '',
    openDrawerAssets: false,
    errorGetData: false,
    allow: {
      email: false, manager: false, managerItemAsset: false, download: false
    },
    data: {}
  })
  const { requestHeaders, pathServer, updated_item } = useSelector((state) => state.appReducer)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    return () => {
      dispatch(UploadActions.removeItemFromContext())
      }
  }, [dispatch])

  const handleOpenDetailsAsset = () => {
    setOpenDetailsAsset(prev => !prev)
  }

  const handleOpenDownloadButton = () => {
    setOpenDownloadButton(prev => !prev)
  }

  const setOpenDrawerAssets = (value) => {
    setStateItem(prev => ({
      ...prev,
      openDrawerAssets: value
    }))
  }
  const setAreaComponentName = (name) => {
    setStateItem(prev => ({
      ...prev,
      areaComponentName: name,
      openDrawerAssets: false
    }))
  }

  const setNavComponentName = (name, id) => {
    setStateItem(prev => ({
      ...prev,
      menuComponentName: { name, id },
      openDrawerAssets: false
    }))
  }

  const checkAllPermissions = useCallback(async (id, index) => {
    let allow = {}

    try {
      const promises = [
        axios.get(`${pathServer}/can/email/${id}/${index}`, requestHeaders),
        axios.get(`${pathServer}/can/manager/${id}/${index}`, requestHeaders),
        axios.get(`${pathServer}/can/managerItemAsset/${id}/${index}`, requestHeaders),
        axios.get(`${pathServer}/can/download/${id}/${index}`, requestHeaders)
      ]

      await Promise.allSettled(promises)
        .then(results => {
          results.forEach(result => {
            if (result.status === "fulfilled") {
              const field = _.get(result, 'value.data.permission', '')
              const result_id = _.get(result, 'value.data.id', '')
              allow[field] = result_id === id
            }
          })
        }).catch(err => {
          console.log(err)
        })
      return allow
    } catch (err) {
      console.log("Erro ao definir permissão", err)
    }
  }, [pathServer, requestHeaders])


  const handleDeleteVersion = useCallback(async (index) => {
    const callback = async () => {
      try {
        await axios.delete(`${pathServer}/item/${stateItem.data.id}/version/${index}`, requestHeaders)
        setStateItem(prev => ({
          ...prev,
          data: {
            ...prev.data,
            versions: prev.data.versions.filter((i, key) => key !== index)
          },
          versionIndex: 0,
          openDrawerAssets: false,
          loading: false
        }), setSlideIndex(0))
      } catch (err) {
        setStateItem(prev => ({ ...prev, loading: false }))
        console.log("Erro excluir versão", err)
      }
    }
    setStateItem(prev => ({ ...prev, loading: true }), callback())
  }, [pathServer, requestHeaders, stateItem])

  const handleChangeVersion = useCallback(async (index = 0) => {
    const callback = async () => {
      try {
        const allows = await checkAllPermissions(stateItem.data.id, index)

        setStateItem(prev => ({
          ...prev,
          data: {
            ...prev.data,
            attribute: _.get(prev, `data.versions[${index}].attributes`),
            assets: _.get(prev, `data.versions[${index}].assets`),
            created_at: _.get(prev, `data.versions[${index}].created_at`),
            hasHighLow: _.get(prev, `data.versions[${index}].hasHighLow`),
          },
          hasHighLow: _.get(prev, `data.versions[${index}].hasHighLow`, false),
          allow: { ...prev.allow, ...allows },
          versionIndex: index,
          openDrawerAssets: false,
          loading: false
        }), setSlideIndex(0))
      } catch (err) {
        setStateItem(prev => ({ ...prev, loading: true }))
      }
    }
    setStateItem(prev => ({ ...prev, loading: true }), callback())
  }, [checkAllPermissions, stateItem])

  const slugUpdateUrl = useCallback((slug) => {
    const { region, division, segment, category } = stateItem.data
    const regionSlug = _.get(region, "slug", "")
    const divisionSlug = _.get(division, "slug", "")
    const segmentSlug = _.get(segment, "slug", "")
    const categorySlug = _.get(category, "slug", "")
    if (regionSlug && divisionSlug && segmentSlug && categorySlug) {
      history.push(`/dam/${regionSlug}/${divisionSlug}/${segmentSlug}/${categorySlug}/${slug}`)
      removeObsoleteUrlSessionStorage()
    }
  }, [stateItem.data, history])

  const getDataItem = useCallback(async () => {
    try {
      const clearRefreshed = _.debounce(() => setRefreshedItem(null), 12000)
      const dataSlug = updated_item != null ? _.get(updated_item, "slug", "") : slugItem;
      const slug = dataSlug.split("-").join("_");

      if (slugItem !== dataSlug) {
        slugUpdateUrl(slug)
      } else {
        const resp = await axios.post(`${pathServer}/item/by/taxonomy/${slug}}`, {}, requestHeaders)

        clearRefreshed()

        const regionSlug = _.get(resp, "data.item.region.slug", "")
        const divisionSlug = _.get(resp, "data.item.division.slug", "")
        const segmentSlug = _.get(resp, "data.item.segment.slug", "")
        const categorySlug = _.get(resp, "data.item.category.slug", "")

        const allows = await checkAllPermissions(resp.data.item.id, 0)

        const hasHighLow = _.get(resp, "data.item.versions[0].hasHighLow", false)

        setStateItem(prev => ({
          ...prev,
          data: {
            ...resp.data.item,
            tools: _.get(resp, "data.item.tools", []),
            versions: _.get(resp, "data.item.draft", false)
              ? []
              : _.get(resp, "data.item.versions", []),
            assets: resp.data.item.versions[prev.versionIndex].assets,
          },
          hasHighLow: hasHighLow,
          isPublished: _.get(resp, "data.item.published", false),
          draft: _.get(resp, "data.item.draft", false),
          loading: false,
          dataHeader: [
            {
              label: _.get(resp, "data.item.division.name", ""),
              url: `/dam/${regionSlug}/${divisionSlug}`,
            },
            {
              label: _.get(resp, "data.item.segment.name", ""),
              url: `/dam/${regionSlug}/${divisionSlug}/${segmentSlug}`,
            },
            {
              label: _.get(resp, "data.item.category.name", ""),
              url: `/dam/${regionSlug}/${divisionSlug}/${segmentSlug}/${categorySlug}`,
            },
            {label: _.get(resp, "data.item.taxonomy.name", "")},
          ],
          isGallery: _.get(resp, "data.item.isGalleryType", false),
          allow: {...prev.allow, ...allows},
        }), dispatch(UploadActions.addItemToContext(resp.data.item)))
      }
    } catch (err) {
      const message = Utils.ajaxErrorGetMessage(err, t)
      let newData = { loading: false, dataHeader: [], errorGetData: true }

      if (_.get(err, 'response.status', 500) === 401) {
        newData = {
          loading: false,
          dataHeader: [],
          permission: {
            status: true,
            title: "Login expirado",
            msg: message
          }
        }
        setStateItem(prev => ({ ...prev, ...newData }), dispatch(AppActions.openDialog("expiredToken")))
        return
      }

      if (_.get(err, 'response.status', 500) === 404) {
        newData = {
          loading: false,
          dataHeader: [],
          permission: {
            status: true,
            title: "Acesso negado",
            msg: t("common:Acesso negado")
          }
        }
      }

      setStateItem(prev => ({ ...prev, ...newData }))
      console.log('erro: ', err)
    }
  }, [pathServer, requestHeaders, t, dispatch, checkAllPermissions, updated_item, slugItem, slugUpdateUrl])


  useEffect(() => {
    const checkIsUpdated = async () =>  {
      const {id, updated_at} = stateItem.data
      try {
        const resp = await axios.get(`${pathServer}/item/${id}`, requestHeaders)

        if(!resp || !resp.item || !resp.item.updated_at) {
          return true;
        }

        return updated_at !== resp.item.updated_at;
      } catch(e) {
        console.error("AssetIntegraProvider.checkIsUpdated. falha", e)
        return true;
      }
    }

    const refresh = _.debounce(async (calls = 0) => {
      const isUpdated = await checkIsUpdated()
      setStateItem(prev => ({ ...prev, loading: true }))
      if(!isUpdated && calls < 10) {
        refresh(++calls);
        return
      }
      dispatch(AppActions.setUpdatedItem(null))
      setRefreshedItem(updated_item)
      getDataItem()
    }, 1000)

    if(stateItem.loading) {
      return;
    }
    if(!updated_item) {
      return;
    }
    if(!stateItem.data) {
      return;
    }
    if(refreshedItem && refreshedItem.id === updated_item.id) {
      return;
    }
    if(updated_item.id === stateItem.data.id) {
      refresh()
    }
    return () => dispatch(AppActions.setUpdatedItem(null))
  }, [updated_item, getDataItem, stateItem, refreshedItem, dispatch, requestHeaders, pathServer])


  return (
    <AssetIntegraContext.Provider
      value={{
        slideIndex,
        setSlideIndex,
        openDetailsAsset,
        handleOpenDetailsAsset,
        openDownloadButton,
        handleOpenDownloadButton,
        setOpenDrawerAssets,
        stateItem,
        getDataItem,
        setNavComponentName,
        setAreaComponentName,
        handleChangeVersion,
        handleDeleteVersion
      }}
    >
      {children}
    </AssetIntegraContext.Provider>
  )
}

export function useDataAssetIntegra() {
  const {
    slideIndex,
    setSlideIndex,
    openDetailsAsset,
    handleOpenDetailsAsset,
    openDownloadButton,
    handleOpenDownloadButton,
    setOpenDrawerAssets,
    stateItem,
    getDataItem,
    setNavComponentName,
    setAreaComponentName,
    handleChangeVersion,
    handleDeleteVersion
  } = useContext(AssetIntegraContext)

  return {
    slideIndex,
    setSlideIndex,
    openDetailsAsset,
    handleOpenDetailsAsset,
    openDownloadButton,
    handleOpenDownloadButton,
    setOpenDrawerAssets,
    stateItem,
    getDataItem,
    setNavComponentName,
    setAreaComponentName,
    handleChangeVersion,
    handleDeleteVersion
  }
}
