import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import {
    IconButton,
    ListItemIcon,
    Divider,
    Tooltip,
    Badge,
    Typography,
    Link,
    Paper,
    Grow,
    MenuList,
    Popper,
    ClickAwayListener,
    Button,
    useMediaQuery
} from '@material-ui/core';
import _ from 'lodash'
import { mdiFilter } from "@mdi/js"
import Icon from "@mdi/react"

import ValuesList from './components/ValuesList'
import { useStyles } from "./styles";
import { useDataItems } from 'pages/DAM/Items/context'

const FilterItemsView = ({
    dataAttributes,
    handleCallbackInput,
    isDataEmpty,
    hasFilters,
    total,
    isClearOptions,
    handleClearOptions,
    setIsDataEmpty,
    loading
}) => {
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)')

    const { t } = useTranslation()
    const { getParamsToItems } = useDataItems()

    const dataParams = getParamsToItems()
    const disableHierarchy = Boolean(_.get(dataParams, 'category', ''))

    const handleClick = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const isDisabled = (() => {


        if (!hasFilters) {
            return true
        }

        if (disableHierarchy && _.get(dataAttributes, 'attributes', []).length === 0) {
            return true
        }

        return false
    })()



    return (
        <>
            <Tooltip title={t("DAM:Filtrar metadados")} placement="top">
                <div>
                    {
                        isMobile ? (
                            <Badge badgeContent={total} color="primary" style={{ margin: '0 10px' }}>
                                <ListItemIcon style={{ minWidth: 10 }} onClick={handleClick}>
                                    <Icon path={mdiFilter} size={1} id={total > 0 ? 'svg-primary' : ''} />
                                </ListItemIcon>
                            </Badge>
                        ) : (
                            <Button
                                variant="outlined"
                                color={(open && hasFilters) ? "primary" : "inherit"}
                                ref={anchorRef}
                                startIcon={
                                    <Badge badgeContent={total} color="primary">
                                        <ListItemIcon style={{ minWidth: 10 }}>
                                            <Icon path={mdiFilter} size={1} id={total > 0 ? 'svg-primary' : ''} />
                                        </ListItemIcon>
                                    </Badge>
                                }
                                onClick={handleClick}
                                disabled={isDisabled}
                                style={{ border: 'none', padding: 15, textTransform: 'inherit' }}
                            >
                                {t("DAM:Filtrar metadados")}
                            </Button>
                        )}
                </div>
            </Tooltip>

            {
                !isDisabled && (
                    <Popper className={classes.rootMenu} open={open} anchorEl={anchorRef.current} transition>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList className={classes.rootMenuList}>
                                            <li className={classes.liHeader}>
                                                <div className='title'>
                                                    <Icon path={mdiFilter} color="#666" size={1} />

                                                    <Typography variant="body2" noWrap>
                                                        {t("DAM:Filtrar metadados")}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <IconButton aria-label="close" onClick={handleClose}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#666"><rect fill="none" height="24" width="24" /><path d="M21.29,4.12l-4.59,4.59l1.59,1.59c0.63,0.63,0.18,1.71-0.71,1.71H13c-0.55,0-1-0.45-1-1V6.41c0-0.89,1.08-1.34,1.71-0.71 l1.59,1.59l4.59-4.59c0.39-0.39,1.02-0.39,1.41,0v0C21.68,3.1,21.68,3.73,21.29,4.12z M4.12,21.29l4.59-4.59l1.59,1.59 c0.63,0.63,1.71,0.18,1.71-0.71V13c0-0.55-0.45-1-1-1H6.41c-0.89,0-1.34,1.08-0.71,1.71l1.59,1.59l-4.59,4.59 c-0.39,0.39-0.39,1.02,0,1.41l0,0C3.1,21.68,3.73,21.68,4.12,21.29z" /></svg>
                                                    </IconButton>
                                                </div>
                                            </li>

                                            <li style={{ padding: '10px 20px' }}>
                                                <Divider />
                                                {
                                                    isClearOptions &&
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                                        <Link
                                                            component="button"
                                                            variant="body2"
                                                            onClick={handleClearOptions}
                                                        >
                                                            {t("DAM:Limpar filtros X")}
                                                        </Link>
                                                    </div>
                                                }
                                            </li>



                                            <li>
                                                <ValuesList
                                                    dataAttributes={dataAttributes}
                                                    disableHierarchy={disableHierarchy}
                                                    handleCallbackInput={handleCallbackInput}
                                                    isDataEmpty={isDataEmpty}
                                                    setIsDataEmpty={setIsDataEmpty}
                                                    loading={loading}
                                                />
                                            </li>

                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                )
            }

        </>
    )
}

FilterItemsView.defaultProps = {
    isClearOptions: false,
    total: 0,
    hasFilters: false,
    isDataEmpty: false,
    dataAttributes: {
        attributes: [],
        structures: [],
    },
    getData: () => { },
    handleCallbackInput: () => { },
    handleClearOptions: () => { },
    setIsDataEmpty: () => { },
}

FilterItemsView.propTypes = {
    isClearOptions: PropTypes.bool,
    total: PropTypes.number,
    hasFilters: PropTypes.bool,
    isDataEmpty: PropTypes.bool,
    dataAttributes: PropTypes.shape({
        attributes: PropTypes.array,
        structures: PropTypes.array,
    }),
    getData: PropTypes.func,
    handleCallbackInput: PropTypes.func,
    handleClearOptions: PropTypes.func,
    setIsDataEmpty: PropTypes.func
}

export default FilterItemsView