import React from "react"
import { mdiCloudDownload, mdiShareVariant } from "@mdi/js"
import Icon from "@mdi/react"
import { Tooltip, useMediaQuery } from "@material-ui/core"
import { useStyles } from "./styles"
import { useTranslation } from "react-i18next"
import { CircularProgress, Fab } from "@material-ui/core"
import DialogSharedItems from "components/Dialogs/DialogSharedItems"
import DialogDetailsAssets from '../../DialogDetailsAssets'
import { useDataAssetIntegra } from "../../context"
import Utils from 'utils/Utils'

import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import ThreeDRotationRoundedIcon from '@material-ui/icons/ThreeDRotationRounded';
import LoupeRoundedIcon from '@material-ui/icons/LoupeRounded';

import BookmarkAsset from './BookmarkAsset'

const ButtonsActions = ({ data, handleComponentArea }) => {
  const { allowDownload, allowEmail, is3DTexture } = data
  const classes = useStyles({ allow: allowDownload && allowEmail })
  const { t } = useTranslation()
  const { stateItem: { loading }, handleOpenDetailsAsset, handleOpenDownloadButton } = useDataAssetIntegra()
  const disableId = loading ? "disabled" : ""
  const isMobile = useMediaQuery('(max-width:600px)')
 
  return (
    <div className={classes.wrapperBtns}>
      {allowDownload && isMobile && (
        <Tooltip title={t("DAM:Download do arquivo")}>
          <div>
            <Fab onClick={() => handleOpenDownloadButton()} id='marginRight'>
              {!loading ? (
                <Icon
                  id={disableId}
                  path={mdiCloudDownload} size={1} color="#616161" />
              ) : (
                <CircularProgress id="CircularProgress" size={20} />
              )}
            </Fab>
          </div>
        </Tooltip>
      )}

      {allowEmail && isMobile && (
        <DialogSharedItems
          dataAsset={{
            idAsset: data.asset.id,
            idItem: data.item_id,
            versionIndex: data.versionIndex,
            isItens: false,
            copyLink: true,
            type: 'asset'
          }}
        >
          {(confirm) => (
            <Tooltip title={t("DAM:Compartilhar arquivo")}>
              <div>
                <Fab onClick={confirm()} id='marginRight'>
                  <Icon path={mdiShareVariant} size={1} color="#616161" />
                </Fab>
              </div>
            </Tooltip>
          )}
        </DialogSharedItems>
      )}

      {
        isMobile &&
        <Tooltip title={t('DAM:Detalhes do ativo')}>
          <div>
            <Fab onClick={() => handleOpenDetailsAsset()} id='marginRight'>
              <LoupeRoundedIcon style={{ color: '#666' }} />
            </Fab>
          </div>
        </Tooltip>
      }

      {
        data.allowManager && isMobile &&
        <BookmarkAsset asset={{ ...data.asset, item_name: data.item_name }} />
      }
     
      {
        (data.asset.extension === 'pdf' ||
          Utils.enabledExtensionsVideos(data.asset.extension))
        && (
          <DialogDetailsAssets
            dataAsset={{
              idAsset: data.asset.id,
              idItem: data.item_id,
              extension: data.asset.extension,
            }}
          >
            {(confirm) => (
              <Tooltip title={data.asset.extension === 'pdf'
                ? t("DAM:Abrir em tela cheia")
                : t("DAM:Executar o video")
              }
              >
                <div>
                  <Fab onClick={confirm()} id='marginRight'>
                    {
                      data.asset.extension === 'pdf'
                        ? <OpenWithRoundedIcon style={{ color: "#616161" }} />
                        : <PlayCircleFilledRoundedIcon style={{ color: "#616161" }} />
                    }
                  </Fab>
                </div>
              </Tooltip>
            )}
          </DialogDetailsAssets>
        )}
      {
        is3DTexture &&
        <Tooltip title={t("DAM:Visualizar modelo 3D")}>
          <div>
            <Fab onClick={() => handleComponentArea("3dModelViewer")} id='marginRight'>
              <ThreeDRotationRoundedIcon />
            </Fab>
          </div>
        </Tooltip>
      }

    </div>
  )
}

export default ButtonsActions
