import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: 20,
    '& p': {
      color: '#666',
    }
  },
  rootPaper: {
    width: "100%",
    minHeight: 700,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  body: {
    color: "#666 !important",
    padding: 20,
    paddingTop: 0,
    "& #title": {
      fontWeight: 500,
      "& span": {
        fontSize: "0.94rem",
      },
    },
    "& #info": {
      fontWeight: 400,
      "& span": {
        fontSize: "0.875rem",
      },
      "& #mui-rte-container": {
        margin: 0,
      },
      "& #mui-rte-editor-container": {
        margin: 0,
        padding: 0,
      },
    },
    "& .MuiChip-root": {
      backgroundColor: "#fff",
      border: "1px solid gray",
    },
  },
  chip: {
    margin: 5,
    height: 25,
    border: '1px solid gray',
    backgroundColor: '#fff',
    "& .MuiChip-avatar": {
      width: 18,
      height: 18,
    },
  },
  contentItem: {
    display: "flex",
    padding: 8,
    width: "100%",
    border: "1px solid #C4C4C4",
    borderRadius: 4,
    fontSize: "0.875rem",
    "&:hover": {
      cursor: "pointer",
    },
    "& img": {
      borderRadius: 4,
    },
    "& .name": {
      display: "flex",
      flexDirection: "column",
      marginLeft: 15,
      marginTop: 10,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 18,
    "& img": {
      filter:
        "invert(43%) sepia(0%) saturate(370%) hue-rotate(176deg) brightness(91%) contrast(94%)",
    },
    "& h6": {
      color: "#666",
      marginLeft: 10,
    },
  },
  wrapperRichText: {
    minHeight: 150,
    border: "1px solid #bababa",
    borderRadius: 4,
  },
  errorRichTextEditor: {
    border: '1px solid #f44336',
    borderRadius: 4,
    minHeight: 150
  },
  labelDescription: {
    top: "12px",
    position: "relative",
    background: "#fff",
    display: 'inline-block',
    left: "11px",
    padding: "0 3px",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    zIndex: 1
  },
  formField: {
    width: '100%',
    position: 'relative',
    color: '#666',        
},
  rootItem: {
    "& .MuiFormControl-root": {
      marginBottom: 0,      
    }
  }
}))
