import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash'
import {
    InputLabel,
    Grid,
    FormControl,
    FormHelperText,
    Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { mdiWallpaper } from "@mdi/js"
import Icon from "@mdi/react"

import AsyncSelect from 'react-select/async';

import svgRemove from '../../imagens/ui/cancel-24px.svg'
import iconSearch from '../../imagens/icons/search.svg'

import { useStyles } from "./styles"

export default function SelectUIMultiAsync({
    title = '',
    disabled = false,
    nameField = '',
    error = false,
    valueSelect = [],
    handleChangeValue,
    loadOptions,
    loading,
    isSearchable,
    msgNoOptionsandLimit,
    isThumb
}) {
    const [focus, setFocus] = React.useState({ status: 'off' });
    const classes = useStyles({ ...focus, error: error });
    const theme = useTheme()
    const { t } = useTranslation()

    useEffect(() => {
        let isMounted = true
        if (Boolean(valueSelect) && valueSelect.length > 0) {
            if (isMounted) setFocus({ status: 'on', is_value: true })
        }
        return () => {
            isMounted = false
        }
    }, [valueSelect])

    const handleFocus = (evt) => {
        evt.preventDefault()
        setFocus({ status: 'on' })
    };

    const handleBlur = (evt) => {
        evt.preventDefault()

        if (Boolean(valueSelect) && valueSelect.length > 0) {
            setFocus({ status: 'on', is_value: true })
        } else {
            setFocus({ status: 'off' })
        }
    };

    const icon = () => ({
        alignItems: 'center',
        display: 'flex',
        ':before': {
            content: `url(${iconSearch})`,
            display: 'block',
            padding: '3px 13px 0 18px'
        },
    });

    const container = () => ({
        padding: '13.5px 0'
    });

    const customStyles = {
        control: (styles) => ({ ...styles, ...icon() }),
        valueContainer: (styles) => ({ ...styles, ...container() }),
        multiValueLabel: () => ({
            color: '#fff',
            padding: '0 12px',
        }),
        multiValue: () => ({
            margin: '4px',
            minHeight: '32px',
            maxWidth: '95%',
            backgroundColor: `${_.get(theme, 'palette.primary.main', '')}`,
            display: 'inline-flex',
            outline: 0,
            padding: 0,
            fontSize: '0.8125rem',
            boxSizing: 'border-box',
            transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            alignItems: 'center',
            borderRadius: '16px',
            verticalAlign: 'middle',
            justifyContent: 'center',
            textDecoration: 'none',
            border: 'none',
            whiteSpace: 'pre-line',
            wordBreak: "break-all"
        }),
        multiValueRemove: () => ({
            borderRadius: '50%',
            width: 22,
            height: 22,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundImage: `url(${svgRemove})`,
            backgroundPosition: 'center',
            margin: '0 5px 0 -6px',
            '& svg': {
                display: 'none'
            },
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)'
            }
        }),
        menu: provided => ({ ...provided, zIndex: 3 }),
        menuList: provided => ({ ...provided, maxHeight: 160 })
    }

    const MyOption = props => {
        const { innerProps, innerRef, data } = props;
        const classes = useStyles();
        return (
            <div ref={innerRef} {...innerProps} className={classes.rootOption}>
                {isThumb && Boolean(data.thumbnail)
                    ? <img src={data.thumbnail} width='30' height='30' alt={data.label} />
                    : <div style={{ width: 30, height: 30, marginRight: 10 }}>
                        <Icon path={mdiWallpaper} color="rgb(240, 240, 240)" />
                    </div>
                }
                <Typography variant='body1' noWrap>
                    {data.label}
                </Typography>
            </div>
        );
    };

    const components = {
        ClearIndicator: null,
        DropdownIndicator: null,
        Option: MyOption
    }

    return (
        <Grid item xs={12} className={error ? classes.rootError : classes.root}>
            <FormControl variant="outlined">
                <InputLabel className={(focus.status === 'on') ? 'label-focus-on-is-mult' : 'label-focus-off-is-mult'}>
                    {title}
                </InputLabel>
                <AsyncSelect
                    isDisabled={disabled}
                    cacheOptions
                    isMulti
                    loadOptions={loadOptions}
                    isLoading={loading}
                    classNamePrefix="select-custom"
                    styles={customStyles}
                    components={components}
                    placeholder=''
                    onChange={handleChangeValue}
                    isSearchable={isSearchable}
                    noOptionsMessage={() => msgNoOptionsandLimit}
                    defaultValue={valueSelect}
                    loadingMessage={() => `${t('manager:carregando')}...`}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    name={nameField}
                />
                {
                    error &&
                    <FormHelperText>
                        {error}
                    </FormHelperText>
                }
            </FormControl>
        </Grid>
    );
}
