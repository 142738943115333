import React from "react"
import { pick } from "ramda"
import axios from "axios/index"

import Template from "../Template"
import Loading from "../../../components/Loading"
import { Typography, Grid, Button, Paper } from "@material-ui/core"
import ErrorBoundary from "../../../components/Core/ErrorBoundary"
import DialogDeleteValidation from "../../../components/Dialogs/DeleteValidationPhrase"

import { WrapperPaperForms } from '../../../components/WrapperForms'
import Settings from 'utils/Settings'
import Utils from "utils/Utils";
export default class CreateDefault extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataOriginal: {},
      data: {},
      hasData: false,
      formValid: true,
      formErrors: {},
      loading: false,
      selectedOption: null,
    }
    this.insert = this.insert.bind(this)
    this.showMessage = this.showMessage.bind(this)
  }

  getParamsAxios() {
    const { pathServer, typeForm } = this.props
    const IS_CREATE = typeForm === "create"

    return {
      method: IS_CREATE ? "post" : "put",
      url: IS_CREATE
        ? `${pathServer}/${this.state.entity}`
        : `${pathServer}/${this.state.entity}/${this.state.id}`,
    }
  }

  showMessage = (message, type) => {
    const params = type === "success"
      ? { ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS }
      : {
        variant: type,
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }

    this.props.enqueueSnackbar(message, params)
  }

  // UPDATE
  insert(e) {
    e.preventDefault()
    const SELF = this
    const { t, requestHeaders } = SELF.props
    const parameters = SELF.getParameters()

    let formValid = SELF.validateForm(parameters)

    if (!formValid) {
      return
    }
    const IS_CREATE = this.props.typeForm === "create"

    if (!IS_CREATE) {
      delete parameters["title"]
    }

    const paramsAxios = this.getParamsAxios()

    const callback = () => {
      axios({
        method: paramsAxios.method,
        url: paramsAxios.url,
        data: parameters,
        headers: { ...requestHeaders.headers },
      })
        .then(
          async function (resp) {
            let msg = { text: resp.headers["x-message"], variant: 'success' }
            SELF.showMessage(msg.text, msg.variant)
            SELF.props.history.push(`/manager/${SELF.state.route}`)
          },
          (err) => {
            if (err.response.status === 422 && this.state.entity === 'category') {
              SELF.setState({
                ...SELF.state,
                loading: false,
                dialog: {
                  open: true,
                  data: err.response.data
                }
              })
            } else {
              const message = Utils.ajaxErrorGetMessage(err, t)
              SELF.showMessage(message, 'error')
              SELF.setState({ ...SELF.state, loading: false })
            }
          }
        )
        .catch(function (error) {
          SELF.showMessage(t("common:Erro ao processar, contate o suporte"), 'error')
          SELF.setState({ ...SELF.state, loading: false })
        })
    }

    SELF.setState({ ...SELF.state, loading: true }, callback())
  }

  getParameters() {
    const SELF = this
    let { data, dataOriginal, fields } = SELF.state
    let parameters = pick(fields, data)
    let modifyParameters = Object.keys(parameters).filter(
      (fieldName) => parameters[fieldName] !== dataOriginal[fieldName]
    )
    return pick(modifyParameters, parameters)
  }

  validateForm(parameters) {
    const { t } = this.props
    let formValid = true
    let formErrors = {}
    if (parameters.hasOwnProperty("name") && !parameters.name.trim()) {
      formErrors.name = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    this.setState({ ...this.state, formErrors, formValid })
    return formValid
  }

  getClassName = (nameAttribute) => {
    let isError = !this.state.formErrors.hasOwnProperty(nameAttribute)
    return isError ? "form-control" : "form-control has-error"
  }

  // HANDLERS
  handleChange = (attribute, evt) => {
    let newState = { ...this.state }
    newState.data[attribute] = evt.target.value
    delete newState.formErrors[attribute]
    this.setState(newState)
  }

  handleChangeSwitch = (attribute, evt) => {
    let newState = { ...this.state }
    newState.data[attribute] = evt.target.checked
    this.setState(newState)
  }

  handleChangeComboBox = (attribute, selectedOption) => {
    let newState = { ...this.state }
    newState.data[attribute + "_id"] = selectedOption.value
    if (!newState.data.hasOwnProperty(attribute)) {
      newState.data[attribute] = {}
    }
    newState.data[attribute].id = selectedOption.value
    newState.data[attribute].name = selectedOption.label
    this.setState(newState)
  }

  handleChangeSelect = (attribute, selectedOption) => {
    let newState = { ...this.state }
    newState["data"][attribute] = selectedOption
    this.setState(newState)
  }

  handleClose = (dialog) => {
    this.setState((prev) => ({
      ...prev,
      dialogs: {
        ...prev.dialogs,
        [dialog]: false,
      },
    }))
  }

  delete = () => {
    const SELF = this
    const { pathServer, requestHeaders } = SELF.props
    SELF.setState({ ...SELF.state, loading: true })
    axios
      .delete(
        `${pathServer}/${SELF.state.entity}/${SELF.state.id}`,
        requestHeaders
      )
      .then(
        function (resp) {
          SELF.setState(
            {
              ...SELF.state,
              loading: false,
            },
            () => SELF.props.history.push(`/manager/${SELF.state.route}`)
          )
          SELF.showMessage(resp.headers["x-message"], 'success')
        },
        (resp) => {
          SELF.setState({ ...SELF.state, loading: false })
          SELF.showMessage(resp.headers["x-message"], 'error')
        }
      )
      .catch((err) => {
        SELF.showMessage(SELF.props.t("Erro ao deletar os dados, contate o administrador."), 'error')
      })
  }

  getDisabledSubmit() {
    return this.state.loading
  }

  render() {
    const { t, typeForm } = this.props
    let singularTitle = this.state.title.singular.toLowerCase()

    const getActions = () => {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          {this.props.typeForm !== "create" && (
            <DialogDeleteValidation
              entity={this.props.t(`manager:${this.state.title.singular}`)}
              name={this.state.data.title || ""}
            >
              {(confirm) => (
                <Button
                  style={{
                    height: 40,
                    width: 115,
                    marginRight: 20,
                    color: "#F4511E",
                    border: "1px solid #F4511E",
                  }}
                  variant="outlined"
                  onClick={confirm(this.delete)}
                  disabled={this.state.loading}
                >
                  {this.props.t("common:Excluir")}
                </Button>
              )}
            </DialogDeleteValidation>
          )}
          <Button
            style={{ height: 40, width: 115, marginRight: 20 }}
            variant="outlined"
            color="primary"
            onClick={() =>
              this.props.history.push(`/manager/${this.state.route}`)
            }
          >
            {t("common:Voltar")}
          </Button>
          <Button
            style={{ height: 40, width: 115 }}
            variant="contained"
            color="primary"
            onClick={this.insert}
            disabled={this.getDisabledSubmit()}
          >
            {t("common:Salvar")}
          </Button>
        </div>
      )
    }

    return (
      <Template
        area={this.state.area}
        localHistory={this.props.history}
        customClassName={"container-center"}
      >
        <ErrorBoundary>
          {this.state.loading && <Loading showLoading={this.state.loading} />}
          {
            this.state.entity === 'profile' &&
              this.state.area === 7 ? (
              <Paper elevation={2} className={this.props.classes.paperForm}>
                <form action="" className="form-default">
                  <Grid
                    container
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginBottom: 20,
                    }}
                  >
                    <Grid item xs={9}>
                      <Typography variant="h5" gutterBottom>
                        {typeForm === "create"
                          ? t("common:Criar")
                          : t("common:Editar")}{" "}
                        {t(`manager:${singularTitle}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    {this.state.hasData ? this.getForm() : null}
                  </Grid>
                  <div className="clearfix" />
                  {getActions()}
                </form>
              </Paper>
            ) : (
              <>
                <WrapperPaperForms
                  title={typeForm === "create"
                    ? `${this.props.workflow ? t(`manager:${singularTitle}`) : `${t("manager:Criação de")} ${t(`manager:${singularTitle}`)}`}`
                    : `${this.props.workflow ? t(`manager:${singularTitle}`) : `${t("manager:Edição de")} ${t(`manager:${singularTitle}`)}`}`}>
                  {this.state.hasData ? this.getForm() : null}
                  {getActions()}
                </WrapperPaperForms>
              </>
            )
          }
        </ErrorBoundary>
      </Template>
    )
  }
}