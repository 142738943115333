/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable  no-unused-vars*/
import UploadDefault from "../Core/Upload"
import {settings} from "./_settings.js";

import { compose } from "recompose"
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next"
import { withSnackbar } from 'notistack';
import { styles } from '../Core/UploadLanguage/styles'

class Upload extends UploadDefault {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            ...settings,
        };
        this.getUrl = this.getUrl.bind(this)
    }

    getUrl(path) {
        const pathClient = localStorage.getItem("clientPath")
        return `${pathClient}/${path}`
    }
}

export default compose(
    withStyles(styles)  
)(withTranslation(["manager", "common"])(withSnackbar(Upload)))