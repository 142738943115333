import React, { useEffect, useCallback, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { getParamsUri, getParamsUriRedirect } from 'utils/Helpers'
import { useSnackbar } from 'notistack'

import AccessRequest from 'pages/AccessRequest'
import Utils from 'utils/Utils'
import AppActions from "flux/actions/AppActions"
import { redirect } from 'utils/Helpers'

export default function OauthValidation() {
    const [state, setState] = useState({ dataUser: {} })
    const { pathClient, data: { client_slug } } = useSelector(
        (state) => state.appReducer
    )
    const { t } = useTranslation()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const returnError = useCallback(message => {
        enqueueSnackbar(message, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        })
    }, [enqueueSnackbar])

    const setUserData = useCallback(async (data) => {
        try {
            let newData = { client_slug: client_slug }
            let token = _.get(state, 'token', '')

            if (data.current_policy_accepted) {
                newData = {
                    ...newData,
                    ..._.get(state, 'dataUser', {}),
                    user: {
                        ..._.get(state, 'dataUser.user', {}),
                        current_policy: {
                            ..._.get(state, 'dataUser.user.current_policy', {}),
                            accepted: data.current_policy_accepted,
                        }
                    }
                }
            }

            const res = await dispatch(AppActions.updateUserData(newData))

            if (Boolean(token)) {
                const requestHeaders = {
                    headers: {
                        Authorization: token
                    }
                }
                const changeRegion = (slug, data, callback) => dispatch(AppActions.changeRegion(slug, data, callback))
                redirect(res.data.region, requestHeaders, history, pathClient, changeRegion)
            }
        } catch (err) {
            console.log(err)
        }
    }, [client_slug, dispatch, history, pathClient, state])

    const getData = useCallback((code, state_) => {
        const checkState = sessionStorage.getItem(state_)

        if (checkState) {
            const stateData = JSON.parse(checkState);
            const params = {
                code: code,
                sso_id: stateData.sso_id,
                scope: stateData.scope,
                redirect_uri: getParamsUriRedirect(true)
            }
            axios
                .post(`${pathClient}/oauth/login`, params)
                .then(async function ({ data }) {
                    sessionStorage.removeItem(state_)
                    if (_.get(data, "user.situation", "") === "incomplete-allowed") {
                        setState({
                            name: _.get(data, 'user.name', ''),
                            email: _.get(data, 'user.email', ''),
                            profile: _.get(data, 'profile', {}),
                            token: _.get(data, 'token', ''),
                            id: _.get(data, 'user.id', ''),
                            dataUser: data,
                        })
                    } else {
                        const res = await dispatch(AppActions.updateUserData(data))
                        if (Boolean(_.get(data, 'token', ''))) {
                            const requestHeaders = {
                                headers: {
                                    Authorization: _.get(data, 'token', '')
                                }
                            }
                            const changeRegion = (slug, data_, callback) => dispatch(AppActions.changeRegion(slug, data_, callback))
                            redirect(res.data.region, requestHeaders, history, pathClient, changeRegion)
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                    let message = Utils.ajaxErrorGetMessage(err, t, t("login:Solicitação de login não foi aceita. Tente novamente por favor."))
                    returnError(message)
                    history.push('/login')
                });
        } else {
            const checkUserAuth = localStorage.getItem('user_auth')
            if (!checkUserAuth) {
                returnError(t("common:Requisição inválida"))
                history.push("/login")
            }
        }
    }, [pathClient, returnError, history, t, dispatch])

    useEffect(() => {
        let isMounted = true
        const params = getParamsUri(window.location.search)

        if (params.code && isMounted) {
            getData(params.code, params.state)
        } else {
            history.push('/')
        }
        return () => { isMounted = false }
    }, [history, getData])


    return !_.isEmpty(state.dataUser) && (
        <AccessRequest
            type='completeRegistration'
            setUserData={setUserData}
            initialState={{
                oauth: true,
                activeStep: 2,
                ...state,
            }}
        />
    )
}
