import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash'
import axios from "axios"
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import Sections from './sections'
import { createEditForm, createForm } from 'components/CategoryAttributes'
import LoadingSkeleton from "./LoadingSkeleton"
import Utils from 'utils/Utils'
import { useDataFormItem } from "./context"

export default function CreateItem() {
    const [state, setState] = useState({ loading: true, checkedData: false, data: {} })
    const { setInitialState } = useDataFormItem()
    const { pathServer, requestHeaders } = useSelector(state => state.appReducer)
    const { paramsDataToCreateItems, filtersOptions, dataLevels } = useSelector((state) => state.itemsReducer)
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const getParamsByContextData = useCallback(() => {
        let data = {}

        Object.entries(paramsDataToCreateItems).forEach(([key, value]) => {
            if (typeof value === 'object' && !_.isEmpty(value)) {
                data[key] = value
            }
            if (typeof value === 'string' && value.length > 0) {
                data[key] = value
            }
        })

        if (filtersOptions.length > 0) data["filtersOptions"] = filtersOptions
        if (!_.isEmpty(dataLevels)) data["levels"] = dataLevels

        return data
    }, [filtersOptions, paramsDataToCreateItems, dataLevels])

    const getData = useCallback(async () => {
        const parameters = getParamsByContextData()

        if (_.isEmpty(parameters)) {
            setState(prev => ({ ...prev, loading: false, checkedData: true }))
        } else {
            try {
                const result = await axios.post(`${pathServer}/item/context`, parameters, requestHeaders)
                const category_id = _.get(result, 'data.category.id', _.get(result, 'data.category_id', ''))
                let newData = {
                    ..._.get(result, 'data', {}),
                }

                if (Boolean(category_id)) {
                    const categoryResult = await axios.get(`${pathServer}/attributesByCategory/${category_id}`, requestHeaders)
                    const dataAttributes = _.get(categoryResult, 'data.items', []).map((dataAttribute) => {
                        dataAttribute.mask_value = dataAttribute.mask_value ? dataAttribute.mask_value.trim() : '';
                        return dataAttribute
                    })


                    if (_.isEmpty(_.get(result, 'data.attributes', {}))) {
                        newData["dataFields"] = createForm(dataAttributes)
                        newData["attributes"] = dataAttributes
                    } else {
                        let attributes = []
                        Object.entries(_.get(result, 'data.attributes', {})).forEach(([key, item]) => {
                            attributes.push({ attribute_id: key, value: item.values, label: item.label })
                        })
                        newData["dataFields"] = createEditForm(attributes, dataAttributes)
                        newData["attributes"] = dataAttributes
                    }
                }

                if (!_.isEmpty(_.get(result, 'data.attributes', {})) && !Boolean(category_id)) {
                    delete result.data.attributes
                }

                if (_.get(result, 'data.category.canPublish', false) || _.get(result, 'data.segment.canPublish', false)) {
                    if (_.get(result, 'data.segment.canPublish', false)) {
                        newData["isCanPublish"] = { value: true, id: "segment" }
                    }
                    if (_.get(result, 'data.category.canPublish', false)) {
                        newData["isCanPublish"] = { value: true, id: "category" }
                    }
                }

                newData["name"] = _.get(parameters, 'searchText', '')

                const callback = () => setState({ loading: false, checkedData: true })
                setInitialState(newData, callback)

                setState(prev => ({ ...prev, loading: false, checkedData: true }))
            } catch (err) {
                let message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
                setState(prev => ({ ...prev, loading: false, checkedData: true }),
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    }))
            }
        }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, getParamsByContextData, setInitialState])

    useEffect(() => {
        let isMounted = true;
        if (isMounted && !state.checkedData) getData()
        return () => { isMounted = false }
    }, [getData, state.checkedData])

    if (state.loading) {
        return <LoadingSkeleton />
    }

    return (
        <Sections initialData={state.data} />
    )
}
