import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core';

import { useDataItemFiles } from './context'

const ActionsBucketManagerAssets = () => {
    const { handleAddAssets, isDisabledAddAssets, handleComponentStatus } = useDataItemFiles()
    const { t } = useTranslation()

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
            <Button
                variant='outlined'
                color='primary'
                style={{ height: 40, marginRight: 10 }}
                onClick={() => handleComponentStatus('initial')}
            >
                {t("common:Voltar")}
            </Button>
            <Button
                variant='contained'
                color='primary'
                style={{ height: 40 }}
                onClick={handleAddAssets}
                disabled={isDisabledAddAssets}
            >
                {t("common:Adicionar")}
            </Button>
        </div>
    )
}

export default ActionsBucketManagerAssets