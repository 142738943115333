import React from 'react'
import PropTypes from 'prop-types';

import { Menu, Typography, MenuItem, makeStyles, Tooltip, Button } from '@material-ui/core';

import SortByAlphaRoundedIcon from '@material-ui/icons/SortByAlphaRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';


const useStyles = makeStyles((theme) => ({
    rootButton: {
        '& span.MuiButton-startIcon': {
            margin: 0
        }
    },
    rootTitle: {
        padding: '6px 16px',
        color: '#666',
        '& p': {
            fontWeight: 600
        }
    },
    rootMenu: {
        '& .Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            opacity: 1
        }
    }
}));

const MenuSortingView = ({
    title,
    options,
    selectedIndex,
    handleMenuItemClick,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (index) => {
        handleMenuItemClick(index)
        setAnchorEl(null);
    }

    return (
        <>
            <Tooltip title={title} placement="top">
                <div className={classes.rootButton}>
                    <Button
                        style={selectedIndex > 0 ? {} : { color: '#666' }}
                        color={selectedIndex > 0 ? "primary" : "default"}
                        endIcon={
                            Boolean(anchorEl)
                                ? <KeyboardArrowUpRoundedIcon fontSize='small' />
                                : <KeyboardArrowDownRoundedIcon fontSize='small' />
                        }
                        startIcon={<SortByAlphaRoundedIcon />}
                        onClick={handleClick}
                    />

                </div>
            </Tooltip>

            <Menu
                id="menu-ordination"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.rootMenu}
            >
                <div className={classes.rootTitle}>
                    <Typography variant='body1' noWrap>
                        {title}
                    </Typography>
                </div>
                {options.map((option, index) => (
                    <MenuItem
                        key={option.id}
                        disabled={index === selectedIndex}
                        selected={index === selectedIndex}
                        onClick={() => handleClickMenu(index)}
                    >
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

MenuSortingView.defaultProps = {
    hasFilters: false,
    title: '',
    options: [],
    selectedIndex: 0,
    handleMenuItemClick: () => { }
}

MenuSortingView.propTypes = {
    hasFilters: PropTypes.bool,
    title: PropTypes.string,
    options: PropTypes.array,
    selectedIndex: PropTypes.number,
    handleMenuItemClick: PropTypes.func,

}

export default MenuSortingView