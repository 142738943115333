import React, { useEffect, useState } from "react"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import IconButton from "@material-ui/core/IconButton"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CardCarousel from "./CardCarousel"
import { useSelector, useDispatch } from "react-redux"
import {
  Tooltip,
  Checkbox,
  Box,
  Button,
  Typography,
  CardContent,
} from "@material-ui/core"
import Loading from "components/Loading"

import DialogPublish from "components/Alert/DialogPublish"
import DeleteValidationPhrase from 'components/Dialogs/DeleteValidationPhrase'
import { useStyles } from "./styles"
import _ from "lodash"

import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import AppActions from "flux/actions/AppActions"
import Utils from "utils/Utils"

const CardItem = ({
  dataItem,
  canManage,
  canPublish,
  published,
  deleteItem,
  publishItem,
  loading = false
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { region } = useSelector((state) => state.appReducer.userData)
  const { markedItems } = useSelector((state) => state.appReducer)
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if(markedItems.some((i) => i.id === dataItem.id)) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [markedItems, dataItem])

  const handleChangeSelected = (evt) => {
    setChecked(prev => !prev)

    if(evt.target.checked) {
      dispatch(AppActions.setmarkedItems(dataItem))
    } else {
      dispatch(AppActions.removeMarkedItems(dataItem))
    }
  }

  const itemError = _.get(dataItem, "taxonomy.name", "") === ""

  const linkUrl = () => {
    return itemError
      ? "#"
      : `/dam/${region}/${_.get(dataItem, "division.slug", "")}/${_.get(
          dataItem,
          "segment.slug",
          ""
        )}/${_.get(dataItem, "category.slug", "")}/${_.get(
          dataItem,
          "taxonomy.slug",
          ""
        )}`
  }

  const className = itemError
    ? classes.cardError
    : !published
    ? classes.cardUnpublished
    : classes.card
  const cardTitle = !itemError
    ? _.get(dataItem, "taxonomy.name", "")
    : t(
        "DAM:Este item teve erro na geração da taxonomia. Exclua-o para incluir novamente"
      )
  const publishTooltip = published
    ? t("DAM:Despublicar item")
    : t("DAM:Publicar item")
  const publishMessageKey = published
    ? t("common:Você tem certeza que deseja despublicar o item?")
    : t("common:Você tem certeza que deseja publicar o item?")

  return (
        <Card
          className={className}
          key={
            dataItem
              ? `list-card-${dataItem.id}`
              : `list-card-${_.get(dataItem, "taxonomy.slug", "")}`
          }
        >
          {
          loading
          ? <Loading showLoading={true} />
          : <>
            <Tooltip title={cardTitle}>
              <div>
                <CardContent className={classes.cardContent}>
                  <Link
                    to={linkUrl()}
                    style={{ textDecoration: "none", cursor: "pointer", width: '100%' }}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      component="div"
                      textoverflow="ellipsis"
                    >
                      <Box
                        style={{ whiteSpace: "nowrap" }}
                        component="div"
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        {_.get(dataItem, "name", "")}
                      </Box>
                    </Typography>


                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ fontSize: ".7rem", marginTop: 2 }}
                  >
                    {`${_.get(dataItem, "assets", []).length} ${
                      _.get(dataItem, "assets", []).length === 1
                        ? t("common:ativo")
                        : t("common:ativos")
                    }`}
                  </Typography>

                  {
                    _.get(dataItem, "validity_date", false) &&
                    <Tooltip title={t("DAM:Data final de vigência")}>
                      <div className={classes.headerDate} >
                      <CalendarTodayRoundedIcon />
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          style={{ fontSize: ".7rem",  }}
                        >
                          {Utils.formatDate(_.get(dataItem, "validity_date", ''))}
                        </Typography>
                      </div>
                    </Tooltip>
                  }

                  </Link>
                </CardContent>

                <CardCarousel
                  data={{
                    assets: _.get(dataItem, "assets", [{ file: "" }]),
                  }}
                />
              </div>
            </Tooltip>
            <CardActions
              disableSpacing
              style={{
                display: "flex",
                justifyContent: checked ? "space-between" : "space-around",
              }}
            >
              {itemError ? (
                <Typography color="error" component="div" textoverflow="ellipsis">
                  <Box
                    style={{ whiteSpace: "nowrap" }}
                    component="div"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {t("DAM:Item com erro")}
                  </Box>
                </Typography>
              ) : (
                <Link
                  to={linkUrl()}
                  style={{ textDecoration: "none", cursor: "pointer", zIndex: 1 }}
                >
                  <Button size="small" color="primary">
                    {t("common:Detalhes")}
                  </Button>
                </Link>
              )}

              {!checked && canManage && (
                <React.Fragment>
                  <DeleteValidationPhrase
                    entity={t("common:Item")}
                    name={_.get(dataItem, "taxonomy.name", "")}
                  >
                    {(confirm) => (
                      <Tooltip title={t("DAM:Excluir item")}>
                        <IconButton
                          className={classes.button}
                          aria-label="Delete"
                          onClick={confirm(deleteItem)}
                        >
                          <DeleteRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </DeleteValidationPhrase>
                </React.Fragment>
              )}
              {!checked && canPublish && (
                <React.Fragment>
                  <DialogPublish
                    t={t}
                    title={t("common:Publicação do item")}
                    description={t(publishMessageKey, {
                      publishName: _.get(dataItem, "name", ""),
                    })}
                  >
                    {(confirm) => (
                      <Tooltip title={publishTooltip}>
                        <IconButton
                          className={classes.button}
                          aria-label="Publish"
                          onClick={confirm(publishItem)}
                        >
                          {published ? <VisibilityIcon /> : <VisibilityOffIcon /> }
                        </IconButton>
                      </Tooltip>
                    )}
                  </DialogPublish>
                </React.Fragment>
              )}

              {itemError ? (
                <React.Fragment />
              ) : (
                <Tooltip title={t("common:Selecionar")}>
                  <div>
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeSelected}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </Tooltip>
              )}
            </CardActions>
            {checked && (
              <Tooltip title={cardTitle}>
                <div className={classes.cardSelected} />
              </Tooltip>
            )}
          </>
          }
        </Card>
  )
}

export default CardItem
