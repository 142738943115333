import * as THREE from 'three'

export const getTypesTextures = (type, url) => { 
    switch (type) {
      case "diffuse":        
        return {
          map: new THREE.TextureLoader().load(url)
        } 
      case 'displacement':
        return {
          displacementMap: new THREE.TextureLoader().load(url),
          displacementScale: 0.005
        }  
      case 'normal': 
        return {
          normalmap: new THREE.TextureLoader().load(url)
        }  
      case 'bump': 
        return {
          bumpMap: new THREE.TextureLoader().load(url)
        }     
      case 'roughness':
        return {
          roughnessMap: new THREE.TextureLoader().load(url),
          roughness: 0.5
        }  
      case 'metalness':
        return {
          metalnessMap: new THREE.TextureLoader().load(url),
          metalness: 1
        } 
      case 'alpha':
        return {
          alphaMap: new THREE.TextureLoader().load(url)
        }       
      case 'aomap':
        return {
          aoMap: new THREE.TextureLoader().load(url)
        }    
      case 'light':
        return {
          lightMap: new THREE.TextureLoader().load(url)
        }      
      default:
        return {}
    }
  }