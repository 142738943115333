import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  rootCardHoverError: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#E35C33",
    zIndex: 2,
    borderRadius: 4,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    padding: 10,
    justifyContent: "space-between",
    cursor: "pointer",
  },
  markedCard: {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: 4,
    border: (props) => `1px solid ${props.primaryColor}`,
    "& .content": {
      width: "100%",
      height: "100%",
      backgroundColor: (props) => props.primaryColor,
      opacity: 0.08,
    },
    "& div.icon": {
      position: "absolute",
      padding: 20,
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      "& svg": {
        color: (props) => props.primaryColor,
      },
    },
  },
  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    "& p": {
      marginBottom: 40,
    },
  },
  icon: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      margin: 5,
    },
  },
  actions: {
    justifyContent: "flex-end",
    padding: 0,
  },
}))
