import React from 'react'
import { Button } from "@material-ui/core";
import {uuid} from 'uuidv4'
import { getParamsUriRedirect } from 'utils/Helpers'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next';

const OauthButton = ({ssoData}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const redirectLoginForOAuth = () => {
        try {
            const endpoint = new URL(ssoData.authorizeUrl);
            const id = uuid();
            const state = {"sso_id": ssoData.id}
            endpoint.searchParams.append("client_id", ssoData.clientId);
            endpoint.searchParams.append("redirect_uri", getParamsUriRedirect());
            endpoint.searchParams.append("response_type", "code");
            endpoint.searchParams.append("scope", ssoData.scope);
            endpoint.searchParams.append("state", id);
    
            sessionStorage.setItem(id, JSON.stringify(state));
    
            window.location.href = endpoint.toString();
        } catch(e) {
            console.log("Erro SSO: ", e);
            enqueueSnackbar(t("login: Parâmetros para autenticação inválidos. Contate o suporte"), {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              })
        }
    }
    return (
        <div style={{paddingBottom: "5px"}}>
            <Button
                component='a'
                variant="contained"
                color="primary"
                onClick={() => redirectLoginForOAuth()}
            >{ssoData.name}</Button>
        </div>

    )
}

export default OauthButton