import React, { useState } from "react"
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next"

import {
  Typography,
  InputBase,
  Avatar,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core"
import { useTheme, withStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import CancelIcon from "@material-ui/icons/Cancel"
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import CheckSVG from '../../../../imagens/ui/fact_check-24px.svg'

import { useStyles } from "./styles"

const ContentSVG = withStyles({
  svg: {
    filter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(19deg) brightness(110%) contrast(101%)"
  },
  disabled_svg: {
    filter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(19deg) brightness(0.1) opacity(30%)"
  },
})(({ classes, thumbnail, title, disabled }) => (
  <img
    src={thumbnail}
    alt={title}
    width="24"
    height="24"
    className={!disabled ? classes.svg : classes.disabled_svg}
  />
))

const HeaderTable = ({ title, handleSubmitSearch, hasSearchText, route, entity, customComponentHeader = null }) => {
  const [searchText, setSearchText] = useState(hasSearchText)
  const [open, setOpen] = useState(false)
  const [toolPath, setToolPath] = useState({ disabled: true, path: '' })
  const classes = useStyles()
  const Icon = title.icon
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()

  const runTextSearch = (e) => {
    let key = e.key

    if (key.toLowerCase() === "enter" && e.target.value.trim() !== "") {
      handleSubmitSearch(searchText)
    }

    if (key.toLowerCase() === "backspace" && e.target.value.length === 1) {
      handleSubmitSearch()
    }
  }

  const handleClose = () => {
    setOpen(false);
    setToolPath(prev => ({ ...prev, path: '', disabled: true }))
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        {Icon && (
          <Avatar className={classes.avatar}>
            <Icon />
          </Avatar>
        )}
        {
          _.get(title, 'plural', false) &&
          <Typography variant="h6">{t(`common:${title.plural}`)}</Typography>
        }
      </div>

      <div style={{ display: 'flex' }}>
        {customComponentHeader}
        {
          (route !== 'users') &&
          <>
            {
              entity !== 'tool' ? (
                <Tooltip
                  title={`${t('common:Incluir')} ${t(`common:${_.get(title, 'singular', '')}`)}`}
                  placement="left"
                >
                  <IconButton
                    color="inherit"
                    onClick={() => history.push(`/manager/${route}/new`)}
                    id='icon-button'
                  >
                    <AddCircleRoundedIcon fontSize='large' color='primary' />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <IconButton
                    color="inherit"
                    onClick={handleOpen}
                    style={{ padding: 0 }}
                  >
                    <AddCircleRoundedIcon fontSize='large' color='primary' />
                  </IconButton>
                  {
                    open &&
                    <Dialog
                      open={open}
                      onClose={handleClose}
                    >
                      <div style={{ padding: 20 }}>
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 25 }}>
                          <div style={{ width: 44, height: 44, borderRadius: '50%', backgroundColor: _.get(theme, 'palette.primary.main', ''), display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
                            <ContentSVG
                              thumbnail={CheckSVG}
                            />
                          </div>
                          <DialogTitle className={classes.dialogTitle}>
                            {t('common:Selecione qual ferramenta você deseja criar')}:
                          </DialogTitle>
                        </div>
                        <DialogContent>
                          <FormControl>
                            <RadioGroup>
                              <div>
                                <FormControlLabel value='request' className={classes.radioTitle} label={t('manager:Solicitação')} control={<Radio color='primary' onClick={() => setToolPath(prev => ({ ...prev, path: 'request', disabled: false }))} />} />
                                <Typography variant='body2'>
                                  {t('common:A partir de itens cadastrados na plataforma, envie uma solicitação para que outros usuários possam realizar alguma tarefa.')}
                                </Typography>
                              </div>
                              <div style={{ margin: '18px 0 15px' }}>
                                <FormControlLabel value='workflow' className={classes.radioTitle} label={t('manager:Workflow')} control={<Radio color='primary' onClick={() => setToolPath(prev => ({ ...prev, path: 'workflow', disabled: false }))} />} />
                                <Typography variant='body2'>
                                  {t('common:Crie um fluxo de trabalho que necessite da aprovação de tarefas e gere um novo item ao finalizar a ferramenta.')}
                                </Typography>
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ margin: 10 }}
                            onClick={handleClose}
                          >
                            {t(`common:Voltar`)}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: 10 }}
                            disabled={toolPath.disabled}
                            onClick={() => history.push(`/manager/${toolPath.path}/new`)}
                          >
                            {t(`common:Avançar`)}
                          </Button>
                        </DialogActions>
                      </div>
                    </Dialog>
                  }
                </>
              )
            }
          </>
        }

        <div className={classes.search}>
          <div className="icon search">
            <SearchIcon />
          </div>

          <InputBase
            placeholder={t("manager:Buscar pelo nome")}
            onChange={(evt) => setSearchText(evt.target.value)}
            onKeyDown={(evt) => runTextSearch(evt)}
            autoFocus
            value={searchText}
          />
          <div className="icon">
            {searchText.length > 0 && (
              <CancelIcon
                onClick={() => handleSubmitSearch()}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      </div>

    </div >
  )
}

export default HeaderTable
