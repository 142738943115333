import React, {  useEffect } from "react"

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import View from './view'

function MenuSorting({ setParametersFilters, filtersParams }) {
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const { t } = useTranslation()
    const options = [
        { id: 0, title: t("DAM:Mais recentes"), field: "created_at", direction: "desc" },
        { id: 1, title: t("DAM:Mais antigos"), field: "created_at", direction: "asc" },
        { id: 2, title: t("DAM:Arquivos de A-Z"), field: "original_name.keyword", direction: "asc" },
        { id: 3, title: t("DAM:Arquivos de Z-A"), field: "original_name.keyword", direction: "desc" },
    ]

    useEffect(() => {
        let isMounted = true

        if(!_.isEmpty(filtersParams)) {
            const { id } = options.find(i => i.field === _.get(filtersParams, 'orderField', '') && i.direction === _.get(filtersParams, 'orderType', ''))
            if(isMounted) setSelectedIndex(id)
        } else {
            if(isMounted) setSelectedIndex(0)
        }

        return () => {
          isMounted = false
          return null
        }
    }, [filtersParams, options])

    const handleMenuItemClick = (index) => {
        const option = options.find(i => i.id === index)

        const params = {
            orderType: option.direction,
            orderField: option.field
        }

        setSelectedIndex(index)
        setParametersFilters('order', params)
    }

    return (
            <View
                title={t("DAM:Ordenação de arquivos")}
                options={options}
                selectedIndex={selectedIndex}
                handleMenuItemClick={handleMenuItemClick}
                hasFilters
                isArrowButton
            />
    )
}


export default MenuSorting
