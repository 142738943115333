import React, { useEffect, useCallback } from "react";
import SelectionArea from "@viselect/vanilla";
import { useSelector, useDispatch } from "react-redux"
import { Grid } from "@material-ui/core"
import AppActions from "flux/actions/AppActions"
import CardFile from './Cards'
import Footer from './CardWrapperFooter'

const extractIds = (els) => {
    return els.map((v) => v.getAttribute("data-key")).filter(Boolean)
}

const selection = new SelectionArea({
    selectionContainerClass: "selection-area-container",
    selectables: [".selectable"],
    startareas: [".areaBoundaries"],
    boundaries: [".areaBoundaries"]
})

const Cards = ({ dataAssets = [], isManager, handlerReprocess, handleConfirmDelete, simpleBucket }) => {
    const { hasIntegration, markedAssets } = useSelector((state) => state.appReducer)
    const dispatch = useDispatch()

    const markTheFiles = useCallback((id, action = '') => {
        const find = dataAssets.find((f) => f.id === id)
        if (find) {
            if (action === "added") {
                dispatch(AppActions.markAsset(find))
            }
            if (action === "removed") {
                dispatch(AppActions.unmarkAsset(find))
            }
        }
    }, [dataAssets, dispatch])

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            selection.on('move', ({ store: { changed: { added, removed } } }) => {
                extractIds(added).forEach((id) => {
                    markTheFiles(id, "added")
                })
                extractIds(removed).forEach((id) => {
                    markTheFiles(id, "removed")
                })
            })
        }
        return () => isMounted = false
    }, [markTheFiles])

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            selection.resolveSelectables()
            if (markedAssets.length === 0) selection.clearSelection()
        }
        return () => isMounted = false
    }, [dataAssets, markedAssets])

    return (
        <Grid
            container
            spacing={2}
            className="areaBoundaries"
            style={{
                margin: 0,
                padding: "20px 2px",
                userSelect: "none",
                width: "100%"
            }}>
            {dataAssets.map((file) => {
                return (
                    <CardFile
                        file={file}
                        key={file.id}
                        isManager={isManager}
                        handlerReprocess={handlerReprocess}
                        handleConfirmDelete={handleConfirmDelete}
                        markedAssets={markedAssets}
                        simpleBucket={simpleBucket}
                        hasIntegration={hasIntegration}
                    />
                )
            })}

            <Footer />
        </Grid>
    )
}

Cards.defaultProps = {
    dataAssets: [],
    simpleBucket: false
}

export default Cards