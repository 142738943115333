import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root:{
    '& .MuiDialog-paperWidthSm': {
     margin: '0 !important',
     padding: '40px',
     minWidth: '550px',
     [theme.breakpoints.down("sm")]: {
      minWidth: '100%'
    },
    }
  },
  title: {
    padding: '0 0 16px 0',
    display: 'flex',
    alignItems: 'center',
    '& .info':{
      display: "flex",
      alignItems: 'center',
    },
    "& svg#main": {
      width: "40px",
      height: "40px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      padding: "4px",
      marginRight: "10px",
      color: "#fff",
    }   
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: '0',
    marginTop: '20px',
    "& button": {
      padding: "10px 16px",
      minWidth: "140px",
      height: "40px",
      maxHeight: "40px",
      fontWeight: 500,
    },
    "& button.delete": {
      "& svg": {
        color: "#757575",
      },
      "&:hover": {
        backgroundColor: "#F4511E",
        color: "#fff",
        "& svg": {
          color: "#fff",
        },
      },
    },
  },
  wrapperRichText: {
    minHeight: "200px",
    border: "1px solid #bababa",
    borderRadius: "4px",
},
}));
