import React, { useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"
import ItensFilterBar from 'components/ItensFilterBar/bucket'
import { Creators as pageBucketActions } from "flux/ducks/page_bucket"

import useContent from './hook/Content'

const Filters = ({
    simpleBucket = false,
    toolbarManagerAssets = null
}) => {
    const {
        loading,
        totals,
        filters_params,
        checkedGetAssets,
    } = useSelector(state => state.pageBucketReducer)
    const {
        hasIntegration,
        markedAssets
    } = useSelector(state => state.appReducer)
    const {
        showMessage,
        getData,
        getParatmeters,
        setParametersFilters
    } = useContent()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const handleCloseAccordion = useCallback(() => {
        const parameters = getParatmeters()
        dispatch(pageBucketActions.setDataState({ loading: true }))
        getData(parameters)
    }, [getParatmeters, getData, dispatch])

    const handleChangeAssetsSelected = useCallback(() => {
        let parameters = getParatmeters()
        let newCheckedGetAssets = false
        if (!checkedGetAssets) {
            parameters = getParatmeters(1, { id: markedAssets.map(i => i.id) })
            newCheckedGetAssets = true
        }
        dispatch(pageBucketActions.setDataState({ checkedGetAssets: newCheckedGetAssets, loading: true }))
        getData(parameters)
    }, [getData, dispatch, getParatmeters, checkedGetAssets, markedAssets])

    const handleCreateItem = useCallback(() => {
        if (markedAssets.length > 150) {
            showMessage(t("common:Você pode selecionar no máximo 150 assets"), "warning")
        } else {
            history.push("item/new")
        }
    }, [markedAssets, history, showMessage, t])

    const handleSubmitSearch = useCallback((searchText = "") => {
        const params = getParatmeters()
        dispatch(pageBucketActions.setDataState({ loading: true }))
        getData({ ...params, search: searchText })
    }, [getParatmeters, getData, dispatch])


    return (
        <ItensFilterBar
            data={{
                params: {
                    total: totals.current,
                    checkedValue: checkedGetAssets,
                    hasIntegration: hasIntegration,
                    filters_params: filters_params
                },
                func: {
                    handleSubmitSearch: (searchText) => handleSubmitSearch(searchText),
                    handleResetSearch: () => handleSubmitSearch(),
                    handleCreateItem: () => handleCreateItem(),
                    handleViewItens: () => handleChangeAssetsSelected(),
                    getData: () => handleCloseAccordion(),
                    setParametersFilters: (field, params) => setParametersFilters(field, params)
                }
            }}
            loading={loading}
            simpleBucket={simpleBucket}
            toolbarManagerAssets={toolbarManagerAssets}
        />
    )
}

export default Filters