import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

import { makeStyles } from "@material-ui/core/styles";

import AppActions from "flux/actions/AppActions"

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: '0px 15px',
    boxShadow: 'none'
  }
}))

const AccessDeniedSimpleAlert = ({ status = ''}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  if(status === 401) {
    dispatch(AppActions.openDialog("expiredToken"))    
  }

  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" component="h3">
          {t("common:Acesso negado")}
        </Typography>
        <Typography component="p">
          {t("common:Contate o administrador ou solicite a permissão para acessar o conteúdo.")}
        </Typography>
      </Paper>
    </div>
  );

}

export default AccessDeniedSimpleAlert;