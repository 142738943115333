import React from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import {
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Divider,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';

import { useStyles } from "./styles";

import DetailsRows from './components/DetailsRows'
import AccordionActions from './components/Actions'
import Utils from "utils/Utils";

const AccordionItems = () => {
  const classes = useStyles();
  const { uploadFiles } = useSelector(state => state.uploadReducer);
  const { t } = useTranslation("DAM")
  const filesLength
    = uploadFiles
    .filter(i => Utils.isOrdinaryAssetUpload(i.entity))
    .filter(i => !i.finishedSuccessfully)
    .length

  return (
    filesLength > 0 && (
      <>
        <div className={classes.root}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon fontSize="default" style={{ color: "#fff" }} />
              }
              aria-controls="panel1c-content"
              id="panel1c-header"
              className={classes.summary}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <CloudUploadRoundedIcon
                  fontSize="default"
                  style={{ color: "#fff" }}
                />
                <div style={{ width: "95%" }}>
                  <Typography className={classes.heading}>
                    {filesLength === 1
                      ? t("common:Fazendo upload de arquivo", {
                          total: filesLength,
                        })
                      : t("common:Fazendo upload de arquivos", {
                          total: filesLength,
                        })}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <DetailsRows />
            </AccordionDetails>
            <Divider />
            <AccordionActions />
          </Accordion>
        </div>
      </>
    )
  );
};

export default AccordionItems
