import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export const useStyles = makeStyles((theme) => ({
  root:{
    '& .MuiDialog-paperWidthSm': {
     margin: '0 !important',
     padding: '40px',
     minWidth: '550px'
    }
  },
  title: {
    padding: '0 0 16px 0',
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div.info':{
        display: "flex",
        alignItems: 'center',
      },
      "& * svg": {
        width: "40px",
        height: "40px",
        backgroundColor: '#F4511E',
        borderRadius: "50%",
        padding: "4px",
        marginRight: "10px",
        color: "#fff",
      },
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: '0',
    marginTop: '20px',
    "& button": {
      padding: "10px 16px",
      minWidth: "140px",
      height: "40px",
      maxHeight: "40px",
      fontWeight: 500,
    },
    "& button.delete": {
      "& svg": {
        color: "#757575",
      },
    },
    "& button.active": {
      backgroundColor: "#F4511E",
      color: "#fff",
      "& svg": {
        color: "#fff !important",
      },
      "&:hover": {
        backgroundColor: darken(0.08, "#F4511E"),
      },
    },
  },
}));
