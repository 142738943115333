import React, { useState, useCallback } from 'react'
import { useTranslation } from "react-i18next"
import axios from 'axios'
import _ from 'lodash'

import { useSelector } from "react-redux"
import { useSnackbar } from "notistack"

import {
  CircularProgress,
  Button,
  Typography,
  Box
} from "@material-ui/core"

import AlertDialogSimple from 'components/Alert/AlertDialogSimple'

import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import ShareRoundedIcon from "@material-ui/icons/ShareRounded"
import clipboardCopy from 'utils/clipboardCopy'

import Settings from 'utils/Settings'
import Utils from 'utils/Utils'

const LinkShared = ({
  idItem,
  idAsset,
  type = 'item',
  isItens = false,
  handleClose,
  handleStepBack,
  copyLink,
  pathDefault = null,
  paramsToItems = {},
  mustZipAssetsWithoutFolders,
  alternativeNameId,
  shareExpirationTime = "24",
  name = '',
  typeListrecipe = '',
  includeMetadata = false
}) => {
  const [state, setState] = useState({
    loading: false,
    error: false,
    openDialogAlert: { open: false, text: '' }
  })
  
  const { t } = useTranslation()
  const { pathServer, requestHeaders, markedItems } = useSelector((state) => state.appReducer)
  const { enqueueSnackbar } = useSnackbar()

  const getUrl = useCallback(() => {
    let urlData = {
      url: `${pathServer}/item/${idItem}/open/share`,
      payload: {
        version: 0,
        expiration: parseInt(shareExpirationTime),
        ...(Boolean(alternativeNameId) && { alternativeNameId }),
        ...(Boolean(name) && { name }),
        ...(Boolean(typeListrecipe) && { type: typeListrecipe }),
        ...(Boolean(includeMetadata) && { include_metadata: true }),
      }
    }

    if (isItens) {
      urlData.url = `${pathServer}/items/share/open`
      urlData.payload = {
        itemsId: markedItems.map((item) => item.id),
        ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
        bulk: true,
        ...(Boolean(alternativeNameId) && { alternativeNameId }),
        expiration: parseInt(shareExpirationTime),
        ...(Boolean(name) && { name }),
        ...(Boolean(typeListrecipe) && { type: typeListrecipe }),
        ...(Boolean(includeMetadata) && { include_metadata: true })
      }
    } else {
      if (type === 'asset') {
        urlData.url = `${pathServer}/asset/${idAsset}/item/${idItem}/share/open`
        urlData.payload = {
          version: 0,
          ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
          ...(Boolean(alternativeNameId) && { alternativeNameId }),
          expiration: parseInt(shareExpirationTime),
          ...(Boolean(name) && { name }),
          ...(Boolean(typeListrecipe) && { type: typeListrecipe })
        }
      }
    }

    if (Boolean(pathDefault)) {
      urlData.url = `${pathServer}/${pathDefault}`
      urlData.payload = {
        ...paramsToItems,
        shareType: 'link',
        ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
        bulk: true,
        ...(Boolean(alternativeNameId) && { alternativeNameId }),
        expiration: parseInt(shareExpirationTime),
        ...(Boolean(name) && { name }),
        ...(Boolean(typeListrecipe) && { type: typeListrecipe }),
        ...(Boolean(includeMetadata) && { include_metadata: true }),
      }
    }

    return urlData
  }, [idAsset, shareExpirationTime, idItem, isItens, markedItems, paramsToItems, name, pathDefault, pathServer, type, mustZipAssetsWithoutFolders, alternativeNameId, typeListrecipe, includeMetadata])

  const getDataLink = useCallback((evt) => {
    const urlData = getUrl()
    const isBulk = _.get(urlData, 'payload.bulk', false)

    setState(prev => ({ ...prev, loading: true }),
      axios.post(urlData.url, urlData.payload, requestHeaders)
        .then((resp) => {
          let newState = {
            ...(isBulk && { openDialogAlert: { open: true, text: _.get(resp, 'headers["x-message"]', '') } })
          }
          let message = t('common:Uma notificação será enviada quando o link estiver pronto')

          try {
            if (copyLink) {
              message = t('common:Link copiado com sucesso')
              clipboardCopy(resp.data?.link)
              newState.link = resp.data?.link
            }

            newState.loading = false
          }
          catch (e) {
            newState.loading = false
            newState.error = true
          }

          const callback = () => {
            if (!isBulk) {
              enqueueSnackbar(message, {
                ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
              })
            }
          }
          setState(prev => ({ ...prev, ...newState }), callback())
        })
        .catch(err => {
          const message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao gerar o link, tente novamente.`))

          setState(prev => ({ ...prev, loading: false }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }))
        }))

  }, [enqueueSnackbar, getUrl, requestHeaders, t, copyLink])

  const getIconSharedLink = useCallback(() => {
    let icon = copyLink ? <FileCopyRoundedIcon /> : null

    if (state.loading) {
      icon = <CircularProgress size={20} />
    }
    if (state.link) {
      icon = <DoneRoundedIcon />
    }
    if (state.error) {
      icon = <ErrorRoundedIcon />
    }
    return icon
  }, [copyLink, state.error, state.link, state.loading])

  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'normal' }}>
        <Typography style={{ color: "#666", marginBottom: 20 }}>
          {copyLink ? t(`DAM:Clique no botão abaixo para compartilhar via link`) : t(`DAM:Clique no botão abaixo para gerar o link. Uma notificação será enviada quando o link estiver pronto para ser compartilhado.`)}
        </Typography>

        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleStepBack}
            style={{ margin: '0 15px 0 0', minWidth: 80 }}
          >
            {t('common:Voltar')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ height: 40 }}
            onClick={getDataLink}
            disabled={Boolean(state?.loading) || Boolean(state?.link)}
            endIcon={getIconSharedLink()}
          >
            {copyLink ? t("DAM:Copiar link") : t("DAM:Gerar link")}
          </Button>
        </Box>
      </Box>
      <AlertDialogSimple
        open={state.openDialogAlert.open}
        icon={<ShareRoundedIcon id="main" />}
        handleClose={() => {
          setState(prev => ({ ...prev, openDialogAlert: { open: false, text: '' } }), handleClose())
        }}
        title={t(`common:Compartilhamento em andamento`)}
        description={state.openDialogAlert.text}
      />
    </>
  )
}

export default LinkShared
