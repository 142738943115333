import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import InputBase from "@material-ui/core/InputBase"
import SearchIcon from "@material-ui/icons/Search"
import CancelIcon from "@material-ui/icons/Cancel"
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    borderRadius: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',  
    '& .searchIcon': {
      width: 56,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      pointerEvents: 'none',
    },  
    '& input': {
      color: '#fff',
      cursor: 'text',
      display: 'inline-flex',
      fontSize: '1rem',
      alignItems: 'center',     
      fontWeight: 400,
      lineHeight: '1.1875em',
      letterSpacing: '0.00938em',
    }
  }
}))

const SearchInput = ({
  handleSubmitSearch,
  placeholder,
  value = "",
  showClearButton,
}) => {
  const [searchText, setSearchText] = useState(value)
  const {t} = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { navigation_view_mode, userData: { region } } = useSelector(state => state.appReducer)
 
  useEffect(() => {
    let isMounted = true

    if(isMounted) setSearchText(value)

    return () => {
      isMounted = false
      return
    }
  }, [value])

  const onChange = (evt) => {
    setSearchText(evt.target.value)
  }

  const handleResetSearch = () => {
    setSearchText("")
    handleSubmitSearch("")    
  }

  const runTextSearch = (e) => {
    let key = e.key

    if (key.toLowerCase() === "enter" && e.target.value.trim() !== "") {
      handleSubmitSearch(searchText)
    }

    if (key.toLowerCase() === "backspace" && e.target.value.length === 1) {
      handleResetSearch()
      if(navigation_view_mode === 'view-items') {
        history.push(`/dam/${region}`)
      }
    }
  }

  return (
    <div className={classes.search} id='input_search'>
      <div className="searchIcon">
        <SearchIcon />
      </div>

      <InputBase
        placeholder={placeholder || t('common:Buscar')}
        onChange={(evt) => onChange(evt)}
        onKeyDown={(evt) => runTextSearch(evt)}
        value={searchText}
      />
      <div style={{ alignSelf: "center", cursor: "pointer" }} />
      {searchText && showClearButton ? (
        <CancelIcon
          onClick={() => handleResetSearch()}
          style={{ marginRight: "5px" }}
        />
      ) : (
        <div style={{ width: "24px", height: "24px", marginRight: "5px" }} />
      )}
    </div>
  )
}

SearchInput.defaultProps = {
  handleSubmitSearch: () => {},
  showClearButton: false,
  placeholder: false,
}

export default SearchInput
