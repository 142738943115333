import React from 'react';
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
    Grid,
    FormControl,
    Box,
    FormControlLabel,
    Radio,
    RadioGroup, 
    TextField,
    Typography
} from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

import PaperCustom from '../PaperCustom'
import { useDataFormItem } from "../context"

import { useStyles } from '../styles'

const Published = () => {
    const { published: state, changePublished, changeDates, errors } = useDataFormItem()
    const classes = useStyles()
    const { t } = useTranslation('common')

    return (
        <Grid item xs={12} id="published">
            <PaperCustom title='Publicação' subTitle='Em relação a publicação, o item deverá ser criado como:'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="is-published"
                                name="isPublished"
                                value={state.option}
                                onChange={changePublished}
                            >
                                <Box>
                                    <FormControlLabel
                                        value="published"
                                        control={<Radio color="primary" />}
                                        label="Publicado"
                                    />
                                    <Box style={{ marginLeft: "32px", display: "flex", flexDirection: "column" }}>
                                        <Typography variant="body">
                                            O item pode ser visualizado e utilizado pelos usuários que possuem permissão no perfil.
                                        </Typography>
                                        <Typography variant="body">
                                            Período: indeterminado
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        value="unpublished"
                                        control={<Radio color="primary" />}
                                        label="Despublicado"
                                    />
                                    <Box style={{ marginLeft: "32px", display: "flex", flexDirection: "column" }}>
                                        <Typography variant="body">
                                            O item ficará oculto dos usuários que não possuírem perfil para gerenciar o conteúdo.
                                        </Typography>
                                        <Typography variant="body">
                                            Período: indeterminado
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        value="validity"
                                        control={<Radio color="primary" />}
                                        label="Vigência"
                                    />
                                    <Box style={{ marginLeft: "32px" }}>
                                        <Typography variant="body">
                                            O item que possuir um conteúdo permitido para ser veiculado e compartilho po um período específico.
                                        </Typography>
                                    </Box>
                                    {
                                        (_.get(state, 'option', "") === "validity") &&
                                        <Box className={classes.fieldDate} style={{ marginLeft: "32px" }}>
                                            <TextField
                                                id="date-initial"
                                                type="date"
                                                label={t('common:Data inicial')}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={_.get(state, 'startDate', '')}
                                                onChange={(evt) => changeDates('startDate', _.get(evt, 'target.value', ''))}
                                            />
                                            {
                                                (_.get(state, 'startDate', '').length > 0) &&
                                                <Box
                                                    style={{ marginLeft: 5, cursor: 'pointer' }}
                                                    onClick={() => changeDates('startDate', "")}
                                                >
                                                    <HighlightOffRoundedIcon />
                                                </Box>
                                            }
                                            <Typography variant="body" component="span" style={{ margin: '0 18px' }}>
                                                {t('common:até')}
                                            </Typography>
                                            <TextField
                                                id="date-end"
                                                type="date"
                                                label={t('common:Data final')}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={_.get(state, 'validityDate', '')}
                                                onChange={(evt) => changeDates('validityDate', _.get(evt, 'target.value', ''))}
                                                error={_.get(errors, 'hasDate', false)}
                                                helperText={_.get(errors, 'hasDate', '')}
                                            />
                                            {
                                                (_.get(state, 'validityDate', '').length > 0) &&
                                                <Box
                                                    style={{ marginLeft: 5, cursor: 'pointer' }}
                                                    onClick={() => changeDates('validityDate', "")}
                                                >
                                                    <HighlightOffRoundedIcon />
                                                </Box>
                                            }
                                        </Box>
                                    }
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </PaperCustom>
        </Grid>
    );
}

export default React.memo(Published)
