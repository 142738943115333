import React, { useEffect, useState } from "react"
import {
  MenuItem,
  Menu,
  ListItemIcon,
  Typography,
  Tooltip,
  useMediaQuery
} from "@material-ui/core"

import axios from "axios"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { mdiBuffer } from "@mdi/js"
import Icon from "@mdi/react"
import Skeleton from "@material-ui/lab/Skeleton"
import _ from "lodash"

import CloudIcon from "@material-ui/icons/Cloud"
import { slugify } from "../../../utils/Helpers"
import { withStyles } from "@material-ui/core/styles"
import CheckSVG from "../../../imagens/ui/fact_check-24px.svg"

const ContentSVG = withStyles({
  svg: {
    filter:
      "invert(46%) sepia(1%) saturate(1788%) hue-rotate(317deg) brightness(84%) contrast(83%)",
  },
})(({ classes }) => (
  <img
    src={CheckSVG}
    alt={"Solicitações"}
    width="24"
    height="24"
    className={classes.svg}
  />
))

const AppMenu = ({ anchorEl, app, handleMenuClose }) => {
  const [tools, setTools] = useState({ data: [], loading: true })
  const { pathServer, requestHeaders } = useSelector(
    (state) => state.appReducer
  )
  const { region } = useSelector((state) => state.appReducer.userData)
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    let isMounted = true
    const getData = async () => {
      await axios
        .post(`${pathServer}/tool/find/manager`, {}, requestHeaders)
        .then((result) => {
          if (isMounted) {
            const DATA = _.get(result, "data.items", []).map((tool) => ({
              id: tool.id,
              title: tool.title,
              thumbnail: tool.thumbnail || "",
              entity: _.get(tool, 'type', '').toLowerCase()
            }))
            setTools({ data: DATA, loading: false })
          }
        })
        .catch((err) => {
          if (isMounted) {
            setTools({ data: [], loading: false })
            console.log(err)
          }
        })
    }

    if (!isMobile) getData()

    return () => {
      isMounted = false
    }
  }, [pathServer, requestHeaders, isMobile])

  return (
    <Menu
      anchorEl={anchorEl.app}
      open={app.isMenuOpen}
      onClose={() => handleMenuClose("app")}
      style={{ maxWidth: 250 }}
    >
      <MenuItem onClick={() => handleMenuClose("app", "/bucket")}>
        {
          !isMobile && (
            <>
              <ListItemIcon style={{ minWidth: 10, marginRight: 10 }}>
                <CloudIcon />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Bucket
              </Typography>
            </>
          )
        }
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose("app", `/dam/${region}`)}>
        <ListItemIcon style={{ minWidth: 10, marginRight: 10 }}>
          <Icon path={mdiBuffer} size={1} color="#666" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          DAM
        </Typography>
      </MenuItem>
      {
        !isMobile && (
          <>
            {tools.loading ? (
              <div style={{ display: "flex", padding: "0 10px 0 17px" }}>
                <Skeleton
                  variant="rect"
                  width={30}
                  height={20}
                  style={{ marginRight: 10, borderRadius: 4 }}
                />
                <Skeleton
                  variant="rect"
                  style={{ borderRadius: 4 }}
                  width={"100%"}
                  height={20}
                />
              </div>
            ) : (
              <div>
                {tools.data.map((tool) => {
                  return (
                    <MenuItem
                      key={tool.id}
                      onClick={() =>
                        handleMenuClose("app", `/${region}/${tool.entity}/${slugify(tool.title)}`)
                      }
                    >
                      <Tooltip
                        title={_.get(tool, "title", t("common:Solicitações"))}
                        placement="top"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 10, marginRight: 10 }}>
                            {_.get(tool, "thumbnail", false) ? (
                              <img
                                width="20"
                                height="20"
                                src={tool.thumbnail}
                                alt={_.get(tool, "title", t("common:Solicitações"))}
                              />
                            ) : (
                              <ContentSVG />
                            )}
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap>
                            {_.get(tool, "title", t("common:Solicitações"))}
                          </Typography>
                        </div>
                      </Tooltip>
                    </MenuItem>
                  )
                })}
              </div>
            )}
          </>
        )
      }
    </Menu>
  )
}

export default AppMenu
