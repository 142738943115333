import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  paperTitle: {
    height: "100px",
    borderRadius: 0,
    padding: "20px 10px 20px 20px",
    display: "flex",
    alignItems: "center",
    color: "#666",
    "& svg": {
      fontSize: "3rem",
      marginRight: "20px",
    },
  },
}))
