import React from 'react';
import axios from "axios/index";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash"

import SelectUI from './SelectUI'
class ComboBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      disabled: (props.disabled) ? !!props.disabled : false,
      entity: "",
    };
    this._isMounted = false;
  }

  componentDidMount() {
    const { data } = this.state;
    this._isMounted = true;
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      this.getData(this.props.onLoadDataCallback);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUrl() {
    const { pathServer } = this.props
    const { entity } = this.state;
    return `${pathServer}/form/${entity}`;
  }

  getFormParams() {
    return {};
  }

  getData(onLoadDataCallback = null) {
    const SELF = this;
    const { requestHeaders, triggerError } = this.props;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = { ...requestHeaders, cancelToken: source.token }
    const params = this.getFormParams();

    axios.post(this.getUrl(), params, options)
      .then(function (resp) {
        let DATA = resp.data.items ? resp.data.items : resp.data

        if (SELF.props.dataDefault && SELF.props.dataDefault.length > 0) {
          DATA = [...SELF.props.dataDefault, ...DATA]
        }

        const newState = {
          ...SELF.state,
          loading: false,
          hasData: true,
          data: DATA
        };

        SELF._isMounted && SELF.setState(newState, () => {
          if (onLoadDataCallback && SELF.props.value) {
            const list = SELF.getListValues();
            const found = list.find(v => v.value === SELF.props.value.value)
            onLoadDataCallback(found)
          }
        });
      }, function (resp) {
        SELF._isMounted && SELF.setState({ ...SELF.state, loading: false });
        toast.error(resp.headers['x-message']);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          triggerError(err);
        }
      });
  }

  getSelectValue(object) {
    const disabledItems
      = this.props.hasOwnProperty('disabledItems') ? this.props.disabledItems : [];

    return {
      disabled: (disabledItems.indexOf(object.id) !== -1),
      label: object.name || "",
      value: object.id || "",
      canPublish: _.get(object, "canPublish", true)
    }
  }

  getListValues() {
    const data = this.state.data || [];
    return Object.values(data).map(object => {
      return this.getSelectValue(object)
    }, this);
  }

  render() {
    const disabled = this.props.isDisabled;
    const selectValues = this.getListValues();
    let valueSelected = {}

    if (Array.isArray(this.state.data)) {
      let selected = this.state.data.find(item => item.name === this.props.optionSelected);

      if (selected) {
        valueSelected = {
          label: selected.name,
          value: selected.id,
          canPublish: selected.canPublish
        }
      }
    }

    return (
      <React.Fragment>
        <SelectUI
          selectValues={selectValues}
          disabled={disabled}
          nameField={this.state.route}
          title={this.props.t(this.state.title)}
          valueSelect={valueSelected}
          handleChangeValue={(value) => this.props.handleChange(value)}
          error={this.props.error}
          fieldRequired={_.get(this.props, 'field_required', false)}
          positionMenu={this.props.positionMenu}
        />
      </React.Fragment>
    );
  }
}

export default ComboBox;
