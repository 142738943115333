import React from 'react'
import ListDefault from "../Core/List/DataGridCustom"
import ListWrapper from '../Core/List/ListWrapper'
import { getColumnDetails } from "../Core/utils"

import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "../../../utils/Flux"

import { withTranslation } from "react-i18next"

import _ from "lodash"
import ExportCSV from './BtnExposrtCSV'
class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      hasFab: false,
      openDialogBlock: false,
    }
    this.superRender = super.render
  }

  getLine(item) {
    const { t } = this.props
    let thumbnail = _.get(item, "urls.thumbnail.original") || "AvatarIcon"
    let situation = t(`common:${item.situation}`)
    if (item.blocked) {
      situation = t("common:Bloqueado")
    }

    let line = {
      id: item.id,
      thumbnail: { id: item.id, thumbnail: thumbnail },
      name: _.get(item, "name", ""),
      email: _.get(item, "email", ""),
      profile_default: _.get(item, "profile_default.name", ""),
      situation,
    }

    return line
  }

  handleClickRow(evt) {
    if (evt.field === "thumbnail") {
      return
    } else {
      this.props.history.push(`/manager/user/${evt.row.id}`)
    }
  }

  getColumns() {
    const { t } = this.props

    let columns = [
      {
        ...getColumnDetails("name", t("common:Nome")),
        cellClassName: "hasClick",
      },
      {
        ...getColumnDetails("email", t("common:E-mail")),
        cellClassName: "hasClick",
      },
      {
        ...getColumnDetails("profile_default", t("common:Perfil"), 200),
        cellClassName: "hasClick",
      },
      {
        ...getColumnDetails("situation", t("common:Situação"), 220),
        cellClassName: "hasClick",
      },
    ]

    return columns
  }

  customComponentHeader() {
    return <ExportCSV />
  }

  render() {
    const { hasFab, area, title, route } = this.state

    return (
      <ListWrapper
        area={area}
        title={title.singular}
        route={route}
        hasFab={hasFab}
      >
        {this.superRender()}
      </ListWrapper>
    )
  }
}

export default connect(
  mapStateToPropsToSettings,
  null
)(withTranslation(["manager", "common"])(List))
