import React from 'react'
import _ from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'
import ViewerModel3D from './ViewerModel3D'
import AssetIntegra from "./AssetIntegra"
import DrawerAssets from './DrawerAssets'
import { useDataAssetIntegra } from "./context"
import ItemDropzone from './ItemDropzone'

const RenderComponentArea = () => {
    const { stateItem, setAreaComponentName } = useDataAssetIntegra()

    const renderLoading = () =>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Skeleton variant="rect" height={600} width={600} style={{ borderRadius: 4, margin: 0 }} />
          <div style={{ marginTop: 22, marginBottom: 43 }}>
              <Skeleton variant="rect" height={32} width={300} style={{ borderRadius: 4, margin: 0 }} />
          </div>
      </div>

    const renderItem = () => {
      return <>
        <AssetIntegra
          loading={_.get(stateItem, 'loading', false)}
          data={{
              assets: _.get(stateItem, 'data.assets', []),
              allowDownload: _.get(stateItem, 'allow.download', false),
              allowEmail: _.get(stateItem, 'allow.email', false),
              allowManager: _.get(stateItem, 'allow.manager', false),
              item_id: _.get(stateItem, 'data.id', ''),
              item_name: _.get(stateItem, 'data.name', ''),
              versionIndex: _.get(stateItem, 'versionIndex', 0),
              is3DTexture: _.get(stateItem, 'data.is3DTexture', false),
              dates: {
                  end: _.get(stateItem, 'data.validity_date', null)
              },
              category_id: _.get(stateItem, 'data.category_id', '')
          }}
          handleComponentArea={setAreaComponentName}
        />
        <DrawerAssets data={{
          item_id: _.get(stateItem, 'data.id', ''),
          category_id: _.get(stateItem, 'data.category_id', ''),
          assets: _.get(stateItem, 'data.assets', []),
          version_item: _.get(stateItem, 'versionIndex', 0),
          is3DTexture: _.get(stateItem, 'data.is3DTexture', false),
          allowDownload: _.get(stateItem, 'allow.download', false),
          allowEmail: _.get(stateItem, 'allow.email', false),
          allowManager: _.get(stateItem, 'allow.manager', false),
          dates: {
              end: _.get(stateItem, 'data.validity_date', null)
          },
          }}
          handleComponentArea={setAreaComponentName}
        />
      </>
    }

    const renderDraft = () =>
      <ItemDropzone context_id={_.get(stateItem, 'data.id', '')} ></ItemDropzone>

    const render = () => {
      if(stateItem.errorGetData) {
        return <></>
      }
      if(_.get(stateItem, 'loading', false)) {
        return renderLoading()
      }
      if(_.get(stateItem, 'data.draft', false)) {
        return renderDraft();
      }
      return renderItem()
    }
    switch (stateItem.areaComponentName) {
        case "3dModelViewer":
            return <ViewerModel3D
                idItem={_.get(stateItem, 'data.id', '')}
                handleComponentArea={setAreaComponentName}
            />
        default:
            return render()
    }
}

export default RenderComponentArea
